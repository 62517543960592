export const proteins = [
    {id: "1,4-alpha-glucan branching enzyme"},
    {id: "ec 2.4.1.18"},
    {id: "1,5-anhydro-d-fructose reductase"},
    {id: "af reductase"},
    {id: "ec 1.1.1.263"},
    {id: "aldo-keto reductase family 1 member c-like protein 2"},
    {id: "aldo-keto reductase family 1 member e2"},
    {id: "1-acyl-sn-glycerol-3-phosphate acyltransferase"},
    {id: "ec 2.3.1.51"},
    {id: "1-acyl-sn-glycerol-3-phosphate acyltransferase delta"},
    {id: "1-acylglycerol-3-phosphate o-acyltransferase 4"},
    {id: "1-agp acyltransferase 4"},
    {id: "1-agpat 4"},
    {id: "lysophosphatidic acid acyltransferase delta"},
    {id: "lpaat-delta"},
    {id: "1-acylglycerol-3-phosphate o-acyltransferase 6"},
    {id: "lysophosphatidic acid acyltransferase, zeta"},
    {id: "glycerol-3-phosphate acyltransferase 4"},
    {id: "1-phosphatidylinositol 4,5-bisphosphate phosphodiesterase"},
    {id: "ec 3.1.4.11"},
    {id: "1-phosphatidylinositol 4,5-bisphosphate phosphodiesterase beta-2"},
    {id: "phosphoinositide phospholipase c-beta-2"},
    {id: "phospholipase c-beta-2"},
    {id: "plc-beta-2"},
    {id: "1-phosphatidylinositol 4,5-bisphosphate phosphodiesterase gamma"},
    {id: "1-phosphatidylinositol 4,5-bisphosphate phosphodiesterase zeta-1"},
    {id: "phosphoinositide phospholipase c-zeta-1"},
    {id: "phospholipase c-zeta-1"},
    {id: "plc-zeta-1"},
    {id: "1-phosphatidylinositol 4-kinase"},
    {id: "ec 2.7.1.67"},
    {id: "1-phosphatidylinositol-3-phosphate 5-kinase"},
    {id: "ec 2.7.1.150"},
    {id: "10-formyltetrahydrofolate dehydrogenase"},
    {id: "ec 1.5.1.6"},
    {id: "116 kda u5 small nuclear ribonucleoprotein component"},
    {id: "u5 snrnp-specific protein, 116 kda"},
    {id: "14-3-3 protein beta/alpha"},
    {id: "prepronerve growth factor rnh-1"},
    {id: "protein kinase c inhibitor protein 1"},
    {id: "kcip-1"},
    {id: "cleaved into: 14-3-3 protein beta/alpha, n-terminally processed"},
    {id: "14-3-3 protein epsilon"},
    {id: "14-3-3e"},
    {id: "mitochondrial import stimulation factor l subunit"},
    {id: "msf l"},
    {id: "14-3-3 protein gamma"},
    {id: "cleaved into: 14-3-3 protein gamma, n-terminally processed"},
    {id: "15-hydroxyprostaglandin dehydrogenase"},
    {id: "nad(+)"},
    {id: "15-pgdh"},
    {id: "ec 1.1.1.141"},
    {id: "eicosanoid/docosanoid dehydrogenase [nad(+)]"},
    {id: "ec 1.1.1.-, ec 1.1.1.232"},
    {id: "prostaglandin dehydrogenase 1"},
    {id: "17-beta-hydroxysteroid dehydrogenase 13"},
    {id: "17-beta-hsd 13"},
    {id: "ec 1.1.1.-"},
    {id: "ec 1.1.1.62"},
    {id: "hepatic retinol/retinal dehydrogenase"},
    {id: "ec 1.1.1.105"},
    {id: "short-chain dehydrogenase/reductase 9"},
    {id: "18s rrna aminocarboxypropyltransferase"},
    {id: "ec 2.5.1.-"},
    {id: "2'-5' oligoadenylate synthetase 1h"},
    {id: "2'-5' oligoadenylate synthase"},
    {id: "ec 2.7.7.84"},
    {id: "2'-5' oligoadenylate synthetase 1c"},
    {id: "2'-5' oligoadenylate synthetase 1e"},
    {id: "2'-deoxynucleoside 5'-phosphate n-hydrolase 1"},
    {id: "ec 3.2.2.-"},
    {id: "deoxyribonucleoside 5'-monophosphate n-glycosidase"},
    {id: "c-myc-responsive protein rcl"},
    {id: "2'-phosphotransferase"},
    {id: "ec 2.7.1.160"},
    {id: "2-"},
    {id: "3-amino-3-carboxypropyl"},
    {id: "ec 2.5.1.108"},
    {id: "diphthamide biosynthesis protein 1"},
    {id: "diphtheria toxin resistance protein 1"},
    {id: "s-adenosyl-l-methionine:l-histidine 3-amino-3-carboxypropyltransferase 1"},
    {id: "diphthamide biosynthesis protein 2"},
    {id: "diphtheria toxin resistance protein 2"},
    {id: "s-adenosyl-l-methionine:l-histidine 3-amino-3-carboxypropyltransferase 2"},
    {id: "2-hydroxyacid oxidase 1"},
    {id: "haox1"},
    {id: "ec 1.1.3.15"},
    {id: "glycolate oxidase"},
    {id: "gox"},
    {id: "glyoxylate oxidase"},
    {id: "ec 1.2.3.5"},
    {id: "2-hydroxyacid oxidase 2"},
    {id: "haox2"},
    {id: "(s)-2-hydroxy-acid oxidase, peroxisomal"},
    {id: "long chain alpha-hydroxy acid oxidase"},
    {id: "long-chain l-2-hydroxy acid oxidase"},
    {id: "lchao"},
    {id: "2-amino-3-carboxymuconate-6-semialdehyde decarboxylase"},
    {id: "ec 4.1.1.45"},
    {id: "picolinate carboxylase"},
    {id: "2-hydroxyacyl-coa lyase 1"},
    {id: "ec 4.1.2.63"},
    {id: "2-hydroxyphytanoyl-coa lyase"},
    {id: "2-hpcl"},
    {id: "phytanoyl-coa 2-hydroxylase 2"},
    {id: "2-hydroxyacyl-coa lyase 2"},
    {id: "acetolactate synthase-like protein"},
    {id: "ilvb-like protein"},
    {id: "2-hydroxyacylsphingosine 1-beta-galactosyltransferase"},
    {id: "ec 2.4.1.47"},
    {id: "ceramide udp-galactosyltransferase"},
    {id: "cgalt"},
    {id: "cerebroside synthase"},
    {id: "udp-galactose-ceramide galactosyltransferase"},
    {id: "2-iminobutanoate/2-iminopropanoate deaminase"},
    {id: "ec 3.5.99.10"},
    {id: "translation inhibitor l-psp ribonuclease"},
    {id: "2-methoxy-6-polyprenyl-1,4-benzoquinol methylase, mitochondrial"},
    {id: "ec 2.1.1.201"},
    {id: "ubiquinone biosynthesis methyltransferase coq5"},
    {id: "2-oxo-4-hydroxy-4-carboxy-5-ureidoimidazoline decarboxylase"},
    {id: "ec 4.1.1.97"},
    {id: "parahox neighbor"},
    {id: "ureidoimidazoline (2-oxo-4-hydroxy-4-carboxy-5-) decarboxylase"},
    {id: "2-oxoglutarate and iron-dependent oxygenase domain-containing protein 3"},
    {id: "ec 1.14.11.-"},
    {id: "2-oxoglutarate dehydrogenase complex component e1"},
    {id: "e1o"},
    {id: "ogdc-e1"},
    {id: "ogdh-e1"},
    {id: "ec 1.2.4.2"},
    {id: "2-oxoglutarate dehydrogenase, mitochondrial"},
    {id: "alpha-ketoglutarate dehydrogenase"},
    {id: "alpha-kgdh-e1"},
    {id: "thiamine diphosphate (thdp)-dependent 2-oxoglutarate dehydrogenase"},
    {id: "2-oxoglutarate receptor 1"},
    {id: "alpha-ketoglutarate receptor 1"},
    {id: "g-protein coupled receptor 80"},
    {id: "p2y purinoceptor 15"},
    {id: "p2y15"},
    {id: "2-oxoisovalerate dehydrogenase subunit beta, mitochondrial"},
    {id: "ec 1.2.4.4"},
    {id: "branched-chain alpha-keto acid dehydrogenase e1 component beta chain"},
    {id: "bckde1b"},
    {id: "bckdh e1-beta"},
    {id: "2-phosphoxylose phosphatase 1"},
    {id: "ec 3.1.3.-"},
    {id: "acid phosphatase-like protein 2"},
    {id: "26s proteasome complex subunit sem1"},
    {id: "26s proteasome complex subunit dss1"},
    {id: "26s proteasome non-atpase regulatory subunit 13"},
    {id: "26s proteasome regulatory subunit rpn9"},
    {id: "26s proteasome regulatory subunit s11"},
    {id: "26s proteasome regulatory subunit p40.5"},
    {id: "26s proteasome regulatory subunit 6b"},
    {id: "26s proteasome aaa-atpase subunit rpt3"},
    {id: "proteasome 26s subunit atpase 4"},
    {id: "s6 atpase"},
    {id: "tat-binding protein 7"},
    {id: "tbp-7"},
    {id: "26s proteasome regulatory subunit 8"},
    {id: "26s proteasome aaa-atpase subunit rpt6"},
    {id: "proteasome 26s subunit atpase 5"},
    {id: "proteasome subunit p45"},
    {id: "thyroid hormone receptor-interacting protein 1"},
    {id: "trip1"},
    {id: "p45/sug"},
    {id: "28 kda heat- and acid-stable phosphoprotein"},
    {id: "pdgf-associated protein"},
    {id: "pap"},
    {id: "pdgfa-associated protein 1"},
    {id: "pap1"},
    {id: "28s ribosomal protein s18b, mitochondrial"},
    {id: "28s ribosomal protein s18-2, mitochondrial"},
    {id: "3-beta-hsd iv"},
    {id: "includes: 3-beta-hydroxy-delta(5)-steroid dehydrogenase (ec 1.1.1.145) (3-beta-hydroxy-5-ene steroid dehydrogenase) (progesterone reductase); steroid delta-isomerase (ec 5.3.3.1) (delta-5-3-ketosteroid isomerase)"},
    {id: "3'"},
    {id: "2'"},
    {id: "ac2-190"},
    {id: "3',5'-cyclic-gmp phosphodiesterase"},
    {id: "ec 3.1.4.35"},
    {id: "3'-5' exoribonuclease 1"},
    {id: "ec 3.1.-.-"},
    {id: "histone mrna 3'-exonuclease 1"},
    {id: "3'-phosphate/5'-hydroxy nucleic acid ligase"},
    {id: "ec 6.5.1.8"},
    {id: "3'-phosphoadenosine 5'-phosphosulfate synthase 2"},
    {id: "papss2 protein"},
    {id: "17"},
    {id: "aldo-keto reductase family 1, member c2"},
    {id: "3-alpha-hydroxysteroid dehydrogenase"},
    {id: "3-alpha-hsd"},
    {id: "ec 1.1.1.50"},
    {id: "hydroxyprostaglandin dehydrogenase"},
    {id: "3-beta-hydroxysteroid-delta"},
    {id: "ec 5.3.3.5"},
    {id: "cholestenol delta-isomerase"},
    {id: "delta(8)-delta(7) sterol isomerase"},
    {id: "d8-d7 sterol isomerase"},
    {id: "emopamil-binding protein"},
    {id: "sterol 8-isomerase"},
    {id: "3-dehydrosphinganine reductase"},
    {id: "ec 1.1.1.102"},
    {id: "3-hydroxy-3-methylglutaryl-coa lyase, cytoplasmic"},
    {id: "ec 4.1.3.4"},
    {id: "3-hydroxy-3-methylglutaryl-coa lyase-like protein 1"},
    {id: "3-hydroxyanthranilate 3,4-dioxygenase"},
    {id: "ec 1.13.11.6"},
    {id: "3-hydroxyanthranilate oxygenase"},
    {id: "3-hao"},
    {id: "3-hydroxyanthranilic acid dioxygenase"},
    {id: "had"},
    {id: "3-hydroxyisobutyrate dehydrogenase"},
    {id: "hibadh"},
    {id: "ec 1.1.1.31"},
    {id: "3-hydroxyisobutyryl-coa hydrolase"},
    {id: "hib-coa hydrolase"},
    {id: "hibyl-coa-h"},
    {id: "ec 3.1.2.4"},
    {id: "3-hydroxyisobutyryl-coenzyme a hydrolase"},
    {id: "3-hydroxyisobutyryl-coa hydrolase, mitochondrial"},
    {id: "3-ketoacyl-coa thiolase b, peroxisomal"},
    {id: "ec 2.3.1.155"},
    {id: "ec 2.3.1.16"},
    {id: "ec 2.3.1.9"},
    {id: "acetyl-coa acyltransferase b"},
    {id: "beta-ketothiolase b"},
    {id: "peroxisomal 3-oxoacyl-coa thiolase b"},
    {id: "3-mercaptopyruvate sulfurtransferase"},
    {id: "mst"},
    {id: "ec 2.8.1.2"},
    {id: "3-oxo-5-alpha-steroid 4-dehydrogenase 2"},
    {id: "ec 1.3.1.22"},
    {id: "sr type 2"},
    {id: "steroid 5-alpha-reductase 2"},
    {id: "s5ar 2"},
    {id: "3-oxoacyl-"},
    {id: "acyl-carrier-protein"},
    {id: "ec 1.1.1.100"},
    {id: "3-ketoacyl-[acyl-carrier-protein] reductase beta subunit"},
    {id: "kar beta subunit"},
    {id: "carbonyl reductase family member 4"},
    {id: "cbr4"},
    {id: "quinone reductase cbr4"},
    {id: "ec 1.6.5.10"},
    {id: "ec 2.3.1.41"},
    {id: "3-phosphoinositide-dependent protein kinase 1"},
    {id: "ec 2.7.11.1"},
    {id: "protein kinase b kinase"},
    {id: "pkb kinase"},
    {id: "3beta-hydroxysteroid 3-dehydrogenase"},
    {id: "ec 1.1.1.270"},
    {id: "4'-phosphopantetheine phosphatase"},
    {id: "4-aminobutyrate aminotransferase, mitochondrial"},
    {id: "beta-alaat i"},
    {id: "ec 2.6.1.19"},
    {id: "(s)-3-amino-2-methylpropionate transaminase"},
    {id: "ec 2.6.1.22"},
    {id: "gaba aminotransferase"},
    {id: "gaba-at"},
    {id: "gamma-amino-n-butyrate transaminase"},
    {id: "gaba transaminase"},
    {id: "gaba-t"},
    {id: "l-aibat"},
    {id: "cleaved into: 4-aminobutyrate aminotransferase, brain isoform; 4-aminobutyrate aminotransferase, liver isoform"},
    {id: "4-galactosyl-n-acetylglucosaminide 3-alpha-l-fucosyltransferase 9"},
    {id: "ec 2.4.1.152"},
    {id: "fucosyltransferase 9"},
    {id: "fucosyltransferase ix"},
    {id: "fuc-tix"},
    {id: "fuct-ix"},
    {id: "galactoside 3-l-fucosyltransferase"},
    {id: "4-hydroxy-2-oxoglutarate aldolase, mitochondrial"},
    {id: "ec 4.1.3.16"},
    {id: "dihydrodipicolinate synthase-like"},
    {id: "probable 2-keto-4-hydroxyglutarate aldolase"},
    {id: "4-hydroxybenzoate polyprenyltransferase, mitochondrial"},
    {id: "4-hb polyprenyltransferase"},
    {id: "ec 2.5.1.39"},
    {id: "para-hydroxybenzoate--polyprenyltransferase"},
    {id: "phb:ppt"},
    {id: "phb:polyprenyltransferase"},
    {id: "4-hydroxyphenylpyruvate dioxygenase"},
    {id: "ec 1.13.11.27"},
    {id: "4-hydroxyphenylpyruvic acid oxidase"},
    {id: "4hppd"},
    {id: "hpd"},
    {id: "hppdase"},
    {id: "4-hydroxyphenylpyruvate dioxygenase-like protein"},
    {id: "ec 1.13.-.-"},
    {id: "glyoxalase domain-containing protein 1"},
    {id: "40-kda huntingtin-associated protein"},
    {id: "factor viii intron 22 protein"},
    {id: "40s ribosomal protein s2"},
    {id: "loc100125366 protein"},
    {id: "40s ribosomal protein s3"},
    {id: "ec 4.2.99.18"},
    {id: "45 kda calcium-binding protein"},
    {id: "stromal cell-derived factor 4"},
    {id: "4a-hydroxytetrahydrobiopterin dehydratase"},
    {id: "ec 4.2.1.96"},
    {id: "5',3'-nucleotidase, mitochondrial"},
    {id: "nt5m protein"},
    {id: "5'-"},
    {id: "n(7)-methylguanosine 5'-triphospho"},
    {id: "mrna"},
    {id: "ec 3.6.1.62"},
    {id: "5'-3' exonuclease pld3"},
    {id: "ec 3.1.16.1"},
    {id: "choline phosphatase 3"},
    {id: "phosphatidylcholine-hydrolyzing phospholipase d3"},
    {id: "phospholipase d3"},
    {id: "pld 3"},
    {id: "5'-3' exoribonuclease"},
    {id: "ec 3.1.13.-"},
    {id: "5'-amp-activated protein kinase catalytic subunit alpha-1"},
    {id: "ampk subunit alpha-1"},
    {id: "acetyl-coa carboxylase kinase"},
    {id: "acaca kinase"},
    {id: "ec 2.7.11.27"},
    {id: "hydroxymethylglutaryl-coa reductase kinase"},
    {id: "hmgcr kinase"},
    {id: "ec 2.7.11.31"},
    {id: "tau-protein kinase prkaa1"},
    {id: "ec 2.7.11.26"},
    {id: "5'-amp-activated protein kinase catalytic subunit alpha-2"},
    {id: "5'-amp-activated protein kinase subunit beta-1"},
    {id: "ampk subunit beta-1"},
    {id: "ampkb"},
    {id: "5'-amp-activated protein kinase 40 kda subunit"},
    {id: "5'-deoxynucleotidase hddc2"},
    {id: "ec 3.1.3.89"},
    {id: "hd domain-containing protein 2"},
    {id: "5'-nucleotidase"},
    {id: "ec 3.1.3.5"},
    {id: "ecto-5'-nucleotidase"},
    {id: "5-aminolevulinate synthase"},
    {id: "ec 2.3.1.37"},
    {id: "5-aminolevulinic acid synthase"},
    {id: "delta-ala synthase"},
    {id: "delta-aminolevulinate synthase"},
    {id: "5-formyltetrahydrofolate cyclo-ligase"},
    {id: "ec 6.3.3.2"},
    {id: "5-hydroxyisourate hydrolase"},
    {id: "hiu hydrolase"},
    {id: "hiuhase"},
    {id: "ec 3.5.2.17"},
    {id: "5-hydroxytryptamine receptor 1f"},
    {id: "serotonin receptor 1f"},
    {id: "5-hydroxytryptamine receptor 2a"},
    {id: "5-ht-2"},
    {id: "5-ht-2a"},
    {id: "serotonin receptor 2a"},
    {id: "5-hydroxytryptamine receptor 2b"},
    {id: "serotonin receptor 2b"},
    {id: "5-hydroxytryptamine receptor 2c"},
    {id: "5-ht-2c"},
    {id: "5-ht2c"},
    {id: "5-htr2c"},
    {id: "5-hydroxytryptamine receptor 1c"},
    {id: "5-ht-1c"},
    {id: "5-ht1c"},
    {id: "serotonin receptor 2c"},
    {id: "5-hydroxytryptamine receptor 4"},
    {id: "serotonin receptor 4"},
    {id: "5-hydroxytryptamine receptor 5b"},
    {id: "5-ht-5b"},
    {id: "5-ht5b"},
    {id: "mr22"},
    {id: "serotonin receptor 5b"},
    {id: "5-hydroxytryptamine receptor 7"},
    {id: "5-ht-7"},
    {id: "5-ht7"},
    {id: "5-ht-x"},
    {id: "gprfo"},
    {id: "serotonin receptor 7"},
    {id: "5-oxoprolinase"},
    {id: "atp-hydrolysing"},
    {id: "ec 3.5.2.9"},
    {id: "5-oxo-l-prolinase"},
    {id: "5-opase"},
    {id: "pyroglutamase"},
    {id: "6-phosphofructo-2-kinase/fructose-2, 6-biphosphatase 3 splice variant"},
    {id: "ec 2.7.1.105, ec 3.1.3.46"},
    {id: "6-phosphofructo-2-kinase/fructose-2,6-bisphosphatase 3"},
    {id: "6-phosphofructo-2-kinase/fructose-2, 6-bisphosphatase-4 transcript variant 3"},
    {id: "6-phosphofructo-2-kinase/fructose-2,6-biphosphatase 4"},
    {id: "6pf-2-k/fru-2,6-p2ase 3"},
    {id: "pfk/fbpase 3"},
    {id: "6pf-2-k/fru-2,6-p2ase brain-type isozyme"},
    {id: "rb2k"},
    {id: "includes: 6-phosphofructo-2-kinase (ec 2.7.1.105); fructose-2,6-bisphosphatase (ec 3.1.3.46)"},
    {id: "6-phosphofructo-2-kinase/fructose-2,6-bisphosphatase 4"},
    {id: "6pf-2-k/fru-2,6-p2ase 4"},
    {id: "pfk/fbpase 4"},
    {id: "6pf-2-k/fru-2,6-p2ase testis-type isozyme"},
    {id: "6-phosphofructokinase"},
    {id: "ec 2.7.1.11"},
    {id: "phosphohexokinase"},
    {id: "6-phosphogluconate dehydrogenase, decarboxylating"},
    {id: "ec 1.1.1.44"},
    {id: "6-phosphogluconolactonase"},
    {id: "6pgl"},
    {id: "ec 3.1.1.31"},
    {id: "6-pyruvoyl tetrahydrobiopterin synthase"},
    {id: "ec 4.2.3.12"},
    {id: "ptp synthase"},
    {id: "ptps"},
    {id: "60 kda heat shock protein, mitochondrial"},
    {id: "ec 5.6.1.7"},
    {id: "60 kda chaperonin"},
    {id: "chaperonin 60"},
    {id: "cpn60"},
    {id: "hsp-65"},
    {id: "heat shock protein 60"},
    {id: "hsp-60"},
    {id: "hsp60"},
    {id: "mitochondrial matrix protein p1"},
    {id: "60s ribosome subunit biogenesis protein nip7 homolog"},
    {id: "nucleolar pre-rrna processing protein nip7"},
    {id: "peachy"},
    {id: "kda93"},
    {id: "7-dehydrocholesterol reductase"},
    {id: "7-dhc reductase"},
    {id: "ec 1.3.1.21"},
    {id: "sterol delta(7)-reductase"},
    {id: "7-methylguanosine phosphate-specific 5'-nucleotidase"},
    {id: "7-methylguanosine nucleotidase"},
    {id: "ec 3.1.3.91"},
    {id: "cytosolic 5'-nucleotidase 3b"},
    {id: "cytosolic 5'-nucleotidase iii-like protein"},
    {id: "cn-iii-like protein"},
    {id: "n(7)-methylguanylate 5'-phosphatase"},
    {id: "72 kda type iv collagenase"},
    {id: "ec 3.4.24.24"},
    {id: "72 kda gelatinase"},
    {id: "gelatinase a"},
    {id: "matrix metalloproteinase-2"},
    {id: "mmp-2"},
    {id: "cleaved into: pex"},
    {id: "8-oxo-dgdp phosphatase nudt18"},
    {id: "ec 3.6.1.58"},
    {id: "2-hydroxy-dadp phosphatase"},
    {id: "7,8-dihydro-8-oxoguanine phosphatase"},
    {id: "mutt homolog 3"},
    {id: "nucleoside diphosphate-linked moiety x motif 18"},
    {id: "nudix motif 18"},
    {id: "adam-ts 7"},
    {id: "adam-ts7"},
    {id: "adamts-7"},
    {id: "ec 3.4.24.-"},
    {id: "compase"},
    {id: "prka"},
    {id: "a-kinase anchor protein 12"},
    {id: "akap-12"},
    {id: "a-kinase anchor protein 14"},
    {id: "akap-14"},
    {id: "takap-1.2"},
    {id: "testis-specific a-kinase-anchoring protein takap-80"},
    {id: "a-kinase anchor protein 8"},
    {id: "akap-8"},
    {id: "a-kinase anchor protein 95 kda"},
    {id: "akap 95"},
    {id: "a030009h04rik"},
    {id: "ring finger protein 227"},
    {id: "abc-type glutathione-s-conjugate transporter"},
    {id: "ec 7.6.2.3"},
    {id: "abc-type oligopeptide transporter abcb9"},
    {id: "ec 7.4.2.6"},
    {id: "atp-binding cassette sub-family b member 9"},
    {id: "atp-binding cassette transporter 9"},
    {id: "abc transporter 9 protein"},
    {id: "tap-like protein"},
    {id: "tapl"},
    {id: "adam metallopeptidase domain 3a"},
    {id: "adam metallopeptidase domain 3a (cyritestin 1)"},
    {id: "transmembrane protein tmdc i"},
    {id: "adamts-like protein 4"},
    {id: "adamtsl-4"},
    {id: "adp-ribose pyrophosphatase, mitochondrial"},
    {id: "ec 3.6.1.13"},
    {id: "adp-ribose diphosphatase"},
    {id: "adp-ribose phosphohydrolase"},
    {id: "adenosine diphosphoribose pyrophosphatase"},
    {id: "adpr-ppase"},
    {id: "nucleoside diphosphate-linked moiety x motif 9"},
    {id: "nudix motif 9"},
    {id: "adp-ribosyl cyclase/cyclic adp-ribose hydrolase"},
    {id: "ec 3.2.2.6"},
    {id: "adp-ribosyl cyclase/cyclic adp-ribose hydrolase 2"},
    {id: "adp-ribosyl cyclase 2"},
    {id: "bone marrow stromal antigen 1"},
    {id: "bst-1"},
    {id: "cyclic adp-ribose hydrolase 2"},
    {id: "cadpr hydrolase 2"},
    {id: "cd antigen cd157"},
    {id: "adp-ribosylation factor 1"},
    {id: "ec 3.6.5.2"},
    {id: "adp-ribosylation factor 3"},
    {id: "liver regeneration-related protein lrrg202"},
    {id: "adp-ribosylation factor 6"},
    {id: "adp-ribosylation factor gtpase activating protein 1"},
    {id: "adp-ribosylation factor gtpase activating protein 1 brain isoform"},
    {id: "adp-ribosylation factor gtpase activating protein 1 heart isoform"},
    {id: "adp-ribosylation factor gtpase-activating protein 2"},
    {id: "arf gap 2"},
    {id: "gtpase-activating protein znf289"},
    {id: "zinc finger protein 289"},
    {id: "adp-ribosylation factor gtpase-activating protein 3"},
    {id: "arf gap 3"},
    {id: "adp-ribosylation factor like gtpase 6 interacting protein 1"},
    {id: "adp-ribosylation factor-like 6 interacting protein 1"},
    {id: "adp-ribosylation factor-binding protein gga3"},
    {id: "golgi-localized, gamma ear-containing, arf-binding protein 3"},
    {id: "adp-ribosylation factor-like protein 2-binding protein"},
    {id: "arf-like 2-binding protein"},
    {id: "binder of arf2 protein 1"},
    {id: "adp-ribosylation factor-like protein 3"},
    {id: "ard3"},
    {id: "adp-ribosylation factor-like protein 6-interacting protein 4"},
    {id: "arl-6-interacting protein 4"},
    {id: "aip-4"},
    {id: "splicing factor srrp37"},
    {id: "adp-ribosylation factor-like protein 6-interacting protein 6"},
    {id: "arl-6-interacting protein 6"},
    {id: "aip-6"},
    {id: "adp-ribosylation factor-like protein 8b"},
    {id: "adp-sugar pyrophosphatase"},
    {id: "8-oxo-dgdp phosphatase"},
    {id: "nuclear atp-synthesis protein nudix5"},
    {id: "ec 2.7.7.96"},
    {id: "nucleoside diphosphate-linked moiety x motif 5"},
    {id: "nudix motif 5"},
    {id: "adp/atp translocase"},
    {id: "adp,atp carrier protein"},
    {id: "afg3"},
    {id: "atpase family gene 3"},
    {id: "s. cerevisiae"},
    {id: "ah receptor-interacting protein"},
    {id: "aip"},
    {id: "aryl-hydrocarbon receptor-interacting protein"},
    {id: "immunophilin xap2"},
    {id: "amp deaminase"},
    {id: "ec 3.5.4.6"},
    {id: "amp deaminase 2"},
    {id: "amp deaminase isoform l"},
    {id: "an1-type zinc finger protein 2b"},
    {id: "arsenite-inducible rna-associated protein-like protein"},
    {id: "airap-like protein"},
    {id: "an1-type zinc finger protein 3"},
    {id: "testis-expressed protein 27"},
    {id: "an1-type zinc finger protein 5"},
    {id: "zinc finger protein 216"},
    {id: "an1-type zinc finger protein 6"},
    {id: "zinc finger a20 domain-containing protein 3"},
    {id: "ap-2 complex subunit beta"},
    {id: "ap105b"},
    {id: "adaptor protein complex ap-2 subunit beta"},
    {id: "adaptor-related protein complex 2 subunit beta"},
    {id: "beta-2-adaptin"},
    {id: "beta-adaptin"},
    {id: "clathrin assembly protein complex 2 beta large chain"},
    {id: "plasma membrane adaptor ha2/ap2 adaptin beta subunit"},
    {id: "ap-2 complex subunit mu"},
    {id: "ap-2 mu chain"},
    {id: "adaptor protein complex ap-2 subunit mu"},
    {id: "adaptor-related protein complex 2 subunit mu"},
    {id: "clathrin assembly protein complex 2 mu medium chain"},
    {id: "clathrin coat assembly protein ap50"},
    {id: "clathrin coat-associated protein ap50"},
    {id: "mu2-adaptin"},
    {id: "plasma membrane adaptor ap-2 50 kda protein"},
    {id: "ap-2 complex subunit sigma"},
    {id: "adaptor protein complex ap-2 subunit sigma"},
    {id: "adaptor-related protein complex 2 subunit sigma"},
    {id: "clathrin assembly protein 2 sigma small chain"},
    {id: "clathrin coat assembly protein ap17"},
    {id: "clathrin coat-associated protein ap17"},
    {id: "plasma membrane adaptor ap-2 17 kda protein"},
    {id: "sigma-adaptin 3b"},
    {id: "sigma2-adaptin"},
    {id: "ap-3 complex subunit mu-2"},
    {id: "adaptor-related protein complex 3 subunit mu-2"},
    {id: "clathrin assembly protein assembly protein complex 3 mu-2 medium chain"},
    {id: "clathrin coat assembly protein ap47 homolog 2"},
    {id: "clathrin coat-associated protein ap47 homolog 2"},
    {id: "golgi adaptor ap-1 47 kda protein homolog 2"},
    {id: "ha1 47 kda subunit homolog 2"},
    {id: "mu3b-adaptin"},
    {id: "p47b"},
    {id: "ap-4 complex subunit mu-1"},
    {id: "ap-4 adaptor complex mu subunit"},
    {id: "adaptor-related protein complex 4 subunit mu-1"},
    {id: "mu subunit of ap-4"},
    {id: "mu-adaptin-related protein 2"},
    {id: "mu-arp2"},
    {id: "mu4-adaptin"},
    {id: "mu4"},
    {id: "ap-5 complex subunit beta-1"},
    {id: "adaptor-related protein complex 5 beta subunit"},
    {id: "beta5"},
    {id: "ap-5 complex subunit mu-1"},
    {id: "adaptor-related protein complex 5 subunit mu-1"},
    {id: "mu5"},
    {id: "mu-2-related death-inducing protein"},
    {id: "mud"},
    {id: "arl14 effector protein"},
    {id: "arf7 effector protein"},
    {id: "aspscr1 tether for slc2a4, ubx domain containing"},
    {id: "aspscr1 protein"},
    {id: "at-rich interactive domain-containing protein 3"},
    {id: "arid domain-containing protein"},
    {id: "atip4"},
    {id: "microtubule associated scaffold protein 1"},
    {id: "atp binding cassette subfamily a member 3"},
    {id: "abca3 protein"},
    {id: "atp binding cassette subfamily b member 10"},
    {id: "atp-binding cassette, sub-family b (mdr/tap), member 10"},
    {id: "atp binding cassette subfamily c member 5"},
    {id: "abcc5 protein"},
    {id: "atp receptor"},
    {id: "purinergic receptor"},
    {id: "atp synthase f"},
    {id: "atp synthase peripheral stalk-membrane subunit b"},
    {id: "atp synthase subunit b"},
    {id: "atpase subunit b"},
    {id: "atp synthase lipid-binding protein"},
    {id: "atp synthase membrane subunit c locus 2"},
    {id: "atp synthase proteolipid p2"},
    {id: "atpase protein 9"},
    {id: "atpase subunit c"},
    {id: "atp synthase f1 subunit epsilon"},
    {id: "atpase inhibitor, mitochondrial"},
    {id: "atp synthase membrane subunit k, mitochondrial"},
    {id: "atp synthase membrane subunit dapit, mitochondrial"},
    {id: "diabetes-associated protein in insulin-sensitive tissues"},
    {id: "up-regulated during skeletal muscle growth protein 5"},
    {id: "atp synthase protein 8"},
    {id: "a6l"},
    {id: "chargerin ii"},
    {id: "f-atpase subunit 8"},
    {id: "atp synthase subunit c lysine n-methyltransferase"},
    {id: "ec 2.1.1.-"},
    {id: "protein n-lysine methyltransferase fam173b"},
    {id: "atp synthase subunit o, mitochondrial"},
    {id: "atp synthase peripheral stalk subunit oscp"},
    {id: "oligomycin sensitivity conferral protein"},
    {id: "oscp"},
    {id: "sperm flagella protein 4"},
    {id: "atp synthase subunit alpha, mitochondrial"},
    {id: "atp synthase f1 subunit alpha"},
    {id: "atp synthase subunit beta"},
    {id: "ec 7.1.2.2"},
    {id: "atp synthase subunit f, mitochondrial"},
    {id: "atp synthase membrane subunit f"},
    {id: "atp synthase subunit g, mitochondrial"},
    {id: "atpase subunit g"},
    {id: "atp synthase membrane subunit g"},
    {id: "atp synthase subunit gamma, mitochondrial"},
    {id: "atp synthase f1 subunit gamma"},
    {id: "f-atpase gamma subunit"},
    {id: "atp synthase subunit s, mitochondrial"},
    {id: "atp synthase-coupling factor b"},
    {id: "distal membrane arm assembly complex 2-like protein"},
    {id: "mitochondrial atp synthase regulatory component factor b"},
    {id: "atp synthase-coupling factor 6, mitochondrial"},
    {id: "atp synthase peripheral stalk subunit f6"},
    {id: "atp-binding cassette sub-family b member 6"},
    {id: "abc-type heme transporter abcb6"},
    {id: "ec 7.6.2.5"},
    {id: "ubiquitously-expressed mammalian abc half transporter"},
    {id: "atp-binding cassette sub-family c member 6"},
    {id: "ec 7.6.2.-"},
    {id: "mrp-like protein 1"},
    {id: "mlp-1"},
    {id: "multidrug resistance-associated protein 6"},
    {id: "atp-binding cassette sub-family d member 1"},
    {id: "ec 3.1.2.-"},
    {id: "adrenoleukodystrophy protein"},
    {id: "aldp"},
    {id: "atp-binding cassette sub-family d member 2"},
    {id: "adrenoleukodystrophy-related protein"},
    {id: "atp-binding cassette sub-family g member 5"},
    {id: "sterolin-1"},
    {id: "atp-binding cassette sub-family g member 8"},
    {id: "sterolin-2"},
    {id: "atp-binding cassette, sub-family a"},
    {id: "abc1"},
    {id: "atp-binding cassette, sub-family g"},
    {id: "white"},
    {id: "atp-binding cassette, subfamily g (white), member 3-like 1"},
    {id: "atp-binding cassette, subfamily a"},
    {id: "atp-binding cassette, subfamily g"},
    {id: "similar to atp-binding cassette, sub-family g (white), member 3"},
    {id: "atp-dependent"},
    {id: "ec 4.2.1.93"},
    {id: "atp-dependent nad(p)hx dehydratase"},
    {id: "carbohydrate kinase domain-containing protein"},
    {id: "atp-dependent 6-phosphofructokinase"},
    {id: "atp-pfk"},
    {id: "phosphofructokinase"},
    {id: "atp-dependent 6-phosphofructokinase, platelet type"},
    {id: "pfk-p"},
    {id: "6-phosphofructokinase type c"},
    {id: "phosphofructo-1-kinase isozyme c"},
    {id: "pfk-c"},
    {id: "atp-dependent clp protease proteolytic subunit"},
    {id: "ec 3.4.21.92"},
    {id: "atp-dependent dna helicase"},
    {id: "ec 3.6.4.12"},
    {id: "atp-dependent dna helicase pif1"},
    {id: "dna repair and recombination helicase pif1"},
    {id: "pif1/rrm3 dna helicase-like protein"},
    {id: "atp-dependent dna helicase q1"},
    {id: "dna-dependent atpase q1"},
    {id: "recq protein-like 1"},
    {id: "atp-dependent dna helicase q5"},
    {id: "dna helicase, recq-like type 5"},
    {id: "recq5"},
    {id: "recq protein-like 5"},
    {id: "atp-dependent dna/rna helicase dhx36"},
    {id: "ec 3.6.4.13"},
    {id: "dead/h box polypeptide 36"},
    {id: "deah-box protein 36"},
    {id: "g4-resolvase-1"},
    {id: "g4r1"},
    {id: "mle-like protein 1"},
    {id: "rna helicase associated with au-rich element protein"},
    {id: "atp-dependent rna helicase"},
    {id: "atp-dependent rna helicase ddx1"},
    {id: "dead box protein 1"},
    {id: "atp-dependent rna helicase ddx39a"},
    {id: "dead box protein 39"},
    {id: "nuclear rna helicase, decd variant of dead box family"},
    {id: "atp-dependent rna helicase dhx29"},
    {id: "deah box protein 29"},
    {id: "atp-dependent rna helicase supv3l1, mitochondrial"},
    {id: "suppressor of var1 3-like protein 1"},
    {id: "atp-dependent rna helicase tdrd9"},
    {id: "tudor domain-containing protein 9"},
    {id: "atp-sensitive inward rectifier potassium channel 10"},
    {id: "atp-sensitive inward rectifier potassium channel kab-2"},
    {id: "bir10"},
    {id: "brain-specific inwardly rectifying k(+) channel 1"},
    {id: "birk1"},
    {id: "inward rectifier k(+) channel kir4.1"},
    {id: "potassium channel, inwardly rectifying subfamily j member 10"},
    {id: "atp-sensitive inward rectifier potassium channel 11"},
    {id: "bir"},
    {id: "inward rectifier k(+) channel kir6.2"},
    {id: "potassium channel, inwardly rectifying subfamily j member 11"},
    {id: "atp-sensitive inward rectifier potassium channel 14"},
    {id: "inward rectifier k(+) channel kir2.4"},
    {id: "irk-4"},
    {id: "potassium channel, inwardly rectifying subfamily j member 14"},
    {id: "atp-sensitive inward rectifier potassium channel 15"},
    {id: "inward rectifier k(+) channel kir4.2"},
    {id: "potassium channel, inwardly rectifying subfamily j member 15"},
    {id: "atpase get3"},
    {id: "ec 3.6.-.-"},
    {id: "arsenical pump-driving atpase"},
    {id: "arsenite-stimulated atpase"},
    {id: "guided entry of tail-anchored proteins factor 3, atpase"},
    {id: "atpase h+ transporting v0 subunit a2"},
    {id: "cc1-3"},
    {id: "atpase na+/k+ transporting family member beta 4"},
    {id: "ac2-628"},
    {id: "atpase paat"},
    {id: "ec 3.6.1.-"},
    {id: "protein associated with abc transporters"},
    {id: "paat"},
    {id: "atpase wrnip1"},
    {id: "werner helicase-interacting protein 1"},
    {id: "atpase family gene 2 protein homolog b"},
    {id: "ec 3.6.4.10"},
    {id: "afg2 aaa atpase homolog b"},
    {id: "ribosome biogenesis protein spata5l1"},
    {id: "spermatogenesis-associated protein 5-like protein 1"},
    {id: "atpase family, aaa domain containing 1"},
    {id: "ab2-088"},
    {id: "atpase phospholipid transporting 10d"},
    {id: "putative"},
    {id: "atpase phospholipid transporting 8b4"},
    {id: "atp8b4 protein"},
    {id: "aa1017"},
    {id: "kruppel-like factor 6"},
    {id: "aa1018"},
    {id: "vitronectin"},
    {id: "aa1114"},
    {id: "sgt1 homolog, mis12 kinetochore complex assembly cochaperone"},
    {id: "aa2-166"},
    {id: "mannosidase, alpha, class 1a, member 1"},
    {id: "aa2-174"},
    {id: "acyl-coa synthetase medium-chain family member 5"},
    {id: "cc1-38"},
    {id: "aa2-245"},
    {id: "aldehyde oxidase 4"},
    {id: "aarf domain containing kinase 2"},
    {id: "adck2 protein"},
    {id: "ab1-114"},
    {id: "mitochondrial ornithine transporter 1"},
    {id: "solute carrier family 25 member 15"},
    {id: "ab1-119"},
    {id: "phosphotyrosine interaction domain containing 1"},
    {id: "ab1-133"},
    {id: "zinc finger, nfx1-type containing 1"},
    {id: "ab1-219"},
    {id: "steap4 metalloreductase"},
    {id: "ab2-001"},
    {id: "ab2-011"},
    {id: "ab2-008"},
    {id: "poly (adp-ribose) polymerase family, member 4"},
    {id: "ab2-073"},
    {id: "similar to 60s ribosomal protein l7"},
    {id: "ab2-095"},
    {id: "divergent protein kinase domain 2a"},
    {id: "ab2-142"},
    {id: "complement factor h-related 1"},
    {id: "ab2-183"},
    {id: "interleukin 2 receptor subunit gamma"},
    {id: "ab2-255"},
    {id: "insulin-like growth factor 2 mrna binding protein 3"},
    {id: "ab2-276"},
    {id: "major facilitator superfamily domain containing 8"},
    {id: "ab2-402"},
    {id: "spermidine/spermine n1-acetyl transferase 1"},
    {id: "ab2-427"},
    {id: "tripartite motif-containing 24"},
    {id: "ab2-450"},
    {id: "mannosidase, alpha, class 2b, member 2"},
    {id: "abasic site processing protein hmces"},
    {id: "embryonic stem cell-specific 5-hydroxymethylcytosine-binding protein"},
    {id: "es cell-specific 5hmc-binding protein"},
    {id: "peptidase hmces"},
    {id: "ec 3.4.-.-"},
    {id: "srap domain-containing protein 1"},
    {id: "abi1 protein"},
    {id: "abl-interactor 1"},
    {id: "ac1-289"},
    {id: "ac1071"},
    {id: "lrrgt00006"},
    {id: "ac1158"},
    {id: "ribosomal l1 domain containing 1"},
    {id: "ac1164"},
    {id: "pyruvate dehydrogenase complex, component x"},
    {id: "ac1177"},
    {id: "liver-expressed antimicrobial peptide 2"},
    {id: "ac1288"},
    {id: "hook microtubule-tethering protein 3"},
    {id: "ac1576"},
    {id: "uncharacterized loc102552783"},
    {id: "ac2-120"},
    {id: "coagulation factor v"},
    {id: "ac2-125"},
    {id: "farnesyl diphosphate synthase"},
    {id: "ac2-202"},
    {id: "lactate dehydrogenase d"},
    {id: "ac2-233"},
    {id: "interferon gamma induced gtpase"},
    {id: "ac2-248"},
    {id: "serpin family c member 1"},
    {id: "ac2-256"},
    {id: "yip1 domain family, member 5"},
    {id: "acetoacetyl-coa synthetase"},
    {id: "ec 6.2.1.16"},
    {id: "acetyl-coa acetyltransferase, cytosolic"},
    {id: "cytosolic acetoacetyl-coa thiolase"},
    {id: "acetyl-coa carboxylase 1"},
    {id: "ec 6.4.1.2"},
    {id: "acetyl-coenzyme a synthetase"},
    {id: "ec 6.2.1.1"},
    {id: "acetyl-coenzyme a transporter 1"},
    {id: "at-1"},
    {id: "acetyl-coa transporter 1"},
    {id: "solute carrier family 33 member 1"},
    {id: "acetylserotonin o-methyltransferase"},
    {id: "acetylserotonin o-methyltransferase variant 2 (ec 2.1.1.4)"},
    {id: "ec 2.1.1.4"},
    {id: "hydroxyindole o-methyltransferase"},
    {id: "acetyltransferase component of pyruvate dehydrogenase complex"},
    {id: "ec 2.3.1.12"},
    {id: "achaete-scute homolog 2"},
    {id: "mash2"},
    {id: "acid sphingomyelinase-like phosphodiesterase 3a"},
    {id: "asm-like phosphodiesterase 3a"},
    {id: "ec 3.1.4.-"},
    {id: "acid-sensing ion channel 1"},
    {id: "asic1"},
    {id: "amiloride-sensitive cation channel 2, neuronal"},
    {id: "brain sodium channel 2"},
    {id: "bnac2"},
    {id: "acid-sensing ion channel 3"},
    {id: "asic3"},
    {id: "amiloride-sensitive cation channel 3"},
    {id: "dorsal root asic"},
    {id: "drasic"},
    {id: "acid-sensing ion channel 5"},
    {id: "asic5"},
    {id: "amiloride-sensitive cation channel 5"},
    {id: "brain-liver-intestine amiloride-sensitive na(+) channel"},
    {id: "blinac"},
    {id: "acidic leucine-rich nuclear phosphoprotein 32 family member b"},
    {id: "proliferation-related acidic leucine-rich protein pal31"},
    {id: "acireductone dioxygenase"},
    {id: "acireductone dioxygenase (fe(2+)-requiring)"},
    {id: "ard'"},
    {id: "fe-ard"},
    {id: "ec 1.13.11.54"},
    {id: "acireductone dioxygenase (ni(2+)-requiring)"},
    {id: "ard"},
    {id: "ni-ard"},
    {id: "ec 1.13.11.53"},
    {id: "androgen-responsive ard-like protein 1"},
    {id: "membrane-type 1 matrix metalloproteinase cytoplasmic tail-binding protein 1"},
    {id: "mtcbp-1"},
    {id: "aconitate hydratase, mitochondrial"},
    {id: "aconitase"},
    {id: "ec 4.2.1.3"},
    {id: "citrate hydro-lyase"},
    {id: "acrosin"},
    {id: "ec 3.4.21.10"},
    {id: "cleaved into: acrosin light chain; acrosin heavy chain"},
    {id: "acrosin-binding protein"},
    {id: "acrosin-binding protein, 60 kda form"},
    {id: "proacrosin-binding protein sp32"},
    {id: "actin maturation protease"},
    {id: "actin aminopeptidase actmap"},
    {id: "actin, alpha cardiac muscle 1"},
    {id: "ec 3.6.4.-"},
    {id: "alpha-cardiac actin"},
    {id: "cleaved into: actin, alpha cardiac muscle 1, intermediate form"},
    {id: "actin, alpha skeletal muscle"},
    {id: "alpha-actin-1"},
    {id: "cleaved into: actin, alpha skeletal muscle, intermediate form"},
    {id: "actin, aortic smooth muscle"},
    {id: "alpha-actin-2"},
    {id: "cleaved into: actin, aortic smooth muscle, intermediate form"},
    {id: "actin, cytoplasmic 1"},
    {id: "beta-actin"},
    {id: "cleaved into: actin, cytoplasmic 1, n-terminally processed"},
    {id: "actin, cytoplasmic 2"},
    {id: "gamma-actin"},
    {id: "cleaved into: actin, cytoplasmic 2, n-terminally processed"},
    {id: "actin-binding lim protein 2"},
    {id: "ablim-2"},
    {id: "actin-binding lim protein family member 2"},
    {id: "actin-binding rho-activating protein"},
    {id: "ms1"},
    {id: "striated muscle activator of rho-dependent signaling"},
    {id: "stars"},
    {id: "actin-histidine n-methyltransferase"},
    {id: "ec 2.1.1.85"},
    {id: "protein-l-histidine n-tele-methyltransferase"},
    {id: "set domain-containing protein 3"},
    {id: "actin-related protein 2/3 complex subunit 1b"},
    {id: "arp2/3 complex 41 kda subunit"},
    {id: "p41-arc"},
    {id: "actin-related protein 2/3 complex subunit 5-like protein"},
    {id: "arp2/3 complex 16 kda subunit 2"},
    {id: "arc16-2"},
    {id: "actin-related protein 3"},
    {id: "actin-like protein 3"},
    {id: "actinin, alpha 1"},
    {id: "actn1 protein"},
    {id: "alpha-actinin-1"},
    {id: "brain-specific alpha actinin 1 isoform"},
    {id: "activated rna polymerase ii transcriptional coactivator p15"},
    {id: "positive cofactor 4"},
    {id: "pc4"},
    {id: "sub1 homolog"},
    {id: "p14"},
    {id: "active regulator of sirt1"},
    {id: "40s ribosomal protein s19-binding protein 1"},
    {id: "activin receptor type-1b"},
    {id: "ec 2.7.11.30"},
    {id: "activin receptor type ib"},
    {id: "actr-ib"},
    {id: "activin receptor-like kinase 4"},
    {id: "alk-4"},
    {id: "serine/threonine-protein kinase receptor r2"},
    {id: "skr2"},
    {id: "activity-dependent neuroprotector homeobox protein"},
    {id: "activity-dependent neuroprotective protein"},
    {id: "activity-regulated cytoskeleton-associated protein"},
    {id: "activity-regulated gene 3.1 protein"},
    {id: "arc/arg3.1"},
    {id: "arg3.1"},
    {id: "acyl-coa 6-desaturase"},
    {id: "ec 1.14.19.3"},
    {id: "delta(6) fatty acid desaturase"},
    {id: "d6d"},
    {id: "delta(6) desaturase"},
    {id: "delta-6 desaturase"},
    {id: "fatty acid desaturase 2"},
    {id: "acyl-coa dehydrogenase family member 11"},
    {id: "acad-11"},
    {id: "ec 1.3.8.-"},
    {id: "acyl-coa dehydrogenase family, member 10"},
    {id: "rgd1310159 protein"},
    {id: "acyl-coa desaturase 1"},
    {id: "ec 1.14.19.1"},
    {id: "delta(9)-desaturase 1"},
    {id: "delta-9 desaturase 1"},
    {id: "fatty acid desaturase 1"},
    {id: "stearoyl-coa desaturase 1"},
    {id: "acyl-coa synthetase short-chain family member 3, mitochondrial"},
    {id: "acetate--coa ligase 3"},
    {id: "acyl-coa synthetase short-chain family member 3"},
    {id: "propionate--coa ligase"},
    {id: "ec 6.2.1.17"},
    {id: "acyl-coenzyme a diphosphatase nudt19"},
    {id: "nucleoside diphosphate-linked moiety x motif 19"},
    {id: "nudix motif 19"},
    {id: "acyl-coenzyme a synthetase acsm4, mitochondrial"},
    {id: "ec 6.2.1.2"},
    {id: "olfactory specific medium-chain acyl coa synthetase"},
    {id: "o-macs"},
    {id: "acyl-coenzyme a synthetase acsm5, mitochondrial"},
    {id: "acyl-coenzyme a thioesterase them4"},
    {id: "acyl-coa thioesterase them4"},
    {id: "ec 3.1.2.2"},
    {id: "thioesterase superfamily member 4"},
    {id: "acyl-protein thioesterase 2"},
    {id: "apt-2"},
    {id: "lysophospholipase 2"},
    {id: "lysophospholipase ii"},
    {id: "lpl-ii"},
    {id: "lysopla ii"},
    {id: "palmitoyl-protein hydrolase"},
    {id: "ec 3.1.2.22"},
    {id: "acylglycerol kinase, mitochondrial"},
    {id: "ec 2.7.1.107"},
    {id: "ec 2.7.1.138"},
    {id: "ec 2.7.1.94"},
    {id: "multiple substrate lipid kinase"},
    {id: "acylphosphatase"},
    {id: "ec 3.6.1.7"},
    {id: "acylpyruvase fahd1, mitochondrial"},
    {id: "ec 3.7.1.5"},
    {id: "fumarylacetoacetate hydrolase domain-containing protein 1"},
    {id: "oxaloacetate decarboxylase"},
    {id: "oaa decarboxylase"},
    {id: "ec 4.1.1.112"},
    {id: "acyltransferase"},
    {id: "ec 2.3.1.-"},
    {id: "adapter molecule crk"},
    {id: "proto-oncogene c-crk"},
    {id: "p38"},
    {id: "adaptin ear-binding coat-associated protein 2"},
    {id: "necap-2"},
    {id: "adaptor related protein complex 3 subunit sigma 2"},
    {id: "loc683402 protein"},
    {id: "adenine dna glycosylase"},
    {id: "ec 3.2.2.31"},
    {id: "adenine phosphoribosyltransferase"},
    {id: "ec 2.4.2.7"},
    {id: "adenosine receptor a3"},
    {id: "tgpcr1"},
    {id: "adenosylhomocysteinase"},
    {id: "adohcyase"},
    {id: "ec 3.13.2.1"},
    {id: "s-adenosyl-l-homocysteine hydrolase"},
    {id: "adenylate cyclase type 1"},
    {id: "ec 4.6.1.1"},
    {id: "adenylate cyclase type 10"},
    {id: "germ cell soluble adenylyl cyclase"},
    {id: "sac"},
    {id: "testicular soluble adenylyl cyclase"},
    {id: "adenylate cyclase type 2"},
    {id: "adenylate cyclase type 3"},
    {id: "adenylate cyclase type 4"},
    {id: "adenylate cyclase type 5"},
    {id: "adenylate cyclase type 7"},
    {id: "adenylate cyclase type 8"},
    {id: "adenylate kinase 2, mitochondrial"},
    {id: "ak 2"},
    {id: "ec 2.7.4.3"},
    {id: "atp-amp transphosphorylase 2"},
    {id: "atp:amp phosphotransferase"},
    {id: "adenylate monophosphate kinase"},
    {id: "adenylate kinase 4, mitochondrial"},
    {id: "ak 4"},
    {id: "ec 2.7.4.10"},
    {id: "ec 2.7.4.6"},
    {id: "adenylate kinase 3-like"},
    {id: "gtp:amp phosphotransferase ak4"},
    {id: "adenylate kinase 8"},
    {id: "ak 8"},
    {id: "atp-amp transphosphorylase 8"},
    {id: "adenylate kinase isoenzyme 1"},
    {id: "ak 1"},
    {id: "atp-amp transphosphorylase 1"},
    {id: "myokinase"},
    {id: "adenylate kinase isoenzyme 6"},
    {id: "ak6"},
    {id: "coilin-interacting nuclear atpase protein"},
    {id: "dual activity adenylate kinase/atpase"},
    {id: "ak/atpase"},
    {id: "adenylosuccinate lyase"},
    {id: "asl"},
    {id: "ec 4.3.2.2"},
    {id: "adenylosuccinase"},
    {id: "adenylosuccinate synthetase"},
    {id: "ampsase"},
    {id: "adss"},
    {id: "ec 6.3.4.4"},
    {id: "imp--aspartate ligase"},
    {id: "adenylosuccinate synthetase isozyme 1"},
    {id: "ampsase 1"},
    {id: "adss 1"},
    {id: "adenylosuccinate synthetase, basic isozyme"},
    {id: "adenylosuccinate synthetase, muscle isozyme"},
    {id: "m-type adenylosuccinate synthetase"},
    {id: "imp--aspartate ligase 1"},
    {id: "adenylosuccinate synthetase isozyme 2"},
    {id: "ampsase 2"},
    {id: "adss 2"},
    {id: "adenylosuccinate synthetase, acidic isozyme"},
    {id: "adenylosuccinate synthetase, liver isozyme"},
    {id: "l-type adenylosuccinate synthetase"},
    {id: "imp--aspartate ligase 2"},
    {id: "adenylyl cyclase-associated protein 2"},
    {id: "cap 2"},
    {id: "adenylyltransferase and sulfurtransferase mocs3"},
    {id: "molybdenum cofactor synthesis protein 3"},
    {id: "includes: molybdopterin-synthase adenylyltransferase (ec 2.7.7.80) (adenylyltransferase mocs3) (sulfur carrier protein mocs2a adenylyltransferase); molybdopterin-synthase sulfurtransferase (ec 2.8.1.11) (sulfurtransferase mocs3) (sulfur carrier protein mocs2a sulfurtransferase)"},
    {id: "adhesion g protein-coupled receptor e2"},
    {id: "egf-like module receptor 1"},
    {id: "egf-like module-containing mucin-like hormone receptor-like 1"},
    {id: "emr1 hormone receptor"},
    {id: "adhesion g protein-coupled receptor f5"},
    {id: "g-protein coupled hepta-helical receptor ig-hepta"},
    {id: "g-protein coupled receptor 116"},
    {id: "adhesion g protein-coupled receptor l4"},
    {id: "egf,latrophilin and seven transmembrane domain-containing protein 1"},
    {id: "egf-tm7-latrophilin-related protein"},
    {id: "etl protein"},
    {id: "adhesion g-protein coupled receptor g1"},
    {id: "g-protein coupled receptor 56"},
    {id: "adhesion g-protein coupled receptor g2"},
    {id: "g-protein coupled receptor 64"},
    {id: "rat epididymis-specific protein 6"},
    {id: "re6"},
    {id: "adhesion molecule with ig like domain 2"},
    {id: "amphoterin-induced protein 2"},
    {id: "adipocyte enhancer-binding protein 1"},
    {id: "ae-binding protein 1"},
    {id: "aortic carboxypeptidase-like protein"},
    {id: "adiponectin a"},
    {id: "complement c1q b chain"},
    {id: "adiponectin b"},
    {id: "complement c1q c chain"},
    {id: "adiponectin c"},
    {id: "complement c1q a chain"},
    {id: "adiponectin d"},
    {id: "adiponectin, c1q and collagen domain containing"},
    {id: "adiponectin f1"},
    {id: "c1q and tnf related 9"},
    {id: "adiponectin h"},
    {id: "c1q and tnf related 2"},
    {id: "adiponectin j"},
    {id: "complement c1q like 3"},
    {id: "adiponectin k"},
    {id: "complement c1q like 4"},
    {id: "adiponectin l"},
    {id: "complement c1q like 1"},
    {id: "adora3 protein"},
    {id: "transmembrane and immunoglobulin domain containing 3"},
    {id: "adrenocorticotropic hormone receptor"},
    {id: "adrenocorticotropin receptor"},
    {id: "melanocortin receptor 2"},
    {id: "advillin"},
    {id: "peripheral nervous system villin-like protein"},
    {id: "pervin"},
    {id: "afadin"},
    {id: "afadin adherens junction formation factor"},
    {id: "protein af-6"},
    {id: "afadin- and alpha-actinin-binding protein"},
    {id: "adip"},
    {id: "afadin dil domain-interacting protein"},
    {id: "aifm3 protein"},
    {id: "apoptosis inducing factor, mitochondria associated 3"},
    {id: "aig1 protein"},
    {id: "androgen-induced 1"},
    {id: "akirin-2"},
    {id: "fourteen-three-three beta interactant 1"},
    {id: "fourteen-three-three beta interacting-protein 1"},
    {id: "alanine aminotransferase 1"},
    {id: "alt1"},
    {id: "ec 2.6.1.2"},
    {id: "glutamate pyruvate transaminase 1"},
    {id: "gpt 1"},
    {id: "glutamic--alanine transaminase 1"},
    {id: "glutamic--pyruvic transaminase 1"},
    {id: "alanine--glyoxylate aminotransferase 2, mitochondrial"},
    {id: "agt 2"},
    {id: "ec 2.6.1.44"},
    {id: "(r)-3-amino-2-methylpropionate--pyruvate transaminase"},
    {id: "ec 2.6.1.40"},
    {id: "beta-alaat ii"},
    {id: "beta-alanine-pyruvate aminotransferase"},
    {id: "d-aibat"},
    {id: "alanine--trna ligase"},
    {id: "ec 6.1.1.7"},
    {id: "alanine--trna ligase, cytoplasmic"},
    {id: "alanyl-trna synthetase"},
    {id: "alars"},
    {id: "alanyl-trna editing protein aarsd1"},
    {id: "alanyl-trna synthetase domain-containing protein 1"},
    {id: "alcohol dehydrogenase 4"},
    {id: "class ii"},
    {id: "alcohol dehydrogenase 6"},
    {id: "class v"},
    {id: "alcohol dehydrogenase 6a"},
    {id: "alcohol dehydrogenase 7"},
    {id: "class iv"},
    {id: "alcohol dehydrogenase class-3"},
    {id: "ec 1.1.1.1"},
    {id: "alcohol dehydrogenase 2"},
    {id: "alcohol dehydrogenase 5"},
    {id: "alcohol dehydrogenase b2"},
    {id: "adh-b2"},
    {id: "alcohol dehydrogenase class-iii"},
    {id: "glutathione-dependent formaldehyde dehydrogenase"},
    {id: "faldh"},
    {id: "fdh"},
    {id: "gsh-fdh"},
    {id: "s-(hydroxymethyl)glutathione dehydrogenase"},
    {id: "ec 1.1.1.284"},
    {id: "alcohol sulfotransferase"},
    {id: "ec 2.8.2.2"},
    {id: "hydroxysteroid sulfotransferase"},
    {id: "st"},
    {id: "st-60"},
    {id: "alcohol sulfotransferase-like"},
    {id: "lrrgt00036"},
    {id: "aldehyde dehydrogenase family 3 member b1"},
    {id: "ec 1.2.1.28"},
    {id: "ec 1.2.1.5"},
    {id: "ec 1.2.1.7"},
    {id: "aldo-keto reductase family 1 member a1"},
    {id: "ec 1.1.1.19"},
    {id: "ec 1.1.1.2"},
    {id: "ec 1.1.1.20"},
    {id: "ec 1.1.1.372"},
    {id: "ec 1.1.1.54"},
    {id: "alcohol dehydrogenase [nadp(+)]"},
    {id: "aldehyde reductase"},
    {id: "glucuronate reductase"},
    {id: "glucuronolactone reductase"},
    {id: "ec 1.1.1.33"},
    {id: "3-dg-reducing enzyme"},
    {id: "aldo-keto reductase family 1 member b7"},
    {id: "ec 1.1.1.21"},
    {id: "aldose reductase-like protein akr1b14"},
    {id: "aldose reductase-related protein 1"},
    {id: "aldo-keto reductase family 1 member c15"},
    {id: "ec 1.1.1.216"},
    {id: "rakc"},
    {id: "aldo-keto reductase family 1 member c18"},
    {id: "ec 1.1.-.-"},
    {id: "20-alpha-hydroxysteroid dehydrogenase"},
    {id: "20-alpha-hsd"},
    {id: "ec 1.1.1.149"},
    {id: "hsd1"},
    {id: "aldo-keto reductase family 1, member b1"},
    {id: "aldose reductase"},
    {id: "aldo-keto reductase family 1, member c1"},
    {id: "aldo-keto reductase family 1, member c1 (dihydrodiol dehydrogenase 1 20-alpha (3-alpha)-hydroxysteroid dehydrogenase)"},
    {id: "type 5 17beta-hydroxysteroid dehydrogenase (ec 1.1.1.62)"},
    {id: "aldose 1-epimerase"},
    {id: "ec 5.1.3.3"},
    {id: "alkaline ceramidase"},
    {id: "ec 3.5.1.-"},
    {id: "alkaline phosphatase"},
    {id: "ec 3.1.3.1"},
    {id: "alkyl transferase"},
    {id: "alkylated dna repair protein alkb homolog 8"},
    {id: "ec 2.1.1.229"},
    {id: "probable alpha-ketoglutarate-dependent dioxygenase abh8"},
    {id: "s-adenosyl-l-methionine-dependent trna methyltransferase abh8"},
    {id: "trna (carboxymethyluridine(34)-5-o)-methyltransferase abh8"},
    {id: "alkylglycerol monooxygenase"},
    {id: "ec 1.14.16.5"},
    {id: "transmembrane protein 195"},
    {id: "alkylglycerone-phosphate synthase"},
    {id: "alkyl-dhap synthase"},
    {id: "ec 2.5.1.26"},
    {id: "all trans-polyprenyl-diphosphate synthase pdss2"},
    {id: "all-trans-decaprenyl-diphosphate synthase subunit 2"},
    {id: "ec 2.5.1.91"},
    {id: "decaprenyl-diphosphate synthase subunit 2"},
    {id: "solanesyl-diphosphate synthase subunit 2"},
    {id: "alox12 protein"},
    {id: "arachidonate 12-lipoxygenase, 12s type"},
    {id: "alpha-"},
    {id: "1,3"},
    {id: "ec 2.4.1.-"},
    {id: "fucosyltransferase xi"},
    {id: "fuc-txi"},
    {id: "fuct-xi"},
    {id: "galactoside 3-l-fucosyltransferase 11"},
    {id: "fucosyltransferase 11"},
    {id: "fucosyltransferase 7"},
    {id: "fucosyltransferase vii"},
    {id: "fuc-tvii"},
    {id: "fuct-vii"},
    {id: "selectin ligand synthase"},
    {id: "1,6"},
    {id: "ec 2.4.1.68"},
    {id: "gdp-l-fuc:n-acetyl-beta-d-glucosaminide alpha1,6-fucosyltransferase"},
    {id: "gdp-fucose--glycoprotein fucosyltransferase"},
    {id: "glycoprotein 6-alpha-l-fucosyltransferase"},
    {id: "alpha-1,3-glucosyltransferase"},
    {id: "alpha-1,3-mannosyl-glycoprotein 2-beta-n-acetylglucosaminyltransferase"},
    {id: "ec 2.4.1.101"},
    {id: "n-glycosyl-oligosaccharide-glycoprotein n-acetylglucosaminyltransferase i"},
    {id: "gnt-i"},
    {id: "glcnac-t i"},
    {id: "alpha-1,3/1,6-mannosyltransferase alg2"},
    {id: "ec 2.4.1.132"},
    {id: "ec 2.4.1.257"},
    {id: "gdp-man:man(1)glcnac(2)-pp-dol alpha-1,3-mannosyltransferase"},
    {id: "alpha-1,6-mannosyl-glycoprotein 2-beta-n-acetylglucosaminyltransferase"},
    {id: "ec 2.4.1.143"},
    {id: "beta-1,2-n-acetylglucosaminyltransferase ii"},
    {id: "glcnac-t ii"},
    {id: "gnt-ii"},
    {id: "mannoside acetylglucosaminyltransferase 2"},
    {id: "n-glycosyl-oligosaccharide-glycoprotein n-acetylglucosaminyltransferase ii"},
    {id: "alpha-1,6-mannosylglycoprotein 6-beta-n-acetylglucosaminyltransferase a"},
    {id: "ec 2.4.1.155"},
    {id: "alpha-mannoside beta-1,6-n-acetylglucosaminyltransferase"},
    {id: "glcnac-t v"},
    {id: "gnt-v"},
    {id: "mannoside acetylglucosaminyltransferase 5"},
    {id: "n-acetylglucosaminyl-transferase v"},
    {id: "cleaved into: secreted alpha-1,6-mannosylglycoprotein 6-beta-n-acetylglucosaminyltransferase a (secreted beta-1,6-n-acetylglucosaminyltransferase v) (secreted gnt-v)"},
    {id: "alpha-1a adrenergic receptor"},
    {id: "alpha-1a adrenoreceptor"},
    {id: "alpha-1c adrenergic receptor"},
    {id: "alpha-1b-glycoprotein"},
    {id: "alpha-1-b glycoprotein"},
    {id: "c44"},
    {id: "liver regeneration-related protein 1"},
    {id: "alpha-1d adrenergic receptor"},
    {id: "alpha-1d adrenoreceptor"},
    {id: "alpha-2,8-sialyltransferase 8b"},
    {id: "ec 2.4.99.-"},
    {id: "sialyltransferase 8b"},
    {id: "siat8-b"},
    {id: "sialyltransferase st8sia ii"},
    {id: "st8siaii"},
    {id: "sialyltransferase x"},
    {id: "stx"},
    {id: "alpha-2,8-sialyltransferase 8e"},
    {id: "sialyltransferase 8e"},
    {id: "siat8-e"},
    {id: "sialyltransferase st8sia v"},
    {id: "satv"},
    {id: "st8siav"},
    {id: "alpha-2-hs-glycoprotein"},
    {id: "59 kda bone sialic acid-containing protein"},
    {id: "bsp"},
    {id: "fetuin-a"},
    {id: "glycoprotein pp63"},
    {id: "alpha-2-macroglobulin receptor-associated protein"},
    {id: "alpha-2-mrap"},
    {id: "gp330-binding 45 kda protein"},
    {id: "low density lipoprotein receptor-related protein-associated protein 1"},
    {id: "rap"},
    {id: "alpha-2u globulin pgcl4"},
    {id: "obp3 protein"},
    {id: "alpha-n-acetylgalactosaminidase"},
    {id: "ec 3.2.1.49"},
    {id: "alpha-galactosidase b"},
    {id: "alpha-actinin cytoskeletal isoform"},
    {id: "f-actin cross-linking protein"},
    {id: "non-muscle alpha-actinin-1"},
    {id: "alpha-aminoadipic semialdehyde dehydrogenase"},
    {id: "alpha-aasa dehydrogenase"},
    {id: "ec 1.2.1.31"},
    {id: "aldehyde dehydrogenase family 7 member a1"},
    {id: "ec 1.2.1.3"},
    {id: "antiquitin-1"},
    {id: "betaine aldehyde dehydrogenase"},
    {id: "ec 1.2.1.8"},
    {id: "delta1-piperideine-6-carboxylate dehydrogenase"},
    {id: "p6c dehydrogenase"},
    {id: "alpha-amylase"},
    {id: "ec 3.2.1.1"},
    {id: "alpha-endosulfine"},
    {id: "arpp-19e"},
    {id: "alpha-galactosidase"},
    {id: "ec 3.2.1.-"},
    {id: "alpha-ketoglutarate-dependent dioxygenase fto"},
    {id: "ec 1.14.11.53"},
    {id: "fat mass and obesity-associated protein"},
    {id: "u6 small nuclear rna (2'-o-methyladenosine-n(6)-)-demethylase fto"},
    {id: "u6 small nuclear rna n(6)-methyladenosine-demethylase fto"},
    {id: "mrna (2'-o-methyladenosine-n(6)-)-demethylase fto"},
    {id: "mrna n(6)-methyladenosine demethylase fto"},
    {id: "trna n1-methyl adenine demethylase fto"},
    {id: "m6a(m)-demethylase fto"},
    {id: "alpha-ketoglutarate-dependent dioxygenase alkb homolog 3"},
    {id: "ec 1.14.11.33"},
    {id: "ec 1.14.11.54"},
    {id: "alkylated dna repair protein alkb homolog 3"},
    {id: "alpha-mannosidase"},
    {id: "alpha-tubulin n-acetyltransferase 1"},
    {id: "alpha-tat"},
    {id: "alpha-tat1"},
    {id: "tat"},
    {id: "ec 2.3.1.108"},
    {id: "acetyltransferase mec-17 homolog"},
    {id: "alpha/beta hydrolase domain-containing protein 17a"},
    {id: "abhydrolase domain-containing protein 17a"},
    {id: "alpha/beta hydrolase domain-containing protein 17b"},
    {id: "abhydrolase domain-containing protein 17b"},
    {id: "alpha/beta hydrolase domain-containing protein 17c"},
    {id: "abhydrolase domain-containing protein 17c"},
    {id: "ameloblastin"},
    {id: "amelin"},
    {id: "amelogenin, x isoform"},
    {id: "leucine-rich amelogenin peptide"},
    {id: "lrap"},
    {id: "amidophosphoribosyltransferase"},
    {id: "atase"},
    {id: "ec 2.4.2.14"},
    {id: "glutamine phosphoribosylpyrophosphate amidotransferase"},
    {id: "gpat"},
    {id: "amine oxidase"},
    {id: "ec 1.4.3.-"},
    {id: "aminoacyl trna synthase complex-interacting multifunctional protein 2"},
    {id: "multisynthase complex auxiliary component p38"},
    {id: "protein jtv-1"},
    {id: "aminomethyltransferase"},
    {id: "ec 2.1.2.10"},
    {id: "glycine cleavage system t protein"},
    {id: "aminopeptidase"},
    {id: "ec 3.4.11.-"},
    {id: "aminopeptidase naaladl1"},
    {id: "100 kda ileum brush border membrane protein"},
    {id: "i100"},
    {id: "ileal dipeptidylpeptidase"},
    {id: "n-acetylated-alpha-linked acidic dipeptidase-like protein"},
    {id: "naaladase l"},
    {id: "ammonium transporter rh type b"},
    {id: "rhesus blood group family type b glycoprotein"},
    {id: "rh family type b glycoprotein"},
    {id: "rh type b glycoprotein"},
    {id: "amphiregulin"},
    {id: "ar"},
    {id: "schwannoma-derived growth factor"},
    {id: "sdgf"},
    {id: "amphoterin-induced protein 1"},
    {id: "amigo-1"},
    {id: "alivin-2"},
    {id: "amphoterin-induced protein 3"},
    {id: "amigo-3"},
    {id: "alivin-3"},
    {id: "amyloid protein-binding protein 2"},
    {id: "amyloid beta precursor protein-binding protein 2"},
    {id: "app-bp2"},
    {id: "amyloid-beta a4 precursor protein-binding family a member 2"},
    {id: "adapter protein x11beta"},
    {id: "neuron-specific x11l protein"},
    {id: "neuronal munc18-1-interacting protein 2"},
    {id: "mint-2"},
    {id: "amyloid-beta a4 precursor protein-binding family a member 3"},
    {id: "adapter protein x11gamma"},
    {id: "neuron-specific x11l2 protein"},
    {id: "neuronal munc18-1-interacting protein 3"},
    {id: "mint-3"},
    {id: "anamorsin"},
    {id: "cytokine-induced apoptosis inhibitor 1"},
    {id: "fe-s cluster assembly protein dre2 homolog"},
    {id: "anaphase-promoting complex subunit 13"},
    {id: "cyclosome subunit 13"},
    {id: "anaphase-promoting complex subunit 15"},
    {id: "apc15"},
    {id: "anaphase-promoting complex subunit 16"},
    {id: "cyclosome subunit 16"},
    {id: "anaphase-promoting complex subunit 4"},
    {id: "cyclosome subunit 4"},
    {id: "anaphase-promoting complex subunit 5"},
    {id: "cyclosome subunit 5"},
    {id: "androgen-binding protein"},
    {id: "androgen-binding protein eta"},
    {id: "secretoglobin, family 1b, member 24"},
    {id: "secretoglobin, family 2b, member 24"},
    {id: "androgen-dependent tfpi-regulating protein"},
    {id: "fatty acid esters of hydroxy fatty acids hydrolase adtrp"},
    {id: "fahfa hydrolase adtrp"},
    {id: "liver regeneration-related protein lrrg140"},
    {id: "angiogenin ribonuclease 2"},
    {id: "angiogenin, ribonuclease a family, member 2"},
    {id: "ribonuclease a a2"},
    {id: "angiopoietin-2"},
    {id: "ang-2"},
    {id: "angiopoietin-related protein 4"},
    {id: "angiopoietin-like protein 4"},
    {id: "hepatic fibrinogen/angiopoietin-related protein"},
    {id: "hfarp"},
    {id: "cleaved into: angptl4 n-terminal chain; angptl4 c-terminal chain"},
    {id: "angiotensin-converting enzyme"},
    {id: "angiotensin-converting enzyme 2"},
    {id: "ec 3.4.17.23"},
    {id: "ace-related carboxypeptidase"},
    {id: "ec 3.4.17.-"},
    {id: "cleaved into: processed angiotensin-converting enzyme 2"},
    {id: "anion exchange transporter"},
    {id: "solute carrier family 26 member 7"},
    {id: "ankib1 protein"},
    {id: "ankyrin repeat and ibr domain containing 1"},
    {id: "ankrd15 protein"},
    {id: "kn motif and ankyrin repeat domains 1"},
    {id: "ankrd49 protein"},
    {id: "ankyrin repeat domain 49"},
    {id: "ankrd9 protein"},
    {id: "ankyrin repeat domain 9"},
    {id: "ankycorbin"},
    {id: "ankyrin repeat and coiled-coil structure-containing protein"},
    {id: "retinoic acid-induced protein 14"},
    {id: "ankyrin repeat and socs box protein 2"},
    {id: "asb-2"},
    {id: "ankyrin repeat and socs box-containing 4"},
    {id: "ankyrin repeat and socs box-containing protein 4"},
    {id: "ankyrin repeat and sterile alpha motif domain-containing protein 1b"},
    {id: "amyloid-beta protein intracellular domain-associated protein 1"},
    {id: "aida-1"},
    {id: "e2a-pbx1-associated protein"},
    {id: "eb-1"},
    {id: "ankyrin repeat domain 36"},
    {id: "spergen-2"},
    {id: "ankyrin repeat domain-containing protein 1"},
    {id: "cardiac adriamycin-responsive protein"},
    {id: "cardiac ankyrin repeat protein"},
    {id: "ankyrin repeat domain-containing protein 46"},
    {id: "ankyrin repeat small protein"},
    {id: "ank-s"},
    {id: "ankyrin repeat family a member 2"},
    {id: "ankyrin repeat, family a (rfxank-like), 2"},
    {id: "slo-interacting ankyrin-containing protein"},
    {id: "ankyrin repeat, sam and basic leucine zipper domain-containing protein 1"},
    {id: "germ cell-specific ankyrin, sam and basic leucine zipper domain-containing protein"},
    {id: "ankyrin-3"},
    {id: "ank-3"},
    {id: "ankyrin-g"},
    {id: "annexin a8"},
    {id: "annexin viii"},
    {id: "annexin-8"},
    {id: "anoctamin-7"},
    {id: "new gene expressed in prostate homolog"},
    {id: "transmembrane protein 16g"},
    {id: "anti-muellerian hormone type-2 receptor"},
    {id: "anti-muellerian hormone type ii receptor"},
    {id: "amh type ii receptor"},
    {id: "c14"},
    {id: "mis type ii receptor"},
    {id: "misrii"},
    {id: "mrii"},
    {id: "antileukoproteinase-like 2"},
    {id: "similar to secretory leukocyte protease inhibitor"},
    {id: "antithrombin-iii"},
    {id: "serpin c1"},
    {id: "anubl1 protein"},
    {id: "zinc finger an1-type containing 4"},
    {id: "apelin receptor"},
    {id: "angiotensin receptor-like 1"},
    {id: "b78"},
    {id: "g-protein coupled receptor apj"},
    {id: "gpcr34"},
    {id: "apolipoprotein c-ii"},
    {id: "apo-cii"},
    {id: "apoc-ii"},
    {id: "apolipoprotein c2"},
    {id: "cleaved into: proapolipoprotein c-ii (proapoc-ii)"},
    {id: "apolipoprotein c-iv"},
    {id: "apo-civ"},
    {id: "apoc-iv"},
    {id: "apolipoprotein c4"},
    {id: "apolipoprotein e-linked"},
    {id: "ecl"},
    {id: "apolipoprotein l domain-containing protein 1"},
    {id: "vascular early response gene protein"},
    {id: "apoptosis facilitator bcl-2-like protein 14"},
    {id: "bcl2-l-14"},
    {id: "apoptosis regulatory protein siva"},
    {id: "cd27-binding protein"},
    {id: "cd27bp"},
    {id: "apoptosis-enhancing nuclease"},
    {id: "interferon-stimulated 20 kda exonuclease-like 1"},
    {id: "apoptosis-inducing factor 1, mitochondrial"},
    {id: "ec 1.6.99.-"},
    {id: "programmed cell death protein 8"},
    {id: "apoptotic protease-activating factor 1"},
    {id: "apaf-1"},
    {id: "appetite-regulating hormone"},
    {id: "growth hormone secretagogue"},
    {id: "growth hormone-releasing peptide"},
    {id: "motilin-related peptide"},
    {id: "cleaved into: ghrelin; obestatin-23; obestatin-13"},
    {id: "cleaved into: ghrelin; obestatin"},
    {id: "aprataxin"},
    {id: "ec 3.6.1.71"},
    {id: "ec 3.6.1.72"},
    {id: "forkhead-associated domain histidine triad-like protein"},
    {id: "fha-hit"},
    {id: "aquaporin-3"},
    {id: "aquaglyceroporin-3"},
    {id: "aquaporin-7"},
    {id: "aqp-7"},
    {id: "aquaglyceroporin-7"},
    {id: "aquaporin-9"},
    {id: "aqp-9"},
    {id: "aquaglyceroporin-9"},
    {id: "arachidonate 5-lipoxygenase-activating protein"},
    {id: "flap"},
    {id: "mk-886-binding protein"},
    {id: "archaemetzincin-1"},
    {id: "archeobacterial metalloproteinase-like protein 1"},
    {id: "arf-gap with gtpase, ank repeat and ph domain-containing protein 2"},
    {id: "agap-2"},
    {id: "centaurin-gamma-1"},
    {id: "cnt-g1"},
    {id: "phosphatidylinositol 3-kinase enhancer"},
    {id: "pike"},
    {id: "arf-gap with coiled-coil, ank repeat and ph domain-containing protein"},
    {id: "cnt-b"},
    {id: "centaurin-beta"},
    {id: "arfgap with dual ph domains 1"},
    {id: "ip4/pip3 binding protein"},
    {id: "arfaptin-2"},
    {id: "adp-ribosylation factor-interacting protein 2"},
    {id: "arginase"},
    {id: "ec 3.5.3.1"},
    {id: "arginine--trna ligase, cytoplasmic"},
    {id: "ec 6.1.1.19"},
    {id: "arginyl-trna synthetase"},
    {id: "argrs"},
    {id: "arginine-glutamic acid dipeptide repeats protein"},
    {id: "atrophin-1-related protein"},
    {id: "arginine-hydroxylase ndufaf5, mitochondrial"},
    {id: "ec 1.-.-.-"},
    {id: "nadh dehydrogenase [ubiquinone] 1 alpha subcomplex assembly factor 5"},
    {id: "putative methyltransferase ndufaf5"},
    {id: "arginyl-trna--protein transferase 1"},
    {id: "arginyltransferase 1"},
    {id: "r-transferase 1"},
    {id: "ec 2.3.2.8"},
    {id: "arginine-trna--protein transferase 1"},
    {id: "armadillo repeat containing 12"},
    {id: "rgd1306008 protein"},
    {id: "armadillo-like helical domain containing 1"},
    {id: "rgd1563714 protein"},
    {id: "arrestin domain-containing protein 1"},
    {id: "alpha-arrestin 1"},
    {id: "arrestin domain-containing protein 3"},
    {id: "liver regeneration-related protein lrrg00048"},
    {id: "arsenite methyltransferase"},
    {id: "ec 2.1.1.137"},
    {id: "aryl hydrocarbon receptor repressor"},
    {id: "ahr repressor"},
    {id: "ahrr"},
    {id: "arylamine n-acetyltransferase 1"},
    {id: "ec 2.3.1.5"},
    {id: "arylamide acetylase 1"},
    {id: "n-acetyltransferase type 1"},
    {id: "nat-1"},
    {id: "arylsulfatase"},
    {id: "ec 3.1.6.1"},
    {id: "ec 3.1.6.14"},
    {id: "n-acetylglucosamine-6-sulfatase"},
    {id: "arylsulfatase e"},
    {id: "arylsulfatase g"},
    {id: "asg"},
    {id: "n-sulfoglucosamine-3-sulfatase"},
    {id: "ec 3.1.6.15"},
    {id: "arylsulfatase i"},
    {id: "asi"},
    {id: "ec 3.1.6.-"},
    {id: "arylsulfatase k"},
    {id: "ask"},
    {id: "glucuronate-2-sulfatase"},
    {id: "ec 3.1.6.18"},
    {id: "asialoglycoprotein receptor 2"},
    {id: "asgp-r 2"},
    {id: "asgpr 2"},
    {id: "hepatic lectin r2/3"},
    {id: "hl-2"},
    {id: "rhl-2"},
    {id: "testis-type galactosyl receptor"},
    {id: "aspartate aminotransferase"},
    {id: "ec 2.6.1.1"},
    {id: "aspartate beta-hydroxylase domain-containing protein 2"},
    {id: "aspartate--trna ligase, cytoplasmic"},
    {id: "ec 6.1.1.12"},
    {id: "aspartyl-trna synthetase"},
    {id: "asprs"},
    {id: "aspartate--trna ligase, mitochondrial"},
    {id: "aspartoacylase"},
    {id: "ec 3.5.1.15"},
    {id: "aminoacylase-2"},
    {id: "acy-2"},
    {id: "aspartyl aminopeptidase"},
    {id: "ec 3.4.11.21"},
    {id: "aspartyl-trna synthetase 2"},
    {id: "mitochondrial"},
    {id: "asteroid homolog 1"},
    {id: "asteroid homolog 1 (drosophila)"},
    {id: "astrocytic phosphoprotein pea-15"},
    {id: "15 kda phosphoprotein enriched in astrocytes"},
    {id: "ataxin-3"},
    {id: "ec 3.4.19.12"},
    {id: "machado-joseph disease protein 1 homolog"},
    {id: "ataxin-7-like protein 3"},
    {id: "saga-associated factor 11 homolog"},
    {id: "atlastin-1"},
    {id: "ec 3.6.5.-"},
    {id: "spastic paraplegia 3a homolog"},
    {id: "atypical chemokine receptor 3"},
    {id: "c-x-c chemokine receptor type 7"},
    {id: "cxc-r7"},
    {id: "cxcr-7"},
    {id: "chemokine orphan receptor 1"},
    {id: "g-protein coupled receptor rdc1 homolog"},
    {id: "rdc-1"},
    {id: "atypical kinase coq8a, mitochondrial"},
    {id: "ec 2.7.-.-"},
    {id: "chaperone activity of bc1 complex-like"},
    {id: "chaperone-abc1-like"},
    {id: "coenzyme q protein 8a"},
    {id: "aarf domain-containing protein kinase 3"},
    {id: "atypical kinase coq8b, mitochondrial"},
    {id: "aarf domain-containing protein kinase 4"},
    {id: "coenzyme q protein 8b"},
    {id: "augurin"},
    {id: "esophageal cancer-related gene 4 protein homolog"},
    {id: "aurora kinase"},
    {id: "aurora kinase b"},
    {id: "aurora 1"},
    {id: "aurora- and ipl1-like midbody-associated protein 1"},
    {id: "aurora/ipl1-related kinase 2"},
    {id: "ark-2"},
    {id: "aurora-related kinase 2"},
    {id: "stk-1"},
    {id: "serine/threonine-protein kinase 12"},
    {id: "serine/threonine-protein kinase 5"},
    {id: "serine/threonine-protein kinase aurora-b"},
    {id: "autophagy-related protein 9a"},
    {id: "apg9-like 1"},
    {id: "axin-2"},
    {id: "axin-like protein"},
    {id: "axil"},
    {id: "axis inhibition protein 2"},
    {id: "conductin"},
    {id: "axonemal dynein light intermediate polypeptide 1"},
    {id: "inner dynein arm light chain, axonemal"},
    {id: "zetin-1"},
    {id: "b-cell linker protein"},
    {id: "cytoplasmic adapter protein"},
    {id: "b-cell lymphoma/leukemia 10"},
    {id: "b-cell cll/lymphoma 10"},
    {id: "bcl-10"},
    {id: "r-rcd1"},
    {id: "rcd"},
    {id: "b-cell receptor-associated protein"},
    {id: "bcr-associated protein"},
    {id: "b-cell scaffold protein with ankyrin repeats 1"},
    {id: "lrrgt00104"},
    {id: "b-cell translocation gene 4"},
    {id: "btg anti-proliferation factor 4"},
    {id: "b1 bradykinin receptor"},
    {id: "b1r"},
    {id: "bk-1 receptor"},
    {id: "kinin b1 receptor"},
    {id: "kb1"},
    {id: "b9 domain containing 1"},
    {id: "b9d1 protein"},
    {id: "baf chromatin remodeling complex subunit bcl11b"},
    {id: "coup-tf-interacting protein 2 long form"},
    {id: "coup-tf-interacting protein 2 short form"},
    {id: "bag cochaperone 2"},
    {id: "bag2 protein"},
    {id: "bag cochaperone 4"},
    {id: "bcl2-associated athanogene 4"},
    {id: "bag family molecular chaperone regulator 5"},
    {id: "bag-5"},
    {id: "bcl-2-associated athanogene 5"},
    {id: "bbx high mobility group box domain containing"},
    {id: "bobby sox homolog (drosophila)"},
    {id: "bcl-w"},
    {id: "bcl2-like 2"},
    {id: "bcl2 interacting protein 3"},
    {id: "bcl2/adenovirus e1b 19 kda-interacting protein 3"},
    {id: "rdcr-0202-2 protein"},
    {id: "bcl2/adenovirus e1b interacting protein 3 variant"},
    {id: "bclaf1 and thrap3 family member 3"},
    {id: "rgd1562161 protein"},
    {id: "ben domain containing 5"},
    {id: "lrrgt00089"},
    {id: "bh3-interacting domain death agonist"},
    {id: "p22 bid"},
    {id: "bid"},
    {id: "cleaved into: bh3-interacting domain death agonist p15 (p15 bid); bh3-interacting domain death agonist p13 (p13 bid); bh3-interacting domain death agonist p11 (p11 bid)"},
    {id: "bicd cargo adaptor 2"},
    {id: "bicaudal d homolog 2 (drosophila)"},
    {id: "bloc-2 complex member hps6"},
    {id: "hermansky-pudlak syndrome 6 protein homolog"},
    {id: "ruby-eye protein homolog"},
    {id: "ruby-eye-like protein"},
    {id: "ru"},
    {id: "bmp and activin membrane-bound inhibitor homolog"},
    {id: "kinase-deficient tgfbeta superfamily receptor subunit"},
    {id: "bos complex subunit tmem147"},
    {id: "transmembrane protein 147"},
    {id: "bpi fold-containing family a member 1"},
    {id: "palate lung and nasal epithelium clone protein"},
    {id: "bpi fold-containing family a member 2"},
    {id: "neonatal submandibular gland protein"},
    {id: "parotid secretory protein"},
    {id: "psp"},
    {id: "brca1-a complex subunit abraxas 1"},
    {id: "coiled-coil domain-containing protein 98"},
    {id: "protein fam175a"},
    {id: "brca1-a complex subunit rap80"},
    {id: "receptor-associated protein 80"},
    {id: "ubiquitin interaction motif-containing protein 1"},
    {id: "brca2, dna repair associated"},
    {id: "breast cancer susceptibility protein 2"},
    {id: "brisc and brca1-a complex member 1"},
    {id: "mediator of rap80 interactions and targeting subunit of 40 kda"},
    {id: "new component of the brca1-a complex"},
    {id: "brisc and brca1-a complex member 2"},
    {id: "brca1-a complex subunit bre"},
    {id: "brca1/brca2-containing complex subunit 45"},
    {id: "brain and reproductive organ-expressed protein"},
    {id: "bro1 domain-containing protein brox"},
    {id: "bro1 domain- and caax motif-containing protein"},
    {id: "btb"},
    {id: "poz"},
    {id: "btb domain containing 1"},
    {id: "btb/poz domain-containing adapter for cul3-mediated rhoa degradation protein 2"},
    {id: "btb/poz domain-containing protein tnfaip1"},
    {id: "tumor necrosis factor-induced protein 1"},
    {id: "btb/poz domain-containing protein 8"},
    {id: "ap2-interacting clathrin-endocytosis protein"},
    {id: "apache"},
    {id: "bud23, rrna methyltransferase and ribosome maturation factor"},
    {id: "wbscr22 protein"},
    {id: "ba1-665"},
    {id: "lrrgt00096"},
    {id: "bactericidal permeability-increasing protein"},
    {id: "bpi"},
    {id: "band 4.1-like protein 1"},
    {id: "erythrocyte membrane protein band 4.1-like 1"},
    {id: "neuronal protein 4.1"},
    {id: "4.1n"},
    {id: "band 4.1-like protein 4b"},
    {id: "erythrocyte membrane protein band 4.1-like 4b"},
    {id: "band 4.1-like protein 5"},
    {id: "erythrocyte membrane protein band 4.1-like 5"},
    {id: "barh-like 1 homeobox protein"},
    {id: "bar-class homeodomain protein mbh2"},
    {id: "barh-related homeobox protein 1"},
    {id: "barh-like 2 homeobox protein"},
    {id: "bar-class homeodomain protein mbh1"},
    {id: "homeobox protein b-h1"},
    {id: "barrier-to-autointegration factor"},
    {id: "lap2-binding protein 1"},
    {id: "cleaved into: barrier-to-autointegration factor, n-terminally processed"},
    {id: "basal cell adhesion molecule"},
    {id: "b-cam cell surface glycoprotein"},
    {id: "lutheran antigen"},
    {id: "cd antigen cd239"},
    {id: "lutheran blood group"},
    {id: "basic leucine zipper transcriptional factor atf-like"},
    {id: "b-cell-activating transcription factor"},
    {id: "b-atf"},
    {id: "bassoon"},
    {id: "presynaptic cytomatrix protein"},
    {id: "bcl-2-binding component 3"},
    {id: "p53 up-regulated modulator of apoptosis"},
    {id: "bcl-2-like protein 1"},
    {id: "apoptosis regulator bcl-x"},
    {id: "bcl-2-like protein 10"},
    {id: "bcl2-l-10"},
    {id: "anti-apoptotic protein boo"},
    {id: "apoptosis regulator bcl-b"},
    {id: "bco2 protein"},
    {id: "beta-carotene oxygenase 2"},
    {id: "beclin 1-associated autophagy-related key regulator"},
    {id: "barkor"},
    {id: "autophagy-related protein 14-like protein"},
    {id: "atg14l"},
    {id: "beclin-1"},
    {id: "coiled-coil myosin-like bcl2-interacting protein"},
    {id: "protein gt197"},
    {id: "cleaved into: beclin-1-c 35 kda; beclin-1-c 37 kda"},
    {id: "beta-1,3-n-acetylglucosaminyltransferase"},
    {id: "ec 2.4.1.222"},
    {id: "o-fucosylpeptide 3-beta-n-acetylglucosaminyltransferase"},
    {id: "beta-1,3-n-acetylglucosaminyltransferase lunatic fringe"},
    {id: "beta-1,3-galactosyl-o-glycosyl-glycoprotein beta-1,6-n-acetylglucosaminyltransferase 3"},
    {id: "ec 2.4.1.102"},
    {id: "ec 2.4.1.148"},
    {id: "ec 2.4.1.150"},
    {id: "c2gnt-mucin type"},
    {id: "c2gnt-m"},
    {id: "di/c2/c4gnt"},
    {id: "dignt"},
    {id: "beta-1,4-n-acetylgalactosaminyltransferase"},
    {id: "ec 2.4.1.244"},
    {id: "beta-1,4-galactosyltransferase"},
    {id: "beta-1,4-galtase"},
    {id: "beta-1,4-galactosyltransferase 3"},
    {id: "beta-1,4-galtase 3"},
    {id: "beta4gal-t3"},
    {id: "b4gal-t3"},
    {id: "beta-n-acetylglucosaminylglycopeptide beta-1,4-galactosyltransferase"},
    {id: "ec 2.4.1.38"},
    {id: "n-acetyllactosamine synthase"},
    {id: "ec 2.4.1.90"},
    {id: "nal synthase"},
    {id: "neolactotriaosylceramide beta-1,4-galactosyltransferase"},
    {id: "ec 2.4.1.275"},
    {id: "udp-gal:beta-glcnac beta-1,4-galactosyltransferase 3"},
    {id: "udp-galactose:beta-n-acetylglucosamine beta-1,4-galactosyltransferase 3"},
    {id: "beta-1,4-galactosyltransferase 4"},
    {id: "beta-1,4-galtase 4"},
    {id: "beta4gal-t4"},
    {id: "b4gal-t4"},
    {id: "beta-n-acetylglucosaminyl-glycolipid beta-1,4-galactosyltransferase"},
    {id: "lactotriaosylceramide beta-1,4-galactosyltransferase"},
    {id: "udp-gal:beta-glcnac beta-1,4-galactosyltransferase 4"},
    {id: "udp-galactose:beta-n-acetylglucosamine beta-1,4-galactosyltransferase 4"},
    {id: "beta-1,4-galactosyltransferase 6"},
    {id: "beta-1,4-galtase 6"},
    {id: "beta4gal-t6"},
    {id: "b4gal-t6"},
    {id: "glucosylceramide beta-1,4-galactosyltransferase"},
    {id: "ec 2.4.1.274"},
    {id: "lactosylceramide synthase"},
    {id: "laccer synthase"},
    {id: "udp-gal:beta-glcnac beta-1,4-galactosyltransferase 6"},
    {id: "udp-gal:glucosylceramide beta-1,4-galactosyltransferase"},
    {id: "udp-galactose:beta-n-acetylglucosamine beta-1,4-galactosyltransferase 6"},
    {id: "beta-1,4-glucuronyltransferase 1"},
    {id: "i-beta-1,3-n-acetylglucosaminyltransferase"},
    {id: "n-acetyllactosaminide beta-1,3-n-acetylglucosaminyltransferase"},
    {id: "poly-n-acetyllactosamine extension enzyme"},
    {id: "udp-glcnac:betagal beta-1,3-n-acetylglucosaminyltransferase 1"},
    {id: "beta-1,4-mannosyl-glycoprotein 4-beta-n-acetylglucosaminyltransferase"},
    {id: "ec 2.4.1.144"},
    {id: "n-glycosyl-oligosaccharide-glycoprotein n-acetylglucosaminyltransferase iii"},
    {id: "gnt-iii"},
    {id: "glcnac-t iii"},
    {id: "n-acetylglucosaminyltransferase iii"},
    {id: "beta-2-glycoprotein 1"},
    {id: "apolipoprotein h"},
    {id: "beta-2-glycoprotein i"},
    {id: "beta-ala-his dipeptidase"},
    {id: "ec 3.4.13.20"},
    {id: "cndp dipeptidase 1"},
    {id: "carnosine dipeptidase 1"},
    {id: "beta-crystallin a4"},
    {id: "beta-a4 crystallin"},
    {id: "beta-crystallin b2"},
    {id: "beta-b2 crystallin"},
    {id: "beta-crystallin bp"},
    {id: "beta-crystallin b3"},
    {id: "beta-b3 crystallin"},
    {id: "cleaved into: beta-crystallin b3, n-terminally processed"},
    {id: "beta-defensin 1"},
    {id: "bd-1"},
    {id: "rbd-1"},
    {id: "defensin, beta 1"},
    {id: "beta-defensin 10"},
    {id: "bd-10"},
    {id: "defensin, beta 10"},
    {id: "beta-defensin 11"},
    {id: "bd-11"},
    {id: "defensin, beta 11"},
    {id: "beta-defensin 12"},
    {id: "bd-12"},
    {id: "defensin, beta 12"},
    {id: "beta-defensin 13"},
    {id: "bd-13"},
    {id: "defensin, beta 13"},
    {id: "beta-defensin 14"},
    {id: "bd-14"},
    {id: "defensin, beta 14"},
    {id: "beta-defensin 18"},
    {id: "bd-18"},
    {id: "defensin, beta 18"},
    {id: "beta-defensin 2"},
    {id: "defensin beta 2"},
    {id: "beta-defensin 20"},
    {id: "bd-20"},
    {id: "defensin, beta 20"},
    {id: "beta-defensin 25"},
    {id: "bd-25"},
    {id: "defensin, beta 25"},
    {id: "beta-defensin 29"},
    {id: "bd-29"},
    {id: "defensin, beta 29"},
    {id: "beta-defensin 30"},
    {id: "bd-30"},
    {id: "defensin, beta 30"},
    {id: "beta-defensin 38"},
    {id: "bd-38"},
    {id: "defensin, beta 38"},
    {id: "beta-defensin 39"},
    {id: "bd-39"},
    {id: "defensin, beta 39"},
    {id: "beta-defensin 4"},
    {id: "bd-4"},
    {id: "bd-2"},
    {id: "defensin, beta 4"},
    {id: "rbd-2"},
    {id: "rbd-4"},
    {id: "beta-defensin 41"},
    {id: "defensin beta 41"},
    {id: "beta-defensin 43"},
    {id: "bd-43"},
    {id: "defensin, beta 43"},
    {id: "beta-defensin 5"},
    {id: "bd-5"},
    {id: "defensin, beta 5"},
    {id: "beta-defensin 50"},
    {id: "bd-50"},
    {id: "defensin, beta 50"},
    {id: "beta-defensin 9"},
    {id: "bd-9"},
    {id: "defensin, beta 9"},
    {id: "beta-galactosidase"},
    {id: "ec 3.2.1.23"},
    {id: "beta-galactoside alpha-2,6-sialyltransferase 2"},
    {id: "alpha 2,6-st 2"},
    {id: "ec 2.4.3.1"},
    {id: "cmp-n-acetylneuraminate-beta-galactosamide-alpha-2,6-sialyltransferase 2"},
    {id: "st6gal ii"},
    {id: "st6galii"},
    {id: "sialyltransferase 2"},
    {id: "beta-hexosaminidase"},
    {id: "ec 3.2.1.52"},
    {id: "beta-hexosaminidase subunit alpha"},
    {id: "beta-n-acetylhexosaminidase subunit alpha"},
    {id: "hexosaminidase subunit a"},
    {id: "n-acetyl-beta-glucosaminidase subunit alpha"},
    {id: "beta-hexosaminidase subunit beta"},
    {id: "beta-n-acetylhexosaminidase subunit beta"},
    {id: "hexosaminidase subunit b"},
    {id: "n-acetyl-beta-glucosaminidase subunit beta"},
    {id: "beta-mannosidase"},
    {id: "ec 3.2.1.25"},
    {id: "lysosomal beta a mannosidase"},
    {id: "mannanase"},
    {id: "mannase"},
    {id: "beta-microseminoprotein"},
    {id: "prostate secreted seminal plasma protein"},
    {id: "prostate secretory protein of 94 amino acids"},
    {id: "psp-94"},
    {id: "psp94"},
    {id: "beta-nerve growth factor"},
    {id: "beta-ngf"},
    {id: "beta-secretase 1"},
    {id: "ec 3.4.23.46"},
    {id: "aspartyl protease 2"},
    {id: "asp2"},
    {id: "asp 2"},
    {id: "beta-site amyloid precursor protein cleaving enzyme 1"},
    {id: "beta-site app cleaving enzyme 1"},
    {id: "memapsin-2"},
    {id: "membrane-associated aspartic protease 2"},
    {id: "beta-secretase 2"},
    {id: "ec 3.4.23.45"},
    {id: "beta-site amyloid precursor protein cleaving enzyme 2"},
    {id: "beta-site app cleaving enzyme 2"},
    {id: "memapsin-1"},
    {id: "membrane-associated aspartic protease 1"},
    {id: "theta-secretase"},
    {id: "beta-ureidopropionase"},
    {id: "ec 3.5.1.6"},
    {id: "beta-alanine synthase"},
    {id: "n-carbamoyl-beta-alanine amidohydrolase"},
    {id: "bifunctional udp-n-acetylglucosamine 2-epimerase/n-acetylmannosamine kinase"},
    {id: "udp-glcnac-2-epimerase/manac kinase"},
    {id: "includes: udp-n-acetylglucosamine 2-epimerase (hydrolyzing) (ec 3.2.1.183) (udp-glcnac-2-epimerase) (uridine diphosphate-n-acetylglucosamine-2-epimerase); n-acetylmannosamine kinase (ec 2.7.1.60) (manac kinase)"},
    {id: "bifunctional heparan sulfate n-deacetylase/n-sulfotransferase 1"},
    {id: "glucosaminyl n-deacetylase/n-sulfotransferase 1"},
    {id: "ndst-1"},
    {id: "golgi heparan sulfate n-deacetylase/n-sulfotransferase"},
    {id: "n-heparan sulfate sulfotransferase 1"},
    {id: "n-hsst"},
    {id: "n-hsst 1"},
    {id: "[heparan sulfate]-glucosamine n-sulfotransferase 1"},
    {id: "hsnst 1"},
    {id: "includes: heparan sulfate n-deacetylase 1 (ec 3.5.1.-); heparan sulfate n-sulfotransferase 1 (ec 2.8.2.8)"},
    {id: "bifunctional lysine-specific demethylase and histidyl-hydroxylase"},
    {id: "bifunctional methylenetetrahydrofolate dehydrogenase/cyclohydrolase 2, mitochondrial"},
    {id: "nadp-dependent methylenetetrahydrofolate dehydrogenase 2-like protein"},
    {id: "mthfd2-like"},
    {id: "includes: methylenetetrahydrofolate dehydrogenase, mitochondrial (ec 1.5.1.15) (ec 1.5.1.5); methenyltetrahydrofolate cyclohydrolase, mitochondrial (ec 3.5.4.9)"},
    {id: "bifunctional phosphoribosylaminoimidazole carboxylase/phosphoribosylaminoimidazole succinocarboxamide synthetase"},
    {id: "paics"},
    {id: "includes: phosphoribosylaminoimidazole carboxylase (ec 4.1.1.21) (air carboxylase) (airc); phosphoribosylaminoimidazole succinocarboxamide synthetase (ec 6.3.2.6) (saicar synthetase)"},
    {id: "bifunctional purine biosynthesis protein atic"},
    {id: "aicar transformylase/inosine monophosphate cyclohydrolase"},
    {id: "atic"},
    {id: "includes: phosphoribosylaminoimidazolecarboxamide formyltransferase (ec 2.1.2.3) (5-aminoimidazole-4-carboxamide ribonucleotide formyltransferase) (aicar formyltransferase) (aicar transformylase); inosine 5'-monophosphate cyclohydrolase (imp cyclohydrolase) (ec 3.5.4.10) (imp synthase) (inosinicase)"},
    {id: "ec 2.1.2.3"},
    {id: "ec 3.5.4.10"},
    {id: "bile acid-coa:amino acid n-acyltransferase"},
    {id: "bacat"},
    {id: "bat"},
    {id: "ec 2.3.1.65"},
    {id: "bile acid-coa thioesterase"},
    {id: "choloyl-coa hydrolase"},
    {id: "ec 3.1.2.27"},
    {id: "glycine n-choloyltransferase"},
    {id: "kan-1"},
    {id: "long-chain fatty-acyl-coa hydrolase"},
    {id: "bile salt export pump"},
    {id: "atp-binding cassette sub-family b member 11"},
    {id: "sister of p-glycoprotein"},
    {id: "biliverdin reductase a"},
    {id: "bvr a"},
    {id: "ec 1.3.1.24"},
    {id: "biogenesis of lysosome-related organelles complex 1 subunit 1"},
    {id: "bloc-1 subunit 1"},
    {id: "biogenesis of lysosome-related organelles complex 1 subunit 3"},
    {id: "bloc-1 subunit 3"},
    {id: "biogenesis of lysosome-related organelles complex 1 subunit 5"},
    {id: "bloc-1 subunit 5"},
    {id: "protein muted homolog"},
    {id: "bis"},
    {id: "5'-adenosyl"},
    {id: "ec 3.6.1.29"},
    {id: "5'-nucleosyl"},
    {id: "asymmetrical"},
    {id: "ec 3.6.1.17"},
    {id: "diadenosine 5',5'''-p1,p4-tetraphosphate asymmetrical hydrolase"},
    {id: "ap4a hydrolase"},
    {id: "ap4aase"},
    {id: "diadenosine tetraphosphatase"},
    {id: "nucleoside diphosphate-linked moiety x motif 2"},
    {id: "nudix motif 2"},
    {id: "bladder cancer-associated protein"},
    {id: "bladder cancer 10 kda protein"},
    {id: "bc10"},
    {id: "bleomycin hydrolase"},
    {id: "ec 3.4.22.40"},
    {id: "blood group rh"},
    {id: "erythrocyte membrane glycoprotein rh30"},
    {id: "cd antigen cd240d"},
    {id: "blood vessel epicardial substance"},
    {id: "popeye domain-containing protein 1"},
    {id: "popeye protein 1"},
    {id: "bone marrow stromal antigen 2"},
    {id: "bst-2"},
    {id: "protein damp-1"},
    {id: "cd antigen cd317"},
    {id: "bone morphogenetic protein 10"},
    {id: "bmp-10"},
    {id: "bone morphogenetic protein receptor type-1a"},
    {id: "bmp type-1a receptor"},
    {id: "bmpr-1a"},
    {id: "activin receptor-like kinase 3"},
    {id: "alk-3"},
    {id: "bone morphogenetic protein 4 receptor"},
    {id: "cd antigen cd292"},
    {id: "borealin"},
    {id: "cell division cycle-associated protein 8"},
    {id: "bq135360"},
    {id: "similar to serum amyloid a-3 protein precursor"},
    {id: "brain mitochondrial carrier protein-1"},
    {id: "solute carrier family 25 (mitochondrial carrier, brain), member 14"},
    {id: "brain-enriched e-map-115-like protein"},
    {id: "map7 domain containing 2"},
    {id: "brain-specific angiogenesis inhibitor 1-associated protein 2"},
    {id: "bai-associated protein 2"},
    {id: "bai1-associated protein 2"},
    {id: "insulin receptor substrate protein of 53 kda"},
    {id: "irsp53"},
    {id: "insulin receptor substrate p53"},
    {id: "insulin receptor tyrosine kinase substrate protein p53"},
    {id: "branched-chain-amino-acid aminotransferase"},
    {id: "ec 2.6.1.42"},
    {id: "breakpoint cluster region protein"},
    {id: "breast cancer anti-estrogen resistance protein 3 homolog"},
    {id: "bcar3 adapter protein, nsp family member"},
    {id: "novel sh2-containing protein 2"},
    {id: "sh2 domain-containing protein 3b"},
    {id: "p130cas-binding protein and-34"},
    {id: "breast cancer type 1 susceptibility protein homolog"},
    {id: "ec 2.3.2.27"},
    {id: "breast carcinoma-amplified sequence 1 homolog"},
    {id: "protein whose mrna is enriched in synaptosomes 2"},
    {id: "pmes-2"},
    {id: "brefeldin a-inhibited guanine nucleotide-exchange protein 2"},
    {id: "brefeldin a-inhibited gep 2"},
    {id: "adp-ribosylation factor guanine nucleotide-exchange factor 2"},
    {id: "broad substrate specificity atp-binding cassette transporter abcg2"},
    {id: "ec 7.6.2.2"},
    {id: "atp-binding cassette sub-family g member 2"},
    {id: "breast cancer resistance protein 1 homolog"},
    {id: "urate exporter"},
    {id: "cd antigen cd338"},
    {id: "bromodomain-containing protein 2"},
    {id: "protein ring3"},
    {id: "c-1-tetrahydrofolate synthase, cytoplasmic"},
    {id: "ec 1.5.1.5"},
    {id: "ec 3.5.4.9"},
    {id: "ec 6.3.4.3"},
    {id: "c-c motif chemokine 2"},
    {id: "immediate-early serum-responsive protein je"},
    {id: "monocyte chemoattractant protein 1"},
    {id: "monocyte chemotactic protein 1"},
    {id: "mcp-1"},
    {id: "small-inducible cytokine a2"},
    {id: "c-c motif chemokine 3"},
    {id: "macrophage inflammatory protein 1-alpha"},
    {id: "mip-1-alpha"},
    {id: "small-inducible cytokine a3"},
    {id: "c-c motif chemokine 7"},
    {id: "monocyte chemoattractant protein 3"},
    {id: "monocyte chemotactic protein 3"},
    {id: "mcp-3"},
    {id: "small-inducible cytokine a7"},
    {id: "c-c motif chemokine receptor 6"},
    {id: "cc chemokine receptor 6"},
    {id: "c-c motif chemokine receptor 7"},
    {id: "chemokine (c-c motif) receptor 7"},
    {id: "c-c motif chemokine receptor 9"},
    {id: "chemokine (c-c motif) receptor 9"},
    {id: "c-jun-amino-terminal kinase-interacting protein 1"},
    {id: "jip-1"},
    {id: "jnk-interacting protein 1"},
    {id: "islet-brain-1"},
    {id: "ib-1"},
    {id: "jip-1-related protein"},
    {id: "jrp"},
    {id: "jnk map kinase scaffold protein 1"},
    {id: "mitogen-activated protein kinase 8-interacting protein 1"},
    {id: "c-jun-amino-terminal kinase-interacting protein 2"},
    {id: "jip-2"},
    {id: "jnk-interacting protein 2"},
    {id: "islet-brain-2"},
    {id: "ib-2"},
    {id: "jnk map kinase scaffold protein 2"},
    {id: "mitogen-activated protein kinase 8-interacting protein 2"},
    {id: "c-x-c chemokine receptor type 1"},
    {id: "cxc-r1"},
    {id: "cxcr-1"},
    {id: "high affinity interleukin-8 receptor a"},
    {id: "il-8r a"},
    {id: "cd antigen cd181"},
    {id: "c-x-c chemokine receptor type 3"},
    {id: "cxc-r3"},
    {id: "cxcr-3"},
    {id: "interferon-inducible protein 10 receptor"},
    {id: "ip-10 receptor"},
    {id: "cd antigen cd183"},
    {id: "c-x-c chemokine receptor type 4"},
    {id: "cxc-r4"},
    {id: "cxcr-4"},
    {id: "fusin"},
    {id: "leukocyte-derived seven transmembrane domain receptor"},
    {id: "lestr"},
    {id: "stromal cell-derived factor 1 receptor"},
    {id: "sdf-1 receptor"},
    {id: "cd antigen cd184"},
    {id: "c-x-c motif chemokine 10"},
    {id: "10 kda interferon gamma-induced protein"},
    {id: "gamma-ip10"},
    {id: "ip-10"},
    {id: "interferon-inducible protein 10"},
    {id: "protein mob-1"},
    {id: "small-inducible cytokine b10"},
    {id: "c-x-c motif chemokine 16"},
    {id: "small-inducible cytokine b16"},
    {id: "transmembrane chemokine cxcl16"},
    {id: "c-x-c motif chemokine 2"},
    {id: "cytokine-induced neutrophil chemoattractant 3"},
    {id: "cinc-3"},
    {id: "macrophage inflammatory protein 2"},
    {id: "mip2"},
    {id: "c-x-c motif chemokine 3"},
    {id: "cytokine-induced neutrophil chemoattractant 2"},
    {id: "cinc-2"},
    {id: "macrophage inflammatory protein 2-alpha/beta"},
    {id: "mip2-alpha/beta"},
    {id: "c-terminal-binding protein 1"},
    {id: "ctbp1"},
    {id: "50 kda bfa-dependent adp-ribosylation substrate"},
    {id: "bars-50"},
    {id: "c-terminal-binding protein 3"},
    {id: "ctbp3"},
    {id: "c-terminal-binding protein 2"},
    {id: "ctbp2"},
    {id: "c-type lectin domain containing 7a"},
    {id: "c-type lectin domain family 7 member a transcript variant 1"},
    {id: "dectin-1"},
    {id: "c-type lectin domain family 7 member a transcript variant 2"},
    {id: "c-type lectin domain family 1 member a"},
    {id: "c-type lectin domain family 1, member a"},
    {id: "c-type lectin domain family 1 member b"},
    {id: "c-type lectin domain family 1, member b"},
    {id: "c-type lectin domain family 11 member a"},
    {id: "lymphocyte secreted c-type lectin"},
    {id: "osteolectin"},
    {id: "stem cell growth factor"},
    {id: "c-type lectin domain family 12 member a"},
    {id: "c-type lectin domain family 12, member a"},
    {id: "clec12a protein"},
    {id: "c-type lectin domain family 12 member b"},
    {id: "c-type lectin domain family 12, member b"},
    {id: "c-type lectin domain family 2 member d5"},
    {id: "osteoclast inhibitory lectin"},
    {id: "c-type lectin domain family 2 member m"},
    {id: "similar to cd69 antigen (p60, early t-cell activation antigen)"},
    {id: "c-type lectin domain family 2 member d10"},
    {id: "c-type lectin domain family 2, member e"},
    {id: "c-type lectin domain family 4 member f"},
    {id: "c-type lectin superfamily member 13"},
    {id: "c-type lectin 13"},
    {id: "kupffer cell receptor"},
    {id: "c-type lectin domain family 4, member a1"},
    {id: "dendritic cell inhibitory receptor 4"},
    {id: "c-type lectin domain family 4, member a2"},
    {id: "dendritic cell inhibitory receptor 2"},
    {id: "c-type lectin domain family 9 member a"},
    {id: "cd antigen cd370"},
    {id: "c-type natriuretic peptide"},
    {id: "cleaved into: cnp-22; cnp-29; cnp-53"},
    {id: "c1q and tnf related 3"},
    {id: "c1qtnf3 protein"},
    {id: "c1r protein"},
    {id: "complement c1r"},
    {id: "c5a anaphylatoxin chemotactic receptor 2"},
    {id: "complement component 5a receptor 2"},
    {id: "g-protein coupled receptor 77"},
    {id: "caax prenyl protease"},
    {id: "ec 3.4.24.84"},
    {id: "cap-gly domain-containing linker protein 1"},
    {id: "cytoplasmic linker protein 170"},
    {id: "clip-170"},
    {id: "restin"},
    {id: "cb1 cannabinoid receptor-interacting protein 1"},
    {id: "crip-1"},
    {id: "ccaat/enhancer-binding protein epsilon"},
    {id: "c/ebp epsilon"},
    {id: "c/ebp-related protein 1"},
    {id: "ccdc54"},
    {id: "coiled-coil domain containing 54"},
    {id: "ccm2 scaffold protein"},
    {id: "ccm2 protein"},
    {id: "ccn family member 3"},
    {id: "cellular communication network factor 3"},
    {id: "nephroblastoma-overexpressed gene protein homolog"},
    {id: "protein nov homolog"},
    {id: "novh"},
    {id: "ccr4-not transcription complex subunit 6"},
    {id: "ec 3.1.13.4"},
    {id: "ccr4 carbon catabolite repression 4-like"},
    {id: "carbon catabolite repressor protein 4 homolog"},
    {id: "cytoplasmic deadenylase"},
    {id: "ccr4-not transcription complex subunit 9"},
    {id: "cell differentiation protein rqcd1 homolog"},
    {id: "rcd-1"},
    {id: "cd151 antigen"},
    {id: "platelet-endothelial tetraspan antigen 3"},
    {id: "peta-3"},
    {id: "cd antigen cd151"},
    {id: "cd300e"},
    {id: "cd300e molecule"},
    {id: "cd302 antigen"},
    {id: "c-type lectin domain family 13 member a"},
    {id: "cd antigen cd302"},
    {id: "cd320 antigen"},
    {id: "transcobalamin receptor"},
    {id: "tcblr"},
    {id: "cd antigen cd320"},
    {id: "cd36 molecule"},
    {id: "thrombospondin receptor"},
    {id: "platelet glycoprotein 4-like"},
    {id: "cd40 ligand"},
    {id: "cd40-l"},
    {id: "tumor necrosis factor ligand superfamily member 5"},
    {id: "cd55 molecule"},
    {id: "cromer blood group"},
    {id: "cd63 antigen"},
    {id: "mast cell antigen ad1"},
    {id: "cd antigen cd63"},
    {id: "cdc-like kinase 2"},
    {id: "secretory carrier membrane protein 3"},
    {id: "cdc16 cell division cycle 16 homolog"},
    {id: "cell division cycle 16"},
    {id: "cdc42 small effector 2"},
    {id: "cdc42 small effector 2-like"},
    {id: "cdk-activating kinase assembly factor mat1"},
    {id: "cdk7/cyclin-h assembly factor"},
    {id: "menage a trois"},
    {id: "ring finger protein mat1"},
    {id: "cdk5 regulatory subunit-associated protein 2"},
    {id: "cdk5 activator-binding protein c48"},
    {id: "cdki protein"},
    {id: "cyclin-dependent kinase inhibitor 1c"},
    {id: "cyclin-dependent kinase inhibitor 1c (p57)"},
    {id: "cyclin-dependent kinase inhibitor p57"},
    {id: "cdkn2a-interacting protein"},
    {id: "collaborator of arf"},
    {id: "cdkn2aip n-terminal-like protein"},
    {id: "cdkn2a-interacting protein n-terminal-like protein"},
    {id: "cdp-diacylglycerol--glycerol-3-phosphate 3-phosphatidyltransferase"},
    {id: "ec 2.7.8.5"},
    {id: "cdp-diacylglycerol--inositol 3-phosphatidyltransferase"},
    {id: "ec 2.7.8.11"},
    {id: "phosphatidylinositol synthase"},
    {id: "pi synthase"},
    {id: "ptdins synthase"},
    {id: "cea cell adhesion molecule 6"},
    {id: "ceacam6-long (ceacam6-l)"},
    {id: "cklf-like marvel transmembrane domain containing 6"},
    {id: "lrrgt00102"},
    {id: "clk4-associating serine/arginine rich protein"},
    {id: "splicing factor, arginine/serine-rich 16"},
    {id: "cmrf35-like molecule 1"},
    {id: "clm-1"},
    {id: "cd300 antigen-like family member f"},
    {id: "cd antigen cd300f"},
    {id: "cop9 signalosome complex subunit 2"},
    {id: "sgn2"},
    {id: "signalosome subunit 2"},
    {id: "alien homolog"},
    {id: "jab1-containing signalosome subunit 2"},
    {id: "thyroid receptor-interacting protein 15"},
    {id: "tr-interacting protein 15"},
    {id: "trip-15"},
    {id: "cop9 signalosome complex subunit 3"},
    {id: "sgn3"},
    {id: "signalosome subunit 3"},
    {id: "cop9 signalosome complex subunit 8"},
    {id: "sgn8"},
    {id: "signalosome subunit 8"},
    {id: "cop9 homolog"},
    {id: "jab1-containing signalosome subunit 8"},
    {id: "coup transcription factor 2"},
    {id: "coup-tf2"},
    {id: "apolipoprotein a-i regulatory protein 1"},
    {id: "arp-1"},
    {id: "coup transcription factor ii"},
    {id: "coup-tf ii"},
    {id: "coupb"},
    {id: "nuclear receptor subfamily 2 group f member 2"},
    {id: "ovalbumin upstream promoter beta nuclear receptor"},
    {id: "cst complex subunit stn1"},
    {id: "oligonucleotide/oligosaccharide-binding fold-containing protein 1"},
    {id: "suppressor of cdc thirteen homolog"},
    {id: "ctp synthase"},
    {id: "ec 6.3.4.2"},
    {id: "utp--ammonia ligase"},
    {id: "glutamine hydrolyzing"},
    {id: "cugbp elav-like family member 2"},
    {id: "celf-2"},
    {id: "bruno-like protein 3"},
    {id: "cug triplet repeat rna-binding protein 2"},
    {id: "cug-bp2"},
    {id: "cug-bp- and etr-3-like factor 2"},
    {id: "elav-type rna-binding protein 3"},
    {id: "etr-3"},
    {id: "protein etr-r3"},
    {id: "neuroblastoma apoptosis-related rna-binding protein"},
    {id: "rnapor"},
    {id: "rna-binding protein brunol-3"},
    {id: "cx3c chemokine receptor 1"},
    {id: "c-x3-c ckr-1"},
    {id: "cx3cr1"},
    {id: "fractalkine receptor"},
    {id: "cxadr-like membrane protein"},
    {id: "adipocyte adhesion molecule"},
    {id: "coxsackie- and adenovirus receptor-like membrane protein"},
    {id: "car-like membrane protein"},
    {id: "cxxc motif containing zinc binding protein"},
    {id: "upf0587 protein c1orf123 homolog"},
    {id: "ca"},
    {id: "2+"},
    {id: "chloride channel accessory 5"},
    {id: "cadherin egf lag seven-pass g-type receptor 3"},
    {id: "multiple epidermal growth factor-like domains protein 2"},
    {id: "multiple egf-like domains protein 2"},
    {id: "cadherin-1"},
    {id: "epithelial cadherin"},
    {id: "e-cadherin"},
    {id: "uvomorulin"},
    {id: "cd antigen cd324"},
    {id: "cleaved into: e-cad/ctf1; e-cad/ctf2; e-cad/ctf3"},
    {id: "cadherin-17"},
    {id: "liver-intestine cadherin"},
    {id: "li-cadherin"},
    {id: "cadherin-22"},
    {id: "pituitary and brain cadherin"},
    {id: "pb-cadherin"},
    {id: "cadherin-related family member 5"},
    {id: "gp100"},
    {id: "mu-protocadherin"},
    {id: "cadherin-related neuronal receptor 1"},
    {id: "protocadherin alpha 1"},
    {id: "protocadherin alpha subfamily c, 2"},
    {id: "cadherin-related neuronal receptor 11"},
    {id: "protocadherin alpha 11"},
    {id: "protocadherin alpha 13"},
    {id: "cadherin-related neuronal receptor 2"},
    {id: "protocadherin alpha 2"},
    {id: "cadherin-related neuronal receptor 3"},
    {id: "protocadherin alpha 3"},
    {id: "cadherin-related neuronal receptor 5"},
    {id: "protocadherin alpha 5"},
    {id: "cadherin-related neuronal receptor 6"},
    {id: "cadherin-related neuronal receptor 8"},
    {id: "protocadherin alpha 8"},
    {id: "cadherin-related neuronal receptor c2"},
    {id: "protocadherin alpha c2"},
    {id: "calbindin"},
    {id: "calbindin d28"},
    {id: "d-28k"},
    {id: "spot 35 protein"},
    {id: "vitamin d-dependent calcium-binding protein, avian-type"},
    {id: "calcineurin b homologous protein 1"},
    {id: "calcineurin b-like protein"},
    {id: "calcium-binding protein chp"},
    {id: "calcium-binding protein p22"},
    {id: "ef-hand calcium-binding domain-containing protein p22"},
    {id: "calcipressin-1"},
    {id: "down syndrome critical region protein 1 homolog"},
    {id: "myocyte-enriched calcineurin-interacting protein 1"},
    {id: "mcip1"},
    {id: "regulator of calcineurin 1"},
    {id: "calcipressin-2"},
    {id: "calcineurin inhibitory protein zaki-4"},
    {id: "down syndrome candidate region 1-like protein 1"},
    {id: "regulator of calcineurin 2"},
    {id: "calcium homeostasis modulator protein 2"},
    {id: "protein fam26b"},
    {id: "calcium homeostasis modulator protein 5"},
    {id: "protein fam26e"},
    {id: "calcium homeostasis modulator protein 6"},
    {id: "protein fam26f"},
    {id: "calcium load-activated calcium channel"},
    {id: "clac channel"},
    {id: "gel complex subunit tmco1"},
    {id: "transmembrane and coiled-coil domain-containing protein 1"},
    {id: "calcium permeable stress-gated cation channel 1"},
    {id: "transmembrane protein 63c"},
    {id: "calcium release-activated calcium modulator 2 protein"},
    {id: "orai calcium release-activated calcium modulator 2"},
    {id: "calcium-activated potassium channel subunit alpha-1"},
    {id: "bk channel"},
    {id: "bkca alpha"},
    {id: "calcium-activated potassium channel, subfamily m subunit alpha-1"},
    {id: "k(vca)alpha"},
    {id: "kca1.1"},
    {id: "maxi k channel"},
    {id: "maxik"},
    {id: "slo-alpha"},
    {id: "slo1"},
    {id: "slowpoke homolog"},
    {id: "slo homolog"},
    {id: "calcium-activated potassium channel subunit beta"},
    {id: "bkbeta"},
    {id: "bk channel subunit beta"},
    {id: "calcium-activated potassium channel, subfamily m subunit beta"},
    {id: "charybdotoxin receptor subunit beta"},
    {id: "k(vca)beta"},
    {id: "maxi k channel subunit beta"},
    {id: "slo-beta"},
    {id: "calcium-activated potassium channel subunit beta-1"},
    {id: "bk channel subunit beta-1"},
    {id: "bkbeta1"},
    {id: "calcium-activated potassium channel, subfamily m subunit beta-1"},
    {id: "charybdotoxin receptor subunit beta-1"},
    {id: "k(vca)beta-1"},
    {id: "maxi k channel subunit beta-1"},
    {id: "slo-beta-1"},
    {id: "slowpoke-beta"},
    {id: "calcium-activated potassium channel subunit beta-3"},
    {id: "calcium-activated potassium channel subfamily m subunit beta-3"},
    {id: "maxi k channel subunit beta-3"},
    {id: "calcium-activated potassium channel subunit beta-4"},
    {id: "bk channel subunit beta-4"},
    {id: "bkbeta4"},
    {id: "calcium-activated potassium channel, subfamily m subunit beta-4"},
    {id: "charybdotoxin receptor subunit beta-4"},
    {id: "k(vca)beta-4"},
    {id: "maxi k channel subunit beta-4"},
    {id: "slo-beta-4"},
    {id: "calcium-binding and spermatid-specific protein 1"},
    {id: "casein-like phosphoprotein"},
    {id: "protein rsd-6"},
    {id: "calcium-binding protein 1"},
    {id: "cabp1"},
    {id: "caldendrin"},
    {id: "calcium-binding protein 7"},
    {id: "cabp7"},
    {id: "calneuron ii"},
    {id: "calneuron-2"},
    {id: "calcium-binding protein 8"},
    {id: "cabp8"},
    {id: "calneuron i"},
    {id: "calneuron-1"},
    {id: "calcium-independent mitochondrial carrier protein scamc-3l"},
    {id: "mitochondrial atp-mg/pi carrier protein slc25a41"},
    {id: "small calcium-binding mitochondrial carrier protein 3-like"},
    {id: "scamc-3-like"},
    {id: "scamc-3l"},
    {id: "solute carrier family 25 member 41"},
    {id: "calcium-independent phospholipase a2-gamma"},
    {id: "ec 3.1.1.-"},
    {id: "ec 3.1.1.5"},
    {id: "intracellular membrane-associated calcium-independent phospholipase a2 gamma"},
    {id: "ipla2-gamma"},
    {id: "patatin-like phospholipase domain-containing protein 8"},
    {id: "calcium-regulated heat stable protein 1"},
    {id: "calcium-regulated heat-stable protein of 24 kda"},
    {id: "crhsp-24"},
    {id: "calcium-responsive transcription factor"},
    {id: "amyotrophic lateral sclerosis 2 chromosomal region candidate gene 8 protein"},
    {id: "calcium-response factor"},
    {id: "carf"},
    {id: "calcium-transporting atpase"},
    {id: "ec 7.2.2.10"},
    {id: "calcium-transporting atpase type 2c member 2"},
    {id: "atpase 2c2"},
    {id: "ca(2+)/mn(2+)-atpase 2c2"},
    {id: "secretory pathway ca(2+)-transporting atpase type 2"},
    {id: "spca2"},
    {id: "calcium/calmodulin-dependent protein kinase type 1b"},
    {id: "ec 2.7.11.17"},
    {id: "cam kinase i beta"},
    {id: "cam kinase ib"},
    {id: "cam-ki beta"},
    {id: "camki-beta"},
    {id: "pregnancy up-regulated non-ubiquitously-expressed cam kinase homolog"},
    {id: "calcium/calmodulin-dependent protein kinase type 1g"},
    {id: "cam kinase i gamma"},
    {id: "cam kinase ig"},
    {id: "cam-ki gamma"},
    {id: "camki gamma"},
    {id: "camkig"},
    {id: "camk-like creb kinase iii"},
    {id: "click iii"},
    {id: "calcium/calmodulin-dependent protein kinase type ii subunit gamma"},
    {id: "cam kinase ii subunit gamma"},
    {id: "camk-ii subunit gamma"},
    {id: "calcyclin-binding protein"},
    {id: "cacybp"},
    {id: "calmodulin-lysine n-methyltransferase"},
    {id: "clnmt"},
    {id: "cam kmt"},
    {id: "ec 2.1.1.60"},
    {id: "calpain-13"},
    {id: "ec 3.4.22.-"},
    {id: "calcium-activated neutral proteinase 13"},
    {id: "canp 13"},
    {id: "calpain-2 catalytic subunit"},
    {id: "ec 3.4.22.53"},
    {id: "calcium-activated neutral proteinase 2"},
    {id: "canp 2"},
    {id: "calpain m-type"},
    {id: "calpain-2 large subunit"},
    {id: "millimolar-calpain"},
    {id: "m-calpain"},
    {id: "calpain-3"},
    {id: "ec 3.4.22.54"},
    {id: "calpain-8"},
    {id: "calpain large subunit 4"},
    {id: "new calpain 2"},
    {id: "ncl-2"},
    {id: "stomach-specific m-type calpain"},
    {id: "calpain-9"},
    {id: "digestive tract-specific calpain"},
    {id: "new calpain 4"},
    {id: "ncl-4"},
    {id: "calponin-1"},
    {id: "basic calponin"},
    {id: "calponin h1, smooth muscle"},
    {id: "calponin-3"},
    {id: "calponin, acidic isoform"},
    {id: "calponin, non-muscle isoform"},
    {id: "calreticulin"},
    {id: "calbp"},
    {id: "crp55"},
    {id: "calcium-binding protein 3"},
    {id: "cabp3"},
    {id: "calregulin"},
    {id: "endoplasmic reticulum resident protein 60"},
    {id: "erp60"},
    {id: "hacbp"},
    {id: "calretinin"},
    {id: "cr"},
    {id: "calsequestrin-1"},
    {id: "aspartactin"},
    {id: "calsequestrin, skeletal muscle isoform"},
    {id: "laminin-binding protein"},
    {id: "cancer-associated gene 1 protein homolog"},
    {id: "cage-1"},
    {id: "cancer/testis antigen 3 homolog"},
    {id: "ct3 homolog"},
    {id: "cannabinoid receptor 2"},
    {id: "cb-2"},
    {id: "cb2"},
    {id: "rcb2"},
    {id: "cap-specific mrna"},
    {id: "nucleoside-2'-o-"},
    {id: "ec 2.1.1.57"},
    {id: "cap methyltransferase 1"},
    {id: "cap1 2'o-ribose methyltransferase 1"},
    {id: "mtr1"},
    {id: "ftsj methyltransferase domain-containing protein 2"},
    {id: "ec 2.1.1.296"},
    {id: "carbamoyl-phosphate synthase"},
    {id: "ammonia"},
    {id: "ec 6.3.4.16"},
    {id: "carbamoyl-phosphate synthetase i"},
    {id: "cpsase i"},
    {id: "carbohydrate sulfotransferase"},
    {id: "ec 2.8.2.-"},
    {id: "carbohydrate sulfotransferase 1"},
    {id: "galactose/n-acetylglucosamine/n-acetylglucosamine 6-o-sulfotransferase 1"},
    {id: "gst-1"},
    {id: "keratan sulfate gal-6 sulfotransferase"},
    {id: "ks6st"},
    {id: "ksgal6st"},
    {id: "ksst"},
    {id: "ec 2.8.2.21"},
    {id: "carbohydrate sulfotransferase 7"},
    {id: "ec 2.8.2.17"},
    {id: "chondroitin 6-sulfotransferase 2"},
    {id: "c6st-2"},
    {id: "galactose/n-acetylglucosamine/n-acetylglucosamine 6-o-sulfotransferase 5"},
    {id: "gst-5"},
    {id: "n-acetylglucosamine 6-o-sulfotransferase 4"},
    {id: "glcnac6st-4"},
    {id: "gn6st-4"},
    {id: "carbonic anhydrase"},
    {id: "ec 4.2.1.1"},
    {id: "carbonic anhydrase 1"},
    {id: "carbonate dehydratase i"},
    {id: "carbonic anhydrase i"},
    {id: "ca-i"},
    {id: "carbonic anhydrase 2"},
    {id: "carbonate dehydratase ii"},
    {id: "carbonic anhydrase ii"},
    {id: "ca-ii"},
    {id: "carbonic anhydrase 3"},
    {id: "carbonate dehydratase iii"},
    {id: "carbonic anhydrase iii"},
    {id: "ca-iii"},
    {id: "carbonic anhydrase 5a, mitochondrial"},
    {id: "carbonate dehydratase va"},
    {id: "carbonic anhydrase va"},
    {id: "ca-va"},
    {id: "carbonic anhydrase 5b, mitochondrial"},
    {id: "carbonate dehydratase vb"},
    {id: "carbonic anhydrase vb"},
    {id: "ca-vb"},
    {id: "carbonic anhydrase-related protein"},
    {id: "carp"},
    {id: "carbonic anhydrase viii"},
    {id: "ca-viii"},
    {id: "carbonyl reductase"},
    {id: "nadph"},
    {id: "ec 1.1.1.184"},
    {id: "monomeric carbonyl reductase 3"},
    {id: "quinone reductase cbr3"},
    {id: "carboxylic ester hydrolase"},
    {id: "carboxymethylenebutenolidase homolog"},
    {id: "liver regeneration-related protein lrrg072"},
    {id: "carboxypeptidase"},
    {id: "ec 3.4.16.-"},
    {id: "carboxypeptidase a1"},
    {id: "ec 3.4.17.1"},
    {id: "carboxypeptidase b2"},
    {id: "ec 3.4.17.20"},
    {id: "carboxypeptidase r"},
    {id: "cpr"},
    {id: "carboxypeptidase u"},
    {id: "cpu"},
    {id: "thrombin-activable fibrinolysis inhibitor"},
    {id: "tafi"},
    {id: "carboxypeptidase n catalytic chain"},
    {id: "cpn"},
    {id: "ec 3.4.17.3"},
    {id: "carboxypeptidase n polypeptide 1"},
    {id: "carboxypeptidase n small subunit"},
    {id: "carboxypeptidase q"},
    {id: "hematopoietic lineage switch 2 related protein"},
    {id: "hls2-rp"},
    {id: "liver annexin-like protein 1"},
    {id: "lal-1"},
    {id: "plasma glutamate carboxypeptidase"},
    {id: "carboxypeptidase x"},
    {id: "m14 family"},
    {id: "carcinoembryonic antigen-related cell adhesion molecule 1"},
    {id: "atp-dependent taurocolate-carrier protein"},
    {id: "cell-cam 105"},
    {id: "c-cam 105"},
    {id: "ecto-atpase"},
    {id: "gp110"},
    {id: "pp120"},
    {id: "cd antigen cd66a"},
    {id: "cardiolipin synthase"},
    {id: "cmp-forming"},
    {id: "cls"},
    {id: "ec 2.7.8.41"},
    {id: "cardiotrophin 2"},
    {id: "neuropoietin"},
    {id: "carnosine n-methyltransferase"},
    {id: "ec 2.1.1.22"},
    {id: "casein kinase 1 epsilon"},
    {id: "casein kinase 1, epsilon"},
    {id: "casein kinase 1 epsilon-3"},
    {id: "casein kinase1 epsilon-2"},
    {id: "casein kinase i isoform delta"},
    {id: "cki-delta"},
    {id: "tau-protein kinase csnk1d"},
    {id: "casein kinase i isoform gamma-1"},
    {id: "cki-gamma 1"},
    {id: "casein kinase i isoform gamma-2"},
    {id: "cki-gamma 2"},
    {id: "casein kinase i isoform gamma-3"},
    {id: "cki-gamma 3"},
    {id: "casein kinase ii subunit alpha"},
    {id: "ck ii alpha"},
    {id: "casein kinase ii subunit beta"},
    {id: "ck ii beta"},
    {id: "caspase recruitment domain-containing protein 9"},
    {id: "rcard9"},
    {id: "caspase-2"},
    {id: "casp-2"},
    {id: "ec 3.4.22.55"},
    {id: "protease ich-1"},
    {id: "cleaved into: caspase-2 subunit p18; caspase-2 subunit p13; caspase-2 subunit p12"},
    {id: "caspase-8"},
    {id: "casp-8"},
    {id: "ec 3.4.22.61"},
    {id: "cleaved into: caspase-8 subunit p18; caspase-8 subunit p10"},
    {id: "caspase-9"},
    {id: "caspase-9 long isoform"},
    {id: "catenin alpha 3"},
    {id: "rgd1562230 protein"},
    {id: "cathepsin 7"},
    {id: "cathepsin b"},
    {id: "ec 3.4.22.1"},
    {id: "cathepsin d"},
    {id: "ec 3.4.23.5"},
    {id: "cathepsin j"},
    {id: "cathepsin l-related protein"},
    {id: "cathepsin p"},
    {id: "catlrp-p"},
    {id: "cathepsin k"},
    {id: "ec 3.4.22.38"},
    {id: "cathepsin q"},
    {id: "cathepsin z"},
    {id: "ec 3.4.18.1"},
    {id: "cathepsin y"},
    {id: "cation channel sperm-associated protein 2"},
    {id: "catsper2"},
    {id: "cation-transporting atpase"},
    {id: "ec 7.2.2.-"},
    {id: "cationic amino acid transporter 2"},
    {id: "cat-2"},
    {id: "cat2"},
    {id: "low affinity cationic amino acid transporter 2"},
    {id: "solute carrier family 7 member 2"},
    {id: "caveolae-associated protein 2"},
    {id: "cavin-2"},
    {id: "phosphatidylserine-binding protein"},
    {id: "serum deprivation-response protein"},
    {id: "caveolae-associated protein 4"},
    {id: "muscle-related coiled-coil protein"},
    {id: "muscle-restricted coiled-coil protein"},
    {id: "cb1-725"},
    {id: "immunoglobulin superfamily, member 5"},
    {id: "cbp/p300-interacting transactivator 4"},
    {id: "msg1-related protein 2"},
    {id: "mrg-2"},
    {id: "cbx3 protein"},
    {id: "chromobox 3"},
    {id: "cc1-9"},
    {id: "chitinase domain containing 1"},
    {id: "cc2-17"},
    {id: "sulfide quinone oxidoreductase"},
    {id: "ccdc110 protein"},
    {id: "coiled-coil domain containing 110"},
    {id: "ccdc123 protein"},
    {id: "centrosomal protein 89"},
    {id: "ccdc148 protein"},
    {id: "coiled-coil domain containing 148"},
    {id: "ccdc186"},
    {id: "coiled-coil domain containing 186"},
    {id: "ccl9-like protein"},
    {id: "chemokine (c-c motif) ligand 9"},
    {id: "chemokine ccl9"},
    {id: "ccnk protein"},
    {id: "cyclin k"},
    {id: "cd2"},
    {id: "cytoplasmic tail"},
    {id: "cdc40 protein"},
    {id: "cell division cycle 40"},
    {id: "cdc42 effector protein 1"},
    {id: "binder of rho gtpases 5"},
    {id: "cdc42 effector protein 2"},
    {id: "binder of rho gtpases 1"},
    {id: "cell adhesion molecule 1"},
    {id: "synaptic cell adhesion molecule"},
    {id: "syncam"},
    {id: "tumor suppressor in lung cancer 1 homolog"},
    {id: "tslc-1"},
    {id: "cell adhesion molecule 4"},
    {id: "immunoglobulin superfamily member 4c"},
    {id: "igsf4c"},
    {id: "nectin-like protein 4"},
    {id: "necl-4"},
    {id: "cell adhesion molecule dscam"},
    {id: "down syndrome cell adhesion molecule homolog"},
    {id: "cell cycle control protein 50a"},
    {id: "p4-atpase flippase complex beta subunit tmem30a"},
    {id: "transmembrane protein 30a"},
    {id: "cell cycle regulator of non-homologous end joining"},
    {id: "cell cycle regulator of nhej"},
    {id: "modulator of retrovirus infection homolog"},
    {id: "cell death-inducing p53-target protein 1"},
    {id: "litaf-like protein"},
    {id: "cell division control protein 42 homolog"},
    {id: "cell division cycle 5-like protein"},
    {id: "cdc5-like protein"},
    {id: "pombe cdc5-related protein"},
    {id: "cell division cycle associated 5"},
    {id: "loc684771 protein"},
    {id: "cell division cycle protein 123 homolog"},
    {id: "protein d123"},
    {id: "cell division cycle-associated protein 3"},
    {id: "trigger of mitotic entry protein 1"},
    {id: "tome-1"},
    {id: "cell surface glycoprotein cd200 receptor 1"},
    {id: "cd200 cell surface glycoprotein receptor"},
    {id: "cell surface glycoprotein ox2 receptor 1"},
    {id: "ox102 antigen"},
    {id: "cell surface glycoprotein muc18"},
    {id: "gicerin"},
    {id: "melanoma cell adhesion molecule"},
    {id: "melanoma-associated antigen muc18"},
    {id: "cd antigen cd146"},
    {id: "cellular communication network factor 4"},
    {id: "wisp1 protein"},
    {id: "centrobin, centriole duplication and spindle assembly protein"},
    {id: "lyst-interacting protein 8"},
    {id: "centromere protein n"},
    {id: "cenp-n"},
    {id: "da2-27"},
    {id: "da2-4"},
    {id: "centromere protein r"},
    {id: "cenp-r"},
    {id: "nuclear receptor-interacting factor 3"},
    {id: "centromere protein t"},
    {id: "cenp-t"},
    {id: "centromere protein u"},
    {id: "cenp-u"},
    {id: "mlf1-interacting protein"},
    {id: "centromere protein w"},
    {id: "cenp-w"},
    {id: "cancer-up-regulated gene 2 protein"},
    {id: "centrosomal protein 57-like 1"},
    {id: "centrosomal protein cep57l1"},
    {id: "centrosomal protein 85"},
    {id: "coiled-coil domain containing 21"},
    {id: "centrosomal protein ccdc61"},
    {id: "coiled-coil domain-containing protein 61"},
    {id: "vfl3 homolog"},
    {id: "centrosomal protein 57kda-like protein 1"},
    {id: "centrosomal protein of 57 kda-related protein"},
    {id: "cep57r"},
    {id: "cep57-related protein"},
    {id: "centrosomal protein poc5"},
    {id: "protein of centriole 5"},
    {id: "centrosomal protein kizuna"},
    {id: "polo-like kinase 1 substrate 1"},
    {id: "centrosomal protein of 162 kda"},
    {id: "cep162"},
    {id: "protein qn1 homolog"},
    {id: "centrosomal protein of 41 kda"},
    {id: "cep41"},
    {id: "testis-specific gene a14 protein"},
    {id: "centrosomal protein of 44 kda"},
    {id: "cep44"},
    {id: "centrosomal protein of 55 kda"},
    {id: "cep55"},
    {id: "centrosomal protein of 57 kda"},
    {id: "cep57"},
    {id: "translokin"},
    {id: "centrosomal protein of 70 kda"},
    {id: "cep70"},
    {id: "centrosomal protein of 83 kda"},
    {id: "cep83"},
    {id: "coiled-coil domain-containing protein 41"},
    {id: "ceramide glucosyltransferase"},
    {id: "ec 2.4.1.80"},
    {id: "glct-1"},
    {id: "glucosylceramide synthase"},
    {id: "gcs"},
    {id: "glycosylceramide synthase"},
    {id: "udp-glucose ceramide glucosyltransferase"},
    {id: "udp-glucose:n-acylsphingosine d-glucosyltransferase"},
    {id: "ceramide synthase 2"},
    {id: "lag1 homolog, ceramide synthase 2"},
    {id: "ceramide synthase 3"},
    {id: "lass3 protein"},
    {id: "ceramide transfer protein"},
    {id: "collagen type iv alpha-3-binding protein"},
    {id: "ceramide-1-phosphate transfer protein"},
    {id: "glycolipid transfer protein domain-containing protein 1"},
    {id: "cptp"},
    {id: "cerebellin-1"},
    {id: "precerebellin"},
    {id: "cleaved into: cerebellin (cer); [des-ser1]-cerebellin (des-ser(1)-cerebellin) ([des-ser1]cer) (des-cer) (des-ser1cer)"},
    {id: "cerebellin-2"},
    {id: "cerebellin-like protein"},
    {id: "cerebral dopamine neurotrophic factor"},
    {id: "armet-like protein 1"},
    {id: "arginine-rich protein mutated in early stage tumors-like 1"},
    {id: "conserved dopamine neurotrophic factor"},
    {id: "cfh protein"},
    {id: "complement factor h"},
    {id: "complement factor h-related protein b"},
    {id: "chaperonin containing tcp1 subunit 6b"},
    {id: "chaperonin containing tcp1, subunit 6b (zeta 2)"},
    {id: "chaperonin containing tcp1, subunit 8"},
    {id: "theta"},
    {id: "charged multivesicular body protein 3"},
    {id: "chromatin-modifying protein 3"},
    {id: "vacuolar protein sorting-associated protein 24"},
    {id: "rvps24p"},
    {id: "charged multivesicular body protein 5"},
    {id: "chromatin-modifying protein 5"},
    {id: "chemerin-like receptor 1"},
    {id: "chemokine-like receptor 1"},
    {id: "g-protein coupled chemoattractant-like receptor"},
    {id: "g-protein coupled receptor dez"},
    {id: "chemokine"},
    {id: "c-c motif"},
    {id: "chemokine xc receptor 1"},
    {id: "x-c motif chemokine receptor 1"},
    {id: "chemokine xcl1"},
    {id: "x-c motif chemokine ligand 1"},
    {id: "chimaerin"},
    {id: "chimerin"},
    {id: "chitinase-3-like protein 1"},
    {id: "cartilage glycoprotein 39"},
    {id: "cgp-39"},
    {id: "gp-39"},
    {id: "chloride anion exchanger"},
    {id: "down-regulated in adenoma"},
    {id: "protein dra"},
    {id: "solute carrier family 26 member 3"},
    {id: "chloride channel accessory 4"},
    {id: "parturition-related protein prp3"},
    {id: "chloride channel protein 2"},
    {id: "clc-2"},
    {id: "chloride voltage-gated channel kb"},
    {id: "clc-k2a"},
    {id: "clc-k2b"},
    {id: "clc-k2c"},
    {id: "clc-k2d"},
    {id: "cholesterol 25-hydroxylase"},
    {id: "ec 1.14.99.38"},
    {id: "cholesterol 25-monooxygenase"},
    {id: "cholesterol side-chain cleavage enzyme, mitochondrial"},
    {id: "ec 1.14.15.6"},
    {id: "cholesterol desmolase"},
    {id: "cholesterol transporter abca5"},
    {id: "atp-binding cassette sub-family a member 5"},
    {id: "choline dehydrogenase, mitochondrial"},
    {id: "cdh"},
    {id: "chd"},
    {id: "ec 1.1.99.1"},
    {id: "choline kinase alpha"},
    {id: "ck"},
    {id: "ec 2.7.1.32"},
    {id: "chetk-alpha"},
    {id: "ethanolamine kinase"},
    {id: "ek"},
    {id: "ec 2.7.1.82"},
    {id: "choline transporter-like protein 4"},
    {id: "solute carrier family 44 member 4"},
    {id: "thiamine pyrophosphate transporter 1"},
    {id: "choline-phosphate cytidylyltransferase a"},
    {id: "ec 2.7.7.15"},
    {id: "cct-alpha"},
    {id: "ctp:phosphocholine cytidylyltransferase a"},
    {id: "cct a"},
    {id: "ct a"},
    {id: "phosphorylcholine transferase a"},
    {id: "choline/ethanolamine kinase"},
    {id: "choline kinase beta"},
    {id: "ckb"},
    {id: "choline/ethanolamine kinase beta"},
    {id: "ckekb"},
    {id: "choline/ethanolaminephosphotransferase 1"},
    {id: "ec 2.7.8.1"},
    {id: "ec 2.7.8.2"},
    {id: "1-alkenyl-2-acylglycerol choline phosphotransferase"},
    {id: "ec 2.7.8.22"},
    {id: "cholinephosphotransferase 1"},
    {id: "diacylglycerol cholinephosphotransferase 1"},
    {id: "cholinergic receptor nicotinic alpha 3 subunit"},
    {id: "neuronal nicotinic acetylcholine receptor alpha3 subunit"},
    {id: "chondroitin sulfate proteoglycan 5"},
    {id: "acidic leucine-rich egf-like domain-containing brain protein"},
    {id: "neuroglycan c"},
    {id: "chorion-specific transcription factor gcma"},
    {id: "gcm motif protein 1"},
    {id: "glial cells missing homolog 1"},
    {id: "chromatin target of prmt1 protein"},
    {id: "friend of prmt1 protein"},
    {id: "small arginine- and glycine-rich protein"},
    {id: "srag"},
    {id: "chromatin-remodeling atpase ino80"},
    {id: "chromobox homolog 3 (hp1 gamma homolog, drosophila)"},
    {id: "chromobox 5"},
    {id: "chromobox homolog 5 (hp1 alpha homolog, drosophila)"},
    {id: "chromodomain y-like protein"},
    {id: "cdy-like"},
    {id: "crotonyl-coa hydratase"},
    {id: "ec 4.2.1.-"},
    {id: "putative tubulin acetyltransferase cdyl"},
    {id: "chromodomain-helicase-dna-binding protein 8"},
    {id: "chd-8"},
    {id: "atp-dependent helicase chd8"},
    {id: "axis duplication inhibitor"},
    {id: "duplin"},
    {id: "chymotrypsin-like elastase family member 2a"},
    {id: "ec 3.4.21.71"},
    {id: "elastase-2"},
    {id: "elastase-2a"},
    {id: "cilia and flagella associated protein 77"},
    {id: "rgd1564114 protein"},
    {id: "cilia- and flagella-associated protein 36"},
    {id: "coiled-coil domain-containing protein 104"},
    {id: "ciliogenesis and planar polarity effector 2"},
    {id: "rem2- and rab-like small gtpase 1"},
    {id: "cirrhosis, autosomal recessive 1a"},
    {id: "human"},
    {id: "utp4 small subunit processome component"},
    {id: "citramalyl-coa lyase, mitochondrial"},
    {id: "ec 4.1.3.25"},
    {id: "(3s)-malyl-coa thioesterase"},
    {id: "ec 3.1.2.30"},
    {id: "beta-methylmalate synthase"},
    {id: "ec 2.3.3.-"},
    {id: "citrate lyase subunit beta-like protein, mitochondrial"},
    {id: "citrate lyase beta-like"},
    {id: "malate synthase"},
    {id: "ec 2.3.3.9"},
    {id: "citron rho-interacting kinase"},
    {id: "crik"},
    {id: "rho-interacting, serine/threonine-protein kinase 21"},
    {id: "clarin-3"},
    {id: "transmembrane protein 12"},
    {id: "clathrin light chain a"},
    {id: "lca"},
    {id: "clathrin light chain b"},
    {id: "lcb"},
    {id: "claudin-3"},
    {id: "rat ventral prostate.1 protein"},
    {id: "rvp1"},
    {id: "clavesin-1"},
    {id: "retinaldehyde-binding protein 1-like 1"},
    {id: "clavesin-2"},
    {id: "retinaldehyde-binding protein 1-like 2"},
    {id: "cleavage and polyadenylation specificity factor subunit 2"},
    {id: "cleavage and polyadenylation specificity factor 100 kda subunit"},
    {id: "cleavage and polyadenylation specificity factor subunit 4"},
    {id: "cpsf 30 kda subunit"},
    {id: "cleavage and polyadenylation specificity factor 30 kda subunit"},
    {id: "cleavage and polyadenylation specificity factor subunit 5"},
    {id: "nucleoside diphosphate-linked moiety x motif 21"},
    {id: "nudix motif 21"},
    {id: "nudix hydrolase 21"},
    {id: "cleavage stimulation factor subunit 1"},
    {id: "cleavage stimulation factor 50 kda subunit"},
    {id: "cstf 50 kda subunit"},
    {id: "cstf-50"},
    {id: "coagulation factor ii"},
    {id: "thrombin"},
    {id: "coagulation factor vii"},
    {id: "ec 3.4.21.21"},
    {id: "serum prothrombin conversion accelerator"},
    {id: "cleaved into: factor vii light chain; factor vii heavy chain"},
    {id: "coagulation factor xi"},
    {id: "lrrgt00086"},
    {id: "coagulation factor xiii b chain"},
    {id: "f13b protein"},
    {id: "coatomer subunit beta"},
    {id: "beta-coat protein"},
    {id: "beta-cop"},
    {id: "coatomer subunit delta"},
    {id: "archain"},
    {id: "delta-coat protein"},
    {id: "delta-cop"},
    {id: "coatomer subunit epsilon"},
    {id: "epsilon-coat protein"},
    {id: "cocaine- and amphetamine-regulated transcript protein"},
    {id: "cleaved into: cart(1-52); cart(55-102); cart(62-102)"},
    {id: "cofilin-1"},
    {id: "cofilin, non-muscle isoform"},
    {id: "cohesin subunit sa"},
    {id: "scc3 homolog"},
    {id: "stromal antigen"},
    {id: "cohesin subunit sa-3"},
    {id: "scc3 homolog 3"},
    {id: "stromal antigen 3"},
    {id: "stromalin-3"},
    {id: "coiled-coil alpha-helical rod protein 1"},
    {id: "alpha-helical coiled-coil rod protein"},
    {id: "coiled-coil and c2 domain-containing protein 1b"},
    {id: "five prime repressor element under dual repression-binding protein 2"},
    {id: "fre under dual repression-binding protein 2"},
    {id: "freud-2"},
    {id: "coiled-coil domain containing 183"},
    {id: "loc499754 protein"},
    {id: "coiled-coil domain-containing protein 172"},
    {id: "tekt2-binding protein 1"},
    {id: "coiled-coil domain-containing protein 80"},
    {id: "down-regulated by oncogenes 1 protein"},
    {id: "steroid-sensitive gene 1 protein"},
    {id: "ssg-1"},
    {id: "coiled-coil domain-containing protein 89"},
    {id: "bc8 orange-interacting protein"},
    {id: "coiled-coil domain-containing protein 91"},
    {id: "gga-binding partner"},
    {id: "coiled-coil-helix-coiled-coil-helix domain containing 2"},
    {id: "coiled-coil-helix-coiled-coil-helix domain-containing protein 2, mitochondrial-like"},
    {id: "col13a1 protein"},
    {id: "collagen type xiii alpha 1 chain"},
    {id: "cold shock domain-containing protein c2"},
    {id: "rna-binding protein pippin"},
    {id: "cold-inducible rna-binding protein"},
    {id: "a18 hnrnp"},
    {id: "glycine-rich rna-binding protein cirp"},
    {id: "collagen alpha-1"},
    {id: "alpha-1 type i collagen"},
    {id: "iii"},
    {id: "xxiii"},
    {id: "collagen beta"},
    {id: "1-o"},
    {id: "collagen type xi alpha 2 chain"},
    {id: "procollagen, type xi, alpha 2"},
    {id: "collectin-12"},
    {id: "collectin placenta protein 1"},
    {id: "cl-p1"},
    {id: "nurse cell scavenger receptor 2"},
    {id: "complement c4b"},
    {id: "chido blood group"},
    {id: "complement component c1q receptor"},
    {id: "c1q/mbl/spa receptor"},
    {id: "c1qr(p)"},
    {id: "c1qrp"},
    {id: "cell surface antigen aa4"},
    {id: "complement component 1 q subcomponent receptor 1"},
    {id: "cd antigen cd93"},
    {id: "complement component c8 beta chain"},
    {id: "complement component 8 subunit beta"},
    {id: "complex i assembly factor acad9, mitochondrial"},
    {id: "acyl-coa dehydrogenase family member 9"},
    {id: "acad-9"},
    {id: "complex i assembly factor tmem126b, mitochondrial"},
    {id: "transmembrane protein 126b"},
    {id: "complex i intermediate-associated protein 30, mitochondrial"},
    {id: "nadh dehydrogenase [ubiquinone] 1 alpha subcomplex assembly factor 1"},
    {id: "complex iii assembly factor lyrm7"},
    {id: "lyr motif-containing protein 7"},
    {id: "complexin-2"},
    {id: "complexin ii"},
    {id: "cpx ii"},
    {id: "synaphin-1"},
    {id: "condensin-2 complex subunit h2"},
    {id: "non-smc condensin ii complex subunit h2"},
    {id: "connector enhancer of kinase suppressor of ras 2"},
    {id: "connector enhancer of ksr 2"},
    {id: "cnk homolog protein 2"},
    {id: "cnk2"},
    {id: "membrane-associated guanylate kinase-interacting protein"},
    {id: "maguin"},
    {id: "connector enhancer of kinase suppressor of ras 3"},
    {id: "connector enhancer of ksr 3"},
    {id: "cnk homolog protein 3"},
    {id: "cnk3"},
    {id: "maguin-like protein"},
    {id: "parturition-related protein 4"},
    {id: "conserved oligomeric golgi complex subunit 2"},
    {id: "component of oligomeric golgi complex 2"},
    {id: "conserved oligomeric golgi complex subunit 3"},
    {id: "component of oligomeric golgi complex 3"},
    {id: "conserved oligomeric golgi complex subunit 4"},
    {id: "component of oligomeric golgi complex 4"},
    {id: "conserved oligomeric golgi complex subunit 6"},
    {id: "cog complex subunit 6"},
    {id: "component of oligomeric golgi complex 6"},
    {id: "conserved oligomeric golgi complex subunit 7"},
    {id: "component of oligomeric golgi complex 7"},
    {id: "conserved oligomeric golgi complex subunit 8"},
    {id: "cog complex subunit 8"},
    {id: "component of oligomeric golgi complex 8"},
    {id: "constitutive androstane receptor splicing variant 2"},
    {id: "nuclear receptor subfamily 1 group i member 3"},
    {id: "contactin-1"},
    {id: "neural cell surface protein f3"},
    {id: "contactin-3"},
    {id: "brain-derived immunoglobulin superfamily protein 1"},
    {id: "big-1"},
    {id: "contactin-4"},
    {id: "brain-derived immunoglobulin superfamily protein 2"},
    {id: "big-2"},
    {id: "contactin-5"},
    {id: "neural recognition molecule nb-2"},
    {id: "contactin-6"},
    {id: "neural recognition molecule nb-3"},
    {id: "contactin-associated protein 1"},
    {id: "caspr"},
    {id: "caspr1"},
    {id: "neurexin iv"},
    {id: "neurexin-4"},
    {id: "paranodin"},
    {id: "p190"},
    {id: "contactin-associated protein like 5-1"},
    {id: "cell recognition molecule caspr5-1"},
    {id: "cell recognition molecule caspr5a"},
    {id: "contactin-associated protein-like 5a"},
    {id: "copine 2"},
    {id: "copine 2 protein"},
    {id: "copine 3"},
    {id: "copine 3 protein"},
    {id: "copine 5"},
    {id: "copine 5 protein"},
    {id: "copine 8"},
    {id: "copine 8 protein"},
    {id: "copine-1"},
    {id: "copine i"},
    {id: "copine-7"},
    {id: "copine vii"},
    {id: "copine-9"},
    {id: "copine ix"},
    {id: "core histone macro-h2a.1"},
    {id: "histone macroh2a1"},
    {id: "mh2a1"},
    {id: "h2a.y"},
    {id: "h2a/y"},
    {id: "core-binding factor subunit beta"},
    {id: "core-binding factor, beta subunit"},
    {id: "corepressor interacting with rbpj 1"},
    {id: "cbf1-interacting corepressor"},
    {id: "coronin-1a"},
    {id: "coronin-like protein a"},
    {id: "clipin-a"},
    {id: "tryptophan aspartate-containing coat protein"},
    {id: "taco"},
    {id: "coronin-7"},
    {id: "crn7"},
    {id: "70 kda wd repeat tumor rejection antigen"},
    {id: "cortactin-binding protein 2"},
    {id: "cortbp2"},
    {id: "corticoliberin"},
    {id: "corticotropin-releasing factor"},
    {id: "crf"},
    {id: "corticotropin-releasing hormone"},
    {id: "corticosteroid-binding globulin"},
    {id: "cbg"},
    {id: "serpin a6"},
    {id: "transcortin"},
    {id: "corticotropin-releasing factor receptor 2"},
    {id: "crf-r-2"},
    {id: "crf-r2"},
    {id: "crfr-2"},
    {id: "corticotropin-releasing hormone receptor 2"},
    {id: "crh-r-2"},
    {id: "crh-r2"},
    {id: "corticotropin-releasing factor-binding protein"},
    {id: "crf-bp"},
    {id: "crf-binding protein"},
    {id: "corticotropin-releasing hormone-binding protein"},
    {id: "costars family protein abracl"},
    {id: "abra c-terminal-like protein"},
    {id: "coxsackievirus and adenovirus receptor homolog"},
    {id: "car"},
    {id: "rcar"},
    {id: "craniofacial development protein 1"},
    {id: "bucentaur"},
    {id: "crossover junction endonuclease mus81"},
    {id: "ec 3.1.22.-"},
    {id: "crystallin zeta like 1"},
    {id: "crystallin, zeta (quinone reductase)-like 1"},
    {id: "cullin-associated nedd8-dissociated protein 1"},
    {id: "cullin-associated and neddylation-dissociated protein 1"},
    {id: "tbp-interacting protein of 120 kda a"},
    {id: "tbp-interacting protein 120a"},
    {id: "p120 cand1"},
    {id: "cullin-associated and neddylation-dissociated 2"},
    {id: "cyanocobalamin reductase / alkylcobalamin dealkylase"},
    {id: "ec 1.16.1.6"},
    {id: "ec 2.5.1.151"},
    {id: "alkylcobalamin:glutathione s-alkyltransferase"},
    {id: "cblc"},
    {id: "cyanocobalamin reductase (cyanide-eliminating)"},
    {id: "methylmalonic aciduria and homocystinuria type c protein"},
    {id: "cyclic amp-dependent transcription factor atf-2"},
    {id: "camp-dependent transcription factor atf-2"},
    {id: "activating transcription factor 2"},
    {id: "camp response element-binding protein cre-bp1"},
    {id: "cyclic amp-dependent transcription factor atf-4"},
    {id: "camp-dependent transcription factor atf-4"},
    {id: "activating transcription factor 4"},
    {id: "ratf-4"},
    {id: "cyclic amp-responsive element-binding protein 1"},
    {id: "creb-1"},
    {id: "camp-responsive element-binding protein 1"},
    {id: "cyclic amp-responsive element-binding protein 3-like protein 3"},
    {id: "camp-responsive element-binding protein 3-like protein 3"},
    {id: "transcription factor creb-h"},
    {id: "cleaved into: processed cyclic amp-responsive element-binding protein 3-like protein 3"},
    {id: "cyclic amp-responsive element-binding protein 3-like protein 4"},
    {id: "camp-responsive element-binding protein 3-like protein 4"},
    {id: "androgen-induced basic leucine zipper protein"},
    {id: "aibzip"},
    {id: "cleaved into: processed cyclic amp-responsive element-binding protein 3-like protein 4"},
    {id: "cyclin dependent kinase inhibitor"},
    {id: "cyclin kinase inhibitor"},
    {id: "cyclin-dependent kinase inhibitor 1b"},
    {id: "p27kip1"},
    {id: "cyclin dependent kinase inhibitor 2d"},
    {id: "similar to cyclin-dependent kinase inhibitor 2d"},
    {id: "cyclin dependent kinase like 2"},
    {id: "lrrgt00026"},
    {id: "cyclin-l1"},
    {id: "cyclin-l"},
    {id: "cyclin ania-6a"},
    {id: "cyclin-q"},
    {id: "cdk10-activating cyclin"},
    {id: "cyclin-m"},
    {id: "cyclin-related protein fam58a"},
    {id: "cyclin-dependent kinase 1"},
    {id: "cdk1"},
    {id: "ec 2.7.11.22"},
    {id: "ec 2.7.11.23"},
    {id: "cell division control protein 2 homolog"},
    {id: "cell division protein kinase 1"},
    {id: "p34 protein kinase"},
    {id: "cyclin-dependent kinase 17"},
    {id: "cell division protein kinase 17"},
    {id: "pctaire-motif protein kinase 2"},
    {id: "serine/threonine-protein kinase pctaire-2"},
    {id: "cyclin-dependent kinase 20"},
    {id: "cell cycle-related kinase"},
    {id: "cell division protein kinase 20"},
    {id: "cyclin-dependent kinase 5 activator 1"},
    {id: "cdk5 activator 1"},
    {id: "cyclin-dependent kinase 5 regulatory subunit 1"},
    {id: "tpkii regulatory subunit"},
    {id: "cleaved into: cyclin-dependent kinase 5 activator 1, p35 (p35); cyclin-dependent kinase 5 activator 1, p25 (p25) (tau protein kinase ii 23 kda subunit) (p23)"},
    {id: "cyclin-dependent kinase 7"},
    {id: "cell division protein kinase 7"},
    {id: "cyclin-dependent kinase inhibitor 1a"},
    {id: "cyclin-dependent kinase inhibitor 1a (p21, cip1)"},
    {id: "p21 protein"},
    {id: "cyclin-dependent kinase inhibitor 2a"},
    {id: "cyclin-dependent kinase 4 inhibitor a"},
    {id: "cdk4i"},
    {id: "p16-ink4a"},
    {id: "p16"},
    {id: "p16-ink4"},
    {id: "cyclin-dependent kinase inhibitor 2c"},
    {id: "cyclin-dependent kinase inhibitor 2c (p18, inhibits cdk4)"},
    {id: "cyclin-dependent kinase inhibitor 3"},
    {id: "ec 3.1.3.16"},
    {id: "ec 3.1.3.48"},
    {id: "cdk2-associated dual-specificity phosphatase"},
    {id: "kinase-associated phosphatase"},
    {id: "cyclin-dependent kinase-like 2"},
    {id: "cyclin-dependent kinase-like 3"},
    {id: "serine/threonine protein kinase nkiatre"},
    {id: "cystatin-8"},
    {id: "cystatin-related epididymal spermatogenic protein"},
    {id: "cystatin-a"},
    {id: "cystatin-alpha"},
    {id: "epidermal stefin"},
    {id: "epidermal thiol proteinase inhibitor"},
    {id: "cystatin-s"},
    {id: "cystatin-1"},
    {id: "protein lm"},
    {id: "cystatin-related protein 2"},
    {id: "prostatic 22 kda glycoprotein p22k15"},
    {id: "cysteine and histidine-rich domain-containing protein 1"},
    {id: "chord domain-containing protein 1"},
    {id: "cysteine dioxygenase"},
    {id: "ec 1.13.11.20"},
    {id: "cysteine dioxygenase type 1"},
    {id: "cysteine dioxygenase type i"},
    {id: "cdo"},
    {id: "cdo-i"},
    {id: "cysteine protease"},
    {id: "cysteine protease atg4b"},
    {id: "autophagy-related protein 4 homolog b"},
    {id: "cysteine sulfinic acid decarboxylase"},
    {id: "ec 4.1.1.29"},
    {id: "aspartate 1-decarboxylase"},
    {id: "ec 4.1.1.11"},
    {id: "cysteine-sulfinate decarboxylase"},
    {id: "sulfinoalanine decarboxylase"},
    {id: "cysteine-rich pdz-binding protein"},
    {id: "cysteine-rich interactor of pdz three"},
    {id: "cysteine-rich interactor of pdz3"},
    {id: "cysteine-rich protein 1"},
    {id: "crp-1"},
    {id: "cysteine-rich intestinal protein"},
    {id: "crip"},
    {id: "cysteine-rich protein 2"},
    {id: "crp-2"},
    {id: "protein esp1"},
    {id: "cysteine-rich secretory protein 1"},
    {id: "32 kda epididymal protein"},
    {id: "acidic epididymal glycoprotein"},
    {id: "protein d"},
    {id: "protein e"},
    {id: "protein iv"},
    {id: "sialoprotein"},
    {id: "sperm-coating glycoprotein"},
    {id: "scp"},
    {id: "cysteinyl leukotriene receptor 1"},
    {id: "cysltr1"},
    {id: "cystic fibrosis transmembrane conductance regulator"},
    {id: "ec 5.6.1.6"},
    {id: "atp-binding cassette sub-family c member 7"},
    {id: "channel conductance-controlling atpase"},
    {id: "camp-dependent chloride channel"},
    {id: "cyth1 protein"},
    {id: "cytohesin-1"},
    {id: "cytidine deaminase"},
    {id: "ec 3.5.4.5"},
    {id: "cytidine aminohydrolase"},
    {id: "cytidine monophosphate-n-acetylneuraminic acid hydroxylase"},
    {id: "ec 1.14.18.2"},
    {id: "cmp-n-acetylneuraminate monooxygenase"},
    {id: "cmp-neu5ac hydroxylase"},
    {id: "cmp-neuac hydroxylase"},
    {id: "cytl1 protein"},
    {id: "cytokine like 1"},
    {id: "cytochrome p450"},
    {id: "ec 1.14.14.-"},
    {id: "ec 1.14.14.1"},
    {id: "cytochrome p450 26b1"},
    {id: "ec 1.14.13.-"},
    {id: "cytochrome p450 2b3"},
    {id: "cypiib3"},
    {id: "cytochrome p450 2c11"},
    {id: "cypiic11"},
    {id: "cytochrome p-450(m-1)"},
    {id: "cytochrome p450-ut-2"},
    {id: "cytochrome p450-ut-a"},
    {id: "cytochrome p450h"},
    {id: "cytochrome p450 2c70"},
    {id: "cypiic70"},
    {id: "cytochrome p-450md"},
    {id: "cytochrome p450 p49"},
    {id: "cytochrome p450 2d4"},
    {id: "cypiid18"},
    {id: "cypiid4"},
    {id: "cytochrome p450 2d-29"},
    {id: "cytochrome p450 2d-35"},
    {id: "cytochrome p450 2d18"},
    {id: "cytochrome p450-cmf3"},
    {id: "cytochrome p450-db4"},
    {id: "debrisoquine 4-hydroxylase"},
    {id: "cytochrome p450 2f2"},
    {id: "cypiif2"},
    {id: "cytochrome p450-nah-2"},
    {id: "naphthalene dehydrogenase"},
    {id: "naphthalene hydroxylase"},
    {id: "cytochrome p450 2j3"},
    {id: "cypiij3"},
    {id: "cytochrome p450 2u1"},
    {id: "long-chain fatty acid omega-monooxygenase"},
    {id: "ec 1.14.14.80"},
    {id: "cytochrome p450 2c13"},
    {id: "cytochrome p450, family 2, subfamily c, polypeptide 13"},
    {id: "cytochrome p450 3a"},
    {id: "cytochrome p450 4a10"},
    {id: "cypiva10"},
    {id: "cytochrome p450-la-omega 1"},
    {id: "cytochrome p452"},
    {id: "lauric acid omega-hydroxylase"},
    {id: "cytochrome p450 4a12"},
    {id: "cypiva12"},
    {id: "cypiva8"},
    {id: "cytochrome p450-kp1"},
    {id: "cytochrome p450-pp1"},
    {id: "cytochrome p450 4v2"},
    {id: "docosahexaenoic acid omega-hydroxylase cyp4v2"},
    {id: "ec 1.14.14.79"},
    {id: "cytochrome p450 4x1"},
    {id: "cypivx1"},
    {id: "cytochrome p450, family 2, subfamily c, polypeptide 6, variant 1"},
    {id: "cytochrome p450, family 2, subfamily c, polypeptide 7-like"},
    {id: "cytochrome b-245 chaperone 1"},
    {id: "essential for reactive oxygen species protein"},
    {id: "eros"},
    {id: "cytochrome b-245 light chain"},
    {id: "cytochrome b(558) alpha chain"},
    {id: "cytochrome b558 subunit alpha"},
    {id: "neutrophil cytochrome b 22 kda polypeptide"},
    {id: "superoxide-generating nadph oxidase light chain subunit"},
    {id: "p22 phagocyte b-cytochrome"},
    {id: "p22-phox"},
    {id: "cytochrome b-c1 complex subunit 1, mitochondrial"},
    {id: "complex iii subunit 1"},
    {id: "core protein i"},
    {id: "ubiquinol-cytochrome-c reductase complex core protein 1"},
    {id: "cytochrome b-c1 complex subunit 2, mitochondrial"},
    {id: "complex iii subunit 2"},
    {id: "core protein ii"},
    {id: "ubiquinol-cytochrome-c reductase complex core protein 2"},
    {id: "cytochrome b-c1 complex subunit 8"},
    {id: "complex iii subunit 8"},
    {id: "complex iii subunit viii"},
    {id: "low molecular mass ubiquinone-binding protein"},
    {id: "ubiquinol-cytochrome c reductase complex 9.5 kda protein"},
    {id: "ubiquinol-cytochrome c reductase complex ubiquinone-binding protein qp-c"},
    {id: "cytochrome b-c1 complex subunit rieske, mitochondrial"},
    {id: "ec 7.1.1.8"},
    {id: "complex iii subunit 5"},
    {id: "cytochrome b-c1 complex subunit 5"},
    {id: "liver regeneration-related protein lrrgt00195"},
    {id: "rieske iron-sulfur protein"},
    {id: "risp"},
    {id: "rieske protein uqcrfs1"},
    {id: "ubiquinol-cytochrome c reductase iron-sulfur subunit"},
    {id: "cleaved into: cytochrome b-c1 complex subunit 9 (su9) (subunit 9) (8 kda subunit 9) (complex iii subunit ix) (cytochrome b-c1 complex subunit 11) (uqcrfs1 mitochondrial targeting sequence) (uqcrfs1 mts) (ubiquinol-cytochrome c reductase 8 kda protein)"},
    {id: "cytochrome b5 reductase 4"},
    {id: "ec 1.6.2.2"},
    {id: "flavohemoprotein b5/b5r"},
    {id: "cb5/cb5r"},
    {id: "cytochrome b5 type b"},
    {id: "cytochrome b5 outer mitochondrial membrane isoform"},
    {id: "cytochrome c oxidase subunit 1"},
    {id: "ec 7.1.1.9"},
    {id: "cytochrome c oxidase polypeptide i"},
    {id: "cytochrome c oxidase subunit 2"},
    {id: "cytochrome c oxidase polypeptide ii"},
    {id: "cytochrome c oxidase subunit 3"},
    {id: "cytochrome c oxidase polypeptide iii"},
    {id: "cytochrome c oxidase subunit 5a, mitochondrial"},
    {id: "cytochrome c oxidase polypeptide va"},
    {id: "cytochrome c oxidase subunit 5b, mitochondrial"},
    {id: "cytochrome c oxidase polypeptide vb"},
    {id: "cytochrome c oxidase subunit 6c"},
    {id: "cytochrome c oxidase polypeptide vic"},
    {id: "cytochrome c oxidase subunit 6c-1"},
    {id: "cytochrome c oxidase polypeptide vic-1"},
    {id: "cytochrome c oxidase subunit 6c-2"},
    {id: "cytochrome c oxidase polypeptide vic-2"},
    {id: "cytochrome c oxidase subunit 7a2, mitochondrial"},
    {id: "cytochrome c oxidase subunit viia-liver/heart"},
    {id: "cytochrome c oxidase subunit viia-l"},
    {id: "cytochrome c oxidase subunit 7b, mitochondrial"},
    {id: "cytochrome c oxidase polypeptide viib"},
    {id: "cytochrome c oxidase subunit 7c, mitochondrial"},
    {id: "cytochrome c oxidase polypeptide viiia"},
    {id: "cytochrome c oxidase polypeptide viic"},
    {id: "cytochrome c oxidase subunit 8c, mitochondrial"},
    {id: "cytochrome c oxidase polypeptide 8 isoform 3"},
    {id: "cytochrome c oxidase polypeptide viii isoform 3"},
    {id: "cox viii-3"},
    {id: "cytochrome c oxidase subunit 8-3"},
    {id: "cytoglobin"},
    {id: "histoglobin"},
    {id: "hgb"},
    {id: "stellate cell activation-associated protein"},
    {id: "ph, sec7 and coiled-coil domain-containing protein 1"},
    {id: "sec7 homolog a"},
    {id: "rsec7-1"},
    {id: "cytohesin-2"},
    {id: "arf nucleotide-binding site opener"},
    {id: "protein arno"},
    {id: "ph, sec7 and coiled-coil domain-containing protein 2"},
    {id: "clm2"},
    {id: "sec7 homolog b"},
    {id: "cytohesin-interacting protein"},
    {id: "pleckstrin homology sec7 and coiled-coil domain-binding protein"},
    {id: "cytokine-like nuclear factor n-pac"},
    {id: "glyoxylate reductase 1 homolog"},
    {id: "nuclear protein np60"},
    {id: "putative oxidoreductase glyr1"},
    {id: "cytoplasmic dynein 1 light intermediate chain 2"},
    {id: "dynein light intermediate chain 2, cytosolic"},
    {id: "lic-2"},
    {id: "lic53/55"},
    {id: "cytoplasmic trna 2-thiolation protein 1"},
    {id: "ec 2.7.7.-"},
    {id: "atp-binding domain-containing protein 3"},
    {id: "cytoplasmic trna adenylyltransferase 1"},
    {id: "cytosol aminopeptidase"},
    {id: "ec 3.4.11.1"},
    {id: "cysteinylglycine-s-conjugate dipeptidase"},
    {id: "ec 3.4.13.23"},
    {id: "leucine aminopeptidase 3"},
    {id: "lap-3"},
    {id: "leucyl aminopeptidase"},
    {id: "lap"},
    {id: "peptidase s"},
    {id: "proline aminopeptidase"},
    {id: "ec 3.4.11.5"},
    {id: "prolyl aminopeptidase"},
    {id: "cytosolic 10-formyltetrahydrofolate dehydrogenase"},
    {id: "10-fthfdh"},
    {id: "aldehyde dehydrogenase family 1 member l1"},
    {id: "fbp-ci"},
    {id: "cytosolic fe-s cluster assembly factor nubp2"},
    {id: "nucleotide-binding protein 2"},
    {id: "nbp 2"},
    {id: "cytosolic arginine sensor for mtorc1 subunit 1"},
    {id: "gats-like protein 3"},
    {id: "cytosolic carboxypeptidase-like protein 5"},
    {id: "ec 3.4.17.24"},
    {id: "atp/gtp-binding protein-like 5"},
    {id: "protein deglutamylase ccp5"},
    {id: "cytosolic non-specific dipeptidase"},
    {id: "ec 3.4.13.18"},
    {id: "cndp dipeptidase 2"},
    {id: "threonyl dipeptidase"},
    {id: "cytospin-a"},
    {id: "specc1-like protein"},
    {id: "sperm antigen with calponin homology and coiled-coil domains 1-like"},
    {id: "cytotoxic t-lymphocyte protein 4"},
    {id: "cytotoxic t-lymphocyte-associated antigen 4"},
    {id: "dopamine d3 receptor"},
    {id: "d-3-phosphoglycerate dehydrogenase"},
    {id: "ec 1.1.1.95"},
    {id: "d-amino-acid oxidase"},
    {id: "daao"},
    {id: "damox"},
    {id: "dao"},
    {id: "ec 1.4.3.3"},
    {id: "d-aminoacyl-trna deacylase"},
    {id: "ec 3.1.1.96"},
    {id: "d-aspartate oxidase"},
    {id: "ec 1.4.3.1"},
    {id: "d-dopachrome decarboxylase"},
    {id: "ec 4.1.1.84"},
    {id: "d-dopachrome tautomerase"},
    {id: "d-ribitol-5-phosphate cytidylyltransferase"},
    {id: "ec 2.7.7.40"},
    {id: "2-c-methyl-d-erythritol 4-phosphate cytidylyltransferase-like protein"},
    {id: "isoprenoid synthase domain-containing protein"},
    {id: "notch1-induced protein"},
    {id: "dap3-binding cell death enhancer 1"},
    {id: "dap3-binding cell death enhancer 1, long form"},
    {id: "dele1(l)"},
    {id: "death ligand signal enhancer"},
    {id: "cleaved into: dap3-binding cell death enhancer 1 short form (dele1(s)) (s-dele1)"},
    {id: "daz-associated protein 2"},
    {id: "deleted in azoospermia-associated protein 2"},
    {id: "proline-rich transcript in brain protein"},
    {id: "dcc-interacting protein 13-beta"},
    {id: "adapter protein containing ph domain, ptb domain and leucine zipper motif 2"},
    {id: "dcn1-like protein"},
    {id: "defective in cullin neddylation protein 1-like protein"},
    {id: "dcn1-like protein 3"},
    {id: "dcnl3"},
    {id: "dcun1 domain-containing protein 3"},
    {id: "defective in cullin neddylation protein 1-like protein 3"},
    {id: "ddb1- and cul4-associated factor 10"},
    {id: "wd repeat-containing protein 32"},
    {id: "ddb1- and cul4-associated factor 11"},
    {id: "wd repeat-containing protein 23"},
    {id: "ddb1- and cul4-associated factor 8"},
    {id: "wd repeat-containing protein 42a"},
    {id: "deah-box helicase 32"},
    {id: "denn domain-containing protein 11"},
    {id: "dennd11"},
    {id: "protein lchn"},
    {id: "denn domain-containing protein 5a"},
    {id: "rab6-interacting protein 1"},
    {id: "rab6ip1"},
    {id: "dis3-like exonuclease 1"},
    {id: "dis3-like exonuclease 2"},
    {id: "dlg associated protein 5"},
    {id: "dlgap5 protein"},
    {id: "dna"},
    {id: "cytosine-5"},
    {id: "ec 2.1.1.37"},
    {id: "dnmt3a"},
    {id: "cysteine methyltransferase dnmt3a"},
    {id: "cytosine-5-"},
    {id: "dna 3'-5' helicase"},
    {id: "ec 5.6.2.4"},
    {id: "dna damage-binding protein 1"},
    {id: "damage-specific dna-binding protein 1"},
    {id: "dna damage-binding protein 2"},
    {id: "damage-specific dna-binding protein 2"},
    {id: "dna damage-induced apoptosis suppressor"},
    {id: "rgd1559690 protein"},
    {id: "dna damage-inducible transcript 3 protein"},
    {id: "c/ebp zeta"},
    {id: "c/ebp-homologous protein"},
    {id: "c/ebp-homologous protein 10"},
    {id: "ccaat/enhancer-binding protein homologous protein"},
    {id: "ddit-3"},
    {id: "growth arrest and dna-damage-inducible protein gadd153"},
    {id: "dna damage-inducible transcript 4-like protein"},
    {id: "soleus muscle atrophied after hindlimb suspension protein 1"},
    {id: "dna damage-regulated autophagy modulator protein 2"},
    {id: "transmembrane protein 77"},
    {id: "dna endonuclease rbbp8"},
    {id: "ctbp-interacting protein"},
    {id: "ctip"},
    {id: "retinoblastoma-binding protein 8"},
    {id: "rbbp-8"},
    {id: "retinoblastoma-interacting protein and myosin-like"},
    {id: "rim"},
    {id: "sporulation in the absence of spo11 protein 2 homolog"},
    {id: "sae2"},
    {id: "dna helicase"},
    {id: "atp-dependent helicase atrx"},
    {id: "dna helicase mcm8"},
    {id: "minichromosome maintenance 8"},
    {id: "dna ligase"},
    {id: "ec 6.5.1.1"},
    {id: "dna nucleotidylexotransferase"},
    {id: "ec 2.7.7.31"},
    {id: "dna polymerase"},
    {id: "ec 2.7.7.7"},
    {id: "dna polymerase beta"},
    {id: "ec 4.2.99.-"},
    {id: "dna polymerase delta interacting protein 2"},
    {id: "dna-directed polymerase delta interacting protein 2"},
    {id: "dna polymerase epsilon catalytic subunit"},
    {id: "dna polymerase epsilon subunit"},
    {id: "dna polymerase ii subunit 2"},
    {id: "dna polymerase epsilon subunit 3"},
    {id: "dna polymerase ii subunit 3"},
    {id: "dna polymerase epsilon subunit p17"},
    {id: "dna polymerase kappa"},
    {id: "dna polymerase lambda"},
    {id: "pol lambda"},
    {id: "dna polymerase subunit gamma-1"},
    {id: "mitochondrial dna polymerase catalytic subunit"},
    {id: "dna polymerase zeta catalytic subunit"},
    {id: "dna primase"},
    {id: "dna primase large subunit"},
    {id: "dna primase 58 kda subunit"},
    {id: "p58"},
    {id: "dna repair and recombination protein rad54-like"},
    {id: "protein okra"},
    {id: "dna repair protein rev1"},
    {id: "dna repair protein swi5 homolog"},
    {id: "protein sae3 homolog"},
    {id: "dna repair protein xrcc1"},
    {id: "x-ray repair cross-complementing protein 1"},
    {id: "dna replication atp-dependent helicase/nuclease"},
    {id: "dna replication licensing factor mcm2"},
    {id: "dna replication licensing factor mcm3"},
    {id: "dna replication licensing factor mcm4"},
    {id: "dna replication licensing factor mcm5"},
    {id: "dna replication licensing factor mcm6"},
    {id: "dna replication licensing factor mcm7"},
    {id: "dna topoisomerase"},
    {id: "ec 5.6.2.1"},
    {id: "dna topoisomerase 2"},
    {id: "ec 5.6.2.2"},
    {id: "dna topoisomerase i"},
    {id: "dna topoisomerase 1"},
    {id: "dna topoisomerase i, mitochondrial"},
    {id: "top1mt"},
    {id: "dna-"},
    {id: "apurinic or apyrimidinic site"},
    {id: "dna-binding protein rfx2"},
    {id: "regulatory factor x 2"},
    {id: "dna-binding protein satb"},
    {id: "special at-rich sequence-binding protein"},
    {id: "dna-damage regulated autophagy modulator 1"},
    {id: "dram protein"},
    {id: "dna-dependent metalloprotease sprtn"},
    {id: "protein with sprt-like domain at the n terminus"},
    {id: "spartan"},
    {id: "dna-dependent protein kinase catalytic subunit"},
    {id: "dna-directed dna polymerase"},
    {id: "dna-directed dna/rna polymerase mu"},
    {id: "dna-directed rna polymerase"},
    {id: "ec 2.7.7.6"},
    {id: "dna-directed rna polymerase i subunit rpa12"},
    {id: "dna-directed rna polymerase i subunit h"},
    {id: "zinc ribbon domain-containing protein 1"},
    {id: "dna-directed rna polymerase ii subunit e"},
    {id: "rpb5 homolog"},
    {id: "dna-directed rna polymerase ii subunit grinl1a"},
    {id: "dna-directed rna polymerase ii subunit m"},
    {id: "glutamate receptor-like protein 1a"},
    {id: "dna-directed rna polymerase ii subunit rpb7"},
    {id: "rna polymerase ii subunit b7"},
    {id: "dna-directed rna polymerase ii subunit g"},
    {id: "dna-directed rna polymerase iii subunit rpc3"},
    {id: "rna polymerase iii subunit c3"},
    {id: "dna-directed rna polymerase iii subunit rpc6"},
    {id: "rna polymerase iii subunit c6"},
    {id: "dna-directed rna polymerase iii subunit rpc9"},
    {id: "rna polymerase iii subunit c9"},
    {id: "calcitonin gene-related peptide-receptor component protein"},
    {id: "cgrp-rcp"},
    {id: "cgrp-receptor component protein"},
    {id: "cgrprcp"},
    {id: "dna-directed rna polymerase subunit"},
    {id: "dna-directed rna polymerase subunit beta"},
    {id: "dna-directed rna polymerases i and iii subunit rpac2"},
    {id: "dna-directed rna polymerase i subunit d"},
    {id: "dna-directed rna polymerases i, ii, and iii subunit rpabc1"},
    {id: "rna polymerases i, ii, and iii subunit abc1"},
    {id: "dna-directed rna polymerases i, ii, and iii subunit rpabc2"},
    {id: "rna polymerases i, ii, and iii subunit abc2"},
    {id: "dna-directed rna polymerase ii subunit f"},
    {id: "rpb6 homolog"},
    {id: "domon domain-containing protein frrs1l"},
    {id: "ferric-chelate reductase 1-like protein"},
    {id: "dppy splice variant d"},
    {id: "dipeptidyl peptidase like 10"},
    {id: "da2-24"},
    {id: "glycosyltransferase 8 domain containing 1"},
    {id: "damage-control phosphatase armt1"},
    {id: "acidic residue methyltransferase 1"},
    {id: "protein-glutamate o-methyltransferase"},
    {id: "sugar phosphate phosphatase armt1"},
    {id: "deaminated glutathione amidase"},
    {id: "dgsh amidase"},
    {id: "ec 3.5.1.128"},
    {id: "nitrilase homolog 1"},
    {id: "death domain-associated protein 6"},
    {id: "daxx"},
    {id: "death effector domain-containing protein"},
    {id: "death effector domain-containing testicular molecule"},
    {id: "death-associated protein kinase 3"},
    {id: "dap kinase 3"},
    {id: "dap-like kinase"},
    {id: "dlk"},
    {id: "mypt1 kinase"},
    {id: "zip-kinase"},
    {id: "decapping and exoribonuclease protein"},
    {id: "dxo"},
    {id: "5'-3' exoribonuclease dxo"},
    {id: "dom-3 homolog z"},
    {id: "nad-capped rna hydrolase dxo"},
    {id: "denadding enzyme dxo"},
    {id: "decapping nuclease"},
    {id: "decorin"},
    {id: "bone proteoglycan ii"},
    {id: "dermatan sulfate proteoglycan-ii"},
    {id: "dspg"},
    {id: "pg-s2"},
    {id: "pg40"},
    {id: "dedicator of cytokinesis 2"},
    {id: "dock2 protein"},
    {id: "defensin alpha 24"},
    {id: "enteric defensin alpha 5"},
    {id: "defensin alpha-like protein 1"},
    {id: "defensin alpha-related sequence 1"},
    {id: "rattusin"},
    {id: "dehydrogenase/reductase"},
    {id: "sdr family"},
    {id: "dehydrogenase/reductase (sdr family) member 7-like 1"},
    {id: "down-regulated in nephrectomized rat kidney #3"},
    {id: "dehydrogenase/reductase sdr family member 4"},
    {id: "ec 1.1.1.300"},
    {id: "nadph-dependent carbonyl reductase"},
    {id: "nadph-dependent retinol dehydrogenase/reductase"},
    {id: "ndrd"},
    {id: "peroxisomal short-chain alcohol dehydrogenase"},
    {id: "pscd"},
    {id: "short chain dehydrogenase/reductase family 25c member 2"},
    {id: "protein sdr25c2"},
    {id: "dehydrogenase/reductase sdr family member 7b"},
    {id: "short-chain dehydrogenase/reductase family 32c member 1"},
    {id: "protein sdr32c1"},
    {id: "dehydrogenase/reductase sdr family member 7c"},
    {id: "sarcoplasmic reticulum protein of 35 kda"},
    {id: "protein srp-35"},
    {id: "short-chain dehydrogenase/reductase family 32c member 2"},
    {id: "protein sdr32c2"},
    {id: "dehydrogenase/reductase sdr family member 9"},
    {id: "ec 1.1.1.209"},
    {id: "ec 1.1.1.53"},
    {id: "3-alpha hydroxysteroid dehydrogenase"},
    {id: "retinol dehydrogenase"},
    {id: "short-chain dehydrogenase/reductase retsdr8"},
    {id: "deleted in malignant brain tumors 1 protein"},
    {id: "hensin"},
    {id: "delta"},
    {id: "14"},
    {id: "ec 1.3.1.70"},
    {id: "3-beta-hydroxysterol delta (14)-reductase"},
    {id: "c-14 sterol reductase"},
    {id: "integral nuclear envelope inner membrane protein"},
    {id: "lamin-b receptor"},
    {id: "sterol c14-reductase"},
    {id: "24"},
    {id: "ec 1.3.1.72"},
    {id: "24-dehydrocholesterol reductase"},
    {id: "3-beta-hydroxysterol delta-24-reductase"},
    {id: "3,5"},
    {id: "2,4"},
    {id: "ec 5.3.3.-"},
    {id: "delta-1-pyrroline-5-carboxylate dehydrogenase, mitochondrial"},
    {id: "ec 1.2.1.88"},
    {id: "aldehyde dehydrogenase family 4 member a1"},
    {id: "l-glutamate gamma-semialdehyde dehydrogenase"},
    {id: "delta-1-pyrroline-5-carboxylate synthase"},
    {id: "includes: glutamate 5-kinase (gk) (ec 2.7.2.11) (gamma-glutamyl kinase); gamma-glutamyl phosphate reductase (gpr) (ec 1.2.1.41) (glutamate-5-semialdehyde dehydrogenase) (glutamyl-gamma-semialdehyde dehydrogenase)"},
    {id: "delta-like protein 4"},
    {id: "drosophila delta homolog 4"},
    {id: "delta4"},
    {id: "delta-type opioid receptor"},
    {id: "d-or-1"},
    {id: "dor-1"},
    {id: "opioid receptor a"},
    {id: "dematin actin binding protein"},
    {id: "epb4.9 protein"},
    {id: "deoxyhypusine hydroxylase"},
    {id: "dohh"},
    {id: "ec 1.14.99.29"},
    {id: "deoxyhypusine dioxygenase"},
    {id: "deoxyhypusine monooxygenase"},
    {id: "deoxyhypusine synthase"},
    {id: "dhs"},
    {id: "ec 2.5.1.46"},
    {id: "deoxynucleotidyltransferase terminal-interacting protein 1"},
    {id: "terminal deoxynucleotidyltransferase-interacting factor 1"},
    {id: "deoxyribonuclease gamma"},
    {id: "dnase gamma"},
    {id: "ec 3.1.21.-"},
    {id: "dnasey"},
    {id: "deoxyribonuclease i-like 3"},
    {id: "dnase i-like 3"},
    {id: "deoxyribonuclease-1-like 1"},
    {id: "dnase x"},
    {id: "deoxyribonuclease i-like 1"},
    {id: "dnase i-like 1"},
    {id: "deoxyribonuclease-2-alpha"},
    {id: "ec 3.1.22.1"},
    {id: "acid dnase"},
    {id: "deoxyribonuclease ii alpha"},
    {id: "dnase ii alpha"},
    {id: "lysosomal dnase ii"},
    {id: "deoxythymidylate kinase"},
    {id: "dtymk protein"},
    {id: "deoxyuridine 5'-triphosphate nucleotidohydrolase"},
    {id: "dutpase"},
    {id: "ec 3.6.1.23"},
    {id: "ppar-interacting protein 4"},
    {id: "pip4"},
    {id: "dutp pyrophosphatase"},
    {id: "dermokine"},
    {id: "dmkn protein"},
    {id: "desmoplakin"},
    {id: "dp"},
    {id: "destrin"},
    {id: "actin-depolymerizing factor"},
    {id: "adf"},
    {id: "deubiquitinase desi2"},
    {id: "desumoylating isopeptidase 2"},
    {id: "desi-2"},
    {id: "pppde peptidase domain-containing protein 1"},
    {id: "protein fam152a"},
    {id: "deubiquitinating protein vcpip1"},
    {id: "valosin-containing protein p97/p47 complex-interacting protein 1"},
    {id: "valosin-containing protein p97/p47 complex-interacting protein p135"},
    {id: "vcp/p47 complex-interacting 135-kda protein"},
    {id: "di-n-acetylchitobiase"},
    {id: "diacylglycerol kinase"},
    {id: "dag kinase"},
    {id: "diacylglycerol kinase iota"},
    {id: "dag kinase iota"},
    {id: "diacylglycerol kinase theta"},
    {id: "dag kinase theta"},
    {id: "dgktheta"},
    {id: "diacylglycerol kinase zeta"},
    {id: "dag kinase zeta"},
    {id: "104 kda diacylglycerol kinase"},
    {id: "dgk-iv"},
    {id: "diglyceride kinase zeta"},
    {id: "dgk-zeta"},
    {id: "diacylglycerol lipase-alpha"},
    {id: "ec 3.1.1.116"},
    {id: "neural stem cell-derived dendrite regulator"},
    {id: "sn1-specific diacylglycerol lipase alpha"},
    {id: "dgl-alpha"},
    {id: "dicarboxylate carrier slc25a8"},
    {id: "mitochondrial uncoupling protein 2"},
    {id: "ucp 2"},
    {id: "solute carrier family 25 member 8"},
    {id: "differentially expressed in fdcp 8 homolog"},
    {id: "def-8"},
    {id: "dihydrolipoamide acetyltransferase component of pyruvate dehydrogenase complex"},
    {id: "dihydrolipoyl dehydrogenase"},
    {id: "ec 1.8.1.4"},
    {id: "dihydrolipoyllysine-residue acetyltransferase component of pyruvate dehydrogenase complex, mitochondrial"},
    {id: "70 kda mitochondrial autoantigen of primary biliary cirrhosis"},
    {id: "pbc"},
    {id: "pyruvate dehydrogenase complex component e2"},
    {id: "pdc-e2"},
    {id: "pdce2"},
    {id: "dihydrolipoyllysine-residue succinyltransferase component of 2-oxoglutarate dehydrogenase complex, mitochondrial"},
    {id: "ec 2.3.1.61"},
    {id: "e2k"},
    {id: "dihydroorotate dehydrogenase"},
    {id: "quinone"},
    {id: "dhodehase"},
    {id: "ec 1.3.5.2"},
    {id: "dihydropyrimidinase"},
    {id: "dhp"},
    {id: "dhpase"},
    {id: "ec 3.5.2.2"},
    {id: "dihydropyrimidine amidohydrolase"},
    {id: "hydantoinase"},
    {id: "dihydropyrimidinase-related protein 5"},
    {id: "drp-5"},
    {id: "unc33-like phosphoprotein 6"},
    {id: "ulip-6"},
    {id: "dihydropyrimidine dehydrogenase"},
    {id: "nadp(+)"},
    {id: "dhpdhase"},
    {id: "dpd"},
    {id: "ec 1.3.1.2"},
    {id: "dihydrothymine dehydrogenase"},
    {id: "dihydrouracil dehydrogenase"},
    {id: "dihydroxyacetone phosphate acyltransferase"},
    {id: "dap-at"},
    {id: "dhap-at"},
    {id: "ec 2.3.1.42"},
    {id: "acyl-coa:dihydroxyacetonephosphateacyltransferase"},
    {id: "glycerone-phosphate o-acyltransferase"},
    {id: "dimethyladenosine transferase 2, mitochondrial"},
    {id: "mitochondrial 12s rrna dimethylase 2"},
    {id: "mitochondrial transcription factor b2"},
    {id: "mttfb2"},
    {id: "s-adenosylmethionine-6-n', n'-adenosyl(rrna) dimethyltransferase 2"},
    {id: "dimethylaniline monooxygenase"},
    {id: "n-oxide-forming"},
    {id: "ec 1.14.13.148"},
    {id: "ec 1.14.13.32"},
    {id: "ec 1.14.13.8"},
    {id: "dimethylaniline oxidase 4"},
    {id: "hepatic flavin-containing monooxygenase 4"},
    {id: "fmo 4"},
    {id: "dipeptidase"},
    {id: "ec 3.4.13.19"},
    {id: "dipeptidase 1"},
    {id: "beta-lactamase"},
    {id: "ec 3.5.2.6"},
    {id: "microsomal dipeptidase"},
    {id: "renal dipeptidase"},
    {id: "dipeptidyl peptidase 2"},
    {id: "ec 3.4.14.2"},
    {id: "dipeptidyl aminopeptidase ii"},
    {id: "dipeptidyl peptidase 7"},
    {id: "dipeptidyl peptidase ii"},
    {id: "dpp ii"},
    {id: "quiescent cell proline dipeptidase"},
    {id: "dipeptidyl peptidase 3"},
    {id: "ec 3.4.14.4"},
    {id: "dipeptidyl aminopeptidase iii"},
    {id: "dipeptidyl arylamidase iii"},
    {id: "dipeptidyl peptidase iii"},
    {id: "dpp iii"},
    {id: "enkephalinase b"},
    {id: "diphosphoinositol polyphosphate phosphohydrolase 1"},
    {id: "dipp-1"},
    {id: "ec 3.6.1.52"},
    {id: "diadenosine 5',5'''-p1,p6-hexaphosphate hydrolase 1"},
    {id: "nucleoside diphosphate-linked moiety x motif 3"},
    {id: "nudix motif 3"},
    {id: "diphosphomevalonate decarboxylase"},
    {id: "ec 4.1.1.33"},
    {id: "mevalonate (diphospho)decarboxylase"},
    {id: "mevalonate pyrophosphate decarboxylase"},
    {id: "diphthine--ammonia ligase"},
    {id: "ec 6.3.1.14"},
    {id: "atp-binding domain-containing protein 4"},
    {id: "diphthamide synthase"},
    {id: "diphthamide synthetase"},
    {id: "protein dph6 homolog"},
    {id: "direct iap-binding protein with low pi"},
    {id: "second mitochondria-derived activator of caspase"},
    {id: "disabled homolog 2"},
    {id: "adaptor molecule disabled-2"},
    {id: "c9"},
    {id: "differentially expressed in ovarian carcinoma 2"},
    {id: "doc-2"},
    {id: "mitogen-responsive phosphoprotein"},
    {id: "dishevelled segment polarity protein 1"},
    {id: "dvl1 protein"},
    {id: "disintegrin and metalloproteinase domain-containing protein 10"},
    {id: "adam 10"},
    {id: "ec 3.4.24.81"},
    {id: "kuzbanian protein homolog"},
    {id: "mammalian disintegrin-metalloprotease"},
    {id: "cd antigen cd156c"},
    {id: "disintegrin and metalloproteinase domain-containing protein 15"},
    {id: "adam 15"},
    {id: "crii-7"},
    {id: "metalloprotease rgd disintegrin protein"},
    {id: "metalloproteinase-like, disintegrin-like, and cysteine-rich protein 15"},
    {id: "mdc-15"},
    {id: "metargidin"},
    {id: "disintegrin and metalloproteinase domain-containing protein 5"},
    {id: "transmembrane metalloproteinase-like, disintegrin-like, and cysteine-rich protein ii"},
    {id: "tmdc ii"},
    {id: "disks large homolog 2"},
    {id: "channel-associated protein of synapse-110"},
    {id: "chapsyn-110"},
    {id: "postsynaptic density protein psd-93"},
    {id: "disks large homolog 3"},
    {id: "psd-95/sap90-related protein 1"},
    {id: "synapse-associated protein 102"},
    {id: "sap-102"},
    {id: "sap102"},
    {id: "disks large-associated protein 1"},
    {id: "dap-1"},
    {id: "guanylate kinase-associated protein"},
    {id: "rgkap"},
    {id: "psd-95/sap90-binding protein 1"},
    {id: "sap90/psd-95-associated protein 1"},
    {id: "sapap1"},
    {id: "distal membrane-arm assembly complex protein 2"},
    {id: "atp synthase subunit s-like protein"},
    {id: "distal-less homeobox 4"},
    {id: "homeodomain transcription factor"},
    {id: "divergent protein kinase domain 1a"},
    {id: "fam69a protein"},
    {id: "divergent protein kinase domain 1b"},
    {id: "pancreatitis-induced protein 49"},
    {id: "pip49"},
    {id: "protein fam69b"},
    {id: "dnaj"},
    {id: "hsp40"},
    {id: "dnaj heat shock protein family (hsp40) member a4"},
    {id: "dnaj heat shock protein family (hsp40) member c4"},
    {id: "dnaj heat shock protein family (hsp40) member b13"},
    {id: "dnaj-like protein"},
    {id: "dnaj heat shock protein family"},
    {id: "loc689593 protein"},
    {id: "heat shock cognate 40"},
    {id: "dnaj homolog subfamily a member 1"},
    {id: "dnaj-like protein 1"},
    {id: "heat shock protein j2"},
    {id: "hsj-2"},
    {id: "dnaj homolog subfamily b member 11"},
    {id: "er-associated dnaj"},
    {id: "er-associated hsp40 co-chaperone"},
    {id: "endoplasmic reticulum dna j domain-containing protein 3"},
    {id: "er-resident protein erdj3"},
    {id: "erdj3"},
    {id: "erj3p"},
    {id: "liver regeneration-related protein lrrgt00084"},
    {id: "dnaj homolog subfamily b member 6"},
    {id: "hsp40 homolog"},
    {id: "mrj"},
    {id: "msj-1"},
    {id: "dnaj homolog subfamily c member 10"},
    {id: "ec 1.8.4.-"},
    {id: "dnaj homolog subfamily c member 12"},
    {id: "dnaj homolog subfamily c member 14"},
    {id: "dopamine receptor-interacting protein of 78 kda"},
    {id: "drip78"},
    {id: "dnaj homolog subfamily c member 16"},
    {id: "endoplasmic reticulum dna j domain-containing protein 8"},
    {id: "er-resident protein erdj8"},
    {id: "erdj8"},
    {id: "dnaj homolog subfamily c member 2"},
    {id: "zuotin-related factor 1"},
    {id: "dnaj homolog subfamily c member 27"},
    {id: "rab and dnaj domain-containing protein"},
    {id: "dnaj homolog subfamily c member 3"},
    {id: "interferon-induced, double-stranded rna-activated protein kinase inhibitor"},
    {id: "protein kinase inhibitor of 58 kda"},
    {id: "protein kinase inhibitor p58"},
    {id: "dnaj homolog subfamily c member 5"},
    {id: "cysteine string protein"},
    {id: "csp"},
    {id: "dnaj homolog subfamily c member 5b"},
    {id: "cysteine string protein beta"},
    {id: "csp-beta"},
    {id: "sterile alpha motif domain containing 13"},
    {id: "docking protein 3"},
    {id: "downstream of tyrosine kinase 3"},
    {id: "dol-p-man:man"},
    {id: "ec 2.4.1.258"},
    {id: "dol-p-man-dependent alpha(1-3)-mannosyltransferase"},
    {id: "dolichol-phosphate mannosyltransferase subunit 1"},
    {id: "ec 2.4.1.83"},
    {id: "dolichyl pyrophosphate man9glcnac2 alpha-1,3-glucosyltransferase"},
    {id: "ec 2.4.1.267"},
    {id: "asparagine-linked glycosylation protein 6 homolog"},
    {id: "dol-p-glc:man(9)glcnac(2)-pp-dol alpha-1,3-glucosyltransferase"},
    {id: "dolichyl-p-glc:man9glcnac2-pp-dolichyl glucosyltransferase"},
    {id: "dolichyl-diphosphooligosaccharide--protein glycosyltransferase 48 kda subunit"},
    {id: "oligosaccharyl transferase 48 kda subunit"},
    {id: "dolichyl-diphosphooligosaccharide--protein glycosyltransferase subunit 2"},
    {id: "dolichyl-diphosphooligosaccharide--protein glycosyltransferase 63 kda subunit"},
    {id: "ribophorin ii"},
    {id: "rpn-ii"},
    {id: "ribophorin-2"},
    {id: "dolichyl-diphosphooligosaccharide--protein glycosyltransferase subunit dad1"},
    {id: "oligosaccharyl transferase subunit dad1"},
    {id: "defender against cell death 1"},
    {id: "dad-1"},
    {id: "dolichyl-phosphate-mannose--protein mannosyltransferase"},
    {id: "ec 2.4.1.109"},
    {id: "dolichyldiphosphatase"},
    {id: "ec 3.6.1.43"},
    {id: "double c2-like domain-containing protein alpha"},
    {id: "doc2-alpha"},
    {id: "double-stranded rna-binding protein staufen homolog 2"},
    {id: "r-staufen protein"},
    {id: "dr1-associated corepressor"},
    {id: "dr1-associated protein 1"},
    {id: "negative cofactor 2-alpha"},
    {id: "nc2-alpha"},
    {id: "draxin"},
    {id: "dorsal inhibitory axon guidance protein"},
    {id: "dorsal repulsive axon guidance protein"},
    {id: "drebrin"},
    {id: "developmentally-regulated brain protein"},
    {id: "drebrin-like protein"},
    {id: "actin-binding protein 1"},
    {id: "abp1"},
    {id: "sh3 domain-containing protein 7"},
    {id: "dual 3',5'-cyclic-amp and -gmp phosphodiesterase 11a"},
    {id: "ec 3.1.4.53"},
    {id: "camp and cgmp phosphodiesterase 11a"},
    {id: "dual endothelin-1/angiotensin ii receptor"},
    {id: "dear"},
    {id: "dual endothelin-1/vegfsp receptor"},
    {id: "despr protein"},
    {id: "fbxw7 antisense rna 1 homolog"},
    {id: "dual specificity calcium/calmodulin-dependent 3',5'-cyclic nucleotide phosphodiesterase 1b"},
    {id: "cam-pde 1b"},
    {id: "ec 3.1.4.17"},
    {id: "63 kda cam-pde"},
    {id: "dual specificity mitogen-activated protein kinase kinase 4"},
    {id: "mitogen activated protein kinase kinase 4"},
    {id: "dual specificity mitogen-activated protein kinase kinase 5"},
    {id: "map kinase kinase 5"},
    {id: "mapkk 5"},
    {id: "ec 2.7.12.2"},
    {id: "mapk/erk kinase 5"},
    {id: "mek 5"},
    {id: "dual specificity mitogen-activated protein kinase kinase 7"},
    {id: "map kinase kinase 7"},
    {id: "mapkk 7"},
    {id: "jnk-activating kinase 2"},
    {id: "mapk/erk kinase 7"},
    {id: "mek 7"},
    {id: "c-jun n-terminal kinase kinase 2"},
    {id: "jnk kinase 2"},
    {id: "jnkk 2"},
    {id: "dual specificity phosphatase 14"},
    {id: "dual specificity phosphate 14"},
    {id: "dual specificity phosphatase 29"},
    {id: "dual specificity phosphatase dupd1"},
    {id: "ec 3.1.3.16, ec 3.1.3.48"},
    {id: "dual specificity protein phosphatase"},
    {id: "dual specificity protein phosphatase 12"},
    {id: "glucokinase-associated dual specificity phosphatase"},
    {id: "gkap"},
    {id: "dual specificity protein phosphatase 15"},
    {id: "dual specificity protein phosphatase 26"},
    {id: "dual specificity protein phosphatase 6"},
    {id: "mitogen-activated protein kinase phosphatase 3"},
    {id: "map kinase phosphatase 3"},
    {id: "mkp-3"},
    {id: "dual specificity protein phosphatase 7"},
    {id: "dual specificity protein phosphatase mkp-x"},
    {id: "dual specificity testis-specific protein kinase 1"},
    {id: "ec 2.7.12.1"},
    {id: "testicular protein kinase 1"},
    {id: "dual specificity testis-specific protein kinase 2"},
    {id: "testicular protein kinase 2"},
    {id: "dual specificity tyrosine-phosphorylation-regulated kinase 3"},
    {id: "dynactin subunit 4"},
    {id: "dynactin subunit p62"},
    {id: "dynamin-1"},
    {id: "ec 3.6.5.5"},
    {id: "b-dynamin"},
    {id: "d100"},
    {id: "dynamin, brain"},
    {id: "dynamin-1-like protein"},
    {id: "dynamin-like protein"},
    {id: "dynamin-3"},
    {id: "dynamin, testicular"},
    {id: "t-dynamin"},
    {id: "dynamin-binding protein"},
    {id: "scaffold protein tuba"},
    {id: "dynamin-like 120 kda protein, mitochondrial"},
    {id: "optic atrophy protein 1 homolog"},
    {id: "cleaved into: dynamin-like 120 kda protein, form s1"},
    {id: "dynein axonemal assembly factor 1"},
    {id: "leucine-rich repeat-containing protein 50"},
    {id: "dynein axonemal assembly factor 4"},
    {id: "dyslexia susceptibility 1 candidate gene 1 protein homolog"},
    {id: "dynein axonemal assembly factor 8"},
    {id: "dynein axonemal-associated protein 1"},
    {id: "uncharacterized protein c16orf71 homolog"},
    {id: "dynein axonemal heavy chain 1"},
    {id: "axonemal beta dynein heavy chain 1"},
    {id: "ciliary dynein heavy chain 1"},
    {id: "dynein axonemal intermediate chain 1"},
    {id: "axonemal dynein intermediate chain 1"},
    {id: "dynein axonemal intermediate chain 2"},
    {id: "axonemal dynein intermediate chain 2"},
    {id: "dynein axonemal light chain 1"},
    {id: "lc1"},
    {id: "dynein cytoplasmic 1 intermediate chain 1"},
    {id: "dynein cytoplasmic 1 intermediate chain 1a"},
    {id: "dynein light chain 1, cytoplasmic"},
    {id: "dlc8"},
    {id: "dynein light chain lc8-type 1"},
    {id: "protein inhibitor of neuronal nitric oxide synthase"},
    {id: "pin"},
    {id: "dynein light chain 2, cytoplasmic"},
    {id: "dynein light chain lc8-type 2"},
    {id: "dynein regulatory complex protein 1"},
    {id: "coiled-coil domain-containing protein 164"},
    {id: "dynein regulatory complex protein 10"},
    {id: "iq domain-containing protein d"},
    {id: "dynein regulatory complex protein 9"},
    {id: "iq domain-containing protein g"},
    {id: "dynein regulatory complex subunit 2"},
    {id: "coiled-coil domain-containing protein 65"},
    {id: "dynein regulatory complex subunit 3"},
    {id: "leucine-rich repeat-containing protein 48"},
    {id: "dynein regulatory complex subunit 4"},
    {id: "growth arrest-specific protein 11"},
    {id: "gas-11"},
    {id: "growth arrest-specific protein 8"},
    {id: "gas-8"},
    {id: "dynein regulatory complex subunit 7"},
    {id: "coiled-coil domain-containing protein 135"},
    {id: "coiled-coil domain-containing protein lobo homolog"},
    {id: "dysbindin"},
    {id: "biogenesis of lysosome-related organelles complex 1 subunit 8"},
    {id: "bloc-1 subunit 8"},
    {id: "dysbindin-1"},
    {id: "dystrobrevin-binding protein 1"},
    {id: "dystroglycan 1"},
    {id: "dystroglycan"},
    {id: "dystrophin-associated glycoprotein 1"},
    {id: "dystrophin-related protein 2"},
    {id: "drp-2"},
    {id: "e1 ubiquitin-activating enzyme"},
    {id: "ec 6.2.1.45"},
    {id: "ubiquitin-activating enzyme e1"},
    {id: "e2 ubiquitin-conjugating enzyme"},
    {id: "ec 2.3.2.23"},
    {id: "e3 sumo-protein ligase egr2"},
    {id: "ec 2.3.2.-"},
    {id: "e3 sumo-protein transferase erg2"},
    {id: "early growth response protein 2"},
    {id: "egr-2"},
    {id: "zinc finger protein krox-20"},
    {id: "e3 sumo-protein ligase nse2"},
    {id: "e3 sumo-protein transferase nse2"},
    {id: "non-structural maintenance of chromosomes element 2 homolog"},
    {id: "mms21 homolog"},
    {id: "non-smc element 2 homolog"},
    {id: "e3 ufm1-protein ligase 1"},
    {id: "e3 ufm1-protein transferase 1"},
    {id: "multiple alpha-helix protein located at er"},
    {id: "regulator of c53/lzap and ddrgk1"},
    {id: "e3 ubiquitin ligase trim40"},
    {id: "probable e3 nedd8-protein ligase"},
    {id: "ring finger protein 35"},
    {id: "e3 ubiquitin protein ligase"},
    {id: "e3 ubiquitin-protein ligase"},
    {id: "ec 2.3.2.26"},
    {id: "ring-type e3 ubiquitin transferase"},
    {id: "e3 ubiquitin-protein ligase cbl"},
    {id: "e3 ubiquitin-protein ligase cbl-c"},
    {id: "ring-type e3 ubiquitin transferase cbl-c"},
    {id: "e3 ubiquitin-protein ligase chfr"},
    {id: "checkpoint with forkhead and ring finger domains protein"},
    {id: "ring-type e3 ubiquitin transferase chfr"},
    {id: "e3 ubiquitin-protein ligase e3d"},
    {id: "hect-type e3 ubiquitin transferase e3d"},
    {id: "ubch10-binding protein with a hect-like domain"},
    {id: "ubiquitin-conjugating enzyme e2c-binding protein"},
    {id: "e3 ubiquitin-protein ligase hace1"},
    {id: "hect domain and ankyrin repeat-containing e3 ubiquitin-protein ligase 1"},
    {id: "hect-type e3 ubiquitin transferase hace1"},
    {id: "e3 ubiquitin-protein ligase huwe1"},
    {id: "hect, uba and wwe domain-containing protein 1"},
    {id: "hect-type e3 ubiquitin transferase huwe1"},
    {id: "upstream regulatory element-binding protein 1"},
    {id: "ure-b1"},
    {id: "ure-binding protein 1"},
    {id: "e3 ubiquitin-protein ligase kcmf1"},
    {id: "ring-type e3 ubiquitin transferase kcmf1"},
    {id: "e3 ubiquitin-protein ligase marchf2"},
    {id: "membrane-associated ring finger protein 2"},
    {id: "membrane-associated ring-ch protein ii"},
    {id: "march-ii"},
    {id: "ring finger protein 172"},
    {id: "rnf172"},
    {id: "ring-type e3 ubiquitin transferase marchf2"},
    {id: "e3 ubiquitin-protein ligase marchf3"},
    {id: "membrane-associated ring finger protein 3"},
    {id: "membrane-associated ring-ch protein iii"},
    {id: "march-iii"},
    {id: "ring-type e3 ubiquitin transferase marchf3"},
    {id: "e3 ubiquitin-protein ligase marchf7"},
    {id: "membrane-associated ring finger protein 7"},
    {id: "membrane-associated ring-ch protein vii"},
    {id: "march-vii"},
    {id: "ring-type e3 ubiquitin transferase marchf7"},
    {id: "e3 ubiquitin-protein ligase mylip"},
    {id: "inducible degrader of the ldl-receptor"},
    {id: "idol"},
    {id: "myosin regulatory light chain interacting protein"},
    {id: "mir"},
    {id: "ring-type e3 ubiquitin transferase mylip"},
    {id: "e3 ubiquitin-protein ligase mdm2"},
    {id: "double minute 2 protein"},
    {id: "ring-type e3 ubiquitin transferase mdm2"},
    {id: "p53-binding protein mdm2"},
    {id: "e3 ubiquitin-protein ligase midline-1"},
    {id: "ring finger protein midline-1"},
    {id: "ring-type e3 ubiquitin transferase midline-1"},
    {id: "tripartite motif-containing protein 18"},
    {id: "e3 ubiquitin-protein ligase neurl3"},
    {id: "lung-inducible neuralized-related c3ch4 ring domain protein"},
    {id: "neuralized-like protein 3"},
    {id: "ring-type e3 ubiquitin transferase neurl3"},
    {id: "e3 ubiquitin-protein ligase nhlrc1"},
    {id: "malin"},
    {id: "nhl repeat-containing protein 1"},
    {id: "ring-type e3 ubiquitin transferase nhlrc1"},
    {id: "e3 ubiquitin-protein ligase nrdp1"},
    {id: "ring finger protein 41"},
    {id: "ring-type e3 ubiquitin transferase nrdp1"},
    {id: "e3 ubiquitin-protein ligase praja-2"},
    {id: "praja2"},
    {id: "ring-type e3 ubiquitin transferase praja-2"},
    {id: "e3 ubiquitin-protein ligase ring2"},
    {id: "ring finger protein 1b"},
    {id: "ring1b"},
    {id: "ring finger protein 2"},
    {id: "ring-type e3 ubiquitin transferase ring2"},
    {id: "e3 ubiquitin-protein ligase rnf"},
    {id: "ring finger protein"},
    {id: "e3 ubiquitin-protein ligase rnf10"},
    {id: "ring finger protein 10"},
    {id: "e3 ubiquitin-protein ligase rnf114"},
    {id: "ring finger protein 114"},
    {id: "ring-type e3 ubiquitin transferase rnf114"},
    {id: "zinc finger protein 313"},
    {id: "e3 ubiquitin-protein ligase rnf13"},
    {id: "ring finger protein 13"},
    {id: "e3 ubiquitin-protein ligase rnf135"},
    {id: "ring finger protein 135"},
    {id: "ring-type e3 ubiquitin transferase rnf135"},
    {id: "e3 ubiquitin-protein ligase rnf138"},
    {id: "ring finger protein 138"},
    {id: "ring-type e3 ubiquitin transferase rnf138"},
    {id: "e3 ubiquitin-protein ligase rnf146"},
    {id: "iduna"},
    {id: "ring finger protein 146"},
    {id: "ring-type e3 ubiquitin transferase rnf146"},
    {id: "e3 ubiquitin-protein ligase rnf152"},
    {id: "ring finger protein 152"},
    {id: "ring-type e3 ubiquitin transferase rnf152"},
    {id: "e3 ubiquitin-protein ligase rnf166"},
    {id: "ring finger protein 166"},
    {id: "ring-type e3 ubiquitin transferase rnf166"},
    {id: "e3 ubiquitin-protein ligase rnf167"},
    {id: "ring finger protein 167"},
    {id: "e3 ubiquitin-protein ligase rnf168"},
    {id: "ring finger protein 168"},
    {id: "ring-type e3 ubiquitin transferase rnf168"},
    {id: "e3 ubiquitin-protein ligase rnf181"},
    {id: "ring finger protein 181"},
    {id: "e3 ubiquitin-protein ligase rnf182"},
    {id: "ring finger protein 182"},
    {id: "ring-type e3 ubiquitin transferase rnf182"},
    {id: "e3 ubiquitin-protein ligase rnf185"},
    {id: "ring finger protein 185"},
    {id: "e3 ubiquitin-protein ligase rnf34"},
    {id: "ring finger protein 34"},
    {id: "ring finger protein momo"},
    {id: "ring-type e3 ubiquitin transferase rnf34"},
    {id: "e3 ubiquitin-protein ligase rnf4"},
    {id: "ring finger protein 4"},
    {id: "small nuclear ring finger protein"},
    {id: "protein snurf"},
    {id: "e3 ubiquitin-protein ligase rnf8"},
    {id: "ring finger protein 8"},
    {id: "ring-type e3 ubiquitin transferase rnf8"},
    {id: "e3 ubiquitin-protein ligase sh3rf1"},
    {id: "plenty of sh3s"},
    {id: "protein posh"},
    {id: "ring-type e3 ubiquitin transferase sh3rf1"},
    {id: "sh3 domain-containing ring finger protein 1"},
    {id: "sh3 multiple domains protein 2"},
    {id: "e3 ubiquitin-protein ligase sh3rf2"},
    {id: "posh-eliminating ring protein"},
    {id: "protein phosphatase 1 regulatory subunit 39"},
    {id: "ring finger protein 158"},
    {id: "ring-type e3 ubiquitin transferase sh3rf2"},
    {id: "sh3 domain-containing ring finger protein 2"},
    {id: "e3 ubiquitin-protein ligase siah1"},
    {id: "ring-type e3 ubiquitin transferase siah1"},
    {id: "seven in absentia homolog 1"},
    {id: "siah-1"},
    {id: "siah-1a"},
    {id: "e3 ubiquitin-protein ligase siah2"},
    {id: "ring-type e3 ubiquitin transferase siah2"},
    {id: "seven in absentia homolog 2"},
    {id: "siah-2"},
    {id: "e3 ubiquitin-protein ligase trim11"},
    {id: "tripartite motif-containing protein 11"},
    {id: "e3 ubiquitin-protein ligase trim13"},
    {id: "putative tumor suppressor rfp2"},
    {id: "ring-type e3 ubiquitin transferase trim13"},
    {id: "ret finger protein 2"},
    {id: "tripartite motif-containing protein 13"},
    {id: "e3 ubiquitin-protein ligase trim17"},
    {id: "ring-type e3 ubiquitin transferase trim17"},
    {id: "testis ring finger protein"},
    {id: "tripartite motif-containing protein 17"},
    {id: "e3 ubiquitin-protein ligase trim63"},
    {id: "muscle-specific ring finger protein 1"},
    {id: "murf-1"},
    {id: "murf1"},
    {id: "ring-type e3 ubiquitin transferase trim63"},
    {id: "tripartite motif-containing protein 63"},
    {id: "e3 ubiquitin-protein ligase trim69"},
    {id: "ring finger protein 36"},
    {id: "ring-type e3 ubiquitin transferase trim69"},
    {id: "tripartite motif-containing protein 69"},
    {id: "e3 ubiquitin-protein ligase trim71"},
    {id: "protein lin-41 homolog"},
    {id: "ring-type e3 ubiquitin transferase trim71"},
    {id: "tripartite motif-containing protein 71"},
    {id: "e3 ubiquitin-protein ligase trim9"},
    {id: "ring-type e3 ubiquitin transferase trim9"},
    {id: "snap-25-interacting ring finger protein"},
    {id: "tripartite motif-containing protein 9"},
    {id: "e3 ubiquitin-protein ligase uhrf"},
    {id: "ring-type e3 ubiquitin transferase uhrf"},
    {id: "ubiquitin-like phd and ring finger domain-containing protein"},
    {id: "ubiquitin-like-containing phd and ring finger domains protein"},
    {id: "e3 ubiquitin-protein ligase xiap"},
    {id: "baculoviral iap repeat-containing protein 4"},
    {id: "iap homolog a"},
    {id: "inhibitor of apoptosis protein 3"},
    {id: "iap-3"},
    {id: "riap-3"},
    {id: "riap3"},
    {id: "ring-type e3 ubiquitin transferase xiap"},
    {id: "x-linked inhibitor of apoptosis protein"},
    {id: "x-linked iap"},
    {id: "e3 ubiquitin-protein ligase listerin"},
    {id: "ring-type e3 ubiquitin transferase listerin"},
    {id: "e3 ubiquitin-protein ligase parkin"},
    {id: "ec 2.3.2.31"},
    {id: "parkin rbr e3 ubiquitin-protein ligase"},
    {id: "e3 ubiquitin-protein ligase pellino homolog"},
    {id: "e3 ubiquitin-protein ligase rififylin"},
    {id: "fyve-ring finger protein sakura"},
    {id: "ring finger and fyve-like domain-containing protein 1"},
    {id: "ring-type e3 ubiquitin transferase rififylin"},
    {id: "e74-like factor 4"},
    {id: "ets domain transcription factor"},
    {id: "earp and garp complex-interacting protein 1"},
    {id: "endosome-associated recycling protein-interacting protein"},
    {id: "golgi-associated retrograde protein-interacting protein"},
    {id: "tumor-suppressing stf cdna 1 protein"},
    {id: "tumor-suppressing subchromosomal transferable fragment candidate gene 1 protein"},
    {id: "eef1a lysine methyltransferase 1"},
    {id: "n(6)-adenine-specific dna methyltransferase 2"},
    {id: "protein-lysine n-methyltransferase n6amt2"},
    {id: "eef1a lysine methyltransferase 2"},
    {id: "methyltransferase-like protein 10"},
    {id: "protein-lysine n-methyltransferase mettl10"},
    {id: "ef hand domain family, member b"},
    {id: "efhb protein"},
    {id: "ef-hand calcium-binding domain-containing protein 4a"},
    {id: "calcium release-activated channel regulator 2b"},
    {id: "ef-hand domain-containing protein d2"},
    {id: "swiprosin-1"},
    {id: "egf containing fibulin extracellular matrix protein 1"},
    {id: "egf-containing fibulin-like extracellular matrix protein 1"},
    {id: "ekc/keops complex subunit tprkb"},
    {id: "prpk-binding protein"},
    {id: "tp53rk-binding protein"},
    {id: "elav-like protein 1"},
    {id: "hu-antigen r"},
    {id: "hur"},
    {id: "elav-like protein 4"},
    {id: "hu-antigen d"},
    {id: "hud"},
    {id: "paraneoplastic encephalomyelitis antigen hud"},
    {id: "ell-associated factor 2"},
    {id: "testosterone-regulated apoptosis inducer and tumor suppressor protein"},
    {id: "elmo domain-containing protein 3"},
    {id: "rna-binding motif and elmo domain-containing protein 1"},
    {id: "enah, actin regulator"},
    {id: "enabled homolog (drosophila)"},
    {id: "eps8-like 2"},
    {id: "eps8l2 protein"},
    {id: "er lumen protein-retaining receptor 1"},
    {id: "kdel endoplasmic reticulum protein retention receptor 1"},
    {id: "kdel receptor 1"},
    {id: "er membrane protein complex subunit 2"},
    {id: "tetratricopeptide repeat protein 35"},
    {id: "tpr repeat protein 35"},
    {id: "er membrane protein complex subunit 3"},
    {id: "transmembrane protein 111"},
    {id: "er membrane protein complex subunit 5"},
    {id: "membrane magnesium transporter 1"},
    {id: "transmembrane protein 32"},
    {id: "er membrane protein complex subunit 6"},
    {id: "transmembrane protein 93"},
    {id: "er membrane protein complex subunit 8"},
    {id: "neighbor of cox4"},
    {id: "er membrane protein complex subunit 9"},
    {id: "protein fam158a"},
    {id: "ercc excision repair 8, csa ubiquitin ligase complex subunit"},
    {id: "ercc8 protein"},
    {id: "ero1-like protein alpha"},
    {id: "ero1-l"},
    {id: "ero1-l-alpha"},
    {id: "endoplasmic reticulum oxidoreductase alpha"},
    {id: "endoplasmic reticulum oxidoreductin-1-like protein"},
    {id: "global ischemia-induced protein 11"},
    {id: "oxidoreductin-1-l-alpha"},
    {id: "esf1 homolog"},
    {id: "abt1-associated protein"},
    {id: "ets variant transcription factor 1"},
    {id: "etv1 protein"},
    {id: "ectonucleoside triphosphate diphosphohydrolase 6"},
    {id: "ntpdase 6"},
    {id: "ec 3.6.1.6"},
    {id: "cd39 antigen-like 2"},
    {id: "ectonucleotide pyrophosphatase/phosphodiesterase family member 2"},
    {id: "e-npp 2"},
    {id: "ec 3.1.4.39"},
    {id: "autotaxin"},
    {id: "extracellular lysophospholipase d"},
    {id: "lysopld"},
    {id: "ectonucleotide pyrophosphatase/phosphodiesterase family member 5"},
    {id: "e-npp 5"},
    {id: "npp-5"},
    {id: "eif4g2 protein"},
    {id: "eukaryotic translation initiation factor 4, gamma 2"},
    {id: "elastin"},
    {id: "tropoelastin"},
    {id: "electron transfer flavoprotein beta subunit lysine methyltransferase"},
    {id: "etfb lysine methyltransferase"},
    {id: "etfb-kmt"},
    {id: "protein n-lysine methyltransferase mettl20"},
    {id: "electron transfer flavoprotein subunit alpha"},
    {id: "alpha-etf"},
    {id: "electron transfer flavoprotein subunit beta"},
    {id: "beta-etf"},
    {id: "electron transfer flavoprotein-ubiquinone oxidoreductase"},
    {id: "etf-qo"},
    {id: "ec 1.5.5.1"},
    {id: "electroneutral sodium bicarbonate exchanger 1"},
    {id: "electroneutral na+-driven cl-hco3 exchanger"},
    {id: "solute carrier family 4 member 8"},
    {id: "k-nbc3"},
    {id: "elongation factor 1-alpha 1"},
    {id: "ef-1-alpha-1"},
    {id: "elongation factor tu"},
    {id: "ef-tu"},
    {id: "eukaryotic elongation factor 1 a-1"},
    {id: "eef1a-1"},
    {id: "elongation factor 1-gamma"},
    {id: "ef-1-gamma"},
    {id: "eef-1b gamma"},
    {id: "elongation factor 2"},
    {id: "ef-2"},
    {id: "elongation factor g, mitochondrial"},
    {id: "ef-gmt"},
    {id: "elongation factor g 1, mitochondrial"},
    {id: "mef-g 1"},
    {id: "elongation factor g1"},
    {id: "elongation factor ts, mitochondrial"},
    {id: "ef-ts"},
    {id: "ef-tsmt"},
    {id: "2a3-2"},
    {id: "elongation of very long chain fatty acids protein"},
    {id: "ec 2.3.1.199"},
    {id: "very-long-chain 3-oxoacyl-coa synthase"},
    {id: "elongation of very long chain fatty acids protein 1"},
    {id: "3-keto acyl-coa synthase elovl1"},
    {id: "elovl fatty acid elongase 1"},
    {id: "elovl fa elongase 1"},
    {id: "very long chain 3-ketoacyl-coa synthase 1"},
    {id: "very long chain 3-oxoacyl-coa synthase 1"},
    {id: "elongation of very long chain fatty acids protein 2"},
    {id: "3-keto acyl-coa synthase elovl2"},
    {id: "elovl fatty acid elongase 2"},
    {id: "elovl fa elongase 2"},
    {id: "very long chain 3-ketoacyl-coa synthase 2"},
    {id: "very long chain 3-oxoacyl-coa synthase 2"},
    {id: "elongation of very long chain fatty acids protein 3"},
    {id: "3-keto acyl-coa synthase elovl3"},
    {id: "elovl fatty acid elongase 3"},
    {id: "elovl fa elongase 3"},
    {id: "very long chain 3-ketoacyl-coa synthase 3"},
    {id: "very long chain 3-oxoacyl-coa synthase 3"},
    {id: "elongation of very long chain fatty acids protein 4"},
    {id: "3-keto acyl-coa synthase elovl4"},
    {id: "elovl fatty acid elongase 4"},
    {id: "elovl fa elongase 4"},
    {id: "very long chain 3-ketoacyl-coa synthase 4"},
    {id: "very long chain 3-oxoacyl-coa synthase 4"},
    {id: "elongation of very long chain fatty acids protein 5"},
    {id: "3-keto acyl-coa synthase elovl5"},
    {id: "elovl fatty acid elongase 5"},
    {id: "elovl fa elongase 5"},
    {id: "very long chain 3-ketoacyl-coa synthase 5"},
    {id: "very long chain 3-oxoacyl-coa synthase 5"},
    {id: "fatty acid elongase 1"},
    {id: "relo1"},
    {id: "elongation of very long chain fatty acids protein 6"},
    {id: "3-keto acyl-coa synthase elovl6"},
    {id: "elovl fatty acid elongase 6"},
    {id: "elovl fa elongase 6"},
    {id: "fatty acid elongase 2"},
    {id: "relo2"},
    {id: "fatty acyl-coa elongase"},
    {id: "long-chain fatty-acyl elongase"},
    {id: "very long chain 3-ketoacyl-coa synthase 6"},
    {id: "very long chain 3-oxoacyl-coa synthase 6"},
    {id: "elongation of very long chain fatty acids protein 7"},
    {id: "3-keto acyl-coa synthase elovl7"},
    {id: "elovl fatty acid elongase 7"},
    {id: "elovl fa elongase 7"},
    {id: "very long chain 3-ketoacyl-coa synthase 7"},
    {id: "very long chain 3-oxoacyl-coa synthase 7"},
    {id: "elongator complex protein 2"},
    {id: "elp2"},
    {id: "shinc-2"},
    {id: "stat3-interacting protein 1"},
    {id: "stip1"},
    {id: "elongator complex protein 3"},
    {id: "elongin-a3 member d-like"},
    {id: "similar to rna polymerase ii transcription factor siii subunit a2"},
    {id: "elongin-c"},
    {id: "eloc"},
    {id: "elongin 15 kda subunit"},
    {id: "rna polymerase ii transcription factor siii subunit c"},
    {id: "siii p15"},
    {id: "stromal membrane-associated protein smap1b homolog"},
    {id: "transcription elongation factor b polypeptide 1"},
    {id: "endogenous retrovirus group frd member 1, envelope"},
    {id: "envelope glycoprotein syncytin-a"},
    {id: "endonuclease"},
    {id: "ec 3.1.30.-"},
    {id: "endonuclease iii-like protein 1"},
    {id: "bifunctional dna n-glycosylase/dna-(apurinic or apyrimidinic site) lyase"},
    {id: "dna glycosylase/ap lyase"},
    {id: "endonuclease v"},
    {id: "loc498029 protein"},
    {id: "endophilin-a2"},
    {id: "endophilin-2"},
    {id: "sh3 domain protein 2b"},
    {id: "sh3 domain-containing grb2-like protein 1"},
    {id: "sh3p8"},
    {id: "endophilin-a3"},
    {id: "endophilin-3"},
    {id: "sh3 domain protein 2c"},
    {id: "sh3 domain-containing grb2-like protein 3"},
    {id: "sh3p13"},
    {id: "endophilin-b1"},
    {id: "sh3 domain-containing grb2-like protein b1"},
    {id: "endoplasmic reticulum lectin"},
    {id: "protein os-9"},
    {id: "endoplasmic reticulum membrane-associated rna degradation protein"},
    {id: "er membrane-associated rna degradation protein"},
    {id: "endoplasmic reticulum protein sc65"},
    {id: "leprecan-like protein 4"},
    {id: "prolyl 3-hydroxylase family member 4"},
    {id: "synaptonemal complex protein sc65"},
    {id: "endoplasmic reticulum resident protein 29"},
    {id: "erp29"},
    {id: "endoplasmic reticulum resident protein 31"},
    {id: "erp31"},
    {id: "endoplasmin"},
    {id: "94 kda glucose-regulated protein"},
    {id: "grp-94"},
    {id: "heat shock protein 90 kda beta member 1"},
    {id: "endoribonuclease dicer"},
    {id: "ec 3.1.26.3"},
    {id: "endoribonuclease lactb2"},
    {id: "beta-lactamase-like protein 2"},
    {id: "ec 3.1.27.-"},
    {id: "endoribonuclease zc3h12a"},
    {id: "monocyte chemotactic protein-induced protein 1"},
    {id: "mcp-induced protein 1"},
    {id: "mcpip-1"},
    {id: "regnase-1"},
    {id: "reg1"},
    {id: "zinc finger ccch domain-containing protein 12a"},
    {id: "endosomal/lysosomal proton channel tmem175"},
    {id: "transmembrane protein 175"},
    {id: "endothelial cell-specific molecule 1"},
    {id: "esm-1"},
    {id: "pg25"},
    {id: "endothelial lipase"},
    {id: "ec 3.1.1.3"},
    {id: "endothelial-derived lipase"},
    {id: "edl"},
    {id: "phospholipase a1"},
    {id: "ec 3.1.1.32"},
    {id: "endothelial protein c receptor"},
    {id: "activated protein c receptor"},
    {id: "apc receptor"},
    {id: "endothelial cell protein c receptor"},
    {id: "cd antigen cd201"},
    {id: "endothelin receptor type b"},
    {id: "et-b"},
    {id: "et-br"},
    {id: "endothelin receptor non-selective type"},
    {id: "endothelin-1"},
    {id: "et-1"},
    {id: "preproendothelin-1"},
    {id: "ppet1"},
    {id: "cleaved into: big endothelin-1"},
    {id: "enhancer of mrna-decapping protein 3"},
    {id: "yjef domain-containing protein 1"},
    {id: "enolase 4"},
    {id: "ec 4.2.1.11"},
    {id: "2-phospho-d-glycerate hydro-lyase"},
    {id: "enolase-phosphatase e1"},
    {id: "ec 3.1.3.77"},
    {id: "2,3-diketo-5-methylthio-1-phosphopentane phosphatase"},
    {id: "masa homolog"},
    {id: "enoyl-coa hydratase, mitochondrial"},
    {id: "mech"},
    {id: "mech1"},
    {id: "ec 4.2.1.17"},
    {id: "ec 5.3.3.8"},
    {id: "enoyl-coa hydratase 1"},
    {id: "echs1"},
    {id: "short-chain enoyl-coa hydratase"},
    {id: "sceh"},
    {id: "eosinophil cationic protein"},
    {id: "ecp"},
    {id: "eosinophil secondary granule ribonuclease 11"},
    {id: "ear-11"},
    {id: "ribonuclease 3"},
    {id: "rnase 3"},
    {id: "eotaxin"},
    {id: "c-c motif chemokine 11"},
    {id: "eosinophil chemotactic protein"},
    {id: "small-inducible cytokine a11"},
    {id: "ephrin type-b receptor 6"},
    {id: "tyrosine-protein kinase-defective receptor eph-6"},
    {id: "epidermal growth factor-like protein 8"},
    {id: "egf-like protein 8"},
    {id: "epididymal-specific lipocalin elp16"},
    {id: "lipocalin 6"},
    {id: "epithelial cell adhesion molecule"},
    {id: "ep-cam"},
    {id: "epithelial glycoprotein 314"},
    {id: "egp314"},
    {id: "protein d5.7a"},
    {id: "tumor-associated calcium signal transducer 1"},
    {id: "cd antigen cd326"},
    {id: "epithelial membrane protein 2"},
    {id: "emp-2"},
    {id: "epithelial membrane protein 3"},
    {id: "emp-3"},
    {id: "eppin"},
    {id: "epididymal protease inhibitor"},
    {id: "serine protease inhibitor-like with kunitz and wap domains 1"},
    {id: "epsilon 2 globin"},
    {id: "globin b3"},
    {id: "hemoglobin, epsilon 2"},
    {id: "epsin-3"},
    {id: "eps-15-interacting protein 3"},
    {id: "equilibrative nucleoside transporter 1"},
    {id: "rent1"},
    {id: "equilibrative nitrobenzylmercaptopurine riboside-sensitive nucleoside transporter"},
    {id: "equilibrative nbmpr-sensitive nucleoside transporter"},
    {id: "nucleoside transporter, es-type"},
    {id: "solute carrier family 29 member 1"},
    {id: "equilibrative nucleoside transporter 3"},
    {id: "rent3"},
    {id: "solute carrier family 29 member 3"},
    {id: "erlin"},
    {id: "spfh domain-containing protein"},
    {id: "endoplasmic reticulum lipid raft-associated protein"},
    {id: "stomatin-prohibitin-flotillin-hflc/k domain-containing protein"},
    {id: "erlin-2"},
    {id: "endoplasmic reticulum lipid raft-associated protein 2"},
    {id: "stomatin-prohibitin-flotillin-hflc/k domain-containing protein 2"},
    {id: "spfh domain-containing protein 2"},
    {id: "ermin"},
    {id: "juxtanodin"},
    {id: "jn"},
    {id: "erythroblast membrane associated protein"},
    {id: "scianna blood group"},
    {id: "erythroferrone"},
    {id: "complement c1q tumor necrosis factor-related protein 15"},
    {id: "myonectin"},
    {id: "erythroid transcription factor"},
    {id: "eryf1"},
    {id: "gata-binding factor 1"},
    {id: "gata-1"},
    {id: "gf-1"},
    {id: "nf-e1 dna-binding protein"},
    {id: "erythropoietin receptor"},
    {id: "epo-r"},
    {id: "esco1 protein"},
    {id: "establishment of sister chromatid cohesion n-acetyltransferase 1"},
    {id: "espin"},
    {id: "ectoplasmic specialization protein"},
    {id: "estradiol 17-beta-dehydrogenase 1"},
    {id: "17-beta-hydroxysteroid dehydrogenase type 1"},
    {id: "17-beta-hsd 1"},
    {id: "estradiol 17-beta-dehydrogenase 11"},
    {id: "17-beta-hydroxysteroid dehydrogenase 11"},
    {id: "17-beta-hsd 11"},
    {id: "17bhsd11"},
    {id: "17betahsd11"},
    {id: "17-beta-hydroxysteroid dehydrogenase xi"},
    {id: "17-beta-hsd xi"},
    {id: "17betahsdxi"},
    {id: "dehydrogenase/reductase sdr family member 8"},
    {id: "estrogen receptor beta"},
    {id: "nuclear receptor subfamily 3 group a member 2"},
    {id: "estrogen-related receptor gamma"},
    {id: "estrogen receptor-related protein 3"},
    {id: "nuclear receptor subfamily 3 group b member 3"},
    {id: "eukaryotic initiation factor 4a-iii"},
    {id: "eif-4a-iii"},
    {id: "eif4a-iii"},
    {id: "atp-dependent rna helicase ddx48"},
    {id: "atp-dependent rna helicase eif4a-3"},
    {id: "dead box protein 48"},
    {id: "eukaryotic translation initiation factor 4a isoform 3"},
    {id: "cleaved into: eukaryotic initiation factor 4a-iii, n-terminally processed"},
    {id: "eukaryotic peptide chain release factor subunit 1"},
    {id: "eukaryotic release factor 1"},
    {id: "erf1"},
    {id: "eukaryotic translation initiation factor 1a"},
    {id: "eif-1a"},
    {id: "eukaryotic translation initiation factor 4c"},
    {id: "eif-4c"},
    {id: "liver regeneration-related protein lrrg048"},
    {id: "eukaryotic translation initiation factor 2 subunit 1"},
    {id: "eukaryotic translation initiation factor 2 subunit alpha"},
    {id: "eif-2-alpha"},
    {id: "eif-2a"},
    {id: "eif-2alpha"},
    {id: "eif2-alpha"},
    {id: "eukaryotic translation initiation factor 2 subunit 3, x-linked"},
    {id: "ec 3.6.5.3"},
    {id: "eukaryotic translation initiation factor 2 subunit gamma, x-linked"},
    {id: "eif-2-gamma"},
    {id: "eif-2-gamma x"},
    {id: "pp42"},
    {id: "eukaryotic translation initiation factor 2 subunit 3, y-linked"},
    {id: "eukaryotic translation initiation factor 2 subunit gamma, y-linked"},
    {id: "eif-2-gamma y"},
    {id: "eukaryotic translation initiation factor 2-alpha kinase 1"},
    {id: "heme-controlled repressor"},
    {id: "hcr"},
    {id: "heme-regulated eukaryotic initiation factor eif-2-alpha kinase"},
    {id: "heme-regulated inhibitor"},
    {id: "hemin-sensitive initiation factor 2-alpha kinase"},
    {id: "eukaryotic translation initiation factor 2-alpha kinase 3"},
    {id: "prkr-like endoplasmic reticulum kinase"},
    {id: "pancreatic eif2-alpha kinase"},
    {id: "eukaryotic translation initiation factor 2a"},
    {id: "eukaryotic translation initiation factor 2d"},
    {id: "ligatin"},
    {id: "eif2d"},
    {id: "eukaryotic translation initiation factor 3 subunit a"},
    {id: "eif3a"},
    {id: "eukaryotic translation initiation factor 3 subunit 10"},
    {id: "eif-3-theta"},
    {id: "eukaryotic translation initiation factor 3 subunit b"},
    {id: "eif3b"},
    {id: "eukaryotic translation initiation factor 3 subunit 9"},
    {id: "eif-3-eta"},
    {id: "eukaryotic translation initiation factor 3 subunit c"},
    {id: "eif3c"},
    {id: "eukaryotic translation initiation factor 3 subunit 8"},
    {id: "eif3 p110"},
    {id: "eukaryotic translation initiation factor 3 subunit d"},
    {id: "eif3d"},
    {id: "eukaryotic translation initiation factor 3 subunit 7"},
    {id: "eif-3-zeta"},
    {id: "eukaryotic translation initiation factor 3 subunit e"},
    {id: "eif3e"},
    {id: "eukaryotic translation initiation factor 3 subunit 6"},
    {id: "eif-3 p48"},
    {id: "eukaryotic translation initiation factor 3 subunit f"},
    {id: "eif3f"},
    {id: "eukaryotic translation initiation factor 3 subunit 5"},
    {id: "eif-3-epsilon"},
    {id: "eif3 p47"},
    {id: "eukaryotic translation initiation factor 3 subunit g"},
    {id: "eif3g"},
    {id: "eukaryotic translation initiation factor 3 rna-binding subunit"},
    {id: "eif-3 rna-binding subunit"},
    {id: "eukaryotic translation initiation factor 3 subunit 4"},
    {id: "eif-3-delta"},
    {id: "eif3 p42"},
    {id: "eif3 p44"},
    {id: "eukaryotic translation initiation factor 3 subunit h"},
    {id: "eif3h"},
    {id: "eukaryotic translation initiation factor 3 subunit 3"},
    {id: "eif-3 gamma"},
    {id: "eif3 p40 subunit"},
    {id: "eukaryotic translation initiation factor 3 subunit i"},
    {id: "eif3i"},
    {id: "eukaryotic translation initiation factor 3 subunit 2"},
    {id: "eif-3-beta"},
    {id: "eif3 p36"},
    {id: "eukaryotic translation initiation factor 3 subunit j"},
    {id: "eif3j"},
    {id: "eukaryotic translation initiation factor 3 subunit 1"},
    {id: "eif-3-alpha"},
    {id: "eif3 p35"},
    {id: "eukaryotic translation initiation factor 3 subunit k"},
    {id: "eif3k"},
    {id: "eukaryotic translation initiation factor 3 subunit 12"},
    {id: "eif-3 p25"},
    {id: "eukaryotic translation initiation factor 3 subunit l"},
    {id: "eif3l"},
    {id: "eukaryotic translation initiation factor 3 subunit 6-interacting protein"},
    {id: "eukaryotic translation initiation factor 3 subunit e-interacting protein"},
    {id: "eukaryotic translation initiation factor 3 subunit m"},
    {id: "eif3m"},
    {id: "eukaryotic translation initiation factor 4e"},
    {id: "eif-4e"},
    {id: "eif4e"},
    {id: "eif-4f 25 kda subunit"},
    {id: "mrna cap-binding protein"},
    {id: "eukaryotic translation initiation factor 4e-binding protein 1"},
    {id: "4e-bp1"},
    {id: "eif4e-binding protein 1"},
    {id: "phosphorylated heat- and acid-stable protein regulated by insulin 1"},
    {id: "phas-i"},
    {id: "eukaryotic translation initiation factor 5"},
    {id: "eif-5"},
    {id: "eukaryotic translation initiation factor 5a"},
    {id: "eif-5a"},
    {id: "eukaryotic translation initiation factor 5a-1"},
    {id: "eif-5a-1"},
    {id: "eif-5a1"},
    {id: "eukaryotic initiation factor 5a isoform 1"},
    {id: "eif-4d"},
    {id: "eukaryotic translation initiation factor 5b"},
    {id: "translation initiation factor if-2"},
    {id: "eif-5b"},
    {id: "annexin v-binding protein abp-7"},
    {id: "eukaryotic translation initiation factor 6"},
    {id: "eif-6"},
    {id: "eva-1 homolog b"},
    {id: "fam176b protein"},
    {id: "exoc6b protein"},
    {id: "exocyst complex component 6b"},
    {id: "exocyst complex component 3"},
    {id: "exocyst complex component sec6"},
    {id: "rsec6"},
    {id: "exocyst complex component 5"},
    {id: "71 kda component of rsec6/8 secretory complex"},
    {id: "exocyst complex component sec10"},
    {id: "p71"},
    {id: "exocyst complex component 7"},
    {id: "exocyst complex component exo70"},
    {id: "exocyst complex component 8"},
    {id: "exocyst complex 84 kda subunit"},
    {id: "exonuclease 1"},
    {id: "exopolyphosphatase prune1"},
    {id: "ec 3.6.1.1"},
    {id: "exosome complex component rrp45"},
    {id: "exosome component 9"},
    {id: "exportin 6"},
    {id: "xpo6 protein"},
    {id: "exportin-2"},
    {id: "chromosome segregation 1-like protein"},
    {id: "importin-alpha re-exporter"},
    {id: "exportin-t"},
    {id: "exportin(trna)"},
    {id: "trna exportin"},
    {id: "extracellular sulfatase"},
    {id: "eyes absent homolog"},
    {id: "ezrin"},
    {id: "cytovillin"},
    {id: "villin-2"},
    {id: "p81"},
    {id: "f-actin monooxygenase"},
    {id: "ec 1.14.13.225"},
    {id: "f-actin-capping protein subunit alpha-1"},
    {id: "capz alpha-1"},
    {id: "f-box only protein 43"},
    {id: "endogenous meiotic inhibitor 2"},
    {id: "f-box only protein 6"},
    {id: "f-box only protein 6b"},
    {id: "f-box protein that recognizes sugar chains 2"},
    {id: "f-box/g-domain protein 2"},
    {id: "f-box/lrr-repeat protein 16"},
    {id: "f-box and leucine-rich repeat protein 16"},
    {id: "spinal cord injury and regeneration-related protein 1"},
    {id: "f-box/lrr-repeat protein 5"},
    {id: "f-box and leucine-rich repeat protein 5"},
    {id: "f-box/wd repeat-containing protein 5"},
    {id: "f-box and wd-40 domain-containing protein 5"},
    {id: "fact complex subunit ssrp1"},
    {id: "facilitates chromatin transcription complex subunit ssrp1"},
    {id: "structure-specific recognition protein 1"},
    {id: "fad synthase"},
    {id: "ec 2.7.7.2"},
    {id: "fad pyrophosphorylase"},
    {id: "fmn adenylyltransferase"},
    {id: "flavin adenine dinucleotide synthase"},
    {id: "fad-linked sulfhydryl oxidase alr"},
    {id: "ec 1.8.3.2"},
    {id: "augmenter of liver regeneration"},
    {id: "fam20c"},
    {id: "fam20c, golgi associated secretory pathway kinase"},
    {id: "family with sequence similarity 20, member c"},
    {id: "fas-associated death domain protein"},
    {id: "fas-associating death domain-containing protein"},
    {id: "fas-associated factor 2"},
    {id: "ubx domain-containing protein 8"},
    {id: "fast kinase domain-containing protein 4"},
    {id: "protein tbrg4"},
    {id: "transforming growth factor beta regulator 4"},
    {id: "fast kinase domains 3"},
    {id: "fastkd3 protein"},
    {id: "fau, ubiquitin like and ribosomal protein s30 fusion"},
    {id: "similar to finkel-biskis-reilly murine sarcoma virusubiquitously expressed"},
    {id: "fbj osteosarcoma viral oncogene isoform deltafosb-2"},
    {id: "fosb proto-oncogene, ap-1 transcription factor subunit"},
    {id: "fcf1 rrna-processing protein"},
    {id: "zh10 protein"},
    {id: "ferm domain containing kindlin 3"},
    {id: "fermt3 protein"},
    {id: "ferm, arhgef and pleckstrin domain-containing protein 1"},
    {id: "ferm, rhogef and pleckstrin domain-containing protein 1"},
    {id: "fgfr1 oncogene partner 2 homolog"},
    {id: "wound-inducible transcript 3.0 protein"},
    {id: "wit3.0"},
    {id: "fignl1-interacting regulator of recombination and mitosis"},
    {id: "fidgetin-like-1 interacting protein"},
    {id: "flip"},
    {id: "polo1-associating protein"},
    {id: "fk506-binding protein-like"},
    {id: "waf-1/cip1 stabilizing protein 39"},
    {id: "wisp39"},
    {id: "fmr1-interacting protein nufip1"},
    {id: "nuclear fmrp-interacting protein 1"},
    {id: "fras1 related extracellular matrix 2"},
    {id: "fras1 related extracellular matrix protein 2"},
    {id: "fxyd domain-containing ion transport regulator 4"},
    {id: "channel-inducing factor"},
    {id: "chif"},
    {id: "corticosteroid-induced protein"},
    {id: "fyn-binding protein 1"},
    {id: "adhesion and degranulation promoting adaptor protein"},
    {id: "adap"},
    {id: "fyb-120/130"},
    {id: "p120/p130"},
    {id: "fyn-t-binding protein"},
    {id: "slap-130"},
    {id: "slp-76-associated phosphoprotein"},
    {id: "fyve, rhogef and ph domain-containing protein 4"},
    {id: "actin filament-binding protein frabin"},
    {id: "fgd1-related f-actin-binding protein"},
    {id: "fam134c protein"},
    {id: "reticulophagy regulator family member 3"},
    {id: "fam19a4 protein"},
    {id: "tafa chemokine like family member 4"},
    {id: "fam84a protein"},
    {id: "lrat domain containing 1"},
    {id: "fam91a1 protein"},
    {id: "family with sequence similarity 91, member a1"},
    {id: "family with sequence similarity 170 member b"},
    {id: "family with sequence similarity 170, member b"},
    {id: "family with sequence similarity 185, member a"},
    {id: "rgd1562135 protein"},
    {id: "family with sequence similarity 193, member b"},
    {id: "rgd1562335 protein"},
    {id: "family with sequence similarity 227, member a"},
    {id: "hypothetical loc300074"},
    {id: "family with sequence similarity 237 member a"},
    {id: "neurosecretory protein gl"},
    {id: "family with sequence similarity 35, member a"},
    {id: "shieldin complex subunit 2"},
    {id: "fanconi anemia core complex-associated protein 20"},
    {id: "fanca-associated protein of 20 kda"},
    {id: "fanconi anemia-associated protein of 20 kda"},
    {id: "fanconi anemia group c protein homolog"},
    {id: "protein facc"},
    {id: "fanconi-associated nuclease"},
    {id: "ec 3.1.4.1"},
    {id: "farnesyl pyrophosphate synthase"},
    {id: "(2e,6e)-farnesyl diphosphate synthase"},
    {id: "dimethylallyltranstransferase"},
    {id: "geranyltranstransferase"},
    {id: "fas apoptotic inhibitory molecule 1"},
    {id: "rfaim"},
    {id: "fasciculation and elongation protein zeta-1"},
    {id: "zygin i"},
    {id: "zygin-1"},
    {id: "fat storage-inducing transmembrane protein 1"},
    {id: "fat-inducing protein 1"},
    {id: "fatty acid 2-hydroxylase"},
    {id: "ec 1.14.18.-"},
    {id: "fatty acid alpha-hydroxylase"},
    {id: "fatty acid desaturase 3"},
    {id: "ec 1.14.19.-"},
    {id: "delta(13) fatty acid desaturase"},
    {id: "delta(13) desaturase"},
    {id: "fatty acid synthase"},
    {id: "ec 1.3.1.39"},
    {id: "ec 2.3.1.38"},
    {id: "ec 2.3.1.39"},
    {id: "ec 2.3.1.85"},
    {id: "ec 3.1.2.14"},
    {id: "ec 4.2.1.59"},
    {id: "type i fas"},
    {id: "includes: [acyl-carrier-protein] s-acetyltransferase (ec 2.3.1.38); [acyl-carrier-protein] s-malonyltransferase (ec 2.3.1.39); 3-oxoacyl-[acyl-carrier-protein] synthase (ec 2.3.1.41); 3-oxoacyl-[acyl-carrier-protein] reductase (ec 1.1.1.100); 3-hydroxyacyl-[acyl-carrier-protein] dehydratase (ec 4.2.1.59); enoyl-[acyl-carrier-protein] reductase (ec 1.3.1.39); acyl-[acyl-carrier-protein] hydrolase (ec 3.1.2.14)"},
    {id: "fatty acid-binding protein, brain"},
    {id: "brain lipid-binding protein"},
    {id: "blbp"},
    {id: "brain-type fatty acid-binding protein"},
    {id: "b-fabp"},
    {id: "fatty acid-binding protein 7"},
    {id: "fatty acid-binding protein, heart"},
    {id: "fatty acid-binding protein 3"},
    {id: "heart-type fatty acid-binding protein"},
    {id: "h-fabp"},
    {id: "fatty acyl-coa reductase"},
    {id: "ec 1.2.1.84"},
    {id: "fatty acyl-coa reductase 1"},
    {id: "fatty-acid amide hydrolase 1"},
    {id: "ec 3.5.1.99"},
    {id: "anandamide amidase"},
    {id: "anandamide amidohydrolase 1"},
    {id: "fatty acid ester hydrolase"},
    {id: "oleamide hydrolase 1"},
    {id: "fc fragment of igg receptor iia"},
    {id: "loc498276 protein"},
    {id: "low affinity immunoglobulin gamma fc region receptor iii-like"},
    {id: "fc fragment of igg receptor iib"},
    {id: "low affinity fc-gamma receptor iib isoform 1"},
    {id: "fc receptor-like a"},
    {id: "fc receptor-like and mucin-like protein 1"},
    {id: "ferrochelatase"},
    {id: "ec 4.98.1.1"},
    {id: "fetub protein"},
    {id: "fetuin b"},
    {id: "fibrinogen-like protein 1"},
    {id: "fibronigen-like protein"},
    {id: "fibroblast growth factor"},
    {id: "fgf"},
    {id: "fibroblast growth factor 10"},
    {id: "fgf-10"},
    {id: "fibroblast growth factor 12"},
    {id: "fgf-12"},
    {id: "fibroblast growth factor homologous factor 1"},
    {id: "fhf-1"},
    {id: "fibroblast growth factor 13"},
    {id: "fgf-13"},
    {id: "fibroblast growth factor 14"},
    {id: "fgf-14"},
    {id: "fibroblast growth factor 16"},
    {id: "fgf-16"},
    {id: "fibroblast growth factor 17"},
    {id: "fgf-17"},
    {id: "fibroblast growth factor 18"},
    {id: "fgf-18"},
    {id: "fibroblast growth factor 2"},
    {id: "fgf-2"},
    {id: "basic fibroblast growth factor"},
    {id: "bfgf"},
    {id: "heparin-binding growth factor 2"},
    {id: "hbgf-2"},
    {id: "fibroblast growth factor 20"},
    {id: "fgf-20"},
    {id: "fibroblast growth factor 23"},
    {id: "fgf-23"},
    {id: "fibroblast growth factor 9"},
    {id: "fgf-9"},
    {id: "glia-activating factor"},
    {id: "gaf"},
    {id: "hbgf-9"},
    {id: "fibroblast growth factor putative isoform 2"},
    {id: "nudix hydrolase 6"},
    {id: "fibroblast growth factor receptor"},
    {id: "ec 2.7.10.1"},
    {id: "fibronectin type iii domain-containing protein 1"},
    {id: "activator of g-protein signaling 8"},
    {id: "ficolin-2"},
    {id: "collagen/fibrinogen domain-containing protein 2"},
    {id: "ficolin-b"},
    {id: "ficolin-beta"},
    {id: "l-ficolin"},
    {id: "fidgetin-like protein 1"},
    {id: "filamin-c"},
    {id: "fln-c"},
    {id: "abp-280-like protein"},
    {id: "abp-l"},
    {id: "actin-binding-like protein"},
    {id: "filamin-2"},
    {id: "gamma-filamin"},
    {id: "flagellum-associated coiled-coil domain-containing protein 1"},
    {id: "amyotrophic lateral sclerosis 2 chromosomal region candidate gene 12 protein homolog"},
    {id: "flap endonuclease 1"},
    {id: "fen-1"},
    {id: "flap structure-specific endonuclease 1"},
    {id: "flavin-containing monooxygenase"},
    {id: "flavin-containing monooxygenase 5"},
    {id: "ec 1.6.3.1"},
    {id: "flotillin-1"},
    {id: "reggie-2"},
    {id: "reg-2"},
    {id: "follicle-stimulating hormone receptor"},
    {id: "fsh-r"},
    {id: "follitropin receptor"},
    {id: "follicular dendritic cell secreted peptide"},
    {id: "follicular dendritic cell secreted protein"},
    {id: "folliculin"},
    {id: "birt-hogg-dube syndrome protein homolog"},
    {id: "follistatin-related protein 1"},
    {id: "follistatin-like protein 1"},
    {id: "follistatin-related protein 3"},
    {id: "follistatin-like protein 3"},
    {id: "follistatin-related gene protein"},
    {id: "follitropin subunit beta"},
    {id: "follicle-stimulating hormone beta subunit"},
    {id: "fsh-b"},
    {id: "fsh-beta"},
    {id: "follitropin beta chain"},
    {id: "folylpolyglutamate synthase"},
    {id: "ec 6.3.2.17"},
    {id: "folylpoly-gamma-glutamate synthetase"},
    {id: "tetrahydrofolylpolyglutamate synthase"},
    {id: "forkhead box p3"},
    {id: "forkhead box p3 isoform 2"},
    {id: "forkhead box p3 isoform 3"},
    {id: "forkhead box protein c2"},
    {id: "brain factor 3"},
    {id: "bf-3"},
    {id: "hfh-bf-3"},
    {id: "forkhead box protein e3"},
    {id: "forkhead-related protein fkhl12"},
    {id: "forkhead-related transcription factor 8"},
    {id: "freac-8"},
    {id: "hepatocyte nuclear factor 3 forkhead homolog 7"},
    {id: "hfh-7"},
    {id: "forkhead box protein o1"},
    {id: "forkhead box protein o1a"},
    {id: "forkhead in rhabdomyosarcoma"},
    {id: "formimidoyltransferase-cyclodeaminase"},
    {id: "58 kda microtubule-binding protein"},
    {id: "formiminotransferase-cyclodeaminase"},
    {id: "ftcd"},
    {id: "includes: glutamate formimidoyltransferase (ec 2.1.2.5) (glutamate formiminotransferase) (glutamate formyltransferase); formimidoyltetrahydrofolate cyclodeaminase (ec 4.3.1.4) (formiminotetrahydrofolate cyclodeaminase)"},
    {id: "formin-binding protein 1"},
    {id: "formin-binding protein 17"},
    {id: "rapostlin"},
    {id: "formin-binding protein 1-like"},
    {id: "transducer of cdc42-dependent actin assembly protein 1"},
    {id: "toca-1"},
    {id: "fos-related antigen 1"},
    {id: "fra-1"},
    {id: "four and a half lim domains protein 2"},
    {id: "fhl-2"},
    {id: "lim domain protein dral"},
    {id: "skeletal muscle lim-protein 3"},
    {id: "slim-3"},
    {id: "foveolin"},
    {id: "gastrokine 1"},
    {id: "fpgt protein"},
    {id: "fucose-1-phosphate guanylyltransferase"},
    {id: "frataxin, mitochondrial"},
    {id: "ec 1.16.3.1"},
    {id: "free fatty acid receptor 1"},
    {id: "g-protein coupled receptor 40"},
    {id: "free fatty acid receptor 2"},
    {id: "g-protein coupled receptor 43"},
    {id: "free fatty acid receptor 3"},
    {id: "g-protein coupled receptor 41"},
    {id: "free fatty acid receptor 4"},
    {id: "g-protein coupled receptor 120"},
    {id: "omega-3 fatty acid receptor 1"},
    {id: "frizzled-4"},
    {id: "fz-4"},
    {id: "rfz4"},
    {id: "cd antigen cd344"},
    {id: "frizzled-8"},
    {id: "fz-8"},
    {id: "frizzled-9"},
    {id: "frizzled-like protein 9"},
    {id: "rfz9"},
    {id: "fructose-1,6-bisphosphatase isozyme 2"},
    {id: "fbpase 2"},
    {id: "ec 3.1.3.11"},
    {id: "d-fructose-1,6-bisphosphate 1-phosphohydrolase 2"},
    {id: "muscle fbpase"},
    {id: "fructose-bisphosphate aldolase"},
    {id: "ec 4.1.2.13"},
    {id: "fucosyltransferase"},
    {id: "fumarylacetoacetase"},
    {id: "ec 3.7.1.2"},
    {id: "fumarylacetoacetate hydrolase"},
    {id: "faa"},
    {id: "beta-diketonase"},
    {id: "furin"},
    {id: "paired basic amino acid cleaving enzyme"},
    {id: "coiled-coil domain-containing protein 75"},
    {id: "girk-1"},
    {id: "inward rectifier k(+) channel kir3.1"},
    {id: "kga"},
    {id: "kgb1"},
    {id: "potassium channel, inwardly rectifying subfamily j member 3"},
    {id: "girk-3"},
    {id: "inward rectifier k(+) channel kir3.3"},
    {id: "potassium channel, inwardly rectifying subfamily j member 9"},
    {id: "cardiac inward rectifier"},
    {id: "heart katp channel"},
    {id: "inward rectifier k(+) channel kir3.4"},
    {id: "katp-1"},
    {id: "potassium channel, inwardly rectifying subfamily j member 5"},
    {id: "girk-4"},
    {id: "cir"},
    {id: "protein bhlhb9"},
    {id: "bhlhb9"},
    {id: "transcription regulator of 60 kda"},
    {id: "p60trp"},
    {id: "ec 2.7.11.-"},
    {id: "ec 2.7.11.16"},
    {id: "g-protein coupled bile acid receptor 1"},
    {id: "membrane-type receptor for bile acids"},
    {id: "m-bar"},
    {id: "g-protein coupled receptor 12"},
    {id: "r334"},
    {id: "g-protein coupled receptor 183"},
    {id: "epstein-barr virus-induced g-protein coupled receptor 2"},
    {id: "ebi2"},
    {id: "ebv-induced g-protein coupled receptor 2"},
    {id: "g-protein coupled receptor 3"},
    {id: "g-protein-coupled receptor r4"},
    {id: "g-protein coupled receptor 6"},
    {id: "sphingosine 1-phosphate receptor gpr6"},
    {id: "g-protein coupled receptor 83"},
    {id: "glucocorticoid-induced receptor"},
    {id: "g0/g1 switch protein 2"},
    {id: "g0s2-like protein"},
    {id: "g6f-ly6g6d fusion protein"},
    {id: "lymphocyte antigen 6 family member g6f"},
    {id: "ga binding protein transcription factor subunit beta 1"},
    {id: "ga repeat binding protein, beta 1"},
    {id: "gaba"},
    {id: "gaba-a receptor epsilon-like subunit"},
    {id: "gamma-aminobutyric acid (gaba) a receptor, subunit epsilon"},
    {id: "gaba-br1h receptor"},
    {id: "gamma-aminobutyric acid type b receptor subunit 1"},
    {id: "gator complex protein nprl3"},
    {id: "nitrogen permease regulator 3-like protein"},
    {id: "gc-rich promoter binding protein 1"},
    {id: "gpbp1 protein"},
    {id: "gdnf family receptor alpha-1"},
    {id: "gdnf receptor alpha-1"},
    {id: "gdnfr-alpha-1"},
    {id: "gfr-alpha-1"},
    {id: "ret ligand 1"},
    {id: "tgf-beta-related neurotrophic factor receptor 1"},
    {id: "gdnf family receptor alpha-4"},
    {id: "gdnf receptor alpha-4"},
    {id: "gdnfr-alpha-4"},
    {id: "gfr-alpha-4"},
    {id: "persephin receptor"},
    {id: "gdnf-inducible zinc finger protein 1"},
    {id: "zinc finger protein 336"},
    {id: "gdp-l-fucose synthase"},
    {id: "ec 1.1.1.271"},
    {id: "gdp-4-keto-6-deoxy-d-mannose-3,5-epimerase-4-reductase"},
    {id: "gdp-man:man"},
    {id: "ec 2.4.1.131"},
    {id: "gdp-fucose protein o-fucosyltransferase 1"},
    {id: "ec 2.4.1.221"},
    {id: "peptide-o-fucosyltransferase 1"},
    {id: "o-fuct-1"},
    {id: "gdp-fucose protein o-fucosyltransferase 2"},
    {id: "peptide-o-fucosyltransferase 2"},
    {id: "gdp-mannose 4,6-dehydratase"},
    {id: "ec 4.2.1.47"},
    {id: "gdp-d-mannose dehydratase"},
    {id: "gem"},
    {id: "nuclear organelle"},
    {id: "ghrh receptor beta ghrhr"},
    {id: "growth hormone releasing hormone receptor"},
    {id: "gimap4"},
    {id: "gtpase, imap family member 4"},
    {id: "gimap7"},
    {id: "gtpase, imap family member 7"},
    {id: "ian3 protein"},
    {id: "gimap9"},
    {id: "gtpase, imap family member 9"},
    {id: "ian7 protein"},
    {id: "gli family zinc finger 4"},
    {id: "similar to gli-kruppel family member gli4"},
    {id: "gmp reductase"},
    {id: "gmpr"},
    {id: "ec 1.7.1.7"},
    {id: "guanosine 5'-monophosphate oxidoreductase"},
    {id: "guanosine monophosphate reductase"},
    {id: "gmp synthase"},
    {id: "glutamine-hydrolyzing"},
    {id: "ec 6.3.5.2"},
    {id: "glutamine amidotransferase"},
    {id: "gp49b2"},
    {id: "leukocyte immunoglobulin like receptor b4"},
    {id: "gpi ethanolamine phosphate transferase 1"},
    {id: "ec 2.-.-.-"},
    {id: "gpi inositol-deacylase"},
    {id: "post-gpi attachment to proteins factor 1"},
    {id: "gpi mannosyltransferase 1"},
    {id: "gpi mannosyltransferase i"},
    {id: "gpi-mt-i"},
    {id: "phosphatidylinositol-glycan biosynthesis class m protein"},
    {id: "pig-m"},
    {id: "gpi mannosyltransferase 2"},
    {id: "gpi mannosyltransferase ii"},
    {id: "gpi-mt-ii"},
    {id: "phosphatidylinositol-glycan biosynthesis class v protein"},
    {id: "pig-v"},
    {id: "gpi transamidase component pig-s"},
    {id: "phosphatidylinositol-glycan biosynthesis class s protein"},
    {id: "gpi-anchor transamidase"},
    {id: "phosphatidylinositol-glycan biosynthesis class k protein"},
    {id: "gpn-loop gtpase"},
    {id: "gpn-loop gtpase 2"},
    {id: "atp-binding domain 1 family member b"},
    {id: "gram domain-containing protein 2b"},
    {id: "gram domain-containing protein 3"},
    {id: "grb2-associated binding protein 1"},
    {id: "grb2-associated binding protein 1 long isoform"},
    {id: "grip1-associated protein 1"},
    {id: "grasp-1"},
    {id: "cleaved into: grasp-1 c-terminal chain (30kda c-terminus form)"},
    {id: "gs homeobox 2"},
    {id: "genomic screened homeo box 2 protein"},
    {id: "gtp 3',8-cyclase"},
    {id: "ec 4.1.99.22"},
    {id: "gtp binding protein 6"},
    {id: "gtp cyclohydrolase 1"},
    {id: "ec 3.5.4.16"},
    {id: "gtp cyclohydrolase i"},
    {id: "gtp-ch-i"},
    {id: "gtp cyclohydrolase 1 feedback regulatory protein"},
    {id: "gtp cyclohydrolase i feedback regulatory protein"},
    {id: "gtp-binding nuclear protein ran"},
    {id: "gtpase ran"},
    {id: "ras-like protein tc4"},
    {id: "ras-related nuclear protein"},
    {id: "gtp-binding nuclear protein ran, testis-specific isoform"},
    {id: "gtp-binding protein rem 2"},
    {id: "rad and gem-like gtp-binding protein 2"},
    {id: "gtp-binding protein rheb"},
    {id: "ras homolog enriched in brain"},
    {id: "gtp-binding protein rhes"},
    {id: "ras homolog enriched in striatum"},
    {id: "se6c"},
    {id: "gtp-binding protein rit2"},
    {id: "gtp:amp phosphotransferase ak3, mitochondrial"},
    {id: "adenylate kinase 3"},
    {id: "ak 3"},
    {id: "adenylate kinase 3 alpha-like 1"},
    {id: "gtpase era, mitochondrial"},
    {id: "era-like protein 1"},
    {id: "gtpase imap family member 5"},
    {id: "immunity-associated nucleotide 4 protein"},
    {id: "ian-4"},
    {id: "immunity-associated nucleotide 4-like 1 protein"},
    {id: "gtpase imap family member 6"},
    {id: "immunity-associated nucleotide 6 protein"},
    {id: "ian-6"},
    {id: "gtpase imap family member 8"},
    {id: "immune-associated nucleotide-binding protein 9"},
    {id: "ian-9"},
    {id: "protein iant"},
    {id: "gtpase rhebl1"},
    {id: "ras homolog enriched in brain-like protein 1"},
    {id: "rheb-like protein 1"},
    {id: "galactocerebrosidase"},
    {id: "ec 3.2.1.46"},
    {id: "galactosylceramidase"},
    {id: "galactosamine"},
    {id: "n-acetyl"},
    {id: "galactose-1-phosphate uridylyltransferase"},
    {id: "ec 2.7.7.12"},
    {id: "galactoside alpha-"},
    {id: "1,2"},
    {id: "alpha 1,2-fucosyltransferase a"},
    {id: "alpha(1,2)ft 1"},
    {id: "fucosyltransferase 1"},
    {id: "gdp-l-fucose:beta-d-galactoside 2-alpha-l-fucosyltransferase 1"},
    {id: "type 1 galactoside alpha-(1,2)-fucosyltransferase fut1"},
    {id: "ec 2.4.1.69"},
    {id: "type 2 galactoside alpha-(1,2)-fucosyltransferase fut1"},
    {id: "ec 2.4.1.344"},
    {id: "galactosylgalactosylxylosylprotein 3-beta-glucuronosyltransferase"},
    {id: "ec 2.4.1.135"},
    {id: "galactosylgalactosylxylosylprotein 3-beta-glucuronosyltransferase 1"},
    {id: "beta-1,3-glucuronyltransferase 1"},
    {id: "glucuronosyltransferase p"},
    {id: "glcat-p"},
    {id: "udp-glcua:glycoprotein beta-1,3-glucuronyltransferase"},
    {id: "glcuat-p"},
    {id: "galactosylgalactosylxylosylprotein 3-beta-glucuronosyltransferase 2"},
    {id: "beta-1,3-glucuronyltransferase 2"},
    {id: "glcat-d"},
    {id: "udp-glucuronosyltransferase s"},
    {id: "glcat-s"},
    {id: "glucuronosyltransferase s"},
    {id: "galanin peptides"},
    {id: "cleaved into: galanin; galanin message-associated peptide (gmap)"},
    {id: "galanin receptor type 1"},
    {id: "gal1-r"},
    {id: "galr-1"},
    {id: "galectin-1"},
    {id: "gal-1"},
    {id: "14 kda lectin"},
    {id: "beta-galactoside-binding lectin l-14-i"},
    {id: "galaptin"},
    {id: "lactose-binding lectin 1"},
    {id: "lectin galactoside-binding soluble 1"},
    {id: "rl 14.5"},
    {id: "s-lac lectin 1"},
    {id: "galectin-3"},
    {id: "gal-3"},
    {id: "35 kda lectin"},
    {id: "carbohydrate-binding protein 35"},
    {id: "cbp 35"},
    {id: "galactose-specific lectin 3"},
    {id: "ige-binding protein"},
    {id: "lectin l-29"},
    {id: "mac-2 antigen"},
    {id: "galectin-3-binding protein"},
    {id: "lectin galactoside-binding soluble 3-binding protein"},
    {id: "galectin-7"},
    {id: "gal-7"},
    {id: "gametogenetin-binding protein 2"},
    {id: "protein znf403"},
    {id: "gamma-aminobutyric acid"},
    {id: "gamma-aminobutyric acid receptor subunit alpha-1"},
    {id: "gaba(a) receptor subunit alpha-1"},
    {id: "gamma-aminobutyric acid receptor subunit alpha-2"},
    {id: "gaba(a) receptor subunit alpha-2"},
    {id: "gamma-aminobutyric acid receptor subunit alpha-4"},
    {id: "gaba(a) receptor subunit alpha-4"},
    {id: "gamma-aminobutyric acid receptor subunit alpha-5"},
    {id: "gaba(a) receptor subunit alpha-5"},
    {id: "gamma-aminobutyric acid receptor subunit delta"},
    {id: "gaba(a) receptor subunit delta"},
    {id: "gamma-aminobutyric acid receptor subunit gamma-1"},
    {id: "gaba(a) receptor subunit gamma-1"},
    {id: "gamma-aminobutyric acid receptor subunit gamma-2"},
    {id: "gaba(a) receptor subunit gamma-2"},
    {id: "gamma-aminobutyric acid receptor subunit gamma-3"},
    {id: "gaba(a) receptor subunit gamma-3"},
    {id: "gamma-aminobutyric acid receptor subunit pi"},
    {id: "gaba(a) receptor subunit pi"},
    {id: "gamma-aminobutyric acid receptor subunit rho-1"},
    {id: "gaba(a) receptor subunit rho-1"},
    {id: "gaba(c) receptor"},
    {id: "gamma-aminobutyric acid receptor subunit rho-2"},
    {id: "gaba(a) receptor subunit rho-2"},
    {id: "gamma-aminobutyric acid receptor subunit rho-3"},
    {id: "gaba(a) receptor subunit rho-3"},
    {id: "gamma-aminobutyric acid receptor-associated protein-like 2"},
    {id: "gaba(a) receptor-associated protein-like 2"},
    {id: "ganglioside expression factor 2"},
    {id: "gef-2"},
    {id: "golgi-associated atpase enhancer of 16 kda"},
    {id: "gate-16"},
    {id: "gaba-b receptor 1"},
    {id: "gaba-b-r1"},
    {id: "gaba-br1"},
    {id: "gababr1"},
    {id: "gb1"},
    {id: "gamma-butyrobetaine dioxygenase"},
    {id: "ec 1.14.11.1"},
    {id: "gamma-butyrobetaine hydroxylase"},
    {id: "gamma-bbh"},
    {id: "gamma-butyrobetaine,2-oxoglutarate dioxygenase"},
    {id: "gamma-crystallin a"},
    {id: "gamma-a-crystallin"},
    {id: "gamma-crystallin 1-1"},
    {id: "gamma-crystallin n"},
    {id: "gamma-n-crystallin"},
    {id: "gamma-crystallin s"},
    {id: "beta-crystallin s"},
    {id: "gamma-s-crystallin"},
    {id: "gamma-glutamyl carboxylase"},
    {id: "ggcx protein"},
    {id: "gamma-glutamylaminecyclotransferase"},
    {id: "ec 4.3.2.8"},
    {id: "ggact"},
    {id: "aig2-like domain-containing protein 1"},
    {id: "gamma-glutamylamine cyclotransferase"},
    {id: "gamma-glutamylcyclotransferase"},
    {id: "ec 4.3.2.7"},
    {id: "gamma-interferon-inducible lysosomal thiol reductase"},
    {id: "ec 1.8.-.-"},
    {id: "interferon gamma inducible protein 30"},
    {id: "gamma-secretase subunit aph-1"},
    {id: "aph-1"},
    {id: "gamma-secretase subunit pen-2"},
    {id: "liver regeneration-related protein lrrgt00140"},
    {id: "presenilin enhancer protein 2"},
    {id: "gap junction alpha-5 protein"},
    {id: "connexin-40"},
    {id: "cx40"},
    {id: "gap junction alpha-8 protein"},
    {id: "connexin-50"},
    {id: "cx50"},
    {id: "gap junction beta-1 protein"},
    {id: "connexin-32"},
    {id: "cx32"},
    {id: "gap junction 28 kda liver protein"},
    {id: "gap junction beta-2 protein"},
    {id: "connexin-26"},
    {id: "cx26"},
    {id: "gap junction beta-3 protein"},
    {id: "connexin-31"},
    {id: "cx31"},
    {id: "gap junction beta-5 protein"},
    {id: "connexin-31.1"},
    {id: "cx31.1"},
    {id: "gap junction delta-2 protein"},
    {id: "connexin-36"},
    {id: "cx36"},
    {id: "gap junction alpha-9 protein"},
    {id: "gap junction gamma-1 protein"},
    {id: "connexin-45"},
    {id: "cx45"},
    {id: "gap junction alpha-7 protein"},
    {id: "gap junction gamma-2 protein"},
    {id: "connexin-47"},
    {id: "cx47"},
    {id: "gap junction alpha-12 protein"},
    {id: "gas2 protein"},
    {id: "growth arrest-specific 2"},
    {id: "gastric inhibitory polypeptide"},
    {id: "gip"},
    {id: "glucose-dependent insulinotropic polypeptide"},
    {id: "gastric inhibitory polypeptide receptor"},
    {id: "gip-r"},
    {id: "glucose-dependent insulinotropic polypeptide receptor"},
    {id: "gastric triacylglycerol lipase"},
    {id: "gl"},
    {id: "gastric lipase"},
    {id: "lingual lipase"},
    {id: "gastricsin"},
    {id: "ec 3.4.23.3"},
    {id: "pepsinogen c"},
    {id: "gastrin"},
    {id: "cleaved into: big gastrin (gastrin-34) (g34); gastrin"},
    {id: "gastrin-releasing peptide"},
    {id: "grp"},
    {id: "cleaved into: neuromedin-c (grp-10) (grp18-27)"},
    {id: "gastrin-releasing peptide receptor"},
    {id: "grp-r"},
    {id: "grp-preferring bombesin receptor"},
    {id: "gastrokine-2"},
    {id: "blottin"},
    {id: "gelsolin"},
    {id: "brevin"},
    {id: "gem-associated protein 2"},
    {id: "gemin-2"},
    {id: "component of gems 2"},
    {id: "survival of motor neuron protein-interacting protein 1"},
    {id: "smn-interacting protein 1"},
    {id: "general receptor for phosphoinositides 1-associated scaffold protein"},
    {id: "grp1-associated scaffold protein"},
    {id: "95 kda postsynaptic density protein discs-large zo-1 domain-containing protein"},
    {id: "psd-95 pdz domain-containing protein"},
    {id: "tamalin"},
    {id: "general transcription and dna repair factor iih helicase subunit xpb"},
    {id: "dna excision repair protein ercc-3"},
    {id: "tfiih subunit xpb"},
    {id: "general transcription and dna repair factor iih helicase subunit xpd"},
    {id: "general transcription factor ii-i"},
    {id: "gtfii-i"},
    {id: "tfii-i"},
    {id: "general transcription factor iif subunit 1"},
    {id: "transcription initiation factor iif subunit alpha"},
    {id: "tfiif-alpha"},
    {id: "general transcription factor iif subunit 2"},
    {id: "transcription initiation factor iif subunit beta"},
    {id: "general transcription factor iih subunit 2"},
    {id: "basic transcription factor 2 44 kda subunit"},
    {id: "btf2 p44"},
    {id: "general transcription factor iih polypeptide 2"},
    {id: "tfiih basal transcription factor complex p44 subunit"},
    {id: "general transcription factor iih subunit 3"},
    {id: "general transcription factor iih polypeptide 3"},
    {id: "general transcription factor iiic subunit 2"},
    {id: "general transcription factor iiic, polypeptide 2, beta"},
    {id: "general transcription factor iiic subunit 5"},
    {id: "general transcription factor iiic, polypeptide 5"},
    {id: "gephyrin"},
    {id: "putative glycine receptor-tubulin linker protein"},
    {id: "includes: molybdopterin adenylyltransferase (mpt adenylyltransferase) (ec 2.7.7.75) (domain g); molybdopterin molybdenumtransferase (mpt mo-transferase) (ec 2.10.1.1) (domain e)"},
    {id: "includes: molybdopterin molybdenumtransferase (mpt mo-transferase) (ec 2.10.1.1) (domain e); molybdopterin adenylyltransferase (mpt adenylyltransferase) (ec 2.7.7.75) (domain g)"},
    {id: "geranylgeranyl pyrophosphate synthase"},
    {id: "ggpp synthase"},
    {id: "ggppsase"},
    {id: "ec 2.5.1.1"},
    {id: "farnesyltranstransferase"},
    {id: "ec 2.5.1.29"},
    {id: "geranylgeranyl diphosphate synthase"},
    {id: "ec 2.5.1.10"},
    {id: "geranylgeranyl transferase type-1 subunit beta"},
    {id: "ec 2.5.1.59"},
    {id: "geranylgeranyl transferase type i subunit beta"},
    {id: "ggtase-i-beta"},
    {id: "type i protein geranyl-geranyltransferase subunit beta"},
    {id: "geranylgeranyl transferase type-2 subunit alpha"},
    {id: "ec 2.5.1.60"},
    {id: "geranylgeranyl transferase type ii subunit alpha"},
    {id: "ghrelin o-acyltransferase"},
    {id: "membrane-bound o-acyltransferase domain-containing protein 4"},
    {id: "glandular kallikrein-10"},
    {id: "k10"},
    {id: "rgk-10"},
    {id: "ec 3.4.21.35"},
    {id: "endopeptidase k"},
    {id: "proteinase b"},
    {id: "t-kininogenase"},
    {id: "tissue kallikrein"},
    {id: "cleaved into: t-kininogenase light chain; t-kininogenase heavy chain"},
    {id: "glia maturation factor beta"},
    {id: "gmf-beta"},
    {id: "glioblastoma amplified sequence"},
    {id: "nipsnap homolog 2"},
    {id: "globin a1"},
    {id: "hemoglobin, beta adult s chain"},
    {id: "zero beta-1 globin"},
    {id: "globin b1"},
    {id: "hemoglobin subunit epsilon 1"},
    {id: "hemoglobin, epsilon 1"},
    {id: "globin b2"},
    {id: "hemoglobin subunit gamma 1"},
    {id: "hemoglobin, gamma a"},
    {id: "globin c1"},
    {id: "hemoglobin alpha, adult chain 2"},
    {id: "globin h"},
    {id: "neuroglobin"},
    {id: "glt8d3 protein"},
    {id: "glucoside xylosyltransferase 1"},
    {id: "glucagon-like peptide 2 receptor"},
    {id: "glp-2 receptor"},
    {id: "glp-2-r"},
    {id: "glp-2r"},
    {id: "glucocorticoid modulatory element-binding protein 1"},
    {id: "gmeb-1"},
    {id: "gluconokinase"},
    {id: "ec 2.7.1.12"},
    {id: "glucosamine"},
    {id: "udp-n-acetyl"},
    {id: "glucosamine 6-phosphate n-acetyltransferase"},
    {id: "ec 2.3.1.4"},
    {id: "glucosamine-6-phosphate isomerase"},
    {id: "ec 3.5.99.6"},
    {id: "glucosamine-6-phosphate deaminase"},
    {id: "glucosaminyl"},
    {id: "glucosaminyl (n-acetyl) transferase 2, i-branching enzyme"},
    {id: "i-branching beta-1,6-acetylglucosaminyltransferase family polypeptide 3"},
    {id: "i-branching beta-1,6-acetylglucosaminyltransferase family polypeptide 1"},
    {id: "glucose-6-phosphatase"},
    {id: "ec 3.1.3.9"},
    {id: "glucose-6-phosphatase 3"},
    {id: "g-6-pase 3"},
    {id: "g6pase 3"},
    {id: "glucose-6-phosphatase catalytic subunit 1"},
    {id: "g-6-pase"},
    {id: "g6pase"},
    {id: "glucose-6-phosphate isomerase"},
    {id: "ec 5.3.1.9"},
    {id: "gpi"},
    {id: "autocrine motility factor"},
    {id: "amf"},
    {id: "neuroleukin"},
    {id: "nlk"},
    {id: "phosphoglucose isomerase"},
    {id: "pgi"},
    {id: "phosphohexose isomerase"},
    {id: "phi"},
    {id: "glucose-dependent insulinotropic receptor"},
    {id: "g-protein coupled receptor 119"},
    {id: "ec 2.4.2.42"},
    {id: "glycosyltransferase 8 domain-containing protein 3"},
    {id: "s33-d"},
    {id: "glucosylceramidase"},
    {id: "ec 3.2.1.45"},
    {id: "glutamate ionotropic receptor ampa type subunit 4"},
    {id: "gria4 protein"},
    {id: "glutamate receptor 1"},
    {id: "glur-1"},
    {id: "ampa-selective glutamate receptor 1"},
    {id: "glur-a"},
    {id: "glur-k1"},
    {id: "glutamate receptor ionotropic, ampa 1"},
    {id: "glua1"},
    {id: "glutamate receptor ionotropic, nmda 3a"},
    {id: "glun3a"},
    {id: "glutamate receptor chi-1"},
    {id: "n-methyl-d-aspartate receptor"},
    {id: "n-methyl-d-aspartate receptor subtype 3a"},
    {id: "nmdar3a"},
    {id: "nr3a"},
    {id: "nmdar-l"},
    {id: "nmdar-l1"},
    {id: "glutamate receptor ionotropic, delta-1"},
    {id: "glud1"},
    {id: "glur delta-1 subunit"},
    {id: "glutamate receptor ionotropic, delta-2"},
    {id: "glud2"},
    {id: "glur delta-2 subunit"},
    {id: "glutamate receptor ionotropic, kainate 2"},
    {id: "gluk2"},
    {id: "glutamate receptor 6"},
    {id: "glur-6"},
    {id: "glur6"},
    {id: "glutamate receptor-interacting protein 2"},
    {id: "grip-2"},
    {id: "ampa receptor-interacting protein grip2"},
    {id: "glutamate--cysteine ligase regulatory subunit"},
    {id: "gcs light chain"},
    {id: "gamma-ecs regulatory subunit"},
    {id: "gamma-glutamylcysteine synthetase regulatory subunit"},
    {id: "glutamate--cysteine ligase modifier subunit"},
    {id: "glutamine-dependent nad"},
    {id: "ec 6.3.5.1"},
    {id: "nad(+) synthase [glutamine-hydrolyzing]"},
    {id: "glutamyl-trna"},
    {id: "gln"},
    {id: "glu-adt subunit a"},
    {id: "ec 6.3.5.7"},
    {id: "glutaminyl-trna synthase-like protein 1"},
    {id: "glu-adt subunit b"},
    {id: "ec 6.3.5.-"},
    {id: "cytochrome oxidase assembly factor pet112 homolog"},
    {id: "pet112-like"},
    {id: "glu-adt subunit c"},
    {id: "glutaredoxin-1"},
    {id: "thioltransferase-1"},
    {id: "ttase-1"},
    {id: "glutaredoxin-3"},
    {id: "pkc-interacting cousin of thioredoxin"},
    {id: "picot"},
    {id: "pkc-theta-interacting protein"},
    {id: "pkcq-interacting protein"},
    {id: "thioredoxin-like protein 2"},
    {id: "glutathione s-transferase"},
    {id: "ec 2.5.1.18"},
    {id: "gst class-pi"},
    {id: "glutathione s-transferase a6"},
    {id: "gst class-alpha member 6"},
    {id: "glutathione s-transferase mu 4"},
    {id: "gst class-mu 4"},
    {id: "gstm4-4"},
    {id: "leukotriene c4 synthase gstm4"},
    {id: "ec 4.4.1.20"},
    {id: "glutathione s-transferase mu 5"},
    {id: "gst class-mu 5"},
    {id: "glutathione s-transferase alpha-4"},
    {id: "gst 8-8"},
    {id: "gst a4-4"},
    {id: "gst k"},
    {id: "glutathione s-transferase yk"},
    {id: "gst yk"},
    {id: "glutathione s-transferase alpha-5"},
    {id: "gst a5-5"},
    {id: "glutathione s-transferase yc-2"},
    {id: "gst yc2"},
    {id: "glutathione s-transferase kappa 1"},
    {id: "gst 13-13"},
    {id: "gst class-kappa"},
    {id: "gstk1-1"},
    {id: "rgstk1"},
    {id: "glutathione s-transferase subunit 13"},
    {id: "glutathione s-transferase omega"},
    {id: "gsto"},
    {id: "ec 1.20.4.2"},
    {id: "ec 1.8.5.1"},
    {id: "glutathione-dependent dehydroascorbate reductase"},
    {id: "monomethylarsonic acid reductase"},
    {id: "glutathione s-transferase theta-4"},
    {id: "gst class-theta-4"},
    {id: "glutathione hydrolase"},
    {id: "ec 2.3.2.2"},
    {id: "ec 3.4.19.13"},
    {id: "gamma-glutamyltransferase"},
    {id: "gamma-glutamyltranspeptidase"},
    {id: "glutathione hydrolase 1 proenzyme"},
    {id: "gamma-glutamyltransferase 1"},
    {id: "gamma-glutamyltranspeptidase 1"},
    {id: "ggt 1"},
    {id: "leukotriene-c4 hydrolase"},
    {id: "ec 3.4.19.14"},
    {id: "cd antigen cd224"},
    {id: "cleaved into: glutathione hydrolase 1 heavy chain; glutathione hydrolase 1 light chain"},
    {id: "glutathione hydrolase 6"},
    {id: "gamma-glutamyltransferase 6"},
    {id: "ggt 6"},
    {id: "gamma-glutamyltranspeptidase 6"},
    {id: "cleaved into: glutathione hydrolase 6 heavy chain; glutathione hydrolase 6 light chain"},
    {id: "glutathione hydrolase 7"},
    {id: "gamma-glutamyltransferase 7"},
    {id: "ggt 7"},
    {id: "gamma-glutamyltransferase-like 3"},
    {id: "gamma-glutamyltranspeptidase 7"},
    {id: "cleaved into: glutathione hydrolase 7 heavy chain; glutathione hydrolase 7 light chain"},
    {id: "glutathione peroxidase 6"},
    {id: "gpx-6"},
    {id: "gshpx-6"},
    {id: "ec 1.11.1.9"},
    {id: "odorant-metabolizing protein ry2d1"},
    {id: "glutathione reductase"},
    {id: "ec 1.8.1.7"},
    {id: "glutathione-specific gamma-glutamylcyclotransferase 1"},
    {id: "gamma-gcg 1"},
    {id: "blocks notch protein"},
    {id: "botch"},
    {id: "cation transport regulator-like protein 1"},
    {id: "neuroprotective protein 7"},
    {id: "glyceraldehyde 3-phosphate dehydrogenase nad"},
    {id: "glyceraldehyde-3-phosphate dehydrogenase"},
    {id: "ec 1.2.1.12"},
    {id: "glyceraldehyde-3-phosphate dehydrogenase, testis-specific"},
    {id: "spermatogenic cell-specific glyceraldehyde 3-phosphate dehydrogenase 2"},
    {id: "gapdh-2"},
    {id: "spermatogenic glyceraldehyde-3-phosphate dehydrogenase"},
    {id: "glycerate kinase"},
    {id: "ec 2.7.1.31"},
    {id: "glycerol-3-phosphate acyltransferase 1, mitochondrial"},
    {id: "ec 2.3.1.15"},
    {id: "glycerol-3-phosphate acyltransferase 2, mitochondrial"},
    {id: "1-acylglycerol-3-phosphate o-acyltransferase gpat2"},
    {id: "glycerol-3-phosphate acyltransferase 3"},
    {id: "gpat-3"},
    {id: "1-acyl-sn-glycerol-3-phosphate o-acyltransferase 10"},
    {id: "agpat 10"},
    {id: "1-acyl-sn-glycerol-3-phosphate o-acyltransferase 9"},
    {id: "1-agp acyltransferase 9"},
    {id: "1-agpat 9"},
    {id: "lysophosphatidic acid acyltransferase theta"},
    {id: "lpaat-theta"},
    {id: "glycerol-3-phosphate dehydrogenase"},
    {id: "ec 1.1.1.8"},
    {id: "gpd-c"},
    {id: "gpdh-c"},
    {id: "glycerophosphocholine cholinephosphodiesterase enpp6"},
    {id: "gpc-cpde"},
    {id: "ec 3.1.4.38"},
    {id: "choline-specific glycerophosphodiester phosphodiesterase"},
    {id: "ectonucleotide pyrophosphatase/phosphodiesterase family member 6"},
    {id: "e-npp 6"},
    {id: "npp-6"},
    {id: "glycine n-acyltransferase"},
    {id: "ec 2.3.1.13"},
    {id: "acyl-coa:glycine n-acyltransferase"},
    {id: "aac"},
    {id: "aralkyl acyl-coa n-acyltransferase"},
    {id: "aralkyl acyl-coa:amino acid n-acyltransferase"},
    {id: "benzoyl-coenzyme a:glycine n-acyltransferase"},
    {id: "glycine n-benzoyltransferase"},
    {id: "ec 2.3.1.71"},
    {id: "liver regeneration-related protein lrrg067"},
    {id: "glycine n-acyltransferase-like protein"},
    {id: "glycine n-acyltransferase-like protein keg1"},
    {id: "acyl-coa:glycine n-acyltransferase protein keg1"},
    {id: "hepatocellular carcinoma-enriched protein of 33 kda"},
    {id: "hp33"},
    {id: "kidney-expressed gene 1 protein"},
    {id: "glycine alpha 1, truncated"},
    {id: "glycine receptor, alpha 1"},
    {id: "glycine amidinotransferase"},
    {id: "ec 2.1.4.1"},
    {id: "l-arginine:glycine amidinotransferase"},
    {id: "glycine cleavage system h protein, mitochondrial"},
    {id: "lipoic acid-containing protein"},
    {id: "glycine cleavage system p protein"},
    {id: "ec 1.4.4.2"},
    {id: "glycine receptor alpha 3"},
    {id: "glycine receptor, alpha 3"},
    {id: "glycine receptor subunit alpha-1"},
    {id: "glycine receptor 48 kda subunit"},
    {id: "glycine receptor strychnine-binding subunit"},
    {id: "glycine--trna ligase"},
    {id: "ec 6.1.1.14"},
    {id: "diadenosine tetraphosphate synthetase"},
    {id: "glycogen"},
    {id: "starch"},
    {id: "ec 2.4.1.11"},
    {id: "glycogen debranching enzyme"},
    {id: "ec 2.4.1.25"},
    {id: "ec 3.2.1.33"},
    {id: "glycogen debrancher"},
    {id: "glycogen phosphorylase, liver form"},
    {id: "ec 2.4.1.1"},
    {id: "glycolipid transfer protein"},
    {id: "gltp"},
    {id: "glycophorin c"},
    {id: "gerbich blood group"},
    {id: "glycophorin-c"},
    {id: "cd antigen cd236"},
    {id: "glycoprotein 6"},
    {id: "platelet"},
    {id: "glycoprotein 9"},
    {id: "glycoprotein ix (platelet)"},
    {id: "glycoprotein endo-alpha-1,2-mannosidase"},
    {id: "endo-alpha mannosidase"},
    {id: "endomannosidase"},
    {id: "rendo"},
    {id: "ec 3.2.1.130"},
    {id: "endo-alpha-d-mannosidase"},
    {id: "enman"},
    {id: "glycoprotein hormone alpha-2"},
    {id: "putative secreted protein zsig51"},
    {id: "thyrostimulin subunit alpha"},
    {id: "glycoprotein hormone subunit beta 5"},
    {id: "glycoprotein-beta 5"},
    {id: "glycoprotein hormones alpha chain"},
    {id: "anterior pituitary glycoprotein hormones common subunit alpha"},
    {id: "follicle-stimulating hormone alpha chain"},
    {id: "fsh-alpha"},
    {id: "follitropin alpha chain"},
    {id: "luteinizing hormone alpha chain"},
    {id: "lsh-alpha"},
    {id: "lutropin alpha chain"},
    {id: "thyroid-stimulating hormone alpha chain"},
    {id: "tsh-alpha"},
    {id: "thyrotropin alpha chain"},
    {id: "glycoprotein-n-acetylgalactosamine 3-beta-galactosyltransferase 1"},
    {id: "ec 2.4.1.122"},
    {id: "glycosylated lysosomal membrane protein"},
    {id: "lysosomal protein ncu-g1"},
    {id: "glycosyltransferase 6 domain-containing protein 1"},
    {id: "glycylpeptide n-tetradecanoyltransferase"},
    {id: "ec 2.3.1.97"},
    {id: "glycylpeptide n-tetradecanoyltransferase 1"},
    {id: "myristoyl-coa:protein n-myristoyltransferase 1"},
    {id: "nmt 1"},
    {id: "type i n-myristoyltransferase"},
    {id: "peptide n-myristoyltransferase 1"},
    {id: "glypican 1"},
    {id: "gpc1 protein"},
    {id: "glypican-2"},
    {id: "cerebroglycan"},
    {id: "hspg m13"},
    {id: "cleaved into: secreted glypican-2"},
    {id: "golgi snap receptor complex member 1"},
    {id: "28 kda golgi snare protein"},
    {id: "28 kda cis-golgi snare p28"},
    {id: "gos-28"},
    {id: "gos28"},
    {id: "golgi snap receptor complex member 2"},
    {id: "27 kda golgi snare protein"},
    {id: "membrin"},
    {id: "golgi integral membrane protein 4"},
    {id: "golgi integral membrane protein, cis"},
    {id: "gimpc"},
    {id: "golgi phosphoprotein 4"},
    {id: "golgi transport 1b"},
    {id: "golt1b protein"},
    {id: "golgi-associated kinase 1b"},
    {id: "expressed in nerve and epithelium during development"},
    {id: "protein fam198b"},
    {id: "golgi-resident adenosine 3',5'-bisphosphate 3'-phosphatase"},
    {id: "golgi-resident pap phosphatase"},
    {id: "gpapp"},
    {id: "ec 3.1.3.7"},
    {id: "3'(2'), 5'-bisphosphate nucleotidase 2"},
    {id: "inositol monophosphatase domain-containing protein 1"},
    {id: "myo-inositol monophosphatase a3"},
    {id: "phosphoadenosine phosphate 3'-nucleotidase"},
    {id: "golgin subfamily a member 2"},
    {id: "130 kda cis-golgi matrix protein"},
    {id: "gm130"},
    {id: "golgin subfamily a member 5"},
    {id: "golgin-84"},
    {id: "golgin-45"},
    {id: "basic leucine zipper nuclear factor 1"},
    {id: "grainyhead-like transcription factor 2"},
    {id: "grhl2 protein"},
    {id: "granulocyte-macrophage colony-stimulating factor"},
    {id: "gm-csf"},
    {id: "colony-stimulating factor"},
    {id: "csf"},
    {id: "granzyme b"},
    {id: "ec 3.4.21.79"},
    {id: "fragmentin"},
    {id: "natural killer cell protease 1"},
    {id: "rnkp-1"},
    {id: "granzyme c"},
    {id: "gzmb protein"},
    {id: "granzyme-like protein 2"},
    {id: "ec 3.4.21.-"},
    {id: "glp-2"},
    {id: "granzyme-like protein ii"},
    {id: "glp ii"},
    {id: "mast cell protease 10"},
    {id: "rmcp-10"},
    {id: "mast cell protease x"},
    {id: "rmcp-x"},
    {id: "gremlin-1"},
    {id: "cysteine knot superfamily 1, bmp antagonist 1"},
    {id: "down-regulated in mos-transformed cells protein"},
    {id: "growth arrest and dna-damage-inducible 45 alpha"},
    {id: "growth arrest and dna-damage-inducible, alpha"},
    {id: "growth arrest-specific protein 6"},
    {id: "gas-6"},
    {id: "axl receptor tyrosine kinase ligand"},
    {id: "growth-potentiating factor"},
    {id: "gpf"},
    {id: "growth factor inhibitor"},
    {id: "growth factor receptor-bound protein 2"},
    {id: "adapter protein grb2"},
    {id: "protein ash"},
    {id: "sh2/sh3 adapter grb2"},
    {id: "growth hormone d10"},
    {id: "prolactin family 8, subfamily a, member 7"},
    {id: "growth hormone d11"},
    {id: "prolactin family 2, subfamily b, member 1"},
    {id: "growth hormone d12"},
    {id: "prolactin family 8, subfamily a, member 2"},
    {id: "growth hormone d14"},
    {id: "prolactin family 3, subfamily a, member 1"},
    {id: "growth hormone d2"},
    {id: "prolactin family 5, subfamily a, member 2"},
    {id: "prolactin-like protein p"},
    {id: "growth hormone d4"},
    {id: "prolactin family 2, subfamily c, member 1"},
    {id: "proliferin"},
    {id: "growth hormone d6"},
    {id: "similar to placental prolactin-like protein o"},
    {id: "growth hormone d7"},
    {id: "prolactin family 7, subfamily a, member 3"},
    {id: "growth hormone releasing hormone receptor variant 1"},
    {id: "growth hormone releasing hormone receptor variant 3"},
    {id: "growth hormone releasing hormone receptor variant 4"},
    {id: "growth hormone releasing hormone receptor variant 2"},
    {id: "putative growth hormone-releasing hormone receptor-related protein"},
    {id: "growth hormone secretagogue receptor type 1"},
    {id: "ghs-r"},
    {id: "gh-releasing peptide receptor"},
    {id: "ghrp"},
    {id: "ghrelin receptor"},
    {id: "growth hormone-inducible transmembrane protein"},
    {id: "mitochondrial morphology and cristae structure 1"},
    {id: "mics1"},
    {id: "growth/differentiation factor 10"},
    {id: "gdf-10"},
    {id: "bone morphogenetic protein 3b"},
    {id: "bmp-3b"},
    {id: "bone-inducing protein"},
    {id: "bip"},
    {id: "growth/differentiation factor 11"},
    {id: "gdf-11"},
    {id: "bone morphogenetic protein 11"},
    {id: "bmp-11"},
    {id: "growth/differentiation factor 8"},
    {id: "gdf-8"},
    {id: "myostatin"},
    {id: "grpe protein homolog 1, mitochondrial"},
    {id: "mt-grpe#1"},
    {id: "guanidino acid hydrolase, mitochondrial"},
    {id: "ec 3.5.3.-"},
    {id: "arginase, mitochondrial"},
    {id: "guanidinobutyrase, mitochondrial"},
    {id: "ec 3.5.3.7"},
    {id: "guanidinopropionase, mitochondrial"},
    {id: "ec 3.5.3.17"},
    {id: "guanine deaminase"},
    {id: "guanase"},
    {id: "ec 3.5.4.3"},
    {id: "guanine aminohydrolase"},
    {id: "guanine nucleotide binding protein-like 3"},
    {id: "nucleolar"},
    {id: "guanine nucleotide exchange factor dbs"},
    {id: "dbl's big sister"},
    {id: "mcf2-transforming sequence-like protein"},
    {id: "ost oncogene"},
    {id: "guanine nucleotide exchange factor mss4"},
    {id: "rab-interacting factor"},
    {id: "guanine nucleotide-binding protein g"},
    {id: "gamma-9"},
    {id: "transducin beta chain 2"},
    {id: "transducin beta chain 3"},
    {id: "adenylate cyclase-inhibiting g alpha protein"},
    {id: "gustducin alpha-3 chain"},
    {id: "guanine nucleotide-binding protein subunit alpha-12"},
    {id: "g-protein subunit alpha-12"},
    {id: "guanine nucleotide-binding protein subunit alpha-13"},
    {id: "g-protein subunit alpha-13"},
    {id: "guanine nucleotide-binding protein subunit beta-4"},
    {id: "transducin beta chain 4"},
    {id: "guanine nucleotide-binding protein-like 3"},
    {id: "nucleolar gtp-binding protein 3"},
    {id: "nucleostemin"},
    {id: "guanylate cyclase"},
    {id: "ec 4.6.1.2"},
    {id: "guanylate cyclase activator 2b"},
    {id: "cleaved into: uroguanylin (ugn)"},
    {id: "guided entry of tail-anchored proteins factor"},
    {id: "calcium signal-modulating cyclophilin ligand"},
    {id: "guided entry of tail-anchored proteins factor 1"},
    {id: "tail-anchored protein insertion receptor wrb"},
    {id: "tryptophan-rich basic protein"},
    {id: "gypsy retrotransposon integrase-like protein 1"},
    {id: "gin-1"},
    {id: "h2.0-like homeobox protein"},
    {id: "homeobox protein hlx1"},
    {id: "haus augmin-like complex subunit 1"},
    {id: "coiled-coil domain-containing protein 5"},
    {id: "haus augmin-like complex, subunit 7"},
    {id: "uchl5ip protein"},
    {id: "hbs1-like protein"},
    {id: "hcls1-associated protein x-1"},
    {id: "hs1-associating protein x-1"},
    {id: "hax-1"},
    {id: "hs1-binding protein 1"},
    {id: "hsp1bp-1"},
    {id: "hect-type e3 ubiquitin transferase"},
    {id: "hig1 domain family member 1a, mitochondrial"},
    {id: "hypoxia-inducible gene 1 protein"},
    {id: "hmg box-containing protein 1"},
    {id: "hmg box transcription factor 1"},
    {id: "high mobility group box transcription factor 1"},
    {id: "horma domain containing 2"},
    {id: "similar to horma domain containing 2"},
    {id: "hspb1-associated protein 1"},
    {id: "27 kda heat shock protein-associated protein 1"},
    {id: "protein associated with small stress protein 1"},
    {id: "hairy and enhancer of split 6"},
    {id: "drosophila"},
    {id: "hes family bhlh transcription factor 6"},
    {id: "harmonin-binding protein ushbp1"},
    {id: "usher syndrome type-1c protein-binding protein 1"},
    {id: "ush1c-binding protein 1"},
    {id: "hdlbp protein"},
    {id: "high density lipoprotein binding protein"},
    {id: "heart- and neural crest derivatives-expressed protein 1"},
    {id: "extraembryonic tissues, heart, autonomic nervous system and neural crest derivatives-expressed protein 1"},
    {id: "ehand"},
    {id: "heart- and neural crest derivatives-expressed protein 2"},
    {id: "deciduum, heart, autonomic nervous system and neural crest derivatives-expressed protein 2"},
    {id: "dhand"},
    {id: "heat shock 70 kda protein 13"},
    {id: "microsomal stress-70 protein atpase core"},
    {id: "stress-70 protein chaperone microsome-associated 60 kda protein"},
    {id: "heat shock factor 2"},
    {id: "heat shock transcription factor 2"},
    {id: "heat shock protein 105 kda"},
    {id: "heat shock 110 kda protein"},
    {id: "heat shock protein hsp 90-alpha"},
    {id: "heat shock 86 kda"},
    {id: "hsp 86"},
    {id: "hsp86"},
    {id: "heat shock protein hsp 90-beta"},
    {id: "heat shock 84 kda"},
    {id: "hsp 84"},
    {id: "hsp84"},
    {id: "heat shock protein beta-6"},
    {id: "hspb6"},
    {id: "heat shock 20 kda-like protein p20"},
    {id: "hsp20"},
    {id: "heat shock protein beta-8"},
    {id: "hspb8"},
    {id: "alpha-crystallin c chain"},
    {id: "small stress protein-like protein hsp22"},
    {id: "heat shock protein family a"},
    {id: "hsp70"},
    {id: "heat shock protein family b"},
    {id: "small"},
    {id: "heat shock protein family h"},
    {id: "hsp110"},
    {id: "heat shock-related 70 kda protein 2"},
    {id: "heat shock protein 70.2"},
    {id: "testis-specific heat shock protein-related"},
    {id: "hst"},
    {id: "hematopoietic cell signal transducer"},
    {id: "dnax-activation protein 10"},
    {id: "membrane protein dap10"},
    {id: "hematopoietic prostaglandin d synthase"},
    {id: "h-pgds"},
    {id: "ec 5.3.99.2"},
    {id: "gst class-sigma"},
    {id: "glutathione-dependent pgd synthase"},
    {id: "glutathione-requiring prostaglandin d synthase"},
    {id: "prostaglandin-h2 d-isomerase"},
    {id: "heme oxygenase 2"},
    {id: "ho-2"},
    {id: "ec 1.14.14.18"},
    {id: "cleaved into: heme oxygenase 2 soluble form"},
    {id: "heme transporter hrg1"},
    {id: "heme-responsive gene 1 protein homolog"},
    {id: "hrg-1"},
    {id: "solute carrier family 48 member 1"},
    {id: "hemoglobin subunit beta-1"},
    {id: "beta-1-globin"},
    {id: "hemoglobin beta chain, major-form"},
    {id: "hemoglobin beta-1 chain"},
    {id: "hemoglobin subunit beta-2"},
    {id: "beta-2-globin"},
    {id: "hemoglobin beta chain, minor-form"},
    {id: "hemoglobin beta-2 chain"},
    {id: "hemoglobin subunit zeta"},
    {id: "hemoglobin zeta chain"},
    {id: "zeta-globin"},
    {id: "heparan sulfate glucosamine 3-o-sulfotransferase 2"},
    {id: "ec 2.8.2.29"},
    {id: "heparan sulfate d-glucosaminyl 3-o-sulfotransferase 2"},
    {id: "heparan sulfate 3-o-sulfotransferase 2"},
    {id: "heparan-sulfate 6-o-sulfotransferase"},
    {id: "heparin cofactor 2"},
    {id: "heparin cofactor ii"},
    {id: "hc-ii"},
    {id: "protease inhibitor leuserpin-2"},
    {id: "serpin d1"},
    {id: "hepatitis a virus cellular receptor 2 homolog"},
    {id: "havcr-2"},
    {id: "t-cell immunoglobulin and mucin domain-containing protein 3"},
    {id: "timd-3"},
    {id: "t-cell immunoglobulin mucin receptor 3"},
    {id: "tim-3"},
    {id: "t-cell membrane protein 3"},
    {id: "cd antigen cd366"},
    {id: "hepatoma-derived growth factor"},
    {id: "hdgf"},
    {id: "hepatoma-derived growth factor-related protein 3"},
    {id: "hrp-3"},
    {id: "hephaestin"},
    {id: "hereditary hemochromatosis protein homolog"},
    {id: "rt1-cafe"},
    {id: "heterogeneous nuclear ribonucleoprotein a3"},
    {id: "hnrnp a3"},
    {id: "heterogeneous nuclear ribonucleoprotein d-like"},
    {id: "hnrnp d-like"},
    {id: "hnrnp dl"},
    {id: "heterogeneous nuclear ribonucleoprotein d0"},
    {id: "hnrnp d0"},
    {id: "au-rich element rna-binding protein 1"},
    {id: "heterogeneous nuclear ribonucleoprotein f"},
    {id: "hnrnp f"},
    {id: "cleaved into: heterogeneous nuclear ribonucleoprotein f, n-terminally processed"},
    {id: "heterogeneous nuclear ribonucleoprotein h1"},
    {id: "hnrph1 protein"},
    {id: "heterogeneous nuclear ribonucleoprotein h2"},
    {id: "hnrnp h2"},
    {id: "heterogeneous nuclear ribonucleoprotein h'"},
    {id: "hnrnp h'"},
    {id: "cleaved into: heterogeneous nuclear ribonucleoprotein h2, n-terminally processed"},
    {id: "heterogeneous nuclear ribonucleoprotein q"},
    {id: "hnrnp q"},
    {id: "liver regeneration-related protein lrrg077"},
    {id: "synaptotagmin-binding, cytoplasmic rna-interacting protein"},
    {id: "heterogeneous nuclear ribonucleoprotein u"},
    {id: "hnrnp u"},
    {id: "sp120"},
    {id: "scaffold-attachment factor a"},
    {id: "saf-a"},
    {id: "hexose-6-phosphate dehydrogenase"},
    {id: "glucose 1-dehydrogenase"},
    {id: "hexosyltransferase"},
    {id: "high affinity cgmp-specific 3',5'-cyclic phosphodiesterase 9a"},
    {id: "high affinity immunoglobulin epsilon receptor subunit alpha"},
    {id: "fc-epsilon ri-alpha"},
    {id: "fceri"},
    {id: "ige fc receptor subunit alpha"},
    {id: "high affinity nerve growth factor receptor"},
    {id: "neurotrophic tyrosine kinase receptor type 1"},
    {id: "slow nerve growth factor receptor"},
    {id: "p140-trka"},
    {id: "trk-a"},
    {id: "high mobility group protein b2"},
    {id: "high mobility group protein 2"},
    {id: "hmg-2"},
    {id: "high mobility group protein hmg-i/hmg-y"},
    {id: "hmg-i(y)"},
    {id: "high mobility group at-hook protein 1"},
    {id: "high mobility group protein a1"},
    {id: "high sulfur protein b2f"},
    {id: "keratin associated protein 1-5"},
    {id: "high-mobility group"},
    {id: "nonhistone chromosomal"},
    {id: "hippocalcin-like protein 1"},
    {id: "neural visinin-like protein 3"},
    {id: "nvl-3"},
    {id: "nvp-3"},
    {id: "visinin-like protein 3"},
    {id: "vilip-3"},
    {id: "hippocalcin-like protein 4"},
    {id: "neural visinin-like protein 2"},
    {id: "nvl-2"},
    {id: "nvp-2"},
    {id: "visinin-like protein 2"},
    {id: "vilip-2"},
    {id: "hippocampus abundant transcript-like protein 1"},
    {id: "major facilitator superfamily domain-containing 14b"},
    {id: "histamine h2 receptor"},
    {id: "gastric receptor i"},
    {id: "histamine h3 receptor"},
    {id: "h3r"},
    {id: "hh3r"},
    {id: "histamine h3 receptor isoform e"},
    {id: "histamine receptor h3"},
    {id: "histamine n-methyltransferase"},
    {id: "hmt"},
    {id: "ec 2.1.1.8"},
    {id: "histidine ammonia-lyase"},
    {id: "histidase"},
    {id: "ec 4.3.1.3"},
    {id: "histidine protein methyltransferase 1 homolog"},
    {id: "methyltransferase-like protein 18"},
    {id: "histidine--trna ligase, cytoplasmic"},
    {id: "ec 6.1.1.21"},
    {id: "histidyl-trna synthetase"},
    {id: "histo-blood group abo system transferase 1"},
    {id: "blood group a glycosyltransferase 1"},
    {id: "cis-ab transferase 1"},
    {id: "fucosylglycoprotein 3-alpha-galactosyltransferase"},
    {id: "fucosylglycoprotein alpha-n-acetylgalactosaminyltransferase"},
    {id: "glycoprotein-fucosylgalactoside alpha-n-acetylgalactosaminyltransferase"},
    {id: "ec 2.4.1.40"},
    {id: "glycoprotein-fucosylgalactoside alpha-galactosyltransferase"},
    {id: "ec 2.4.1.37"},
    {id: "histo-blood group a transferase"},
    {id: "histo-blood group b transferase"},
    {id: "n-acetylgalactosaminyltransferase a blood group-like enzyme"},
    {id: "nagat 1"},
    {id: "histo-blood group abo system transferase 2"},
    {id: "blood group a glycosyltransferase 2"},
    {id: "cis-ab transferase 2"},
    {id: "nagat 2"},
    {id: "putative blood group a transferase t2"},
    {id: "histocompatibility 2, t region locus 24"},
    {id: "rt1 class i, locus t24, gene 4"},
    {id: "histocompatibility 2, class ii antigen e alpha"},
    {id: "rt1 class ii, locus da"},
    {id: "rt1-da"},
    {id: "histone h1.1"},
    {id: "histone h1a"},
    {id: "histone h1.4"},
    {id: "h1d"},
    {id: "histone h2a.z"},
    {id: "h2a/z"},
    {id: "histone h4"},
    {id: "cleaved into: osteogenic growth peptide (ogp)"},
    {id: "histone acetyltransferase"},
    {id: "ec 2.3.1.48"},
    {id: "histone acetyltransferase kat5"},
    {id: "60 kda tat-interactive protein"},
    {id: "tip60"},
    {id: "histone acetyltransferase htatip"},
    {id: "lysine acetyltransferase 5"},
    {id: "protein 2-hydroxyisobutyryltransferase kat5"},
    {id: "protein acetyltransferase kat5"},
    {id: "protein crotonyltransferase kat5"},
    {id: "histone acetyltransferase kat6a"},
    {id: "moz, ybf2/sas3, sas2 and tip60 protein 3"},
    {id: "myst-3"},
    {id: "monocytic leukemia zinc finger homolog"},
    {id: "monocytic leukemia zinc finger protein"},
    {id: "histone acetyltransferase kat7"},
    {id: "histone acetyltransferase binding to orc1"},
    {id: "lysine acetyltransferase 7"},
    {id: "moz, ybf2/sas3, sas2 and tip60 protein 2"},
    {id: "myst-2"},
    {id: "histone acetyltransferase type b catalytic subunit"},
    {id: "histone acetyltransferase 1"},
    {id: "histone deacetylase"},
    {id: "ec 3.5.1.98"},
    {id: "histone deacetylase 1"},
    {id: "hd1"},
    {id: "protein deacetylase hdac1"},
    {id: "protein decrotonylase hdac1"},
    {id: "histone deacetylase 2"},
    {id: "hd2"},
    {id: "histone deacetylase 8"},
    {id: "hd8"},
    {id: "protein deacetylase hdac8"},
    {id: "protein decrotonylase hdac8"},
    {id: "histone deacetylase complex subunit sap18"},
    {id: "18 kda sin3-associated polypeptide"},
    {id: "histone-arginine methyltransferase carm1"},
    {id: "ec 2.1.1.319"},
    {id: "coactivator-associated arginine methyltransferase 1"},
    {id: "protein arginine n-methyltransferase 4"},
    {id: "histone-arginine methyltransferase mettl23"},
    {id: "methyltransferase-like protein 23"},
    {id: "histone-binding protein rbbp7"},
    {id: "nucleosome-remodeling factor subunit rbap46"},
    {id: "retinoblastoma-binding protein 7"},
    {id: "rbbp-7"},
    {id: "histone-lysine n-methyltransferase"},
    {id: "ec 2.1.1.355"},
    {id: "ec 2.1.1.364"},
    {id: "histone-lysine n-methyltransferase ezh2"},
    {id: "ec 2.1.1.356"},
    {id: "histone-lysine n-methyltransferase kmt5b"},
    {id: "lysine-specific methyltransferase 5b"},
    {id: "suppressor of variegation 4-20 homolog 1"},
    {id: "su(var)4-20 homolog 1"},
    {id: "suv4-20h1"},
    {id: "[histone h4]-n-methyl-l-lysine20 n-methyltransferase kmt5b"},
    {id: "ec 2.1.1.362"},
    {id: "[histone h4]-lysine20 n-methyltransferase kmt5b"},
    {id: "ec 2.1.1.361"},
    {id: "histone-lysine n-methyltransferase kmt5c"},
    {id: "lysine-specific methyltransferase 5c"},
    {id: "suppressor of variegation 4-20 homolog 2"},
    {id: "su(var)4-20 homolog 2"},
    {id: "suv4-20h2"},
    {id: "histone-lysine n-methyltransferase prdm9"},
    {id: "pr domain zinc finger protein 9"},
    {id: "pr domain-containing protein 9"},
    {id: "protein-lysine n-methyltransferase prdm9"},
    {id: "[histone h3]-lysine36 n-trimethyltransferase prdm9"},
    {id: "ec 2.1.1.359"},
    {id: "[histone h3]-lysine4 n-trimethyltransferase prdm9"},
    {id: "ec 2.1.1.354"},
    {id: "[histone h3]-lysine9 n-trimethyltransferase prdm9"},
    {id: "[histone h4]-n-methyl-l-lysine20 n-methyltransferase prdm9"},
    {id: "[histone h4]-lysine20 n-methyltransferase prdm9"},
    {id: "histone-lysine n-methyltransferase setd7"},
    {id: "set domain-containing protein 7"},
    {id: "histone-lysine n-trimethyltransferase smyd5"},
    {id: "ec 2.1.1.372"},
    {id: "set and mynd domain-containing protein 5"},
    {id: "[histone h4]-lysine20 n-trimethyltransferase smyd5"},
    {id: "hla-dmb protein"},
    {id: "rt1 class ii, locus dmb"},
    {id: "rt1-dmb"},
    {id: "holocytochrome c-type synthase"},
    {id: "ec 4.4.1.17"},
    {id: "homeo box b3"},
    {id: "hoxb3 protein"},
    {id: "homeobox and leucine zipper protein homez"},
    {id: "homeodomain leucine zipper-containing factor"},
    {id: "homeobox protein arx"},
    {id: "aristaless-related homeobox"},
    {id: "homeobox protein dbx1"},
    {id: "developing brain homeobox protein 1"},
    {id: "homeobox protein hox-b7"},
    {id: "homeobox protein r1b"},
    {id: "homeobox protein nkx-6.1"},
    {id: "homeobox protein nk-6 homolog a"},
    {id: "homeobox protein cut-like 1"},
    {id: "ccaat displacement protein"},
    {id: "cdp"},
    {id: "cdp2"},
    {id: "homeobox protein cux-1"},
    {id: "cleaved into: cdp/cux p110"},
    {id: "homeobox protein prophet of pit-1"},
    {id: "pituitary-specific homeodomain factor"},
    {id: "homeodomain-interacting protein kinase 4"},
    {id: "homeodomain-only protein"},
    {id: "odd homeobox protein 1"},
    {id: "homer protein homolog 1"},
    {id: "psd-zip45"},
    {id: "vasp/ena-related gene up-regulated during seizure and ltp 1"},
    {id: "vesl-1"},
    {id: "homer protein homolog 2"},
    {id: "homer-2"},
    {id: "cupidin"},
    {id: "vasp/ena-related gene up-regulated during seizure and ltp 2"},
    {id: "vesl-2"},
    {id: "homogentisate 1,2-dioxygenase"},
    {id: "ec 1.13.11.5"},
    {id: "homogentisate oxygenase"},
    {id: "homogentisic acid oxidase"},
    {id: "homogentisicase"},
    {id: "host cell factor 2"},
    {id: "hcf-2"},
    {id: "c2 factor"},
    {id: "hsp70-binding protein 1"},
    {id: "hspbp1"},
    {id: "heat shock protein-binding protein 1"},
    {id: "hsp70-interacting protein 1"},
    {id: "hsp90 co-chaperone cdc37"},
    {id: "hsp90 chaperone protein kinase-targeting subunit"},
    {id: "p50cdc37"},
    {id: "hyaluronan and proteoglycan link protein 1"},
    {id: "cartilage-linking protein 1"},
    {id: "cartilage-link protein"},
    {id: "proteoglycan link protein"},
    {id: "hyaluronidase"},
    {id: "ec 3.2.1.35"},
    {id: "hyaluronidase ph-20"},
    {id: "hyal-ph20"},
    {id: "hyaluronoglucosaminidase ph-20"},
    {id: "sperm adhesion molecule 1"},
    {id: "sperm surface antigen 2b1"},
    {id: "sperm surface protein ph-20"},
    {id: "hydroperoxide isomerase aloxe3"},
    {id: "epidermis-type lipoxygenase 3"},
    {id: "epidermal lox-3"},
    {id: "e-lox-3"},
    {id: "elox-3"},
    {id: "hydroperoxy dehydratase aloxe3"},
    {id: "hydroperoxy icosatetraenoate dehydratase"},
    {id: "ec 4.2.1.152"},
    {id: "hydroperoxy icosatetraenoate isomerase"},
    {id: "ec 5.4.4.7"},
    {id: "hydroxyacid-oxoacid transhydrogenase, mitochondrial"},
    {id: "ec 1.1.99.24"},
    {id: "alcohol dehydrogenase iron-containing protein 1"},
    {id: "hydroxyacyl-coenzyme a dehydrogenase, mitochondrial"},
    {id: "hcdh"},
    {id: "ec 1.1.1.35"},
    {id: "medium and short-chain l-3-hydroxyacyl-coenzyme a dehydrogenase"},
    {id: "short-chain 3-hydroxyacyl-coa dehydrogenase"},
    {id: "hydroxycarboxylic acid receptor 2"},
    {id: "g-protein coupled receptor 109"},
    {id: "g-protein coupled receptor 109a"},
    {id: "g-protein coupled receptor hm74"},
    {id: "niacin receptor 1"},
    {id: "nicotinic acid receptor"},
    {id: "protein puma-g"},
    {id: "hydroxymethylglutaryl-coa lyase, mitochondrial"},
    {id: "3-hydroxy-3-methylglutarate-coa lyase"},
    {id: "hydroxymethylglutaryl-coa synthase"},
    {id: "hmg-coa synthase"},
    {id: "ec 2.3.3.10"},
    {id: "3-hydroxy-3-methylglutaryl coenzyme a synthase"},
    {id: "hydroxymethylglutaryl-coa synthase, cytoplasmic"},
    {id: "hydroxysteroid"},
    {id: "17-beta"},
    {id: "hypothetical loc100361087"},
    {id: "nitric oxide synthase 1 adaptor protein isoform f"},
    {id: "hypothetical loc287798"},
    {id: "mgc95210 protein"},
    {id: "hypothetical loc299330"},
    {id: "testis specific protein 26"},
    {id: "hypothetical loc317456"},
    {id: "lrrgt00013"},
    {id: "hypothetical gene supported by bc079265"},
    {id: "testis expressed 36"},
    {id: "hypoxanthine phosphoribosyltransferase"},
    {id: "ec 2.4.2.8"},
    {id: "ino80 complex subunit e"},
    {id: "coiled-coil domain-containing protein 95"},
    {id: "iq and ubiquitin-like domain-containing protein"},
    {id: "protein trs4"},
    {id: "ist1 homolog"},
    {id: "charged multivesicular body protein 8"},
    {id: "ier5 protein"},
    {id: "immediate early response 5"},
    {id: "ifi44 protein"},
    {id: "interferon-induced protein 44"},
    {id: "ikappab kinase"},
    {id: "ec 2.7.11.10"},
    {id: "ileal sodium/bile acid cotransporter"},
    {id: "apical sodium-dependent bile acid transporter"},
    {id: "asbt"},
    {id: "ileal na(+)/bile acid cotransporter"},
    {id: "ileal sodium-dependent bile acid transporter"},
    {id: "ibat"},
    {id: "isbt"},
    {id: "na(+)-dependent ileal bile acid transporter"},
    {id: "sodium/taurocholate-cotransporting polypeptide, ileal"},
    {id: "solute carrier family 10 member 2"},
    {id: "immunity-related gtpase family m protein"},
    {id: "immunoglobulin"},
    {id: "cd79a"},
    {id: "immunoglobulin superfamily member 11"},
    {id: "igsf11"},
    {id: "immunoglobulin superfamily member 21"},
    {id: "igsf21"},
    {id: "immunoglobulin superfamily member 5"},
    {id: "igsf5"},
    {id: "junctional adhesion molecule 4"},
    {id: "jam-4"},
    {id: "immunoglobulin-binding protein 1"},
    {id: "alpha4 phosphoprotein"},
    {id: "cd79a-binding protein 1"},
    {id: "protein phosphatase 2/4/6 regulatory subunit"},
    {id: "importin subunit alpha-6"},
    {id: "karyopherin subunit alpha-5"},
    {id: "inad-like protein"},
    {id: "inadl protein"},
    {id: "channel-interacting pdz domain-containing protein"},
    {id: "pals1-associated tight junction protein"},
    {id: "protein associated to tight junctions"},
    {id: "inactive 2'-5'-oligoadenylate synthase 1b"},
    {id: "(2-5')oligo(a) synthase 1b"},
    {id: "2-5a synthase 1b"},
    {id: "inactive dipeptidyl peptidase 10"},
    {id: "dipeptidyl peptidase x"},
    {id: "dpp x"},
    {id: "kv4 potassium channel auxiliary subunit"},
    {id: "inactive peptidyl-prolyl cis-trans isomerase fkbp6"},
    {id: "inactive ppiase fkbp6"},
    {id: "36 kda fk506-binding protein"},
    {id: "fk506-binding protein 6"},
    {id: "immunophilin fkbp36"},
    {id: "inactive phospholipid phosphatase 7"},
    {id: "phosphatidic acid phosphatase type 2 domain-containing protein 3"},
    {id: "inactive rhomboid protein"},
    {id: "irhom"},
    {id: "rhomboid family member"},
    {id: "rhomboid veinlet-like protein"},
    {id: "inactive rhomboid protein 1"},
    {id: "irhom1"},
    {id: "rhomboid family member 1"},
    {id: "inactive serine protease 45"},
    {id: "inactive testis serine protease 5"},
    {id: "inactive serine protease 54"},
    {id: "plasma kallikrein-like protein 4"},
    {id: "inactive tyrosine-protein kinase prag1"},
    {id: "peak1-related kinase-activating pseudokinase 1"},
    {id: "pragma of rnd2"},
    {id: "inactive ubiquitin carboxyl-terminal hydrolase 54"},
    {id: "inactive ubiquitin-specific peptidase 54"},
    {id: "indoleamine 2,3-dioxygenase 2"},
    {id: "ido-2"},
    {id: "ec 1.13.11.-"},
    {id: "indoleamine 2,3-dioxygenase-like protein 1"},
    {id: "indoleamine-pyrrole 2,3-dioxygenase-like protein 1"},
    {id: "inducible t-cell costimulator"},
    {id: "activation-inducible lymphocyte immunomediatory molecule"},
    {id: "cd antigen cd278"},
    {id: "inhibin beta a chain"},
    {id: "activin beta-a chain"},
    {id: "inhibin beta c chain"},
    {id: "activin beta-c chain"},
    {id: "inhibitor of nuclear factor kappa-b kinase-interacting protein"},
    {id: "ikbkb-interacting protein"},
    {id: "ikk-interacting protein"},
    {id: "inhibitory synaptic factor 1"},
    {id: "insyn1"},
    {id: "inosine triphosphate pyrophosphatase"},
    {id: "itpase"},
    {id: "inosine triphosphatase"},
    {id: "ec 3.6.1.9"},
    {id: "non-canonical purine ntp pyrophosphatase"},
    {id: "non-standard purine ntp pyrophosphatase"},
    {id: "nucleoside-triphosphate diphosphatase"},
    {id: "nucleoside-triphosphate pyrophosphatase"},
    {id: "ntpase"},
    {id: "inosine-5'-monophosphate dehydrogenase"},
    {id: "imp dehydrogenase"},
    {id: "impd"},
    {id: "impdh"},
    {id: "ec 1.1.1.205"},
    {id: "inositol hexakisphosphate and diphosphoinositol-pentakisphosphate kinase"},
    {id: "ec 2.7.4.24"},
    {id: "inositol hexakisphosphate kinase 2"},
    {id: "insp6 kinase 2"},
    {id: "ec 2.7.4.-"},
    {id: "p(i)-uptake stimulator"},
    {id: "pius"},
    {id: "inositol oxygenase"},
    {id: "ec 1.13.99.1"},
    {id: "aldehyde reductase-like 6"},
    {id: "kidney-specific protein 32"},
    {id: "myo-inositol oxygenase"},
    {id: "mi oxygenase"},
    {id: "renal-specific oxidoreductase"},
    {id: "inositol polyphosphate-5-phosphatase a"},
    {id: "ec 3.1.3.56"},
    {id: "type i inositol 1,4,5-trisphosphate 5-phosphatase"},
    {id: "5ptase"},
    {id: "inositol-1-monophosphatase"},
    {id: "ec 3.1.3.25"},
    {id: "inositol-3-phosphate synthase 1"},
    {id: "ips 1"},
    {id: "ec 5.5.1.4"},
    {id: "myo-inositol 1-phosphate synthase"},
    {id: "mi-1-p synthase"},
    {id: "mip synthase"},
    {id: "inositol-pentakisphosphate 2-kinase"},
    {id: "ec 2.7.1.158"},
    {id: "inositol-tetrakisphosphate 1-kinase"},
    {id: "ec 2.7.1.134"},
    {id: "ec 2.7.1.159"},
    {id: "inositol 1,3,4-trisphosphate 5/6-kinase"},
    {id: "inositol-trisphosphate 3-kinase a"},
    {id: "ec 2.7.1.127"},
    {id: "inositol 1,4,5-trisphosphate 3-kinase a"},
    {id: "ip3 3-kinase a"},
    {id: "ip3k a"},
    {id: "insp 3-kinase a"},
    {id: "inositol-trisphosphate 3-kinase b"},
    {id: "inositol 1,4,5-trisphosphate 3-kinase b"},
    {id: "ip3 3-kinase b"},
    {id: "ip3k b"},
    {id: "insp 3-kinase b"},
    {id: "inositol-trisphosphate 3-kinase c"},
    {id: "inositol 1,4,5-trisphosphate 3-kinase c"},
    {id: "ip3 3-kinase c"},
    {id: "ip3k c"},
    {id: "insp 3-kinase c"},
    {id: "insulin receptor-related protein"},
    {id: "irr"},
    {id: "ir-related receptor"},
    {id: "insulin-1"},
    {id: "cleaved into: insulin-1 b chain; insulin-1 a chain"},
    {id: "insulin-2"},
    {id: "cleaved into: insulin-2 b chain; insulin-2 a chain"},
    {id: "insulin-induced gene 1 protein"},
    {id: "insig-1"},
    {id: "immediate-early protein cl-6"},
    {id: "insulin-induced growth response protein cl-6"},
    {id: "insulin-like 3"},
    {id: "leydig insulin-like peptide"},
    {id: "ley-i-l"},
    {id: "relaxin-like factor"},
    {id: "cleaved into: insulin-like 3 b chain; insulin-like 3 a chain"},
    {id: "insulin-like growth factor 2 mrna-binding protein 1"},
    {id: "igf-ii mrna-binding protein 1"},
    {id: "vickz family member 1"},
    {id: "igf2 mrna-binding protein 1"},
    {id: "imp-1"},
    {id: "b-actin zipcode-binding protein 1"},
    {id: "zbp1"},
    {id: "rzbp-1"},
    {id: "insulin-like growth factor-binding protein 4"},
    {id: "ibp-4"},
    {id: "igf-binding protein 4"},
    {id: "igfbp-4"},
    {id: "insulin-like peptide insl6"},
    {id: "insulin-like peptide 6"},
    {id: "cleaved into: insulin-like peptide insl6 b chain; insulin-like peptide insl6 a chain"},
    {id: "integral membrane protein 2b"},
    {id: "immature bri2"},
    {id: "imbri2"},
    {id: "transmembrane protein bri"},
    {id: "bri"},
    {id: "cleaved into: bri2, membrane form (mature bri2) (mbri2); bri2 intracellular domain (bri2 icd); bri2c, soluble form; bri23 peptide (bri2-23) (abri23) (c-terminal peptide) (p23 peptide)"},
    {id: "integral membrane protein 2c"},
    {id: "cleaved into: ct-bri3"},
    {id: "integrator complex subunit 11"},
    {id: "cleavage and polyadenylation-specific factor 3-like protein"},
    {id: "integrator complex subunit 12"},
    {id: "int12"},
    {id: "phd finger protein 22"},
    {id: "integrator complex subunit 14"},
    {id: "von willebrand factor a domain-containing protein 9"},
    {id: "integrator complex subunit 6"},
    {id: "lrrgt00024"},
    {id: "integrin alpha 3 variant a"},
    {id: "integrin subunit alpha 3"},
    {id: "integrin alpha 3 variant b"},
    {id: "integrin alpha fg-gap repeat containing 2"},
    {id: "itfg2 protein"},
    {id: "integrin beta-3"},
    {id: "platelet membrane glycoprotein iiia"},
    {id: "gpiiia"},
    {id: "integrin-linked protein kinase"},
    {id: "59 kda serine/threonine-protein kinase"},
    {id: "beta-integrin-linked kinase"},
    {id: "ilk-1"},
    {id: "ilk-2"},
    {id: "p59ilk"},
    {id: "inter-alpha-trypsin inhibitor heavy chain h3"},
    {id: "iti heavy chain h3"},
    {id: "iti-hc3"},
    {id: "inter-alpha-inhibitor heavy chain 3"},
    {id: "interferon 1fa"},
    {id: "interferon kappa"},
    {id: "interferon activated gene 204"},
    {id: "myeloid cell nuclear differentiation antigen"},
    {id: "interferon beta"},
    {id: "ifn-beta"},
    {id: "interferon gamma"},
    {id: "ifn-gamma"},
    {id: "interferon stimulated exonuclease 20"},
    {id: "interferon stimulated exonuclease gene 20"},
    {id: "interferon, alpha-inducible protein 27 like 2b"},
    {id: "putative isg12(b) protein"},
    {id: "interferon-gamma-inducible gtpase ifgga3 protein"},
    {id: "similar to interferon-inducible gtpase"},
    {id: "interferon-gamma-inducible gtpase ifgga4 protein"},
    {id: "similar to cdna sequence bc023105"},
    {id: "interferon-gamma-inducible gtpase ifgga5 protein"},
    {id: "interferon-induced transmembrane protein 3"},
    {id: "dispanin subfamily a member 2b"},
    {id: "dspa2b"},
    {id: "interferon-inducible gtpase 5"},
    {id: "immunity-related gtpase cinema 1"},
    {id: "interferon-stimulated 20 kda exonuclease-like 2"},
    {id: "interleukin 20 receptor beta il-20r2"},
    {id: "interleukin 20 receptor subunit beta"},
    {id: "interleukin-1 alpha"},
    {id: "il-1 alpha"},
    {id: "interleukin-1 receptor accessory protein-like 1"},
    {id: "x-linked interleukin-1 receptor accessory protein-like 1"},
    {id: "interleukin-1 receptor-associated kinase 1 binding protein 1"},
    {id: "irak1bp1 protein"},
    {id: "interleukin-1 receptor-associated kinase 4"},
    {id: "interleukin-1 receptor-associated kinase-like 2"},
    {id: "irak-2"},
    {id: "interleukin-10"},
    {id: "il-10"},
    {id: "cytokine synthesis inhibitory factor"},
    {id: "csif"},
    {id: "interleukin-11 receptor subunit alpha"},
    {id: "il-11 receptor subunit alpha"},
    {id: "il-11r subunit alpha"},
    {id: "il-11r-alpha"},
    {id: "il-11ra"},
    {id: "cleaved into: soluble interleukin-11 receptor subunit alpha (sil-11r) (sil-11ra) (sil11ra)"},
    {id: "interleukin-12 subunit alpha"},
    {id: "il-12a"},
    {id: "interleukin-12 subunit beta"},
    {id: "il-12b"},
    {id: "cytotoxic lymphocyte maturation factor 40 kda subunit"},
    {id: "il-12 subunit p40"},
    {id: "interleukin-13"},
    {id: "il-13"},
    {id: "t-cell activation protein p600"},
    {id: "interleukin-15"},
    {id: "il-15"},
    {id: "interleukin-17 receptor e"},
    {id: "il-17 receptor e"},
    {id: "il-17re"},
    {id: "interleukin-17f"},
    {id: "il-17f"},
    {id: "interleukin-18"},
    {id: "il-18"},
    {id: "interleukin-2"},
    {id: "il-2"},
    {id: "t-cell growth factor"},
    {id: "tcgf"},
    {id: "interleukin-2 receptor subunit alpha"},
    {id: "il-2 receptor subunit alpha"},
    {id: "il-2-ra"},
    {id: "il-2r subunit alpha"},
    {id: "il2-ra"},
    {id: "cd antigen cd25"},
    {id: "interleukin-2 receptor subunit beta"},
    {id: "high affinity il-2 receptor subunit beta"},
    {id: "p70-75"},
    {id: "interleukin-21"},
    {id: "il-21"},
    {id: "interleukin-23 subunit alpha"},
    {id: "il-23 subunit alpha"},
    {id: "il-23-a"},
    {id: "interleukin-23 subunit p19"},
    {id: "il-23p19"},
    {id: "interleukin-24"},
    {id: "il-24"},
    {id: "cytokine-like protein mob-5"},
    {id: "interleukin-3"},
    {id: "il-3"},
    {id: "hematopoietic growth factor"},
    {id: "mast cell growth factor"},
    {id: "multipotential colony-stimulating factor"},
    {id: "p-cell-stimulating factor"},
    {id: "interleukin-33"},
    {id: "il-33"},
    {id: "interleukin-4"},
    {id: "il-4"},
    {id: "b-cell igg differentiation factor"},
    {id: "b-cell growth factor 1"},
    {id: "b-cell stimulatory factor 1"},
    {id: "bsf-1"},
    {id: "lymphocyte stimulatory factor 1"},
    {id: "interleukin-5"},
    {id: "il-5"},
    {id: "b-cell growth factor ii"},
    {id: "bcgf-ii"},
    {id: "cytotoxic t-lymphocyte inducer"},
    {id: "eosinophil differentiation factor"},
    {id: "t-cell replacing factor"},
    {id: "trf"},
    {id: "interleukin-6"},
    {id: "il-6"},
    {id: "interleukin-7"},
    {id: "il-7"},
    {id: "intermediate conductance k channel isoform 4b"},
    {id: "potassium calcium-activated channel subfamily n member 4"},
    {id: "intersectin-1"},
    {id: "eh domain and sh3 domain regulator of endocytosis 1"},
    {id: "intracellular hyaluronan-binding protein 4"},
    {id: "ihabp-4"},
    {id: "ihabp4"},
    {id: "hyaluronan-binding protein 4"},
    {id: "ki-1/57 intracellular antigen"},
    {id: "intraflagellar transport protein 56"},
    {id: "tetratricopeptide repeat protein 26"},
    {id: "tpr repeat protein 26"},
    {id: "intraflagellar transport protein 70a2"},
    {id: "tetratricopeptide repeat protein 30a2"},
    {id: "tpr repeat protein 30a2"},
    {id: "intraflagellar transport protein 80 homolog"},
    {id: "wd repeat-containing protein 56"},
    {id: "inward rectifier potassium channel 13"},
    {id: "inward rectifier k(+) channel kir7.1"},
    {id: "potassium channel, inwardly rectifying subfamily j member 13"},
    {id: "inward rectifier potassium channel 2"},
    {id: "inward rectifier k(+) channel kir2.1"},
    {id: "irk-1"},
    {id: "rbl-irk1"},
    {id: "potassium channel, inwardly rectifying subfamily j member 2"},
    {id: "inward rectifier potassium channel kir2.2/irk2"},
    {id: "potassium inwardly-rectifying channel, subfamily j, member 12"},
    {id: "iodotyrosine deiodinase 1"},
    {id: "iyd-1"},
    {id: "ec 1.21.1.1"},
    {id: "iodotyrosine dehalogenase 1"},
    {id: "ion channel tacan"},
    {id: "transmembrane protein 120a"},
    {id: "iron-responsive element-binding protein 2"},
    {id: "ire-bp 2"},
    {id: "iron regulatory protein 2"},
    {id: "irp2"},
    {id: "iron-sulfur cluster assembly 1 homolog, mitochondrial"},
    {id: "hesb-like domain-containing protein 2"},
    {id: "iron-sulfur assembly protein isca"},
    {id: "islet amyloid polypeptide"},
    {id: "amylin"},
    {id: "diabetes-associated peptide"},
    {id: "dap"},
    {id: "isoamyl acetate-hydrolyzing esterase 1 homolog"},
    {id: "hypertrophic agonist-responsive protein b64"},
    {id: "isoaspartyl peptidase/l-asparaginase"},
    {id: "ec 3.4.19.5"},
    {id: "ec 3.5.1.1"},
    {id: "asparaginase-like protein 1"},
    {id: "beta-aspartyl-peptidase"},
    {id: "isoaspartyl dipeptidase"},
    {id: "l-asparagine amidohydrolase"},
    {id: "isocitrate dehydrogenase"},
    {id: "nadp"},
    {id: "ec 1.1.1.42"},
    {id: "nad"},
    {id: "josephin-1"},
    {id: "josephin domain-containing protein 1"},
    {id: "junctional adhesion molecule a"},
    {id: "junctional adhesion molecule 1"},
    {id: "junctional adhesion molecule c"},
    {id: "jam-c"},
    {id: "junctional adhesion molecule 3"},
    {id: "jam-3"},
    {id: "cleaved into: soluble form of jam-c (sjam-c)"},
    {id: "junctophilin-2"},
    {id: "jp-2"},
    {id: "junctophilin type 2"},
    {id: "cleaved into: junctophilin-2 n-terminal fragment (jp2nt)"},
    {id: "jupiter microtubule associated homolog 2"},
    {id: "hematological and neurological expressed 1-like protein"},
    {id: "hn1-like protein"},
    {id: "2p"},
    {id: "potassium two pore domain channel subfamily k member 18"},
    {id: "kat8 regulatory nsl complex subunit 2"},
    {id: "nsl complex protein nsl2"},
    {id: "non-specific lethal 2 homolog"},
    {id: "kh domain-containing rna-binding protein qki"},
    {id: "protein quaking"},
    {id: "kh domain-containing protein 3"},
    {id: "protein filia"},
    {id: "kh domain-containing, rna-binding, signal transduction-associated protein 1"},
    {id: "gap-associated tyrosine phosphoprotein p62"},
    {id: "src-associated in mitosis 68 kda protein"},
    {id: "sam68"},
    {id: "p21 ras gtpase-activating protein-associated p62"},
    {id: "p68"},
    {id: "kh domain-containing, rna-binding, signal transduction-associated protein 2"},
    {id: "sam68-like mammalian protein 1"},
    {id: "slm-1"},
    {id: "rslm-1"},
    {id: "kh homology domain-containing protein 4"},
    {id: "brings lots of money 7"},
    {id: "kif-binding protein"},
    {id: "kif1-binding protein"},
    {id: "krr1 small subunit processome component"},
    {id: "krr-r motif-containing protein 1"},
    {id: "kallikrein 4"},
    {id: "kallikrein b1"},
    {id: "kallikrein-related peptidase 4"},
    {id: "kallikrein f"},
    {id: "kallikrein related-peptidase 9"},
    {id: "kallikrein h"},
    {id: "kallikrein related-peptidase 8"},
    {id: "kallikrein j"},
    {id: "kallikrein related-peptidase 10"},
    {id: "kallikrein-10"},
    {id: "kallikrein k"},
    {id: "kallikrein related-peptidase 12"},
    {id: "kallikrein l"},
    {id: "kallikrein related-peptidase 13"},
    {id: "kallikrein m"},
    {id: "kallikrein related-peptidase 14"},
    {id: "kallikrein related-peptidase 7"},
    {id: "chymotryptic, stratum corneum"},
    {id: "kappa-type opioid receptor"},
    {id: "k-or-1"},
    {id: "kor-1"},
    {id: "katanin p60 atpase-containing subunit a-like 1"},
    {id: "katanin p60 subunit a-like 1"},
    {id: "ec 5.6.1.1"},
    {id: "p60 katanin-like 1"},
    {id: "katanin p60 atpase-containing subunit a-like 2"},
    {id: "katanin p60 subunit a-like 2"},
    {id: "p60 katanin-like 2"},
    {id: "katanin p60 atpase-containing subunit a1"},
    {id: "katanin p60 subunit a1"},
    {id: "p60 katanin"},
    {id: "katanin p80 wd40 repeat-containing subunit b1"},
    {id: "katanin p80 subunit b1"},
    {id: "p80 katanin"},
    {id: "kelch domain containing 1"},
    {id: "klhdc1 protein"},
    {id: "kelch-like family member 29"},
    {id: "klhl29 protein"},
    {id: "kelch-like family member 5"},
    {id: "myocardial ischemic preconditioning associated protein 6"},
    {id: "kelch-like protein 17"},
    {id: "actinfilin"},
    {id: "kelch-like protein 2"},
    {id: "mayven"},
    {id: "kelch-like protein 24"},
    {id: "kainate receptor-interacting protein for glur6"},
    {id: "krip6"},
    {id: "protein dre1"},
    {id: "kelch-like protein 41"},
    {id: "kel-like protein 23"},
    {id: "kelch repeat and btb domain-containing protein 10"},
    {id: "kelch-related protein 1"},
    {id: "sarcosin"},
    {id: "kell metallo-endopeptidase"},
    {id: "kell blood group"},
    {id: "keratin 14"},
    {id: "type i keratin ka11"},
    {id: "type i keratin ka16"},
    {id: "keratin 28"},
    {id: "type i keratin ka41"},
    {id: "keratin 36"},
    {id: "type i hair keratin ka31"},
    {id: "keratin 76"},
    {id: "type ii keratin kb9"},
    {id: "keratin 81"},
    {id: "type ii keratin kb21"},
    {id: "keratin 83"},
    {id: "loc681126 protein"},
    {id: "keratin 84"},
    {id: "type ii keratin kb24"},
    {id: "keratin 85"},
    {id: "type ii keratin kb25"},
    {id: "keratin 86"},
    {id: "type ii keratin kb26"},
    {id: "keratin associated protein 2-1"},
    {id: "keratin associated protein 2-4-like"},
    {id: "keratin, type i cytoskeletal 10"},
    {id: "cytokeratin-10"},
    {id: "ck-10"},
    {id: "keratin-10"},
    {id: "type i keratin ka10"},
    {id: "keratin, type i cytoskeletal 13"},
    {id: "cytokeratin-13"},
    {id: "ck-13"},
    {id: "keratin-13"},
    {id: "k13"},
    {id: "type i keratin ka13"},
    {id: "keratin, type i cytoskeletal 14"},
    {id: "cytokeratin-14"},
    {id: "ck-14"},
    {id: "keratin-14"},
    {id: "k14"},
    {id: "type i keratin ka14"},
    {id: "keratin, type i cytoskeletal 17"},
    {id: "cytokeratin-17"},
    {id: "ck-17"},
    {id: "keratin-17"},
    {id: "k17"},
    {id: "type i keratin ka17"},
    {id: "keratin, type i cytoskeletal 18"},
    {id: "cytokeratin-18"},
    {id: "ck-18"},
    {id: "keratin-18"},
    {id: "k18"},
    {id: "keratin, type i cytoskeletal 19"},
    {id: "cytokeratin-19"},
    {id: "ck-19"},
    {id: "keratin-19"},
    {id: "k19"},
    {id: "type i keratin ka19"},
    {id: "keratin, type i cytoskeletal 24"},
    {id: "cytokeratin-24"},
    {id: "ck-24"},
    {id: "keratin-24"},
    {id: "k24"},
    {id: "type i keratin-24"},
    {id: "keratin, type i cytoskeletal 25"},
    {id: "cytokeratin-25"},
    {id: "ck-25"},
    {id: "keratin-25"},
    {id: "k25"},
    {id: "keratin-25a"},
    {id: "k25a"},
    {id: "type i inner root sheath-specific keratin-k25irs1"},
    {id: "type i keratin ka38"},
    {id: "keratin, type i cytoskeletal 27"},
    {id: "cytokeratin-27"},
    {id: "ck-27"},
    {id: "keratin-27"},
    {id: "k27"},
    {id: "type i inner root sheath-specific keratin-k25irs3"},
    {id: "type i keratin ka40"},
    {id: "keratin, type i cytoskeletal 40"},
    {id: "cytokeratin-40"},
    {id: "ck-40"},
    {id: "keratin-40"},
    {id: "k40"},
    {id: "type i hair keratin ka36"},
    {id: "keratin, type i cytoskeletal 42"},
    {id: "cytokeratin-42"},
    {id: "ck-42"},
    {id: "keratin-42"},
    {id: "k42"},
    {id: "type i keratin ka22"},
    {id: "keratin, type ii cytoskeletal 1"},
    {id: "cytokeratin-1"},
    {id: "keratin-1"},
    {id: "type-ii keratin kb1"},
    {id: "keratin, type ii cytoskeletal 5"},
    {id: "cytokeratin-5"},
    {id: "ck-5"},
    {id: "keratin-5"},
    {id: "k5"},
    {id: "type-ii keratin kb5"},
    {id: "keratin, type ii cytoskeletal 6a"},
    {id: "cytokeratin-6a"},
    {id: "ck-6a"},
    {id: "keratin-6a"},
    {id: "k6a"},
    {id: "type-ii keratin kb6"},
    {id: "keratin, type ii cytoskeletal 72"},
    {id: "cytokeratin-72"},
    {id: "ck-72"},
    {id: "keratin-72"},
    {id: "k72"},
    {id: "type ii inner root sheath-specific keratin-k6irs2"},
    {id: "type-ii keratin kb35"},
    {id: "keratin, type ii cytoskeletal 75"},
    {id: "cytokeratin-75"},
    {id: "ck-75"},
    {id: "keratin-6 hair follicle"},
    {id: "keratin-75"},
    {id: "k75"},
    {id: "type ii keratin-k6hf"},
    {id: "type-ii keratin kb18"},
    {id: "keratinocyte-associated protein 2"},
    {id: "dolichyl-diphosphooligosaccharide--protein glycosyltransferase subunit kcp2"},
    {id: "keratinocyte-associated protein 3"},
    {id: "kcp-3"},
    {id: "ketimine reductase mu-crystallin"},
    {id: "ec 1.5.1.25"},
    {id: "cdk108"},
    {id: "nadp-regulated thyroid-hormone-binding protein"},
    {id: "kidney androgen-regulated protein"},
    {id: "arp"},
    {id: "kap"},
    {id: "kidney mitochondrial carrier protein 1"},
    {id: "solute carrier family 25 member 30"},
    {id: "killer cell immunoglobulin-like receptor 3dl1"},
    {id: "cd antigen cd158e"},
    {id: "killer cell lectin-like receptor subfamily b member 1"},
    {id: "immunoreceptor nkr-p1e"},
    {id: "killer cell lectin-like receptor subfamily b member 1g"},
    {id: "natural killer cell surface protein nkr-p1g"},
    {id: "natural killer lectin-like receptor 1e"},
    {id: "killer cell lectin-like receptor subfamily b member 1f"},
    {id: "natural killer cell surface protein nkr-p1f"},
    {id: "killer cell lectin-like receptor, subfamily a, member 2"},
    {id: "klra2 protein"},
    {id: "kin of irre-like protein 1"},
    {id: "kin of irregular chiasm-like protein 1"},
    {id: "nephrin-like protein 1"},
    {id: "kinase"},
    {id: "kinase d-interacting substrate 220"},
    {id: "kinase d-interacting substrate 220 isoform 7"},
    {id: "kinesin heavy chain isoform 5c"},
    {id: "kinesin heavy chain neuron-specific 2"},
    {id: "kinesin-1"},
    {id: "kinesin light chain 1"},
    {id: "klc 1"},
    {id: "kinesin light chain 3"},
    {id: "kinesin light chain klct"},
    {id: "kinesin light chain 4"},
    {id: "klc 4"},
    {id: "kinesin-like protein 8"},
    {id: "kinesin-like protein kif28p"},
    {id: "kinesin-like protein 6"},
    {id: "kinetochore protein nuf2"},
    {id: "cell division cycle-associated protein 1"},
    {id: "kit ligand"},
    {id: "hematopoietic growth factor kl"},
    {id: "mgf"},
    {id: "stem cell factor"},
    {id: "scf"},
    {id: "c-kit ligand"},
    {id: "cleaved into: soluble kit ligand (skitlg)"},
    {id: "klotho"},
    {id: "ec 3.2.1.31"},
    {id: "cleaved into: klotho peptide"},
    {id: "kremen protein"},
    {id: "kringle-containing protein marking the eye and the nose"},
    {id: "krueppel-like factor 10"},
    {id: "transforming growth factor-beta-inducible early growth response protein 1"},
    {id: "tgfb-inducible early growth response protein 1"},
    {id: "tieg-1"},
    {id: "zinc finger transcription factor homolog cpg20"},
    {id: "krueppel-like factor 2"},
    {id: "lung krueppel-like factor"},
    {id: "kruppel like factor 4"},
    {id: "kruppel-like factor 4 (gut)"},
    {id: "kv channel-interacting protein 1"},
    {id: "kchip1"},
    {id: "a-type potassium channel modulatory protein 1"},
    {id: "potassium channel-interacting protein 1"},
    {id: "potassium voltage-gated channel interacting protein 1"},
    {id: "kv channel-interacting protein 2"},
    {id: "kchip2"},
    {id: "a-type potassium channel modulatory protein 2"},
    {id: "potassium channel-interacting protein 2"},
    {id: "kv channel-interacting protein 2x"},
    {id: "potassium voltage-gated channel interacting protein 2"},
    {id: "kv channel-interacting protein 4"},
    {id: "kchip4"},
    {id: "a-type potassium channel modulatory protein 4"},
    {id: "potassium channel-interacting protein 4"},
    {id: "kxdl motif-containing protein 1"},
    {id: "ubiquitin a-52 residue ribosomal protein fusion product 1"},
    {id: "ubiquitin-60s ribosomal protein l40"},
    {id: "kynureninase"},
    {id: "ec 3.7.1.3"},
    {id: "l-kynurenine hydrolase"},
    {id: "kynurenine 3-monooxygenase"},
    {id: "ec 1.14.13.9"},
    {id: "kynurenine 3-hydroxylase"},
    {id: "kynurenine formamidase"},
    {id: "kfa"},
    {id: "kfase"},
    {id: "ec 3.5.1.9"},
    {id: "arylformamidase"},
    {id: "n-formylkynurenine formamidase"},
    {id: "fkf"},
    {id: "l-fucosyltransferase"},
    {id: "l-aminoadipate-semialdehyde dehydrogenase-phosphopantetheinyl transferase"},
    {id: "ec 2.7.8.7"},
    {id: "4'-phosphopantetheinyl transferase"},
    {id: "alpha-aminoadipic semialdehyde dehydrogenase-phosphopantetheinyl transferase"},
    {id: "l-gulonolactone oxidase"},
    {id: "lgo"},
    {id: "ec 1.1.3.8"},
    {id: "l-lactate dehydrogenase"},
    {id: "ec 1.1.1.27"},
    {id: "l-methionine"},
    {id: "ec 1.8.4.14"},
    {id: "l-serine ammonia-lyase"},
    {id: "ec 4.3.1.17"},
    {id: "las1-like"},
    {id: "letm1 domain containing 1"},
    {id: "letmd1 protein"},
    {id: "letm1 domain-containing protein letm2, mitochondrial"},
    {id: "letm1 and ef-hand domain-containing protein 2"},
    {id: "leucine zipper-ef-hand-containing transmembrane protein 1-like"},
    {id: "lhfpl tetraspan subfamily member 1 protein"},
    {id: "lipoma hmgic fusion partner-like 1 protein"},
    {id: "lhfpl tetraspan subfamily member 5 protein"},
    {id: "lipoma hmgic fusion partner-like 5 protein"},
    {id: "tetraspan membrane protein of hair cell stereocilia"},
    {id: "lhfpl tetraspan subfamily member 6 protein"},
    {id: "lipoma hmgic fusion partner"},
    {id: "lim and sh3 domain protein 1"},
    {id: "lasp-1"},
    {id: "lim domain and actin-binding protein 1"},
    {id: "epithelial protein lost in neoplasm"},
    {id: "lim domain kinase 2"},
    {id: "limk-2"},
    {id: "lim/homeobox protein lhx1"},
    {id: "lim homeobox protein 1"},
    {id: "homeobox protein lim-1"},
    {id: "rlim"},
    {id: "lim/homeobox protein lhx5"},
    {id: "lim homeobox protein 5"},
    {id: "homeobox protein lim-2"},
    {id: "lim/homeobox protein lhx9"},
    {id: "lim homeobox protein 9"},
    {id: "loc100125368 protein"},
    {id: "zinc finger protein loc100125368"},
    {id: "loc100125371 protein"},
    {id: "taurine up-regulated 1"},
    {id: "loc100158225 protein"},
    {id: "tubulin epsilon and delta complex 2"},
    {id: "loc361985 protein"},
    {id: "similar to nice-3"},
    {id: "loc498604 protein"},
    {id: "niban apoptosis regulator 3"},
    {id: "loc501126 protein"},
    {id: "von willebrand factor a domain containing 3b"},
    {id: "loc652955 protein"},
    {id: "ring finger protein 130"},
    {id: "loc680200 protein"},
    {id: "similar to zinc finger protein 455"},
    {id: "loc681092 protein"},
    {id: "pou class 6 homeobox 2"},
    {id: "loc681908 protein"},
    {id: "sterile alpha motif domain containing 15"},
    {id: "loc682033 protein"},
    {id: "protein phosphatase 2, regulatory subunit b'', beta"},
    {id: "loc683469 protein"},
    {id: "loc683983 protein"},
    {id: "nucleoporin 43"},
    {id: "loc686841 protein"},
    {id: "testis expressed 33"},
    {id: "loc687105 protein"},
    {id: "rho guanine nucleotide exchange factor 16"},
    {id: "loc691036 protein"},
    {id: "set binding factor 2"},
    {id: "loc691995 protein"},
    {id: "riken cdna 6330403k07 gene"},
    {id: "lrp chaperone mesd"},
    {id: "ldlr chaperone mesd"},
    {id: "mesoderm development candidate 2"},
    {id: "mesoderm development protein"},
    {id: "lrrg00118"},
    {id: "wd repeat domain 75"},
    {id: "lrrg00120"},
    {id: "transmembrane p24 trafficking protein 5"},
    {id: "lrrg00133"},
    {id: "protein red"},
    {id: "lrrgt00010"},
    {id: "lrrgt00010-like"},
    {id: "lrrgt00011"},
    {id: "phd finger protein 14"},
    {id: "lrrgt00016"},
    {id: "lon peptidase 2, peroxisomal"},
    {id: "lrrgt00031"},
    {id: "mitochondrial ribosomal protein s28"},
    {id: "lrrgt00040"},
    {id: "smg6 nonsense mediated mrna decay factor"},
    {id: "lrrgt00043"},
    {id: "rab11 family interacting protein 3"},
    {id: "lrrgt00053"},
    {id: "lrrgt00053-like"},
    {id: "lrrgt00058"},
    {id: "slx1 homolog b, structure-specific endonuclease subunit"},
    {id: "lrrgt00069"},
    {id: "translocation associated membrane protein 1"},
    {id: "lrrgt00070"},
    {id: "tho complex 1"},
    {id: "lrrgt00074"},
    {id: "phosphodiesterase 12"},
    {id: "lrrgt00077"},
    {id: "similar to riken cdna 1300017j02"},
    {id: "lrrgt00083"},
    {id: "similar to glutathione s-transferase alpha-4"},
    {id: "lrrgt00093"},
    {id: "splicing factor 3a, subunit 3"},
    {id: "lrrgt00101"},
    {id: "transmembrane protein 106b"},
    {id: "lrrgt00103"},
    {id: "uncharacterized loc100912266"},
    {id: "lrrgt00105"},
    {id: "pdz domain containing 9"},
    {id: "lrrgt00106"},
    {id: "lrrgt00106-like"},
    {id: "lrrgt00107"},
    {id: "ribosomal protein l28-like"},
    {id: "lrrgt00111"},
    {id: "retinol dehydrogenase 11"},
    {id: "lrrgt00112"},
    {id: "lrrgt00112-like"},
    {id: "lrrgt00155"},
    {id: "lrrgt00155-like"},
    {id: "lrrgt00172"},
    {id: "lateral signaling target protein 2 homolog"},
    {id: "lsm14a mrna processing body assembly factor"},
    {id: "lsm14a protein"},
    {id: "la-related protein 7"},
    {id: "la ribonucleoprotein domain family member 7"},
    {id: "lactase"},
    {id: "lct protein"},
    {id: "lactate dehydrogenase c"},
    {id: "lactate dehydrogenase c isoform 1 (ec 1.1.1.27)"},
    {id: "lactate dehydrogenase c variant 1"},
    {id: "lactate dehydrogenase c variant 3"},
    {id: "lactate dehydrogenase c variant 32"},
    {id: "lactosylceramide 1,3-n-acetyl-beta-d-glucosaminyltransferase"},
    {id: "ec 2.4.1.206"},
    {id: "lactotriaosylceramide synthase"},
    {id: "lc(3)cer synthase"},
    {id: "lc3 synthase"},
    {id: "udp-glcnac:beta-gal beta-1,3-n-acetylglucosaminyltransferase 5"},
    {id: "bgnt-5"},
    {id: "beta-1,3-gn-t5"},
    {id: "beta-1,3-n-acetylglucosaminyltransferase 5"},
    {id: "beta3gn-t5"},
    {id: "lactosylceramide 4-alpha-galactosyltransferase"},
    {id: "ec 2.4.1.228"},
    {id: "alpha-1,4-n-acetylglucosaminyltransferase"},
    {id: "alpha-1,4-galactosyltransferase"},
    {id: "alpha4gal-t1"},
    {id: "globotriaosylceramide synthase"},
    {id: "gb3 synthase"},
    {id: "udp-galactose:beta-d-galactosyl-beta1-r 4-alpha-d-galactosyltransferase"},
    {id: "lactosylceramide alpha-2,3-sialyltransferase"},
    {id: "ec 2.4.3.9"},
    {id: "cmp-neuac:lactosylceramide alpha-2,3-sialyltransferase"},
    {id: "ganglioside gm3 synthase"},
    {id: "st3gal v"},
    {id: "st3galv"},
    {id: "sialyltransferase 9"},
    {id: "lactoylglutathione lyase"},
    {id: "ec 4.4.1.5"},
    {id: "aldoketomutase"},
    {id: "glyoxalase i"},
    {id: "glx i"},
    {id: "ketone-aldehyde mutase"},
    {id: "methylglyoxalase"},
    {id: "s-d-lactoylglutathione methylglyoxal lyase"},
    {id: "ladinin-1"},
    {id: "lad-1"},
    {id: "laforin"},
    {id: "glucan phosphatase"},
    {id: "lafora ptpase"},
    {id: "lafptpase"},
    {id: "lambda-crystallin homolog"},
    {id: "ec 1.1.1.45"},
    {id: "l-gulonate 3-dehydrogenase"},
    {id: "gul3dh"},
    {id: "lamin tail domain containing 2"},
    {id: "rgd1307439 protein"},
    {id: "laminin subunit alpha 1"},
    {id: "laminin, alpha 1"},
    {id: "lanc lantibiotic synthetase component c-like 2"},
    {id: "bacterial"},
    {id: "lanc like 2"},
    {id: "lanc lantibiotic synthetase component c-like 3"},
    {id: "large neutral amino acids transporter small subunit 1"},
    {id: "4f2 light chain"},
    {id: "4f2 lc"},
    {id: "4f2lc"},
    {id: "integral membrane protein e16"},
    {id: "protein ta1"},
    {id: "l-type amino acid transporter 1"},
    {id: "lat-1"},
    {id: "solute carrier family 7 member 5"},
    {id: "large neutral amino acids transporter small subunit 2"},
    {id: "l-type amino acid transporter 2"},
    {id: "solute carrier family 7 member 8"},
    {id: "large proline-rich protein bag6"},
    {id: "bcl2-associated athanogene 6"},
    {id: "hla-b-associated transcript 3"},
    {id: "large ribosomal subunit protein p1"},
    {id: "60s acidic ribosomal protein p1"},
    {id: "large ribosomal subunit protein p2"},
    {id: "60s acidic ribosomal protein p2"},
    {id: "large ribosomal subunit protein bl17m"},
    {id: "39s ribosomal protein l17, mitochondrial"},
    {id: "l17mt"},
    {id: "mrp-l17"},
    {id: "large ribosomal subunit protein bl36m"},
    {id: "39s ribosomal protein l36, mitochondrial"},
    {id: "l36mt"},
    {id: "mrp-l36"},
    {id: "large ribosomal subunit protein el13"},
    {id: "60s ribosomal protein l13"},
    {id: "large ribosomal subunit protein el15"},
    {id: "60s ribosomal protein l15"},
    {id: "large ribosomal subunit protein el18"},
    {id: "60s ribosomal protein l18"},
    {id: "large ribosomal subunit protein el19"},
    {id: "60s ribosomal protein l19"},
    {id: "large ribosomal subunit protein el20"},
    {id: "60s ribosomal protein l18a"},
    {id: "large ribosomal subunit protein el24"},
    {id: "60s ribosomal protein l24"},
    {id: "l30"},
    {id: "large ribosomal subunit protein el27"},
    {id: "60s ribosomal protein l27"},
    {id: "large ribosomal subunit protein el30"},
    {id: "60s ribosomal protein l30"},
    {id: "large ribosomal subunit protein el31"},
    {id: "60s ribosomal protein l31"},
    {id: "large ribosomal subunit protein el32"},
    {id: "60s ribosomal protein l32"},
    {id: "large ribosomal subunit protein el37"},
    {id: "60s ribosomal protein l37"},
    {id: "large ribosomal subunit protein el38"},
    {id: "60s ribosomal protein l38"},
    {id: "large ribosomal subunit protein el42"},
    {id: "60s ribosomal protein l36a"},
    {id: "60s ribosomal protein l44"},
    {id: "large ribosomal subunit protein el8"},
    {id: "60s ribosomal protein l7a"},
    {id: "large ribosomal subunit protein ml37"},
    {id: "39s ribosomal protein l37, mitochondrial"},
    {id: "l37mt"},
    {id: "mrp-l37"},
    {id: "large ribosomal subunit protein ml38"},
    {id: "39s ribosomal protein l38, mitochondrial"},
    {id: "l38mt"},
    {id: "mrp-l38"},
    {id: "large ribosomal subunit protein ml40"},
    {id: "39s ribosomal protein l40, mitochondrial"},
    {id: "l40mt"},
    {id: "mrp-l40"},
    {id: "large ribosomal subunit protein ml41"},
    {id: "39s ribosomal protein l41, mitochondrial"},
    {id: "l41mt"},
    {id: "mrp-l41"},
    {id: "large ribosomal subunit protein ml42"},
    {id: "28s ribosomal protein l42, mitochondrial"},
    {id: "l42mt"},
    {id: "mrp-l42"},
    {id: "28s ribosomal protein s32, mitochondrial"},
    {id: "mrp-s32"},
    {id: "s32mt"},
    {id: "large ribosomal subunit protein ml46"},
    {id: "39s ribosomal protein l46, mitochondrial"},
    {id: "l46mt"},
    {id: "mrp-l46"},
    {id: "large ribosomal subunit protein ml64"},
    {id: "39s ribosomal protein l59, mitochondrial"},
    {id: "mrp-l59"},
    {id: "growth arrest and dna damage-inducible proteins-interacting protein 1"},
    {id: "large ribosomal subunit protein ul1"},
    {id: "60s ribosomal protein l10a"},
    {id: "large ribosomal subunit protein ul10"},
    {id: "60s acidic ribosomal protein p0"},
    {id: "60s ribosomal protein l10e"},
    {id: "large ribosomal subunit protein ul10m"},
    {id: "39s ribosomal protein l10, mitochondrial"},
    {id: "l10mt"},
    {id: "mrp-l10"},
    {id: "mrp-l8"},
    {id: "large ribosomal subunit protein ul11"},
    {id: "60s ribosomal protein l12"},
    {id: "large ribosomal subunit protein ul14m"},
    {id: "39s ribosomal protein l14, mitochondrial"},
    {id: "l14mt"},
    {id: "mrp-l14"},
    {id: "39s ribosomal protein l32, mitochondrial"},
    {id: "l32mt"},
    {id: "mrp-l32"},
    {id: "large ribosomal subunit protein ul16"},
    {id: "60s ribosomal protein l10"},
    {id: "ribosomal protein l10"},
    {id: "large ribosomal subunit protein ul16m"},
    {id: "39s ribosomal protein l16, mitochondrial"},
    {id: "l16mt"},
    {id: "mrp-l16"},
    {id: "large ribosomal subunit protein ul18"},
    {id: "60s ribosomal protein l5"},
    {id: "large ribosomal subunit protein ul2"},
    {id: "60s ribosomal protein l8"},
    {id: "large ribosomal subunit protein ul22"},
    {id: "60s ribosomal protein l17"},
    {id: "amino acid starvation-induced protein"},
    {id: "l23"},
    {id: "large ribosomal subunit protein ul24m"},
    {id: "39s ribosomal protein l24, mitochondrial"},
    {id: "l24mt"},
    {id: "mrp-l24"},
    {id: "large ribosomal subunit protein ul2m"},
    {id: "39s ribosomal protein l2, mitochondrial"},
    {id: "l2mt"},
    {id: "mrp-l2"},
    {id: "large ribosomal subunit protein ul30m"},
    {id: "39s ribosomal protein l30, mitochondrial"},
    {id: "l30mt"},
    {id: "mrp-l30"},
    {id: "large ribosomal subunit protein ul6"},
    {id: "60s ribosomal protein l9"},
    {id: "large subunit gtpase 1 homolog"},
    {id: "large-conductance ca2+-activated k+ channel beta2 subunit"},
    {id: "potassium calcium-activated channel subfamily m regulatory beta subunit 2"},
    {id: "latexin"},
    {id: "endogenous carboxypeptidase inhibitor"},
    {id: "eci"},
    {id: "tissue carboxypeptidase inhibitor"},
    {id: "tci"},
    {id: "lathosterol oxidase"},
    {id: "ec 1.14.19.20"},
    {id: "c-5 sterol desaturase"},
    {id: "delta(7)-sterol 5-desaturase"},
    {id: "delta(7)-sterol c5(6)-desaturase"},
    {id: "lathosterol 5-desaturase"},
    {id: "sterol-c5-desaturase"},
    {id: "lebercilin"},
    {id: "leber congenital amaurosis 5 protein homolog"},
    {id: "lecithin retinol acyltransferase"},
    {id: "ec 2.3.1.135"},
    {id: "phosphatidylcholine--retinol o-acyltransferase"},
    {id: "lectin, mannose-binding 2-like"},
    {id: "lman2l protein"},
    {id: "legumain"},
    {id: "ec 3.4.22.34"},
    {id: "asparaginyl endopeptidase"},
    {id: "protease, cysteine 1"},
    {id: "leishmanolysin-like peptidase"},
    {id: "lengsin"},
    {id: "glutamate-ammonia ligase domain-containing protein 1"},
    {id: "lens glutamine synthase-like"},
    {id: "lens fiber membrane intrinsic protein"},
    {id: "mp17"},
    {id: "mp18"},
    {id: "mp19"},
    {id: "mp20"},
    {id: "leptin receptor overlapping transcript-like 1"},
    {id: "endospanin-2"},
    {id: "lethal"},
    {id: "h-l(3)mbt"},
    {id: "h-l(3)mbt protein"},
    {id: "l(3)mbt-like"},
    {id: "l(3)mbt protein homolog"},
    {id: "l(3)mbt-like protein 2"},
    {id: "leucine carboxyl methyltransferase 1"},
    {id: "ec 2.1.1.233"},
    {id: "leucine repeat adapter protein 25"},
    {id: "mammary tumor virus receptor 2"},
    {id: "leucine rich repeat containing 24"},
    {id: "rgd1308720 protein"},
    {id: "leucine rich repeat containing 27"},
    {id: "lrrc27 protein"},
    {id: "leucine rich repeats and calponin homology domain containing 1"},
    {id: "lrch1 protein"},
    {id: "leucine zipper putative tumor suppressor 1"},
    {id: "psd-zip70"},
    {id: "leucine zipper putative tumor suppressor 2"},
    {id: "protein lapser1"},
    {id: "leucine-rich repeat lgi family, member 2"},
    {id: "lgi2 protein"},
    {id: "leucine-rich repeat and iq domain-containing protein 3"},
    {id: "leucine-rich repeat-containing protein 44"},
    {id: "leucine-rich repeat and wd repeat-containing protein 1"},
    {id: "origin recognition complex-associated protein"},
    {id: "leucine-rich repeat and fibronectin type iii domain-containing protein 1"},
    {id: "synaptic adhesion-like molecule 2"},
    {id: "leucine-rich repeat and fibronectin type-iii domain-containing protein 3"},
    {id: "synaptic adhesion-like molecule 4"},
    {id: "leucine-rich repeat flightless-interacting protein 2"},
    {id: "lrr flii-interacting protein 2"},
    {id: "leucine-rich repeat neuronal protein 1"},
    {id: "neuronal leucine-rich repeat protein 1"},
    {id: "nlrr-1"},
    {id: "leucine-rich repeat protein shoc-2"},
    {id: "protein soc-2 homolog"},
    {id: "protein sur-8 homolog"},
    {id: "leucine-rich repeat transmembrane neuronal protein 2"},
    {id: "leucine-rich repeat neuronal 2 protein"},
    {id: "leucine-rich repeat transmembrane protein flrt2"},
    {id: "fibronectin-like domain-containing leucine-rich transmembrane protein 2"},
    {id: "leucine-rich repeat transmembrane protein flrt3"},
    {id: "fibronectin-like domain-containing leucine-rich transmembrane protein 3"},
    {id: "leucine-rich repeat, immunoglobulin-like domain and transmembrane domain-containing protein 1"},
    {id: "leucine-rich repeat-containing protein 21"},
    {id: "photoreceptor-associated lrr superfamily protein"},
    {id: "retina-specific protein pal"},
    {id: "leucine-rich repeat-containing g protein-coupled receptor 8"},
    {id: "relaxin family peptide receptor 2"},
    {id: "leucine-rich repeat-containing g-protein coupled receptor 4"},
    {id: "g-protein coupled receptor 48"},
    {id: "leucine-rich repeat-containing protein 26"},
    {id: "bk channel auxiliary gamma subunit lrrc26"},
    {id: "leucine-rich repeat-containing protein 39"},
    {id: "myosin-interacting m-band-associated stress-responsive protein"},
    {id: "myomasp"},
    {id: "leucine-rich repeat-containing protein 4"},
    {id: "netrin-g2 ligand"},
    {id: "ngl-2"},
    {id: "leucine-rich repeat-containing protein 4b"},
    {id: "netrin-g3 ligand"},
    {id: "ngl-3"},
    {id: "leucine-rich repeat-containing protein 59"},
    {id: "protein p34"},
    {id: "cleaved into: leucine-rich repeat-containing protein 59, n-terminally processed"},
    {id: "leucine-rich repeat-containing protein 7"},
    {id: "densin-180"},
    {id: "densin"},
    {id: "protein lap1"},
    {id: "leucine-rich repeat-containing protein 73"},
    {id: "nod3-like protein"},
    {id: "leucine-rich repeat-containing protein 74a"},
    {id: "leucine-rich repeat-containing protein 74"},
    {id: "leucine-rich repeats and calponin homology"},
    {id: "ch"},
    {id: "leucyl-cystinyl aminopeptidase"},
    {id: "cystinyl aminopeptidase"},
    {id: "ec 3.4.11.3"},
    {id: "gp160"},
    {id: "insulin-regulated membrane aminopeptidase"},
    {id: "insulin-responsive aminopeptidase"},
    {id: "irap"},
    {id: "oxytocinase"},
    {id: "otase"},
    {id: "placental leucine aminopeptidase"},
    {id: "p-lap"},
    {id: "vesicle protein of 165 kda"},
    {id: "vp165"},
    {id: "leukocyte elastase inhibitor a"},
    {id: "serine protease inhibitor eia"},
    {id: "serpin b1a"},
    {id: "leukocyte immunoglobulin-like receptor, subfamily b"},
    {id: "with tm and itim domains"},
    {id: "leukocyte surface antigen cd47"},
    {id: "integrin-associated protein"},
    {id: "leukocyte surface antigen cd53"},
    {id: "cell surface glycoprotein cd53"},
    {id: "leukocyte antigen mrc ox-44"},
    {id: "cd antigen cd53"},
    {id: "leukocyte-associated immunoglobulin-like receptor 1"},
    {id: "lair-1"},
    {id: "rlair1"},
    {id: "cd antigen cd305"},
    {id: "leukotriene a-4 hydrolase"},
    {id: "lta-4 hydrolase"},
    {id: "ec 3.3.2.6"},
    {id: "leukotriene a(4) hydrolase"},
    {id: "tripeptide aminopeptidase lta4h"},
    {id: "ec 3.4.11.4"},
    {id: "leukotriene b4 receptor 2"},
    {id: "ltb4-r 2"},
    {id: "ltb4-r2"},
    {id: "ltb4 receptor julf2"},
    {id: "limbic system-associated membrane protein"},
    {id: "lsamp"},
    {id: "linker for activation of t-cells family member 1"},
    {id: "36 kda phospho-tyrosine adapter protein"},
    {id: "pp36"},
    {id: "p36-38"},
    {id: "lipid droplet-associated hydrolase"},
    {id: "lipid droplet-associated serine hydrolase"},
    {id: "lipid transferase cidec"},
    {id: "cell death-inducing dffa-like effector protein c"},
    {id: "fat-specific protein fsp27"},
    {id: "lipolysis-stimulated lipoprotein receptor"},
    {id: "lipolysis-stimulated remnant receptor"},
    {id: "lipopolysaccharide-binding protein"},
    {id: "lbp"},
    {id: "lipopolysaccharide-induced tumor necrosis factor-alpha factor homolog"},
    {id: "lps-induced tnf-alpha factor homolog"},
    {id: "estrogen-enhanced transcript protein 1"},
    {id: "eet-1"},
    {id: "lipoprotein"},
    {id: "lipoyl synthase, mitochondrial"},
    {id: "ec 2.8.1.8"},
    {id: "lipoate synthase"},
    {id: "ls"},
    {id: "lip-syn"},
    {id: "lipoic acid synthase"},
    {id: "lithostathine"},
    {id: "islet cells regeneration factor"},
    {id: "icrf"},
    {id: "islet of langerhans regenerating protein"},
    {id: "reg"},
    {id: "pancreatic stone protein"},
    {id: "pancreatic thread protein"},
    {id: "ptp"},
    {id: "lon protease homolog 2, peroxisomal"},
    {id: "lon protease-like protein 2"},
    {id: "lon protease 2"},
    {id: "peroxisomal lon protease"},
    {id: "ec 3.4.21.53"},
    {id: "lon protease homolog, mitochondrial"},
    {id: "lon protease-like protein"},
    {id: "lonp"},
    {id: "mitochondrial atp-dependent protease lon"},
    {id: "serine protease 15"},
    {id: "long-chain fatty acid transport protein 5"},
    {id: "fatp-5"},
    {id: "fatty acid transport protein 5"},
    {id: "bile acid-coa ligase"},
    {id: "ba-coa ligase"},
    {id: "bal"},
    {id: "bile acyl-coa synthetase"},
    {id: "bacs"},
    {id: "ec 6.2.1.7"},
    {id: "cholate--coa ligase"},
    {id: "long-chain-fatty-acid--coa ligase"},
    {id: "ec 6.2.1.3"},
    {id: "solute carrier family 27 member 5"},
    {id: "very long-chain acyl-coa synthetase-related protein"},
    {id: "vlacs-related"},
    {id: "vlacsr"},
    {id: "ec 6.2.1.-"},
    {id: "ec 6.2.1.15"},
    {id: "acyl-coa synthetase"},
    {id: "long-chain acyl-coa synthetase"},
    {id: "long-chain-fatty-acid--coa ligase 4"},
    {id: "arachidonate--coa ligase"},
    {id: "long-chain acyl-coa synthetase 4"},
    {id: "lacs 4"},
    {id: "long-chain-fatty-acid--coa ligase 6"},
    {id: "long-chain acyl-coa synthetase 6"},
    {id: "lacs 6"},
    {id: "long-chain-fatty-acid--coa ligase, brain isozyme"},
    {id: "long-chain-fatty-acid--coa ligase acsbg2"},
    {id: "acyl-coa synthetase bubblegum family member 2"},
    {id: "arachidonate--coa ligase acsbg2"},
    {id: "low affinity immunoglobulin gamma fc region receptor iii"},
    {id: "igg fc receptor iii"},
    {id: "fc-gamma riii"},
    {id: "fcriii"},
    {id: "cd antigen cd16"},
    {id: "low molecular weight phosphotyrosine protein phosphatase"},
    {id: "lmw-ptp"},
    {id: "lmw-ptpase"},
    {id: "ec 3.1.3.2"},
    {id: "low molecular weight cytosolic acid phosphatase"},
    {id: "low-density lipoprotein receptor-related protein 4"},
    {id: "lrp-4"},
    {id: "multiple epidermal growth factor-like domains 7"},
    {id: "lumican"},
    {id: "keratan sulfate proteoglycan lumican"},
    {id: "kspg lumican"},
    {id: "lutropin-choriogonadotropic hormone receptor"},
    {id: "lh/cg-r"},
    {id: "luteinizing hormone receptor"},
    {id: "lsh-r"},
    {id: "ly49 inhibitory receptor 2"},
    {id: "ly49 inhibitory receptor 4"},
    {id: "ly49 stimulatory receptor 6"},
    {id: "ly49s6 protein"},
    {id: "ly6/plaur domain-containing protein 3"},
    {id: "gpi-anchored metastasis-associated protein c4.4a"},
    {id: "lymphocyte activation gene 3 protein"},
    {id: "lag-3"},
    {id: "cd antigen cd223"},
    {id: "cleaved into: secreted lymphocyte activation gene 3 protein (slag-3)"},
    {id: "lymphocyte antigen 6b"},
    {id: "ly-6b"},
    {id: "lymphocyte antigen 96"},
    {id: "md2"},
    {id: "lymphocyte transmembrane adapter 1"},
    {id: "membrane-associated adapter protein lax"},
    {id: "lymphotoxin-alpha"},
    {id: "lt-alpha"},
    {id: "tnf-beta"},
    {id: "tumor necrosis factor ligand superfamily member 1"},
    {id: "lys-63-specific deubiquitinase brcc36"},
    {id: "ec 3.4.19.-"},
    {id: "brca1-a complex subunit brcc36"},
    {id: "brca1/brca2-containing complex subunit 3"},
    {id: "brca1/brca2-containing complex subunit 36"},
    {id: "brisc complex subunit brcc36"},
    {id: "lysine--trna ligase"},
    {id: "ec 6.1.1.6"},
    {id: "lysyl-trna synthetase"},
    {id: "lysrs"},
    {id: "lysine-specific demethylase"},
    {id: "ec 1.14.11.65"},
    {id: "lysine-specific demethylase 4d"},
    {id: "ec 1.14.11.66"},
    {id: "jmjc domain-containing histone demethylation protein 3d"},
    {id: "jumonji domain-containing protein 2d"},
    {id: "[histone h3]-trimethyl-l-lysine(9) demethylase 4d"},
    {id: "lysine-specific histone demethylase"},
    {id: "ec 1.14.99.66"},
    {id: "lysophosphatidic acid receptor 1"},
    {id: "lpa receptor 1"},
    {id: "lpa-1"},
    {id: "lysophosphatidic acid receptor edg-2"},
    {id: "lysophosphatidic acid receptor 6"},
    {id: "lpa receptor 6"},
    {id: "lpa-6"},
    {id: "oleoyl-l-alpha-lysophosphatidic acid receptor"},
    {id: "p2y purinoceptor 5"},
    {id: "p2y5"},
    {id: "purinergic receptor 5"},
    {id: "lysophosphatidylcholine acyltransferase 1"},
    {id: "lpc acyltransferase 1"},
    {id: "lpcat-1"},
    {id: "lysopc acyltransferase 1"},
    {id: "ec 2.3.1.23"},
    {id: "1-acylglycerol-3-phosphate o-acyltransferase"},
    {id: "1-acylglycerophosphocholine o-acyltransferase"},
    {id: "1-alkenylglycerophosphocholine o-acyltransferase"},
    {id: "ec 2.3.1.25"},
    {id: "1-alkylglycerophosphocholine o-acetyltransferase"},
    {id: "ec 2.3.1.67"},
    {id: "acetyl-coa:lyso-platelet-activating factor acetyltransferase"},
    {id: "acetyl-coa:lyso-paf acetyltransferase"},
    {id: "lyso-paf acetyltransferase"},
    {id: "lysopafat"},
    {id: "acyltransferase-like 2"},
    {id: "lysophosphatidylcholine acyltransferase 2b"},
    {id: "acyltransferase-like 1-b"},
    {id: "lysophosphatidylserine lipase abhd12"},
    {id: "2-arachidonoylglycerol hydrolase abhd12"},
    {id: "abhydrolase domain-containing protein 12"},
    {id: "monoacylglycerol lipase abhd12"},
    {id: "ec 3.1.1.23"},
    {id: "oxidized phosphatidylserine lipase abhd12"},
    {id: "lysophospholipase d gdpd1"},
    {id: "glycerophosphodiester phosphodiesterase 4"},
    {id: "glycerophosphodiester phosphodiesterase domain-containing protein 1"},
    {id: "lysophospholipid acyltransferase 2"},
    {id: "lplat 2"},
    {id: "1-acylglycerophosphate o-acyltransferase"},
    {id: "1-acylglycerophosphoethanolamine o-acyltransferase"},
    {id: "ec 2.3.1.n7"},
    {id: "lysophosphatidic acid acyltransferase"},
    {id: "lpaat"},
    {id: "lyso-pa acyltransferase"},
    {id: "lysophosphatidylcholine acyltransferase"},
    {id: "lpcat"},
    {id: "lyso-pc acyltransferase"},
    {id: "lysophosphatidylcholine acyltransferase 4"},
    {id: "lyso-pc acyltransferase 4"},
    {id: "lysophosphatidylethanolamine acyltransferase"},
    {id: "lpeat"},
    {id: "lyso-pe acyltransferase"},
    {id: "membrane-bound o-acyltransferase domain-containing protein 2"},
    {id: "o-acyltransferase domain-containing protein 2"},
    {id: "lysophospholipid acyltransferase 5"},
    {id: "lplat 5"},
    {id: "1-acylglycerophosphoserine o-acyltransferase"},
    {id: "ec 2.3.1.n6"},
    {id: "lysophosphatidylcholine acyltransferase 3"},
    {id: "lyso-pc acyltransferase 3"},
    {id: "membrane-bound o-acyltransferase domain-containing protein 5"},
    {id: "o-acyltransferase domain-containing protein 5"},
    {id: "lysosomal cobalamin transport escort protein lmbd1"},
    {id: "lmbd1"},
    {id: "lmbr1 domain-containing protein 1"},
    {id: "lysosomal enzyme trafficking factor"},
    {id: "transmembrane protein 251"},
    {id: "lysosomal proton-coupled steroid conjugate and bile acid symporter slc46a3"},
    {id: "solute carrier family 46 member 3"},
    {id: "lysosomal thioesterase ppt2"},
    {id: "ppt-2"},
    {id: "lysosome membrane protein 2"},
    {id: "85 kda lysosomal membrane sialoglycoprotein"},
    {id: "lgp85"},
    {id: "cd36 antigen-like 2"},
    {id: "lysosome membrane protein ii"},
    {id: "limp ii"},
    {id: "scavenger receptor class b member 2"},
    {id: "cd antigen cd36"},
    {id: "lysosome-associated membrane glycoprotein 3"},
    {id: "lamp-3"},
    {id: "lysosomal-associated membrane protein 3"},
    {id: "cd antigen cd208"},
    {id: "lysosome-associated membrane glycoprotein 5"},
    {id: "brain and dendritic cell-associated lamp"},
    {id: "brain-associated lamp-like protein"},
    {id: "bad-lamp"},
    {id: "lysosome-associated membrane protein 5"},
    {id: "lamp-5"},
    {id: "lysozyme c"},
    {id: "sperm acrosome associated 3"},
    {id: "lysozyme d1"},
    {id: "lysozyme-like 1"},
    {id: "lysozyme f1"},
    {id: "similar to riken cdna 9530003j23"},
    {id: "lysozyme g-like protein"},
    {id: "lysozyme-like protein 4"},
    {id: "lysozyme-4"},
    {id: "lysyl oxidase homolog"},
    {id: "ec 1.4.3.13"},
    {id: "m-phase inducer phosphatase"},
    {id: "mage family member b-like 1"},
    {id: "magebl1 protein"},
    {id: "maguk p55 subfamily member 2"},
    {id: "protein mpp2"},
    {id: "maguk p55 subfamily member 3"},
    {id: "discs large homolog 3"},
    {id: "protein mpp3"},
    {id: "mam domain-containing glycosylphosphatidylinositol anchor protein 2"},
    {id: "mam domain-containing protein 1"},
    {id: "map kinase-activated protein kinase 3"},
    {id: "mapk-activated protein kinase 3"},
    {id: "mapkap kinase 3"},
    {id: "mapkap-k3"},
    {id: "mapkapk-3"},
    {id: "mk-3"},
    {id: "map kinase-interacting serine/threonine-protein kinase 2"},
    {id: "map kinase signal-integrating kinase 2"},
    {id: "mapk signal-integrating kinase 2"},
    {id: "mnk2"},
    {id: "map2k4delta"},
    {id: "marcks-related protein"},
    {id: "brain protein f52"},
    {id: "marcks-like protein 1"},
    {id: "macrophage myristoylated alanine-rich c kinase substrate"},
    {id: "mac-marcks"},
    {id: "macmarcks"},
    {id: "mas related gpr family member f"},
    {id: "mas-related g protein-coupled receptor c"},
    {id: "mas related gpr family member x1"},
    {id: "mas-related g protein-coupled receptor g10"},
    {id: "mas-related gpr, member b4"},
    {id: "mas-related g protein-coupled receptor g3"},
    {id: "mau2 chromatid cohesion factor homolog"},
    {id: "cohesin loading complex subunit scc4 homolog"},
    {id: "mfs-type transporter slc18b1"},
    {id: "solute carrier family 18 member b1"},
    {id: "vesicular polyamine transporter"},
    {id: "vpat"},
    {id: "mhc class i-like protein mill1"},
    {id: "mhc class i-like located near the leukocyte receptor complex 1"},
    {id: "mhc class ii antigen"},
    {id: "rt1 class ii, d beta 1"},
    {id: "rt1 class ii, locus db1"},
    {id: "rt1-db1"},
    {id: "rt1 class ii, locus ba"},
    {id: "rt1-ba"},
    {id: "mhc class ii beta chain"},
    {id: "rt1 class ii, locus db2"},
    {id: "rt1-db2"},
    {id: "micos complex subunit mic60"},
    {id: "mitofilin"},
    {id: "mmr_hsr1 domain containing protein rgd1359460"},
    {id: "nitric oxide associated 1"},
    {id: "mob-like protein phocein"},
    {id: "class ii mmob1"},
    {id: "mob1 homolog 3"},
    {id: "mob3"},
    {id: "mps one binder kinase activator-like 3"},
    {id: "phocein"},
    {id: "preimplantation protein 3"},
    {id: "mon2 homolog, regulator of endosome-to-golgi trafficking"},
    {id: "mon2 protein"},
    {id: "myc associated factor x"},
    {id: "max protein"},
    {id: "myg1 exonuclease"},
    {id: "macoilin"},
    {id: "macoilin-1"},
    {id: "transmembrane protein 57"},
    {id: "macrophage colony-stimulating factor 1"},
    {id: "csf-1"},
    {id: "mcsf"},
    {id: "macrophage migration inhibitory factor"},
    {id: "mif"},
    {id: "ec 5.3.2.1"},
    {id: "glutathione-binding 13 kda protein"},
    {id: "l-dopachrome isomerase"},
    {id: "l-dopachrome tautomerase"},
    {id: "ec 5.3.3.12"},
    {id: "phenylpyruvate tautomerase"},
    {id: "macrophage-capping protein"},
    {id: "actin regulatory protein cap-g"},
    {id: "macrophage-expressed gene 1 protein"},
    {id: "perforin-2"},
    {id: "maestro heat-like repeat-containing protein family member 7"},
    {id: "heat repeat-containing protein 8"},
    {id: "magnesium transporter mrs2 homolog, mitochondrial"},
    {id: "mrs2-like protein"},
    {id: "major facilitator superfamily"},
    {id: "mfs"},
    {id: "major histocompatibility complex, class ii, dm alpha"},
    {id: "rt1 class ii, dm alpha"},
    {id: "rt1 class ii, locus dma"},
    {id: "rt1-dma"},
    {id: "major intrinsically disordered notch2-binding receptor 1"},
    {id: "membrane integral notch2-associated receptor 1"},
    {id: "ubiquitination and mtor signaling protein"},
    {id: "malate dehydrogenase"},
    {id: "ec 1.1.1.37"},
    {id: "malate dehydrogenase, mitochondrial"},
    {id: "male-enhanced antigen 1"},
    {id: "mea-1"},
    {id: "male-specific lethal 3-like 2"},
    {id: "malignant t-cell-amplified sequence 1"},
    {id: "mct-1"},
    {id: "malonyl-coa decarboxylase, mitochondrial"},
    {id: "mcd"},
    {id: "ec 4.1.1.9"},
    {id: "mammalian ependymin-related protein 1"},
    {id: "merp-1"},
    {id: "manganese-dependent adp-ribose/cdp-alcohol diphosphatase"},
    {id: "ec 3.6.1.16"},
    {id: "ec 3.6.1.53"},
    {id: "adpribase-mn"},
    {id: "cdp-choline phosphohydrolase"},
    {id: "mannan-binding lectin serine protease 1"},
    {id: "complement factor masp-3"},
    {id: "complement-activating component of ra-reactive factor"},
    {id: "mannose-binding lectin-associated serine protease 1"},
    {id: "masp-1"},
    {id: "mannose-binding protein-associated serine protease"},
    {id: "ra-reactive factor serine protease p100"},
    {id: "rarf"},
    {id: "serine protease 5"},
    {id: "cleaved into: mannan-binding lectin serine protease 1 heavy chain; mannan-binding lectin serine protease 1 light chain"},
    {id: "mannose-1-phosphate guanyltransferase alpha"},
    {id: "gdp-mannose pyrophosphorylase a"},
    {id: "gtp-mannose-1-phosphate guanylyltransferase alpha"},
    {id: "mannose-6-phosphate isomerase"},
    {id: "ec 5.3.1.8"},
    {id: "phosphohexomutase"},
    {id: "phosphomannose isomerase"},
    {id: "pmi"},
    {id: "mannosyl-oligosaccharide glucosidase"},
    {id: "ec 3.2.1.106"},
    {id: "mannosyltransferase"},
    {id: "marginal zone b- and b1-cell-specific protein"},
    {id: "plasma cell-induced resident endoplasmic reticulum protein"},
    {id: "plasma cell-induced resident er protein"},
    {id: "perp1"},
    {id: "proapoptotic caspase adapter protein"},
    {id: "mas-related g-protein coupled receptor member d"},
    {id: "beta-alanine receptor"},
    {id: "g-protein coupled receptor tgr7"},
    {id: "maspardin"},
    {id: "spastic paraplegia 21 autosomal recessive mast syndrome protein homolog"},
    {id: "mast cell protease 1"},
    {id: "rmcp-1"},
    {id: "ec 3.4.21.39"},
    {id: "chymase"},
    {id: "chymotrypsin-like protease"},
    {id: "clip protein"},
    {id: "mast cell protease i"},
    {id: "rmcp-i"},
    {id: "mast cell protease 1-like 3"},
    {id: "mast cell protease 1-like 4"},
    {id: "mast cell protease 2"},
    {id: "rmcp-2"},
    {id: "group-specific protease"},
    {id: "mast cell protease ii"},
    {id: "rmcp-ii"},
    {id: "mast cell protease 4"},
    {id: "rmcp-4"},
    {id: "mast cell protease iv"},
    {id: "rmcp-iv"},
    {id: "mast cell protease 8"},
    {id: "rmcp-8"},
    {id: "mast cell protease viii"},
    {id: "rmcp-viii"},
    {id: "mast/stem cell growth factor receptor"},
    {id: "maternal embryonic leucine zipper kinase"},
    {id: "matrilysin"},
    {id: "ec 3.4.24.23"},
    {id: "matrin"},
    {id: "matrix metalloproteinase-7"},
    {id: "mmp-7"},
    {id: "pump-1 protease"},
    {id: "uterine metalloproteinase"},
    {id: "matrix metallopeptidase 1"},
    {id: "mmp1a protein"},
    {id: "matrix metallopeptidase 1b"},
    {id: "interstitial collagenase"},
    {id: "matrix metallopeptidase 28"},
    {id: "matrix metalloproteinase 28"},
    {id: "matrix metalloproteinase-14"},
    {id: "ec 3.4.24.80"},
    {id: "mmp-14"},
    {id: "membrane-type matrix metalloproteinase 1"},
    {id: "mt-mmp 1"},
    {id: "mtmmp1"},
    {id: "membrane-type-1 matrix metalloproteinase"},
    {id: "mt-mmp"},
    {id: "mt1-mmp"},
    {id: "mt1mmp"},
    {id: "matrix metalloproteinase-23"},
    {id: "mmp-23"},
    {id: "metalloprotease in the female reproductive tract"},
    {id: "mifr"},
    {id: "cleaved into: matrix metalloproteinase-23, soluble form"},
    {id: "matrix metalloproteinase-24"},
    {id: "mmp-24"},
    {id: "membrane-type matrix metalloproteinase 5"},
    {id: "mt-mmp 5"},
    {id: "mtmmp5"},
    {id: "membrane-type-5 matrix metalloproteinase"},
    {id: "mt5-mmp"},
    {id: "mt5mmp"},
    {id: "cleaved into: processed matrix metalloproteinase-24"},
    {id: "matrix metalloproteinase-9"},
    {id: "ec 3.4.24.35"},
    {id: "92 kda gelatinase"},
    {id: "92 kda type iv collagenase"},
    {id: "gelatinase b"},
    {id: "matrix remodeling-associated protein 8"},
    {id: "limitrin"},
    {id: "mcart1l protein"},
    {id: "solute carrier family 25, member 53"},
    {id: "mediator of rna polymerase ii transcription subunit 1"},
    {id: "mediator complex subunit 1"},
    {id: "mediator of rna polymerase ii transcription subunit 10"},
    {id: "mediator complex subunit 10"},
    {id: "mediator of rna polymerase ii transcription subunit 11"},
    {id: "mediator complex subunit 11"},
    {id: "mediator of rna polymerase ii transcription subunit 14"},
    {id: "mediator complex subunit 14"},
    {id: "mediator of rna polymerase ii transcription subunit 15"},
    {id: "mediator complex subunit 15"},
    {id: "mediator of rna polymerase ii transcription subunit 16"},
    {id: "mediator complex subunit 16"},
    {id: "mediator of rna polymerase ii transcription subunit 17"},
    {id: "mediator complex subunit 17"},
    {id: "mediator of rna polymerase ii transcription subunit 18"},
    {id: "mediator complex subunit 18"},
    {id: "mediator of rna polymerase ii transcription subunit 19"},
    {id: "mediator complex subunit 19"},
    {id: "mediator of rna polymerase ii transcription subunit 20"},
    {id: "mediator complex subunit 20"},
    {id: "trf-proximal protein homolog"},
    {id: "mediator of rna polymerase ii transcription subunit 22"},
    {id: "mediator complex subunit 22"},
    {id: "surfeit locus protein 5"},
    {id: "mediator of rna polymerase ii transcription subunit 23"},
    {id: "mediator complex subunit 23"},
    {id: "mediator of rna polymerase ii transcription subunit 24"},
    {id: "mediator complex subunit 24"},
    {id: "mediator of rna polymerase ii transcription subunit 26"},
    {id: "cofactor required for sp1 transcriptional activation subunit 7"},
    {id: "mediator complex subunit 26"},
    {id: "mediator of rna polymerase ii transcription subunit 27"},
    {id: "cofactor required for sp1 transcriptional activation subunit 8"},
    {id: "mediator complex subunit 27"},
    {id: "mediator of rna polymerase ii transcription subunit 28"},
    {id: "mediator complex subunit 28"},
    {id: "mediator of rna polymerase ii transcription subunit 29"},
    {id: "intersex-like protein"},
    {id: "mediator complex subunit 29"},
    {id: "mediator of rna polymerase ii transcription subunit 30"},
    {id: "mediator complex subunit 30"},
    {id: "mediator of rna polymerase ii transcription subunit 4"},
    {id: "mediator complex subunit 4"},
    {id: "mediator of rna polymerase ii transcription subunit 6"},
    {id: "mediator complex subunit 6"},
    {id: "mediator of rna polymerase ii transcription subunit 8"},
    {id: "mediator complex subunit 8"},
    {id: "mediator of rna polymerase ii transcription subunit 9"},
    {id: "mediator complex subunit 9"},
    {id: "medium-chain acyl-coa ligase acsf2, mitochondrial"},
    {id: "medium-wave-sensitive opsin 1"},
    {id: "green cone photoreceptor pigment"},
    {id: "green-sensitive opsin"},
    {id: "medium wavelength-sensitive cone opsin"},
    {id: "megakaryocyte and platelet inhibitory receptor g6b"},
    {id: "protein g6b"},
    {id: "megakaryocyte-associated tyrosine-protein kinase"},
    {id: "ec 2.7.10.2"},
    {id: "protein kinase batk"},
    {id: "tyrosine-protein kinase ctk"},
    {id: "meiosis regulator and mrna stability factor 1"},
    {id: "limkain-b1"},
    {id: "meiosis arrest female protein 1"},
    {id: "meiosis-specific with ob domain-containing protein"},
    {id: "meiotic recombination protein rec8 homolog"},
    {id: "cohesin rec8p"},
    {id: "meis homeobox 3"},
    {id: "meis3 protein"},
    {id: "melanin-concentrating hormone receptor 1"},
    {id: "mch receptor 1"},
    {id: "mch-r1"},
    {id: "mchr-1"},
    {id: "g-protein coupled receptor 24"},
    {id: "mch-1r"},
    {id: "mch1r"},
    {id: "mchr"},
    {id: "slc-1"},
    {id: "somatostatin receptor-like protein"},
    {id: "melanocortin receptor 3"},
    {id: "mc3-r"},
    {id: "melanocortin receptor 4"},
    {id: "mc4-r"},
    {id: "melanocortin receptor 5"},
    {id: "mc5-r"},
    {id: "melanocyte-stimulating hormone receptor"},
    {id: "msh-r"},
    {id: "melanocortin receptor 1"},
    {id: "melanoma-associated antigen b16"},
    {id: "mage-b16 antigen"},
    {id: "melanoma-associated antigen d1"},
    {id: "mage-d1 antigen"},
    {id: "neurotrophin receptor-interacting mage homolog"},
    {id: "sertoli cell necdin-related gene protein 1"},
    {id: "snerg-1"},
    {id: "melanoma-associated antigen e1"},
    {id: "alpha-dystrobrevin-associated mage protein"},
    {id: "damage"},
    {id: "mage-e1 antigen"},
    {id: "melanopsin"},
    {id: "opsin-4"},
    {id: "melatonin receptor type 1b"},
    {id: "mel-1b-r"},
    {id: "mel1b receptor"},
    {id: "membrane associated ring-ch finger protein ii"},
    {id: "membrane associated ring-ch-type finger 2"},
    {id: "membrane protein bri3"},
    {id: "brain protein i3"},
    {id: "membrane protein fam174a"},
    {id: "transmembrane protein 157"},
    {id: "membrane spanning 4-domains a5"},
    {id: "membrane-spanning 4-domains subfamily a member 5"},
    {id: "membrane-associated phosphatidylinositol transfer protein 1"},
    {id: "phosphatidylinositol transfer protein, membrane-associated 1"},
    {id: "pitpnm 1"},
    {id: "pyk2 n-terminal domain-interacting receptor 2"},
    {id: "nir-2"},
    {id: "membrane-associated progesterone receptor component 1"},
    {id: "mpr"},
    {id: "25-dx"},
    {id: "acidic 25 kda protein"},
    {id: "ventral midline antigen"},
    {id: "vema"},
    {id: "membrane-bound transcription factor site-1 protease"},
    {id: "ec 3.4.21.112"},
    {id: "endopeptidase s1p"},
    {id: "subtilisin/kexin isozyme 1"},
    {id: "ski-1"},
    {id: "membrane-bound transcription factor site-2 protease"},
    {id: "ec 3.4.24.85"},
    {id: "endopeptidase s2p"},
    {id: "mesoderm-specific transcript homolog protein"},
    {id: "ec 3.-.-.-"},
    {id: "metabotropic glutamate receptor 7"},
    {id: "mglur7"},
    {id: "metal cation symporter zip8"},
    {id: "solute carrier family 39 member 8"},
    {id: "zrt- and irt-like protein 8"},
    {id: "zip-8"},
    {id: "metal transporter cnnm2"},
    {id: "ancient conserved domain-containing protein 2"},
    {id: "cyclin-m2"},
    {id: "metal transporter cnnm4"},
    {id: "ancient conserved domain-containing protein 4"},
    {id: "cyclin-m4"},
    {id: "metalloendopeptidase"},
    {id: "metallophosphoesterase 1"},
    {id: "post-gpi attachment to proteins factor 5"},
    {id: "metallophosphoesterase mpped2"},
    {id: "239fb"},
    {id: "fetal brain protein 239 homolog"},
    {id: "metallophosphoesterase domain-containing protein 2"},
    {id: "metalloprotease tiki"},
    {id: "trab domain-containing protein 2"},
    {id: "metalloproteinase inhibitor 1"},
    {id: "tissue inhibitor of metalloproteinases 1"},
    {id: "timp-1"},
    {id: "metalloproteinase inhibitor 2"},
    {id: "tissue inhibitor of metalloproteinases 2"},
    {id: "timp-2"},
    {id: "metalloreductase steap3"},
    {id: "ec 1.16.1.-"},
    {id: "six-transmembrane epithelial antigen of prostate 3"},
    {id: "phyde"},
    {id: "metalloreductase steap4"},
    {id: "ec 1.16.1.9"},
    {id: "six-transmembrane epithelial antigen of prostate 4"},
    {id: "metallothionein-1"},
    {id: "mt-1"},
    {id: "metallothionein-i"},
    {id: "mt-i"},
    {id: "metallothionein-2"},
    {id: "mt-2"},
    {id: "metallothionein-ii"},
    {id: "mt-ii"},
    {id: "metallothionein-3"},
    {id: "mt-3"},
    {id: "growth inhibitory factor"},
    {id: "gif"},
    {id: "metallothionein-iii"},
    {id: "mt-iii"},
    {id: "metastasis-suppressor kiss-1"},
    {id: "kisspeptin-1"},
    {id: "cleaved into: metastin; kisspeptin-10 (metastin45-54)"},
    {id: "metaxin 1"},
    {id: "mtx1 protein"},
    {id: "meteorin"},
    {id: "hypoxia/reoxygenation regulatory factor"},
    {id: "meteorin-like protein"},
    {id: "subfatin"},
    {id: "methanethiol oxidase"},
    {id: "mto"},
    {id: "ec 1.8.3.4"},
    {id: "56 kda selenium-binding protein"},
    {id: "sbp56"},
    {id: "sp56"},
    {id: "selenium-binding protein 1"},
    {id: "selenium-binding protein 2"},
    {id: "methionine adenosyltransferase 2 subunit beta"},
    {id: "methionine adenosyltransferase ii beta"},
    {id: "methionine aminopeptidase"},
    {id: "ec 3.4.11.18"},
    {id: "methionine aminopeptidase 2"},
    {id: "map 2"},
    {id: "metap 2"},
    {id: "initiation factor 2-associated 67 kda glycoprotein"},
    {id: "peptidase m"},
    {id: "p67"},
    {id: "p67eif2"},
    {id: "methionine synthase"},
    {id: "ec 2.1.1.13"},
    {id: "5-methyltetrahydrofolate--homocysteine methyltransferase"},
    {id: "methionine synthase reductase"},
    {id: "msr"},
    {id: "ec 1.16.1.8"},
    {id: "aquacobalamin reductase"},
    {id: "aqcbl reductase"},
    {id: "methionine--trna ligase, cytoplasmic"},
    {id: "ec 6.1.1.10"},
    {id: "methionyl-trna synthetase"},
    {id: "methionine-r-sulfoxide reductase b1"},
    {id: "msrb1"},
    {id: "ec 1.8.4.12"},
    {id: "selenoprotein x"},
    {id: "selx"},
    {id: "methionine-r-sulfoxide reductase b2, mitochondrial"},
    {id: "msrb2"},
    {id: "methionyl-trna formyltransferase, mitochondrial"},
    {id: "ec 2.1.2.9"},
    {id: "methyl-cpg-binding domain protein 4"},
    {id: "methyl-cpg-binding protein 2"},
    {id: "mecp-2 protein"},
    {id: "mecp2"},
    {id: "methylcrotonoyl-coa carboxylase beta chain, mitochondrial"},
    {id: "mccase subunit beta"},
    {id: "ec 6.4.1.4"},
    {id: "3-methylcrotonyl-coa carboxylase 2"},
    {id: "3-methylcrotonyl-coa carboxylase non-biotin-containing subunit"},
    {id: "3-methylcrotonyl-coa:carbon dioxide ligase subunit beta"},
    {id: "methylcytosine dioxygenase tet"},
    {id: "ec 1.14.11.80"},
    {id: "methylenetetrahydrofolate dehydrogenase"},
    {id: "nadp+ dependent"},
    {id: "methylenetetrahydrofolate reductase"},
    {id: "ec 1.5.1.53"},
    {id: "methylglutaconyl-coa hydratase, mitochondrial"},
    {id: "3-mg-coa hydratase"},
    {id: "ec 4.2.1.18"},
    {id: "au-specific rna-binding enoyl-coa hydratase"},
    {id: "au-binding enoyl-coa hydratase"},
    {id: "itaconyl-coa hydratase"},
    {id: "ec 4.2.1.56"},
    {id: "methylmalonyl-coa mutase, mitochondrial"},
    {id: "ec 5.4.99.2"},
    {id: "methylmalonyl-coa isomerase"},
    {id: "methylosome protein wdr77"},
    {id: "methylosome protein 50"},
    {id: "mep-50"},
    {id: "wd repeat-containing protein 77"},
    {id: "methylosome subunit picln"},
    {id: "chloride channel, nucleotide sensitive 1a"},
    {id: "chloride conductance regulatory protein icln"},
    {id: "methylsterol monooxygenase 1"},
    {id: "ec 1.14.18.9"},
    {id: "c-4 methylsterol oxidase"},
    {id: "neuropep 1"},
    {id: "ranp-1"},
    {id: "sterol-c4-methyl oxidase"},
    {id: "methylthioribose-1-phosphate isomerase"},
    {id: "m1pi"},
    {id: "mtr-1-p isomerase"},
    {id: "ec 5.3.1.23"},
    {id: "s-methyl-5-thioribose-1-phosphate isomerase"},
    {id: "translation initiation factor eif-2b subunit alpha/beta/delta-like protein"},
    {id: "methylthioribulose-1-phosphate dehydratase"},
    {id: "mtru-1-p dehydratase"},
    {id: "ec 4.2.1.109"},
    {id: "apaf1-interacting protein"},
    {id: "methyltransferase 10 domain-containing protein"},
    {id: "ec 2.1.1.346"},
    {id: "ec 2.1.1.348"},
    {id: "methyltransferase-like protein 16"},
    {id: "n6-adenosine-methyltransferase mettl16"},
    {id: "u6 small nuclear rna (adenine-(43)-n(6))-methyltransferase"},
    {id: "methyltransferase-like protein 25b"},
    {id: "protein rrnad1"},
    {id: "mevalonate kinase"},
    {id: "mk"},
    {id: "ec 2.7.1.36"},
    {id: "mical3 protein"},
    {id: "microtubule associated monooxygenase, calponin and lim domain containing 3"},
    {id: "microsomal glutathione s-transferase 1"},
    {id: "microsomal gst-1"},
    {id: "microsomal gst-i"},
    {id: "microtubule-associated protein 1 light chain 3 beta"},
    {id: "zbs559"},
    {id: "microtubule-associated protein 10"},
    {id: "microtubule regulator of 120 kda"},
    {id: "microtubule-associated protein 1b"},
    {id: "map-1b"},
    {id: "neuraxin"},
    {id: "cleaved into: map1b heavy chain; map1 light chain lc1"},
    {id: "microtubule-associated protein rp/eb family member 3"},
    {id: "eb1 protein family member 3"},
    {id: "ebf3"},
    {id: "end-binding protein 3"},
    {id: "eb3"},
    {id: "rp3"},
    {id: "microtubule-associated proteins 1a/1b light chain 3a"},
    {id: "autophagy-related protein lc3 a"},
    {id: "autophagy-related ubiquitin-like modifier lc3 a"},
    {id: "map1 light chain 3-like protein 1"},
    {id: "map1a/map1b light chain 3 a"},
    {id: "map1a/map1b lc3 a"},
    {id: "microtubule-associated protein 1 light chain 3 alpha"},
    {id: "microtubule-associated serine/threonine-protein kinase 1"},
    {id: "syntrophin-associated serine/threonine-protein kinase"},
    {id: "microtubule-associated tumor suppressor 1 homolog"},
    {id: "angiotensin-ii type 2 receptor-interacting protein"},
    {id: "mitochondrial tumor suppressor 1 homolog"},
    {id: "microtubule-associated tyrosine carboxypeptidase"},
    {id: "ec 3.4.17.17"},
    {id: "mid1-interacting protein 1"},
    {id: "gastrulation-specific g12-like protein"},
    {id: "mid1-interacting g12-like protein"},
    {id: "protein strait11499 homolog"},
    {id: "spot 14-related protein"},
    {id: "s14r"},
    {id: "spot 14-r"},
    {id: "midnolin"},
    {id: "midbrain nucleolar protein"},
    {id: "migration and invasion-inhibitory protein"},
    {id: "invasion-inhibitory protein 45"},
    {id: "iip45"},
    {id: "mitochondria-eating protein"},
    {id: "spermatogenesis-associated protein 18"},
    {id: "mitochondrial 2-oxodicarboxylate carrier"},
    {id: "solute carrier family 25 member 21"},
    {id: "mitochondrial rho gtpase"},
    {id: "mitochondrial rho gtpase 2"},
    {id: "miro-2"},
    {id: "ras homolog gene family member t2"},
    {id: "mitochondrial antiviral-signaling protein"},
    {id: "mavs"},
    {id: "interferon beta promoter stimulator protein 1"},
    {id: "ips-1"},
    {id: "virus-induced-signaling adapter"},
    {id: "visa"},
    {id: "mitochondrial carrier 1"},
    {id: "mtch1 protein"},
    {id: "mitochondrial chaperone bcs1"},
    {id: "bcs1-like protein"},
    {id: "mitochondrial coenzyme a transporter slc25a42"},
    {id: "slc25a42 protein"},
    {id: "solute carrier family 25 member 42"},
    {id: "mitochondrial dicarboxylate carrier"},
    {id: "dic"},
    {id: "solute carrier family 25 member 10"},
    {id: "mitochondrial dynamics protein mid51"},
    {id: "mitochondrial dynamics protein of 51 kda homolog"},
    {id: "mitochondrial elongation factor 1"},
    {id: "smith-magenis syndrome chromosomal region candidate gene 7 protein-like"},
    {id: "mitochondrial fission 1 protein"},
    {id: "fis1 homolog"},
    {id: "rfis1"},
    {id: "tetratricopeptide repeat protein 11"},
    {id: "tpr repeat protein 11"},
    {id: "mitochondrial fission process protein 1"},
    {id: "mitochondrial 18 kda protein"},
    {id: "mitochondrial genome maintenance exonuclease 1"},
    {id: "mitochondrial glutamate carrier 1"},
    {id: "gc-1"},
    {id: "glutamate/h(+) symporter 1"},
    {id: "solute carrier family 25 member 22"},
    {id: "mitochondrial glutamate carrier 2"},
    {id: "gc-2"},
    {id: "glutamate/h(+) symporter 2"},
    {id: "solute carrier family 25 member 18"},
    {id: "mitochondrial glutathione transporter slc25a39"},
    {id: "solute carrier family 25 member 39"},
    {id: "mitochondrial glutathione transporter slc25a40"},
    {id: "solute carrier family 25 member 40"},
    {id: "mitochondrial glycine transporter"},
    {id: "solute carrier family 25 member 38"},
    {id: "mitochondrial import inner membrane translocase subunit tim10 b"},
    {id: "fracture callus protein 1"},
    {id: "fxc1"},
    {id: "mitochondrial import inner membrane translocase subunit tim9 b"},
    {id: "timm10b"},
    {id: "tim10b"},
    {id: "mitochondrial import inner membrane translocase subunit tim21"},
    {id: "tim21-like protein, mitochondrial"},
    {id: "mitochondrial import inner membrane translocase subunit tim8 a"},
    {id: "deafness dystonia protein 1 homolog"},
    {id: "mitochondrial import inner membrane translocase subunit tim8 b"},
    {id: "deafness dystonia protein 2 homolog"},
    {id: "mitochondrial import receptor subunit tom20 homolog"},
    {id: "mitochondrial 20 kda outer membrane protein"},
    {id: "outer mitochondrial membrane receptor tom20"},
    {id: "mitochondrial import receptor subunit tom22 homolog"},
    {id: "translocase of outer membrane 22 kda subunit homolog"},
    {id: "mitochondrial import receptor subunit tom40b"},
    {id: "protein tomm40-like"},
    {id: "mitochondrial import receptor subunit tom7 homolog"},
    {id: "translocase of outer membrane 7 kda subunit homolog"},
    {id: "mitochondrial import receptor subunit tom70"},
    {id: "mitochondrial precursor proteins import receptor"},
    {id: "translocase of outer membrane 70 kda subunit"},
    {id: "translocase of outer mitochondrial membrane protein 70"},
    {id: "mitochondrial inner membrane protease atp23"},
    {id: "mitochondrial inner membrane protease subunit"},
    {id: "mitochondrial intermembrane space import and assembly protein 40"},
    {id: "coiled-coil-helix-coiled-coil-helix domain-containing protein 4"},
    {id: "mitochondrial nicotinamide adenine dinucleotide transporter slc25a51"},
    {id: "mitochondrial nad(+) transporter slc25a51"},
    {id: "mitochondrial carrier triple repeat protein 1"},
    {id: "solute carrier family 25 member 51"},
    {id: "mitochondrial outer membrane protein slc25a46"},
    {id: "solute carrier family 25 member 46"},
    {id: "mitochondrial peptide methionine sulfoxide reductase"},
    {id: "ec 1.8.4.11"},
    {id: "peptide-methionine (s)-s-oxide reductase"},
    {id: "peptide met(o) reductase"},
    {id: "protein-methionine-s-oxide reductase"},
    {id: "pmsr"},
    {id: "mitochondrial poly"},
    {id: "mitochondrial potassium channel atp-binding subunit"},
    {id: "atp-binding cassette sub-family b member 8, mitochondrial"},
    {id: "abcb8"},
    {id: "mitochondrial sulfonylurea-receptor"},
    {id: "mitosur"},
    {id: "mitochondrial proton/calcium exchanger protein"},
    {id: "electroneutral mitochondrial k(+)/h(+)exchanger"},
    {id: "khe"},
    {id: "leucine zipper-ef-hand-containing transmembrane protein 1"},
    {id: "mitochondrial pyruvate carrier 2"},
    {id: "brain protein 44"},
    {id: "protein 0-44"},
    {id: "mitochondrial ribonuclease p catalytic subunit"},
    {id: "ec 3.1.26.5"},
    {id: "mitochondrial ribonuclease p protein 3"},
    {id: "mitochondrial rnase p protein 3"},
    {id: "mitochondrial ribosomal protein s22"},
    {id: "similar to ribosomal protein, mitochondrial, s22"},
    {id: "mitochondrial sodium/calcium exchanger protein"},
    {id: "na(+)/k(+)/ca(2+)-exchange protein 6"},
    {id: "sodium/calcium exchanger protein, mitochondrial"},
    {id: "sodium/potassium/calcium exchanger 6"},
    {id: "solute carrier family 24 member 6"},
    {id: "solute carrier family 8 member b1"},
    {id: "mitochondrial trna-specific 2-thiouridylase 1"},
    {id: "ec 2.8.1.14"},
    {id: "mitochondrial translation release factor in rescue"},
    {id: "rgd1563482 protein"},
    {id: "mitogen-activated protein kinase"},
    {id: "ec 2.7.11.24"},
    {id: "mitogen-activated protein kinase 1"},
    {id: "map kinase 1"},
    {id: "mapk 1"},
    {id: "ert1"},
    {id: "extracellular signal-regulated kinase 2"},
    {id: "erk-2"},
    {id: "map kinase isoform p42"},
    {id: "p42-mapk"},
    {id: "mitogen-activated protein kinase 2"},
    {id: "map kinase 2"},
    {id: "mapk 2"},
    {id: "mitogen-activated protein kinase 12"},
    {id: "map kinase 12"},
    {id: "mapk 12"},
    {id: "extracellular signal-regulated kinase 6"},
    {id: "erk-6"},
    {id: "mitogen-activated protein kinase p38 gamma"},
    {id: "map kinase p38 gamma"},
    {id: "stress-activated protein kinase 3"},
    {id: "mitogen-activated protein kinase 13"},
    {id: "mitogen-activated protein kinase 14"},
    {id: "mitogen-activated protein kinase p38 alpha"},
    {id: "mitogen-activated protein kinase 15"},
    {id: "map kinase 15"},
    {id: "mapk 15"},
    {id: "extracellular signal-regulated kinase 7"},
    {id: "erk-7"},
    {id: "extracellular signal-regulated kinase 8"},
    {id: "erk-8"},
    {id: "mitogen-activated protein kinase 6"},
    {id: "map kinase 6"},
    {id: "mapk 6"},
    {id: "extracellular signal-regulated kinase 3"},
    {id: "erk-3"},
    {id: "p55-mapk"},
    {id: "mitogen-activated protein kinase kinase kinase"},
    {id: "ec 2.7.11.25"},
    {id: "mitogen-activated protein kinase kinase kinase 10"},
    {id: "mitogen-activated protein kinase kinase kinase 11"},
    {id: "mixed lineage kinase 3"},
    {id: "mitogen-activated protein kinase kinase kinase 13"},
    {id: "similar to mitogen-activated protein kinase kinase kinase 13 leucine zipper-bearing kinase"},
    {id: "mitogen-activated protein kinase kinase kinase 14"},
    {id: "mitogen-activated protein kinase kinase kinase 7"},
    {id: "mitogen-activated protein kinase kinase kinase 8"},
    {id: "mitogen-activated protein kinase kinase kinase kinase 1"},
    {id: "mitogen-activated protein kinase kinase kinase kinase 2"},
    {id: "mitogen-activated protein kinase kinase kinase kinase 3"},
    {id: "germinal center kinase-related protein kinase"},
    {id: "glk"},
    {id: "mapk/erk kinase kinase kinase 3"},
    {id: "mek kinase kinase 3"},
    {id: "mekkk 3"},
    {id: "mitogen-activated protein kinase kinase kinase kinase 5"},
    {id: "mitotic spindle assembly checkpoint protein mad2b"},
    {id: "mitotic arrest deficient 2-like protein 2"},
    {id: "mad2-like protein 2"},
    {id: "mitsugumin29"},
    {id: "synaptophysin-like 2"},
    {id: "molybdate-anion transporter"},
    {id: "major facilitator superfamily domain-containing protein 5"},
    {id: "molybdate transporter 2 homolog"},
    {id: "molybdenum cofactor sulfurase"},
    {id: "mcs"},
    {id: "mos"},
    {id: "moco sulfurase"},
    {id: "ec 2.8.1.9"},
    {id: "molybdenum cofactor sulfurtransferase"},
    {id: "molybdopterin synthase catalytic subunit"},
    {id: "ec 2.8.1.12"},
    {id: "molybdenum cofactor synthesis protein 2 large subunit"},
    {id: "molybdenum cofactor synthesis protein 2b"},
    {id: "mocs2b"},
    {id: "monoacylglycerol lipase abhd6"},
    {id: "2-arachidonoylglycerol hydrolase"},
    {id: "abhydrolase domain-containing protein 6"},
    {id: "monocarboxylate transporter 10"},
    {id: "mct 10"},
    {id: "aromatic amino acid transporter 1"},
    {id: "solute carrier family 16 member 10"},
    {id: "t-type amino acid transporter 1"},
    {id: "monocarboxylate transporter 12"},
    {id: "mct 12"},
    {id: "solute carrier family 16 member 12"},
    {id: "monocarboxylate transporter 13"},
    {id: "mct 13"},
    {id: "solute carrier family 16 member 13"},
    {id: "monocarboxylate transporter 2"},
    {id: "mct 2"},
    {id: "solute carrier family 16 member 7"},
    {id: "monocarboxylate transporter 3"},
    {id: "mct 3"},
    {id: "solute carrier family 16 member 8"},
    {id: "monocarboxylate transporter 4"},
    {id: "mct 4"},
    {id: "solute carrier family 16 member 3"},
    {id: "monocarboxylate transporter 8"},
    {id: "mct 8"},
    {id: "solute carrier family 16 member 2"},
    {id: "monocarboxylate transporter 9"},
    {id: "mct 9"},
    {id: "solute carrier family 16 member 9"},
    {id: "monocyte differentiation antigen cd14"},
    {id: "myeloid cell-specific leucine-rich glycoprotein"},
    {id: "cd antigen cd14"},
    {id: "monocyte to macrophage differentiation factor"},
    {id: "macrophage/microglia activation-associated factor"},
    {id: "maf"},
    {id: "progestin and adipoq receptor family member 11"},
    {id: "progestin and adipoq receptor family member xi"},
    {id: "monoglyceride lipase"},
    {id: "mgl"},
    {id: "monoacylglycerol lipase"},
    {id: "magl"},
    {id: "mortality factor 4-like protein 2"},
    {id: "liver regeneration-related protein lrrg00119"},
    {id: "morf-related gene x protein"},
    {id: "transcription factor-like protein mrgx"},
    {id: "mothers against decapentaplegic homolog"},
    {id: "mad homolog"},
    {id: "mothers against dpp homolog"},
    {id: "smad family member"},
    {id: "mothers against decapentaplegic homolog 2"},
    {id: "mad homolog 2"},
    {id: "mothers against dpp homolog 2"},
    {id: "mad-related protein 2"},
    {id: "smad family member 2"},
    {id: "smad 2"},
    {id: "smad2"},
    {id: "mothers against decapentaplegic homolog 4"},
    {id: "mad homolog 4"},
    {id: "mothers against dpp homolog 4"},
    {id: "smad family member 4"},
    {id: "smad 4"},
    {id: "smad4"},
    {id: "mothers against decapentaplegic homolog 5"},
    {id: "mad homolog 5"},
    {id: "mothers against dpp homolog 5"},
    {id: "smad family member 5"},
    {id: "smad 5"},
    {id: "smad5"},
    {id: "motor neuron and pancreas homeobox 1"},
    {id: "homeobox protein hb9"},
    {id: "mucolipin 2"},
    {id: "mucolipin trp cation channel 2"},
    {id: "mucolipin 3"},
    {id: "mucolipin trp cation channel 3"},
    {id: "mucosal addressin cell adhesion molecule 1"},
    {id: "madcam-1"},
    {id: "rmadcam-1"},
    {id: "multifunctional fusion protein"},
    {id: "includes: delta-1-pyrroline-5-carboxylate dehydrogenase (p5c dehydrogenase) (l-glutamate gamma-semialdehyde dehydrogenase); l-glutamate gamma-semialdehyde dehydrogenase (ec 1.2.1.88)"},
    {id: "includes: interleukin-1; interleukin-1 beta"},
    {id: "multifunctional procollagen lysine hydroxylase and glycosyltransferase lh3"},
    {id: "includes: procollagen-lysine,2-oxoglutarate 5-dioxygenase 3 (ec 1.14.11.4) (lysyl hydroxylase 3) (lh3); procollagen glycosyltransferase (ec 2.4.1.50) (ec 2.4.1.66) (galactosylhydroxylysine-glucosyltransferase) (procollagen galactosyltransferase) (procollagen glucosyltransferase)"},
    {id: "multiple inositol polyphosphate phosphatase 1"},
    {id: "ec 3.1.3.62"},
    {id: "ec 3.1.3.80"},
    {id: "2,3-bisphosphoglycerate 3-phosphatase"},
    {id: "multivesicular body subunit 12a"},
    {id: "escrt-i complex subunit mvb12a"},
    {id: "protein fam125a"},
    {id: "muscular lmna-interacting protein"},
    {id: "cardiac isl1-interacting protein"},
    {id: "cip"},
    {id: "musculoskeletal embryonic nuclear protein 1"},
    {id: "fracture callus protein mustang"},
    {id: "musculoskeletal temporally activated novel gene protein"},
    {id: "myadm protein"},
    {id: "myeloid-associated differentiation marker"},
    {id: "myc box-dependent-interacting protein 1"},
    {id: "amphiphysin ii"},
    {id: "amphiphysin-like protein"},
    {id: "bridging integrator 1"},
    {id: "myelin and lymphocyte protein"},
    {id: "17 kda myelin vesicular protein"},
    {id: "mvp17"},
    {id: "ns 3"},
    {id: "t-lymphocyte maturation-associated protein"},
    {id: "myelin-associated glycoprotein"},
    {id: "1b236"},
    {id: "brain neuron cytoplasmic protein 3"},
    {id: "sialic acid-binding ig-like lectin 4a"},
    {id: "siglec-4a"},
    {id: "myl6 protein"},
    {id: "myosin light chain 6"},
    {id: "myosin light chain 1/3, skeletal muscle isoform"},
    {id: "mlc1/mlc3"},
    {id: "mlc1f/mlc3f"},
    {id: "myosin light chain alkali 1/2"},
    {id: "myosin light chain a1/a2"},
    {id: "myosin light chain kinase 3"},
    {id: "ec 2.7.11.18"},
    {id: "cardiac-mybp-c-associated ca/cam kinase"},
    {id: "cardiac-mlck"},
    {id: "myosin light chain kinase, smooth muscle"},
    {id: "telokin"},
    {id: "myosin regulatory light chain 2, ventricular/cardiac muscle isoform"},
    {id: "mlc-2"},
    {id: "mlc-2v"},
    {id: "myosin light chain 2, slow skeletal/ventricular muscle isoform"},
    {id: "mlc-2s/v"},
    {id: "myosin regulatory light chain rlc-a"},
    {id: "myosin rlc-a"},
    {id: "myosin regulatory light chain 2-a, smooth muscle isoform"},
    {id: "myotrophin"},
    {id: "granule cell differentiation protein"},
    {id: "protein v-1"},
    {id: "myotubularin"},
    {id: "phosphatidylinositol-3,5-bisphosphate 3-phosphatase"},
    {id: "ec 3.1.3.95"},
    {id: "phosphatidylinositol-3-phosphate phosphatase"},
    {id: "ec 3.1.3.64"},
    {id: "myotubularin-related protein 3"},
    {id: "myotubularin-related protein 6"},
    {id: "beta-n-acetylglucosaminyl"},
    {id: "ec 3.5.1.26"},
    {id: "aspartylglucosaminidase"},
    {id: "aga"},
    {id: "glycosylasparaginase"},
    {id: "n4-(n-acetyl-beta-glucosaminyl)-l-asparagine amidase"},
    {id: "cleaved into: glycosylasparaginase alpha chain; glycosylasparaginase beta chain"},
    {id: "ddah-1"},
    {id: "dimethylarginine dimethylaminohydrolase 1"},
    {id: "ec 3.5.3.18"},
    {id: "ddahi"},
    {id: "dimethylargininase-1"},
    {id: "ddah-2"},
    {id: "dimethylarginine dimethylaminohydrolase 2"},
    {id: "ddahii"},
    {id: "dimethylargininase-2"},
    {id: "alpha"},
    {id: "n-acetyl-d-glucosamine kinase"},
    {id: "ec 2.7.1.59"},
    {id: "glcnac kinase"},
    {id: "n-acetylglucosamine kinase"},
    {id: "muramyl dipeptide kinase"},
    {id: "ec 2.7.1.-"},
    {id: "n-acetyl-d-mannosamine kinase"},
    {id: "ec 2.7.1.60"},
    {id: "n-acetylaspartate synthetase"},
    {id: "naa synthetase"},
    {id: "n-acetyltransferase 8-like protein"},
    {id: "ec 2.3.1.17"},
    {id: "n-acetylaspartylglutamate synthase"},
    {id: "ec 6.3.2.41"},
    {id: "n-acetylated alpha-linked acidic dipeptidase 2"},
    {id: "naalad2 protein"},
    {id: "n-acetylgalactosamine kinase"},
    {id: "ec 2.7.1.157"},
    {id: "galnac kinase"},
    {id: "galactokinase 2"},
    {id: "n-acetylgalactosamine-6-sulfatase"},
    {id: "ec 3.1.6.4"},
    {id: "chondroitinsulfatase"},
    {id: "chondroitinase"},
    {id: "galactose-6-sulfate sulfatase"},
    {id: "n-acetylgalactosamine-6-sulfate sulfatase"},
    {id: "galnac6s sulfatase"},
    {id: "n-acetylglucosamine-6-phosphate deacetylase"},
    {id: "ec 3.5.1.25"},
    {id: "glucosamine-6-sulfatase"},
    {id: "n-acetylglucosaminyl-phosphatidylinositol de-n-acetylase"},
    {id: "ec 3.5.1.89"},
    {id: "phosphatidylinositol-glycan biosynthesis class l protein"},
    {id: "pig-l"},
    {id: "n-acetyllactosaminide alpha-1,3-galactosyltransferase"},
    {id: "ec 2.4.1.87"},
    {id: "udp-galactose:beta-d-galactosyl-1,4-n-acetyl-d-glucosaminide alpha-1,3-galactosyltransferase"},
    {id: "galactosyltransferase"},
    {id: "n-acetyllactosaminide alpha-1,3-galactosyltransferase-like 1"},
    {id: "n-acetylneuraminate lyase"},
    {id: "nalase"},
    {id: "ec 4.1.3.3"},
    {id: "n-acetylneuraminate pyruvate-lyase"},
    {id: "n-acetylneuraminic acid aldolase"},
    {id: "sialate lyase"},
    {id: "sialate-pyruvate lyase"},
    {id: "sialic acid aldolase"},
    {id: "sialic acid lyase"},
    {id: "n-acetyltransferase 8"},
    {id: "acetyltransferase 2"},
    {id: "atase2"},
    {id: "camello-like protein 4"},
    {id: "cysteinyl-conjugate n-acetyltransferase"},
    {id: "ccnat"},
    {id: "ec 2.3.1.80"},
    {id: "gcn5-related"},
    {id: "n-acetyltransferase family 8 member 3"},
    {id: "camello-like protein 3"},
    {id: "n-acetyltransferase cml3"},
    {id: "n-acyl-aliphatic-l-amino acid amidohydrolase"},
    {id: "ec 3.5.1.14"},
    {id: "n-acyl-l-amino-acid amidohydrolase"},
    {id: "n-acyl-aromatic-l-amino acid amidohydrolase"},
    {id: "carboxylate-forming"},
    {id: "ec 3.5.1.114"},
    {id: "aminoacylase-3"},
    {id: "acy-3"},
    {id: "aspartoacylase-2"},
    {id: "n-acyl-phosphatidylethanolamine-hydrolyzing phospholipase d"},
    {id: "ec 3.1.4.54"},
    {id: "n-acyl phosphatidylethanolamine phospholipase d"},
    {id: "nape-pld"},
    {id: "nape-hydrolyzing phospholipase d"},
    {id: "n-acylethanolamine-hydrolyzing acid amidase"},
    {id: "ec 3.5.1.60"},
    {id: "acylsphingosine deacylase naaa"},
    {id: "ec 3.5.1.23"},
    {id: "n-acylsphingosine amidohydrolase-like"},
    {id: "asah-like protein"},
    {id: "cleaved into: n-acylethanolamine-hydrolyzing acid amidase subunit alpha; n-acylethanolamine-hydrolyzing acid amidase subunit beta"},
    {id: "n-acylglucosamine 2-epimerase"},
    {id: "ec 5.1.3.8"},
    {id: "glcnac 2-epimerase"},
    {id: "n-acetyl-d-glucosamine 2-epimerase"},
    {id: "renin-binding protein"},
    {id: "n-acylneuraminate cytidylyltransferase"},
    {id: "ec 2.7.7.43"},
    {id: "cmp-n-acetylneuraminic acid synthase"},
    {id: "n-acylneuraminate-9-phosphatase"},
    {id: "ec 3.1.3.29"},
    {id: "haloacid dehalogenase-like hydrolase domain-containing protein 4"},
    {id: "neu5ac-9-pase"},
    {id: "n-alpha-acetyltransferase 11"},
    {id: "ec 2.3.1.255"},
    {id: "n-terminal acetyltransferase complex ard1 subunit homolog b"},
    {id: "nata catalytic subunit naa11"},
    {id: "n-alpha-acetyltransferase 25, natb auxiliary subunit"},
    {id: "liver regeneration-related protein lrrgt00164"},
    {id: "mitochondrial distribution and morphology protein 20"},
    {id: "n-terminal acetyltransferase b complex subunit mdm20"},
    {id: "natb complex subunit mdm20"},
    {id: "n-terminal acetyltransferase b complex subunit naa25"},
    {id: "n-alpha-acetyltransferase 35, natc auxiliary subunit"},
    {id: "protein mak10 homolog"},
    {id: "n-alpha-acetyltransferase 40"},
    {id: "ec 2.3.1.257"},
    {id: "n-alpha-acetyltransferase 60"},
    {id: "ec 2.3.1.259"},
    {id: "histone acetyltransferase type b protein 4"},
    {id: "n-acetyltransferase 15"},
    {id: "n-alpha-acetyltransferase f"},
    {id: "hat4"},
    {id: "natf"},
    {id: "n-arachidonyl glycine receptor"},
    {id: "nagly receptor"},
    {id: "g-protein coupled receptor 18"},
    {id: "n-glycosylase/dna lyase"},
    {id: "includes: 8-oxoguanine dna glycosylase (ec 3.2.2.-); dna-(apurinic or apyrimidinic site) lyase (ap lyase) (ec 4.2.99.18)"},
    {id: "n-lysine methyltransferase setd6"},
    {id: "set domain-containing protein 6"},
    {id: "n-lysine methyltransferase smyd2"},
    {id: "histone methyltransferase smyd2"},
    {id: "set and mynd domain-containing protein 2"},
    {id: "n-myc"},
    {id: "and stat"},
    {id: "n-terminal ef-hand calcium-binding protein 2"},
    {id: "ef-hand calcium-binding protein 2"},
    {id: "neuronal calcium-binding protein 2"},
    {id: "n-terminal xaa-pro-lys n-methyltransferase 1"},
    {id: "ec 2.1.1.244"},
    {id: "alpha n-terminal protein methyltransferase 1a"},
    {id: "methyltransferase-like protein 11a"},
    {id: "x-pro-lys n-terminal protein methyltransferase 1a"},
    {id: "ntm1a"},
    {id: "cleaved into: n-terminal xaa-pro-lys n-methyltransferase 1, n-terminally processed"},
    {id: "n-terminal xaa-pro-lys n-methyltransferase 2"},
    {id: "ec 2.1.1.299"},
    {id: "alpha n-terminal protein methyltransferase 1b"},
    {id: "methyltransferase-like protein 11b"},
    {id: "x-pro-lys n-terminal protein methyltransferase 1b"},
    {id: "ntm1b"},
    {id: "n-terminal amino-acid n"},
    {id: "n-terminal kinase-like protein"},
    {id: "scy1-like protein 1"},
    {id: "n6-adenosine-methyltransferase non-catalytic subunit"},
    {id: "methyltransferase-like protein 14"},
    {id: "nacht, lrr and pyd domains-containing protein 3"},
    {id: "nad kinase 2, mitochondrial"},
    {id: "ec 2.7.1.23"},
    {id: "mitochondrial nad kinase"},
    {id: "nad kinase domain-containing protein 1, mitochondrial"},
    {id: "ec 3.6.1.22"},
    {id: "nadp(+) hydrolase sarm1"},
    {id: "sterile alpha and tir motif-containing protein 1"},
    {id: "nadase sarm1"},
    {id: "ec 2.4.2.31"},
    {id: "mono(adp-ribosyl)transferase"},
    {id: "h2o2-forming"},
    {id: "ec 5.1.99.6"},
    {id: "apolipoprotein a-i-binding protein"},
    {id: "ai-bp"},
    {id: "nad(p)hx epimerase"},
    {id: "nad-capped rna hydrolase nudt12"},
    {id: "nadh pyrophosphatase nudt12"},
    {id: "nucleoside diphosphate-linked moiety x motif 12"},
    {id: "nad-dependent protein deacetylase"},
    {id: "ec 2.3.1.286"},
    {id: "nad-dependent protein deacetylase sirtuin-7"},
    {id: "nad-dependent protein deacylase sirtuin-7"},
    {id: "regulatory protein sir2 homolog 7"},
    {id: "sir2-like protein 7"},
    {id: "nad-dependent protein deacylase sirtuin-5, mitochondrial"},
    {id: "regulatory protein sir2 homolog 5"},
    {id: "sir2-like protein 5"},
    {id: "nad-dependent protein lipoamidase sirtuin-4, mitochondrial"},
    {id: "nad-dependent adp-ribosyltransferase sirtuin-4"},
    {id: "ec 2.4.2.-"},
    {id: "nad-dependent protein biotinylase sirtuin-4"},
    {id: "nad-dependent protein deacetylase sirtuin-4"},
    {id: "regulatory protein sir2 homolog 4"},
    {id: "sir2-like protein 4"},
    {id: "nadh dehydrogenase"},
    {id: "ubiquinone"},
    {id: "nuclear protein e3-3"},
    {id: "hormone-regulated proliferation-associated protein of 20 kda homolog"},
    {id: "protein hrpap20"},
    {id: "complex i-mwfe"},
    {id: "nadh-ubiquinone oxidoreductase mwfe subunit"},
    {id: "complex i-42kd"},
    {id: "nadh-ubiquinone oxidoreductase 42 kda subunit"},
    {id: "complex i-b14.7"},
    {id: "nadh-ubiquinone oxidoreductase subunit b14.7"},
    {id: "complex i-b9"},
    {id: "nadh-ubiquinone oxidoreductase b9 subunit"},
    {id: "complex i subunit b13"},
    {id: "complex i-13kd-b"},
    {id: "nadh-ubiquinone oxidoreductase 13 kda-b subunit"},
    {id: "complex i-b14.5a"},
    {id: "nadh-ubiquinone oxidoreductase subunit b14.5a"},
    {id: "complex i-19kd"},
    {id: "nadh-ubiquinone oxidoreductase 19 kda subunit"},
    {id: "complex i-pdsw"},
    {id: "nadh-ubiquinone oxidoreductase pdsw subunit"},
    {id: "complex i-esss"},
    {id: "nadh-ubiquinone oxidoreductase esss subunit"},
    {id: "complex i-aggg"},
    {id: "nadh-ubiquinone oxidoreductase aggg subunit"},
    {id: "complex i-b12"},
    {id: "nadh-ubiquinone oxidoreductase b12 subunit"},
    {id: "complex i-b15"},
    {id: "nadh-ubiquinone oxidoreductase b15 subunit"},
    {id: "complex i-sgdh"},
    {id: "nadh-ubiquinone oxidoreductase sgdh subunit"},
    {id: "complex i-b17"},
    {id: "nadh-ubiquinone oxidoreductase b17 subunit"},
    {id: "complex i-b18"},
    {id: "nadh-ubiquinone oxidoreductase b18 subunit"},
    {id: "complex i-ashi"},
    {id: "nadh-ubiquinone oxidoreductase ashi subunit"},
    {id: "complex i-b22"},
    {id: "nadh-ubiquinone oxidoreductase b22 subunit"},
    {id: "ec 7.1.1.2"},
    {id: "nadh-ubiquinone oxidoreductase 24 kda subunit"},
    {id: "complex i-9kd"},
    {id: "ci-9kd"},
    {id: "nadh-ubiquinone oxidoreductase 9 kda subunit"},
    {id: "complex i-49kd"},
    {id: "nadh-ubiquinone oxidoreductase 49 kda subunit"},
    {id: "ci-49kd"},
    {id: "complex i-30kd"},
    {id: "nadh-ubiquinone oxidoreductase 30 kda subunit"},
    {id: "complex i-18 kda"},
    {id: "ci-18 kda"},
    {id: "nadh-ubiquinone oxidoreductase 18 kda subunit"},
    {id: "complex i-15 kda"},
    {id: "nadh-ubiquinone oxidoreductase 15 kda subunit"},
    {id: "complex i-20kd"},
    {id: "nadh-ubiquinone oxidoreductase 20 kda subunit"},
    {id: "complex i-23kd"},
    {id: "nadh-ubiquinone oxidoreductase 23 kda subunit"},
    {id: "nadh-cytochrome b5 reductase"},
    {id: "nadh-ubiquinone oxidoreductase 75 kda subunit, mitochondrial"},
    {id: "nadh-ubiquinone oxidoreductase chain 2"},
    {id: "nadh dehydrogenase subunit 2"},
    {id: "nadh-ubiquinone oxidoreductase chain 4"},
    {id: "nadh dehydrogenase subunit 4"},
    {id: "nadh-ubiquinone oxidoreductase chain 6"},
    {id: "nadh dehydrogenase subunit 6"},
    {id: "nadph oxidase 3"},
    {id: "ec 1.6.3.-"},
    {id: "nadph oxidase 4"},
    {id: "kidney oxidase-1"},
    {id: "kox-1"},
    {id: "kidney superoxide-producing nadph oxidase"},
    {id: "nadph--cytochrome p450 reductase"},
    {id: "p450r"},
    {id: "ec 1.6.2.4"},
    {id: "nadph-dependent diflavin oxidoreductase 1"},
    {id: "ec 1.18.1.-"},
    {id: "nadph-dependent fmn and fad-containing oxidoreductase"},
    {id: "nadph:adrenodoxin oxidoreductase, mitochondrial"},
    {id: "adrenodoxin reductase"},
    {id: "ec 1.18.1.6"},
    {id: "ferredoxin--nadp(+) reductase"},
    {id: "ferredoxin reductase"},
    {id: "nedd1 gamma-tubulin ring complex targeting factor"},
    {id: "neural cell expressed developmentally down-regulated 1"},
    {id: "nedd4-binding protein 2-like 2"},
    {id: "phosphonoformate immuno-associated protein 5 homolog"},
    {id: "nedd8-activating enzyme e1 catalytic subunit"},
    {id: "ec 6.2.1.64"},
    {id: "nedd8-conjugating enzyme ube2f"},
    {id: "ec 2.3.2.32"},
    {id: "nedd8 carrier protein ube2f"},
    {id: "nedd8 protein ligase ube2f"},
    {id: "ring-type e3 nedd8 transferase ube2f"},
    {id: "ubiquitin-conjugating enzyme e2 f"},
    {id: "nel-like 2"},
    {id: "chicken"},
    {id: "neural egfl like 2"},
    {id: "nf-kappa-b inhibitor-like protein 1"},
    {id: "inhibitor of kappa b-like protein"},
    {id: "i-kappa-b-like protein"},
    {id: "ikappabl"},
    {id: "nuclear factor of kappa light polypeptide gene enhancer in b-cells inhibitor-like 1"},
    {id: "nfatc2-interacting protein"},
    {id: "nuclear factor of activated t-cells, cytoplasmic 2-interacting protein"},
    {id: "nfkb inhibitor interacting ras-like 2"},
    {id: "nkiras2 protein"},
    {id: "ngfi-a-binding protein 1"},
    {id: "egr-1-binding protein 1"},
    {id: "nhp2-like protein 1"},
    {id: "high mobility group-like nuclear protein 2 homolog 1"},
    {id: "otk27"},
    {id: "u4/u6.u5 small nuclear ribonucleoprotein snu13"},
    {id: "u4/u6.u5 tri-snrnp 15.5 kda protein"},
    {id: "cleaved into: nhp2-like protein 1, n-terminally processed"},
    {id: "nima"},
    {id: "never in mitosis gene a"},
    {id: "nima-related kinase 4"},
    {id: "nek4 protein"},
    {id: "nlr family card domain-containing protein 4"},
    {id: "ice protease-activating factor"},
    {id: "ipaf"},
    {id: "nme/nm23 family member 8"},
    {id: "txndc3 protein"},
    {id: "nop58 ribonucleoprotein"},
    {id: "nol5 protein"},
    {id: "npc intracellular cholesterol transporter 2"},
    {id: "epididymal secretory protein e1"},
    {id: "npc1-like intracellular cholesterol transporter 1"},
    {id: "npc1l1"},
    {id: "niemann-pick c1-like protein 1"},
    {id: "ns5a"},
    {id: "hepatitis c virus"},
    {id: "ns5atp4"},
    {id: "nsfl1 cofactor p47"},
    {id: "p97 cofactor p47"},
    {id: "nt-3 growth factor receptor"},
    {id: "gp145-trkc"},
    {id: "trk-c"},
    {id: "neurotrophic tyrosine kinase receptor type 3"},
    {id: "trkc tyrosine kinase"},
    {id: "nuak family snf1-like kinase 2"},
    {id: "snf1/amp kinase-related kinase"},
    {id: "snark"},
    {id: "nxpe family member 4"},
    {id: "protein fam55d"},
    {id: "na"},
    {id: "nherf-1"},
    {id: "ezrin-radixin-moesin-binding phosphoprotein 50"},
    {id: "ebp50"},
    {id: "regulatory cofactor of na(+)/h(+) exchanger"},
    {id: "sodium-hydrogen exchanger regulatory factor 1"},
    {id: "solute carrier family 9 isoform a3 regulatory factor 1"},
    {id: "nherf-3"},
    {id: "c-terminal-linking and modulating protein"},
    {id: "dietary pi-regulated rna-1"},
    {id: "diphor-1"},
    {id: "na(+)/h(+) exchanger regulatory factor 3"},
    {id: "na/pi cotransporter c-terminal-associated protein 1"},
    {id: "napi-cap1"},
    {id: "pdz domain-containing protein 1"},
    {id: "sodium-hydrogen exchanger regulatory factor 3"},
    {id: "natural cytotoxicity triggering receptor 3"},
    {id: "natural killer cell p30-related protein"},
    {id: "natural killer cells antigen cd94"},
    {id: "killer cell lectin-like receptor subfamily d member 1"},
    {id: "cd antigen cd94"},
    {id: "nectin cell adhesion molecule 2"},
    {id: "poliovirus receptor-related 2"},
    {id: "nedd4 binding protein 3"},
    {id: "n4bp3"},
    {id: "nel-like 2 splice variant"},
    {id: "nesprin-4"},
    {id: "kash domain-containing protein 4"},
    {id: "kash4"},
    {id: "nuclear envelope spectrin repeat protein 4"},
    {id: "netrin receptor unc5d"},
    {id: "protein unc-5 homolog d"},
    {id: "netrin-5"},
    {id: "netrin-1-like protein"},
    {id: "neudesin"},
    {id: "neuron-derived neurotrophic factor"},
    {id: "scirp10-related protein"},
    {id: "spinal cord injury-related protein 10"},
    {id: "neuferricin"},
    {id: "cytochrome b5 domain-containing protein 2"},
    {id: "neugrin"},
    {id: "neurite outgrowth-associated protein"},
    {id: "neurabin-1"},
    {id: "neurabin-i"},
    {id: "neural tissue-specific f-actin-binding protein i"},
    {id: "pp1bp175"},
    {id: "protein phosphatase 1 regulatory subunit 9a"},
    {id: "p180"},
    {id: "neurabin-2"},
    {id: "neurabin-ii"},
    {id: "neural tissue-specific f-actin-binding protein ii"},
    {id: "pp1bp134"},
    {id: "protein phosphatase 1 regulatory subunit 9b"},
    {id: "spinophilin"},
    {id: "p130"},
    {id: "neuralized 2"},
    {id: "neuralized e3 ubiquitin protein ligase 1b"},
    {id: "neurexin-2-beta"},
    {id: "neurexin ii-beta"},
    {id: "neurite extension and migration factor"},
    {id: "kiaa2022 protein associated with intellectual disability, language impairment and autistic behavior homolog"},
    {id: "kidlia"},
    {id: "xlmr protein related to neurite extension"},
    {id: "xpn"},
    {id: "neurochondrin"},
    {id: "neurite outgrowth-related protein from the rat brain"},
    {id: "norbin"},
    {id: "neurofilament light polypeptide"},
    {id: "nf-l"},
    {id: "68 kda neurofilament protein"},
    {id: "neurofilament triplet l protein"},
    {id: "neurogenic differentiation factor 1"},
    {id: "neurod1"},
    {id: "basic helix-loop-helix factor 1"},
    {id: "bhf-1"},
    {id: "neurogenin-1"},
    {id: "ngn-1"},
    {id: "neurogenic basic-helix-loop-helix protein"},
    {id: "neurogenic differentiation factor 3"},
    {id: "neurod3"},
    {id: "neuroligin-1"},
    {id: "neuroligin i"},
    {id: "neuromodulin"},
    {id: "axonal membrane protein gap-43"},
    {id: "growth-associated protein 43"},
    {id: "protein f1"},
    {id: "neuron-specific calcium-binding protein hippocalcin"},
    {id: "p23k"},
    {id: "neuronal pas domain-containing protein 4"},
    {id: "neuronal pas4"},
    {id: "hlh-pas transcription factor nxf"},
    {id: "neuronal acetylcholine receptor subunit alpha-10"},
    {id: "nicotinic acetylcholine receptor subunit alpha-10"},
    {id: "nachr alpha-10"},
    {id: "neuronal acetylcholine receptor subunit beta-2"},
    {id: "neuronal acetylcholine receptor non-alpha-1 chain"},
    {id: "n-alpha 1"},
    {id: "neuronal acetylcholine receptor subunit beta-4"},
    {id: "neuronal acetylcholine receptor non-alpha-2 chain"},
    {id: "n-alpha 2"},
    {id: "neuronal calcium sensor 1"},
    {id: "ncs-1"},
    {id: "frequenin homolog"},
    {id: "frequenin-like protein"},
    {id: "frequenin-like ubiquitous protein"},
    {id: "neuronal growth regulator 1"},
    {id: "kindred of iglon"},
    {id: "kilon"},
    {id: "neuronal membrane glycoprotein m6-a"},
    {id: "m6a"},
    {id: "neuronal pentraxin-1"},
    {id: "np1"},
    {id: "47 kda taipoxin-binding protein"},
    {id: "neuronal pentraxin i"},
    {id: "np-i"},
    {id: "neuronal vesicle trafficking-associated protein 2"},
    {id: "neuron-specific protein family member 2"},
    {id: "neuropeptide b"},
    {id: "preproprotein l7"},
    {id: "rppl7"},
    {id: "cleaved into: neuropeptide b-29 (npb29) (l7c)"},
    {id: "neuropeptide ff receptor 2"},
    {id: "g-protein coupled receptor 74"},
    {id: "neuropeptide g-protein coupled receptor"},
    {id: "neuropeptide s receptor"},
    {id: "g-protein coupled receptor 154"},
    {id: "neuropeptide w"},
    {id: "preproprotein l8"},
    {id: "ppl8"},
    {id: "cleaved into: neuropeptide w-23 (npw23) (l8); neuropeptide w-30 (npw30) (l8c)"},
    {id: "neuropeptide y receptor type 4"},
    {id: "npy4-r"},
    {id: "pancreatic polypeptide receptor 1"},
    {id: "pp1"},
    {id: "neuropeptides b/w receptor type 1"},
    {id: "g-protein coupled receptor 7"},
    {id: "neuropilin and tolloid-like protein 2"},
    {id: "brain-specific transmembrane protein containing 2 cub and 1 ldl-receptor class a domains protein 2"},
    {id: "neuropilin-1"},
    {id: "vascular endothelial cell growth factor 165 receptor"},
    {id: "cd antigen cd304"},
    {id: "neurosecretory protein vgf"},
    {id: "vgf8a protein"},
    {id: "cleaved into: vgf(24-63); vgf(180-194); vgf(375-407); neuroendocrine regulatory peptide-1 (nerp-1); neuroendocrine regulatory peptide-2 (nerp-2); vgf-derived peptide tlqp-11; vgf-derived peptide tlqp-21; vgf-derived peptide tlqp-30; vgf-derived peptide tlqp-62; vgf-derived peptide hfhh-10; vgf-derived peptide aqee-30; vgf-derived peptide lqeq-19"},
    {id: "neuroserpin"},
    {id: "peptidase inhibitor 12"},
    {id: "pi-12"},
    {id: "serine protease inhibitor 17"},
    {id: "serpin i1"},
    {id: "neurotensin receptor type 1"},
    {id: "nt-r-1"},
    {id: "ntr1"},
    {id: "high-affinity levocabastine-insensitive neurotensin receptor"},
    {id: "ntrh"},
    {id: "neurotrophin-3"},
    {id: "nt-3"},
    {id: "hdnf"},
    {id: "nerve growth factor 2"},
    {id: "ngf-2"},
    {id: "neurotrophic factor"},
    {id: "neurotrophin-4"},
    {id: "nt-4"},
    {id: "neurotrophin-5"},
    {id: "nt-5"},
    {id: "neutrophic factor 4"},
    {id: "neurotrypsin"},
    {id: "serine protease 12"},
    {id: "neutral amino acid transporter 9"},
    {id: "solute carrier family 38 member 9"},
    {id: "neutral amino acid transporter b"},
    {id: "atb(0)"},
    {id: "asc-like na(+)-dependent neutral amino acid transporter asct2"},
    {id: "insulin-activated amino acid transporter"},
    {id: "sodium-dependent neutral amino acid transporter type 2"},
    {id: "neutral and basic amino acid transport protein rbat"},
    {id: "d2"},
    {id: "solute carrier family 3 member 1"},
    {id: "b(0,+)-type amino acid transport protein"},
    {id: "naa-tr"},
    {id: "neutral ceramidase"},
    {id: "n-cdase"},
    {id: "ncdase"},
    {id: "acylsphingosine deacylase 2"},
    {id: "n-acylsphingosine amidohydrolase 2"},
    {id: "cleaved into: neutral ceramidase soluble form"},
    {id: "neutral cholesterol ester hydrolase 1"},
    {id: "nceh"},
    {id: "acetylalkylglycerol acetylhydrolase"},
    {id: "2-acetyl mage hydrolase"},
    {id: "ec 3.1.1.71"},
    {id: "arylacetamide deacetylase-like 1"},
    {id: "neutrophil antibiotic peptide np-2"},
    {id: "ratnp-2"},
    {id: "neutrophil defensin 2"},
    {id: "neutrophil antibiotic peptide np-3b"},
    {id: "ratnp-3b"},
    {id: "neutrophil defensin 3"},
    {id: "neutrophil cytosolic factor 1"},
    {id: "ncf-1"},
    {id: "neutrophil gelatinase-associated lipocalin"},
    {id: "ngal"},
    {id: "alpha-2-microglobulin-related protein"},
    {id: "alpha-2u globulin-related protein"},
    {id: "lipocalin 24p3"},
    {id: "lipocalin-2"},
    {id: "siderocalin lcn2"},
    {id: "p25"},
    {id: "nexilin"},
    {id: "nicotinamide phosphoribosyltransferase"},
    {id: "namprtase"},
    {id: "ec 2.4.2.12"},
    {id: "nampt"},
    {id: "pre-b-cell colony-enhancing factor 1 homolog"},
    {id: "pbef"},
    {id: "visfatin"},
    {id: "nicotinamide riboside kinase 1"},
    {id: "nrk 1"},
    {id: "nmr-k 1"},
    {id: "ec 2.7.1.22"},
    {id: "nicotinic acid riboside kinase 1"},
    {id: "ec 2.7.1.173"},
    {id: "ribosylnicotinamide kinase 1"},
    {id: "rnk 1"},
    {id: "ribosylnicotinic acid kinase 1"},
    {id: "nicotinamide-nucleotide adenylyltransferase"},
    {id: "ec 2.7.7.1"},
    {id: "ec 2.7.7.18"},
    {id: "nicotinate phosphoribosyltransferase"},
    {id: "ec 6.3.4.21"},
    {id: "nicotinate-nucleotide pyrophosphorylase"},
    {id: "carboxylating"},
    {id: "ec 2.4.2.19"},
    {id: "quinolinate phosphoribosyltransferase [decarboxylating]"},
    {id: "qaprtase"},
    {id: "qprtase"},
    {id: "nidogen-2"},
    {id: "nid-2"},
    {id: "ninjurin-2"},
    {id: "nerve injury-induced protein 2"},
    {id: "nipsnap homolog 3a"},
    {id: "c. elegans"},
    {id: "nipsnap homolog 3b"},
    {id: "nitric oxide synthase"},
    {id: "ec 1.14.13.39"},
    {id: "nitric oxide synthase-interacting protein"},
    {id: "e3 ubiquitin-protein ligase nosip"},
    {id: "ring-type e3 ubiquitin transferase nosip"},
    {id: "nociceptin receptor"},
    {id: "kappa-type 3 opioid receptor"},
    {id: "kor-3"},
    {id: "orphanin fq receptor"},
    {id: "ror-c"},
    {id: "xor1"},
    {id: "nocturnin"},
    {id: "ec 3.1.3.108"},
    {id: "carbon catabolite repression 4-like protein"},
    {id: "noelin"},
    {id: "1b426b"},
    {id: "neuronal olfactomedin-related er localized protein"},
    {id: "olfactomedin-1"},
    {id: "pancortin"},
    {id: "noelin-2"},
    {id: "olfactomedin-2"},
    {id: "noelin-3"},
    {id: "olfactomedin-3"},
    {id: "optimedin"},
    {id: "non-pou domain-containing octamer-binding protein"},
    {id: "nono protein"},
    {id: "non-lysosomal glucosylceramidase"},
    {id: "nlgase"},
    {id: "non-structural maintenance of chromosomes element 1 homolog"},
    {id: "noncompact myelin-associated protein"},
    {id: "myelin protein of 11 kda"},
    {id: "mp11"},
    {id: "nostrin"},
    {id: "bm247"},
    {id: "nitric oxide synthase trafficker"},
    {id: "enos-trafficking inducer"},
    {id: "novel acetylcholine receptor chaperone"},
    {id: "spinal cord expression protein 4"},
    {id: "tmem35 gene-derived unknown factor 1"},
    {id: "transmembrane protein 35a"},
    {id: "nuclear autoantigenic sperm protein"},
    {id: "nasp"},
    {id: "nuclear cap-binding protein subunit 1"},
    {id: "80 kda nuclear cap-binding protein"},
    {id: "cbp80"},
    {id: "ncbp 80 kda subunit"},
    {id: "nuclear cap-binding protein subunit 2"},
    {id: "20 kda nuclear cap-binding protein"},
    {id: "nuclear distribution protein nude-like 1"},
    {id: "protein nudel"},
    {id: "nuclear envelope phosphatase-regulatory subunit 1"},
    {id: "transmembrane protein 188"},
    {id: "nuclear factor nf-kappa-b p105 subunit"},
    {id: "dna-binding factor kbf1"},
    {id: "ebp-1"},
    {id: "nuclear factor of kappa light polypeptide gene enhancer in b-cells 1"},
    {id: "cleaved into: nuclear factor nf-kappa-b p50 subunit"},
    {id: "nuclear factor erythroid 2-related factor 2"},
    {id: "nf-e2-related factor 2"},
    {id: "nfe2-related factor 2"},
    {id: "nuclear factor, erythroid derived 2, like 2"},
    {id: "nuclear factor interleukin-3-regulated protein"},
    {id: "e4 promoter-binding protein 4"},
    {id: "nuclear factor kappab subunit p65"},
    {id: "rela proto-oncogene, nf-kb subunit"},
    {id: "v-rel reticuloendotheliosis viral oncogene homolog a (avian)"},
    {id: "nuclear factor of activated t-cells 5"},
    {id: "nf-at5"},
    {id: "t-cell transcription factor nfat5"},
    {id: "nuclear factor of activated t-cells, cytoplasmic 4"},
    {id: "nf-atc4"},
    {id: "nfatc4"},
    {id: "t-cell transcription factor nfat3"},
    {id: "nf-at3"},
    {id: "nuclear migration protein nudc"},
    {id: "nuclear distribution protein c homolog"},
    {id: "c15"},
    {id: "nuclear pore complex protein nup54"},
    {id: "54 kda nucleoporin"},
    {id: "nucleoporin nup54"},
    {id: "nuclear pore complex protein nup85"},
    {id: "85 kda nucleoporin"},
    {id: "nucleoporin nup85"},
    {id: "pericentrin-1"},
    {id: "nuclear pore complex protein nup93"},
    {id: "93 kda nucleoporin"},
    {id: "nucleoporin nup93"},
    {id: "nuclear pore complex protein nup98-nup96"},
    {id: "cleaved into: nuclear pore complex protein nup98 (98 kda nucleoporin) (nucleoporin nup98) (nup98); nuclear pore complex protein nup96 (96 kda nucleoporin) (nucleoporin nup96) (nup96)"},
    {id: "nuclear pore glycoprotein p62"},
    {id: "62 kda nucleoporin"},
    {id: "nucleoporin nup62"},
    {id: "nuclear pore membrane glycoprotein 210"},
    {id: "nuclear pore protein gp210"},
    {id: "nuclear envelope pore membrane protein pom 210"},
    {id: "pom210"},
    {id: "nucleoporin nup210"},
    {id: "pore membrane protein of 210 kda"},
    {id: "nuclear protein localization protein 4 homolog"},
    {id: "protein npl4"},
    {id: "nuclear receptor subfamily 0 group b member 1"},
    {id: "nuclear receptor dax-1"},
    {id: "nuclear receptor subfamily 0 group b member 2"},
    {id: "orphan nuclear receptor shp"},
    {id: "small heterodimer partner"},
    {id: "nuclear receptor subfamily 1 group d member 2"},
    {id: "ear4"},
    {id: "rev-erb-beta"},
    {id: "nuclear receptor subfamily 1 group i member 2"},
    {id: "orphan nuclear receptor pxr"},
    {id: "pregnane x receptor"},
    {id: "constitutive androstane receptor"},
    {id: "nuclear receptor subfamily 1, group i, member 3"},
    {id: "uncharacterized protein car"},
    {id: "nuclear receptor subfamily 2 group c member 1"},
    {id: "orphan nuclear receptor tr2"},
    {id: "testicular receptor 2"},
    {id: "nuclear receptor subfamily 4 group a member 1"},
    {id: "nur77"},
    {id: "nerve growth factor-induced protein i-b"},
    {id: "ngfi-b"},
    {id: "orphan nuclear receptor hmr"},
    {id: "nuclear receptor subfamily 4 group a member 3"},
    {id: "neuron-derived orphan receptor 1/2"},
    {id: "nuclear hormone receptor nor-1/nor-2"},
    {id: "nuclear receptor subfamily 5 group a member 2"},
    {id: "ftz-f1 beta"},
    {id: "liver receptor homolog 1"},
    {id: "lrh-1"},
    {id: "nuclear speckle splicing regulatory protein 1"},
    {id: "coiled-coil domain-containing protein 55"},
    {id: "nuclear speckle-related protein 70"},
    {id: "nsrp70"},
    {id: "nuclear transport factor 2"},
    {id: "ntf-2"},
    {id: "nucleolar rna helicase 2"},
    {id: "dead box protein 21"},
    {id: "gu-alpha"},
    {id: "nucleolar rna helicase gu"},
    {id: "nucleolar rna helicase ii"},
    {id: "rh ii/gu"},
    {id: "nucleolar complex protein 3 homolog"},
    {id: "noc3 protein homolog"},
    {id: "nucleolar protein 12"},
    {id: "25 kda nucleolar protein"},
    {id: "nucleolar protein 16"},
    {id: "protein zh1"},
    {id: "nucleolar protein 58"},
    {id: "nopp140-associated protein of 65 kda"},
    {id: "nucleolar protein 5"},
    {id: "nucleoporin 37"},
    {id: "nup37 protein"},
    {id: "nucleoporin ndc1"},
    {id: "transmembrane protein 48"},
    {id: "nucleoporin nup35"},
    {id: "35 kda nucleoporin"},
    {id: "nuclear pore complex protein nup53"},
    {id: "nucleoporin nup53"},
    {id: "nucleoporin p58/p45"},
    {id: "58 kda nucleoporin"},
    {id: "nucleoporin-like protein 1"},
    {id: "nucleoredoxin"},
    {id: "ec 1.8.1.8"},
    {id: "nucleoside diphosphate kinase"},
    {id: "nucleoside diphosphate kinase a"},
    {id: "ndk a"},
    {id: "ndp kinase a"},
    {id: "metastasis inhibition factor nm23"},
    {id: "tumor metastatic process-associated protein"},
    {id: "nucleoside diphosphate kinase b"},
    {id: "ndk b"},
    {id: "ndp kinase b"},
    {id: "histidine protein kinase ndkb"},
    {id: "ec 2.7.13.3"},
    {id: "p18"},
    {id: "nucleoside diphosphate-linked moiety x motif 6"},
    {id: "nudix motif 6"},
    {id: "protein gfg"},
    {id: "nucleus accumbens-associated protein 1"},
    {id: "nac-1"},
    {id: "btb/poz domain-containing protein 14b"},
    {id: "nudc domain containing 3"},
    {id: "nudcd3 protein"},
    {id: "nurim"},
    {id: "nuclear envelope membrane protein"},
    {id: "nuclear rim protein"},
    {id: "rgd1309543 protein"},
    {id: "mapo2"},
    {id: "sperm-tail pg-rich repeat-containing protein 1"},
    {id: "o-phosphoseryl-trna"},
    {id: "sec"},
    {id: "ec 2.9.1.2"},
    {id: "selenocysteine synthase"},
    {id: "selenocysteinyl-trna(sec) synthase"},
    {id: "sep-trna:sec-trna synthase"},
    {id: "oca2 melanosomal transmembrane protein"},
    {id: "pink-eyed dilution"},
    {id: "okl38-2.3"},
    {id: "okl38-2.3a"},
    {id: "oxidative stress induced growth inhibitor 1"},
    {id: "occludin/ell domain containing 1"},
    {id: "ocel1 protein"},
    {id: "odontogenic ameloblast-associated protein"},
    {id: "apin"},
    {id: "olfactory receptor 1073"},
    {id: "putative gustatory receptor pte45"},
    {id: "olfactory receptor 1366"},
    {id: "olr1366 protein"},
    {id: "olfactory receptor 1743"},
    {id: "olfactory receptor 8, rat orthologue of mouse mor121-1"},
    {id: "oligodendrocyte transcription factor 1"},
    {id: "oligo1"},
    {id: "olg-1 bhlh protein"},
    {id: "oligoribonuclease, mitochondrial"},
    {id: "ec 3.1.15.-"},
    {id: "rna exonuclease 2 homolog"},
    {id: "small fragment nuclease"},
    {id: "oncoprotein-induced transcript 3 protein"},
    {id: "liver-specific zona pellucida domain-containing protein"},
    {id: "oncostatin-m"},
    {id: "osm"},
    {id: "opalin"},
    {id: "oligodendrocytic myelin paranodal and inner loop protein"},
    {id: "transmembrane protein 10"},
    {id: "orexigenic neuropeptide qrfp"},
    {id: "p518"},
    {id: "cleaved into: qrf-amide (neuropeptide rf-amide) (pyroglutamylated arginine-phenylalanine-amide peptide)"},
    {id: "organic cation/carnitine transporter 2"},
    {id: "ct1"},
    {id: "high-affinity sodium-dependent carnitine cotransporter"},
    {id: "solute carrier family 22 member 5"},
    {id: "ust2r"},
    {id: "ornithine aminotransferase, mitochondrial"},
    {id: "ec 2.6.1.13"},
    {id: "ornithine--oxo-acid aminotransferase"},
    {id: "osteocalcin"},
    {id: "bone gla protein"},
    {id: "bgp"},
    {id: "gamma-carboxyglutamic acid-containing protein"},
    {id: "osteoclastogenesis associated transmembrane protein 1"},
    {id: "osteopetrosis associated transmembrane protein 1"},
    {id: "osteocrin"},
    {id: "musclin"},
    {id: "cleaved into: processed osteocrin"},
    {id: "otoferlin"},
    {id: "fer-1-like protein 2"},
    {id: "outer dense fiber protein 2"},
    {id: "84 kda outer dense fiber protein"},
    {id: "cenexin"},
    {id: "outer dense fiber of sperm tails protein 2"},
    {id: "outer dynein arm-docking complex subunit 4"},
    {id: "tetratricopeptide repeat protein 25"},
    {id: "oxidative stress-responsive serine-rich protein 1"},
    {id: "oxidative stress-responsive protein 1"},
    {id: "peroxide-inducible transcript 1 protein"},
    {id: "oxidized purine nucleoside triphosphate hydrolase"},
    {id: "ec 3.6.1.56"},
    {id: "2-hydroxy-datp diphosphatase"},
    {id: "7,8-dihydro-8-oxoguanine triphosphatase"},
    {id: "8-oxo-dgtpase"},
    {id: "methylated purine nucleoside triphosphate hydrolase"},
    {id: "nucleoside diphosphate-linked moiety x motif 1"},
    {id: "nudix motif 1"},
    {id: "p21"},
    {id: "rac1"},
    {id: "p2r1a-ppp2r2a-interacting phosphatase regulator 1"},
    {id: "pabir family member 1"},
    {id: "p2x purinoceptor 2"},
    {id: "p2x2"},
    {id: "p2x purinoceptor 4"},
    {id: "p2x4"},
    {id: "p2x purinoceptor 6"},
    {id: "p2x6"},
    {id: "p2xm"},
    {id: "purinergic receptor p2x-like 1"},
    {id: "p2y purinoceptor 12"},
    {id: "p2y12"},
    {id: "p2y12 platelet adp receptor"},
    {id: "p2y purinoceptor 13"},
    {id: "p2y13"},
    {id: "p2y purinoceptor 6"},
    {id: "p2y6"},
    {id: "p2y receptor family member 10"},
    {id: "p2ry10 protein"},
    {id: "pan2-pan3 deadenylation complex catalytic subunit pan2"},
    {id: "inactive ubiquitin carboxyl-terminal hydrolase 52"},
    {id: "pab1p-dependent poly(a)-specific ribonuclease"},
    {id: "poly(a)-nuclease deadenylation complex subunit 2"},
    {id: "pan deadenylation complex subunit 2"},
    {id: "pan2-pan3 deadenylation complex subunit pan3"},
    {id: "poly(a)-nuclease deadenylation complex subunit 3"},
    {id: "pan deadenylation complex subunit 3"},
    {id: "pat complex subunit ccdc47"},
    {id: "coiled-coil domain-containing protein 47"},
    {id: "pbx homeobox 2"},
    {id: "pre-b-cell leukemia transcription factor 2"},
    {id: "pc-esterase domain-containing protein 1b"},
    {id: "protein fam113b"},
    {id: "pci domain-containing protein 2"},
    {id: "csn12-like protein"},
    {id: "pcna-associated factor"},
    {id: "pcna-associated factor of 15 kda"},
    {id: "pcna-clamp-associated factor"},
    {id: "pcna-interacting partner"},
    {id: "pari"},
    {id: "antisense rna overlapping mch gene protein"},
    {id: "arom"},
    {id: "parp-1 binding protein"},
    {id: "parp1-binding protein"},
    {id: "parpbp"},
    {id: "pdz and lim domain protein 1"},
    {id: "c-terminal lim domain protein 1"},
    {id: "elfin"},
    {id: "lim domain protein clp-36"},
    {id: "pdz domain-containing protein gipc1"},
    {id: "gaip c-terminus-interacting protein"},
    {id: "glut1 c-terminal-binding protein"},
    {id: "glut1cbp"},
    {id: "rgs-gaip-interacting protein"},
    {id: "rgs19-interacting protein 1"},
    {id: "synectin"},
    {id: "pest proteolytic signal-containing nuclear protein"},
    {id: "pcnp"},
    {id: "pest-containing nuclear protein"},
    {id: "liver regeneration-related protein lrrg084"},
    {id: "phd and ring finger domain-containing protein 1"},
    {id: "ctd-binding sr-like protein ra9"},
    {id: "phd finger protein 1"},
    {id: "phf1 protein"},
    {id: "phd finger-like domain-containing protein 5a"},
    {id: "phd finger-like domain protein 5a"},
    {id: "splicing factor 3b-associated 14 kda protein"},
    {id: "sf3b14b"},
    {id: "pilr alpha-associated neural protein"},
    {id: "pilr-associating neural protein"},
    {id: "paired immunoglobin-like type 2 receptor-associating neural protein"},
    {id: "pin2/terf1-interacting telomerase inhibitor 1"},
    {id: "pin2-interacting protein x1"},
    {id: "pnkd metallo-beta-lactamase domain containing"},
    {id: "pnkd protein"},
    {id: "pou domain, class 2, transcription factor 3"},
    {id: "octamer-binding protein 11"},
    {id: "oct-11"},
    {id: "octamer-binding transcription factor 11"},
    {id: "otf-11"},
    {id: "transcription factor skn-1"},
    {id: "pou domain, class 3, transcription factor 4"},
    {id: "brain-specific homeobox/pou domain protein 4"},
    {id: "brain-4"},
    {id: "brn-4"},
    {id: "octamer-binding protein 9"},
    {id: "oct-9"},
    {id: "octamer-binding transcription factor 9"},
    {id: "otf-9"},
    {id: "rhs2 class iii pou protein"},
    {id: "pou domain, class 4, transcription factor 2"},
    {id: "brain-specific homeobox/pou domain protein 3b"},
    {id: "brain-3b"},
    {id: "brn-3b"},
    {id: "brn-3.2"},
    {id: "pou domain, class 6, transcription factor 1"},
    {id: "brain-specific homeobox/pou domain protein 5"},
    {id: "brain-5"},
    {id: "brn-5"},
    {id: "pr domain zinc finger protein 1"},
    {id: "pr domain zinc finger protein 10"},
    {id: "pr domain-containing protein 10"},
    {id: "pr domain zinc finger protein 12"},
    {id: "pr domain-containing protein 12"},
    {id: "pr-vbeta1"},
    {id: "submaxillary gland androgen regulated protein 3a"},
    {id: "pra1 family protein 3"},
    {id: "adp-ribosylation factor-like protein 6-interacting protein 5"},
    {id: "arl-6-interacting protein 5"},
    {id: "aip-5"},
    {id: "gtrap3-18"},
    {id: "glutamate transporter eaac1-interacting protein"},
    {id: "prenylated rab acceptor protein 2"},
    {id: "protein jwa"},
    {id: "preli domain containing protein 3b"},
    {id: "protein slowmo homolog 2"},
    {id: "prkca-binding protein"},
    {id: "protein interacting with c kinase 1"},
    {id: "protein kinase c-alpha-binding protein"},
    {id: "pwwp domain containing 2a"},
    {id: "pwwp2a protein"},
    {id: "px domain-containing protein kinase-like protein"},
    {id: "modulator of na,k-atpase"},
    {id: "monaka"},
    {id: "pachytene checkpoint protein 2 homolog"},
    {id: "tr-interacting protein 13"},
    {id: "trip-13"},
    {id: "thyroid hormone receptor interactor 13"},
    {id: "thyroid receptor-interacting protein 13"},
    {id: "paired box protein pax-6"},
    {id: "oculorhombin"},
    {id: "paired mesoderm homeobox protein 1"},
    {id: "paired-related homeobox protein 1"},
    {id: "prx-1"},
    {id: "rhox"},
    {id: "palmitoyl-protein thioesterase 1"},
    {id: "ppt-1"},
    {id: "palmitoyl-protein hydrolase 1"},
    {id: "palmitoyltransferase"},
    {id: "ec 2.3.1.225"},
    {id: "palmitoyltransferase zdhhc15"},
    {id: "acyltransferase zdhhc15"},
    {id: "zinc finger dhhc domain-containing protein 15"},
    {id: "dhhc-15"},
    {id: "palmitoyltransferase zdhhc2"},
    {id: "acyltransferase zdhhc2"},
    {id: "zinc finger dhhc domain-containing protein 2"},
    {id: "dhhc-2"},
    {id: "palmitoyltransferase zdhhc22"},
    {id: "zinc finger dhhc domain-containing protein 22"},
    {id: "dhhc-22"},
    {id: "zdhhc22"},
    {id: "palmitoyltransferase zdhhc3"},
    {id: "acyltransferase zdhhc3"},
    {id: "zinc finger dhhc domain-containing protein 3"},
    {id: "palmitoyltransferase zdhhc5"},
    {id: "zinc finger dhhc domain-containing protein 5"},
    {id: "dhhc-5"},
    {id: "palmitoyltransferase zdhhc7"},
    {id: "acyltransferase zdhhc7"},
    {id: "sertoli cell gene with a zinc finger domain protein"},
    {id: "zinc finger dhhc domain-containing protein 7"},
    {id: "dhhc7"},
    {id: "pancreas transcription factor 1 subunit alpha"},
    {id: "pancreas-specific transcription factor 1a"},
    {id: "bhlh transcription factor p48"},
    {id: "p48 dna-binding subunit of transcription factor ptf1"},
    {id: "ptf1-p48"},
    {id: "pancreas/duodenum homeobox protein 1"},
    {id: "insulin promoter factor 1"},
    {id: "ipf-1"},
    {id: "islet/duodenum homeobox 1"},
    {id: "idx-1"},
    {id: "somatostatin-transactivating factor 1"},
    {id: "stf-1"},
    {id: "pancreatic polypeptide prohormone"},
    {id: "cleaved into: pancreatic polypeptide (pp); c-terminal peptide"},
    {id: "pancreatic progenitor cell differentiation and proliferation factor"},
    {id: "exocrine differentiation and proliferation factor"},
    {id: "paralemmin-1"},
    {id: "paralemmin"},
    {id: "paraoxonase"},
    {id: "ec 3.1.1.2"},
    {id: "parathyroid hormone"},
    {id: "pth"},
    {id: "parathyrin"},
    {id: "parathyroid hormone/parathyroid hormone-related peptide receptor"},
    {id: "pth/pthrp type i receptor"},
    {id: "pth/pthr receptor"},
    {id: "parathyroid hormone 1 receptor"},
    {id: "pth1 receptor"},
    {id: "parkin rbr e3 ubiquitin protein ligase"},
    {id: "truncated parkin variant sv2bins (ec 6.3.2.19)"},
    {id: "parkinson disease protein 7 homolog"},
    {id: "contraception-associated protein 1"},
    {id: "protein cap1"},
    {id: "fertility protein sp22"},
    {id: "maillard deglycase"},
    {id: "parkinsonism-associated deglycase"},
    {id: "protein dj-1"},
    {id: "dj-1"},
    {id: "protein/nucleic acid deglycase dj-1"},
    {id: "ec 3.1.2.-, ec 3.5.1.-, ec 3.5.1.124"},
    {id: "partitioning defective 3 homolog"},
    {id: "par-3"},
    {id: "pard-3"},
    {id: "atypical pkc isotype-specific-interacting protein"},
    {id: "asip"},
    {id: "atypical pkc-specific-binding protein"},
    {id: "asbp"},
    {id: "patatin-like phospholipase domain-containing protein 2"},
    {id: "adipose triglyceride lipase"},
    {id: "calcium-independent phospholipase a2-zeta"},
    {id: "ipla2-zeta"},
    {id: "ec 3.1.1.4"},
    {id: "patched"},
    {id: "patched 1"},
    {id: "pcbp2 protein"},
    {id: "poly(rc) binding protein 2"},
    {id: "pcgf5 protein"},
    {id: "polycomb group ring finger 5"},
    {id: "pcolce protein"},
    {id: "procollagen c-endopeptidase enhancer"},
    {id: "pcyox1l protein"},
    {id: "prenylcysteine oxidase 1 like"},
    {id: "pdxp protein"},
    {id: "pyridoxal phosphatase"},
    {id: "pecanex-like protein 1"},
    {id: "pecanex 1"},
    {id: "pecanex homolog protein 1"},
    {id: "peflin"},
    {id: "pef protein with a long n-terminal hydrophobic domain"},
    {id: "penta-ef hand domain-containing protein 1"},
    {id: "pendrin"},
    {id: "sodium-independent chloride/iodide transporter"},
    {id: "solute carrier family 26 member 4"},
    {id: "pentatricopeptide repeat domain 3"},
    {id: "ptcd3 protein"},
    {id: "peptide deformylase"},
    {id: "ec 3.5.1.88"},
    {id: "peptide-n"},
    {id: "n-acetyl-beta-glucosaminyl"},
    {id: "ec 3.5.1.52"},
    {id: "n-glycanase 1"},
    {id: "peptide:n-glycanase"},
    {id: "pngase"},
    {id: "peptide-methionine"},
    {id: "peptidyl-prolyl cis-trans isomerase"},
    {id: "ec 5.2.1.8"},
    {id: "ppiase"},
    {id: "peptidyl-prolyl cis-trans isomerase b"},
    {id: "ppiase b"},
    {id: "cyp-s1"},
    {id: "cyclophilin b"},
    {id: "rotamase b"},
    {id: "s-cyclophilin"},
    {id: "scylp"},
    {id: "peptidyl-prolyl cis-trans isomerase e"},
    {id: "ppiase e"},
    {id: "peptidyl-prolyl cis-trans isomerase f, mitochondrial"},
    {id: "ppiase f"},
    {id: "cyclophilin d"},
    {id: "cyp-d"},
    {id: "cypd"},
    {id: "cyclophilin f"},
    {id: "rotamase f"},
    {id: "peptidyl-prolyl cis-trans isomerase fkbp1b"},
    {id: "ppiase fkbp1b"},
    {id: "12.6 kda fk506-binding protein"},
    {id: "12.6 kda fkbp"},
    {id: "fkbp-12.6"},
    {id: "calstabin-2"},
    {id: "fk506-binding protein 1b"},
    {id: "fkbp-1b"},
    {id: "immunophilin fkbp12.6"},
    {id: "rotamase"},
    {id: "peptidyl-prolyl cis-trans isomerase fkbp4"},
    {id: "ppiase fkbp4"},
    {id: "52 kda fk506-binding protein"},
    {id: "52 kda fkbp"},
    {id: "fkbp-52"},
    {id: "59 kda immunophilin"},
    {id: "p59"},
    {id: "fk506-binding protein 4"},
    {id: "fkbp-4"},
    {id: "fkbp59"},
    {id: "hsp-binding immunophilin"},
    {id: "hbi"},
    {id: "immunophilin fkbp52"},
    {id: "cleaved into: peptidyl-prolyl cis-trans isomerase fkbp4, n-terminally processed"},
    {id: "peptidyl-prolyl cis-trans isomerase fkbp8"},
    {id: "ppiase fkbp8"},
    {id: "fk506-binding protein 8"},
    {id: "fkbp-8"},
    {id: "peptidyl-prolyl cis-trans isomerase fkbp9"},
    {id: "ppiase fkbp9"},
    {id: "fk506-binding protein 9"},
    {id: "fkbp-9"},
    {id: "peptidyl-prolyl cis-trans isomerase-like 3"},
    {id: "cyp10l"},
    {id: "cyclophilin-like protein ppil3"},
    {id: "rotamase ppil3"},
    {id: "perforin 1"},
    {id: "perforin 1 (pore forming protein)"},
    {id: "perilipin-5"},
    {id: "lipid storage droplet protein 5"},
    {id: "period circadian protein homolog 1"},
    {id: "rper1"},
    {id: "circadian clock protein period 1"},
    {id: "period circadian protein homolog 2"},
    {id: "rper2"},
    {id: "circadian clock protein period 2"},
    {id: "peroxiredoxin-1"},
    {id: "ec 1.11.1.24"},
    {id: "hbp23"},
    {id: "heme-binding 23 kda protein"},
    {id: "thioredoxin peroxidase 2"},
    {id: "thioredoxin-dependent peroxide reductase 2"},
    {id: "thioredoxin-dependent peroxiredoxin 1"},
    {id: "peroxiredoxin-5"},
    {id: "peroxiredoxin-6"},
    {id: "ec 1.11.1.27"},
    {id: "peroxisomal 2,4-dienoyl-coa reductase"},
    {id: "(3e)-enoyl-coa-producing"},
    {id: "ec 1.3.1.124"},
    {id: "2,4-dienoyl-coa reductase 2"},
    {id: "dcr-akl"},
    {id: "pvi-akl"},
    {id: "peroxisomal atpase pex1"},
    {id: "peroxin-1"},
    {id: "peroxisomal atpase pex6"},
    {id: "peroxin-6"},
    {id: "peroxisomal biogenesis factor 6"},
    {id: "peroxisome assembly factor 2"},
    {id: "paf-2"},
    {id: "peroxisomal acyl-coenzyme a oxidase 1"},
    {id: "aco"},
    {id: "aox"},
    {id: "ec 1.3.3.6"},
    {id: "palmitoyl-coa oxidase"},
    {id: "peroxisomal fatty acyl-coa oxidase"},
    {id: "straight-chain acyl-coa oxidase"},
    {id: "cleaved into: peroxisomal acyl-coa oxidase 1, a chain; peroxisomal acyl-coa oxidase 1, b chain; peroxisomal acyl-coa oxidase 1, c chain"},
    {id: "peroxisomal bifunctional enzyme"},
    {id: "pbe"},
    {id: "pbfe"},
    {id: "multifunctional enzyme 1"},
    {id: "mfe1"},
    {id: "multifunctional protein 1"},
    {id: "mfp1"},
    {id: "includes: enoyl-coa hydratase/3,2-trans-enoyl-coa isomerase (ec 4.2.1.17) (ec 5.3.3.8); 3-hydroxyacyl-coa dehydrogenase (ec 1.1.1.35)"},
    {id: "peroxisomal biogenesis factor 19"},
    {id: "peroxin-19"},
    {id: "peroxisomal farnesylated protein"},
    {id: "peroxisomal leader peptide-processing protease"},
    {id: "peroxisomal membrane protein 11a"},
    {id: "28 kda peroxisomal integral membrane protein"},
    {id: "pmp28"},
    {id: "peroxin-11a"},
    {id: "peroxisomal biogenesis factor 11a"},
    {id: "peroxisomal coatomer receptor"},
    {id: "peroxisomal membrane protein 26"},
    {id: "pmp26p"},
    {id: "protein pex11 homolog alpha"},
    {id: "pex11-alpha"},
    {id: "rnpex11p"},
    {id: "peroxisomal membrane protein 4"},
    {id: "24 kda peroxisomal intrinsic membrane protein"},
    {id: "peroxisomal membrane protein pex13"},
    {id: "peroxin-13"},
    {id: "peroxisomal membrane protein pex14"},
    {id: "pts1 receptor-docking protein"},
    {id: "peroxin-14"},
    {id: "peroxisomal membrane anchor protein pex14"},
    {id: "peroxisomal trans-2-enoyl-coa reductase"},
    {id: "terp"},
    {id: "ec 1.3.1.38"},
    {id: "px-2,4-dcr1"},
    {id: "rlf98"},
    {id: "peroxisome assembly protein 12"},
    {id: "peroxin-12"},
    {id: "peroxisome assembly factor 3"},
    {id: "paf-3"},
    {id: "peroxisome biogenesis factor 2"},
    {id: "ec 2.3.2.36"},
    {id: "peroxin-2"},
    {id: "peroxisomal membrane protein 3"},
    {id: "peroxisome assembly factor 1"},
    {id: "paf-1"},
    {id: "peroxisome proliferator-activated receptor gamma coactivator 1-beta"},
    {id: "pgc-1-beta"},
    {id: "ppar-gamma coactivator 1-beta"},
    {id: "ppargc-1-beta"},
    {id: "peroxynitrite isomerase thap4"},
    {id: "ec 5.99.-.-"},
    {id: "ferric nitrobindin"},
    {id: "nb(iii)"},
    {id: "thap domain-containing protein 4"},
    {id: "peter pan homolog"},
    {id: "peter pan homolog (drosophila)"},
    {id: "phakinin"},
    {id: "49 kda cytoskeletal protein"},
    {id: "beaded filament structural protein 2"},
    {id: "lens fiber cell beaded filament protein cp 47"},
    {id: "cp47"},
    {id: "lens fiber cell beaded filament protein cp 49"},
    {id: "cp49"},
    {id: "lens intermediate filament-like light"},
    {id: "lifl-l"},
    {id: "phenazine biosynthesis-like domain-containing protein"},
    {id: "ec 5.1.-.-"},
    {id: "phenylalanine--trna ligase alpha subunit"},
    {id: "ec 6.1.1.20"},
    {id: "phenylalanyl-trna synthetase alpha subunit"},
    {id: "phers"},
    {id: "phenylalanine--trna ligase beta subunit"},
    {id: "phenylalanyl-trna synthetase beta subunit"},
    {id: "phenylalanine--trna ligase, mitochondrial"},
    {id: "phenylalanyl-trna synthetase"},
    {id: "phosducin"},
    {id: "phd"},
    {id: "33 kda phototransducing protein"},
    {id: "protein meka"},
    {id: "rod photoreceptor 1"},
    {id: "rpr-1"},
    {id: "phosducin-like protein"},
    {id: "phlp"},
    {id: "phosphatidate cytidylyltransferase"},
    {id: "ec 2.7.7.41"},
    {id: "phosphatidate cytidylyltransferase 1"},
    {id: "cdp-dag synthase 1"},
    {id: "cdp-dg synthase 1"},
    {id: "cdp-diacylglycerol synthase 1"},
    {id: "cds 1"},
    {id: "cdp-diglyceride pyrophosphorylase 1"},
    {id: "cdp-diglyceride synthase 1"},
    {id: "ctp:phosphatidate cytidylyltransferase 1"},
    {id: "phosphatidate cytidylyltransferase, mitochondrial"},
    {id: "cdp-diacylglycerol synthase"},
    {id: "cdp-dag synthase"},
    {id: "mitochondrial translocator assembly and maintenance protein 41 homolog"},
    {id: "tam41"},
    {id: "phosphatidylcholine transfer protein"},
    {id: "pc-tp"},
    {id: "start domain-containing protein 2"},
    {id: "stard2"},
    {id: "star-related lipid transfer protein 2"},
    {id: "phosphatidylcholine:ceramide cholinephosphotransferase 1"},
    {id: "ec 2.7.8.27"},
    {id: "protein mob"},
    {id: "sphingomyelin synthase 1"},
    {id: "transmembrane protein 23"},
    {id: "phosphatidylinositol 3,4,5-trisphosphate 3-phosphatase and dual-specificity protein phosphatase pten"},
    {id: "ec 3.1.3.67"},
    {id: "phosphatase and tensin homolog"},
    {id: "phosphatidylinositol 3,4,5-trisphosphate 5-phosphatase 2"},
    {id: "ec 3.1.3.86"},
    {id: "inositol polyphosphate phosphatase-like protein 1"},
    {id: "inppl-1"},
    {id: "protein 51c"},
    {id: "sh2 domain-containing inositol 5'-phosphatase 2"},
    {id: "sh2 domain-containing inositol phosphatase 2"},
    {id: "ship-2"},
    {id: "phosphatidylinositol 3-kinase catalytic subunit type 3"},
    {id: "ec 2.7.1.137"},
    {id: "phosphatidylinositol 4,5-bisphosphate 3-kinase catalytic subunit alpha isoform"},
    {id: "pi3-kinase subunit alpha"},
    {id: "pi3k-alpha"},
    {id: "pi3kalpha"},
    {id: "ptdins-3-kinase subunit alpha"},
    {id: "ec 2.7.1.153"},
    {id: "phosphatidylinositol 4,5-bisphosphate 3-kinase 110 kda catalytic subunit alpha"},
    {id: "ptdins-3-kinase subunit p110-alpha"},
    {id: "p110alpha"},
    {id: "phosphoinositide-3-kinase catalytic alpha polypeptide"},
    {id: "serine/threonine protein kinase pik3ca"},
    {id: "phosphatidylinositol 4-kinase type 2"},
    {id: "phosphatidylinositol 4-kinase type 2-alpha"},
    {id: "55 kda type ii phosphatidylinositol 4-kinase"},
    {id: "phosphatidylinositol 4-kinase type ii-alpha"},
    {id: "phosphatidylinositol 4-kinase type 2-beta"},
    {id: "phosphatidylinositol 4-kinase type ii-beta"},
    {id: "phosphatidylinositol 5-phosphate 4-kinase type-2 alpha"},
    {id: "ec 2.7.1.149"},
    {id: "1-phosphatidylinositol 5-phosphate 4-kinase 2-alpha"},
    {id: "diphosphoinositide kinase 2-alpha"},
    {id: "pipk2 alpha"},
    {id: "phosphatidylinositol 5-phosphate 4-kinase type ii alpha"},
    {id: "pi(5)p 4-kinase type ii alpha"},
    {id: "pip4kii-alpha"},
    {id: "ptdins(5)p-4-kinase isoform 2-alpha"},
    {id: "phosphatidylinositol 5-phosphate 4-kinase type-2 beta"},
    {id: "1-phosphatidylinositol 5-phosphate 4-kinase 2-beta"},
    {id: "diphosphoinositide kinase 2-beta"},
    {id: "phosphatidylinositol 5-phosphate 4-kinase type ii beta"},
    {id: "pi(5)p 4-kinase type ii beta"},
    {id: "pip4kii-beta"},
    {id: "phosphatidylinositol-phosphate kinase iigamma"},
    {id: "pipkiigamma"},
    {id: "ptdins(5)p-4-kinase isoform 2-beta"},
    {id: "phosphatidylinositol n-acetylglucosaminyltransferase subunit c"},
    {id: "phosphatidylinositol-glycan biosynthesis class c protein"},
    {id: "pig-c"},
    {id: "phosphatidylinositol glycan anchor biosynthesis class u protein"},
    {id: "cell division cycle protein 91-like 1"},
    {id: "protein cdc91-like 1"},
    {id: "gpi transamidase component pig-u"},
    {id: "liver regeneration-related protein lrrgt00059"},
    {id: "phosphatidylinositol polyphosphate 5-phosphatase type iv"},
    {id: "5-phosphatase that induces arborization"},
    {id: "pharbin"},
    {id: "72 kda inositol polyphosphate 5-phosphatase"},
    {id: "inositol polyphosphate-5-phosphatase e"},
    {id: "phosphatidylinositol 4,5-bisphosphate 5-phosphatase"},
    {id: "ec 3.1.3.36"},
    {id: "phosphatidylinositol-3,4,5-trisphosphate 5-phosphatase"},
    {id: "phosphatidylinositol transfer protein alpha isoform"},
    {id: "pi-tp-alpha"},
    {id: "ptdins transfer protein alpha"},
    {id: "ptdinstp alpha"},
    {id: "phosphatidylinositol transfer protein beta isoform"},
    {id: "pi-tp-beta"},
    {id: "ptdins transfer protein beta"},
    {id: "ptdinstp beta"},
    {id: "phosphatidylinositol-4,5-bisphosphate 4-phosphatase"},
    {id: "ec 3.1.3.78"},
    {id: "phosphatidylinositol-glycan biosynthesis class w protein"},
    {id: "ec 2.3.-.-"},
    {id: "phosphatidylinositol-glycan-specific phospholipase d"},
    {id: "ec 3.1.4.50"},
    {id: "glycosyl-phosphatidylinositol-specific phospholipase d"},
    {id: "phosphatidylserine decarboxylase proenzyme, mitochondrial"},
    {id: "ec 4.1.1.65"},
    {id: "cleaved into: phosphatidylserine decarboxylase beta chain; phosphatidylserine decarboxylase alpha chain"},
    {id: "phosphatidylserine synthase"},
    {id: "ec 2.7.8.29"},
    {id: "serine-exchange enzyme"},
    {id: "phosphatidylserine synthase 1"},
    {id: "pss-1"},
    {id: "ptdser synthase 1"},
    {id: "serine-exchange enzyme i"},
    {id: "phosphatidylserine synthase 2"},
    {id: "pss-2"},
    {id: "ptdser synthase 2"},
    {id: "serine-exchange enzyme ii"},
    {id: "phosphoacetylglucosamine mutase"},
    {id: "pagm"},
    {id: "ec 5.4.2.3"},
    {id: "acetylglucosamine phosphomutase"},
    {id: "n-acetylglucosamine-phosphate mutase"},
    {id: "phosphodiesterase"},
    {id: "phosphoenolpyruvate carboxykinase, cytosolic"},
    {id: "gtp"},
    {id: "pepck-c"},
    {id: "ec 4.1.1.32"},
    {id: "serine-protein kinase pck1"},
    {id: "phosphofurin acidic cluster sorting protein 1"},
    {id: "pacs-1"},
    {id: "phosphoglycerate kinase"},
    {id: "ec 2.7.2.3"},
    {id: "phosphoglycerate mutase"},
    {id: "ec 5.4.2.11"},
    {id: "ec 5.4.2.4"},
    {id: "phosphoinositide 3-kinase regulatory subunit 4"},
    {id: "pi3-kinase regulatory subunit 4"},
    {id: "phosphoinositide phospholipase c"},
    {id: "phosphoinositide-3-kinase-interacting protein 1"},
    {id: "kringle domain-containing protein hgfl"},
    {id: "phospholipase a and acyltransferase 1"},
    {id: "hras-like suppressor 1"},
    {id: "hrsl1"},
    {id: "phospholipid-metabolizing enzyme a-c1"},
    {id: "rat lrat-like protein-2"},
    {id: "rlp-2"},
    {id: "phospholipase a-2-activating protein"},
    {id: "pla2p"},
    {id: "plap"},
    {id: "phospholipase a1 member a"},
    {id: "ec 3.1.1.111"},
    {id: "phosphatidylserine-specific phospholipase a1"},
    {id: "ps-pla1"},
    {id: "phospholipase a2"},
    {id: "phospholipase a2 group v"},
    {id: "pla2-10"},
    {id: "phosphatidylcholine 2-acylhydrolase 5"},
    {id: "phospholipase a2 group xv"},
    {id: "1-o-acylceramide synthase"},
    {id: "acs"},
    {id: "lcat-like lysophospholipase"},
    {id: "llpl"},
    {id: "lysophospholipase 3"},
    {id: "lysosomal phospholipase a and acyltransferase"},
    {id: "ec 2.3.1.-, ec 3.1.1.32, ec 3.1.1.4"},
    {id: "lysosomal phospholipase a2"},
    {id: "lpla2"},
    {id: "phospholipase a2, group xiia"},
    {id: "pla2g12a protein"},
    {id: "phospholipase a2, membrane associated"},
    {id: "giic spla2"},
    {id: "group iia phospholipase a2"},
    {id: "phosphatidylcholine 2-acylhydrolase 2a"},
    {id: "phospholipase b-like"},
    {id: "phospholipase b-like 1"},
    {id: "lama-like protein 1"},
    {id: "lamina ancestor homolog 1"},
    {id: "phospholipase b domain-containing protein 1"},
    {id: "cleaved into: phospholipase b-like 1 chain a; phospholipase b-like 1 chain b; phospholipase b-like 1 chain c"},
    {id: "phospholipase b1, membrane-associated"},
    {id: "lysophospholipase"},
    {id: "phospholipase b/lipase"},
    {id: "triacylglycerol lipase"},
    {id: "phospholipase b"},
    {id: "plb/lip"},
    {id: "phospholipid hydroperoxide glutathione peroxidase"},
    {id: "phgpx"},
    {id: "ec 1.11.1.12"},
    {id: "glutathione peroxidase 4"},
    {id: "gpx-4"},
    {id: "gshpx-4"},
    {id: "phospholipid phosphatase 2"},
    {id: "ec 3.1.3.4"},
    {id: "lipid phosphate phosphohydrolase 2"},
    {id: "pap2-gamma"},
    {id: "pap2-g"},
    {id: "phosphatidate phosphohydrolase type 2c"},
    {id: "phosphatidic acid phosphatase 2c"},
    {id: "pap-2c"},
    {id: "pap2c"},
    {id: "phospholipid phosphatase 7"},
    {id: "inactive"},
    {id: "phospholipid phosphatase related 3"},
    {id: "plasticity-related gene 2"},
    {id: "phospholipid-transporting atpase"},
    {id: "ec 7.6.2.1"},
    {id: "phospholipid-transporting atpase abca3"},
    {id: "atp-binding cassette sub-family a member 3"},
    {id: "xenobiotic-transporting atpase abca3"},
    {id: "cleaved into: 150 kda mature form"},
    {id: "phosphomannomutase"},
    {id: "ec 5.4.2.8"},
    {id: "phosphomevalonate kinase"},
    {id: "pmkase"},
    {id: "ec 2.7.4.2"},
    {id: "phosphoprotein associated with glycosphingolipid-enriched microdomains 1"},
    {id: "csk-binding protein"},
    {id: "transmembrane phosphoprotein cbp"},
    {id: "phosphoribosyl pyrophosphate synthase-associated protein 2"},
    {id: "prpp synthase-associated protein 2"},
    {id: "41 kda phosphoribosypyrophosphate synthetase-associated protein"},
    {id: "pap41"},
    {id: "phosphorylase b kinase gamma catalytic chain, liver/testis isoform"},
    {id: "phk-gamma-lt"},
    {id: "phk-gamma-t"},
    {id: "ec 2.7.11.19"},
    {id: "phosphorylase kinase subunit gamma-2"},
    {id: "phosphorylase b kinase gamma catalytic chain, skeletal muscle/heart isoform"},
    {id: "phosphorylase kinase subunit gamma-1"},
    {id: "serine/threonine-protein kinase phkg1"},
    {id: "ec 2.7.11.1, ec 2.7.11.26"},
    {id: "phosphorylated adapter rna export protein"},
    {id: "26 kda resiniferatoxin-binding protein"},
    {id: "rbp-26"},
    {id: "rna u small nuclear rna export adapter protein"},
    {id: "rtx-42"},
    {id: "resiniferatoxin-binding protein 2"},
    {id: "rbp-2"},
    {id: "phosphoserine aminotransferase"},
    {id: "ec 2.6.1.52"},
    {id: "phosphoserine phosphatase"},
    {id: "pspase"},
    {id: "ec 3.1.3.3"},
    {id: "o-phosphoserine phosphohydrolase"},
    {id: "phosphotriesterase-related protein"},
    {id: "parathion hydrolase-related protein"},
    {id: "resiniferotoxin-binding phosphotriesterase-related protein"},
    {id: "phytanoyl-coa dioxygenase, peroxisomal"},
    {id: "ec 1.14.11.18"},
    {id: "phytanic acid oxidase"},
    {id: "phytanoyl-coa alpha-hydroxylase"},
    {id: "phyh"},
    {id: "phytanoyl-coa hydroxylase-interacting protein"},
    {id: "phytanoyl-coa hydroxylase-associated protein 1"},
    {id: "pahx-ap1"},
    {id: "pahxap1"},
    {id: "piccolo"},
    {id: "pirin"},
    {id: "ec 1.13.11.24"},
    {id: "probable quercetin 2,3-dioxygenase pir"},
    {id: "probable quercetinase"},
    {id: "pituitary homeobox 1"},
    {id: "homeobox protein pitx1"},
    {id: "paired-like homeodomain transcription factor 1"},
    {id: "pituitary homeobox 2"},
    {id: "homeobox protein pitx2"},
    {id: "paired-like homeodomain transcription factor 2"},
    {id: "rptx2"},
    {id: "pituitary homeobox 3"},
    {id: "homeobox protein pitx3"},
    {id: "paired-like homeodomain transcription factor 3"},
    {id: "pituitary tumor-transforming gene 1 protein-interacting protein"},
    {id: "pituitary tumor-transforming gene protein-binding factor"},
    {id: "pbf"},
    {id: "pttg-binding factor"},
    {id: "pituitary-specific positive transcription factor 1"},
    {id: "pit-1"},
    {id: "growth hormone factor 1"},
    {id: "ghf-1"},
    {id: "pkmyt1 protein"},
    {id: "protein kinase, membrane associated tyrosine/threonine 1"},
    {id: "placental lactogen i alpha"},
    {id: "placental lactogen i beta"},
    {id: "prolactin family 3, subfamily d, member 2"},
    {id: "plasma membrane ascorbate-dependent reductase cybrd1"},
    {id: "ec 7.2.1.3"},
    {id: "cytochrome b reductase 1"},
    {id: "duodenal cytochrome b"},
    {id: "plasma membrane calcium-transporting atpase 3"},
    {id: "pmca3"},
    {id: "plasma membrane calcium atpase isoform 3"},
    {id: "plasma membrane calcium pump isoform 3"},
    {id: "plasma membrane calcium-transporting atpase 4"},
    {id: "pmca4"},
    {id: "plasma membrane calcium atpase isoform 4"},
    {id: "plasma membrane calcium pump isoform 4"},
    {id: "plasma protease c1 inhibitor"},
    {id: "c1 inh"},
    {id: "c1inh"},
    {id: "c1 esterase inhibitor"},
    {id: "c1-inhibiting factor"},
    {id: "serpin g1"},
    {id: "plasmalemma vesicle-associated protein"},
    {id: "plasmalemma vesicle protein 1"},
    {id: "pv-1"},
    {id: "gp68"},
    {id: "plasminogen"},
    {id: "ec 3.4.21.7"},
    {id: "cleaved into: plasmin heavy chain a; activation peptide; angiostatin; plasmin heavy chain a, short form; plasmin light chain b"},
    {id: "plasminogen activator inhibitor 2 type a"},
    {id: "pai-2"},
    {id: "pai2a"},
    {id: "serpin b2"},
    {id: "plasminogen receptor"},
    {id: "kt"},
    {id: "plg-r(kt)"},
    {id: "plasmolipin"},
    {id: "plasma membrane proteolipid"},
    {id: "plastin 3"},
    {id: "t-isoform"},
    {id: "platelet factor 4"},
    {id: "pf-4"},
    {id: "c-x-c motif chemokine 4"},
    {id: "platelet glycoprotein ib beta chain"},
    {id: "gp-ib beta"},
    {id: "gpib-beta"},
    {id: "gpibb"},
    {id: "cd antigen cd42c"},
    {id: "platelet-activating factor acetylhydrolase"},
    {id: "ec 3.1.1.47"},
    {id: "platelet-activating factor acetylhydrolase ib subunit alpha"},
    {id: "lissencephaly-1 protein"},
    {id: "lis-1"},
    {id: "paf acetylhydrolase 45 kda subunit"},
    {id: "paf-ah 45 kda subunit"},
    {id: "paf-ah alpha"},
    {id: "pafah alpha"},
    {id: "platelet-activating factor acetylhydrolase ib subunit alpha1"},
    {id: "paf acetylhydrolase 29 kda subunit"},
    {id: "paf-ah 29 kda subunit"},
    {id: "paf-ah subunit gamma"},
    {id: "pafah subunit gamma"},
    {id: "platelet-activating factor acetylhydrolase alpha 1 subunit"},
    {id: "paf-ah alpha 1"},
    {id: "platelet-activating factor acetylhydrolase ib subunit alpha2"},
    {id: "paf acetylhydrolase 30 kda subunit"},
    {id: "paf-ah 30 kda subunit"},
    {id: "paf-ah subunit beta"},
    {id: "pafah subunit beta"},
    {id: "platelet-activating factor acetylhydrolase alpha 2 subunit"},
    {id: "paf-ah alpha 2"},
    {id: "platelet-activating factor receptor"},
    {id: "paf-r"},
    {id: "pafr"},
    {id: "platelet-derived growth factor c"},
    {id: "pdgf-c"},
    {id: "fallotein"},
    {id: "spinal cord-derived growth factor"},
    {id: "rscdfg"},
    {id: "vegf-e"},
    {id: "cleaved into: platelet-derived growth factor c, latent form (pdgfc latent form); platelet-derived growth factor c, receptor-binding form (pdgfc receptor-binding form)"},
    {id: "platelet-derived growth factor d"},
    {id: "pdgf-d"},
    {id: "iris-expressed growth factor"},
    {id: "spinal cord-derived growth factor b"},
    {id: "scdgf-b"},
    {id: "cleaved into: platelet-derived growth factor d, latent form (pdgfd latent form); platelet-derived growth factor d, receptor-binding form (pdgfd receptor-binding form)"},
    {id: "platelet-derived growth factor receptor-like protein"},
    {id: "pdgfr-like protein"},
    {id: "pleckstrin homology domain containing a1"},
    {id: "pleckstrin homology domain containing, family a (phosphoinositide binding specific) member 1"},
    {id: "pleckstrin homology domain containing a3"},
    {id: "pleckstrin homology domain-containing, family a (phosphoinositide binding specific) member 3"},
    {id: "pleckstrin homology domain-containing family a member 4"},
    {id: "ph domain-containing family a member 4"},
    {id: "phosphoinositol 3-phosphate-binding protein 1"},
    {id: "pepp-1"},
    {id: "pleckstrin homology domain-containing family a member 8"},
    {id: "ph domain-containing family a member 8"},
    {id: "phosphatidylinositol-four-phosphate adapter protein 2"},
    {id: "fapp-2"},
    {id: "phosphoinositol 4-phosphate adapter protein 2"},
    {id: "pleckstrin homology domain-containing family b member 1"},
    {id: "ph domain-containing family b member 1"},
    {id: "evectin-1"},
    {id: "pleckstrin homology domain-containing family d member 1"},
    {id: "ph domain-containing family d member 1"},
    {id: "pleckstrin homology domain-containing family f member 1"},
    {id: "ph domain-containing family f member 1"},
    {id: "pleckstrin homology domain-containing family g member 5"},
    {id: "ph domain-containing family g member 5"},
    {id: "neuronal rhoa gef protein"},
    {id: "transcript highly enriched in cortex and hippocampus"},
    {id: "pleckstrin homology domain-containing family m member 1"},
    {id: "ph domain-containing family m member 1"},
    {id: "pleckstrin homology-like domain family a member 3"},
    {id: "tdag51/ipl homolog 1"},
    {id: "plexin-b3"},
    {id: "protein plxnb3"},
    {id: "podocalyxin"},
    {id: "podocalyxin-like protein 1"},
    {id: "pc"},
    {id: "pclp-1"},
    {id: "poly"},
    {id: "adp-ribose"},
    {id: "parp"},
    {id: "ec 2.7.7.19"},
    {id: "polyadenylate-specific ribonuclease"},
    {id: "60 kda poly(u)-binding-splicing factor"},
    {id: "rc"},
    {id: "polyadenylate-binding protein"},
    {id: "pabp"},
    {id: "polyadenylate-binding protein 1"},
    {id: "pabp-1"},
    {id: "poly(a)-binding protein 1"},
    {id: "polyamine deacetylase hdac10"},
    {id: "ec 3.5.1.48"},
    {id: "ec 3.5.1.62"},
    {id: "histone deacetylase 10"},
    {id: "hd10"},
    {id: "polyglutamylase complex subunit ttll1"},
    {id: "ec 6.3.2.-"},
    {id: "tubulin polyglutamylase ttll1"},
    {id: "tubulin polyglutamylase complex subunit 3"},
    {id: "pgs3"},
    {id: "tubulin--tyrosine ligase-like protein 1"},
    {id: "polyisoprenoid diphosphate/phosphate phosphohydrolase plpp6"},
    {id: "ec 3.6.1.68"},
    {id: "phospholipid phosphatase 6"},
    {id: "polypeptide n-acetylgalactosaminyltransferase"},
    {id: "protein-udp acetylgalactosaminyltransferase"},
    {id: "polypeptide n-acetylgalactosaminyltransferase 1"},
    {id: "ec 2.4.1.41"},
    {id: "polypeptide galnac transferase 1"},
    {id: "galnac-t1"},
    {id: "pp-gantase 1"},
    {id: "protein-udp acetylgalactosaminyltransferase 1"},
    {id: "udp-galnac:polypeptide n-acetylgalactosaminyltransferase 1"},
    {id: "cleaved into: polypeptide n-acetylgalactosaminyltransferase 1 soluble form"},
    {id: "polypeptide n-acetylgalactosaminyltransferase 5"},
    {id: "polypeptide galnac transferase 5"},
    {id: "galnac-t5"},
    {id: "pp-gantase 5"},
    {id: "protein-udp acetylgalactosaminyltransferase 5"},
    {id: "udp-galnac:polypeptide n-acetylgalactosaminyltransferase 5"},
    {id: "polypeptide n-acetylgalactosaminyltransferase-like 5"},
    {id: "udp-n-acetyl-alpha-d-galactosamine:polypeptide n-acetylgalactosaminyltransferase-like 5"},
    {id: "polyprenol reductase"},
    {id: "ec 1.3.1.94"},
    {id: "polypyrimidine tract-binding protein 2"},
    {id: "neural polypyrimidine tract-binding protein"},
    {id: "ptb-like protein"},
    {id: "polyribonucleotide 5'-hydroxyl-kinase clp1"},
    {id: "ec 2.7.1.78"},
    {id: "polyadenylation factor clp1"},
    {id: "polynucleotide kinase clp1"},
    {id: "pre-mrna cleavage complex ii protein clp1"},
    {id: "polyserase-2"},
    {id: "polyunsaturated fatty acid lipoxygenase alox12"},
    {id: "arachidonate (12s)-lipoxygenase"},
    {id: "12s-lox"},
    {id: "12s-lipoxygenase"},
    {id: "ec 1.13.11.31"},
    {id: "arachidonate (15s)-lipoxygenase"},
    {id: "ec 1.13.11.33"},
    {id: "linoleate (13s)-lipoxygenase"},
    {id: "lipoxin synthase 12-lo"},
    {id: "ec 3.3.2.-"},
    {id: "platelet-type lipoxygenase 12"},
    {id: "polyunsaturated fatty acid lipoxygenase alox15"},
    {id: "12/15-lipoxygenase"},
    {id: "arachidonate 12-lipoxygenase, leukocyte-type"},
    {id: "12-lox"},
    {id: "arachidonate 15-lipoxygenase"},
    {id: "15-lox"},
    {id: "arachidonate omega-6 lipoxygenase"},
    {id: "hepoxilin a3 synthase alox15"},
    {id: "linoleate 13s-lipoxygenase"},
    {id: "ec 1.13.11.12"},
    {id: "porimin"},
    {id: "transmembrane protein 123"},
    {id: "post-gpi attachment to proteins factor 4"},
    {id: "post-gpi attachment to proteins galnac transferase 4"},
    {id: "transmembrane protein 246"},
    {id: "potassium channel subfamily k member 1"},
    {id: "inward rectifying potassium channel protein twik-1"},
    {id: "rtwik"},
    {id: "potassium channel k2p1"},
    {id: "potassium channel subfamily k member 10"},
    {id: "outward rectifying potassium channel protein trek-2"},
    {id: "trek-2 k(+) channel subunit"},
    {id: "potassium channel subfamily k member 12"},
    {id: "tandem pore domain halothane-inhibited potassium channel 2"},
    {id: "thik-2"},
    {id: "potassium channel subfamily k member 15"},
    {id: "acid-sensitive potassium channel protein task-5"},
    {id: "rtask-5"},
    {id: "twik-related acid-sensitive k(+) channel 5"},
    {id: "potassium channel subfamily k member 18"},
    {id: "tresk-2"},
    {id: "two-pore-domain potassium channel tresk"},
    {id: "potassium channel subfamily k member 2"},
    {id: "outward rectifying potassium channel protein trek-1"},
    {id: "stretch-activated potassium channel trek-1"},
    {id: "trek-1 k(+) channel subunit"},
    {id: "two pore domain potassium channel trek-1"},
    {id: "two pore potassium channel tpkc1"},
    {id: "potassium channel subfamily k member 3"},
    {id: "acid-sensitive potassium channel protein task-1"},
    {id: "twik-related acid-sensitive k(+) channel 1"},
    {id: "two pore potassium channel kt3.1"},
    {id: "two pore k(+) channel kt3.1"},
    {id: "potassium channel subfamily k member 4"},
    {id: "twik-related arachidonic acid-stimulated potassium channel protein"},
    {id: "traak"},
    {id: "potassium channel subfamily k member 9"},
    {id: "acid-sensitive potassium channel protein task-3"},
    {id: "twik-related acid-sensitive k(+) channel 3"},
    {id: "two pore potassium channel kt3.2"},
    {id: "two pore k(+) channel kt3.2"},
    {id: "potassium channel, subfamily k, member 5"},
    {id: "potassium two pore domain channel subfamily k member 5"},
    {id: "potassium voltage-gated channel subfamily a member 3"},
    {id: "rck3"},
    {id: "rgk5"},
    {id: "voltage-gated potassium channel subunit kv1.3"},
    {id: "voltage-gated potassium channel subunit kv3"},
    {id: "potassium voltage-gated channel subfamily c member 2"},
    {id: "potassium channel voltage-gated shaw-related subfamily c member 2"},
    {id: "shaw-like potassium channel"},
    {id: "voltage-gated potassium channel subunit kv3.2"},
    {id: "potassium voltage-gated channel subfamily c member 3"},
    {id: "voltage-gated potassium channel kv3.3el long variant"},
    {id: "potassium voltage-gated channel subfamily d member 2"},
    {id: "voltage-gated potassium channel subunit kv4.2"},
    {id: "potassium voltage-gated channel subfamily e member 2"},
    {id: "mink-related peptide 1"},
    {id: "minimum potassium ion channel-related peptide 1"},
    {id: "potassium channel subunit beta mirp1"},
    {id: "potassium voltage-gated channel subfamily g member 1"},
    {id: "voltage-gated potassium channel subunit kv6.1"},
    {id: "potassium voltage-gated channel subfamily g member 2"},
    {id: "cardiac potassium channel subunit"},
    {id: "voltage-gated potassium channel subunit kv6.2"},
    {id: "potassium voltage-gated channel subfamily h member 2"},
    {id: "ether-a-go-go-related gene potassium channel 1"},
    {id: "voltage-gated potassium channel subunit kv11.1"},
    {id: "potassium voltage-gated channel subfamily h member 3"},
    {id: "brain-specific eag-like channel 1"},
    {id: "bec1"},
    {id: "ether-a-go-go-like potassium channel 2"},
    {id: "elk channel 2"},
    {id: "relk2"},
    {id: "voltage-gated potassium channel subunit kv12.2"},
    {id: "potassium voltage-gated channel subfamily h member 5"},
    {id: "ether-a-go-go potassium channel 2"},
    {id: "reag2"},
    {id: "voltage-gated potassium channel subunit kv10.2"},
    {id: "potassium voltage-gated channel subfamily h member 7"},
    {id: "ether-a-go-go-related gene potassium channel 3"},
    {id: "erg-3"},
    {id: "eag-related protein 3"},
    {id: "ether-a-go-go-related protein 3"},
    {id: "voltage-gated potassium channel subunit kv11.3"},
    {id: "potassium voltage-gated channel subfamily kqt member 1"},
    {id: "iks producing slow voltage-gated potassium channel subunit alpha kvlqt1"},
    {id: "kqt-like 1"},
    {id: "voltage-gated potassium channel subunit kv7.1"},
    {id: "potassium voltage-gated channel subfamily kqt member 3"},
    {id: "kqt-like 3"},
    {id: "potassium channel subunit alpha kvlqt3"},
    {id: "voltage-gated potassium channel subunit kv7.3"},
    {id: "potassium voltage-gated channel subfamily s member 1"},
    {id: "delayed-rectifier k(+) channel alpha subunit 1"},
    {id: "voltage-gated potassium channel subunit kv9.1"},
    {id: "potassium voltage-gated channel subfamily s member 2"},
    {id: "delayed-rectifier k(+) channel alpha subunit 2"},
    {id: "voltage-gated potassium channel subunit kv9.2"},
    {id: "potassium voltage-gated channel subfamily s member 3"},
    {id: "delayed-rectifier k(+) channel alpha subunit 3"},
    {id: "voltage-gated potassium channel subunit kv9.3"},
    {id: "potassium voltage-gated channel subfamily v member 1"},
    {id: "voltage-gated potassium channel subunit kv2.3r"},
    {id: "voltage-gated potassium channel subunit kv8.1"},
    {id: "potassium-transporting atpase subunit beta"},
    {id: "gastric h(+)/k(+) atpase subunit beta"},
    {id: "proton pump beta chain"},
    {id: "ppp4r2 protein"},
    {id: "protein phosphatase 4, regulatory subunit 2"},
    {id: "pre-b-cell leukemia transcription factor-interacting protein 1"},
    {id: "hematopoietic pbx-interacting protein"},
    {id: "pre-eosinophil-associated ribonuclease-2"},
    {id: "ribonuclease 15"},
    {id: "ribonuclease a f1"},
    {id: "ribonuclease a family member 2"},
    {id: "pre-mrna 3'-end-processing factor fip1"},
    {id: "fip1-like 1 protein"},
    {id: "pre-mrna-processing factor 19"},
    {id: "pre-mrna-processing factor 6"},
    {id: "prp6 homolog"},
    {id: "u5 snrnp-associated 102 kda protein"},
    {id: "u5-102 kda protein"},
    {id: "pre-mrna-splicing factor 18"},
    {id: "prp18 homolog"},
    {id: "potassium channel regulatory factor"},
    {id: "pre-mrna-splicing factor rbm22"},
    {id: "rna-binding motif protein 22"},
    {id: "pre-mrna-splicing regulator wtap"},
    {id: "female-lethal(2)d homolog"},
    {id: "wt1-associated protein"},
    {id: "wilms tumor 1-associating protein"},
    {id: "prenylated rab acceptor protein 1"},
    {id: "pra1 family protein 1"},
    {id: "preprorelaxin"},
    {id: "relaxin 1"},
    {id: "presenilin-1"},
    {id: "ps-1"},
    {id: "ec 3.4.23.-"},
    {id: "protein s182"},
    {id: "cleaved into: presenilin-1 ntf subunit; presenilin-1 ctf subunit; presenilin-1 ctf12 (ps1-ctf12)"},
    {id: "presenilin-2"},
    {id: "ps-2"},
    {id: "cleaved into: presenilin-2 ntf subunit; presenilin-2 ctf subunit"},
    {id: "prestin"},
    {id: "solute carrier family 26 member 5"},
    {id: "prickle homolog 3"},
    {id: "prickle planar cell polarity protein 3"},
    {id: "prion like protein doppel"},
    {id: "prion-like protein"},
    {id: "prkab2 protein"},
    {id: "protein kinase amp-activated non-catalytic subunit beta 2"},
    {id: "prkd3 protein"},
    {id: "protein kinase d3"},
    {id: "pro-interleukin-16"},
    {id: "cleaved into: interleukin-16 (il-16) (lymphocyte chemoattractant factor) (lcf)"},
    {id: "probable atp-dependent rna helicase ddx5"},
    {id: "dead box protein 5"},
    {id: "probable atp-dependent rna helicase ddx52"},
    {id: "atp-dependent rna helicase rok1-like"},
    {id: "rrok1l"},
    {id: "dead box protein 52"},
    {id: "probable atp-dependent rna helicase ddx59"},
    {id: "dead box protein 59"},
    {id: "zinc finger hit domain-containing protein 5"},
    {id: "probable e3 ubiquitin-protein ligase herc4"},
    {id: "hect domain and rcc1-like domain-containing protein 4"},
    {id: "hect-type e3 ubiquitin transferase herc4"},
    {id: "probable e3 ubiquitin-protein ligase marchf10"},
    {id: "membrane-associated ring finger protein 10"},
    {id: "membrane-associated ring-ch protein x"},
    {id: "march-x"},
    {id: "ring finger protein 190"},
    {id: "ring-type e3 ubiquitin transferase marchf10"},
    {id: "probable g-protein coupled receptor 139"},
    {id: "g(q)-coupled orphan receptor gprg1"},
    {id: "probable g-protein coupled receptor 149"},
    {id: "induced early in differentiating astrocytes gene protein"},
    {id: "probable g-protein coupled receptor 151"},
    {id: "gpcr-2037"},
    {id: "probable g-protein coupled receptor 173"},
    {id: "super conserved receptor expressed in brain 3"},
    {id: "probable g-protein coupled receptor 176"},
    {id: "g-protein coupled receptor agr9"},
    {id: "rbu-15"},
    {id: "probable g-protein coupled receptor 27"},
    {id: "super conserved receptor expressed in brain 1"},
    {id: "probable g-protein coupled receptor 85"},
    {id: "pkrcx1"},
    {id: "super conserved receptor expressed in brain 2"},
    {id: "probable g-protein coupled receptor 88"},
    {id: "striatum-specific g-protein coupled receptor"},
    {id: "probable n-acetyltransferase 8b"},
    {id: "camello-like protein 1"},
    {id: "camello-like protein 6"},
    {id: "probable rna-binding protein 18"},
    {id: "rna-binding motif protein 18"},
    {id: "probable rna-binding protein 46"},
    {id: "rna-binding motif protein 46"},
    {id: "probable rna-binding protein eif1ad"},
    {id: "eukaryotic translation initiation factor 1a domain-containing protein"},
    {id: "probable udp-sugar transporter protein slc35a4"},
    {id: "complex leucine repeat protein"},
    {id: "solute carrier family 35 member a4"},
    {id: "probable cytosolic iron-sulfur protein assembly protein ciao1"},
    {id: "wd repeat-containing protein 39"},
    {id: "probable gluconokinase"},
    {id: "gluconate kinase"},
    {id: "probable glutamate--trna ligase, mitochondrial"},
    {id: "ec 6.1.1.17"},
    {id: "glutamyl-trna synthetase"},
    {id: "probable imidazolonepropionase"},
    {id: "ec 3.5.2.7"},
    {id: "amidohydrolase domain-containing protein 1"},
    {id: "probable inactive allantoicase"},
    {id: "allantoate amidinohydrolase"},
    {id: "probable inactive trna-specific adenosine deaminase-like protein 3"},
    {id: "trna-specific adenosine-34 deaminase subunit adat3"},
    {id: "probable methyltransferase-like protein 24"},
    {id: "probable mitochondrial glutathione transporter slc25a39"},
    {id: "probable mitochondrial glutathione transporter slc25a40"},
    {id: "probable palmitoyltransferase zdhhc24"},
    {id: "membrane-associated zinc finger protein dhhc25"},
    {id: "zinc finger dhhc domain-containing protein 24"},
    {id: "dhhc-24"},
    {id: "probable phospholipid-transporting atpase iib"},
    {id: "atpase class ii type 9b"},
    {id: "probable proline--trna ligase, mitochondrial"},
    {id: "ec 6.1.1.15"},
    {id: "prolyl-trna synthetase"},
    {id: "prors"},
    {id: "probable proton-coupled zinc antiporter slc30a3"},
    {id: "solute carrier family 30 member 3"},
    {id: "zinc transporter 3"},
    {id: "znt-3"},
    {id: "probable serine carboxypeptidase cpvl"},
    {id: "probable splicing factor yju2b"},
    {id: "coiled-coil domain-containing protein 130"},
    {id: "probable tubulin polyglutamylase ttll9"},
    {id: "tubulin--tyrosine ligase-like protein 9"},
    {id: "proenkephalin-a"},
    {id: "cleaved into: synenkephalin; met-enkephalin (opioid growth factor) (ogf); penk(114-133); penk(143-185); met-enkephalin-arg-gly-leu; leu-enkephalin; penk(239-260); met-enkephalin-arg-phe"},
    {id: "proenkephalin-b"},
    {id: "beta-neoendorphin-dynorphin"},
    {id: "preprodynorphin"},
    {id: "proepiregulin"},
    {id: "cleaved into: epiregulin (epr)"},
    {id: "profilin-1"},
    {id: "profilin i"},
    {id: "profilin-2"},
    {id: "profilin ii"},
    {id: "profilin-4"},
    {id: "profilin iv"},
    {id: "progestin and adipoq receptor family member 8"},
    {id: "progestin membrane receptor beta"},
    {id: "programmed cell death 6-interacting protein"},
    {id: "alg-2-interacting protein 1"},
    {id: "programmed cell death protein 2"},
    {id: "zinc finger protein rp-8"},
    {id: "programmed cell death protein 4"},
    {id: "death up-regulated gene protein"},
    {id: "programmed cell death protein 6"},
    {id: "apoptosis-linked gene 2 protein homolog"},
    {id: "alg-2"},
    {id: "prohibitin-2"},
    {id: "b-cell receptor-associated protein bap37"},
    {id: "bap-37"},
    {id: "prokineticin receptor 1"},
    {id: "pk-r1"},
    {id: "g-protein coupled receptor 73"},
    {id: "g-protein coupled receptor zaq"},
    {id: "prokineticin-1"},
    {id: "endocrine-gland-derived vascular endothelial growth factor"},
    {id: "eg-vegf"},
    {id: "prokineticin-2"},
    {id: "pk2"},
    {id: "prolactin family 7, subfamily d, member 1"},
    {id: "proliferin-related protein"},
    {id: "prolactin regulatory element-binding protein"},
    {id: "mammalian guanine nucleotide exchange factor msec12"},
    {id: "prolactin-2a1"},
    {id: "placental prolactin-like protein m"},
    {id: "plp-m"},
    {id: "prl-like protein m"},
    {id: "prolactin-3b1"},
    {id: "chorionic somatomammotropin hormone 2"},
    {id: "placental lactogen ii"},
    {id: "pl-ii"},
    {id: "rplii"},
    {id: "prolactin-3d4"},
    {id: "chorionic somatomammotropin hormone 1 variant"},
    {id: "placental lactogen i variant"},
    {id: "pl-iv"},
    {id: "prolactin-4a1"},
    {id: "placental prolactin-like protein a"},
    {id: "plp-a"},
    {id: "prl-like protein a"},
    {id: "prolactin-5a1"},
    {id: "placental prolactin-like protein l"},
    {id: "plp-l"},
    {id: "prl-like protein l"},
    {id: "prolactin-7b1"},
    {id: "placental prolactin-like protein n"},
    {id: "plp-n"},
    {id: "prl-like protein n"},
    {id: "prolactin-8a4"},
    {id: "prolactin-like protein-c variant"},
    {id: "prolactin-8a9"},
    {id: "placental prolactin-like protein c2"},
    {id: "plp-c2"},
    {id: "prl-like protein c2"},
    {id: "prolactin-like protein c-beta"},
    {id: "plp c-beta"},
    {id: "prolargin"},
    {id: "proline-arginine-rich end leucine-rich repeat protein"},
    {id: "proline dehydrogenase"},
    {id: "ec 1.5.5.2"},
    {id: "proline rich 19"},
    {id: "prr19 protein"},
    {id: "proline rich gla"},
    {id: "g-carboxyglutamic acid"},
    {id: "proline-, glutamic acid- and leucine-rich protein 1"},
    {id: "modulator of non-genomic activity of estrogen receptor"},
    {id: "proline-rich protein 5"},
    {id: "protein observed with rictor-1"},
    {id: "protor-1"},
    {id: "proline-rich protein 7"},
    {id: "synaptic proline-rich membrane protein"},
    {id: "proline-rich transmembrane protein 1"},
    {id: "dispanin subfamily d member 1"},
    {id: "dspd1"},
    {id: "synapse differentiation-induced protein 4"},
    {id: "syndig4"},
    {id: "proline-rich transmembrane protein 2"},
    {id: "dispanin subfamily b member 3"},
    {id: "dspb3"},
    {id: "proline-serine-threonine phosphatase-interacting protein 1"},
    {id: "pstpip1 protein"},
    {id: "prolow-density lipoprotein receptor-related protein 1"},
    {id: "lrp-1"},
    {id: "cleaved into: low-density lipoprotein receptor-related protein 1 85 kda subunit (lrp-85); low-density lipoprotein receptor-related protein 1 515 kda subunit (lrp-515); low-density lipoprotein receptor-related protein 1 intracellular domain (lrpicd)"},
    {id: "prolyl 3-hydroxylase 2"},
    {id: "ec 1.14.11.7"},
    {id: "leprecan-like protein 1"},
    {id: "prolyl 3-hydroxylase ogfod1"},
    {id: "2-oxoglutarate and iron-dependent oxygenase domain-containing protein 1"},
    {id: "us12 prolyl 3-hydroxylase"},
    {id: "prolyl 4-hydroxylase subunit alpha-1"},
    {id: "4-ph alpha-1"},
    {id: "ec 1.14.11.2"},
    {id: "procollagen-proline,2-oxoglutarate-4-dioxygenase subunit alpha-1"},
    {id: "prolyl endopeptidase"},
    {id: "prolyl endopeptidase-like"},
    {id: "prolylendopeptidase-like"},
    {id: "prolyl hydroxylase egln2"},
    {id: "egl nine homolog 2"},
    {id: "ec 1.14.11.29"},
    {id: "hph-3"},
    {id: "hypoxia-inducible factor prolyl hydroxylase 1"},
    {id: "hif-ph1"},
    {id: "hif-prolyl hydroxylase 1"},
    {id: "hph-1"},
    {id: "prolyl hydroxylase domain-containing protein 1"},
    {id: "phd1"},
    {id: "prom1 protein"},
    {id: "prominin 1"},
    {id: "prominin-1.s1 splice variant"},
    {id: "promyelocytic leukemia zinc finger protein"},
    {id: "zinc finger and btb domain containing 16"},
    {id: "propionyl-coa carboxylase alpha chain, mitochondrial"},
    {id: "ec 6.4.1.3"},
    {id: "propanoyl-coa:carbon dioxide ligase subunit alpha"},
    {id: "proprotein convertase subtilisin/kexin type 5"},
    {id: "proprotein convertase 5"},
    {id: "pc5"},
    {id: "proprotein convertase 6"},
    {id: "pc6"},
    {id: "subtilisin/kexin-like protease pc5"},
    {id: "rpc5"},
    {id: "proprotein convertase subtilisin/kexin type 7"},
    {id: "prohormone convertase 7"},
    {id: "proprotein convertase 7"},
    {id: "pc7"},
    {id: "rpc7"},
    {id: "subtilisin/kexin-like protease pc7"},
    {id: "proprotein convertase subtilisin/kexin type 9"},
    {id: "neural apoptosis-regulated convertase 1"},
    {id: "narc-1"},
    {id: "proprotein convertase 9"},
    {id: "pc9"},
    {id: "subtilisin/kexin-like protease pc9"},
    {id: "prosaposin receptor gpr37"},
    {id: "g-protein coupled receptor 37"},
    {id: "g-protein coupled receptor cns1"},
    {id: "prostacyclin receptor"},
    {id: "prostaglandin i2 receptor"},
    {id: "pgi receptor"},
    {id: "pgi2 receptor"},
    {id: "prostanoid ip receptor"},
    {id: "prostacyclin synthase"},
    {id: "ec 5.3.99.4"},
    {id: "prostaglandin i2 synthase"},
    {id: "prostaglandin d2 receptor 2"},
    {id: "prostaglandin e synthase 2"},
    {id: "ec 5.3.99.3"},
    {id: "microsomal prostaglandin e synthase 2"},
    {id: "prostaglandin e synthase 3"},
    {id: "cpges"},
    {id: "cytosolic prostaglandin e2 synthase"},
    {id: "prostaglandin e2 receptor ep1 subtype"},
    {id: "pge receptor ep1 subtype"},
    {id: "pge2 receptor ep1 subtype"},
    {id: "prostanoid ep1 receptor"},
    {id: "prostaglandin e2 receptor ep2 subtype"},
    {id: "pge receptor ep2 subtype"},
    {id: "pge2 receptor ep2 subtype"},
    {id: "prostanoid ep2 receptor"},
    {id: "prostaglandin e2 receptor ep3 subtype"},
    {id: "prostanoid ep3 receptor"},
    {id: "prostaglandin e2 receptor ep4 subtype"},
    {id: "prostanoid ep4 receptor"},
    {id: "prostaglandin g/h synthase 2"},
    {id: "ec 1.14.99.1"},
    {id: "cyclooxygenase-2"},
    {id: "cox-2"},
    {id: "phs ii"},
    {id: "prostaglandin h2 synthase 2"},
    {id: "pgh synthase 2"},
    {id: "pghs-2"},
    {id: "prostaglandin-endoperoxide synthase 2"},
    {id: "prostaglandin reductase 1"},
    {id: "ec 1.3.1.48"},
    {id: "ec 1.3.1.74"},
    {id: "15-oxoprostaglandin 13-reductase"},
    {id: "dithiolethione-inducible gene 1 protein"},
    {id: "leukotriene b4 12-hydroxydehydrogenase"},
    {id: "nad(p)h-dependent alkenal/one oxidoreductase"},
    {id: "prostaglandin reductase 2"},
    {id: "prg-2"},
    {id: "zinc-binding alcohol dehydrogenase domain-containing protein 1"},
    {id: "prostate and testis expressed 1"},
    {id: "prostate and testis expressed protein"},
    {id: "prostate and testis expressed 3"},
    {id: "prostate and testis expressed dj"},
    {id: "prostate and testis expressed c"},
    {id: "rcg22807-like"},
    {id: "prostate and testis expressed n"},
    {id: "prostate and testis expressed n-like"},
    {id: "prostate and testis expressed family member-like"},
    {id: "prostate and testis expressed protein a"},
    {id: "prostatic acid phosphatase"},
    {id: "5'-nt"},
    {id: "acid phosphatase 3"},
    {id: "fluoride-resistant acid phosphatase"},
    {id: "frap"},
    {id: "protein tyrosine phosphatase acp3"},
    {id: "thiamine monophosphatase"},
    {id: "tmpase"},
    {id: "prostatic steroid-binding protein c1"},
    {id: "prostatein peptide c1"},
    {id: "prostatic steroid-binding protein c2"},
    {id: "prostatein peptide c2"},
    {id: "protamine-2"},
    {id: "sperm histone p2"},
    {id: "sperm protamine p2"},
    {id: "protamine-3"},
    {id: "sperm protamine p3"},
    {id: "proteasome"},
    {id: "prosome, macropain"},
    {id: "proteasome 26s subunit, non-atpase 12"},
    {id: "pa28 alpha"},
    {id: "proteasome 26s subunit, non-atpase 14"},
    {id: "psmd14 protein"},
    {id: "proteasome activator complex subunit 2"},
    {id: "11s regulator complex subunit beta"},
    {id: "reg-beta"},
    {id: "activator of multicatalytic protease subunit 2"},
    {id: "proteasome activator 28 subunit beta"},
    {id: "pa28b"},
    {id: "pa28beta"},
    {id: "proteasome subunit alpha type-1"},
    {id: "macropain subunit c2"},
    {id: "multicatalytic endopeptidase complex subunit c2"},
    {id: "proteasome component c2"},
    {id: "proteasome nu chain"},
    {id: "proteasome subunit alpha type-2"},
    {id: "macropain subunit c3"},
    {id: "multicatalytic endopeptidase complex subunit c3"},
    {id: "proteasome component c3"},
    {id: "proteasome subunit alpha type-3"},
    {id: "macropain subunit c8"},
    {id: "multicatalytic endopeptidase complex subunit c8"},
    {id: "proteasome component c8"},
    {id: "proteasome subunit k"},
    {id: "proteasome subunit alpha type-4"},
    {id: "macropain subunit c9"},
    {id: "multicatalytic endopeptidase complex subunit c9"},
    {id: "proteasome component c9"},
    {id: "proteasome subunit l"},
    {id: "proteasome subunit alpha type-6"},
    {id: "macropain iota chain"},
    {id: "multicatalytic endopeptidase complex iota chain"},
    {id: "proteasome iota chain"},
    {id: "proteasome subunit beta type-2"},
    {id: "macropain subunit c7-i"},
    {id: "multicatalytic endopeptidase complex subunit c7-i"},
    {id: "proteasome component c7-i"},
    {id: "proteasome subunit beta type-3"},
    {id: "proteasome chain 13"},
    {id: "proteasome component c10-ii"},
    {id: "proteasome theta chain"},
    {id: "proteasome subunit beta type-6"},
    {id: "ec 3.4.25.1"},
    {id: "macropain delta chain"},
    {id: "multicatalytic endopeptidase complex delta chain"},
    {id: "proteasome chain 5"},
    {id: "proteasome delta chain"},
    {id: "proteasome subunit y"},
    {id: "protein 4.1"},
    {id: "band 4.1"},
    {id: "erythrocyte membrane protein band 4.1"},
    {id: "protein aar2 homolog"},
    {id: "aar2 splicing factor homolog"},
    {id: "protein aatf"},
    {id: "apoptosis-antagonizing transcription factor"},
    {id: "protein abhd1"},
    {id: "alpha/beta hydrolase domain-containing protein 1"},
    {id: "abhydrolase domain-containing protein 1"},
    {id: "protein abhd16b"},
    {id: "alpha/beta hydrolase domain-containing protein 16b"},
    {id: "abhydrolase domain-containing protein 16b"},
    {id: "protein adm2"},
    {id: "intermedin"},
    {id: "cleaved into: adrenomedullin-2 (am2) (intermedin-long) (imdl); intermedin-short (imds)"},
    {id: "protein abitram"},
    {id: "actin-binding transcription modulator"},
    {id: "protein simiate"},
    {id: "protein bex2"},
    {id: "brain-expressed x-linked protein 2 homolog"},
    {id: "protein bex3"},
    {id: "brain-expressed x-linked protein 3 homolog"},
    {id: "rbex3"},
    {id: "nerve growth factor receptor-associated protein 1"},
    {id: "p75ntr-associated cell death executor"},
    {id: "protein btg1"},
    {id: "anti-proliferative factor"},
    {id: "b-cell translocation gene 1 protein"},
    {id: "protein btg2"},
    {id: "btg family member 2"},
    {id: "ngf-inducible anti-proliferative protein pc3"},
    {id: "protein bud31 homolog"},
    {id: "protein edg-2"},
    {id: "protein g10 homolog"},
    {id: "protein cmss1"},
    {id: "cms1 ribosomal small subunit homolog"},
    {id: "protein ddi1 homolog 1"},
    {id: "protein dr1"},
    {id: "down-regulator of transcription 1"},
    {id: "negative cofactor 2-beta"},
    {id: "protein fam220a"},
    {id: "stat3-interacting protein as a repressor"},
    {id: "protein fam234a"},
    {id: "protein itfg3"},
    {id: "protein fev"},
    {id: "pc12 ets domain-containing transcription factor 1"},
    {id: "pc12 ets factor 1"},
    {id: "pet-1"},
    {id: "protein fmc1 homolog"},
    {id: "formation of mitochondrial complex v assembly factor 1 homolog"},
    {id: "protein flattop"},
    {id: "cilia- and flagella-associated protein 126"},
    {id: "protein fosb"},
    {id: "transcription factor ap-1 subunit fosb"},
    {id: "protein gpr15lg"},
    {id: "protein gpr15 ligand"},
    {id: "protein gpr15l"},
    {id: "protein hexim1"},
    {id: "cardiac lineage protein 1"},
    {id: "protein impact"},
    {id: "imprinted and ancient gene protein homolog"},
    {id: "protein iws1 homolog"},
    {id: "iws1-like protein"},
    {id: "protein llp homolog"},
    {id: "protein laps18-like"},
    {id: "protein memo1"},
    {id: "mediator of erbb2-driven cell motility 1"},
    {id: "memo-1"},
    {id: "protein mix23"},
    {id: "coiled-coil domain-containing protein 58"},
    {id: "protein mms22-like"},
    {id: "methyl methanesulfonate-sensitivity protein 22-like"},
    {id: "protein mdm4"},
    {id: "double minute 4 protein"},
    {id: "mdm2-like p53-binding protein"},
    {id: "p53-binding protein mdm4"},
    {id: "protein n-terminal glutamine amidohydrolase"},
    {id: "ec 3.5.1.122"},
    {id: "protein nh2-terminal glutamine deamidase"},
    {id: "n-terminal gln amidase"},
    {id: "nt(q)-amidase"},
    {id: "wdyhv motif-containing protein 1"},
    {id: "protein ndrg1"},
    {id: "n-myc downstream-regulated gene 1 protein"},
    {id: "protein ndr1"},
    {id: "protein ndrg3"},
    {id: "n-myc downstream-regulated gene 3 protein"},
    {id: "protein niban 1"},
    {id: "protein fam129a"},
    {id: "protein niban"},
    {id: "protein niban 2"},
    {id: "meg-3"},
    {id: "niban-like protein 1"},
    {id: "protein fam129b"},
    {id: "protein o-glucosyltransferase 3"},
    {id: "kdel motif-containing protein 2"},
    {id: "protein o-xylosyltransferase poglut3"},
    {id: "protein o-linked-mannose beta-1,2-n-acetylglucosaminyltransferase"},
    {id: "pomgnt1"},
    {id: "protein o-linked-mannose beta-1,4-n-acetylglucosaminyltransferase 2"},
    {id: "pomgnt2"},
    {id: "ec 2.4.1.312"},
    {id: "extracellular o-linked n-acetylglucosamine transferase-like"},
    {id: "glycosyltransferase-like domain-containing protein 2"},
    {id: "protein o-mannose kinase"},
    {id: "ec 2.7.1.183"},
    {id: "protein kinase-like protein sgk196"},
    {id: "sugen kinase 196"},
    {id: "pomk"},
    {id: "protein o-mannosyl-transferase 1"},
    {id: "dolichyl-phosphate-mannose--protein mannosyltransferase 1"},
    {id: "protein pals1"},
    {id: "maguk p55 subfamily member 5"},
    {id: "protein associated with lin-7 1"},
    {id: "protein pat1 homolog 1"},
    {id: "pat1-like protein 1"},
    {id: "protein pat1 homolog b"},
    {id: "pat1b"},
    {id: "protein pigbos1"},
    {id: "pigb opposite strand protein 1 homolog"},
    {id: "protein s100"},
    {id: "s100 calcium-binding protein"},
    {id: "protein s100-a1"},
    {id: "s-100 protein alpha chain"},
    {id: "s-100 protein subunit alpha"},
    {id: "s100 calcium-binding protein a1"},
    {id: "protein s100-a10"},
    {id: "calpactin i light chain"},
    {id: "calpactin-1 light chain"},
    {id: "cellular ligand of annexin ii"},
    {id: "nerve growth factor-induced protein 42c"},
    {id: "s100 calcium-binding protein a10"},
    {id: "p10 protein"},
    {id: "p11"},
    {id: "protein s100-a11"},
    {id: "calgizzarin"},
    {id: "s100 calcium-binding protein a11"},
    {id: "protein s100-a5"},
    {id: "protein s-100d"},
    {id: "s100 calcium-binding protein a5"},
    {id: "protein s100-a6"},
    {id: "calcyclin"},
    {id: "prolactin receptor-associated protein"},
    {id: "s100 calcium-binding protein a6"},
    {id: "protein s100-a8"},
    {id: "calgranulin-a"},
    {id: "migration inhibitory factor-related protein 8"},
    {id: "mrp-8"},
    {id: "p8"},
    {id: "s100 calcium-binding protein a8"},
    {id: "protein sec13 homolog"},
    {id: "gator complex protein sec13"},
    {id: "sec13-like protein 1"},
    {id: "protein set"},
    {id: "liver regeneration-related protein lrrgr00002"},
    {id: "phosphatase 2a inhibitor i2pp2a"},
    {id: "i-2pp2a"},
    {id: "template-activating factor i"},
    {id: "taf-i"},
    {id: "protein sgt1 homolog"},
    {id: "suppressor of g2 allele of skp1 homolog"},
    {id: "protein shroom2"},
    {id: "liver regeneration-related protein lrrg167"},
    {id: "protein apxl"},
    {id: "protein spindly"},
    {id: "coiled-coil domain-containing protein 99"},
    {id: "spindle apparatus coiled-coil domain-containing protein 1"},
    {id: "protein topaz1"},
    {id: "testis- and ovary-specific paz domain-containing protein 1"},
    {id: "protein uxt"},
    {id: "ubiquitously expressed transcript protein"},
    {id: "protein yif1b"},
    {id: "yip1-interacting factor homolog b"},
    {id: "protein yipf1"},
    {id: "yip1 family member 1"},
    {id: "protein yipf2"},
    {id: "yip1 family member 2"},
    {id: "protein yipf3"},
    {id: "liver regeneration-related protein lrrgt00110"},
    {id: "yip1 family member 3"},
    {id: "protein yipf5"},
    {id: "yip1 family member 5"},
    {id: "ypt-interacting protein 1 a"},
    {id: "protein yipf6"},
    {id: "yip1 family member 6"},
    {id: "protein z-dependent protease inhibitor"},
    {id: "pz-dependent protease inhibitor"},
    {id: "pzi"},
    {id: "regeneration-associated serpin 1"},
    {id: "rasp-1"},
    {id: "serpin a10"},
    {id: "protein adenylyltransferase ficd"},
    {id: "ec 2.7.7.108"},
    {id: "ampylator ficd"},
    {id: "fic domain-containing protein"},
    {id: "protein arginine n-methyltransferase"},
    {id: "ec 2.1.1.321"},
    {id: "protein arginine n-methyltransferase 5"},
    {id: "ec 2.1.1.320"},
    {id: "protein arginine n-methyltransferase 7"},
    {id: "histone-arginine n-methyltransferase prmt7"},
    {id: "[myelin basic protein]-arginine n-methyltransferase prmt7"},
    {id: "protein arginine methyltransferase ndufaf7"},
    {id: "protein argonaute-2"},
    {id: "argonaute2"},
    {id: "ec 3.1.26.n2"},
    {id: "eukaryotic translation initiation factor 2c 2"},
    {id: "eif-2c 2"},
    {id: "eif2c 2"},
    {id: "protein slicer"},
    {id: "protein argonaute-3"},
    {id: "argonaute3"},
    {id: "eukaryotic translation initiation factor 2c 3"},
    {id: "eif-2c 3"},
    {id: "eif2c 3"},
    {id: "protein argonaute-4"},
    {id: "argonaute4"},
    {id: "eukaryotic translation initiation factor 2c 4"},
    {id: "eif-2c 4"},
    {id: "eif2c 4"},
    {id: "protein c-fos"},
    {id: "cellular oncogene fos"},
    {id: "proto-oncogene c-fos"},
    {id: "protein chibby homolog 2"},
    {id: "spermatid-associated protein"},
    {id: "protein delta homolog 1"},
    {id: "dlk-1"},
    {id: "preadipocyte factor 1"},
    {id: "pref-1"},
    {id: "cleaved into: fetal antigen 1 (fa1)"},
    {id: "protein delta homolog 2"},
    {id: "dlk-2"},
    {id: "endothelial cell-specific protein s-1"},
    {id: "epidermal growth factor-like protein 9"},
    {id: "egf-like protein 9"},
    {id: "protein disulfide isomerase creld1"},
    {id: "ec 5.3.4.1"},
    {id: "cysteine-rich with egf-like domain protein 1"},
    {id: "protein disulfide isomerase creld2"},
    {id: "cysteine-rich with egf-like domain protein 2"},
    {id: "protein disulfide-isomerase"},
    {id: "protein disulfide-isomerase a4"},
    {id: "endoplasmic reticulum resident protein 72"},
    {id: "protein disulfide-isomerase a5"},
    {id: "protein disulfide-isomerase a6"},
    {id: "protein farnesyltransferase subunit beta"},
    {id: "ftase-beta"},
    {id: "ec 2.5.1.58"},
    {id: "caax farnesyltransferase subunit beta"},
    {id: "ras proteins prenyltransferase subunit beta"},
    {id: "protein fem-1 homolog a"},
    {id: "fem1a"},
    {id: "fem1-alpha"},
    {id: "protein fem-1 homolog b"},
    {id: "fem1b"},
    {id: "fem1-beta"},
    {id: "protein inturned"},
    {id: "inturned planar cell polarity effector homolog"},
    {id: "protein kinase c"},
    {id: "ec 2.7.11.13"},
    {id: "protein kinase c and casein kinase substrate in neurons 2 protein"},
    {id: "synaptic dynamin-associated protein ii"},
    {id: "syndapin-2"},
    {id: "syndapin-ii"},
    {id: "sdpii"},
    {id: "protein kinase c delta type"},
    {id: "npkc-delta"},
    {id: "protein kinase c epsilon type"},
    {id: "npkc-epsilon"},
    {id: "protein kinase c eta type"},
    {id: "pkc-l"},
    {id: "npkc-eta"},
    {id: "protein kinase c iota type"},
    {id: "atypical protein kinase c-lambda/iota"},
    {id: "apkc-lambda/iota"},
    {id: "npkc-iota"},
    {id: "protein kinase c theta type"},
    {id: "npkc-theta"},
    {id: "protein kinase-related protein"},
    {id: "receptor-like tyrosine kinase"},
    {id: "protein kintoun"},
    {id: "dynein assembly factor 2, axonemal"},
    {id: "protein lifeguard 2"},
    {id: "fas apoptotic inhibitory molecule 2"},
    {id: "neural membrane protein 35"},
    {id: "protein lin-7 homolog b"},
    {id: "lin-7b"},
    {id: "mammalian lin-seven protein 2"},
    {id: "mals-2"},
    {id: "vertebrate lin-7 homolog 2"},
    {id: "veli-2"},
    {id: "protein lin-7 homolog c"},
    {id: "lin-7c"},
    {id: "mammalian lin-seven protein 3"},
    {id: "mals-3"},
    {id: "vertebrate lin-7 homolog 3"},
    {id: "veli-3"},
    {id: "protein lyl-1"},
    {id: "lymphoblastic leukemia-derived sequence 1"},
    {id: "protein max"},
    {id: "myc-associated factor x"},
    {id: "protein phosphatase"},
    {id: "protein phosphatase 1 regulatory subunit 12a"},
    {id: "mbsp"},
    {id: "myosin phosphatase-targeting subunit 1"},
    {id: "myosin phosphatase target subunit 1"},
    {id: "protein phosphatase myosin-binding subunit"},
    {id: "protein phosphatase subunit 1m"},
    {id: "pp-1m"},
    {id: "serine/threonine protein phosphatase pp1 smooth muscle regulatory subunit m110"},
    {id: "protein phosphatase 1 regulatory subunit 14c"},
    {id: "kinase-enhanced pp1 inhibitor"},
    {id: "pkc-potentiated pp1 inhibitory protein"},
    {id: "protein phosphatase 1 regulatory subunit 1a"},
    {id: "protein phosphatase inhibitor 1"},
    {id: "i-1"},
    {id: "ipp-1"},
    {id: "protein phosphatase 1 regulatory subunit 1b"},
    {id: "darpp-32"},
    {id: "dopamine- and camp-regulated neuronal phosphoprotein"},
    {id: "protein phosphatase 1 regulatory subunit 21"},
    {id: "coiled-coil domain-containing protein 128"},
    {id: "klraq motif-containing protein 1"},
    {id: "protein phosphatase 1 regulatory subunit 32"},
    {id: "protein iiig9"},
    {id: "protein phosphatase 1 regulatory subunit 37"},
    {id: "leucine-rich repeat-containing protein 68"},
    {id: "protein phosphatase 1 regulatory subunit 3b"},
    {id: "33 kda glycogen-binding protein"},
    {id: "hepatic glycogen-targeting protein phosphatase 1 regulatory subunit gl"},
    {id: "protein phosphatase 1 regulatory subunit 4"},
    {id: "pp1 subunit r4"},
    {id: "protein phosphatase 1 subunit gl"},
    {id: "protein phosphatase 1 regulatory subunit 7"},
    {id: "protein phosphatase 1 regulatory subunit 22"},
    {id: "protein phosphatase 1, regulatory"},
    {id: "inhibitor"},
    {id: "protein phosphatase 1e"},
    {id: "ca(2+)/calmodulin-dependent protein kinase phosphatase n"},
    {id: "camkp-n"},
    {id: "camkp-nucleus"},
    {id: "camkn"},
    {id: "partner of pix 1"},
    {id: "partner of pix-alpha"},
    {id: "partner of pixa"},
    {id: "protein phosphatase 1f"},
    {id: "ca(2+)/calmodulin-dependent protein kinase phosphatase"},
    {id: "cam-kinase phosphatase"},
    {id: "camkpase"},
    {id: "partner of pix 2"},
    {id: "protein phosphatase 1g"},
    {id: "formerly 2c"},
    {id: "protein phosphatase pp2c gamma"},
    {id: "protein phosphatase, mg2+/mn2+ dependent, 1g"},
    {id: "protein phosphatase 1h"},
    {id: "protein phosphatase inhibitor 2"},
    {id: "ipp-2"},
    {id: "protein phosphatase methylesterase 1"},
    {id: "pme-1"},
    {id: "ec 3.1.1.89"},
    {id: "protein prune homolog 2"},
    {id: "bnip2 motif-containing molecule at the c-terminal region 1"},
    {id: "protein quaking-like"},
    {id: "qk protein"},
    {id: "protein salvador homolog 1"},
    {id: "45 kda ww domain protein"},
    {id: "rww45"},
    {id: "protein shisa-5"},
    {id: "scotin"},
    {id: "protein spinster homolog 1"},
    {id: "rspin1"},
    {id: "spns1"},
    {id: "protein transport protein sec16b"},
    {id: "leucine zipper transcription regulator 2"},
    {id: "regucalcin gene promoter region-related protein p117"},
    {id: "rgpr-p117"},
    {id: "sec16 homolog b"},
    {id: "protein turtle homolog a"},
    {id: "dendrite arborization and synapse maturation protein 1"},
    {id: "immunoglobulin superfamily member 9a"},
    {id: "igsf9a"},
    {id: "protein tyrosine phosphatase receptor type c-associated protein"},
    {id: "ptprc-associated protein"},
    {id: "cd45-associated protein"},
    {id: "protein tyrosine phosphatase type iva 1"},
    {id: "protein-tyrosine phosphatase 4a1"},
    {id: "protein-tyrosine phosphatase of regenerating liver 1"},
    {id: "prl-1"},
    {id: "protein tyrosine phosphatase type iva 2"},
    {id: "protein-tyrosine phosphatase 4a2"},
    {id: "protein-tyrosine phosphatase of regenerating liver 2"},
    {id: "prl-2"},
    {id: "protein unc-119 homolog a"},
    {id: "retinal protein 4"},
    {id: "rrg4"},
    {id: "protein unc-45 homolog a"},
    {id: "unc-45a"},
    {id: "smooth muscle cell-associated protein 1"},
    {id: "smap-1"},
    {id: "protein unc-50 homolog"},
    {id: "uncoordinated-like protein"},
    {id: "protein wntless homolog"},
    {id: "integral membrane protein gpr177"},
    {id: "protein evenness interrupted homolog"},
    {id: "evi"},
    {id: "protein-l-isoaspartate"},
    {id: "d-aspartate"},
    {id: "protein-s-isoprenylcysteine o-methyltransferase"},
    {id: "ec 2.1.1.100"},
    {id: "farnesyl cysteine carboxyl methyltransferase"},
    {id: "fcmt"},
    {id: "isoprenylcysteine carboxylmethyltransferase"},
    {id: "prenylated protein carboxyl methyltransferase"},
    {id: "ppmt"},
    {id: "prenylcysteine carboxyl methyltransferase"},
    {id: "pccmt"},
    {id: "protein-arginine deiminase"},
    {id: "ec 3.5.3.15"},
    {id: "protein-arginine deiminase type-4"},
    {id: "pad-r4"},
    {id: "peptidylarginine deiminase iv"},
    {id: "peptidylarginine deiminase type alpha"},
    {id: "protein-arginine deiminase type iv"},
    {id: "protein-glutamine gamma-glutamyltransferase 2"},
    {id: "ec 2.3.2.13"},
    {id: "isopeptidase tgm2"},
    {id: "protein g alpha(h)"},
    {id: "protein g(h)"},
    {id: "protein-glutamine deamidase tgm2"},
    {id: "ec 3.5.1.44"},
    {id: "protein-glutamine dopaminyltransferase tgm2"},
    {id: "protein-glutamine histaminyltransferase tgm2"},
    {id: "protein-glutamine noradrenalinyltransferase tgm2"},
    {id: "protein-glutamine serotonyltransferase tgm2"},
    {id: "tissue transglutaminase"},
    {id: "ttg"},
    {id: "ttgase"},
    {id: "transglutaminase ii"},
    {id: "tgase ii"},
    {id: "transglutaminase-2"},
    {id: "tgase 2"},
    {id: "tgase-2"},
    {id: "protein-glutamine gamma-glutamyltransferase e"},
    {id: "transglutaminase e"},
    {id: "tg(e)"},
    {id: "tge"},
    {id: "tgase e"},
    {id: "transglutaminase-3"},
    {id: "tgase-3"},
    {id: "cleaved into: protein-glutamine gamma-glutamyltransferase e 50 kda catalytic chain; protein-glutamine gamma-glutamyltransferase e 27 kda non-catalytic chain"},
    {id: "protein-glutamine gamma-glutamyltransferase k"},
    {id: "epidermal tgase"},
    {id: "transglutaminase k"},
    {id: "tg(k)"},
    {id: "tgk"},
    {id: "tgase k"},
    {id: "transglutaminase-1"},
    {id: "tgase-1"},
    {id: "protein-serine/threonine kinase"},
    {id: "protein-tyrosine kinase 2-beta"},
    {id: "calcium-dependent tyrosine kinase"},
    {id: "cadtk"},
    {id: "calcium-regulated non-receptor proline-rich tyrosine kinase"},
    {id: "cell adhesion kinase beta"},
    {id: "cak-beta"},
    {id: "cakb"},
    {id: "focal adhesion kinase 2"},
    {id: "fadk 2"},
    {id: "proline-rich tyrosine kinase 2"},
    {id: "protein-tyrosine sulfotransferase"},
    {id: "ec 2.8.2.20"},
    {id: "protein-tyrosine sulfotransferase 1"},
    {id: "tyrosylprotein sulfotransferase 1"},
    {id: "tpst-1"},
    {id: "proteinase-activated receptor 2"},
    {id: "par-2"},
    {id: "coagulation factor ii receptor-like 1"},
    {id: "thrombin receptor-like 1"},
    {id: "prothrombin"},
    {id: "ec 3.4.21.5"},
    {id: "proto-oncogene tyrosine-protein kinase src"},
    {id: "proto-oncogene c-src"},
    {id: "pp60c-src"},
    {id: "p60-src"},
    {id: "protocadherin 7"},
    {id: "protocadherin 7c"},
    {id: "protocadherin alpha-4"},
    {id: "pcdh-alpha-4"},
    {id: "cadherin-related neuronal receptor 4"},
    {id: "protocadherin-16"},
    {id: "protein dachsous homolog 1"},
    {id: "protocadherin-8"},
    {id: "activity-regulated cadherin-like protein"},
    {id: "arcadlin"},
    {id: "protoheme ix farnesyltransferase, mitochondrial"},
    {id: "heme o synthase"},
    {id: "proton channel otop1"},
    {id: "otopetrin-1"},
    {id: "proton myo-inositol cotransporter"},
    {id: "h(+)-myo-inositol cotransporter"},
    {id: "hmit"},
    {id: "h(+)-myo-inositol symporter"},
    {id: "solute carrier family 2 member 13"},
    {id: "proton-activated chloride channel"},
    {id: "pac"},
    {id: "transmembrane protein 206"},
    {id: "proton-coupled amino acid transporter 1"},
    {id: "proton/amino acid transporter 1"},
    {id: "lysosomal amino acid transporter 1"},
    {id: "lyaat-1"},
    {id: "neutral amino acid/proton symporter"},
    {id: "solute carrier family 36 member 1"},
    {id: "proton-coupled amino acid transporter 3"},
    {id: "proton/amino acid transporter 3"},
    {id: "solute carrier family 36 member 3"},
    {id: "tramdorin-2"},
    {id: "proton-coupled folate transporter"},
    {id: "rpcft"},
    {id: "heme carrier protein 1"},
    {id: "pcft/hcp1"},
    {id: "solute carrier family 46 member 1"},
    {id: "proton-coupled zinc antiporter slc30a1"},
    {id: "solute carrier family 30 member 1"},
    {id: "zinc transporter 1"},
    {id: "znt-1"},
    {id: "proton-coupled zinc antiporter slc30a8"},
    {id: "solute carrier family 30 member 8"},
    {id: "zinc transporter 8"},
    {id: "znt-8"},
    {id: "proton-coupled zinc antiporter slc30a9, mitochondrial"},
    {id: "solute carrier family 30 member 9"},
    {id: "zinc transporter 9"},
    {id: "protrudin"},
    {id: "zinc finger fyve domain-containing protein 27"},
    {id: "pseudouridylate synthase 1 homolog"},
    {id: "ec 5.4.99.-"},
    {id: "trna pseudouridine synthase 1"},
    {id: "ec 5.4.99.12"},
    {id: "trna pseudouridine(38-40) synthase"},
    {id: "trna pseudouridylate synthase i"},
    {id: "trna-uridine isomerase i"},
    {id: "pseudouridylate synthase rpusd4, mitochondrial"},
    {id: "rna pseudouridylate synthase domain-containing protein 4"},
    {id: "pseudouridylate synthase trub1"},
    {id: "trub pseudouridine synthase homolog 1"},
    {id: "trna pseudouridine 55 synthase trub1"},
    {id: "psi55 synthase trub1"},
    {id: "ec 5.4.99.25"},
    {id: "pterin-4-alpha-carbinolamine dehydratase"},
    {id: "phs"},
    {id: "4-alpha-hydroxy-tetrahydropterin dehydratase"},
    {id: "dimerization cofactor of hepatocyte nuclear factor 1-alpha"},
    {id: "dcoh"},
    {id: "dimerization cofactor of hnf1"},
    {id: "phenylalanine hydroxylase-stimulating protein"},
    {id: "pterin carbinolamine dehydratase"},
    {id: "pcd"},
    {id: "pulmonary surfactant-associated protein c"},
    {id: "pulmonary surfactant-associated proteolipid spl(val)"},
    {id: "purine nucleoside phosphorylase"},
    {id: "ec 2.4.2.1"},
    {id: "inosine phosphorylase"},
    {id: "inosine-guanosine phosphorylase"},
    {id: "pnp"},
    {id: "purple acid phosphatase"},
    {id: "putative c->u-editing enzyme apobec-4"},
    {id: "ec 3.5.4.-"},
    {id: "apolipoprotein b mrna-editing enzyme catalytic polypeptide-like 4"},
    {id: "putative dol-p-glc:glc"},
    {id: "ec 2.4.1.256"},
    {id: "alpha-1,2-glucosyltransferase alg10-a"},
    {id: "alpha-2-glucosyltransferase alg10-b"},
    {id: "asparagine-linked glycosylation protein 10 homolog b"},
    {id: "potassium channel regulator 1"},
    {id: "putative erv-f"},
    {id: "putative glutathione-specific gamma-glutamylcyclotransferase 2"},
    {id: "gamma-gcg 2"},
    {id: "cation transport regulator-like protein 2"},
    {id: "putative glycerol kinase 5"},
    {id: "ec 2.7.1.30"},
    {id: "atp:glycerol 3-phosphotransferase 5"},
    {id: "putative hydroxypyruvate isomerase"},
    {id: "ec 5.3.1.22"},
    {id: "putative inactive serine protease 58"},
    {id: "ec 3.4.21.4"},
    {id: "trypsin-x3"},
    {id: "putative lipoyltransferase 2, mitochondrial"},
    {id: "ec 2.3.1.181"},
    {id: "putative monooxygenase p33monox"},
    {id: "liver regeneration-related protein lrrg011"},
    {id: "putative protein mss51 homolog, mitochondrial"},
    {id: "zinc finger mynd domain-containing protein 17"},
    {id: "putative protein-lysine deacylase abhd14b"},
    {id: "alpha/beta hydrolase domain-containing protein 14b"},
    {id: "abhydrolase domain-containing protein 14b"},
    {id: "putative trna"},
    {id: "cytidine(32)/guanosine(34)-2'-o"},
    {id: "ec 2.1.1.205"},
    {id: "2'-o-ribose rna methyltransferase trm7 homolog"},
    {id: "protein ftsj homolog 1"},
    {id: "pyrethroid hydrolase ces2e"},
    {id: "ec 3.1.1.88"},
    {id: "pyridoxal kinase"},
    {id: "ec 2.7.1.35"},
    {id: "pyridoxine kinase"},
    {id: "pyridoxal phosphate homeostasis protein"},
    {id: "plp homeostasis protein"},
    {id: "proline synthase co-transcribed bacterial homolog protein"},
    {id: "pyridoxal phosphate phosphatase phospho2"},
    {id: "ec 3.1.3.74"},
    {id: "pyridoxine-5'-phosphate oxidase"},
    {id: "ec 1.4.3.5"},
    {id: "pyridoxamine-phosphate oxidase"},
    {id: "pyroglutamyl-peptidase 1"},
    {id: "ec 3.4.19.3"},
    {id: "5-oxoprolyl-peptidase"},
    {id: "pyroglutamyl aminopeptidase i"},
    {id: "pap-i"},
    {id: "pyroglutamyl-peptidase i"},
    {id: "pgp-i"},
    {id: "pyrrolidone-carboxylate peptidase"},
    {id: "pyroglutamylated rf-amide peptide receptor"},
    {id: "aq27"},
    {id: "g-protein coupled receptor 103"},
    {id: "orexigenic neuropeptide qrfp receptor"},
    {id: "sp9155"},
    {id: "pyrroline-5-carboxylate reductase"},
    {id: "ec 1.5.1.2"},
    {id: "pyruvate dehydrogenase e1 component subunit alpha"},
    {id: "ec 1.2.4.1"},
    {id: "pyruvate dehydrogenase e1 component subunit beta"},
    {id: "pyruvate kinase"},
    {id: "ec 2.7.1.40"},
    {id: "queuine trna-ribosyltransferase accessory subunit 2"},
    {id: "queuine trna-ribosyltransferase domain-containing protein 1"},
    {id: "queuine trna-ribosyltransferase catalytic subunit 1"},
    {id: "ec 2.4.2.64"},
    {id: "guanine insertion enzyme"},
    {id: "trna-guanine transglycosylase"},
    {id: "quinone oxidoreductase"},
    {id: "ec 1.6.5.5"},
    {id: "nadph:quinone reductase"},
    {id: "zeta-crystallin"},
    {id: "quinone oxidoreductase-like protein 2"},
    {id: "r-spondin 2"},
    {id: "r-spondin2 protein"},
    {id: "r-spondin 3"},
    {id: "r-spondin 3-like protein"},
    {id: "rab28, member ras oncogene family"},
    {id: "rab28 protein"},
    {id: "rab3b, member ras oncogene family"},
    {id: "rab3b protein"},
    {id: "rab5a, member ras oncogene family"},
    {id: "rab5a protein"},
    {id: "rab6-interacting golgin"},
    {id: "n-terminal kinase-like-binding protein 1"},
    {id: "ntkl-bp1"},
    {id: "ntkl-binding protein 1"},
    {id: "scy1-like 1-binding protein 1"},
    {id: "scyl1-bp1"},
    {id: "scyl1-binding protein 1"},
    {id: "rbmy"},
    {id: "rna binding motif protein, y-linked, family 1, member j"},
    {id: "rbpj-interacting and tubulin-associated protein 1"},
    {id: "rbpj-interacting and tubulin-associated protein"},
    {id: "rbr-type e3 ubiquitin transferase"},
    {id: "rcc1 and btb domain-containing protein 2"},
    {id: "chromosome condensation 1-like"},
    {id: "regulator of chromosome condensation and btb domain-containing protein 2"},
    {id: "rcr-type e3 ubiquitin transferase"},
    {id: "ec 2.3.2.33"},
    {id: "relt-like 1"},
    {id: "rell1 protein"},
    {id: "rest corepressor 3"},
    {id: "rcor3 protein"},
    {id: "rgd1305455 protein"},
    {id: "trafficking protein particle complex subunit 14"},
    {id: "rgd1306462 protein"},
    {id: "tubulin tyrosine ligase like 8"},
    {id: "rgd1307355 protein"},
    {id: "serine/threonine kinase-like domain containing 1"},
    {id: "rgd1308026 protein"},
    {id: "swim-type zinc finger 7 associated protein 1"},
    {id: "rgd1311084 protein"},
    {id: "similar to 1700113k14rik protein"},
    {id: "rgd1560775 protein"},
    {id: "similar to riken cdna 4930579c12 gene"},
    {id: "rgd1560860 protein"},
    {id: "similar to ankyrin repeat domain 26"},
    {id: "rgd1560940 protein"},
    {id: "serine protease 44"},
    {id: "rgd1561552 protein"},
    {id: "similar to wasp family 1"},
    {id: "rgd1561648"},
    {id: "rgd1561648 protein"},
    {id: "rgd1562024"},
    {id: "rgd1562024 protein"},
    {id: "rgd1562665 protein"},
    {id: "zinc finger protein 707"},
    {id: "rgd1563224 protein"},
    {id: "transmembrane protein 65"},
    {id: "rgd1564140 protein"},
    {id: "similar to kiaa1328 protein"},
    {id: "rgd1564379 protein"},
    {id: "transmembrane protein 250"},
    {id: "rgd1565410 protein"},
    {id: "similar to ly6-c antigen gene"},
    {id: "rgd1565685 protein"},
    {id: "similar to riken cdna 1810030o07"},
    {id: "rgd1566036 protein"},
    {id: "scaffold protein involved in dna repair"},
    {id: "rgd1566403 protein"},
    {id: "transmembrane protein 181"},
    {id: "rilp-like protein 2"},
    {id: "rab-interacting lysosomal-like protein 2"},
    {id: "ring finger protein 39"},
    {id: "ltp-induced ring finger protein"},
    {id: "ring-type e3 ubiquitin transferase rad18"},
    {id: "rna 3'-terminal-phosphate cyclase"},
    {id: "atp"},
    {id: "ec 6.5.1.4"},
    {id: "rna terminal phosphate cyclase domain-containing protein 1"},
    {id: "rna 5'-monophosphate methyltransferase"},
    {id: "bcdin3 domain-containing protein"},
    {id: "rna binding motif"},
    {id: "rnp1, rrm"},
    {id: "rbm3 protein"},
    {id: "rna binding motif protein 7"},
    {id: "rbm7 protein"},
    {id: "rna binding protein fox-1 homolog 2"},
    {id: "fox-1 homolog b"},
    {id: "rna-binding motif protein 9"},
    {id: "rna-binding protein 9"},
    {id: "rna binding protein, mrna processing factor 2"},
    {id: "rbpms2 protein"},
    {id: "rna cytidine acetyltransferase"},
    {id: "18s rrna cytosine acetyltransferase"},
    {id: "n-acetyltransferase 10"},
    {id: "rna demethylase alkbh5"},
    {id: "alkylated dna repair protein alkb homolog 5"},
    {id: "alpha-ketoglutarate-dependent dioxygenase alkb homolog 5"},
    {id: "rna exonuclease 5"},
    {id: "putative rna exonuclease nef-sp"},
    {id: "rna helicase"},
    {id: "deah box protein 9"},
    {id: "nuclear dna helicase ii"},
    {id: "rna methyltransferase"},
    {id: "rna polymerase ii subunit a c-terminal domain phosphatase"},
    {id: "rna polymerase ii subunit a c-terminal domain phosphatase ssu72"},
    {id: "ctd phosphatase ssu72"},
    {id: "rna polymerase ii subunit b1 ctd phosphatase rpap2 homolog"},
    {id: "rna uridylyltransferase"},
    {id: "ec 2.7.7.52"},
    {id: "rna-binding motif protein, x chromosome"},
    {id: "heterogeneous nuclear ribonucleoprotein g"},
    {id: "hnrnp g"},
    {id: "rna-binding motif protein, x chromosome retrogene"},
    {id: "rna-binding motif protein, x chromosome retrogene-like"},
    {id: "cleaved into: rna-binding motif protein, x chromosome, n-terminally processed"},
    {id: "rna-binding protein 10"},
    {id: "rna-binding motif protein 10"},
    {id: "rna-binding protein s1-1"},
    {id: "rna-binding protein 20"},
    {id: "rna-binding motif protein 20"},
    {id: "rna-binding protein 34"},
    {id: "rna-binding motif protein 34"},
    {id: "rna-binding protein 42"},
    {id: "rna-binding motif protein 42"},
    {id: "rna-binding protein 47"},
    {id: "rna-binding motif protein 47"},
    {id: "rna-binding protein 4b"},
    {id: "rna-binding motif protein 30"},
    {id: "rna-binding motif protein 4b"},
    {id: "rna-binding protein 30"},
    {id: "zinc-responsive protein zd7"},
    {id: "rna-binding protein 8a"},
    {id: "rna-binding motif protein 8a"},
    {id: "ribonucleoprotein rbm8a"},
    {id: "rna-binding protein musashi homolog 1"},
    {id: "musashi-1"},
    {id: "rna-binding region-containing protein 3"},
    {id: "rna-binding motif protein 40"},
    {id: "rna-binding protein 40"},
    {id: "rna-directed dna polymerase"},
    {id: "ec 2.7.7.49"},
    {id: "rna-splicing ligase rtcb homolog"},
    {id: "rna/rnp complex-1-interacting phosphatase"},
    {id: "dual specificity protein phosphatase 11"},
    {id: "phosphatase that interacts with rna/rnp complex 1"},
    {id: "rpgr interacting protein 1"},
    {id: "retinitis pigmentosa gtpase regulator interacting protein 1"},
    {id: "rrp15-like protein"},
    {id: "ribosomal rna-processing protein 15"},
    {id: "rt1 class i, m1, gene 2"},
    {id: "rt1 class i, locus m1, gene 2"},
    {id: "rt1 class i, m6, gene 2"},
    {id: "rt1 class i, locus m6, gene 2"},
    {id: "rt1 class i, o1"},
    {id: "rt1 class ib, locus s2"},
    {id: "rt1 class i, s2"},
    {id: "rt1 class ib, locus o1"},
    {id: "rt1 class i, t24, gene 1"},
    {id: "rt1 class i, locus t24, gene 1"},
    {id: "rt1 class ii, h alpha"},
    {id: "rt1 class ii, locus ha"},
    {id: "rt1 class ii, locus dob"},
    {id: "rt1-dob"},
    {id: "rwd domain-containing protein 1"},
    {id: "small androgen receptor-interacting protein"},
    {id: "rab gdp dissociation inhibitor beta"},
    {id: "rab gdi beta"},
    {id: "gdi-3"},
    {id: "guanosine diphosphate dissociation inhibitor 2"},
    {id: "gdi-2"},
    {id: "rab effector noc2"},
    {id: "no c2 domains protein"},
    {id: "rabphilin-3a-like protein"},
    {id: "rab-3a-interacting protein"},
    {id: "rab3a-interacting protein"},
    {id: "rabin-3"},
    {id: "ssx2-interacting protein"},
    {id: "rab11 family-interacting protein 1"},
    {id: "rab11-fip1"},
    {id: "rab-coupling protein"},
    {id: "rac/cdc42 guanine nucleotide exchange factor"},
    {id: "gef"},
    {id: "radial spoke head homolog 14"},
    {id: "chlamydomonas"},
    {id: "ragulator complex protein lamtor1"},
    {id: "late endosomal/lysosomal adaptor and mapk and mtor activator 1"},
    {id: "lipid raft adaptor protein p18"},
    {id: "ragulator complex protein lamtor3"},
    {id: "late endosomal/lysosomal adaptor and mapk and mtor activator 3"},
    {id: "ragulator complex protein lamtor4"},
    {id: "late endosomal/lysosomal adaptor and mapk and mtor activator 4"},
    {id: "ragulator complex protein lamtor5"},
    {id: "late endosomal/lysosomal adaptor and mapk and mtor activator 5"},
    {id: "ral gtpase-activating protein subunit alpha-1"},
    {id: "gap-related-interacting partner to e12"},
    {id: "gripe"},
    {id: "gtpase-activating rapgap domain-like 1"},
    {id: "tuberin-like protein 1"},
    {id: "p240"},
    {id: "ral gtpase-activating protein subunit alpha-2"},
    {id: "250 kda substrate of akt"},
    {id: "as250"},
    {id: "p220"},
    {id: "ranbp-type and c3hc4-type zinc finger-containing protein 1"},
    {id: "rap guanine nucleotide exchange factor 4"},
    {id: "camps"},
    {id: "ras association"},
    {id: "ralgds/af-6"},
    {id: "ras association domain-containing protein 5"},
    {id: "maxp1"},
    {id: "new ras effector 1"},
    {id: "ras homolog family member c"},
    {id: "ras homolog gene family, member c"},
    {id: "ras homolog family member j"},
    {id: "ras homolog gene family, member j"},
    {id: "ras-like protein family member 11a"},
    {id: "ras-like protein family member 11b"},
    {id: "ras-related gtp-binding protein b"},
    {id: "rag b"},
    {id: "ragb"},
    {id: "ras-related protein r-ras"},
    {id: "p23"},
    {id: "ras-related protein rab-11a"},
    {id: "rab-11"},
    {id: "24kg"},
    {id: "ras-related protein rab-1a"},
    {id: "ras-related protein rab-27a"},
    {id: "rab-27"},
    {id: "gtp-binding protein ram"},
    {id: "ram p25"},
    {id: "ras-related protein rab-27b"},
    {id: "ras-related protein rab-4b"},
    {id: "ras-related protein rab-7l1"},
    {id: "rab-7-like protein 1"},
    {id: "ras-related protein rab-29"},
    {id: "ras-related protein rab-7a"},
    {id: "ras-related protein brl-ras"},
    {id: "ras-related protein p23"},
    {id: "ras-related protein rab-8a"},
    {id: "ras-related protein ral-a"},
    {id: "ras-related protein ral-b"},
    {id: "ras-related protein rap-1a"},
    {id: "ras-related protein krev-1"},
    {id: "ras-related protein rap-1b"},
    {id: "gtp-binding protein smg p21b"},
    {id: "ras-related protein rap-2b"},
    {id: "reactive oxygen species modulator 1"},
    {id: "protein mgr2 homolog"},
    {id: "receptor"},
    {id: "calcitonin"},
    {id: "chemosensory"},
    {id: "receptor expression-enhancing protein 6"},
    {id: "polyposis locus protein 1-like 1"},
    {id: "receptor for retinol uptake stra6"},
    {id: "retinol-binding protein receptor stra6"},
    {id: "stimulated by retinoic acid gene 6 protein homolog"},
    {id: "receptor protein-tyrosine kinase"},
    {id: "receptor-binding cancer antigen expressed on siso cells"},
    {id: "estrogen receptor-binding fragment-associated gene 9 protein"},
    {id: "receptor-interacting serine/threonine-protein kinase 2"},
    {id: "tyrosine-protein kinase ripk2"},
    {id: "receptor-type tyrosine-protein phosphatase"},
    {id: "receptor-type tyrosine-protein phosphatase n2"},
    {id: "r-ptp-n2"},
    {id: "ptp ne-6"},
    {id: "ptpne6"},
    {id: "phogrin"},
    {id: "cleaved into: ia-2beta60"},
    {id: "receptor-type tyrosine-protein phosphatase epsilon"},
    {id: "protein-tyrosine phosphatase epsilon"},
    {id: "r-ptp-epsilon"},
    {id: "reduced folate transporter"},
    {id: "methotrexate carrier 1"},
    {id: "mtx-1"},
    {id: "mtx1"},
    {id: "plasma membrane folate antiporter slc19a1"},
    {id: "reduced folate carrier 1"},
    {id: "rfc-1"},
    {id: "rfc1"},
    {id: "solute carrier family 19 member 1"},
    {id: "refilin-b"},
    {id: "regulator of filamin protein b"},
    {id: "refilinb"},
    {id: "regenerating islet-derived protein 4"},
    {id: "reg-4"},
    {id: "regulating synaptic membrane exocytosis protein 2"},
    {id: "rab-3-interacting molecule 2"},
    {id: "rim 2"},
    {id: "regulator of g-protein signaling 1"},
    {id: "rgs1"},
    {id: "regulator of g-protein signaling 10"},
    {id: "rgs10"},
    {id: "regulator of g-protein signaling 14"},
    {id: "rgs14"},
    {id: "regulator of g-protein signaling 19"},
    {id: "rgs19"},
    {id: "g-alpha-interacting protein"},
    {id: "gaip"},
    {id: "regulator of g-protein signaling 2"},
    {id: "rgs2"},
    {id: "regulator of g-protein signaling 4"},
    {id: "rgp4"},
    {id: "rgs4"},
    {id: "regulator of g-protein signaling 5"},
    {id: "rgs5"},
    {id: "regulator of g-protein signaling 7"},
    {id: "rgs7"},
    {id: "regulator of g-protein signaling 7-binding protein"},
    {id: "r7 family-binding protein"},
    {id: "regulator of g-protein signaling 8"},
    {id: "rgs8"},
    {id: "regulator of g-protein signaling 9"},
    {id: "rgs9"},
    {id: "regulator of cell cycle rgcc"},
    {id: "response gene to complement 32 protein"},
    {id: "rgc-32"},
    {id: "regulator of microtubule dynamics protein 1"},
    {id: "rmd-1"},
    {id: "protein fam82b"},
    {id: "regulator of microtubule dynamics protein 2"},
    {id: "rmd-2"},
    {id: "protein fam82a1"},
    {id: "regulator of microtubule dynamics protein 3"},
    {id: "rmd-3"},
    {id: "protein fam82a2"},
    {id: "protein fam82c"},
    {id: "regulator of telomere elongation helicase 1"},
    {id: "regulatory factor x, 3"},
    {id: "influences hla class ii expression"},
    {id: "regulatory factor x3"},
    {id: "relaxin-3"},
    {id: "insulin-like peptide insl7"},
    {id: "insulin-like peptide 7"},
    {id: "prorelaxin r3"},
    {id: "cleaved into: relaxin-3 b chain; relaxin-3 a chain"},
    {id: "renalase"},
    {id: "ec 1.6.3.5"},
    {id: "monoamine oxidase-c"},
    {id: "mao-c"},
    {id: "alpha-nad(p)h oxidase/anomerase"},
    {id: "renin receptor"},
    {id: "atpase h(+)-transporting lysosomal accessory protein 2"},
    {id: "atpase h(+)-transporting lysosomal-interacting protein 2"},
    {id: "renin/prorenin receptor"},
    {id: "replication factor c subunit 2"},
    {id: "activator 1 subunit c2"},
    {id: "replication protein a 32 kda subunit"},
    {id: "rp-a p32"},
    {id: "replication factor a protein 2"},
    {id: "rf-a protein 2"},
    {id: "replication termination factor 2"},
    {id: "rtf2"},
    {id: "replication termination factor 2 domain-containing protein 1"},
    {id: "reproductive homeobox 10"},
    {id: "reproductive homeobox on x chromosome 10"},
    {id: "reproductive homeobox 4g"},
    {id: "reproductive homeobox on x chromosome 4"},
    {id: "reproductive homeobox on x chromosome 11"},
    {id: "rhox homeobox family member 11"},
    {id: "reproductive homeobox on x chromosome 2"},
    {id: "rhox homeobox family member 2"},
    {id: "reticulon-1"},
    {id: "neuroendocrine-specific protein"},
    {id: "s-rex"},
    {id: "reticulon-2"},
    {id: "neuroendocrine-specific protein-like 1"},
    {id: "nsp-like protein 1"},
    {id: "neuroendocrine-specific protein-like i"},
    {id: "nsp-like protein i"},
    {id: "nspli"},
    {id: "reticulon-4"},
    {id: "foocen"},
    {id: "glut4 vesicle 20 kda protein"},
    {id: "neurite outgrowth inhibitor"},
    {id: "nogo protein"},
    {id: "reticulon-4 receptor"},
    {id: "nogo receptor"},
    {id: "ngr"},
    {id: "nogo-66 receptor"},
    {id: "reticulon-4 receptor-like 1"},
    {id: "nogo receptor-like 2"},
    {id: "nogo-66 receptor homolog 2"},
    {id: "nogo-66 receptor-related protein 3"},
    {id: "ngr3"},
    {id: "reticulophagy regulator 1"},
    {id: "reticulophagy receptor 1"},
    {id: "retinal cone rhodopsin-sensitive cgmp 3',5'-cyclic phosphodiesterase subunit gamma"},
    {id: "gmp-pde gamma"},
    {id: "retinal dehydrogenase 2"},
    {id: "raldh 2"},
    {id: "raldh2"},
    {id: "ec 1.2.1.36"},
    {id: "aldehyde dehydrogenase family 1 member a2"},
    {id: "aldh1a2"},
    {id: "retinaldehyde-specific dehydrogenase type 2"},
    {id: "raldh(ii)"},
    {id: "retinal guanylyl cyclase 1"},
    {id: "retgc-1"},
    {id: "guanylate cyclase 2e"},
    {id: "guanylyl cyclase gc-e"},
    {id: "retinal guanylyl cyclase 2"},
    {id: "retgc-2"},
    {id: "guanylate cyclase 2f, retinal"},
    {id: "guanylate cyclase f"},
    {id: "gc-f"},
    {id: "rod outer segment membrane guanylate cyclase 2"},
    {id: "ros-gc2"},
    {id: "retinal homeobox protein rx"},
    {id: "retina and anterior neural fold homeobox protein"},
    {id: "retinoic acid receptor rxr"},
    {id: "nuclear receptor subfamily 2 group b member"},
    {id: "retinoic acid receptor responder"},
    {id: "tazarotene induced"},
    {id: "retinoic acid receptor responder 1"},
    {id: "retinoic acid receptor responder protein 2"},
    {id: "chemerin"},
    {id: "retinoid isomerohydrolase"},
    {id: "ec 3.1.1.64"},
    {id: "all-trans-retinyl-palmitate hydrolase"},
    {id: "lutein isomerase"},
    {id: "meso-zeaxanthin isomerase"},
    {id: "ec 5.3.3.22"},
    {id: "retinal pigment epithelium-specific 65 kda protein"},
    {id: "retinol isomerase"},
    {id: "retinol dehydrogenase 10"},
    {id: "retinol dehydrogenase 11 (all-trans/9-cis/11-cis)"},
    {id: "retinol dehydrogenase 16"},
    {id: "ec 1.1.1.315"},
    {id: "29 kda protein"},
    {id: "retinol dehydrogenase 2"},
    {id: "retinol dehydrogenase type 1"},
    {id: "retinol dehydrogenase type ii"},
    {id: "rodh ii"},
    {id: "retinol dehydrogenase 7"},
    {id: "retinol dehydrogenase 3"},
    {id: "retinol dehydrogenase type iii"},
    {id: "rodh iii"},
    {id: "retinol-binding protein 2"},
    {id: "cellular retinol-binding protein ii"},
    {id: "crbp-ii"},
    {id: "retrotransposon gag like 8a"},
    {id: "similar to mammalian retrotransposon derived 8b"},
    {id: "rho gdp-dissociation inhibitor 1"},
    {id: "rho gdi 1"},
    {id: "rho-gdi alpha"},
    {id: "rho gtpase-activating protein 15"},
    {id: "arhgap15"},
    {id: "rho-type gtpase-activating protein 15"},
    {id: "rho gtpase-activating protein 17"},
    {id: "neuron-associated developmentally-regulated protein"},
    {id: "nadrin"},
    {id: "rho-type gtpase-activating protein 17"},
    {id: "rho gtpase-activating protein 20"},
    {id: "ra and rhogap domain-containing protein"},
    {id: "rarhogap"},
    {id: "rho-type gtpase-activating protein 20"},
    {id: "rho gtpase-activating protein 24"},
    {id: "down-regulated in nephrectomized rat kidney #2"},
    {id: "rho-type gtpase-activating protein 24"},
    {id: "rho gtpase-activating protein 26"},
    {id: "rho-type gtpase-activating protein 26"},
    {id: "rho gtpase-activating protein 29"},
    {id: "rho-type gtpase-activating protein 29"},
    {id: "rho gtpase-activating protein 35"},
    {id: "gap-associated protein p190"},
    {id: "glucocorticoid receptor dna-binding factor 1"},
    {id: "rho gtpase-activating protein 44"},
    {id: "rho-type gtpase-activating protein rich2"},
    {id: "rhogap interacting with cip4 homologs protein 2"},
    {id: "rich-2"},
    {id: "rho gtpase-activating protein syde1"},
    {id: "synapse defective protein 1 homolog 1"},
    {id: "protein syd-1 homolog 1"},
    {id: "rho family gtpase 2"},
    {id: "rhon"},
    {id: "rho guanine nucleotide exchange factor 2"},
    {id: "guanine nucleotide exchange factor h1"},
    {id: "gef-h1"},
    {id: "rho guanine nucleotide exchange factor 9"},
    {id: "collybistin"},
    {id: "rac/cdc42 guanine nucleotide exchange factor 9"},
    {id: "rho-associated protein kinase"},
    {id: "rho-related gtp-binding protein rhoe"},
    {id: "rho family gtpase 3"},
    {id: "rnd3"},
    {id: "rhomboid-related protein 2"},
    {id: "ec 3.4.21.105"},
    {id: "rhomboid-related protein 3"},
    {id: "rhomboid-related protein 4"},
    {id: "rrp4"},
    {id: "rhomboid domain-containing protein 1"},
    {id: "rrhbdd1"},
    {id: "rhomboid-like protein 4"},
    {id: "ribokinase"},
    {id: "rk"},
    {id: "ec 2.7.1.15"},
    {id: "ribonuclease 11"},
    {id: "ribonuclease a family member 11"},
    {id: "ribonuclease a j1"},
    {id: "ribonuclease, rnase a family, 11 (non-active)"},
    {id: "ribonuclease 16"},
    {id: "ribonuclease 16-like"},
    {id: "ribonuclease a f2"},
    {id: "ribonuclease 4"},
    {id: "rnase 4"},
    {id: "rl3"},
    {id: "ribonuclease 9"},
    {id: "ribonuclease a family member 9"},
    {id: "ribonuclease a c1"},
    {id: "ribonuclease, rnase a family, 1-like 1 (pancreatic)"},
    {id: "ribonuclease h1"},
    {id: "rnase h1"},
    {id: "ec 3.1.26.4"},
    {id: "ribonuclease h2 subunit a"},
    {id: "rnase h2 subunit a"},
    {id: "ribonuclease hi large subunit"},
    {id: "rnase hi large subunit"},
    {id: "ribonuclease hi subunit a"},
    {id: "ribonuclease h2 subunit b"},
    {id: "ribonuclease hi subunit b"},
    {id: "ribonuclease p protein subunit p20"},
    {id: "rnasep protein p20"},
    {id: "ribonuclease p protein subunit p25"},
    {id: "rnase p protein subunit p25"},
    {id: "ribonuclease p protein subunit p40"},
    {id: "rnasep protein p40"},
    {id: "ribonuclease inhibitor"},
    {id: "ribonuclease/angiogenin inhibitor 1"},
    {id: "ribonuclease pancreatic beta-type"},
    {id: "ec 4.6.1.18"},
    {id: "rl1"},
    {id: "rnase 1 gamma"},
    {id: "rnase a"},
    {id: "ribonuclease, rnase a family, 10"},
    {id: "non-active"},
    {id: "ribonucleoprotein ptb-binding 1"},
    {id: "protein raver-1"},
    {id: "ribonucleoside-diphosphate reductase"},
    {id: "ec 1.17.4.1"},
    {id: "ribosomal rna processing 1"},
    {id: "ribosomal rna processing 1 homolog (s. cerevisiae)"},
    {id: "ribosomal rna-processing protein 8"},
    {id: "ribosomal oxygenase 1"},
    {id: "bifunctional lysine-specific demethylase and histidyl-hydroxylase no66"},
    {id: "ec 1.14.11.27, ec 1.14.11.79"},
    {id: "histone lysine demethylase no66"},
    {id: "ribosomal oxygenase 2"},
    {id: "bifunctional lysine-specific demethylase and histidyl-hydroxylase mina"},
    {id: "ec 1.14.11.79"},
    {id: "histone lysine demethylase mina"},
    {id: "myc-induced nuclear antigen"},
    {id: "ribosomal protein l22"},
    {id: "ribosomal protein l22-like"},
    {id: "ribosomal protein s6 kinase"},
    {id: "ribosomal protein s6 kinase beta-1"},
    {id: "s6k-beta-1"},
    {id: "s6k1"},
    {id: "70 kda ribosomal protein s6 kinase 1"},
    {id: "p70s6k1"},
    {id: "p70-s6k 1"},
    {id: "ribosomal protein s6 kinase i"},
    {id: "p70 ribosomal s6 kinase alpha"},
    {id: "p70 s6 kinase alpha"},
    {id: "p70 s6k-alpha"},
    {id: "p70 s6ka"},
    {id: "ribosome biogenesis protein bop1"},
    {id: "block of proliferation 1 protein"},
    {id: "ribosome biogenesis protein brx1 homolog"},
    {id: "brix domain-containing protein 2"},
    {id: "ribosome biogenesis protein wdr12"},
    {id: "wd repeat-containing protein 12"},
    {id: "ribosome maturation protein sbds"},
    {id: "shwachman-bodian-diamond syndrome protein homolog"},
    {id: "ribosome production factor 1"},
    {id: "brix domain-containing protein 5"},
    {id: "ribosome biogenesis protein rpf1"},
    {id: "ribosome production factor 2 homolog"},
    {id: "ribosome biogenesis protein rpf2 homolog"},
    {id: "ribosome-recycling factor, mitochondrial"},
    {id: "rrf"},
    {id: "ribosome-releasing factor, mitochondrial"},
    {id: "ribosome-releasing factor 2, mitochondrial"},
    {id: "rrf2mt"},
    {id: "elongation factor g 2, mitochondrial"},
    {id: "ef-g2mt"},
    {id: "mef-g 2"},
    {id: "ribulose-phosphate 3-epimerase"},
    {id: "ec 5.1.3.1"},
    {id: "rieske"},
    {id: "fe-s"},
    {id: "ring finger protein 128"},
    {id: "rnf128 protein"},
    {id: "rod outer segment membrane protein 1"},
    {id: "rosp1"},
    {id: "ropporin-1"},
    {id: "rhophilin-associated protein 1"},
    {id: "rtf1, paf1/rna polymerase ii complex component, homolog"},
    {id: "runt-related transcription factor 1"},
    {id: "acute myeloid leukemia 1 protein"},
    {id: "core-binding factor subunit alpha-2"},
    {id: "cbf-alpha-2"},
    {id: "oncogene aml-1"},
    {id: "polyomavirus enhancer-binding protein 2 alpha b subunit"},
    {id: "pea2-alpha b"},
    {id: "pebp2-alpha b"},
    {id: "ruvb-like 1"},
    {id: "49 kda tata box-binding protein-interacting protein"},
    {id: "49 kda tbp-interacting protein"},
    {id: "dna helicase p50"},
    {id: "pontin 52"},
    {id: "tip49a"},
    {id: "ruvb-like helicase"},
    {id: "s-acyl fatty acid synthase thioesterase, medium chain"},
    {id: "medium-chain s-acyl fatty acid synthetase thioester hydrolase"},
    {id: "oleoyl-acp hydrolase"},
    {id: "thioesterase ii"},
    {id: "thioesterase domain-containing protein 1"},
    {id: "s-adenosylmethionine sensor upstream of mtorc1"},
    {id: "probable methyltransferase bmt2 homolog"},
    {id: "s-adenosylmethionine synthase"},
    {id: "ec 2.5.1.6"},
    {id: "s-adenosylmethionine-dependent nucleotide dehydratase rsad2"},
    {id: "radical s-adenosyl methionine domain-containing protein 2"},
    {id: "virus inhibitory protein, endoplasmic reticulum-associated, interferon-inducible"},
    {id: "s-formylglutathione hydrolase"},
    {id: "ec 3.1.2.12"},
    {id: "esterase d"},
    {id: "fgh"},
    {id: "s-methyl-5'-thioadenosine phosphorylase"},
    {id: "ec 2.4.2.28"},
    {id: "5'-methylthioadenosine phosphorylase"},
    {id: "mta phosphorylase"},
    {id: "mtap"},
    {id: "mtapase"},
    {id: "sant and btb domain regulator of class switch recombination"},
    {id: "sant and btb domain regulator of csr"},
    {id: "sap domain-containing ribonucleoprotein"},
    {id: "nuclear protein hcc-1"},
    {id: "sec14 and spectrin domain containing 1"},
    {id: "sestd1 protein"},
    {id: "serpine1 mrna-binding protein 1"},
    {id: "pai1 rna-binding protein 1"},
    {id: "pai-rbp1"},
    {id: "plasminogen activator inhibitor 1 rna-binding protein"},
    {id: "rda288"},
    {id: "set domain containing 4"},
    {id: "setd4 protein"},
    {id: "sh2 domain containing 3c"},
    {id: "sh2d3c protein"},
    {id: "sh2b adapter protein 2"},
    {id: "adapter protein with pleckstrin homology and src homology 2 domains"},
    {id: "sh2 and ph domain-containing adapter protein aps"},
    {id: "sh3 and multiple ankyrin repeat domains protein 1"},
    {id: "shank1"},
    {id: "gkap/sapap-interacting protein"},
    {id: "spank-1"},
    {id: "somatostatin receptor-interacting protein"},
    {id: "sstr-interacting protein"},
    {id: "sstrip"},
    {id: "synamon"},
    {id: "sh3 and multiple ankyrin repeat domains protein 2"},
    {id: "shank2"},
    {id: "cortactin-binding protein 1"},
    {id: "cortbp1"},
    {id: "proline-rich synapse-associated protein 1"},
    {id: "prosap1"},
    {id: "spank-3"},
    {id: "sh3 domain containing grb2 like 3, endophilin a3"},
    {id: "sh3-domain grb2-like 3"},
    {id: "sh3 domain-binding protein 5"},
    {id: "sh3bp-5"},
    {id: "sh3 domain-containing kinase-binding protein 1"},
    {id: "regulator of ubiquitous kinase"},
    {id: "ruk"},
    {id: "sh3-containing, expressed in tumorigenic astrocytes"},
    {id: "sh3-domain kinase binding protein 1"},
    {id: "sh3-containing grb2-like protein 3-interacting protein 1"},
    {id: "endophilin-3-interacting protein"},
    {id: "sh3kbp1-binding protein 1"},
    {id: "seta-binding protein 1"},
    {id: "shc-transforming protein 2"},
    {id: "protein sck"},
    {id: "sh2 domain protein c2"},
    {id: "src homology 2 domain-containing-transforming protein c2"},
    {id: "ski family transcriptional corepressor 1"},
    {id: "ladybird homeobox corepressor 1"},
    {id: "slain motif family, member 1"},
    {id: "similar to 9630044o09rik protein"},
    {id: "slit-robo rho gtpase activating protein 2"},
    {id: "srgap2 protein"},
    {id: "slit-robo rho gtpase-activating protein 2"},
    {id: "srgap2"},
    {id: "snf-related serine/threonine-protein kinase"},
    {id: "snf1-related kinase"},
    {id: "soss complex subunit b1"},
    {id: "nucleic acid-binding protein 2"},
    {id: "oligonucleotide/oligosaccharide-binding fold-containing protein 2b"},
    {id: "sensor of single-strand dna complex subunit b1"},
    {id: "sensor of ssdna subunit b1"},
    {id: "soss-b1"},
    {id: "single-stranded dna-binding protein 1"},
    {id: "soss complex subunit b2"},
    {id: "nucleic acid-binding protein 1"},
    {id: "oligonucleotide/oligosaccharide-binding fold-containing protein 2a"},
    {id: "sensor of single-strand dna complex subunit b2"},
    {id: "sensor of ssdna subunit b2"},
    {id: "soss-b2"},
    {id: "single-stranded dna-binding protein 2"},
    {id: "soss complex subunit c"},
    {id: "soss-c"},
    {id: "ssb-interacting protein 1"},
    {id: "sensor of single-strand dna complex subunit c"},
    {id: "sensor of ssdna subunit c"},
    {id: "single-stranded dna-binding protein-interacting protein 1"},
    {id: "spaca6 protein"},
    {id: "sperm acrosome associated 6"},
    {id: "spry domain-containing socs box protein 2"},
    {id: "ssb-2"},
    {id: "spry domain-containing protein 7"},
    {id: "chronic lymphocytic leukemia deletion region gene 6 protein homolog"},
    {id: "cll deletion region gene 6 protein homolog"},
    {id: "sry"},
    {id: "sex determining region y"},
    {id: "st6"},
    {id: "alpha-n-acetyl-neuraminyl-2,3-beta-galactosyl-1, 3"},
    {id: "st6 n-acetylgalactosaminide alpha-2,6-sialyltransferase 2"},
    {id: "stam-binding protein"},
    {id: "associated molecule with the sh3 domain of stam"},
    {id: "ste20/sps1-related proline-alanine-rich protein kinase"},
    {id: "ste-20-related kinase"},
    {id: "pancreatic serine/threonine-protein kinase"},
    {id: "ps/tk"},
    {id: "pstk1"},
    {id: "serine/threonine-protein kinase 39"},
    {id: "sumo-activating enzyme subunit 1"},
    {id: "ubiquitin-like 1-activating enzyme e1a"},
    {id: "cleaved into: sumo-activating enzyme subunit 1, n-terminally processed"},
    {id: "sumo-activating enzyme subunit 2"},
    {id: "surp and g-patch domain-containing protein 1"},
    {id: "splicing factor 4"},
    {id: "swi/snf-related matrix-associated actin-dependent regulator of chromatin subfamily a containing dead/h box 1"},
    {id: "swi/snf-related matrix-associated actin-dependent regulator of chromatin subfamily a-like protein 1"},
    {id: "hepa-related protein"},
    {id: "sucrose nonfermenting protein 2-like 1"},
    {id: "swi/snf-related matrix-associated actin-dependent regulator of chromatin subfamily e member 1"},
    {id: "brg1-associated factor 57"},
    {id: "baf57"},
    {id: "saccharopine dehydrogenase"},
    {id: "saccharopine dehydrogenase-like oxidoreductase"},
    {id: "sad1 and unc84 domain containing 1"},
    {id: "unc-84 homolog a (c. elegans)"},
    {id: "sarcoglycan, gamma"},
    {id: "sarcoglycan, gamma (dystrophin-associated glycoprotein)"},
    {id: "sarcolemma associated protein"},
    {id: "slmap protein"},
    {id: "sarcoplasmic/endoplasmic reticulum calcium atpase 1"},
    {id: "serca1"},
    {id: "sr ca(2+)-atpase 1"},
    {id: "calcium pump 1"},
    {id: "calcium-transporting atpase sarcoplasmic reticulum type, fast twitch skeletal muscle isoform"},
    {id: "endoplasmic reticulum class 1/2 ca(2+) atpase"},
    {id: "sarcoplasmic/endoplasmic reticulum calcium atpase 2"},
    {id: "serca2"},
    {id: "sr ca(2+)-atpase 2"},
    {id: "calcium pump 2"},
    {id: "calcium-transporting atpase sarcoplasmic reticulum type, slow twitch skeletal muscle isoform"},
    {id: "schip1 protein"},
    {id: "schwannomin interacting protein 1"},
    {id: "schlafen family member 13"},
    {id: "ribonuclease s13"},
    {id: "rnase s13"},
    {id: "schlafen-13"},
    {id: "schlafen13"},
    {id: "rslfn13"},
    {id: "sclerostin domain-containing protein 1"},
    {id: "uterine sensitization-associated gene 1 protein"},
    {id: "usag-1"},
    {id: "wnt-signaling modulator"},
    {id: "scrapie-responsive protein 1"},
    {id: "scrapie-responsive gene 1 protein"},
    {id: "scrg-1"},
    {id: "sec1 family domain containing 2"},
    {id: "similar to sec1 family domain containing protein 2 (syntaxin binding protein 1-like 1) (neuronal sec1)"},
    {id: "sec1 family domain-containing protein 1"},
    {id: "sly1 homolog"},
    {id: "sly1p"},
    {id: "syntaxin-binding protein 1-like 2"},
    {id: "vesicle transport-related protein ra410"},
    {id: "secreted frizzled-related protein 3"},
    {id: "frizzled-related protein 1"},
    {id: "frzb-1"},
    {id: "secreted phosphoprotein 24"},
    {id: "secreted phosphoprotein 2"},
    {id: "secretin receptor"},
    {id: "sct-r"},
    {id: "secretoglobin family 2a member 2"},
    {id: "c3.1"},
    {id: "prostatein peptide c3"},
    {id: "prostatic steroid-binding protein c3"},
    {id: "secretogranin-3"},
    {id: "secretogranin iii"},
    {id: "secretory calcium-binding phosphoprotein proline-glutamine rich 1"},
    {id: "secretory calcium-binding phosphoprotein proline-glutamine rich 1-like"},
    {id: "secretory carrier-associated membrane protein"},
    {id: "secretory carrier membrane protein"},
    {id: "secretory carrier-associated membrane protein 1"},
    {id: "secretory carrier membrane protein 1"},
    {id: "scamp 37"},
    {id: "securin"},
    {id: "pituitary tumor-transforming gene 1 protein"},
    {id: "segment polarity protein dishevelled homolog dvl-1"},
    {id: "dishevelled-1"},
    {id: "dsh homolog 1"},
    {id: "seipin"},
    {id: "bernardinelli-seip congenital lipodystrophy type 2 protein homolog"},
    {id: "selection and upkeep of intraepithelial t cells 1"},
    {id: "skint 1 isoform a"},
    {id: "selenocysteine lyase"},
    {id: "ec 4.4.1.16"},
    {id: "selenoprotein f"},
    {id: "15 kda selenoprotein"},
    {id: "selenoprotein k"},
    {id: "selk"},
    {id: "selenoprotein p"},
    {id: "sep"},
    {id: "cleaved into: selenoprotein se-p10; selenoprotein se-p6; selenoprotein se-p2; selenoprotein se-p1"},
    {id: "semaphorin 7a"},
    {id: "john milton hagen blood group"},
    {id: "semaphorin-3a"},
    {id: "semaphorin iii"},
    {id: "sema iii"},
    {id: "semaphorin-4f"},
    {id: "semaphorin-w"},
    {id: "sema w"},
    {id: "semaphorin-5a"},
    {id: "semaphorin-f"},
    {id: "sema f"},
    {id: "semaphorin-6c"},
    {id: "semaphorin-y"},
    {id: "sema y"},
    {id: "sentrin-specific protease 8"},
    {id: "deneddylase-1"},
    {id: "nedd8-specific protease 1"},
    {id: "sentrin/sumo-specific protease senp8"},
    {id: "sepiapterin reductase"},
    {id: "ec 1.1.1.153"},
    {id: "spr"},
    {id: "sept4 protein"},
    {id: "septin 4"},
    {id: "septin-4"},
    {id: "apoptosis-related protein in the tgf-beta signaling pathway"},
    {id: "arts"},
    {id: "bradeion beta"},
    {id: "brain protein h5"},
    {id: "ce5b3 beta"},
    {id: "cell division control-related protein 2"},
    {id: "hcdcrel-2"},
    {id: "peanut-like protein 2"},
    {id: "sequestosome-1"},
    {id: "protein kinase c-zeta-interacting protein"},
    {id: "pkc-zeta-interacting protein"},
    {id: "ubiquitin-binding protein p62"},
    {id: "serglycin"},
    {id: "chondroitin sulfate proteoglycan core protein"},
    {id: "cytolytic granule proteoglycan core protein"},
    {id: "pg19 core protein"},
    {id: "proteoglycan 10k core protein"},
    {id: "secretory granule proteoglycan core protein"},
    {id: "serine"},
    {id: "or cysteine"},
    {id: "alpha-1 antiproteinase, antitrypsin"},
    {id: "serine hydroxymethyltransferase"},
    {id: "ec 2.1.2.1"},
    {id: "serine incorporator 1"},
    {id: "tumor differentially expressed protein 1-like"},
    {id: "tumor differentially expressed protein 2"},
    {id: "serine palmitoyltransferase 1"},
    {id: "ec 2.3.1.50"},
    {id: "long chain base biosynthesis protein 1"},
    {id: "lcb 1"},
    {id: "serine-palmitoyl-coa transferase 1"},
    {id: "spt 1"},
    {id: "spt1"},
    {id: "serine palmitoyltransferase small subunit a"},
    {id: "small subunit of serine palmitoyltransferase a"},
    {id: "ssspta"},
    {id: "serine protease 23"},
    {id: "serine protease 27"},
    {id: "marapsin"},
    {id: "pancreasin"},
    {id: "serine protease 40"},
    {id: "testicular serine protease 2"},
    {id: "serine protease 46"},
    {id: "testis-specific serine protease-6"},
    {id: "serine protease htr4"},
    {id: "high-temperature requirement factor a4"},
    {id: "serine protease htra3"},
    {id: "high-temperature requirement factor a3"},
    {id: "pregnancy-related serine protease"},
    {id: "serine protease inhibitor a3n"},
    {id: "serpin a3n"},
    {id: "cpi-26"},
    {id: "contrapsin-like protease inhibitor 6"},
    {id: "spi-2.2"},
    {id: "serine protease inhibitor 3"},
    {id: "spi-3"},
    {id: "serine racemase"},
    {id: "ec 5.1.1.18"},
    {id: "d-serine ammonia-lyase"},
    {id: "d-serine dehydratase"},
    {id: "ec 4.3.1.18"},
    {id: "l-serine dehydratase"},
    {id: "serine--trna ligase, cytoplasmic"},
    {id: "ec 6.1.1.11"},
    {id: "seryl-trna synthetase"},
    {id: "serrs"},
    {id: "seryl-trna(ser/sec) synthetase"},
    {id: "serine-protein kinase atm"},
    {id: "serine-threonine kinase receptor-associated protein"},
    {id: "unr-interacting protein"},
    {id: "serine/arginine-related protein 53"},
    {id: "srrp53"},
    {id: "arginine/serine-rich coiled-coil protein 1"},
    {id: "serine/arginine-rich splicing factor 1"},
    {id: "splicing factor, arginine/serine-rich 1"},
    {id: "serine/arginine-rich splicing factor 2"},
    {id: "splicing component, 35 kda"},
    {id: "splicing factor sc35"},
    {id: "sc-35"},
    {id: "splicing factor, arginine/serine-rich 2"},
    {id: "serine/arginine-rich splicing factor 5"},
    {id: "delayed-early protein hrs"},
    {id: "insulin-induced growth response protein cl-4"},
    {id: "pre-mrna-splicing factor srp40"},
    {id: "splicing factor, arginine/serine-rich 5"},
    {id: "serine/arginine-rich splicing factor 6"},
    {id: "pre-mrna-splicing factor srp55"},
    {id: "splicing factor, arginine/serine-rich 6"},
    {id: "serine/arginine-rich splicing factor 9"},
    {id: "splicing factor, arginine/serine-rich 9"},
    {id: "serine/threonine kinase 25"},
    {id: "serine/threonine kinase 25 (ste20 homolog, yeast)"},
    {id: "serine/threonine-protein kinase"},
    {id: "serine/threonine-protein kinase 16"},
    {id: "myristoylated and palmitoylated serine/threonine-protein kinase"},
    {id: "mpsk"},
    {id: "protein kinase pkl12"},
    {id: "tgf-beta-stimulated factor 1"},
    {id: "tsf-1"},
    {id: "tyrosine-protein kinase stk16"},
    {id: "serine/threonine-protein kinase 17b"},
    {id: "dap kinase-related apoptosis-inducing protein kinase 2"},
    {id: "serine/threonine-protein kinase 24"},
    {id: "mammalian ste20-like protein kinase 3"},
    {id: "mst-3"},
    {id: "mst3b"},
    {id: "ste20-like kinase mst3"},
    {id: "cleaved into: serine/threonine-protein kinase 24 35 kda subunit (mammalian ste20-like protein kinase 3 n-terminal) (mst3/n); serine/threonine-protein kinase 24 12 kda subunit (mammalian ste20-like protein kinase 3 c-terminal) (mst3/c)"},
    {id: "serine/threonine-protein kinase 40"},
    {id: "serine/threonine-protein kinase a-raf"},
    {id: "proto-oncogene a-raf"},
    {id: "proto-oncogene a-raf-1"},
    {id: "serine/threonine-protein kinase atr"},
    {id: "serine/threonine-protein kinase brsk1"},
    {id: "brain-specific serine/threonine-protein kinase 1"},
    {id: "br serine/threonine-protein kinase 1"},
    {id: "serine/threonine-protein kinase sad-b"},
    {id: "serine/threonine-protein kinase brsk2"},
    {id: "brain-specific serine/threonine-protein kinase 2"},
    {id: "br serine/threonine-protein kinase 2"},
    {id: "serine/threonine-protein kinase sad-a"},
    {id: "serine/threonine-protein kinase d1"},
    {id: "protein kinase c mu type"},
    {id: "protein kinase d"},
    {id: "npkc-d1"},
    {id: "npkc-mu"},
    {id: "serine/threonine-protein kinase d2"},
    {id: "npkc-d2"},
    {id: "serine/threonine-protein kinase kist"},
    {id: "kinase interacting with stathmin"},
    {id: "pam cooh-terminal interactor protein 2"},
    {id: "p-cip2"},
    {id: "u2af homology motif kinase 1"},
    {id: "serine/threonine-protein kinase mrck beta"},
    {id: "cdc42-binding protein kinase beta"},
    {id: "dmpk-like beta"},
    {id: "myotonic dystrophy kinase-related cdc42-binding kinase beta"},
    {id: "mrck beta"},
    {id: "myotonic dystrophy protein kinase-like beta"},
    {id: "serine/threonine-protein kinase n1"},
    {id: "protease-activated kinase 1"},
    {id: "pak-1"},
    {id: "protein kinase c-like 1"},
    {id: "protein kinase c-like pkn"},
    {id: "protein-kinase c-related kinase 1"},
    {id: "serine-threonine protein kinase n"},
    {id: "serine/threonine-protein kinase nlk"},
    {id: "nemo-like kinase"},
    {id: "serine/threonine-protein kinase nek6"},
    {id: "ec 2.7.11.34"},
    {id: "never in mitosis a-related kinase 6"},
    {id: "nima-related protein kinase 6"},
    {id: "serine/threonine-protein kinase nek7"},
    {id: "never in mitosis a-related kinase 7"},
    {id: "nima-related protein kinase 7"},
    {id: "serine/threonine-protein kinase nek8"},
    {id: "never in mitosis a-related kinase 8"},
    {id: "nima-related protein kinase 8"},
    {id: "serine/threonine-protein kinase pak 1"},
    {id: "alpha-pak"},
    {id: "protein kinase muk2"},
    {id: "p21-activated kinase 1"},
    {id: "p68-pak"},
    {id: "serine/threonine-protein kinase pak 2"},
    {id: "gamma-pak"},
    {id: "p21-activated kinase 2"},
    {id: "pak-2"},
    {id: "cleaved into: pak-2p27; pak-2p34"},
    {id: "serine/threonine-protein kinase pak 3"},
    {id: "beta-pak"},
    {id: "p21-activated kinase 3"},
    {id: "pak-3"},
    {id: "p65-pak"},
    {id: "serine/threonine-protein kinase pak 5"},
    {id: "p21-activated kinase 5"},
    {id: "pak-5"},
    {id: "p21-activated kinase 7"},
    {id: "pak-7"},
    {id: "serine/threonine-protein kinase pink1, mitochondrial"},
    {id: "pten-induced putative kinase 1"},
    {id: "serine/threonine-protein kinase plk"},
    {id: "ec 2.7.11.21"},
    {id: "polo-like kinase"},
    {id: "serine/threonine-protein kinase plk2"},
    {id: "polo-like kinase 2"},
    {id: "plk-2"},
    {id: "serine/threonine-protein kinase snk"},
    {id: "serum-inducible kinase"},
    {id: "serine/threonine-protein kinase plk3"},
    {id: "cytokine-inducible serine/threonine-protein kinase"},
    {id: "fgf-inducible kinase"},
    {id: "polo-like kinase 3"},
    {id: "plk-3"},
    {id: "serine/threonine-protein kinase plk4"},
    {id: "polo-like kinase 4"},
    {id: "serine/threonine-protein kinase prp4 homolog"},
    {id: "prp4 pre-mrna-processing factor 4 homolog"},
    {id: "serine/threonine-protein kinase rio1"},
    {id: "serine/threonine-protein kinase rio3"},
    {id: "serine/threonine-protein kinase sbk2"},
    {id: "sh3-binding domain kinase family member 2"},
    {id: "serine/threonine-protein kinase sik1"},
    {id: "protein kinase kid2"},
    {id: "salt-inducible kinase 1"},
    {id: "sik-1"},
    {id: "serine/threonine-protein kinase snf1-like kinase 1"},
    {id: "serine/threonine-protein kinase snf1lk"},
    {id: "serine/threonine-protein kinase stk11"},
    {id: "liver kinase b1 homolog"},
    {id: "lkb1"},
    {id: "serine/threonine-protein kinase sgk2"},
    {id: "serum/glucocorticoid-regulated kinase 2"},
    {id: "serine/threonine-protein kinase ulk3"},
    {id: "unc-51-like kinase 3"},
    {id: "serine/threonine-protein kinase wnk1"},
    {id: "protein kinase lysine-deficient 1"},
    {id: "protein kinase with no lysine 1"},
    {id: "serine/threonine-protein kinase wnk4"},
    {id: "protein kinase lysine-deficient 4"},
    {id: "protein kinase with no lysine 4"},
    {id: "serine/threonine-protein kinase mtor"},
    {id: "serine/threonine-protein kinase pim-1"},
    {id: "serine/threonine-protein kinase pim-3"},
    {id: "kinase induced by depolarization"},
    {id: "protein kinase kid-1"},
    {id: "serine/threonine-protein kinase receptor"},
    {id: "serine/threonine-protein phosphatase"},
    {id: "serine/threonine-protein phosphatase 1 regulatory subunit 10"},
    {id: "mhc class i region proline-rich protein cat53"},
    {id: "phosphatase 1 nuclear targeting subunit"},
    {id: "protein pnuts"},
    {id: "serine/threonine-protein phosphatase 2a 55 kda regulatory subunit b alpha isoform"},
    {id: "pp2a subunit b isoform b55-alpha"},
    {id: "pp2a subunit b isoform bra"},
    {id: "pp2a subunit b isoform pr55-alpha"},
    {id: "pp2a subunit b isoform r2-alpha"},
    {id: "pp2a subunit b isoform alpha"},
    {id: "serine/threonine-protein phosphatase 2a 55 kda regulatory subunit b beta isoform"},
    {id: "pp2a subunit b isoform b55-beta"},
    {id: "pp2a subunit b isoform brb"},
    {id: "pp2a subunit b isoform pr55-beta"},
    {id: "pp2a subunit b isoform r2-beta"},
    {id: "pp2a subunit b isoform beta"},
    {id: "serine/threonine-protein phosphatase 2a 56 kda regulatory subunit beta isoform"},
    {id: "pp2a b subunit isoform b'-beta"},
    {id: "pp2a b subunit isoform b56-beta"},
    {id: "pp2a b subunit isoform pr61-beta"},
    {id: "pp2a b subunit isoform r5-beta"},
    {id: "serine/threonine-protein phosphatase 2a activator"},
    {id: "phosphotyrosyl phosphatase activator"},
    {id: "serine/threonine-protein phosphatase 5"},
    {id: "pp5"},
    {id: "protein phosphatase t"},
    {id: "ppt"},
    {id: "serine/threonine-protein phosphatase 6 catalytic subunit"},
    {id: "pp6c"},
    {id: "protein phosphatase v"},
    {id: "pp-v"},
    {id: "serine/threonine-protein phosphatase cpped1"},
    {id: "calcineurin-like phosphoesterase domain-containing protein 1"},
    {id: "serine/threonine-protein phosphatase with ef-hands"},
    {id: "serine/threonine-protein phosphatase with ef-hands 1"},
    {id: "ppef-1"},
    {id: "serotonin n-acetyltransferase"},
    {id: "serotonin acetylase"},
    {id: "ec 2.3.1.87"},
    {id: "aralkylamine n-acetyltransferase"},
    {id: "aa-nat"},
    {id: "serpin a11"},
    {id: "liver regeneration-related protein lrrg023"},
    {id: "serpin h1"},
    {id: "collagen-binding protein"},
    {id: "serum response factor-binding protein 1"},
    {id: "srf-dependent transcription regulation-associated protein"},
    {id: "p49/strap"},
    {id: "sesquipedalian"},
    {id: "ses"},
    {id: "ph domain-containing endocytic trafficking adaptor"},
    {id: "sesquipedalian-1"},
    {id: "ses1"},
    {id: "27 kda inositol polyphosphate phosphatase interacting protein a"},
    {id: "ipip27a"},
    {id: "ph domain-containing endocytic trafficking adaptor 1"},
    {id: "sgms1 protein"},
    {id: "sharpin"},
    {id: "shank-associated rh domain-interacting protein"},
    {id: "shisa family member 4"},
    {id: "shisa homolog 4 (xenopus laevis)"},
    {id: "shootin-1"},
    {id: "shootin1"},
    {id: "short transient receptor potential channel 2"},
    {id: "trpc2"},
    {id: "transient receptor protein 2"},
    {id: "trp-2"},
    {id: "rtrp2"},
    {id: "short transient receptor potential channel 3"},
    {id: "trpc3"},
    {id: "trp-related protein 3"},
    {id: "short-wave-sensitive opsin 1"},
    {id: "blue cone photoreceptor pigment"},
    {id: "blue-sensitive opsin"},
    {id: "sialate:o-sulfotransferase 1"},
    {id: "wsc domain-containing protein 1"},
    {id: "sialidase-3"},
    {id: "ec 3.2.1.18"},
    {id: "ganglioside sialidase"},
    {id: "membrane sialidase"},
    {id: "n-acetyl-alpha-neuraminidase 3"},
    {id: "sialomucin core protein 24"},
    {id: "muc-24"},
    {id: "endolyn"},
    {id: "multi-glycosylated core protein 24"},
    {id: "mgc-24"},
    {id: "mgc-24v"},
    {id: "sideroflexin-5"},
    {id: "tricarboxylate carrier bbg-tcc"},
    {id: "sigma intracellular receptor 2"},
    {id: "sigma-2 receptor"},
    {id: "sigma2 receptor"},
    {id: "transmembrane protein 97"},
    {id: "sigma non-opioid intracellular receptor 1"},
    {id: "sigma 1-type opioid receptor"},
    {id: "sigma1-receptor"},
    {id: "sigma1r"},
    {id: "signal peptidase complex catalytic subunit sec11"},
    {id: "ec 3.4.21.89"},
    {id: "signal peptidase complex subunit 1"},
    {id: "microsomal signal peptidase 12 kda subunit"},
    {id: "signal peptidase complex subunit 3"},
    {id: "microsomal signal peptidase 22/23 kda subunit"},
    {id: "spc22/23"},
    {id: "spase 22/23 kda subunit"},
    {id: "signal recognition particle 14 kda protein"},
    {id: "srp14"},
    {id: "signal recognition particle 54 kda protein"},
    {id: "signal recognition particle 9 kda protein"},
    {id: "srp9"},
    {id: "signal recognition particle subunit srp54"},
    {id: "signal recognition particle subunit srp68"},
    {id: "srp68"},
    {id: "signal transducing adapter molecule 2"},
    {id: "stam-2"},
    {id: "signaling threshold-regulating transmembrane adapter 1"},
    {id: "shp2-interacting transmembrane adapter protein"},
    {id: "suppression-inducing transmembrane adapter 1"},
    {id: "similar to 60s ribosomal protein l27a"},
    {id: "l29"},
    {id: "similar to 60s ribosomal protein l4"},
    {id: "l1"},
    {id: "similar to actin, cytoplasmic 2"},
    {id: "similar to anionic trypsin ii precursor"},
    {id: "pretrypsinogen ii"},
    {id: "similar to cathepsin l precursor"},
    {id: "major excreted protein"},
    {id: "mep"},
    {id: "similar to centrosomal protein of 27 kda"},
    {id: "cep27 protein"},
    {id: "similar to chromodomain-helicase-dna-binding protein 1"},
    {id: "chd-1"},
    {id: "similar to cystatin s precursor"},
    {id: "lm protein"},
    {id: "similar to discs, large homolog 5"},
    {id: "placenta and prostate dlg"},
    {id: "similar to dnaj homolog subfamily b member 6"},
    {id: "mdj4"},
    {id: "similar to fas apoptotic inhibitory molecule 1"},
    {id: "similar to fusion"},
    {id: "involved in t(12;16) in malignant liposarcoma"},
    {id: "similar to gtpase hras precursor"},
    {id: "transforming protein p21"},
    {id: "p21ras"},
    {id: "h-ras-1"},
    {id: "c-h-ras"},
    {id: "similar to histone family member"},
    {id: "his-41"},
    {id: "similar to high mobility group protein 1"},
    {id: "hmg-1"},
    {id: "similar to high mobility group protein 2"},
    {id: "similar to leukosialin"},
    {id: "leucocyte sialoglycoprotein"},
    {id: "sialophorin"},
    {id: "cd43"},
    {id: "w3/13 antigen"},
    {id: "similar to leukosialin precursor (leucocyte sialoglycoprotein) (sialophorin) (cd43) (w3/13 antigen)"},
    {id: "similar to lung carbonyl reductase"},
    {id: "lcr"},
    {id: "adipocyte p27 protein"},
    {id: "ap27"},
    {id: "similar to map/microtubule affinity-regulating kinase 4"},
    {id: "map/microtubule affinity-regulating kinase-like 1"},
    {id: "similar to macrophage migration inhibitory factor"},
    {id: "delayed early response protein 6"},
    {id: "glycosylation-inhibiting factor"},
    {id: "der6"},
    {id: "similar to natural killer cell protease 1 precursor"},
    {id: "similar to nuclear autoantigen sp-100"},
    {id: "speckled 100 kda"},
    {id: "similar to nucleoside diphosphate-linked moiety x motif 16"},
    {id: "nudix motif 16"},
    {id: "similar to ox-2 membrane glycoprotein precursor"},
    {id: "mrc ox-2 antigen"},
    {id: "cd200 antigen"},
    {id: "similar to plasma kallikrein precursor"},
    {id: "plasma prekallikrein"},
    {id: "kininogenin"},
    {id: "fletcher factor"},
    {id: "similar to ral guanine nucleotide dissociation stimulator"},
    {id: "ralgef"},
    {id: "ralgds"},
    {id: "similar to solute carrier family 23, member 2"},
    {id: "sodium-dependent vitamin c transporter 2"},
    {id: "sodium-dependent nucleobase transporter 1"},
    {id: "similar to spindlin-like protein 2"},
    {id: "spin-2"},
    {id: "similar to taf11 rna polymerase ii, tata box binding protein"},
    {id: "tbp"},
    {id: "similar to urinary protein 2 precursor"},
    {id: "rup-2"},
    {id: "similar to vigilin"},
    {id: "high density lipoprotein-binding protein"},
    {id: "similar to vinculin"},
    {id: "metavinculin"},
    {id: "similar to vomeronasal secretory protein 2 precursor"},
    {id: "vomeronasal secretory protein ii"},
    {id: "vnsp ii"},
    {id: "lipocalin-4"},
    {id: "similar to zinc finger protein 180"},
    {id: "hhz168"},
    {id: "similar to zinc finger protein ozf"},
    {id: "pozf-1"},
    {id: "zinc finger protein 772"},
    {id: "similar to expressed sequence ai449175"},
    {id: "zinc finger protein 868"},
    {id: "similar to glyceraldehyde-3-phosphate dehydrogenase"},
    {id: "phosphorylating"},
    {id: "similar to interferon induced transmembrane protein 2"},
    {id: "1-8d"},
    {id: "similar to putative pheromone receptor"},
    {id: "go-vn4"},
    {id: "similar to serine"},
    {id: "similar to solute carrier family 22"},
    {id: "organic cation transporter"},
    {id: "similar to solute carrier family 7"},
    {id: "cationic amino acid transporter, y+ system"},
    {id: "similar to speedy b isoform"},
    {id: "speedy/ringo cell cycle regulator family member e4"},
    {id: "similar to spermatogenesis associated glutamate"},
    {id: "similar to splicing factor, arginine/serine-rich 10"},
    {id: "transformer 2 homolog, drosophila"},
    {id: "similar to testis expressed sequence 13b"},
    {id: "tex13 family member c"},
    {id: "similar to transcription elongation factor b"},
    {id: "siii"},
    {id: "similar to zinc finger protein 84"},
    {id: "hpf2"},
    {id: "sine oculis-binding protein homolog"},
    {id: "jackson circler protein 1"},
    {id: "single-strand selective monofunctional uracil-dna glycosylase"},
    {id: "ski oncogene"},
    {id: "proto-oncogene c-ski"},
    {id: "slc16a11 protein"},
    {id: "solute carrier family 16, member 11"},
    {id: "slc16a6 protein"},
    {id: "solute carrier family 16, member 6"},
    {id: "slc17a3 protein"},
    {id: "solute carrier family 17 member 3"},
    {id: "slc22a5 protein"},
    {id: "slc35e3 protein"},
    {id: "solute carrier family 35, member e3"},
    {id: "slc39a1 protein"},
    {id: "solute carrier family 39 member 1"},
    {id: "slc5a10 protein"},
    {id: "solute carrier family 5 member 10"},
    {id: "slit homolog 1 protein"},
    {id: "slit-1"},
    {id: "multiple epidermal growth factor-like domains protein 4"},
    {id: "multiple egf-like domains protein 4"},
    {id: "small rna 2'-o-methyltransferase"},
    {id: "ec 2.1.1.386"},
    {id: "hen1 methyltransferase homolog 1"},
    {id: "small glutamine-rich tetratricopeptide repeat-containing protein beta"},
    {id: "beta-sgt"},
    {id: "small glutamine-rich protein with tetratricopeptide repeats 2"},
    {id: "small integral membrane protein 3"},
    {id: "ngf-induced differentiation clone 67 protein"},
    {id: "small membrane protein nid67"},
    {id: "small nuclear ribonucleoprotein e"},
    {id: "snrnp-e"},
    {id: "sm protein e"},
    {id: "small nuclear ribonucleoprotein g"},
    {id: "snrnp-g"},
    {id: "small nuclear ribonucleoprotein sm d1"},
    {id: "snrnp core protein d1"},
    {id: "small nuclear ribonucleoprotein sm d2"},
    {id: "sm-d2"},
    {id: "snrnp core protein d2"},
    {id: "small nuclear ribonucleoprotein sm d3"},
    {id: "sm-d3"},
    {id: "snrnp core protein d3"},
    {id: "small nuclear ribonucleoprotein polypeptides b and b1"},
    {id: "snrpb protein"},
    {id: "small nuclear ribonucleoprotein-associated protein b"},
    {id: "snrnp-b"},
    {id: "snrpb"},
    {id: "sm11"},
    {id: "sm protein b"},
    {id: "sm-b"},
    {id: "smb"},
    {id: "small nuclear ribonucleoprotein-associated protein n"},
    {id: "snrnp-n"},
    {id: "sm protein d"},
    {id: "sm-d"},
    {id: "sm protein n"},
    {id: "sm-n"},
    {id: "smn"},
    {id: "small ribosomal subunit protein rack1"},
    {id: "guanine nucleotide-binding protein subunit beta-2-like 1"},
    {id: "receptor for activated c kinase"},
    {id: "receptor of activated protein c kinase 1"},
    {id: "receptor of activated protein kinase c 1"},
    {id: "cleaved into: small ribosomal subunit protein rack1, n-terminally processed (receptor of activated protein c kinase 1, n-terminally processed)"},
    {id: "small ribosomal subunit protein es1"},
    {id: "40s ribosomal protein s3a"},
    {id: "v-fos transformation effector protein"},
    {id: "cleaved into: 40s ribosomal protein s3b"},
    {id: "small ribosomal subunit protein es10"},
    {id: "40s ribosomal protein s10"},
    {id: "small ribosomal subunit protein es17"},
    {id: "40s ribosomal protein s17"},
    {id: "small ribosomal subunit protein es24"},
    {id: "40s ribosomal protein s24"},
    {id: "small ribosomal subunit protein es27"},
    {id: "40s ribosomal protein s27"},
    {id: "small ribosomal subunit protein es27-like"},
    {id: "40s ribosomal protein s27-like"},
    {id: "small ribosomal subunit protein es28"},
    {id: "40s ribosomal protein s28"},
    {id: "small ribosomal subunit protein es4"},
    {id: "40s ribosomal protein s4, x isoform"},
    {id: "small ribosomal subunit protein es6"},
    {id: "40s ribosomal protein s6"},
    {id: "small ribosomal subunit protein ms25"},
    {id: "28s ribosomal protein s25, mitochondrial"},
    {id: "mrp-s25"},
    {id: "s25mt"},
    {id: "small ribosomal subunit protein ms31"},
    {id: "28s ribosomal protein s31, mitochondrial"},
    {id: "mrp-s31"},
    {id: "s31mt"},
    {id: "small ribosomal subunit protein us10"},
    {id: "40s ribosomal protein s20"},
    {id: "small ribosomal subunit protein us10m"},
    {id: "28s ribosomal protein s10, mitochondrial"},
    {id: "mrp-s10"},
    {id: "s10mt"},
    {id: "liver regeneration-related protein lrrg099"},
    {id: "small ribosomal subunit protein us13"},
    {id: "40s ribosomal protein s18"},
    {id: "small ribosomal subunit protein us15m"},
    {id: "28s ribosomal protein s15, mitochondrial"},
    {id: "mrp-s15"},
    {id: "s15mt"},
    {id: "small ribosomal subunit protein us17"},
    {id: "40s ribosomal protein s11"},
    {id: "small ribosomal subunit protein us19"},
    {id: "40s ribosomal protein s15"},
    {id: "rig protein"},
    {id: "small ribosomal subunit protein us3"},
    {id: "small ribosomal subunit protein us4"},
    {id: "40s ribosomal protein s9"},
    {id: "small ribosomal subunit protein us5"},
    {id: "small ribosomal subunit protein us7m"},
    {id: "28s ribosomal protein s7, mitochondrial"},
    {id: "mrp-s7"},
    {id: "s7mt"},
    {id: "small ribosomal subunit protein us8"},
    {id: "40s ribosomal protein s15a"},
    {id: "small ubiquitin-related modifier"},
    {id: "sumo"},
    {id: "small ubiquitin-related modifier 2"},
    {id: "sumo-2"},
    {id: "smt3 homolog 2"},
    {id: "sentrin-2"},
    {id: "ubiquitin-like protein smt3a"},
    {id: "smt3a"},
    {id: "snurportin-1"},
    {id: "rna u transporter 1"},
    {id: "sodium channel and clathrin linker 1"},
    {id: "clathrin-associated protein 1a"},
    {id: "cap-1a"},
    {id: "sodium channel nav1.8-binding protein"},
    {id: "sodium channel-associated protein 1"},
    {id: "sodium channel protein type 10 subunit alpha"},
    {id: "peripheral nerve sodium channel 3"},
    {id: "pn3"},
    {id: "sensory neuron sodium channel"},
    {id: "sodium channel protein type x subunit alpha"},
    {id: "voltage-gated sodium channel subunit alpha nav1.8"},
    {id: "sodium channel protein type 8 subunit alpha"},
    {id: "peripheral nerve protein type 4"},
    {id: "pn4"},
    {id: "sodium channel 6"},
    {id: "nach6"},
    {id: "sodium channel protein type viii subunit alpha"},
    {id: "voltage-gated sodium channel subunit alpha nav1.6"},
    {id: "sodium sulfate cotransporter-2"},
    {id: "solute carrier family 13 member 4"},
    {id: "sodium- and chloride-dependent gaba transporter 1"},
    {id: "gat-1"},
    {id: "solute carrier family 6 member 1"},
    {id: "sodium- and chloride-dependent gaba transporter 2"},
    {id: "gat-2"},
    {id: "solute carrier family 6 member 13"},
    {id: "sodium- and chloride-dependent gaba transporter 3"},
    {id: "gat-3"},
    {id: "solute carrier family 6 member 11"},
    {id: "sodium- and chloride-dependent glycine transporter 1"},
    {id: "glyt-1"},
    {id: "glyt1"},
    {id: "solute carrier family 6 member 9"},
    {id: "sodium- and chloride-dependent glycine transporter 2"},
    {id: "glyt-2"},
    {id: "glyt2"},
    {id: "solute carrier family 6 member 5"},
    {id: "sodium- and chloride-dependent taurine transporter"},
    {id: "solute carrier family 6 member 6"},
    {id: "sodium-coupled monocarboxylate transporter 1"},
    {id: "electrogenic sodium monocarboxylate cotransporter"},
    {id: "solute carrier family 5 member 8"},
    {id: "sodium-coupled neutral amino acid symporter 1"},
    {id: "amino acid transporter a1"},
    {id: "rata1"},
    {id: "glutamine transporter"},
    {id: "n-system amino acid transporter 2"},
    {id: "solute carrier family 38 member 1"},
    {id: "system a amino acid transporter 1"},
    {id: "system a transporter 2"},
    {id: "system n amino acid transporter 1"},
    {id: "sodium-coupled neutral amino acid symporter 2"},
    {id: "amino acid transporter a2"},
    {id: "solute carrier family 38 member 2"},
    {id: "system a amino acid transporter 2"},
    {id: "system a transporter 1"},
    {id: "system n amino acid transporter 2"},
    {id: "sodium-coupled neutral amino acid transporter 3"},
    {id: "n-system amino acid transporter 1"},
    {id: "na(+)-coupled neutral amino acid transporter 3"},
    {id: "solute carrier family 38 member 3"},
    {id: "sodium-coupled neutral amino acid transporter 4"},
    {id: "amino acid transporter a3"},
    {id: "na(+)-coupled neutral amino acid transporter 4"},
    {id: "solute carrier family 38 member 4"},
    {id: "system a amino acid transporter 3"},
    {id: "sodium-coupled neutral amino acid transporter 5"},
    {id: "solute carrier family 38 member 5"},
    {id: "system n transporter 2"},
    {id: "sodium-coupled neutral amino acid transporter 7"},
    {id: "solute carrier family 38 member 7"},
    {id: "sodium-dependent multivitamin transporter"},
    {id: "na(+)-dependent multivitamin transporter"},
    {id: "solute carrier family 5 member 6"},
    {id: "sodium-dependent neutral amino acid transporter b"},
    {id: "solute carrier family 6 member 19"},
    {id: "system b(0) neutral amino acid transporter at1"},
    {id: "solute carrier family 6 member 15"},
    {id: "transporter v7-3"},
    {id: "sodium- and chloride-dependent neurotransmitter transporter ntt73"},
    {id: "sodium-dependent neutral amino acid transporter slc6a17"},
    {id: "sodium-dependent neurotransmitter transporter ntt4"},
    {id: "solute carrier family 6 member 17"},
    {id: "sodium-dependent organic anion transporter"},
    {id: "soat"},
    {id: "solute carrier family 10 member 6"},
    {id: "slc10a6"},
    {id: "sodium-dependent phosphate transporter 1"},
    {id: "phosphate transporter 1"},
    {id: "rpho-1"},
    {id: "solute carrier family 20 member 1"},
    {id: "sodium-dependent phosphate transporter 2"},
    {id: "phosphate transporter 2"},
    {id: "pit-2"},
    {id: "receptor for amphotropic viruses 1"},
    {id: "ram-1"},
    {id: "solute carrier family 20 member 2"},
    {id: "sodium-driven chloride bicarbonate exchanger"},
    {id: "solute carrier family 4 member 10"},
    {id: "sodium/bile acid cotransporter 7"},
    {id: "na(+)/bile acid cotransporter 7"},
    {id: "solute carrier family 10 member 7"},
    {id: "sodium/calcium exchanger 1"},
    {id: "na(+)/ca(2+)-exchange protein 1"},
    {id: "solute carrier family 8 member 1"},
    {id: "sodium/calcium exchanger 3"},
    {id: "na(+)/ca(2+)-exchange protein 3"},
    {id: "solute carrier family 8 member 3"},
    {id: "sodium/hydrogen exchanger 5"},
    {id: "na(+)/h(+) exchanger 5"},
    {id: "nhe-5"},
    {id: "solute carrier family 9 member 5"},
    {id: "sodium/hydrogen exchanger 6"},
    {id: "na(+)/h(+) exchanger 6"},
    {id: "nhe-6"},
    {id: "sodium/hydrogen exchanger"},
    {id: "solute carrier family 9 member 6"},
    {id: "sodium/hydrogen exchanger 9"},
    {id: "na(+)/h(+) exchanger 9"},
    {id: "nhe-9"},
    {id: "solute carrier family 9 member 9"},
    {id: "sodium/potassium-transporting atpase subunit beta-1-interacting protein"},
    {id: "na(+)/k(+)-transporting atpase subunit beta-1-interacting protein"},
    {id: "sodium/potassium/calcium exchanger 1"},
    {id: "na(+)/k(+)/ca(2+)-exchange protein 1"},
    {id: "retinal rod na-ca+k exchanger"},
    {id: "solute carrier family 24 member 1"},
    {id: "sodium/potassium/calcium exchanger 2"},
    {id: "na(+)/k(+)/ca(2+)-exchange protein 2"},
    {id: "retinal cone na-ca+k exchanger"},
    {id: "solute carrier family 24 member 2"},
    {id: "soluble calcium-activated nucleotidase 1"},
    {id: "scan-1"},
    {id: "apyrase homolog"},
    {id: "solute carrier family 10"},
    {id: "sodium/bile acid cotransporter family"},
    {id: "solute carrier family 12 member 4"},
    {id: "electroneutral potassium-chloride cotransporter 1"},
    {id: "erythroid k-cl cotransporter 1"},
    {id: "rkcc1"},
    {id: "furosemide-sensitive k-cl cotransporter"},
    {id: "solute carrier family 12 member 5"},
    {id: "electroneutral potassium-chloride cotransporter 2"},
    {id: "k-cl cotransporter 2"},
    {id: "rkcc2"},
    {id: "neuronal k-cl cotransporter"},
    {id: "solute carrier family 12 member 9"},
    {id: "cation-chloride cotransporter 6"},
    {id: "solute carrier family 13"},
    {id: "sodium-dependent dicarboxylate transporter"},
    {id: "solute carrier family 13 member 3"},
    {id: "solute carrier family 13 member 1"},
    {id: "nasi-1"},
    {id: "renal sodium/sulfate cotransporter"},
    {id: "na(+)/sulfate cotransporter"},
    {id: "solute carrier family 13 member 2"},
    {id: "intestinal sodium/dicarboxylate cotransporter"},
    {id: "na(+)/dicarboxylate cotransporter 1"},
    {id: "nadc-1"},
    {id: "solute carrier family 14"},
    {id: "urea transporter"},
    {id: "solute carrier family 14 member 1"},
    {id: "kidd blood group"},
    {id: "solute carrier family 15"},
    {id: "oligopeptide transporter"},
    {id: "solute carrier family 15 member 3"},
    {id: "peptide transporter 3"},
    {id: "peptide/histidine transporter 2"},
    {id: "solute carrier family 16, member 4"},
    {id: "solute carrier family 16, member 4 (monocarboxylic acid transporter 5)"},
    {id: "solute carrier family 19 member 1 variant 5"},
    {id: "solute carrier family 2"},
    {id: "facilitated glucose transporter"},
    {id: "solute carrier family 2, facilitated glucose transporter member 5"},
    {id: "fructose transporter"},
    {id: "glucose transporter type 5, small intestine"},
    {id: "glut-5"},
    {id: "solute carrier family 22"},
    {id: "solute carrier family 22 member 12"},
    {id: "urate anion exchanger 1"},
    {id: "urat1"},
    {id: "urate:anion antiporter slc22a12"},
    {id: "solute carrier family 22 member 13"},
    {id: "organic anion transporter 10"},
    {id: "oat10"},
    {id: "organic cation transporter-like 3"},
    {id: "orctl-3"},
    {id: "solute carrier family 22 member 23"},
    {id: "ion transporter protein"},
    {id: "solute carrier family 22 member 3"},
    {id: "extraneuronal monoamine transporter"},
    {id: "emt"},
    {id: "organic cation transporter 3"},
    {id: "oct3"},
    {id: "solute carrier family 22 member 4"},
    {id: "organic cation/carnitine transporter 1"},
    {id: "octn1"},
    {id: "solute carrier family 22 member 6"},
    {id: "organic anion transporter 1"},
    {id: "roat1"},
    {id: "renal organic anion transporter 1"},
    {id: "rroat1"},
    {id: "solute carrier family 22 member 7"},
    {id: "novel liver transporter"},
    {id: "organic anion transporter 2"},
    {id: "solute carrier family 23 member 2"},
    {id: "na(+)/l-ascorbic acid transporter 2"},
    {id: "solute carrier family 25"},
    {id: "mitochondrial carrier, brain"},
    {id: "pyrimidine nucleotide carrier"},
    {id: "solute carrier family 25 member 47"},
    {id: "hepatocellular carcinoma down-regulated mitochondrial carrier homolog"},
    {id: "solute carrier family 26 member 9"},
    {id: "anion transporter/exchanger protein 9"},
    {id: "solute carrier family 27"},
    {id: "fatty acid transporter"},
    {id: "solute carrier family 27 member 2"},
    {id: "solute carrier family 35"},
    {id: "udp-galactose transporter"},
    {id: "solute carrier family 35 member f6"},
    {id: "ant2-binding protein"},
    {id: "ant2bp"},
    {id: "transport and golgi organization 9 homolog"},
    {id: "solute carrier family 35 member g2"},
    {id: "transmembrane protein 22"},
    {id: "solute carrier family 37"},
    {id: "glycerol-3-phosphate transporter"},
    {id: "solute carrier family 37 member 1"},
    {id: "solute carrier family 49 member 4"},
    {id: "disrupted in renal carcinoma protein 2 homolog"},
    {id: "solute carrier family 5"},
    {id: "neutral amino acid transporters, system a"},
    {id: "solute carrier family 52, riboflavin transporter, member 2"},
    {id: "porcine endogenous retrovirus a receptor 2"},
    {id: "protein gpr172b"},
    {id: "riboflavin transporter 1"},
    {id: "rrft1"},
    {id: "solute carrier family 66 member 2"},
    {id: "pq-loop repeat-containing protein 1"},
    {id: "solute carrier family 7"},
    {id: "solute carrier family 7 member 10"},
    {id: "solute carrier family 7, (neutral amino acid transporter, y+ system) member 10"},
    {id: "solute carrier family 7 member 13"},
    {id: "sodium-independent aspartate/glutamate transporter 1"},
    {id: "solute carrier family 9, member c2"},
    {id: "solute carrier organic anion transporter family member 1a1"},
    {id: "organic anion-transporting polypeptide 1"},
    {id: "oatp-1"},
    {id: "sodium-independent organic anion transporter 1"},
    {id: "solute carrier family 21 member 1"},
    {id: "solute carrier organic anion transporter family member 1a3"},
    {id: "oat-k2"},
    {id: "sodium-independent organic anion transporter k1"},
    {id: "oat-k1"},
    {id: "solute carrier family 21 member 4"},
    {id: "roat-k"},
    {id: "solute carrier organic anion transporter family member 1a4"},
    {id: "brain digoxin carrier protein"},
    {id: "brain-specific organic anion transporter"},
    {id: "oatp-b1"},
    {id: "sodium-independent organic anion-transporting polypeptide 2"},
    {id: "solute carrier family 21 member 5"},
    {id: "solute carrier organic anion transporter family member 1b2"},
    {id: "liver-specific organic anion transporter 1"},
    {id: "rlst-1"},
    {id: "sodium-independent organic anion-transporting polypeptide 4"},
    {id: "oatp-4"},
    {id: "solute carrier family 21 member 10"},
    {id: "somatoliberin"},
    {id: "growth hormone-releasing factor"},
    {id: "grf"},
    {id: "growth hormone-releasing hormone"},
    {id: "ghrh"},
    {id: "somatostatin receptor type 2"},
    {id: "ss-2-r"},
    {id: "ss2-r"},
    {id: "ss2r"},
    {id: "srif-1"},
    {id: "somatostatin receptor type 3"},
    {id: "ss-3-r"},
    {id: "ss3-r"},
    {id: "ss3r"},
    {id: "ssr-28"},
    {id: "somatostatin receptor type 4"},
    {id: "ss-4-r"},
    {id: "ss4-r"},
    {id: "ss4r"},
    {id: "somatotropin"},
    {id: "growth hormone"},
    {id: "something about silencing protein 10"},
    {id: "charged amino acid-rich leucine zipper 1"},
    {id: "disrupter of silencing sas10"},
    {id: "utp3 homolog"},
    {id: "sortilin-related receptor"},
    {id: "low-density lipoprotein receptor relative with 11 ligand-binding repeats"},
    {id: "ldlr relative with 11 ligand-binding repeats"},
    {id: "lr11"},
    {id: "sorting protein-related receptor containing ldlr class a repeats"},
    {id: "sorla"},
    {id: "sp110 nuclear body protein"},
    {id: "intracellular pathogen resistance protein 1"},
    {id: "sp2 protein"},
    {id: "sp2 transcription factor"},
    {id: "sp7 transcription factor"},
    {id: "specificity protein 7 beta"},
    {id: "spastin"},
    {id: "speckle targeted pip5k1a-regulated poly"},
    {id: "star-pap"},
    {id: "rna-binding motif protein 21"},
    {id: "rna-binding protein 21"},
    {id: "u6 snrna-specific terminal uridylyltransferase 1"},
    {id: "u6-tutase"},
    {id: "speedy protein a"},
    {id: "gs4"},
    {id: "rapid inducer of g2/m progression in oocytes a"},
    {id: "ringo a"},
    {id: "speedy-1"},
    {id: "spy1"},
    {id: "sperm acrosome associated 7"},
    {id: "sperm acrosome associated protein 7"},
    {id: "sperm associated antigen 6"},
    {id: "sperm associated antigen 6-like"},
    {id: "sperm motility kinase"},
    {id: "sperm surface protein sp17"},
    {id: "sperm autoantigenic protein 17"},
    {id: "sperm-associated antigen 1"},
    {id: "hsd-3.8"},
    {id: "infertility-related sperm protein spag-1"},
    {id: "sperm-associated antigen 11a"},
    {id: "antimicrobial-like protein bin-1b"},
    {id: "ep2 protein"},
    {id: "spermatogenesis-associated protein 19, mitochondrial"},
    {id: "spermatogenic cell-specific gene 1 protein"},
    {id: "spergen-1"},
    {id: "spermatogenesis-associated protein 21"},
    {id: "spermatogenic cell-specific gene 3 protein"},
    {id: "spergen-3"},
    {id: "spermatogenesis-associated protein 24"},
    {id: "testis protein t6441"},
    {id: "spermatogenesis-associated protein 31"},
    {id: "acrosome-expressed protein 1"},
    {id: "spermatogenesis-defective protein 39 homolog"},
    {id: "vps33b-interacting protein in apical-basolateral polarity regulator"},
    {id: "vps33b-interacting protein in polarity and apical restriction"},
    {id: "hspe-39"},
    {id: "spexin"},
    {id: "liver regeneration-related protein lrrgt00060"},
    {id: "npq"},
    {id: "neuropeptide q"},
    {id: "spexin hormone"},
    {id: "cleaved into: spexin-1; spexin-2 (npq 53-70)"},
    {id: "sphingolipid delta"},
    {id: "ec 1.14.19.17"},
    {id: "degenerative spermatocyte homolog 1"},
    {id: "degenerative spermatocyte-like protein rdes"},
    {id: "dihydroceramide desaturase-1"},
    {id: "ec 5.2.1.-"},
    {id: "ec 1.14.18.5"},
    {id: "degenerative spermatocyte homolog 2"},
    {id: "sphingolipid 4-desaturase"},
    {id: "sphingolipid c4-monooxygenase"},
    {id: "sphingomyelin phosphodiesterase"},
    {id: "ec 3.1.4.12"},
    {id: "sphingomyelin phosphodiesterase 2"},
    {id: "lyso-platelet-activating factor-phospholipase c"},
    {id: "lyso-paf-plc"},
    {id: "neutral sphingomyelinase"},
    {id: "n-smase"},
    {id: "nsmase"},
    {id: "sphingosine 1-phosphate receptor 1"},
    {id: "s1p receptor 1"},
    {id: "s1p1"},
    {id: "endothelial differentiation g-protein coupled receptor 1"},
    {id: "sphingosine 1-phosphate receptor edg-1"},
    {id: "s1p receptor edg-1"},
    {id: "cd antigen cd363"},
    {id: "sphingosine 1-phosphate receptor 2"},
    {id: "s1p receptor 2"},
    {id: "s1p2"},
    {id: "agr16"},
    {id: "endothelial differentiation g-protein coupled receptor 5"},
    {id: "g-protein coupled receptor h218"},
    {id: "sphingosine 1-phosphate receptor edg-5"},
    {id: "s1p receptor edg-5"},
    {id: "sphingosine 1-phosphate receptor 5"},
    {id: "s1p receptor 5"},
    {id: "s1p5"},
    {id: "endothelial differentiation g-protein-coupled receptor 8"},
    {id: "nerve growth factor-regulated g-protein-coupled receptor 1"},
    {id: "nrg-1"},
    {id: "sphingosine 1-phosphate receptor edg-8"},
    {id: "s1p receptor edg-8"},
    {id: "spikar"},
    {id: "zinc finger, mynd-type containing 8"},
    {id: "spin2a protein"},
    {id: "spindlin family, member 2a"},
    {id: "spindle and centriole-associated protein 1"},
    {id: "coiled-coil domain-containing protein 52"},
    {id: "spindle and kinetochore-associated protein 2"},
    {id: "protein fam33a"},
    {id: "spindlin-1"},
    {id: "spindlin1"},
    {id: "spliceosome rna helicase ddx39b"},
    {id: "56 kda u2af65-associated protein"},
    {id: "atp-dependent rna helicase p47"},
    {id: "dead box protein uap56"},
    {id: "splicing factor 3b subunit 5"},
    {id: "sf3b5"},
    {id: "splicing factor 45"},
    {id: "rna-binding motif protein 17"},
    {id: "splicing factor u2af 26 kda subunit"},
    {id: "liver regeneration-related protein lrrg157/lrrg158"},
    {id: "u2 auxiliary factor 26"},
    {id: "u2 small nuclear rna auxiliary factor 1-like protein 4"},
    {id: "u2af1-like 4"},
    {id: "splicing factor u2af subunit"},
    {id: "u2 snrnp auxiliary factor large subunit"},
    {id: "splicing factor proline and glutamine rich"},
    {id: "splicing factor proline/glutamine rich (polypyrimidine tract binding protein associated)"},
    {id: "splicing factor, arginine/serine-rich 19"},
    {id: "ctd-binding sr-like protein ra1"},
    {id: "sr-related and ctd-associated factor 1"},
    {id: "splicing factor, suppressor of white-apricot homolog"},
    {id: "splicing factor, arginine/serine-rich 8"},
    {id: "suppressor of white apricot protein homolog"},
    {id: "spondin-1"},
    {id: "f-spondin"},
    {id: "spondin-2"},
    {id: "mindin"},
    {id: "spred3 protein"},
    {id: "sprouty-related, evh1 domain containing 3"},
    {id: "sproutin"},
    {id: "ta20"},
    {id: "sprouty rtk signaling antagonist 2"},
    {id: "sprouty homolog 2 (drosophila)"},
    {id: "sprouty-related, evh1 domain-containing protein 2"},
    {id: "spred-2"},
    {id: "squalene monooxygenase"},
    {id: "ec 1.14.14.17"},
    {id: "squalene epoxidase"},
    {id: "se"},
    {id: "squalene synthase"},
    {id: "sqs"},
    {id: "ss"},
    {id: "ec 2.5.1.21"},
    {id: "fpp:fpp farnesyltransferase"},
    {id: "farnesyl-diphosphate farnesyltransferase"},
    {id: "src kinase-associated phosphoprotein 1"},
    {id: "skap55 adapter protein"},
    {id: "src family-associated phosphoprotein 1"},
    {id: "src kinase-associated phosphoprotein of 55 kda"},
    {id: "skap-55"},
    {id: "pp55"},
    {id: "src kinase-associated phosphoprotein 2"},
    {id: "skap55 homolog"},
    {id: "skap-55hom"},
    {id: "skap-hom"},
    {id: "skap55-hom"},
    {id: "src family-associated phosphoprotein 2"},
    {id: "src kinase-associated phosphoprotein 55-related protein"},
    {id: "star-related lipid transfer protein 3"},
    {id: "start domain-containing protein 3"},
    {id: "stanniocalcin-1"},
    {id: "stc-1"},
    {id: "stanniocalcin-2"},
    {id: "stc-2"},
    {id: "staphylococcal nuclease domain-containing protein"},
    {id: "ec 3.1.31.1"},
    {id: "starch-binding domain-containing protein 1"},
    {id: "genethonin-1"},
    {id: "glycophagy cargo receptor stbd1"},
    {id: "statherin"},
    {id: "theobromine induced protein"},
    {id: "stathmin 4"},
    {id: "stmn4 protein"},
    {id: "stathmin-2"},
    {id: "superior cervical ganglion-10 protein"},
    {id: "protein scg10"},
    {id: "stathmin-4"},
    {id: "stathmin-like protein b3"},
    {id: "rb3"},
    {id: "staufen double-stranded rna binding protein 1"},
    {id: "staufen isoform stau+i6"},
    {id: "steroid transmembrane transporter slc22a24"},
    {id: "organic anion transporter 5"},
    {id: "roat5"},
    {id: "solute carrier family 22 member 24"},
    {id: "steroidogenic acute regulatory protein, mitochondrial"},
    {id: "star"},
    {id: "start domain-containing protein 1"},
    {id: "stard1"},
    {id: "steroidogenic factor 1"},
    {id: "sf-1"},
    {id: "adrenal 4-binding protein"},
    {id: "fushi tarazu factor homolog 1"},
    {id: "nuclear receptor subfamily 5 group a member 1"},
    {id: "steroid hormone receptor ad4bp"},
    {id: "sterol regulatory element-binding protein 1"},
    {id: "srebp-1"},
    {id: "adipocyte determination- and differentiation-dependent factor 1"},
    {id: "add1"},
    {id: "sterol regulatory element-binding transcription factor 1"},
    {id: "cleaved into: processed sterol regulatory element-binding protein 1 (transcription factor srebf1)"},
    {id: "sterol regulatory element-binding protein cleavage-activating protein"},
    {id: "scap"},
    {id: "srebp cleavage-activating protein"},
    {id: "sterol-4-alpha-carboxylate 3-dehydrogenase, decarboxylating"},
    {id: "ec 1.1.1.170"},
    {id: "stomatin"},
    {id: "epb7.2"},
    {id: "stomatin-like protein 2, mitochondrial"},
    {id: "slp-2"},
    {id: "store-operated calcium entry regulator stimate"},
    {id: "stim-activating enhancer encoded by tmem110"},
    {id: "transmembrane protein 110"},
    {id: "store-operated calcium entry-associated regulatory factor"},
    {id: "transmembrane protein 66"},
    {id: "stress-70 protein, mitochondrial"},
    {id: "75 kda glucose-regulated protein"},
    {id: "heat shock 70 kda protein 9"},
    {id: "stress-activated protein kinase jnk"},
    {id: "stress-associated endoplasmic reticulum protein 1"},
    {id: "ribosome-attached membrane protein 4"},
    {id: "stress-induced-phosphoprotein 1"},
    {id: "sti1"},
    {id: "hsc70/hsp90-organizing protein"},
    {id: "hop"},
    {id: "striated muscle-specific serine/threonine-protein kinase"},
    {id: "aortic preferentially expressed protein 1"},
    {id: "apeg-1"},
    {id: "striatin-3"},
    {id: "cell cycle autoantigen sg2na"},
    {id: "s/g2 antigen"},
    {id: "stromelysin-3"},
    {id: "sl-3"},
    {id: "st3"},
    {id: "matrix metalloproteinase-11"},
    {id: "mmp-11"},
    {id: "structure-specific endonuclease subunit slx1"},
    {id: "giy-yig domain-containing protein 1"},
    {id: "substance-k receptor"},
    {id: "skr"},
    {id: "nk-2 receptor"},
    {id: "nk-2r"},
    {id: "neurokinin a receptor"},
    {id: "tachykinin receptor 2"},
    {id: "succinate dehydrogenase"},
    {id: "cybs"},
    {id: "cii-4"},
    {id: "qps3"},
    {id: "succinate dehydrogenase complex subunit d"},
    {id: "succinate-ubiquinone oxidoreductase cytochrome b small subunit"},
    {id: "succinate-ubiquinone reductase membrane anchor subunit"},
    {id: "ec 1.3.5.1"},
    {id: "iron-sulfur subunit of complex ii"},
    {id: "ip"},
    {id: "succinate dehydrogenase assembly factor 1, mitochondrial"},
    {id: "sdh assembly factor 1"},
    {id: "sdhaf1"},
    {id: "lyr motif-containing protein 8"},
    {id: "succinate dehydrogenase assembly factor 2, mitochondrial"},
    {id: "sdh assembly factor 2"},
    {id: "sdhaf2"},
    {id: "succinate dehydrogenase assembly factor 3, mitochondrial"},
    {id: "sdh assembly factor 3"},
    {id: "sdhaf3"},
    {id: "liver regeneration-related protein lrrgt00092"},
    {id: "succinate receptor 1"},
    {id: "g-protein coupled receptor 91"},
    {id: "succinate--coa ligase"},
    {id: "adp-forming"},
    {id: "ec 6.2.1.5"},
    {id: "atp-specific succinyl-coa synthetase subunit beta"},
    {id: "a-scs"},
    {id: "succinyl-coa synthetase beta-a chain"},
    {id: "scs-betaa"},
    {id: "adp/gdp-forming"},
    {id: "ec 6.2.1.4"},
    {id: "succinyl-coa synthetase subunit alpha"},
    {id: "scs-alpha"},
    {id: "gdp-forming"},
    {id: "gtp-specific succinyl-coa synthetase subunit beta"},
    {id: "g-scs"},
    {id: "gtpscs"},
    {id: "succinyl-coa synthetase beta-g chain"},
    {id: "scs-betag"},
    {id: "succinate--hydroxymethylglutarate coa-transferase"},
    {id: "ec 2.8.3.13"},
    {id: "succinylcoa:glutarate-coa transferase"},
    {id: "succinate-semialdehyde dehydrogenase"},
    {id: "ec 1.2.1.24"},
    {id: "succinate-semialdehyde dehydrogenase, mitochondrial"},
    {id: "aldehyde dehydrogenase family 5 member a1"},
    {id: "nad(+)-dependent succinic semialdehyde dehydrogenase"},
    {id: "succinyl-coa:3-ketoacid-coenzyme a transferase"},
    {id: "ec 2.8.3.5"},
    {id: "sugar phosphate phosphatase"},
    {id: "sugar transporter sweet1"},
    {id: "solute carrier family 50 member 1"},
    {id: "sulfate anion transporter 1"},
    {id: "solute carrier family 26 member 1"},
    {id: "sulfate transporter"},
    {id: "diastrophic dysplasia protein homolog"},
    {id: "solute carrier family 26 member 2"},
    {id: "sulfhydryl oxidase"},
    {id: "sulfhydryl oxidase 1"},
    {id: "rqsox"},
    {id: "rsox"},
    {id: "fad-dependent sulfhydryl oxidase-2"},
    {id: "sox-2"},
    {id: "quiescin q6"},
    {id: "sulfotransferase"},
    {id: "sulfotransferase 1 family member d1"},
    {id: "st1d1"},
    {id: "dopamine sulfotransferase sult1d1"},
    {id: "tyrosine-ester sulfotransferase"},
    {id: "sulfotransferase 1a1"},
    {id: "st1a1"},
    {id: "ec 2.8.2.1"},
    {id: "aryl sulfotransferase"},
    {id: "aryl sulfotransferase iv"},
    {id: "astiv"},
    {id: "minoxidil sulfotransferase"},
    {id: "mx-st"},
    {id: "pst-1"},
    {id: "phenol sulfotransferase"},
    {id: "sulfokinase"},
    {id: "sulfotransferase 1b1"},
    {id: "st1b1"},
    {id: "dopa/tyrosine sulfotransferase"},
    {id: "sulfotransferase family cytosolic 1b member 1"},
    {id: "sulfotransferase 1c2a"},
    {id: "st1c2a"},
    {id: "rsult1c2a"},
    {id: "sulfotransferase k2"},
    {id: "sulfotransferase 2b1"},
    {id: "hydroxysteroid sulfotransferase 2"},
    {id: "sulfotransferase family cytosolic 2b member 1"},
    {id: "st2b1"},
    {id: "sulfotransferase 4a1"},
    {id: "st4a1"},
    {id: "brain sulfotransferase-like protein"},
    {id: "rbr-stl"},
    {id: "nervous system sulfotransferase"},
    {id: "nst"},
    {id: "superoxide dismutase"},
    {id: "cu-zn"},
    {id: "ec 1.15.1.1"},
    {id: "suppressor of ikbke 1"},
    {id: "suppressor of ikk-epsilon"},
    {id: "suppressor of tumorigenicity 14 protein homolog"},
    {id: "ec 3.4.21.109"},
    {id: "serine protease 14"},
    {id: "surfeit locus protein 1"},
    {id: "surfeit locus protein 4"},
    {id: "survival of motor neuron-related-splicing factor 30"},
    {id: "survival motor neuron domain-containing protein 1"},
    {id: "susd2 protein"},
    {id: "sushi domain containing 2"},
    {id: "sushi repeat-containing protein srpx"},
    {id: "down-regulated by v-src"},
    {id: "drs"},
    {id: "sushi, nidogen and egf-like domain-containing protein 1"},
    {id: "insulin-responsive sequence dna-binding protein 1"},
    {id: "ire-bp1"},
    {id: "swi5-dependent recombination dna repair protein 1 homolog"},
    {id: "liver regeneration-related protein lrrgt00030"},
    {id: "meiosis protein 5 homolog"},
    {id: "synapse differentiation-inducing gene protein 1"},
    {id: "syndig1"},
    {id: "dispanin subfamily c member 2"},
    {id: "dspc2"},
    {id: "transmembrane protein 90b"},
    {id: "synapsin-3"},
    {id: "synapsin iii"},
    {id: "synaptic vesicle glycoprotein 2a"},
    {id: "synaptic vesicle protein 2"},
    {id: "synaptic vesicle protein 2a"},
    {id: "synaptic vesicle glycoprotein 2c"},
    {id: "synaptic vesicle protein 2c"},
    {id: "synaptic vesicle membrane protein vat-1 homolog"},
    {id: "mitofusin-binding protein"},
    {id: "protein mib"},
    {id: "synaptobrevin homolog ykt6"},
    {id: "synaptogyrin-1"},
    {id: "p29"},
    {id: "synaptojanin-1"},
    {id: "synaptic inositol 1,4,5-trisphosphate 5-phosphatase 1"},
    {id: "synaptojanin-2-binding protein"},
    {id: "mitochondrial outer membrane protein 25"},
    {id: "npw16"},
    {id: "synaptonemal complex protein 3"},
    {id: "scp-3"},
    {id: "synaptosomal-associated protein 23"},
    {id: "snap-23"},
    {id: "vesicle-membrane fusion protein snap-23"},
    {id: "synaptosomal-associated protein 47"},
    {id: "snap-47"},
    {id: "synaptosomal-associated 47 kda protein"},
    {id: "synaptotagmin 11"},
    {id: "synaptotagmin xi"},
    {id: "synaptotagmin-10"},
    {id: "synaptotagmin x"},
    {id: "sytx"},
    {id: "synaptotagmin-12"},
    {id: "synaptotagmin xii"},
    {id: "sytxii"},
    {id: "synaptotagmin-related gene 1 protein"},
    {id: "srg1"},
    {id: "synaptotagmin-17"},
    {id: "protein b/k"},
    {id: "synaptotagmin xvii"},
    {id: "sytxvii"},
    {id: "synaptotagmin-4"},
    {id: "synaptotagmin iv"},
    {id: "sytiv"},
    {id: "synaptotagmin-5"},
    {id: "synaptotagmin ix"},
    {id: "synaptotagmin v"},
    {id: "sytv"},
    {id: "synaptotagmin-6"},
    {id: "synaptotagmin vi"},
    {id: "sytvi"},
    {id: "synaptotagmin-7"},
    {id: "protein syt7"},
    {id: "synaptotagmin vii"},
    {id: "sytvii"},
    {id: "syncollin"},
    {id: "proximal small intestine-specific protein 9"},
    {id: "syndetin"},
    {id: "coiled-coil domain-containing protein 132"},
    {id: "earp/garpii complex subunit vps50"},
    {id: "synembryn"},
    {id: "protein ric-8"},
    {id: "synembryn-b"},
    {id: "protein ric-8b"},
    {id: "synoviolin 1"},
    {id: "syvn1 protein"},
    {id: "syntaxin-12"},
    {id: "syntaxin-13"},
    {id: "syntaxin-binding protein 5"},
    {id: "lethal(2) giant larvae protein homolog 3"},
    {id: "tomosyn-1"},
    {id: "syntenin-1"},
    {id: "syndecan-binding protein 1"},
    {id: "syntenin-2"},
    {id: "syndecan-binding protein 2"},
    {id: "synuclein alpha"},
    {id: "truncated alpha synuclein isoform 41"},
    {id: "t-box transcription factor tbx3"},
    {id: "t-box protein 3"},
    {id: "t-box transcription factor tbx6"},
    {id: "t-box protein 6"},
    {id: "t-cell ecto-adp-ribosyltransferase 2"},
    {id: "adp-ribosyltransferase c2 and c3 toxin-like 2"},
    {id: "artc2"},
    {id: "alloantigen rt6.2"},
    {id: "mono(adp-ribosyl)transferase 2b"},
    {id: "nad(+) glycohydrolase"},
    {id: "ec 3.2.2.5"},
    {id: "t-cell nad(p)(+)--arginine adp-ribosyltransferase 2"},
    {id: "t-cell mono(adp-ribosyl)transferase 2"},
    {id: "t-cell surface protein rt6.2"},
    {id: "t-cell immunoglobulin and mucin domain-containing protein 2"},
    {id: "timd-2"},
    {id: "t-cell immunoglobulin mucin receptor 2"},
    {id: "tim-2"},
    {id: "t-cell membrane protein 2"},
    {id: "t-cell surface antigen cd2"},
    {id: "lfa-2"},
    {id: "lfa-3 receptor"},
    {id: "ox-34 antigen"},
    {id: "t-cell surface antigen t11/leu-5"},
    {id: "cd antigen cd2"},
    {id: "t-cell surface glycoprotein cd3 gamma chain"},
    {id: "t-cell receptor t3 gamma chain"},
    {id: "t-cell surface protein tactile"},
    {id: "cell surface antigen cd96"},
    {id: "cd antigen cd96"},
    {id: "t-complex protein 1 subunit beta"},
    {id: "cct-beta"},
    {id: "tcp-1-beta"},
    {id: "t-complex protein 1 subunit delta"},
    {id: "tcp-1-delta"},
    {id: "cct-delta"},
    {id: "t-complex protein 1 subunit epsilon"},
    {id: "cct-epsilon"},
    {id: "tcp-1-epsilon"},
    {id: "t-complex protein 1 subunit eta"},
    {id: "tcp-1-eta"},
    {id: "cct-eta"},
    {id: "t-complex protein 1 subunit gamma"},
    {id: "tcp-1-gamma"},
    {id: "cct-gamma"},
    {id: "t-complex protein 1 subunit theta"},
    {id: "cct-theta"},
    {id: "t-complex protein 10b"},
    {id: "tcp10b protein"},
    {id: "taf7-like rna polymerase ii, tata box binding protein"},
    {id: "tank-binding kinase 1-binding protein 1"},
    {id: "tbk1-binding protein 1"},
    {id: "protein prosapip2"},
    {id: "tar"},
    {id: "hiv-1"},
    {id: "tata box-binding protein-associated factor rna polymerase i subunit a"},
    {id: "tata box-binding protein-associated factor 1a"},
    {id: "tbp-associated factor 1a"},
    {id: "transcription initiation factor sl1/tif-ib subunit a"},
    {id: "tata box-binding protein-associated factor rna polymerase i subunit b"},
    {id: "rna polymerase i-specific tbp-associated factor 63 kda"},
    {id: "tafi63"},
    {id: "tata box-binding protein-associated factor 1b"},
    {id: "tbp-associated factor 1b"},
    {id: "transcription initiation factor sl1/tif-ib subunit b"},
    {id: "tata box-binding protein-associated factor rna polymerase i subunit d"},
    {id: "tata box-binding protein-associated factor 1d"},
    {id: "transcription initiation factor sl1/tif-ib subunit d"},
    {id: "tata box-binding protein-associated factor, rna polymerase i, subunit c"},
    {id: "tata box-binding protein-associated factor 1c"},
    {id: "tbp-associated factor 1c"},
    {id: "transcription initiation factor sl1/tif-ib subunit c"},
    {id: "tata box-binding protein-like 1"},
    {id: "tata box-binding protein-related factor 2"},
    {id: "tbp-like factor"},
    {id: "tbp-related protein"},
    {id: "tata-box binding protein associated factor 15"},
    {id: "taf15 protein"},
    {id: "tcdd-inducible poly"},
    {id: "tcf3 fusion partner homolog"},
    {id: "protein amida"},
    {id: "tea domain transcription factor 1"},
    {id: "tef-1 epsilon"},
    {id: "tef-1 theta"},
    {id: "tgf-beta receptor type-2"},
    {id: "tgfr-2"},
    {id: "tgf-beta type ii receptor"},
    {id: "transforming growth factor-beta receptor type ii"},
    {id: "tgf-beta receptor type ii"},
    {id: "tbetar-ii"},
    {id: "tgf-beta-activated kinase 1 and map3k7-binding protein 2"},
    {id: "mitogen-activated protein kinase kinase kinase 7-interacting protein 2"},
    {id: "tgf-beta-activated kinase 1-binding protein 2"},
    {id: "thap domain containing 1"},
    {id: "thap domain-containing apoptosis-associated protein 1 transcript variant 2"},
    {id: "tho complex subunit 1"},
    {id: "tho1"},
    {id: "liver regeneration-related protein lrrg175"},
    {id: "nuclear matrix protein p84"},
    {id: "tho complex subunit 6 homolog"},
    {id: "wd repeat-containing protein 58"},
    {id: "tir domain-containing adapter molecule 1"},
    {id: "ticam-1"},
    {id: "tlc domain-containing protein 1"},
    {id: "calfacilitin"},
    {id: "tle family member 5"},
    {id: "amino-terminal enhancer of split"},
    {id: "amino enhancer of split"},
    {id: "grg-5"},
    {id: "groucho-related protein 5"},
    {id: "r-esp1"},
    {id: "protein grg"},
    {id: "tlr4 interactor with leucine rich repeats"},
    {id: "leucine-rich repeat-containing protein kiaa0644 homolog"},
    {id: "tnf receptor-associated factor"},
    {id: "tnf receptor-associated factor 6"},
    {id: "e3 ubiquitin-protein ligase traf6"},
    {id: "ring-type e3 ubiquitin transferase traf6"},
    {id: "tnf superfamily member 12"},
    {id: "tumor necrosis factor ligand 4a"},
    {id: "tnf superfamily member 14"},
    {id: "tumor necrosis factor ligand 1d"},
    {id: "tnf superfamily member 15"},
    {id: "tumor necrosis factor ligand 1b"},
    {id: "tnf superfamily member 8"},
    {id: "tumor necrosis factor ligand 3a"},
    {id: "tnf superfamily member 9"},
    {id: "tumor necrosis factor ligand 5a"},
    {id: "tumor necrosis factor ligand superfamily member 9"},
    {id: "tpd52 like 1"},
    {id: "tumor protein d52-like 1"},
    {id: "traf-interacting protein with fha domain-containing protein b"},
    {id: "tifa-like protein"},
    {id: "traf3-interacting protein 1"},
    {id: "intraflagellar transport protein 54 homolog"},
    {id: "microtubule-interacting protein associated with traf3"},
    {id: "mip-t3"},
    {id: "trk-fused gene protein"},
    {id: "trafficking from er to golgi regulator"},
    {id: "tsc22 domain family protein 1"},
    {id: "regulatory protein tsc-22"},
    {id: "tgfb-stimulated clone 22 homolog"},
    {id: "transforming growth factor beta-1-induced transcript 4 protein"},
    {id: "tsc22 domain family protein 3"},
    {id: "glucocorticoid-induced leucine zipper protein"},
    {id: "tsc22 domain family protein 4"},
    {id: "tsc22-related-inducible leucine zipper protein 2"},
    {id: "tyro protein tyrosine kinase-binding protein"},
    {id: "dnax-activation protein 12"},
    {id: "tachykinin-4"},
    {id: "preprotachykinin-c"},
    {id: "ppt-c"},
    {id: "cleaved into: hemokinin (hk1) (hemokinin-1) (hemokinin-i) (hk-i)"},
    {id: "target of rapamycin complex 2 subunit mapkap1"},
    {id: "mitogen-activated protein kinase 2-associated protein 1"},
    {id: "stress-activated map kinase-interacting protein 1"},
    {id: "torc2 subunit mapkap1"},
    {id: "sapk-interacting protein 1"},
    {id: "target of rapamycin complex subunit lst8"},
    {id: "protein gbetal"},
    {id: "torc subunit lst8"},
    {id: "mlst8"},
    {id: "mammalian lethal with sec13 protein 8"},
    {id: "taste receptor type 1 member 1"},
    {id: "g-protein coupled receptor 70"},
    {id: "taste receptor type 1 member 2"},
    {id: "g-protein coupled receptor 71"},
    {id: "sweet taste receptor t1r2"},
    {id: "taste receptor type 1 member 3"},
    {id: "sweet taste receptor t1r3"},
    {id: "taste receptor type 2 member 102"},
    {id: "t2r102"},
    {id: "taste receptor type 2 member 37"},
    {id: "t2r37"},
    {id: "taste receptor type 2 member 104"},
    {id: "t2r104"},
    {id: "taste receptor type 2 member 21"},
    {id: "t2r21"},
    {id: "taste receptor type 2 member 105"},
    {id: "t2r105"},
    {id: "taste receptor type 2 member 9"},
    {id: "t2r9"},
    {id: "taste receptor type 2 member 106"},
    {id: "t2r106"},
    {id: "taste receptor type 2 member 19"},
    {id: "t2r19"},
    {id: "taste receptor type 2 member 109"},
    {id: "t2r109"},
    {id: "taste receptor type 2 member 38"},
    {id: "t2r38"},
    {id: "taste receptor type 2 member 113"},
    {id: "t2r113"},
    {id: "taste receptor type 2 member 30"},
    {id: "t2r30"},
    {id: "taste receptor type 2 member 116"},
    {id: "t2r116"},
    {id: "taste receptor type 2 member 33"},
    {id: "t2r33"},
    {id: "taste receptor type 2 member 120"},
    {id: "t2r120"},
    {id: "taste receptor type 2 member 17"},
    {id: "t2r17"},
    {id: "taste receptor type 2 member 124"},
    {id: "t2r124"},
    {id: "taste receptor type 2 member 25"},
    {id: "t2r25"},
    {id: "taste receptor type 2 member 129"},
    {id: "t2r129"},
    {id: "taste receptor type 2 member 24"},
    {id: "t2r24"},
    {id: "taste receptor type 2 member 134"},
    {id: "t2r134"},
    {id: "t2r34"},
    {id: "taste receptor type 2 member 23"},
    {id: "t2r23"},
    {id: "taste receptor type 2 member 136"},
    {id: "t2r136"},
    {id: "taste receptor type 2 member 40"},
    {id: "t2r40"},
    {id: "taste receptor type 2 member 143"},
    {id: "t2r143"},
    {id: "taste receptor type 2 member 27"},
    {id: "t2r27"},
    {id: "taste receptor type 2 member 16"},
    {id: "t2r16"},
    {id: "t2r18"},
    {id: "t2r3"},
    {id: "taste receptor type 2 member 3"},
    {id: "t2r11"},
    {id: "taste receptor type 2 member 39"},
    {id: "t2r39"},
    {id: "taste receptor type 2 member 32"},
    {id: "t2r32"},
    {id: "taste receptor type 2 member 4"},
    {id: "t2r4"},
    {id: "taste receptor type 2 member 20"},
    {id: "t2r20"},
    {id: "taste receptor type 2 member 18"},
    {id: "taste receptor type 2 member 41"},
    {id: "t2r41"},
    {id: "t2r12"},
    {id: "taste receptor type 2 member 7"},
    {id: "t2r7"},
    {id: "taste receptor type 2 member 6"},
    {id: "t2r6"},
    {id: "tax1-binding protein 1 homolog"},
    {id: "liver regeneration-related protein lrrg004"},
    {id: "taxilin alpha"},
    {id: "txlna protein"},
    {id: "tcerg1 protein"},
    {id: "transcription elongation regulator 1"},
    {id: "tcfcp2 protein"},
    {id: "transcription factor cp2"},
    {id: "tektin-2"},
    {id: "tektin-t"},
    {id: "testicular tektin"},
    {id: "telomerase cajal body protein 1"},
    {id: "wd repeat-containing protein 79"},
    {id: "wd40 repeat-containing protein antisense to tp53 gene homolog"},
    {id: "telomerase reverse transcriptase"},
    {id: "telomerase catalytic subunit"},
    {id: "telomerase-binding protein est1a"},
    {id: "telomeric repeat-binding factor 2-interacting protein 1"},
    {id: "terf2-interacting telomeric protein 1"},
    {id: "repressor/activator protein 1 homolog"},
    {id: "trf2-interacting telomeric protein 1"},
    {id: "rap1 homolog"},
    {id: "tenomodulin"},
    {id: "tem"},
    {id: "rtem"},
    {id: "chondromodulin-1-like protein"},
    {id: "chm1l"},
    {id: "rchm1l"},
    {id: "myodulin"},
    {id: "terminal nucleotidyltransferase 5b"},
    {id: "non-canonical poly(a) polymerase fam46b"},
    {id: "terminal nucleotidyltransferase 5c"},
    {id: "terpene cyclase/mutase family member"},
    {id: "tesb protein"},
    {id: "testis expressed basic protein 1"},
    {id: "tesmin"},
    {id: "metallothionein-like 5, testis-specific"},
    {id: "testin-2"},
    {id: "cmb-23"},
    {id: "cleaved into: testin-1 (cmb-22)"},
    {id: "testis anion transporter 1"},
    {id: "solute carrier family 26 member 8"},
    {id: "testis expressed 2"},
    {id: "tex2"},
    {id: "testis expressed 21"},
    {id: "testis expressed gene 21"},
    {id: "testis expressed 35"},
    {id: "tex35"},
    {id: "testis expressed 46"},
    {id: "testis-expressed protein 46"},
    {id: "testis-expressed protein 19.1"},
    {id: "testis-expressed protein 19a"},
    {id: "tex19.1"},
    {id: "testis-specific h1 histone"},
    {id: "haploid germ cell-specific nuclear protein 1"},
    {id: "histone h1.7"},
    {id: "histone h1t2"},
    {id: "testis-specific y-encoded protein 1"},
    {id: "rtspy"},
    {id: "testis-specific serine kinase substrate"},
    {id: "testis-specific kinase substrate"},
    {id: "stk22 substrate 1"},
    {id: "tetraspanin-1"},
    {id: "tspan-1"},
    {id: "tetraspanin-11"},
    {id: "tspan-11"},
    {id: "tetraspanin-12"},
    {id: "tspan-12"},
    {id: "transmembrane 4 superfamily member 12"},
    {id: "tetraspanin-13"},
    {id: "tspan-13"},
    {id: "transmembrane 4 superfamily member 13"},
    {id: "tetraspanin-2"},
    {id: "tspan-2"},
    {id: "tetraspanin-5"},
    {id: "tspan-5"},
    {id: "transmembrane 4 superfamily member 9"},
    {id: "tetratricopeptide repeat domain 39a"},
    {id: "ttc39a protein"},
    {id: "tetratricopeptide repeat domain 8"},
    {id: "ttc8 protein"},
    {id: "tetratricopeptide repeat domain 9"},
    {id: "ttc9 protein"},
    {id: "tetratricopeptide repeat protein 17"},
    {id: "tpr repeat protein 17"},
    {id: "tetratricopeptide repeat protein 19, mitochondrial"},
    {id: "tpr repeat protein 19"},
    {id: "tetratricopeptide repeat protein 29"},
    {id: "tpr repeat protein 29"},
    {id: "tetratricopeptide repeat protein 5"},
    {id: "tpr repeat protein 5"},
    {id: "stress-responsive activator of p300"},
    {id: "protein strap"},
    {id: "tetratricopeptide repeat protein 9c"},
    {id: "tpr repeat protein 9c"},
    {id: "tgfb2 protein"},
    {id: "transforming growth factor, beta 2"},
    {id: "thiamine pyrophosphokinase"},
    {id: "ec 2.7.6.2"},
    {id: "thiamine transporter 1"},
    {id: "thtr-1"},
    {id: "solute carrier family 19 member 2"},
    {id: "thiamine-triphosphatase"},
    {id: "ec 3.6.1.28"},
    {id: "thtpase"},
    {id: "thimet oligopeptidase"},
    {id: "ec 3.4.24.15"},
    {id: "endo-oligopeptidase a"},
    {id: "endopeptidase 24.15"},
    {id: "pz-peptidase"},
    {id: "soluble metallo-endopeptidase"},
    {id: "thiol s-methyltransferase tmt1b"},
    {id: "ec 2.1.1.9"},
    {id: "associated with lipid droplet protein 1"},
    {id: "aldi"},
    {id: "methyltransferase-like protein 7b"},
    {id: "thiol s-methyltransferase mettl7b"},
    {id: "thiopurine s-methyltransferase"},
    {id: "ec 2.1.1.67"},
    {id: "thiopurine methyltransferase"},
    {id: "thioredoxin domain containing 11"},
    {id: "txndc11 protein"},
    {id: "thioredoxin domain containing 2"},
    {id: "thioredoxin domain-containing protein 2"},
    {id: "thioredoxin domain-containing protein 12"},
    {id: "ec 1.8.4.2"},
    {id: "spermatid-specific thioredoxin-1"},
    {id: "sptrx-1"},
    {id: "thioredoxin reductase 2, mitochondrial"},
    {id: "ec 1.8.1.9"},
    {id: "thioredoxin reductase tr3"},
    {id: "thioredoxin reductase-like selenoprotein t"},
    {id: "selt"},
    {id: "thioredoxin, mitochondrial"},
    {id: "mtrx"},
    {id: "mt-trx"},
    {id: "thioredoxin-2"},
    {id: "thioredoxin-interacting protein"},
    {id: "vitamin d3 up-regulated protein 1"},
    {id: "thioredoxin-like protein 1"},
    {id: "thioredoxin-related protein"},
    {id: "thioredoxin-related transmembrane protein 2"},
    {id: "thioredoxin domain-containing protein 14"},
    {id: "threonine--trna ligase 1, cytoplasmic"},
    {id: "ec 6.1.1.3"},
    {id: "threonine--trna ligase, cytoplasmic"},
    {id: "threonyl-trna synthetase"},
    {id: "thrrs"},
    {id: "threonyl-trna synthetase 1"},
    {id: "threonine--trna ligase, mitochondrial"},
    {id: "threonyl-trna synthetase-like 1"},
    {id: "threonylcarbamoyl-amp synthase"},
    {id: "ec 2.7.7.87"},
    {id: "thymidine kinase"},
    {id: "ec 2.7.1.21"},
    {id: "thymidine phosphorylase"},
    {id: "tp"},
    {id: "ec 2.4.2.4"},
    {id: "tdrpase"},
    {id: "thymidylate synthase"},
    {id: "ec 2.1.1.45"},
    {id: "ts"},
    {id: "tsase"},
    {id: "thyroid hormone receptor alpha"},
    {id: "nuclear receptor subfamily 1 group a member 1"},
    {id: "c-erba-1"},
    {id: "c-erba-alpha"},
    {id: "thyroid hormone receptor-associated protein 3"},
    {id: "thyroid hormone receptor-associated protein complex 150 kda component"},
    {id: "trap150"},
    {id: "thyroid peroxidase"},
    {id: "ec 1.11.1.8"},
    {id: "thyrotropin subunit beta"},
    {id: "thyroid-stimulating hormone subunit beta"},
    {id: "tsh-b"},
    {id: "tsh-beta"},
    {id: "thyrotropin beta chain"},
    {id: "thyrotropin-releasing hormone receptor"},
    {id: "thyroliberin receptor"},
    {id: "thyroxine 5-deiodinase"},
    {id: "ec 1.21.99.3"},
    {id: "5diii"},
    {id: "dioiii"},
    {id: "type 3 di"},
    {id: "type iii iodothyronine deiodinase"},
    {id: "tight junction protein zo-1"},
    {id: "tight junction protein 1"},
    {id: "zona occludens protein 1"},
    {id: "zonula occludens protein 1"},
    {id: "tissue factor pathway inhibitor"},
    {id: "tfpi"},
    {id: "extrinsic pathway inhibitor"},
    {id: "epi"},
    {id: "lipoprotein-associated coagulation inhibitor"},
    {id: "laci"},
    {id: "tmem129 protein"},
    {id: "transmembrane protein 129, e3 ubiquitin ligase"},
    {id: "toll/interleukin-1 receptor domain-containing adapter protein"},
    {id: "tir domain-containing adapter protein"},
    {id: "tomoregulin-1"},
    {id: "tr-1"},
    {id: "protein nc1"},
    {id: "transmembrane protein with egf-like and one follistatin-like domain"},
    {id: "tonsoku-like protein"},
    {id: "inhibitor of kappa b-related protein"},
    {id: "i-kappa-b-related protein"},
    {id: "ikappabr"},
    {id: "nf-kappa-b inhibitor-like protein 2"},
    {id: "nuclear factor of kappa light polypeptide gene enhancer in b-cells inhibitor-like 2"},
    {id: "torsin-1a"},
    {id: "dystonia 1 protein"},
    {id: "torsin atpase 1"},
    {id: "torsin-1a-interacting protein 1"},
    {id: "lamina-associated polypeptide 1b"},
    {id: "lap1b"},
    {id: "lamina-associated polypeptide 1c"},
    {id: "lap1c"},
    {id: "torsin-3a"},
    {id: "torsin family 3 member a"},
    {id: "trace amine-associated receptor 2"},
    {id: "tar-2"},
    {id: "trace amine receptor 2"},
    {id: "g-protein coupled receptor 58"},
    {id: "trace amine-associated receptor 3"},
    {id: "tar-3"},
    {id: "trace amine receptor 3"},
    {id: "trace amine-associated receptor 4"},
    {id: "tar-4"},
    {id: "trace amine receptor 4"},
    {id: "2-phenylethylamine receptor"},
    {id: "trace amine-associated receptor 5"},
    {id: "tar-5"},
    {id: "trace amine receptor 5"},
    {id: "trace amine-associated receptor 6"},
    {id: "tar-6"},
    {id: "trace amine receptor 6"},
    {id: "trafficking kinesin-binding protein 2"},
    {id: "amyotrophic lateral sclerosis 2 chromosomal region candidate gene 3 protein homolog"},
    {id: "gaba-a receptor-interacting factor 1"},
    {id: "grif-1"},
    {id: "o-glcnac transferase-interacting protein of 98 kda"},
    {id: "trafficking regulator of glut4 1"},
    {id: "brain endothelial cell-derived protein 1"},
    {id: "bec-1"},
    {id: "dispanin subfamily b member 1"},
    {id: "dspb1"},
    {id: "tumor suppressor candidate 5 homolog"},
    {id: "transaldolase"},
    {id: "ec 2.2.1.2"},
    {id: "transcription and mrna export factor eny2"},
    {id: "enhancer of yellow 2 transcription factor homolog"},
    {id: "transcription elongation factor a protein 1"},
    {id: "transcription elongation factor s-ii protein 1"},
    {id: "transcription elongation factor a protein-like 1"},
    {id: "tcea-like protein 1"},
    {id: "transcription elongation factor s-ii protein-like 1"},
    {id: "transcription elongation factor a protein-like 7"},
    {id: "tcea-like protein 7"},
    {id: "transcription elongation factor s-ii protein-like 7"},
    {id: "transcription elongation factor a protein-like 8"},
    {id: "tcea-like protein 8"},
    {id: "transcription elongation factor s-ii protein-like 8"},
    {id: "up-regulated in nephrectomized rat kidney #1"},
    {id: "transcription factor a, mitochondrial"},
    {id: "mttfa"},
    {id: "transcription factor ap-2-alpha"},
    {id: "ap-2 transcription factor"},
    {id: "activating enhancer-binding protein 2-alpha"},
    {id: "activator protein 2"},
    {id: "transcription factor e2f7"},
    {id: "e2f-7"},
    {id: "transcription factor ec"},
    {id: "tfe-c"},
    {id: "rtfec"},
    {id: "transcription factor gata-4"},
    {id: "dna-binding protein gata-gt2"},
    {id: "gata-binding factor 4"},
    {id: "transcription factor gata-6"},
    {id: "gata-binding factor 6"},
    {id: "transcription factor hes-1"},
    {id: "hairy and enhancer of split 1"},
    {id: "hairy-like protein"},
    {id: "rhl"},
    {id: "transcription factor hes-2"},
    {id: "hairy and enhancer of split 2"},
    {id: "transcription factor hes-3"},
    {id: "hairy and enhancer of split 3"},
    {id: "transcription factor hes-5"},
    {id: "hairy and enhancer of split 5"},
    {id: "transcription factor iiib 50 kda subunit"},
    {id: "b-related factor 2"},
    {id: "brf-2"},
    {id: "transcription factor lbx1"},
    {id: "ladybird homeobox protein homolog 1"},
    {id: "transcription factor mafa"},
    {id: "pancreatic beta-cell-specific transcriptional activator"},
    {id: "transcription factor mafb"},
    {id: "maf-b"},
    {id: "transcription factor maf-1"},
    {id: "v-maf musculoaponeurotic fibrosarcoma oncogene homolog b"},
    {id: "transcription factor mafg"},
    {id: "v-maf musculoaponeurotic fibrosarcoma oncogene homolog g"},
    {id: "transcription factor nf-e2 45 kda subunit"},
    {id: "leucine zipper protein nf-e2"},
    {id: "nuclear factor, erythroid-derived 2 45 kda subunit"},
    {id: "p45 nf-e2"},
    {id: "transcription factor yy2"},
    {id: "yin and yang 2"},
    {id: "yy-2"},
    {id: "transcription initiation factor iia subunit 1"},
    {id: "general transcription factor iia subunit 1"},
    {id: "cleaved into: transcription initiation factor iia alpha chain (tfiia p35 subunit); transcription initiation factor iia beta chain (tfiia p19 subunit)"},
    {id: "transcription initiation factor iia subunit 2"},
    {id: "general transcription factor iia subunit 2"},
    {id: "transcription initiation factor iia gamma chain"},
    {id: "tfiia-gamma"},
    {id: "transcription initiation factor iib"},
    {id: "general transcription factor tfiib"},
    {id: "transcription initiation factor tfiid subunit 11"},
    {id: "tfiid subunit p30-beta"},
    {id: "transcription initiation factor tfiid 28 kda subunit"},
    {id: "taf(ii)28"},
    {id: "tafii-28"},
    {id: "tafii28"},
    {id: "transcription initiation factor tfiid subunit 2"},
    {id: "transcription initiation factor tfiid 150 kda subunit"},
    {id: "transcription initiation factor tfiid subunit 8"},
    {id: "tbp-associated factor 8"},
    {id: "transcription initiation factor tfiid subunit 9"},
    {id: "rna polymerase ii tbp-associated factor subunit g"},
    {id: "transcription initiation factor tfiid 31 kda subunit"},
    {id: "tafii-31"},
    {id: "tafii31"},
    {id: "transcription initiation factor tfiid 32 kda subunit"},
    {id: "tafii-32"},
    {id: "tafii32"},
    {id: "transcription intermediary factor 1-beta"},
    {id: "tif1-beta"},
    {id: "e3 sumo-protein ligase trim28"},
    {id: "krab-associated protein 1"},
    {id: "nuclear corepressor kap-1"},
    {id: "ring-type e3 ubiquitin transferase tif1-beta"},
    {id: "tripartite motif-containing protein 28"},
    {id: "transcription termination factor 3, mitochondrial"},
    {id: "mitochondrial transcription termination factor 3"},
    {id: "mterf3"},
    {id: "mterf domain-containing protein 1, mitochondrial"},
    {id: "transcription termination factor 4, mitochondrial"},
    {id: "mitochondrial transcription termination factor 4"},
    {id: "mterf domain-containing protein 2"},
    {id: "transcriptional adapter 1"},
    {id: "transcriptional adapter 1-like protein"},
    {id: "transcriptional adapter 3"},
    {id: "ada3 homolog"},
    {id: "transcriptional adapter 3-like"},
    {id: "ada3-like protein"},
    {id: "transcriptional coactivator yap1"},
    {id: "yes-associated protein 1"},
    {id: "protein yorkie homolog"},
    {id: "yes-associated protein yap65 homolog"},
    {id: "transducin"},
    {id: "beta"},
    {id: "transferrin receptor protein 1"},
    {id: "tr"},
    {id: "tfr"},
    {id: "tfr1"},
    {id: "trfr"},
    {id: "cd antigen cd71"},
    {id: "transferrin receptor protein 2"},
    {id: "tfr2"},
    {id: "transforming growth factor beta activator lrrc33"},
    {id: "leucine-rich repeat-containing protein 33^"},
    {id: "negative regulator of reactive oxygen species"},
    {id: "transforming growth factor beta receptor type 3"},
    {id: "tgf-beta receptor type 3"},
    {id: "tgfr-3"},
    {id: "betaglycan"},
    {id: "transforming growth factor beta receptor iii"},
    {id: "tgf-beta receptor type iii"},
    {id: "transforming growth factor beta-1 proprotein"},
    {id: "cleaved into: latency-associated peptide (lap); transforming growth factor beta-1 (tgf-beta-1)"},
    {id: "transforming protein rhoa"},
    {id: "transgelin-3"},
    {id: "neuronal protein 22"},
    {id: "np22"},
    {id: "neuronal protein np25"},
    {id: "transient receptor potential cation channel subfamily c member 5"},
    {id: "transient receptor potential cation channel, subfamily c, member 5"},
    {id: "transient receptor potential cation channel subfamily m member 8"},
    {id: "cold menthol receptor 1"},
    {id: "transient receptor potential cation channel subfamily v member 1"},
    {id: "trpv1"},
    {id: "capsaicin receptor"},
    {id: "osm-9-like trp channel 1"},
    {id: "otrpc1"},
    {id: "vanilloid receptor 1"},
    {id: "vanilloid receptor type 1-like"},
    {id: "transient receptor potential cation channel subfamily v member 4"},
    {id: "trpv4"},
    {id: "osm-9-like trp channel 4"},
    {id: "otrpc4"},
    {id: "vanilloid receptor-related osmotically-activated channel"},
    {id: "vr-oac"},
    {id: "transient receptor potential cation channel subfamily v member 5"},
    {id: "trpv5"},
    {id: "calcium transporter 2"},
    {id: "epithelial calcium channel 1"},
    {id: "ecac1"},
    {id: "osm-9-like trp channel 3"},
    {id: "otrpc3"},
    {id: "transitional endoplasmic reticulum atpase"},
    {id: "ter atpase"},
    {id: "ec 3.6.4.6"},
    {id: "15s mg(2+)-atpase p97 subunit"},
    {id: "valosin-containing protein"},
    {id: "vcp"},
    {id: "translation factor guf1, mitochondrial"},
    {id: "ec 3.6.5.n1"},
    {id: "elongation factor 4 homolog"},
    {id: "ef-4"},
    {id: "gtpase guf1"},
    {id: "ribosomal back-translocase"},
    {id: "translation initiation factor eif-2b subunit alpha"},
    {id: "eif-2b gdp-gtp exchange factor subunit alpha"},
    {id: "translation initiation factor eif-2b subunit delta"},
    {id: "eif-2b gdp-gtp exchange factor subunit delta"},
    {id: "translation initiation factor eif-2b subunit epsilon"},
    {id: "eif-2b gdp-gtp exchange factor subunit epsilon"},
    {id: "translation initiation factor eif-2b subunit gamma"},
    {id: "eif-2b gdp-gtp exchange factor subunit gamma"},
    {id: "translational activator of cytochrome c oxidase 1"},
    {id: "coiled-coil domain-containing protein 44"},
    {id: "translational activator of mitochondrially-encoded cytochrome c oxidase i"},
    {id: "translationally-controlled tumor protein"},
    {id: "tctp"},
    {id: "lens epithelial protein"},
    {id: "translin"},
    {id: "component 3 of promoter of risc"},
    {id: "translin-associated protein x"},
    {id: "translin-associated factor x"},
    {id: "translocon-associated protein subunit alpha"},
    {id: "trap-alpha"},
    {id: "liver regeneration-related protein lrrg137"},
    {id: "signal sequence receptor subunit alpha"},
    {id: "ssr-alpha"},
    {id: "translocon-associated protein subunit beta"},
    {id: "trap-beta"},
    {id: "signal sequence receptor subunit beta"},
    {id: "translocon-associated protein subunit delta"},
    {id: "signal sequence receptor subunit delta"},
    {id: "translocon-associated protein subunit gamma"},
    {id: "signal sequence receptor subunit gamma"},
    {id: "transmembrane o-methyltransferase homolog"},
    {id: "ec 2.1.1.6"},
    {id: "catechol o-methyltransferase 2"},
    {id: "transmembrane emp24 domain-containing protein 1"},
    {id: "interleukin-1 receptor-like 1 ligand"},
    {id: "p24 family protein gamma-1"},
    {id: "p24gamma1"},
    {id: "transmembrane emp24 domain-containing protein 2"},
    {id: "copi-coated vesicle membrane protein p24"},
    {id: "membrane protein p24a"},
    {id: "rnp21.4"},
    {id: "p24 family protein beta-1"},
    {id: "p24beta1"},
    {id: "transmembrane emp24 domain-containing protein 5"},
    {id: "p24 family protein gamma-2"},
    {id: "p24gamma2"},
    {id: "transmembrane emp24 domain-containing protein 7"},
    {id: "p24 family protein gamma-3"},
    {id: "p24gamma3"},
    {id: "p27"},
    {id: "transmembrane emp24 domain-containing protein 9"},
    {id: "p24 family protein alpha-2"},
    {id: "p24alpha2"},
    {id: "transmembrane protease serine"},
    {id: "transmembrane protease serine 9"},
    {id: "transmembrane protein 109"},
    {id: "mitsugumin-23"},
    {id: "mg23"},
    {id: "transmembrane protein 135"},
    {id: "peroxisomal membrane protein 52"},
    {id: "pmp52"},
    {id: "transmembrane protein 150a"},
    {id: "fasting-inducible integral membrane protein tm6p1"},
    {id: "transmembrane protein 150"},
    {id: "transmembrane protein 158"},
    {id: "40 kda binp-binding protein"},
    {id: "p40bbp"},
    {id: "brain-specific binding protein"},
    {id: "ras-induced senescence protein 1"},
    {id: "transmembrane protein 163"},
    {id: "synaptic vesicle membrane protein of 31 kda"},
    {id: "transmembrane protein 165"},
    {id: "transmembrane protein tparl"},
    {id: "transmembrane protein 170b"},
    {id: "liver regeneration-related protein lrrg102"},
    {id: "transmembrane protein 176b"},
    {id: "protein lr8"},
    {id: "tolerance-related and induced transcript protein"},
    {id: "transmembrane protein 204"},
    {id: "claudin-like protein 24"},
    {id: "transmembrane protein 258"},
    {id: "dolichyl-diphosphooligosaccharide-protein glycosyltransferase subunit tmem258"},
    {id: "transmembrane protein 33"},
    {id: "protein db83"},
    {id: "transmembrane protein 35b"},
    {id: "zmym6 neighbor protein"},
    {id: "transmembrane protein 79"},
    {id: "mattrin"},
    {id: "transmembrane reductase cyb561d2"},
    {id: "cytochrome b561 domain-containing protein 2"},
    {id: "trefoil factor 1"},
    {id: "protein ps2"},
    {id: "trehalase"},
    {id: "ec 3.2.1.28"},
    {id: "alpha-trehalose glucohydrolase"},
    {id: "pancreatic lipase"},
    {id: "tribbles homolog 3"},
    {id: "trb-3"},
    {id: "neuronal cell death-inducible putative kinase"},
    {id: "trifunctional enzyme subunit alpha, mitochondrial"},
    {id: "monolysocardiolipin acyltransferase"},
    {id: "tp-alpha"},
    {id: "includes: long-chain enoyl-coa hydratase (ec 4.2.1.17); long chain 3-hydroxyacyl-coa dehydrogenase (ec 1.1.1.211)"},
    {id: "trifunctional enzyme subunit beta, mitochondrial"},
    {id: "tp-beta"},
    {id: "includes: 3-ketoacyl-coa thiolase (ec 2.3.1.155) (ec 2.3.1.16) (acetyl-coa acyltransferase) (beta-ketothiolase)"},
    {id: "trifunctional purine biosynthetic protein adenosine-3"},
    {id: "includes: phosphoribosylamine--glycine ligase (ec 6.3.4.13) (glycinamide ribonucleotide synthetase) (gars) (phosphoribosylglycinamide synthetase); phosphoribosylformylglycinamidine cyclo-ligase (ec 6.3.3.1) (air synthase) (airs) (phosphoribosyl-aminoimidazole synthetase); phosphoribosylglycinamide formyltransferase (ec 2.1.2.2) (5'-phosphoribosylglycinamide transformylase) (gar transformylase) (gart)"},
    {id: "trim14 protein"},
    {id: "tripartite motif-containing 14"},
    {id: "trimeric intracellular cation channel type b"},
    {id: "tric-b"},
    {id: "tricb"},
    {id: "transmembrane protein 38b"},
    {id: "trimethylguanosine synthase"},
    {id: "nuclear receptor coactivator 6-interacting protein"},
    {id: "prip-interacting protein with methyltransferase motif"},
    {id: "pimt"},
    {id: "pipmt"},
    {id: "trimethyllysine dioxygenase, mitochondrial"},
    {id: "ec 1.14.11.8"},
    {id: "epsilon-trimethyllysine 2-oxoglutarate dioxygenase"},
    {id: "tml hydroxylase"},
    {id: "tml-alpha-ketoglutarate dioxygenase"},
    {id: "tml dioxygenase"},
    {id: "tmld"},
    {id: "triokinase/fmn cyclase"},
    {id: "bifunctional atp-dependent dihydroxyacetone kinase/fad-amp lyase (cyclizing)"},
    {id: "includes: atp-dependent dihydroxyacetone kinase (dha kinase) (ec 2.7.1.28) (ec 2.7.1.29) (glycerone kinase) (triokinase) (triose kinase); fad-amp lyase (cyclizing) (ec 4.6.1.15) (fad-amp lyase (cyclic fmn forming)) (fmn cyclase)"},
    {id: "ec 4.6.1.15"},
    {id: "triosephosphate isomerase"},
    {id: "ec 5.3.1.1"},
    {id: "tripartite motif-containing protein 26"},
    {id: "zinc finger protein 173"},
    {id: "tripartite motif-containing protein 44"},
    {id: "protein dipb"},
    {id: "tripartite motif-containing protein 54"},
    {id: "ring finger protein 30"},
    {id: "tripartite motif-containing protein 72"},
    {id: "mitsugumin-53"},
    {id: "mg53"},
    {id: "tripeptidyl-peptidase 1"},
    {id: "ec 3.4.14.9"},
    {id: "tripeptidyl aminopeptidase"},
    {id: "tripeptidyl-peptidase i"},
    {id: "tripeptidyl-peptidase 2"},
    {id: "ec 3.4.14.10"},
    {id: "triple qxxk/r motif-containing protein"},
    {id: "triple repetitive-sequence of qxxk/r protein homolog"},
    {id: "trophoblast glycoprotein"},
    {id: "5t4 oncofetal trophoblast glycoprotein"},
    {id: "5t4 oncotrophoblast glycoprotein"},
    {id: "wnt-activated inhibitory factor 1"},
    {id: "waif1"},
    {id: "tropomodulin-2"},
    {id: "neuronal tropomodulin"},
    {id: "n-tmod"},
    {id: "tropomyosin beta chain"},
    {id: "beta-tropomyosin"},
    {id: "tropomyosin-2"},
    {id: "troponin i, cardiac muscle"},
    {id: "cardiac troponin i"},
    {id: "troponin i, fast skeletal muscle"},
    {id: "troponin i, fast-twitch isoform"},
    {id: "troponin t, slow skeletal muscle"},
    {id: "tnts"},
    {id: "slow skeletal muscle troponin t"},
    {id: "stnt"},
    {id: "trypsin v-a"},
    {id: "trypsin-4"},
    {id: "pretrypsinogen iv"},
    {id: "trypsin iv"},
    {id: "tryptophan 2,3-dioxygenase"},
    {id: "tdo"},
    {id: "ec 1.13.11.11"},
    {id: "tryptamin 2,3-dioxygenase"},
    {id: "tryptophan oxygenase"},
    {id: "to"},
    {id: "trpo"},
    {id: "tryptophan pyrrolase"},
    {id: "tryptophanase"},
    {id: "tryptophan 5-hydroxylase 1"},
    {id: "ec 1.14.16.4"},
    {id: "tryptophan 5-monooxygenase 1"},
    {id: "tryptophan--trna ligase, cytoplasmic"},
    {id: "ec 6.1.1.2"},
    {id: "tryptophanyl-trna synthetase"},
    {id: "trprs"},
    {id: "cleaved into: t1-trprs; t2-trprs"},
    {id: "tryptophanyl trna synthetase 2"},
    {id: "tubulin alpha-1c chain"},
    {id: "alpha-tubulin 6"},
    {id: "tubulin alpha-6 chain"},
    {id: "cleaved into: detyrosinated tubulin alpha-1c chain"},
    {id: "tubulin alpha-3 chain"},
    {id: "alpha-tubulin 3"},
    {id: "cleaved into: detyrosinated tubulin alpha-3 chain"},
    {id: "tubulin alpha-4a chain"},
    {id: "alpha-tubulin 4"},
    {id: "tubulin alpha-4 chain"},
    {id: "tubulin alpha-8 chain"},
    {id: "alpha-tubulin 8"},
    {id: "cleaved into: dephenylalaninated tubulin alpha-8 chain"},
    {id: "tubulin beta-3 chain"},
    {id: "neuron-specific class iii beta-tubulin"},
    {id: "tubulin delta chain"},
    {id: "delta-tubulin"},
    {id: "tubulin folding cofactor b"},
    {id: "zh14 protein"},
    {id: "tubulin gamma-1 chain"},
    {id: "gamma-1-tubulin"},
    {id: "gamma-tubulin complex component 1"},
    {id: "gcp-1"},
    {id: "tubulin polyglutamylase complex subunit 2"},
    {id: "pgs2"},
    {id: "tubulin polymerization-promoting protein"},
    {id: "tppp"},
    {id: "25 kda brain-specific protein"},
    {id: "tppp/p25"},
    {id: "p25-alpha"},
    {id: "tubulin-specific chaperone a"},
    {id: "tcp1-chaperonin cofactor a"},
    {id: "tubulin-folding cofactor a"},
    {id: "cfa"},
    {id: "tubulin-specific chaperone e"},
    {id: "tubulin-folding cofactor e"},
    {id: "tudor domain-containing protein 7"},
    {id: "pctaire2-binding protein"},
    {id: "tudor repeat associator with pctaire-2"},
    {id: "trap"},
    {id: "tuft1 protein"},
    {id: "tuftelin 1"},
    {id: "tuftelin-interacting protein 11"},
    {id: "septin and tuftelin-interacting protein 1"},
    {id: "stip-1"},
    {id: "tumor necrosis factor"},
    {id: "ligand"},
    {id: "tumor necrosis factor ligand 7a"},
    {id: "tumor necrosis factor alpha-induced protein 8-like protein 2"},
    {id: "tipe2"},
    {id: "tnf alpha-induced protein 8-like protein 2"},
    {id: "tnfaip8-like protein 2"},
    {id: "tumor necrosis factor ligand superfamily member 4"},
    {id: "ox40 ligand"},
    {id: "ox40l"},
    {id: "cd antigen cd252"},
    {id: "tumor necrosis factor ligand superfamily member 6"},
    {id: "cd95 ligand"},
    {id: "cd95-l"},
    {id: "fas antigen ligand"},
    {id: "fas ligand"},
    {id: "fasl"},
    {id: "cd antigen cd178"},
    {id: "cleaved into: tumor necrosis factor ligand superfamily member 6, membrane form; tumor necrosis factor ligand superfamily member 6, soluble form (receptor-binding fasl ectodomain) (soluble fas ligand) (sfasl); adam10-processed fasl form (apl); fasl intracellular domain (fasl icd) (sppl2a-processed fasl form) (spa)"},
    {id: "tumor necrosis factor receptor superfamily member 1b"},
    {id: "tumor necrosis factor receptor 2"},
    {id: "tnf-r2"},
    {id: "tumor necrosis factor receptor type ii"},
    {id: "tnf-rii"},
    {id: "tnfr-ii"},
    {id: "p75"},
    {id: "p80 tnf-alpha receptor"},
    {id: "cd antigen cd120b"},
    {id: "tumor necrosis factor receptor superfamily member 21"},
    {id: "death receptor 6"},
    {id: "cd antigen cd358"},
    {id: "tumor necrosis factor receptor superfamily member 4"},
    {id: "mrc ox40"},
    {id: "ox40 antigen"},
    {id: "ox40l receptor"},
    {id: "cd antigen cd134"},
    {id: "tumor necrosis factor receptor superfamily member 5"},
    {id: "b-cell surface antigen cd40"},
    {id: "cd40l receptor"},
    {id: "tumor necrosis factor receptor superfamily member 6"},
    {id: "apo-1 antigen"},
    {id: "apoptosis-mediating surface antigen fas"},
    {id: "faslg receptor"},
    {id: "tumor protein 63"},
    {id: "p63"},
    {id: "keratinocyte transcription factor ket"},
    {id: "transformation-related protein 63"},
    {id: "tp63"},
    {id: "tumor protein p73-like"},
    {id: "p73l"},
    {id: "tumor protein p53-inducible protein 11"},
    {id: "p53-induced gene 11 protein"},
    {id: "tumor susceptibility gene 101 protein"},
    {id: "escrt-i complex subunit tsg101"},
    {id: "type 1 phosphatidylinositol 4,5-bisphosphate 4-phosphatase"},
    {id: "type 1 ptdins-4,5-p2 4-ptase"},
    {id: "ptdins-4,5-p2 4-ptase i"},
    {id: "transmembrane protein 55b"},
    {id: "type 2 phosphatidylinositol 4,5-bisphosphate 4-phosphatase"},
    {id: "type 2 ptdins-4,5-p2 4-ptase"},
    {id: "ptdins-4,5-p2 4-ptase ii"},
    {id: "transmembrane protein 55a"},
    {id: "type ii inositol 3,4-bisphosphate 4-phosphatase"},
    {id: "ec 3.1.3.66"},
    {id: "inositol polyphosphate 4-phosphatase type ii"},
    {id: "type ii keratin 23"},
    {id: "type ii keratin kb23"},
    {id: "type-1 angiotensin ii receptor b"},
    {id: "at3"},
    {id: "angiotensin ii type-1 receptor b"},
    {id: "at1 receptor b"},
    {id: "type-1 angiotensin ii receptor-associated protein"},
    {id: "at1 receptor-associated protein"},
    {id: "type-2 angiotensin ii receptor"},
    {id: "angiotensin ii type-2 receptor"},
    {id: "at2 receptor"},
    {id: "tyrosine--trna ligase"},
    {id: "ec 6.1.1.1"},
    {id: "tyrosyl-trna synthetase"},
    {id: "tyrosine-protein kinase"},
    {id: "tyrosine-protein kinase csk"},
    {id: "c-src kinase"},
    {id: "tyrosine-protein kinase fer"},
    {id: "proto-oncogene c-fer"},
    {id: "tyrosine-protein kinase flk"},
    {id: "p94-fer"},
    {id: "tyrosine-protein kinase fgr"},
    {id: "proto-oncogene c-fgr"},
    {id: "p55-fgr"},
    {id: "tyrosine-protein kinase lyn"},
    {id: "v-yes-1 yamaguchi sarcoma viral related oncogene homolog"},
    {id: "p53lyn"},
    {id: "p56lyn"},
    {id: "tyrosine-protein kinase mer"},
    {id: "proto-oncogene c-mer"},
    {id: "receptor tyrosine kinase mertk"},
    {id: "tyrosine-protein kinase receptor"},
    {id: "tyrosine-protein phosphatase non-receptor type"},
    {id: "tyrosine-protein phosphatase non-receptor type 12"},
    {id: "tyrosine-protein phosphatase non-receptor type 13"},
    {id: "tyrosine-protein phosphatase non-receptor type 20"},
    {id: "tyrosine-protein phosphatase non-receptor type 7"},
    {id: "hematopoietic protein-tyrosine phosphatase"},
    {id: "heptp"},
    {id: "protein-tyrosine phosphatase lc-ptp"},
    {id: "tyrosine-protein phosphatase non-receptor type 9"},
    {id: "u1 small nuclear ribonucleoprotein c"},
    {id: "u1 snrnp c"},
    {id: "u1-c"},
    {id: "u1c"},
    {id: "u11/u12 small nuclear ribonucleoprotein 35 kda protein"},
    {id: "u1 snrnp-binding protein homolog"},
    {id: "u3 small nucleolar rna-associated protein 11"},
    {id: "u3 snorna-associated protein 11"},
    {id: "u3 small nucleolar rna-associated protein 25 homolog"},
    {id: "digestive organ expansion factor homolog"},
    {id: "utp25 small subunit processor component"},
    {id: "u3 small nucleolar ribonucleoprotein protein imp4"},
    {id: "u3 snornp protein imp4"},
    {id: "u4/u6 small nuclear ribonucleoprotein prp3"},
    {id: "pre-mrna-splicing factor 3"},
    {id: "u4/u6 small nuclear ribonucleoprotein prp31"},
    {id: "pre-mrna-processing factor 31"},
    {id: "u4/u6.u5 small nuclear ribonucleoprotein 27 kda protein"},
    {id: "u4/u6.u5 tri-snrnp-associated protein 3"},
    {id: "u4/u6.u5 tri-snrnp-associated protein 1"},
    {id: "squamous cell carcinoma antigen recognized by t-cells 1"},
    {id: "sart-1"},
    {id: "rsart-1"},
    {id: "u5 small nuclear ribonucleoprotein 200 kda helicase"},
    {id: "brr2 homolog"},
    {id: "u5 snrnp-specific 200 kda protein"},
    {id: "u5-200kd"},
    {id: "u6 small nuclear rna"},
    {id: "adenine-(43)-n(6)"},
    {id: "u6 snrna phosphodiesterase 1"},
    {id: "3'-5' rna exonuclease usb1"},
    {id: "ec 4.6.1.-"},
    {id: "uap56-interacting factor"},
    {id: "forty-two-three domain-containing protein 1"},
    {id: "ubx domain protein 2a"},
    {id: "ubx domain-containing protein 2a-like"},
    {id: "ubx domain-containing protein 2b"},
    {id: "nsfl1 cofactor p37"},
    {id: "p97 cofactor p37"},
    {id: "udp-galnac:beta-1,3-n-acetylgalactosaminyltransferase 1"},
    {id: "beta-1,3-galnac-t1"},
    {id: "ec 2.4.1.79"},
    {id: "beta-1,3-galactosyltransferase 3"},
    {id: "beta-1,3-galtase 3"},
    {id: "beta3gal-t3"},
    {id: "beta3galt3"},
    {id: "b3gal-t3"},
    {id: "beta-3-gx-t3"},
    {id: "galactosylgalactosylglucosylceramide beta-d-acetyl-galactosaminyltransferase"},
    {id: "globoside synthase"},
    {id: "udp-n-acetylgalactosamine:globotriaosylceramide beta-1,3-n-acetylgalactosaminyltransferase"},
    {id: "udp-glcnac:betagal beta-1,3-n-acetylglucosaminyltransferase 7"},
    {id: "bgnt-7"},
    {id: "beta-1,3-gn-t7"},
    {id: "beta-1,3-n-acetylglucosaminyltransferase 7"},
    {id: "beta3gn-t7"},
    {id: "udp-glcnac:betagal beta-1,3-n-acetylglucosaminyltransferase-like protein 1"},
    {id: "bgnt-like protein 1"},
    {id: "beta1,3-n-acetylglucosaminyltransferase-like protein 1"},
    {id: "beta3gn-t-like protein 1"},
    {id: "beta3gntl1"},
    {id: "udp-n-acetylglucosamine pyrophosphorylase 1 like 1"},
    {id: "uap1l1 protein"},
    {id: "udp-n-acetylglucosamine transferase subunit alg13 homolog"},
    {id: "ec 2.4.1.141"},
    {id: "glycosyltransferase 28 domain-containing protein 1"},
    {id: "udp-n-acetylglucosamine transporter"},
    {id: "golgi udp-glcnac transporter"},
    {id: "solute carrier family 35 member a3"},
    {id: "udp-n-acetylglucosamine--dolichyl-phosphate n-acetylglucosaminephosphotransferase"},
    {id: "ec 2.7.8.15"},
    {id: "glcnac-1-p transferase"},
    {id: "n-acetylglucosamine-1-phosphate transferase"},
    {id: "udp-n-acetylglucosamine--peptide n-acetylglucosaminyltransferase 110 kda subunit"},
    {id: "ec 2.4.1.255"},
    {id: "o-glcnac transferase subunit p110"},
    {id: "o-linked n-acetylglucosamine transferase 110 kda subunit"},
    {id: "udp-glucose 4-epimerase"},
    {id: "ec 5.1.3.2"},
    {id: "udp-glucose 6-dehydrogenase"},
    {id: "ec 1.1.1.22"},
    {id: "udp-glucose:glycoprotein glucosyltransferase 1"},
    {id: "ugt1"},
    {id: "rugt1"},
    {id: "udp--glc:glycoprotein glucosyltransferase"},
    {id: "udp-glucose ceramide glucosyltransferase-like 1"},
    {id: "udp-glucuronate decarboxylase"},
    {id: "ec 4.1.1.35"},
    {id: "udp-glucuronic acid decarboxylase 1"},
    {id: "udp-glucuronate decarboxylase 1"},
    {id: "udp-glucuronosyltransferase"},
    {id: "ec 2.4.1.17"},
    {id: "udp-glucuronosyltransferase 2b1"},
    {id: "udpgt 2b1"},
    {id: "ugt2b1"},
    {id: "udpgtr-2"},
    {id: "ump-cmp kinase"},
    {id: "ec 2.7.4.14"},
    {id: "deoxycytidylate kinase"},
    {id: "dcmp kinase"},
    {id: "nucleoside-diphosphate kinase"},
    {id: "uridine monophosphate/cytidine monophosphate kinase"},
    {id: "ump/cmp kinase"},
    {id: "ump/cmpk"},
    {id: "upf0696 protein c11orf68 homolog"},
    {id: "basophilic leukemia-expressed protein bles03"},
    {id: "utp--glucose-1-phosphate uridylyltransferase"},
    {id: "ec 2.7.7.9"},
    {id: "ube2l3 protein"},
    {id: "ubiquitin-conjugating enzyme e2l 3"},
    {id: "ubia prenyltransferase domain-containing protein 1"},
    {id: "ubiquinol-cytochrome-c reductase complex assembly factor 2"},
    {id: "mitochondrial nucleoid factor 1"},
    {id: "mitochondrial protein m19"},
    {id: "ubiquinone biosynthesis o-methyltransferase, mitochondrial"},
    {id: "3-demethylubiquinol 3-o-methyltransferase"},
    {id: "ec 2.1.1.64"},
    {id: "polyprenyldihydroxybenzoate methyltransferase"},
    {id: "ec 2.1.1.114"},
    {id: "ubiquinone biosynthesis monooxygenase coq6, mitochondrial"},
    {id: "coenzyme q10 monooxygenase 6"},
    {id: "ubiquinone biosynthesis protein coq4 homolog, mitochondrial"},
    {id: "coenzyme q biosynthesis protein 4 homolog"},
    {id: "ubiquitin carboxyl-terminal hydrolase"},
    {id: "ubiquitin carboxyl-terminal hydrolase 1"},
    {id: "deubiquitinating enzyme 1"},
    {id: "ubiquitin thioesterase 1"},
    {id: "ubiquitin-specific-processing protease 1"},
    {id: "cleaved into: ubiquitin carboxyl-terminal hydrolase 1, n-terminal fragment"},
    {id: "ubiquitin carboxyl-terminal hydrolase 16"},
    {id: "deubiquitinating enzyme 16"},
    {id: "ubiquitin thioesterase 16"},
    {id: "ubiquitin-specific-processing protease 16"},
    {id: "ubiquitin carboxyl-terminal hydrolase 2"},
    {id: "41 kda ubiquitin-specific protease"},
    {id: "deubiquitinating enzyme 2"},
    {id: "ubiquitin thioesterase 2"},
    {id: "ubiquitin-specific-processing protease 2"},
    {id: "ubiquitin-specific-processing protease testis"},
    {id: "ubp-t"},
    {id: "ubiquitin carboxyl-terminal hydrolase 28"},
    {id: "deubiquitinating enzyme 28"},
    {id: "ubiquitin thioesterase 28"},
    {id: "ubiquitin-specific-processing protease 28"},
    {id: "ubiquitin carboxyl-terminal hydrolase 30"},
    {id: "deubiquitinating enzyme 30"},
    {id: "ubiquitin thioesterase 30"},
    {id: "ubiquitin-specific-processing protease 30"},
    {id: "ub-specific protease 30"},
    {id: "ubiquitin carboxyl-terminal hydrolase 4"},
    {id: "deubiquitinating enzyme 4"},
    {id: "ubiquitin thioesterase 4"},
    {id: "ubiquitin-specific-processing protease 4"},
    {id: "ubiquitin carboxyl-terminal hydrolase 42"},
    {id: "deubiquitinating enzyme 42"},
    {id: "ubiquitin thioesterase 42"},
    {id: "ubiquitin-specific-processing protease 42"},
    {id: "ubiquitin carboxyl-terminal hydrolase 47"},
    {id: "deubiquitinating enzyme 47"},
    {id: "ubiquitin thioesterase 47"},
    {id: "ubiquitin-specific-processing protease 47"},
    {id: "ubiquitin carboxyl-terminal hydrolase 7"},
    {id: "ubiquitin thioesterase 7"},
    {id: "ubiquitin-specific-processing protease 7"},
    {id: "ubiquitin carboxyl-terminal hydrolase cyld"},
    {id: "deubiquitinating enzyme cyld"},
    {id: "ubiquitin thioesterase cyld"},
    {id: "ubiquitin-specific-processing protease cyld"},
    {id: "ubiquitin carboxyl-terminal hydrolase mindy"},
    {id: "ubiquitin carboxyl-terminal hydrolase mindy-1"},
    {id: "deubiquitinating enzyme mindy-1"},
    {id: "protein fam63a"},
    {id: "ubiquitin carboxyl-terminal hydrolase isozyme l1"},
    {id: "uch-l1"},
    {id: "neuron cytoplasmic protein 9.5"},
    {id: "pgp 9.5"},
    {id: "pgp9.5"},
    {id: "ubiquitin thioesterase l1"},
    {id: "ubiquitin conjugation factor e4"},
    {id: "ring-type e3 ubiquitin transferase e4"},
    {id: "ubiquitin recognition factor in er-associated degradation protein 1"},
    {id: "ubiquitin fusion degradation protein 1 homolog"},
    {id: "ub fusion protein 1"},
    {id: "ubiquitin thioesterase"},
    {id: "ubiquitin thioesterase otu1"},
    {id: "ubiquitin thioesterase otub1"},
    {id: "deubiquitinating enzyme otub1"},
    {id: "otu domain-containing ubiquitin aldehyde-binding protein 1"},
    {id: "otubain-1"},
    {id: "ubiquitin-specific-processing protease otub1"},
    {id: "ubiquitin-40s ribosomal protein s27a"},
    {id: "ubiquitin carboxyl extension protein 80"},
    {id: "ubiquitin-associated domain-containing protein 1"},
    {id: "uba domain-containing protein 1"},
    {id: "e3 ubiquitin-protein ligase subunit kpc2"},
    {id: "kip1 ubiquitination-promoting complex protein 2"},
    {id: "ubiquitin-associated protein 1"},
    {id: "ubap-1"},
    {id: "ubiquitin-conjugating enzyme e2 d1"},
    {id: "(e3-independent) e2 ubiquitin-conjugating enzyme d1"},
    {id: "ec 2.3.2.24"},
    {id: "e2 ubiquitin-conjugating enzyme d1"},
    {id: "ubiquitin carrier protein d1"},
    {id: "ubiquitin-conjugating enzyme e2(17)kb 1"},
    {id: "ubiquitin-conjugating enzyme e2-17 kda 1"},
    {id: "ubiquitin-protein ligase d1"},
    {id: "ubiquitin-conjugating enzyme e2 d2"},
    {id: "(e3-independent) e2 ubiquitin-conjugating enzyme d2"},
    {id: "e2 ubiquitin-conjugating enzyme d2"},
    {id: "ubiquitin carrier protein d2"},
    {id: "ubiquitin-conjugating enzyme e2(17)kb 2"},
    {id: "ubiquitin-conjugating enzyme e2-17 kda 2"},
    {id: "ubiquitin-protein ligase d2"},
    {id: "ubiquitin-conjugating enzyme e2 d3"},
    {id: "(e3-independent) e2 ubiquitin-conjugating enzyme d3"},
    {id: "e2 ubiquitin-conjugating enzyme d3"},
    {id: "phosphoarginine phosphatase"},
    {id: "papase"},
    {id: "ubiquitin carrier protein d3"},
    {id: "ubiquitin-conjugating enzyme e2(17)kb 3"},
    {id: "ubiquitin-conjugating enzyme e2-17 kda 3"},
    {id: "ubiquitin-protein ligase d3"},
    {id: "ubiquitin-conjugating enzyme e2 g1"},
    {id: "e2 ubiquitin-conjugating enzyme g1"},
    {id: "e217k"},
    {id: "ubc7"},
    {id: "ubiquitin carrier protein g1"},
    {id: "ubiquitin-protein ligase g1"},
    {id: "cleaved into: ubiquitin-conjugating enzyme e2 g1, n-terminally processed"},
    {id: "ubiquitin-conjugating enzyme e2 n"},
    {id: "bendless-like ubiquitin-conjugating enzyme"},
    {id: "e2 ubiquitin-conjugating enzyme n"},
    {id: "ubiquitin carrier protein n"},
    {id: "ubiquitin-protein ligase n"},
    {id: "ubiquitin-conjugating enzyme e2 s"},
    {id: "e2 ubiquitin-conjugating enzyme s"},
    {id: "ubiquitin carrier protein s"},
    {id: "ubiquitin-protein ligase s"},
    {id: "ubiquitin-conjugating enzyme e2 z"},
    {id: "e2 ubiquitin-conjugating enzyme z"},
    {id: "uba6-specific e2 conjugating enzyme 1"},
    {id: "use1"},
    {id: "ubiquitin carrier protein z"},
    {id: "ubiquitin-protein ligase z"},
    {id: "ubiquitin-conjugating enzyme e2a"},
    {id: "ubiquitin-conjugating enzyme e2a, rad6 homolog (s. cerevisiae)"},
    {id: "ubiquitin-conjugating enzyme e2f"},
    {id: "ubiquitin-fold modifier-conjugating enzyme 1"},
    {id: "ufm1-conjugating enzyme 1"},
    {id: "ubiquitin-like domain-containing ctd phosphatase 1"},
    {id: "nuclear proteasome inhibitor ublcp1"},
    {id: "ubiquitin-like modifier-activating enzyme 5"},
    {id: "ubiquitin-activating enzyme 5"},
    {id: "ufm1-activating enzyme"},
    {id: "ubiquitin-like modifier-activating enzyme atg7"},
    {id: "autophagy-related protein 7"},
    {id: "ubiquitin-like protein atg12"},
    {id: "autophagy-related protein 12"},
    {id: "apg12-like"},
    {id: "ubiquitin-like-conjugating enzyme atg3"},
    {id: "autophagy-related protein 3"},
    {id: "apg3-like"},
    {id: "preconditioning-inducible gene 1 protein"},
    {id: "ubiquitin-protein ligase e3a"},
    {id: "ubiquitin-ribosomal protein el40 fusion protein"},
    {id: "cleaved into: ubiquitin; large ribosomal subunit protein el40 (60s ribosomal protein l40) (cep52)"},
    {id: "ubiquitin-ribosomal protein es31 fusion protein"},
    {id: "cleaved into: ubiquitin; small ribosomal subunit protein es31 (40s ribosomal protein s27a)"},
    {id: "ubiquitin/isg15-conjugating enzyme e2 l6"},
    {id: "e2 ubiquitin-conjugating enzyme l6"},
    {id: "ubiquitin carrier protein l6"},
    {id: "ubiquitin-protein ligase l6"},
    {id: "ufm1-specific protease 2"},
    {id: "ufsp2"},
    {id: "uncharacterized protein c11orf96 homolog"},
    {id: "protein ag2"},
    {id: "uncharacterized protein clba1"},
    {id: "clathrin-binding box of aftiphilin-containing protein 1"},
    {id: "unconventional myosin-ib"},
    {id: "myosin i alpha"},
    {id: "mmi-alpha"},
    {id: "mmia"},
    {id: "myosin heavy chain myr 1"},
    {id: "unconventional myosin-vi"},
    {id: "unconventional myosin-6"},
    {id: "unconventional myosin-x"},
    {id: "unconventional myosin-10"},
    {id: "unconventional myosin-xvi"},
    {id: "myosin heavy chain myr 8"},
    {id: "neuronal tyrosine-phosphorylated phosphoinositide-3-kinase adapter 3"},
    {id: "unconventional myosin-16"},
    {id: "unique cartilage matrix-associated protein"},
    {id: "cleaved into: unique cartilage matrix-associated protein c-terminal fragment (ucma-c) (gla-rich protein) (grp)"},
    {id: "up-regulator of carnitine transporter, octn2"},
    {id: "mrna stabilizer 2b1 splice variant"},
    {id: "upstream stimulatory factor 2"},
    {id: "major late transcription factor 2"},
    {id: "upstream transcription factor 2"},
    {id: "uracil nucleotide/cysteinyl leukotriene receptor"},
    {id: "udp/cyslt receptor"},
    {id: "g-protein coupled receptor 17"},
    {id: "uracil-dna glycosylase"},
    {id: "udg"},
    {id: "ec 3.2.2.27"},
    {id: "uricase"},
    {id: "ec 1.7.3.3"},
    {id: "urate oxidase"},
    {id: "uridine 5'-monophosphate synthase"},
    {id: "ec 2.4.2.10"},
    {id: "ec 4.1.1.23"},
    {id: "uridine diphosphate glucose pyrophosphatase nudt22"},
    {id: "udpg pyrophosphatase"},
    {id: "ugppase"},
    {id: "ec 3.6.1.45"},
    {id: "nucleoside diphosphate-linked moiety x motif 22"},
    {id: "nudix motif 22"},
    {id: "uridine phosphorylase"},
    {id: "ec 2.4.2.3"},
    {id: "uridine-cytidine kinase"},
    {id: "ec 2.7.1.48"},
    {id: "uridine-cytidine kinase 2"},
    {id: "uck 2"},
    {id: "cytidine monophosphokinase 2"},
    {id: "uridine monophosphokinase 2"},
    {id: "uridylate-specific endoribonuclease"},
    {id: "urinary protein 1"},
    {id: "up-1"},
    {id: "rup-1"},
    {id: "liver regeneration-related protein lrrg05"},
    {id: "urinary protein 1-like"},
    {id: "urinary protein 4"},
    {id: "urocortin"},
    {id: "corticotensin"},
    {id: "uroporphyrinogen decarboxylase"},
    {id: "ec 4.1.1.37"},
    {id: "urotensin-2"},
    {id: "urotensin ii"},
    {id: "u-ii"},
    {id: "uii"},
    {id: "urotensin-2 receptor"},
    {id: "ur-2-r"},
    {id: "g-protein coupled receptor 14"},
    {id: "g-protein coupled sensory epithelial neuropeptide-like receptor"},
    {id: "senr"},
    {id: "urotensin ii receptor"},
    {id: "ur-ii-r"},
    {id: "urotensin-2b"},
    {id: "urotensin ii-related peptide"},
    {id: "urotensin iib"},
    {id: "u-iib"},
    {id: "uiib"},
    {id: "urotensin-2 domain-containing protein"},
    {id: "v-type proton atpase 16 kda proteolipid subunit c"},
    {id: "v-atpase 16 kda proteolipid subunit c"},
    {id: "vacuolar proton pump 16 kda proteolipid subunit c"},
    {id: "v-type proton atpase subunit b, brain isoform"},
    {id: "v-atpase subunit b 2"},
    {id: "endomembrane proton pump 58 kda subunit"},
    {id: "vacuolar proton pump subunit b 2"},
    {id: "v-type proton atpase subunit c 1"},
    {id: "v-atpase subunit c 1"},
    {id: "vacuolar proton pump subunit c 1"},
    {id: "v-type proton atpase subunit c 2"},
    {id: "v-atpase subunit c 2"},
    {id: "vacuolar proton pump subunit c 2"},
    {id: "v-type proton atpase subunit d"},
    {id: "vacuolar proton pump subunit d"},
    {id: "v-type proton atpase subunit d 2"},
    {id: "v-atpase subunit d 2"},
    {id: "vacuolar proton pump subunit d 2"},
    {id: "v-type proton atpase subunit e 2"},
    {id: "v-atpase subunit e 2"},
    {id: "vacuolar proton pump subunit e 2"},
    {id: "vip peptides"},
    {id: "cleaved into: intestinal peptide phv-42; intestinal peptide phi-27 (peptide histidine isoleucinamide 27); vasoactive intestinal peptide (vip) (vasoactive intestinal polypeptide)"},
    {id: "vacuolar protein sorting-associated protein 26a"},
    {id: "vesicle protein sorting 26a"},
    {id: "vacuolar protein sorting-associated protein 29"},
    {id: "vesicle protein sorting 29"},
    {id: "vacuolar protein sorting-associated protein 33b"},
    {id: "r-vps33b"},
    {id: "vacuolar protein sorting-associated protein 45"},
    {id: "rvps45"},
    {id: "vacuolar protein sorting-associated protein 72 homolog"},
    {id: "transcription factor-like 1"},
    {id: "vacuolar protein-sorting-associated protein 25"},
    {id: "escrt-ii complex subunit vps25"},
    {id: "vacuolar protein-sorting-associated protein 36"},
    {id: "escrt-ii complex subunit vps36"},
    {id: "vacuolar proton pump subunit b"},
    {id: "v-atpase subunit b"},
    {id: "vacuolar-sorting protein snf8"},
    {id: "ell-associated protein of 30 kda"},
    {id: "escrt-ii complex subunit vps22"},
    {id: "vang-like protein 2"},
    {id: "van gogh-like protein 2"},
    {id: "vascular endothelial growth factor a"},
    {id: "vegf-a"},
    {id: "vascular permeability factor"},
    {id: "vpf"},
    {id: "vascular endothelial growth factor b"},
    {id: "vegf-b"},
    {id: "vegf-related factor"},
    {id: "vrf"},
    {id: "vascular endothelial growth factor c"},
    {id: "vegf-c"},
    {id: "flt4 ligand"},
    {id: "flt4-l"},
    {id: "vascular endothelial growth factor-related protein"},
    {id: "vrp"},
    {id: "vasculin-like protein 1"},
    {id: "gc-rich promoter-binding protein 1-like 1"},
    {id: "vasoactive intestinal polypeptide receptor 1"},
    {id: "vip-r-1"},
    {id: "pituitary adenylate cyclase-activating polypeptide type ii receptor"},
    {id: "pacap type ii receptor"},
    {id: "pacap-r-2"},
    {id: "pacap-r2"},
    {id: "vasoactive intestinal polypeptide receptor 2"},
    {id: "vip-r-2"},
    {id: "pituitary adenylate cyclase-activating polypeptide type iii receptor"},
    {id: "pacap type iii receptor"},
    {id: "pacap-r-3"},
    {id: "pacap-r3"},
    {id: "ventricular zone-expressed ph domain-containing protein homolog 1"},
    {id: "protein melted homolog"},
    {id: "versican core protein"},
    {id: "chondroitin sulfate proteoglycan core protein 2"},
    {id: "chondroitin sulfate proteoglycan 2"},
    {id: "glial hyaluronate-binding protein"},
    {id: "ghap"},
    {id: "large fibroblast proteoglycan"},
    {id: "pg-m"},
    {id: "very-long-chain"},
    {id: "3r"},
    {id: "ec 4.2.1.134"},
    {id: "very-long-chain 3-oxoacyl-coa reductase"},
    {id: "ec 1.1.1.330"},
    {id: "17-beta-hydroxysteroid dehydrogenase 12"},
    {id: "17-beta-hsd 12"},
    {id: "3-ketoacyl-coa reductase"},
    {id: "kar"},
    {id: "estradiol 17-beta-dehydrogenase 12"},
    {id: "very-long-chain enoyl-coa reductase"},
    {id: "ec 1.3.1.93"},
    {id: "synaptic glycoprotein sc2"},
    {id: "trans-2,3-enoyl-coa reductase"},
    {id: "ter"},
    {id: "vesicle trafficking 1"},
    {id: "vps20-associated 1 homolog (s. cerevisiae)"},
    {id: "vesicle transport protein use1"},
    {id: "use1-like protein"},
    {id: "vesicle transport through interaction with t-snares homolog 1a"},
    {id: "vesicle transport v-snare protein vti1-like 2"},
    {id: "vti1-rp2"},
    {id: "vesicle transport through interaction with t-snares homolog 1b"},
    {id: "vesicle transport v-snare protein vti1-like 1"},
    {id: "vti1-rp1"},
    {id: "vesicle-associated membrane protein 7"},
    {id: "vamp-7"},
    {id: "synaptobrevin-like protein 1"},
    {id: "vesicle-associated membrane protein 8"},
    {id: "vamp-8"},
    {id: "endobrevin"},
    {id: "edb"},
    {id: "vesicle-associated membrane protein-associated protein a"},
    {id: "vamp-a"},
    {id: "vamp-associated protein a"},
    {id: "vap-a"},
    {id: "33 kda vamp-associated protein"},
    {id: "vap-33"},
    {id: "vesicle-associated membrane protein-associated protein b"},
    {id: "vamp-b"},
    {id: "vamp-associated protein b"},
    {id: "vap-b"},
    {id: "vesicle-fusing atpase"},
    {id: "vesicle-trafficking protein sec22a"},
    {id: "sec22 vesicle-trafficking protein homolog a"},
    {id: "sec22 vesicle-trafficking protein-like 2"},
    {id: "rsec22a"},
    {id: "vesicle-trafficking protein sec22b"},
    {id: "er-golgi snare of 24 kda"},
    {id: "ers-24"},
    {id: "ers24"},
    {id: "sec22 vesicle-trafficking protein homolog b"},
    {id: "sec22 vesicle-trafficking protein-like 1"},
    {id: "vesicular glutamate transporter 1"},
    {id: "vglut1"},
    {id: "brain-specific na(+)-dependent inorganic phosphate cotransporter"},
    {id: "solute carrier family 17 member 7"},
    {id: "vesicular glutamate transporter 3"},
    {id: "vglut3"},
    {id: "solute carrier family 17 member 8"},
    {id: "vesicular inhibitory amino acid transporter"},
    {id: "gaba and glycine transporter"},
    {id: "solute carrier family 32 member 1"},
    {id: "vesicular gaba transporter"},
    {id: "rgvat"},
    {id: "rat unc-47 homolog"},
    {id: "runc-47"},
    {id: "vesicular, overexpressed in cancer, prosurvival protein 1"},
    {id: "egfr-coamplified and overexpressed protein"},
    {id: "ecop"},
    {id: "vinculin"},
    {id: "visual system homeobox 2"},
    {id: "ceh-10 homeodomain-containing homolog"},
    {id: "homeobox protein chx10"},
    {id: "vitamin k epoxide reductase complex subunit 1"},
    {id: "ec 1.17.4.4"},
    {id: "vitamin k1 2,3-epoxide reductase subunit 1"},
    {id: "vitamin k-dependent gamma-carboxylase"},
    {id: "ec 4.1.1.90"},
    {id: "peptidyl-glutamate 4-carboxylase"},
    {id: "vitamin k gamma glutamyl carboxylase"},
    {id: "voltage-dependent l-type calcium channel subunit beta-1"},
    {id: "calcium channel voltage-dependent subunit beta 1"},
    {id: "voltage-dependent l-type calcium channel subunit beta-2"},
    {id: "cab2"},
    {id: "calcium channel voltage-dependent subunit beta 2"},
    {id: "voltage-dependent l-type calcium channel subunit beta-4"},
    {id: "cab4"},
    {id: "calcium channel voltage-dependent subunit beta 4"},
    {id: "voltage-dependent anion-selective channel protein 1"},
    {id: "vdac-1"},
    {id: "rvdac1"},
    {id: "outer mitochondrial membrane protein porin 1"},
    {id: "voltage-dependent calcium channel gamma-1 subunit"},
    {id: "dihydropyridine-sensitive l-type, skeletal muscle calcium channel subunit gamma"},
    {id: "voltage-dependent calcium channel gamma-2 subunit"},
    {id: "neuronal voltage-gated calcium channel gamma-2 subunit"},
    {id: "stargazin"},
    {id: "transmembrane ampar regulatory protein gamma-2"},
    {id: "tarp gamma-2"},
    {id: "voltage-dependent calcium channel gamma-3 subunit"},
    {id: "neuronal voltage-gated calcium channel gamma-3 subunit"},
    {id: "transmembrane ampar regulatory protein gamma-3"},
    {id: "tarp gamma-3"},
    {id: "voltage-dependent calcium channel gamma-4 subunit"},
    {id: "neuronal voltage-gated calcium channel gamma-4 subunit"},
    {id: "transmembrane ampar regulatory protein gamma-4"},
    {id: "tarp gamma-4"},
    {id: "voltage-dependent calcium channel gamma-5 subunit"},
    {id: "neuronal voltage-gated calcium channel gamma-5 subunit"},
    {id: "transmembrane ampar regulatory protein gamma-5"},
    {id: "tarp gamma-5"},
    {id: "voltage-dependent calcium channel gamma-6 subunit"},
    {id: "neuronal voltage-gated calcium channel gamma-6 subunit"},
    {id: "voltage-dependent calcium channel gamma-7 subunit"},
    {id: "neuronal voltage-gated calcium channel gamma-7 subunit"},
    {id: "transmembrane ampar regulatory protein gamma-7"},
    {id: "tarp gamma-7"},
    {id: "voltage-dependent calcium channel subunit alpha-2/delta-3"},
    {id: "voltage-gated calcium channel subunit alpha-2/delta-3"},
    {id: "cleaved into: voltage-dependent calcium channel subunit alpha-2-3; voltage-dependent calcium channel subunit delta-3"},
    {id: "voltage-gated hydrogen channel 1"},
    {id: "hydrogen voltage-gated channel 1"},
    {id: "voltage-gated potassium channel subunit beta-1"},
    {id: "k(+) channel subunit beta-1"},
    {id: "kv-beta-1"},
    {id: "voltage-gated potassium channel subunit beta-2"},
    {id: "k(+) channel subunit beta-2"},
    {id: "kv-beta-2"},
    {id: "voltage-gated potassium channel subunit beta-3"},
    {id: "k(+) channel subunit beta-3"},
    {id: "kv-beta-3"},
    {id: "rck beta3"},
    {id: "volume-regulated anion channel subunit lrrc8a"},
    {id: "leucine-rich repeat-containing protein 8a"},
    {id: "volume-regulated anion channel subunit lrrc8c"},
    {id: "leucine-rich repeat-containing protein 8c"},
    {id: "volume-regulated anion channel subunit lrrc8d"},
    {id: "leucine-rich repeat-containing protein 5"},
    {id: "leucine-rich repeat-containing protein 8d"},
    {id: "volume-regulated anion channel subunit lrrc8e"},
    {id: "leucine-rich repeat-containing protein 8e"},
    {id: "vomeronasal type-1 receptor 102"},
    {id: "pheromone receptor vn2"},
    {id: "vomeronasal receptor 2"},
    {id: "vomeronasal type-1 receptor a15"},
    {id: "vomeronasal type-1 receptor 90"},
    {id: "pheromone receptor vn6"},
    {id: "vomeronasal receptor 6"},
    {id: "vomeronasal type-1 receptor a16"},
    {id: "vomeronasal type-1 receptor 96"},
    {id: "vomeronasal type-1 receptor b14"},
    {id: "wap four-disulfide core 6-like 1"},
    {id: "wap four-disulfide core domain 6b"},
    {id: "wap four-disulfide core domain 16"},
    {id: "wap four-disulfide core domain protein 16"},
    {id: "wap four-disulfide core domain protein 2"},
    {id: "epididymal secretory protein 4"},
    {id: "re4"},
    {id: "was/wasl-interacting protein family member 3"},
    {id: "corticosteroids and regional expression protein 16"},
    {id: "wash complex subunit 3"},
    {id: "coiled-coil domain-containing protein 53"},
    {id: "wash complex subunit 5"},
    {id: "wash complex subunit strumpellin"},
    {id: "wd repeat containing, antisense to tp73"},
    {id: "wd repeat domain 8"},
    {id: "wd repeat domain 25"},
    {id: "wdr25l protein"},
    {id: "wd repeat domain 31"},
    {id: "wdr31 protein"},
    {id: "wd repeat domain 45b"},
    {id: "wdr45-like protein"},
    {id: "wd repeat domain phosphoinositide-interacting protein 2"},
    {id: "wipi-2"},
    {id: "wd repeat domain phosphoinositide-interacting protein 4"},
    {id: "wipi-4"},
    {id: "wd repeat-containing protein 45"},
    {id: "wd repeat-containing protein 35"},
    {id: "naofen"},
    {id: "wd repeat-containing protein 48"},
    {id: "usp1-associated factor 1"},
    {id: "wd repeat-containing protein 7"},
    {id: "tgf-beta resistance-associated protein trag"},
    {id: "wd40 repeat-containing protein smu1"},
    {id: "smu-1 suppressor of mec-8 and unc-52 protein homolog"},
    {id: "ww domain-binding protein 11"},
    {id: "wbp-11"},
    {id: "splicing factor that interacts with pqbp-1 and pp1"},
    {id: "ww domain-binding protein 4"},
    {id: "wbp-4"},
    {id: "formin-binding protein 21"},
    {id: "ww domain-containing-binding protein 4"},
    {id: "wee1-like protein kinase"},
    {id: "wee1a kinase"},
    {id: "wiskott-aldrich syndrome protein family member"},
    {id: "wasp family protein member"},
    {id: "wnt inhibitory factor 1"},
    {id: "wif-1"},
    {id: "x-linked lymphocyte-regulated 3a"},
    {id: "x-linked lymphocyte-regulated protein 3a-like"},
    {id: "x-prolyl aminopeptidase"},
    {id: "aminopeptidase p"},
    {id: "x-ray repair cross-complementing protein 5"},
    {id: "xaa-pro aminopeptidase 2"},
    {id: "ec 3.4.11.9"},
    {id: "membrane-bound aminopeptidase p"},
    {id: "membrane-bound app"},
    {id: "mapp"},
    {id: "x-prolyl aminopeptidase 2"},
    {id: "xaa-pro dipeptidase"},
    {id: "x-pro dipeptidase"},
    {id: "ec 3.4.13.9"},
    {id: "imidodipeptidase"},
    {id: "peptidase d"},
    {id: "proline dipeptidase"},
    {id: "prolidase"},
    {id: "xanthine dehydrogenase/oxidase"},
    {id: "ec 1.17.1.4"},
    {id: "ec 1.17.3.2"},
    {id: "xylulose kinase"},
    {id: "ec 2.7.1.17"},
    {id: "ybx1 protein"},
    {id: "y+l amino acid transporter 1"},
    {id: "solute carrier family 7 member 7"},
    {id: "y(+)l-type amino acid transporter 1"},
    {id: "y+lat1"},
    {id: "y+lat-1"},
    {id: "y+l amino acid transporter 2"},
    {id: "solute carrier family 7 member 6"},
    {id: "y(+)l-type amino acid transporter 2"},
    {id: "y+lat2"},
    {id: "y+lat-2"},
    {id: "yth n"},
    {id: "yth domain-containing protein 1"},
    {id: "ra301-binding protein"},
    {id: "splicing factor yt521"},
    {id: "zw10 interactor"},
    {id: "snap25-interacting protein 30"},
    {id: "scoilin"},
    {id: "zw10-interacting protein 1"},
    {id: "zwint-1"},
    {id: "zbtb7c protein"},
    {id: "zinc finger and btb domain containing 7c"},
    {id: "zc3hc1 protein"},
    {id: "zinc finger, c3hc-type containing 1"},
    {id: "zc4h2 protein"},
    {id: "zinc finger c4h2-type containing"},
    {id: "zfp385a protein"},
    {id: "zinc finger protein 385a"},
    {id: "zfp579 protein"},
    {id: "zinc finger protein 579"},
    {id: "zfp597 protein"},
    {id: "zinc finger protein 597"},
    {id: "zfp9 protein"},
    {id: "zinc finger protein 9"},
    {id: "zinc finger"},
    {id: "ccch type"},
    {id: "zinc finger ccch domain-containing protein 15"},
    {id: "p48znf"},
    {id: "zinc finger cchc domain-containing protein 7"},
    {id: "tramp-like complex rna-binding factor zcchc7"},
    {id: "zinc finger cchc domain-containing protein 8"},
    {id: "tramp-like complex rna-binding factor zcchc8"},
    {id: "zinc finger cchc-type and rna-binding motif-containing protein 1"},
    {id: "u11/u12 small nuclear ribonucleoprotein 31 kda protein"},
    {id: "zinc finger mynd domain-containing protein 19"},
    {id: "melanin-concentrating hormone receptor 1-interacting zinc finger protein"},
    {id: "mch-r1-interacting zinc finger protein"},
    {id: "zinc finger ran-binding domain-containing protein 2"},
    {id: "zinc finger protein 265"},
    {id: "zinc finger, splicing"},
    {id: "zinc finger traf-type-containing protein 1"},
    {id: "cysteine and histidine-rich protein 1"},
    {id: "zinc finger matrin-type protein 3"},
    {id: "zinc finger protein wig-1"},
    {id: "p53-activated gene 608 protein"},
    {id: "zinc finger protein"},
    {id: "c2h2 type"},
    {id: "zinc finger protein 143"},
    {id: "zfp-143"},
    {id: "selenocysteine trna gene transcription-activating factor"},
    {id: "zinc finger protein 184"},
    {id: "znf184 protein"},
    {id: "zinc finger protein 22"},
    {id: "zinc finger protein krox-25"},
    {id: "zinc finger protein 24"},
    {id: "zinc finger protein 191"},
    {id: "zfp-191"},
    {id: "zinc finger protein 260"},
    {id: "zfp-260"},
    {id: "pancreas-only zinc finger protein 1"},
    {id: "zinc finger protein 330"},
    {id: "nucleolar autoantigen 36"},
    {id: "zinc finger protein 335"},
    {id: "nrc-interacting factor 1"},
    {id: "nif-1"},
    {id: "zinc finger protein 36 c3h type-like 3"},
    {id: "zinc finger protein 36, c3h type-like 3"},
    {id: "zinc finger protein 386"},
    {id: "kruppel-like"},
    {id: "zinc finger protein 57"},
    {id: "zfp-57"},
    {id: "zinc finger protein 667"},
    {id: "myocardial ischemic preconditioning up-regulated protein 1"},
    {id: "zinc finger protein 689"},
    {id: "transcription factor hit-39"},
    {id: "zinc finger protein 692"},
    {id: "aicar responsive element binding protein"},
    {id: "zinc finger protein 81"},
    {id: "hfz20"},
    {id: "zinc finger protein 830"},
    {id: "coiled-coil domain-containing protein 16"},
    {id: "zinc finger protein gfi-1"},
    {id: "growth factor independent protein 1"},
    {id: "zinc finger protein plag1"},
    {id: "pleiomorphic adenoma gene 1 protein"},
    {id: "zinc finger, matrin type 4"},
    {id: "zmat4 protein"},
    {id: "zinc finger-containing ubiquitin peptidase 1"},
    {id: "lys-63-specific deubiquitinase zufsp"},
    {id: "dub"},
    {id: "zinc finger with ufm1-specific peptidase domain protein"},
    {id: "zinc fingers and homeoboxes protein 2"},
    {id: "alpha-fetoprotein regulator 1"},
    {id: "afp regulator 1"},
    {id: "regulator of afp"},
    {id: "zinc finger and homeodomain protein 2"},
    {id: "zinc fingers and homeoboxes protein 3"},
    {id: "zinc finger and homeodomain protein 3"},
    {id: "zinc phosphodiesterase elac protein 2"},
    {id: "ec 3.1.26.11"},
    {id: "elac homolog protein 2"},
    {id: "ribonuclease z 2"},
    {id: "trna 3 endonuclease 2"},
    {id: "trnase z 2"},
    {id: "zinc transporter 7"},
    {id: "solute carrier family 30 member 7"},
    {id: "zinc transporter zip11"},
    {id: "solute carrier family 39 member 11"},
    {id: "zrt- and irt-like protein 11"},
    {id: "zip-11"},
    {id: "zinc transporter zip3"},
    {id: "solute carrier family 39 member 3"},
    {id: "zrt- and irt-like protein 3"},
    {id: "zip-3"},
    {id: "zinc transporter zip6"},
    {id: "solute carrier family 39 member 6"},
    {id: "zrt- and irt-like protein 6"},
    {id: "zip-6"},
    {id: "zinc transporter zip9"},
    {id: "solute carrier family 39 member 9"},
    {id: "zrt- and irt-like protein 9"},
    {id: "zip-9"},
    {id: "zinc-regulated gtpase metalloprotein activator 1"},
    {id: "cobalamin synthase w domain-containing protein 1"},
    {id: "cobw domain-containing protein 1"},
    {id: "zymogen granule membrane protein 16"},
    {id: "zymogen granule protein 16"},
    {id: "secretory lectin zg16"},
    {id: "[3-methyl-2-oxobutanoate dehydrogenase"},
    {id: "3-methyl-2-oxobutanoate dehydrogenase [lipoamide]"},
    {id: "ec 2.7.11.4"},
    {id: "branched-chain alpha-ketoacid dehydrogenase kinase"},
    {id: "bckd-kinase"},
    {id: "bckdhkin"},
    {id: "f-actin"},
    {id: "molecule interacting with casl protein 1"},
    {id: "mical-1"},
    {id: "mical c-terminal-like protein"},
    {id: "mical-cl"},
    {id: "molecule interacting with casl protein 2"},
    {id: "mical-2"},
    {id: "protein rsb-11-77"},
    {id: "heparan sulfate"},
    {id: "ec 2.8.2.8"},
    {id: "histone h3"},
    {id: "27"},
    {id: "36"},
    {id: "set domain-containing protein 2"},
    {id: "ec 1.14.11.68"},
    {id: "ec 1.14.11.67"},
    {id: "20"},
    {id: "tau protein"},
    {id: "acetyl-coa carboxylase"},
    {id: "adenosylmethionine decarboxylase"},
    {id: "ec 4.1.1.50"},
    {id: "alanine transaminase"},
    {id: "aldehyde dehydrogenase"},
    {id: "aldehyde oxidase"},
    {id: "ec 1.2.3.1"},
    {id: "alpha-1,2-mannosidase"},
    {id: "alpha-1,6-mannosyl-glycoprotein 6-beta-n-acetylglucosaminyltransferase"},
    {id: "alpha-l-fucosidase"},
    {id: "ec 3.2.1.51"},
    {id: "ec 3.2.1.24"},
    {id: "aminoacyl-trna hydrolase"},
    {id: "ec 3.1.1.29"},
    {id: "arginine--trna ligase"},
    {id: "arylamine n-acetyltransferase"},
    {id: "ascorbate ferrireductase"},
    {id: "transmembrane"},
    {id: "asparagine--trna ligase"},
    {id: "ec 6.1.1.22"},
    {id: "0,+"},
    {id: "b(0,+)at"},
    {id: "glycoprotein-associated amino acid transporter b0,+at1"},
    {id: "solute carrier family 7 member 9"},
    {id: "bmerb domain-containing protein 1"},
    {id: "uncharacterized protein c16orf45 homolog"},
    {id: "beta-n-acetylhexosaminidase"},
    {id: "camp and camp-inhibited cgmp 3',5'-cyclic phosphodiesterase 10a"},
    {id: "camp-dependent protein kinase"},
    {id: "ec 2.7.11.11"},
    {id: "camp-dependent protein kinase catalytic subunit beta"},
    {id: "pka c-beta"},
    {id: "camp-regulated phosphoprotein 19"},
    {id: "arpp-19"},
    {id: "cgmp-dependent protein kinase"},
    {id: "ec 2.7.11.12"},
    {id: "cgmp-inhibited 3',5'-cyclic phosphodiesterase 3a"},
    {id: "cyclic gmp-inhibited phosphodiesterase a"},
    {id: "cgi-pde a"},
    {id: "calcium/calmodulin-dependent protein kinase"},
    {id: "carnitine o-palmitoyltransferase"},
    {id: "ec 2.3.1.21"},
    {id: "cathepsin x"},
    {id: "ceramidase"},
    {id: "chitinase"},
    {id: "ec 3.2.1.14"},
    {id: "choline-phosphate cytidylyltransferase"},
    {id: "coproporphyrinogen oxidase"},
    {id: "ec 1.3.3.3"},
    {id: "creatine kinase"},
    {id: "ec 2.7.3.2"},
    {id: "cysteine desulfurase"},
    {id: "ec 2.8.1.7"},
    {id: "cysteine--trna ligase"},
    {id: "ec 6.1.1.16"},
    {id: "cysteinyl-trna synthetase"},
    {id: "cytochrome-b5 reductase"},
    {id: "dctp pyrophosphatase 1"},
    {id: "ec 3.6.1.12"},
    {id: "deoxycytidine-triphosphatase 1"},
    {id: "dctpase 1"},
    {id: "rs21-c6"},
    {id: "dtmp kinase"},
    {id: "ec 2.7.4.9"},
    {id: "deoxyribonuclease ii"},
    {id: "deoxyribose-phosphate aldolase"},
    {id: "ec 4.1.2.4"},
    {id: "2-deoxy-d-ribose 5-phosphate aldolase"},
    {id: "phosphodeoxyriboaldolase"},
    {id: "diphosphoinositol-polyphosphate diphosphatase"},
    {id: "diphthine methyl ester synthase"},
    {id: "ec 2.1.1.314"},
    {id: "ditrans,polycis-polyprenyl diphosphate synthase"},
    {id: "(2e,6e)-farnesyldiphosphate specific"},
    {id: "ec 2.5.1.87"},
    {id: "dolichol kinase"},
    {id: "ec 2.7.1.108"},
    {id: "dolichyl-diphosphooligosaccharide--protein glycotransferase"},
    {id: "ec 2.4.99.18"},
    {id: "dolichyl-phosphate beta-glucosyltransferase"},
    {id: "ec 2.4.1.117"},
    {id: "dual-specificity kinase"},
    {id: "dynamin gtpase"},
    {id: "eif5-mimic protein 1"},
    {id: "basic leucine zipper and w2 domain-containing protein 2"},
    {id: "brain development-related molecule 2"},
    {id: "eif5-mimic protein 2"},
    {id: "basic leucine zipper and w2 domain-containing protein 1"},
    {id: "enoyl-coa hydratase"},
    {id: "ethanolamine-phosphate cytidylyltransferase"},
    {id: "ec 2.7.7.14"},
    {id: "ctp:phosphoethanolamine cytidylyltransferase"},
    {id: "exo-alpha-sialidase"},
    {id: "exodeoxyribonuclease iii"},
    {id: "ec 3.1.11.2"},
    {id: "fatty acid amide hydrolase"},
    {id: "formate--tetrahydrofolate ligase"},
    {id: "glutaminase"},
    {id: "ec 3.5.1.2"},
    {id: "glutamine--fructose-6-phosphate transaminase"},
    {id: "isomerizing"},
    {id: "ec 2.6.1.16"},
    {id: "glutamine--trna ligase"},
    {id: "ec 6.1.1.18"},
    {id: "glutaminyl-trna synthetase"},
    {id: "glutaminyl-peptide cyclotransferase"},
    {id: "ec 2.3.2.5"},
    {id: "glutathione transferase"},
    {id: "glutathione-disulfide reductase"},
    {id: "glycerol kinase"},
    {id: "guanylate kinase"},
    {id: "ec 2.7.4.8"},
    {id: "heme oxygenase"},
    {id: "biliverdin-producing"},
    {id: "heparosan-n-sulfate-glucuronate 5-epimerase"},
    {id: "ec 5.1.3.17"},
    {id: "hexokinase"},
    {id: "ec 2.7.1.1"},
    {id: "histidine--trna ligase"},
    {id: "hyaluronoglucosaminidase"},
    {id: "hydroxymethylglutaryl-coa lyase"},
    {id: "inorganic diphosphatase"},
    {id: "isoleucine--trna ligase"},
    {id: "ec 6.1.1.5"},
    {id: "isoleucyl-trna synthetase"},
    {id: "isopentenyl-diphosphate delta-isomerase"},
    {id: "ec 5.3.3.2"},
    {id: "leucine--trna ligase"},
    {id: "ec 6.1.1.4"},
    {id: "leucyl-trna synthetase"},
    {id: "m-aaa protease-interacting protein 1, mitochondrial"},
    {id: "matrix aaa peptidase-interacting protein 1"},
    {id: "m7gpppx diphosphatase"},
    {id: "ec 3.6.1.59"},
    {id: "dcs-1"},
    {id: "decapping scavenger enzyme"},
    {id: "hint-related 7megmp-directed hydrolase"},
    {id: "histidine triad nucleotide-binding protein 5"},
    {id: "histidine triad protein member 5"},
    {id: "scavenger mrna-decapping enzyme dcps"},
    {id: "guanine-n(7)"},
    {id: "ec 2.1.1.56"},
    {id: "mrna cap guanine-n7 methyltransferase"},
    {id: "rg7mt1"},
    {id: "mrna (guanine-n(7))-methyltransferase"},
    {id: "mrna cap methyltransferase"},
    {id: "mrna decay activator protein zfp36"},
    {id: "zinc finger protein 36"},
    {id: "mrna decay activator protein zfp36l1"},
    {id: "butyrate response factor 1"},
    {id: "egf-inducible protein cmg1"},
    {id: "tpa-induced sequence 11b"},
    {id: "zinc finger protein 36, c3h1 type-like 1"},
    {id: "zfp36-like 1"},
    {id: "mrna export factor"},
    {id: "rae1 protein homolog"},
    {id: "mrna-associated protein mrnp 41"},
    {id: "mrna export factor gle1"},
    {id: "gle1 rna export mediator"},
    {id: "gle1-like protein"},
    {id: "nucleoporin gle1"},
    {id: "mrna-capping enzyme"},
    {id: "includes: mrna 5'-triphosphate monophosphatase (ec 3.6.1.74) (mrna 5'-phosphatase); mrna guanylyltransferase (ec 2.7.7.50) (gtp--rna guanylyltransferase) (gtase)"},
    {id: "maleylacetoacetate isomerase"},
    {id: "ec 5.2.1.2"},
    {id: "mannosyl-glycoprotein endo-beta-n-acetylglucosaminidase"},
    {id: "ec 3.2.1.96"},
    {id: "methenyltetrahydrofolate cyclohydrolase"},
    {id: "methionine adenosyltransferase"},
    {id: "methionine--trna ligase"},
    {id: "methylcrotonoyl-coa carboxylase"},
    {id: "non-specific protein-tyrosine kinase"},
    {id: "non-specific serine/threonine protein kinase"},
    {id: "oleoyl-"},
    {id: "oxoglutarate dehydrogenase"},
    {id: "succinyl-transferring"},
    {id: "peptide chain release factor n"},
    {id: "ec 2.1.1.297"},
    {id: "peptidylprolyl isomerase"},
    {id: "phenylalanine--trna ligase"},
    {id: "phosphatidate phosphatase"},
    {id: "phosphatidylinositol n-acetylglucosaminyltransferase"},
    {id: "ec 2.4.1.198"},
    {id: "glcnac-pi synthesis protein"},
    {id: "phosphatidylinositol-3,4-bisphosphate 4-phosphatase"},
    {id: "phosphatidylinositol-3-phosphatase"},
    {id: "phosphatidylinositol-4,5-bisphosphate 3-kinase"},
    {id: "phosphatidylserine decarboxylase"},
    {id: "phosphoenolpyruvate carboxykinase"},
    {id: "phosphoglucomutase"},
    {id: "alpha-d-glucose-1,6-bisphosphate-dependent"},
    {id: "ec 5.4.2.2"},
    {id: "phosphoinositide 5-phosphatase"},
    {id: "phosphopyruvate hydratase"},
    {id: "phosphoribosylformylglycinamidine synthase"},
    {id: "ec 6.3.5.3"},
    {id: "formylglycinamide ribonucleotide amidotransferase"},
    {id: "formylglycinamide ribotide amidotransferase"},
    {id: "phosphorylase kinase"},
    {id: "phosphoserine transaminase"},
    {id: "ec 3.2.1.143"},
    {id: "polynucleotide adenylyltransferase"},
    {id: "polyribonucleotide nucleotidyltransferase"},
    {id: "ec 2.7.7.8"},
    {id: "polynucleotide phosphorylase 1"},
    {id: "pre-rrna 2'-o-ribose rna methyltransferase ftsj3"},
    {id: "protein ftsj homolog 3"},
    {id: "putative rrna methyltransferase 3"},
    {id: "procollagen-lysine 5-dioxygenase"},
    {id: "ec 1.14.11.4"},
    {id: "procollagen-proline 3-dioxygenase"},
    {id: "procollagen-proline 4-dioxygenase"},
    {id: "proline--trna ligase"},
    {id: "proteasome endopeptidase complex"},
    {id: "protein deglycase"},
    {id: "ec 3.5.1.124"},
    {id: "protein xylosyltransferase"},
    {id: "ec 2.4.2.26"},
    {id: "protein-glutamine gamma-glutamyltransferase"},
    {id: "protein-histidine n-methyltransferase"},
    {id: "protein-ribulosamine 3-kinase"},
    {id: "ec 2.7.1.172"},
    {id: "protein-serine/threonine phosphatase"},
    {id: "protein-synthesizing gtpase"},
    {id: "protein-tyrosine-phosphatase"},
    {id: "proton-translocating nad"},
    {id: "ec 7.1.1.1"},
    {id: "rrna n6-adenosine-methyltransferase zcchc4"},
    {id: "zinc finger cchc domain-containing protein 4"},
    {id: "rrna adenine n"},
    {id: "receptor protein serine/threonine kinase"},
    {id: "riboflavin kinase"},
    {id: "ec 2.7.1.26"},
    {id: "ribonuclease p"},
    {id: "ribose-5-phosphate isomerase"},
    {id: "ec 5.3.1.6"},
    {id: "ribose-phosphate diphosphokinase"},
    {id: "ec 2.7.6.1"},
    {id: "sarcosine oxidasee"},
    {id: "formaldehyde-forming"},
    {id: "ec 1.5.3.1"},
    {id: "selenide, water dikinase"},
    {id: "ec 2.7.9.3"},
    {id: "separase"},
    {id: "ec 3.4.22.49"},
    {id: "serine--trna ligase"},
    {id: "small monomeric gtpase"},
    {id: "sn-1-specific diacylglycerol lipase"},
    {id: "snrna-activating protein complex subunit 2"},
    {id: "snapc subunit 2"},
    {id: "small nuclear rna-activating complex polypeptide 2"},
    {id: "snrna-activating protein complex 45 kda subunit"},
    {id: "snapc 45 kda subunit"},
    {id: "snrna-activating protein complex subunit 3"},
    {id: "snapc subunit 3"},
    {id: "small nuclear rna-activating complex polypeptide 3"},
    {id: "steroid 11beta-monooxygenase"},
    {id: "ec 1.14.15.4"},
    {id: "sulfate adenylyltransferase"},
    {id: "ec 2.7.7.4"},
    {id: "sulfiredoxin"},
    {id: "ec 1.8.98.2"},
    {id: "sulfite oxidase"},
    {id: "ec 1.8.3.1"},
    {id: "trna"},
    {id: "adenine(37)-n6"},
    {id: "trna methyltransferase o"},
    {id: "adenine(58)-n(1)"},
    {id: "ec 2.1.1.220"},
    {id: "mrna methyladenosine-n(1)-methyltransferase catalytic subunit trmt61a"},
    {id: "trna(m1a58)-methyltransferase subunit trmt61a"},
    {id: "trna(m1a58)mtase subunit trmt61a"},
    {id: "cytosine(34)-c(5)"},
    {id: "ec 2.1.1.203"},
    {id: "nol1/nop2/sun domain family member 2"},
    {id: "mrna cytosine c(5)-methyltransferase"},
    {id: "trna cytosine c(5)-methyltransferase"},
    {id: "cytosine(38)-c(5)"},
    {id: "ec 2.1.1.204"},
    {id: "dna (cytosine-5)-methyltransferase-like protein 2"},
    {id: "dnmt2"},
    {id: "guanine(10)-n2"},
    {id: "trna guanosine-2'-o-methyltransferase trm11 homolog"},
    {id: "guanine(26)-n(2)"},
    {id: "ec 2.1.1.216"},
    {id: "guanine(37)-n1"},
    {id: "ec 2.1.1.228"},
    {id: "m1g-methyltransferase"},
    {id: "trna [gm37] methyltransferase"},
    {id: "trna methyltransferase 5 homolog"},
    {id: "guanine(9)-n(1)"},
    {id: "ec 2.1.1.221"},
    {id: "guanine-n(7)-"},
    {id: "ec 2.1.1.33"},
    {id: "methyltransferase-like protein 1"},
    {id: "trna (guanine(46)-n(7))-methyltransferase"},
    {id: "trna(m7g46)-methyltransferase"},
    {id: "wd repeat-containing protein 4"},
    {id: "uracil(54)-c(5)"},
    {id: "ec 2.1.1.35"},
    {id: "uracil-o(2)-"},
    {id: "ec 2.1.1.211"},
    {id: "trna 4-demethylwyosine synthase"},
    {id: "adomet-dependent"},
    {id: "ec 4.1.3.44"},
    {id: "trna n"},
    {id: "methyltransferase-like protein 6"},
    {id: "trna n6-adenosine threonylcarbamoyltransferase"},
    {id: "ec 2.3.1.234"},
    {id: "n6-l-threonylcarbamoyladenine synthase"},
    {id: "t(6)a synthase"},
    {id: "o-sialoglycoprotein endopeptidase"},
    {id: "t(6)a37 threonylcarbamoyladenosine biosynthesis protein osgep"},
    {id: "trna threonylcarbamoyladenosine biosynthesis protein osgep"},
    {id: "trna n6-adenosine threonylcarbamoyltransferase, mitochondrial"},
    {id: "o-sialoglycoprotein endopeptidase-like protein 1"},
    {id: "t(6)a37 threonylcarbamoyladenosine biosynthesis protein osgepl1"},
    {id: "trna threonylcarbamoyladenosine biosynthesis protein osgepl1"},
    {id: "trna dimethylallyltransferase"},
    {id: "ec 2.5.1.75"},
    {id: "trna methyltransferase 10 homolog a"},
    {id: "rna (guanine-9-)-methyltransferase domain-containing protein 2"},
    {id: "trna (guanine(9)-n(1))-methyltransferase trmt10a"},
    {id: "trna methyltransferase 10 homolog b"},
    {id: "rna (guanine-9-)-methyltransferase domain-containing protein 3"},
    {id: "trna (guanine(9)-n(1))-methyltransferase trmt10b"},
    {id: "trna methyltransferase 10 homolog c"},
    {id: "mitochondrial ribonuclease p protein 1"},
    {id: "mitochondrial rnase p protein 1"},
    {id: "rna (guanine-9-)-methyltransferase domain-containing protein 1"},
    {id: "mrna methyladenosine-n(1)-methyltransferase"},
    {id: "trna (adenine(9)-n(1))-methyltransferase"},
    {id: "ec 2.1.1.218"},
    {id: "trna (guanine(9)-n(1))-methyltransferase"},
    {id: "trna methyltransferase 9b"},
    {id: "trna modification gtpase gtpbp3, mitochondrial"},
    {id: "gtp-binding protein 3"},
    {id: "trna nucleotidyl transferase 1"},
    {id: "trna nucleotidyl transferase, cca-adding, 1"},
    {id: "trna pseudouridine synthase"},
    {id: "trna pseudouridine"},
    {id: "55"},
    {id: "trna selenocysteine 1-associated protein 1"},
    {id: "secp43"},
    {id: "trna selenocysteine-associated protein 1"},
    {id: "trna wybutosine-synthesizing protein 2 homolog"},
    {id: "trna-yw-synthesizing protein 2"},
    {id: "ec 2.5.1.114"},
    {id: "trna(phe) (4-demethylwyosine(37)-c(7)) aminocarboxypropyltransferase"},
    {id: "trna wybutosine-synthesizing protein 3 homolog"},
    {id: "ec 2.1.1.282"},
    {id: "trna(phe) 7-((3-amino-3-carboxypropyl)-4-demethylwyosine(37)-n(4))-methyltransferase"},
    {id: "trna wybutosine-synthesizing protein 4"},
    {id: "trna yw-synthesizing protein 4"},
    {id: "ec 2.1.1.290"},
    {id: "ec 2.3.1.231"},
    {id: "leucine carboxyl methyltransferase 2"},
    {id: "trna(phe) (7-(3-amino-3-(methoxycarbonyl)propyl)wyosine(37)-n)-methoxycarbonyltransferase"},
    {id: "trna(phe) (7-(3-amino-3-carboxypropyl)wyosine(37)-o)-methyltransferase"},
    {id: "his"},
    {id: "ec 2.7.7.79"},
    {id: "trna-histidine guanylyltransferase"},
    {id: "trna-dihydrouridine synthase"},
    {id: "ec 1.3.1.-"},
    {id: "trna-dihydrouridine"},
    {id: "47"},
    {id: "nad(p)(+)"},
    {id: "trna-dihydrouridine synthase 3"},
    {id: "trna-specific adenosine deaminase 2"},
    {id: "ec 3.5.4.33"},
    {id: "deaminase domain-containing protein 1"},
    {id: "trna-specific adenosine-34 deaminase subunit adat2"},
    {id: "trna-splicing endonuclease subunit sen2"},
    {id: "ec 4.6.1.16"},
    {id: "trna-intron endonuclease sen2"},
    {id: "trna-splicing endonuclease subunit sen34"},
    {id: "trna-t"},
    {id: "ec 2.8.4.5"},
    {id: "trna-uridine aminocarboxypropyltransferase"},
    {id: "ec 2.5.1.25"},
    {id: "trna:m"},
    {id: "ec 2.1.1.225"},
    {id: "thioredoxin-dependent peroxiredoxin"},
    {id: "thioredoxin-disulfide reductase"},
    {id: "threonine--trna ligase"},
    {id: "trans-l-3-hydroxyproline dehydratase"},
    {id: "ec 4.2.1.77"},
    {id: "tryptophan 5-monooxygenase"},
    {id: "tryptophan--trna ligase"},
    {id: "tubulin-glutamate carboxypeptidase"},
    {id: "ubiquitinyl hydrolase 1"},
    {id: "uridine/cytidine kinase"},
    {id: "urocanate hydratase"},
    {id: "ec 4.2.1.49"},
    {id: "imidazolonepropionate hydrolase"},
    {id: "uroporphyrinogen-iii synthase"},
    {id: "ec 4.2.1.75"},
    {id: "hydroxymethylbilane hydrolyase [cyclizing]"},
    {id: "uroporphyrinogen-iii cosynthase"},
    {id: "vitamin-k-epoxide reductase"},
    {id: "warfarin-sensitive"},
    {id: "von ebner gland protein 1"},
    {id: "veg protein 1"},
    {id: "von ebner gland protein 2"},
    {id: "veg protein 2"},
    {id: "von willebrand factor"},
    {id: "vwf"},
    {id: "von willebrand factor a domain-containing protein 7"},
    {id: "protein g7c"},
];
