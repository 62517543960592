const pass1b06AssayTissueTreeData = [
  { id: '0.0', parent: '', name: 'Rats: Endurance Training' },
  { id: '1.0', parent: '0.0', name: 'Targeted Metabolomics', collapsed: true },
  { id: '1.1', parent: '0.0', name: 'Untargeted Metabolomics', collapsed: true },
  { id: '1.2', parent: '0.0', name: 'Untargeted Proteomics', collapsed: true },
  { id: '1.3', parent: '0.0', name: 'Transcriptomics' },
  { id: '1.4', parent: '0.0', name: 'Epigenomics', collapsed: true },
  { id: '2.0', parent: '1.4', name: 'RRBS' },
  { id: '2.1', parent: '1.4', name: 'ATAC-seq' },
  { id: '2.2', parent: '1.0', name: 'Targeted Tricarboxylic Acid Cycle' },
  { id: '2.3', parent: '1.0', name: 'Targeted Oxylipins' },
  { id: '2.4', parent: '1.0', name: 'Targeted Nucleotides' },
  { id: '2.5', parent: '1.0', name: 'Targeted Keto Acids' },
  { id: '2.6', parent: '1.0', name: 'Targeted Ethanolamides' },
  { id: '2.7', parent: '1.0', name: 'Targeted Amines' },
  { id: '2.8', parent: '1.0', name: 'Targeted Acyl-CoA' },
  { id: '2.9', parent: '1.1', name: 'Untargeted Reversed-Phase Positive' },
  { id: '2.10', parent: '1.1', name: 'Untargeted Reversed-Phase Negative' },
  { id: '2.11', parent: '1.1', name: 'Untargeted Lipidomics Reversed-Phase Positive' },
  { id: '2.12', parent: '1.1', name: 'Untargeted Lipidomics Reversed-Phase Negative' },
  { id: '2.13', parent: '1.1', name: 'Untargeted Ion-Pair Negative' },
  { id: '2.14', parent: '1.1', name: 'Untargeted HILIC-Positive' },
  { id: '2.15', parent: '1.2', name: 'Protein Ubiquitination' },
  { id: '2.16', parent: '1.2', name: 'Global Proteomics' },
  { id: '2.17', parent: '1.2', name: 'Phosphoproteomics' },
  { id: '2.18', parent: '1.2', name: 'Acetyl Proteomics' },
  { id: '2.19', parent: '1.3', name: 'RNA-seq' },
  // RRBS tissues
  { id: '3.0', parent: '2.0', name: 'White Adipose' },
  { id: '3.1', parent: '2.0', name: 'Brown Adipose' },
  { id: '3.2', parent: '2.0', name: 'Liver' },
  { id: '3.3', parent: '2.0', name: 'Lung' },
  { id: '3.4', parent: '2.0', name: 'Kidney' },
  { id: '3.5', parent: '2.0', name: 'Heart' },
  { id: '3.6', parent: '2.0', name: 'Gastrocnemius' },
  { id: '3.7', parent: '2.0', name: 'Hippocampus' },
  // ATAC-seq tissues
  { id: '3.8', parent: '2.1', name: 'White Adipose' },
  { id: '3.9', parent: '2.1', name: 'Brown Adipose' },
  { id: '3.10', parent: '2.1', name: 'Liver' },
  { id: '3.11', parent: '2.1', name: 'Lung' },
  { id: '3.12', parent: '2.1', name: 'Kidney' },
  { id: '3.13', parent: '2.1', name: 'Heart' },
  { id: '3.14', parent: '2.1', name: 'Gastrocnemius' },
  { id: '3.15', parent: '2.1', name: 'Hippocampus' },
  // Targeted Tricarboxylic Acid Cycle tissues
  { id: '3.16', parent: '2.2', name: 'White Adipose' },
  { id: '3.17', parent: '2.2', name: 'Brown Adipose' },
  { id: '3.18', parent: '2.2', name: 'Liver' },
  { id: '3.19', parent: '2.2', name: 'Lung' },
  { id: '3.20', parent: '2.2', name: 'Kidney' },
  { id: '3.21', parent: '2.2', name: 'Heart' },
  { id: '3.22', parent: '2.2', name: 'Gastrocnemius' },
  { id: '3.23', parent: '2.2', name: 'Hippocampus' },
  { id: '3.24', parent: '2.2', name: 'Plasma' },
  // Targeted Oxylipins tissues
  { id: '3.25', parent: '2.3', name: 'White Adipose' },
  { id: '3.26', parent: '2.3', name: 'Brown Adipose' },
  { id: '3.27', parent: '2.3', name: 'Liver' },
  { id: '3.28', parent: '2.3', name: 'Lung' },
  { id: '3.29', parent: '2.3', name: 'Kidney' },
  { id: '3.30', parent: '2.3', name: 'Heart' },
  { id: '3.31', parent: '2.3', name: 'Gastrocnemius' },
  { id: '3.32', parent: '2.3', name: 'Hippocampus' },
  { id: '3.33', parent: '2.3', name: 'Plasma' },
  // Targeted Nucleotides tissues
  { id: '3.34', parent: '2.4', name: 'White Adipose' },
  { id: '3.35', parent: '2.4', name: 'Liver' },
  { id: '3.36', parent: '2.4', name: 'Heart' },
  { id: '3.37', parent: '2.4', name: 'Gastrocnemius' },
  // Targeted Keto Acids tissues
  { id: '3.38', parent: '2.5', name: 'White Adipose' },
  { id: '3.39', parent: '2.5', name: 'Liver' },
  { id: '3.40', parent: '2.5', name: 'Heart' },
  { id: '3.41', parent: '2.5', name: 'Gastrocnemius' },
  { id: '3.42', parent: '2.5', name: 'Plasma' },
  // Targeted Ethanolamides tissues
  { id: '3.43', parent: '2.6', name: 'White Adipose' },
  { id: '3.44', parent: '2.6', name: 'Brown Adipose' },
  { id: '3.45', parent: '2.6', name: 'Liver' },
  { id: '3.46', parent: '2.6', name: 'Lung' },
  { id: '3.47', parent: '2.6', name: 'Kidney' },
  { id: '3.48', parent: '2.6', name: 'Heart' },
  { id: '3.49', parent: '2.6', name: 'Gastrocnemius' },
  { id: '3.50', parent: '2.6', name: 'Hippocampus' },
  { id: '3.51', parent: '2.6', name: 'Plasma' },
  // Targeted Amines tissues
  { id: '3.52', parent: '2.7', name: 'White Adipose' },
  { id: '3.53', parent: '2.7', name: 'Brown Adipose' },
  { id: '3.54', parent: '2.7', name: 'Liver' },
  { id: '3.55', parent: '2.7', name: 'Lung' },
  { id: '3.56', parent: '2.7', name: 'Kidney' },
  { id: '3.57', parent: '2.7', name: 'Heart' },
  { id: '3.58', parent: '2.7', name: 'Gastrocnemius' },
  { id: '3.59', parent: '2.7', name: 'Hippocampus' },
  { id: '3.60', parent: '2.7', name: 'Plasma' },
  // Targeted Acyl-CoA tissues
  { id: '3.61', parent: '2.8', name: 'White Adipose' },
  { id: '3.62', parent: '2.8', name: 'Liver' },
  { id: '3.63', parent: '2.8', name: 'Heart' },
  { id: '3.64', parent: '2.8', name: 'Gastrocnemius' },
  // Untargeted Reversed-Phase Positive tissues
  { id: '3.65', parent: '2.9', name: 'White Adipose' },
  { id: '3.66', parent: '2.9', name: 'Brown Adipose' },
  { id: '3.67', parent: '2.9', name: 'Liver' },
  { id: '3.68', parent: '2.9', name: 'Lung' },
  { id: '3.69', parent: '2.9', name: 'Kidney' },
  { id: '3.70', parent: '2.9', name: 'Heart' },
  { id: '3.71', parent: '2.9', name: 'Gastrocnemius' },
  { id: '3.72', parent: '2.9', name: 'Hippocampus' },
  { id: '3.73', parent: '2.9', name: 'Plasma' },
  // Untargeted Reversed-Phase Negative tissues
  { id: '3.74', parent: '2.10', name: 'White Adipose' },
  { id: '3.75', parent: '2.10', name: 'Brown Adipose' },
  { id: '3.76', parent: '2.10', name: 'Liver' },
  { id: '3.77', parent: '2.10', name: 'Lung' },
  { id: '3.78', parent: '2.10', name: 'Kidney' },
  { id: '3.79', parent: '2.10', name: 'Heart' },
  { id: '3.80', parent: '2.10', name: 'Gastrocnemius' },
  { id: '3.81', parent: '2.10', name: 'Hippocampus' },
  { id: '3.82', parent: '2.10', name: 'Plasma' },
  // Untargeted Lipidomics Reversed-Phase Positive tissues
  { id: '3.83', parent: '2.11', name: 'White Adipose' },
  { id: '3.84', parent: '2.11', name: 'Brown Adipose' },
  { id: '3.85', parent: '2.11', name: 'Liver' },
  { id: '3.86', parent: '2.11', name: 'Lung' },
  { id: '3.87', parent: '2.11', name: 'Kidney' },
  { id: '3.88', parent: '2.11', name: 'Heart' },
  { id: '3.89', parent: '2.11', name: 'Gastrocnemius' },
  { id: '3.90', parent: '2.11', name: 'Hippocampus' },
  { id: '3.91', parent: '2.11', name: 'Plasma' },
  // Untargeted Lipidomics Reversed-Phase Negaive tissues
  { id: '3.92', parent: '2.12', name: 'White Adipose' },
  { id: '3.93', parent: '2.12', name: 'Brown Adipose' },
  { id: '3.94', parent: '2.12', name: 'Liver' },
  { id: '3.95', parent: '2.12', name: 'Lung' },
  { id: '3.96', parent: '2.12', name: 'Kidney' },
  { id: '3.97', parent: '2.12', name: 'Heart' },
  { id: '3.98', parent: '2.12', name: 'Gastrocnemius' },
  { id: '3.99', parent: '2.12', name: 'Hippocampus' },
  { id: '4.00', parent: '2.12', name: 'Plasma' },
  // Untargeted Ion-Pair Negative tissues
  { id: '4.01', parent: '2.13', name: 'White Adipose' },
  { id: '4.02', parent: '2.13', name: 'Brown Adipose' },
  { id: '4.03', parent: '2.13', name: 'Liver' },
  { id: '4.04', parent: '2.13', name: 'Lung' },
  { id: '4.05', parent: '2.13', name: 'Kidney' },
  { id: '4.06', parent: '2.13', name: 'Heart' },
  { id: '4.07', parent: '2.13', name: 'Gastrocnemius' },
  { id: '4.08', parent: '2.13', name: 'Hippocampus' },
  { id: '4.09', parent: '2.13', name: 'Plasma' },
  // Untargeted HILIC-Positive tissues
  { id: '4.10', parent: '2.14', name: 'White Adipose' },
  { id: '4.11', parent: '2.14', name: 'Brown Adipose' },
  { id: '4.12', parent: '2.14', name: 'Liver' },
  { id: '4.12', parent: '2.14', name: 'Small Intestine' },
  { id: '4.14', parent: '2.14', name: 'Lung' },
  { id: '4.15', parent: '2.14', name: 'Ovaries' },
  { id: '4.16', parent: '2.14', name: 'Testes' },
  { id: '4.17', parent: '2.14', name: 'Spleen' },
  { id: '4.18', parent: '2.14', name: 'Colon' },
  { id: '4.19', parent: '2.14', name: 'Adrenal' },
  { id: '4.20', parent: '2.14', name: 'Kidney' },
  { id: '4.21', parent: '2.14', name: 'Heart' },
  { id: '4.22', parent: '2.14', name: 'Vastus Lateralis' },
  { id: '4.23', parent: '2.14', name: 'Gastrocnemius' },
  { id: '4.24', parent: '2.14', name: 'Hypothalamus' },
  { id: '4.25', parent: '2.14', name: 'Cortex' },
  { id: '4.26', parent: '2.14', name: 'Hippocampus' },
  { id: '4.27', parent: '2.14', name: 'Plasma' },
  // Protein Ubiquitination tissues
  { id: '4.28', parent: '2.15', name: 'Liver' },
  { id: '4.29', parent: '2.15', name: 'Heart' },
  // Global Proteomics tissues
  { id: '4.30', parent: '2.16', name: 'White Adipose' },
  { id: '4.31', parent: '2.16', name: 'Liver' },
  { id: '4.32', parent: '2.16', name: 'Lung' },
  { id: '4.33', parent: '2.16', name: 'Kidney' },
  { id: '4.34', parent: '2.16', name: 'Heart' },
  { id: '4.35', parent: '2.16', name: 'Gastrocnemius' },
  { id: '4.36', parent: '2.16', name: 'Cortex' },
  // Phosphoproteomics tissues
  { id: '4.37', parent: '2.17', name: 'White Adipose' },
  { id: '4.38', parent: '2.17', name: 'Liver' },
  { id: '4.39', parent: '2.17', name: 'Lung' },
  { id: '4.41', parent: '2.17', name: 'Kidney' },
  { id: '4.42', parent: '2.17', name: 'Heart' },
  { id: '4.43', parent: '2.17', name: 'Gastrocnemius' },
  { id: '4.44', parent: '2.17', name: 'Cortex' },
  // Acetyl Proteomics tissues
  { id: '4.45', parent: '2.18', name: 'Liver' },
  { id: '4.46', parent: '2.18', name: 'Heart' },
  // Untargeted HILIC-Positive tissues
  { id: '4.47', parent: '2.19', name: 'Vena Cava' },
  { id: '4.48', parent: '2.19', name: 'White Adipose' },
  { id: '4.49', parent: '2.19', name: 'Brown Adipose' },
  { id: '4.50', parent: '2.19', name: 'Liver' },
  { id: '4.51', parent: '2.19', name: 'Small Intestine' },
  { id: '4.52', parent: '2.19', name: 'Lung' },
  { id: '4.53', parent: '2.19', name: 'Ovaries' },
  { id: '4.54', parent: '2.19', name: 'Testes' },
  { id: '4.55', parent: '2.19', name: 'Spleen' },
  { id: '4.56', parent: '2.19', name: 'Colon' },
  { id: '4.57', parent: '2.19', name: 'Adrenal' },
  { id: '4.58', parent: '2.19', name: 'Kidney' },
  { id: '4.59', parent: '2.19', name: 'Heart' },
  { id: '4.60', parent: '2.19', name: 'Vastus Lateralis' },
  { id: '4.61', parent: '2.19', name: 'Gastrocnemius' },
  { id: '4.62', parent: '2.19', name: 'Hypothalamus' },
  { id: '4.63', parent: '2.19', name: 'Cortex' },
  { id: '4.64', parent: '2.19', name: 'Hippocampus' },
  { id: '4.65', parent: '2.19', name: 'Blood Rna' },
];

export default pass1b06AssayTissueTreeData;
